import { FormControl, FormGroup } from '@angular/forms';

export interface TypeCounter {
    tipo: string;
    codigo_tipo: string;
    date_time_modified: Date;
    id: number;
}

export function getTypeCounterDisplayColumns(): string[] {
    const displayedColumns: string[] = ['Código tipo', 'Tipo', 'Fecha de Modificación'];
    return displayedColumns;
}

export function getTypeCounterField(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'Código tipo':
            value = 'codigo_tipo';
            break;
        case 'Tipo':
            value = 'tipo';
            break;
        case 'Fecha de Modificación':
            value = 'date_time_modified';
            break;
    }
    return value;
}

export function getTypeCounterFieldName(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'codigo_tipo':
            value = 'Código tipo';
            break;
        case 'tipo':
            value = 'Tipo';
            break;
        case 'date_time_modified':
            value = 'Fecha de Modificación';
            break;
    }
    return value;
}

export function getTypeCounterFormControls(): FormGroup {
    const typeCounterFormData: FormGroup = new FormGroup({
        tipo: new FormControl(),
        codigo_tipo: new FormControl(),
        date_time_modified: new FormControl(),
        id: new FormControl(),
    });
    return typeCounterFormData;
}

export function getTypeCounterFieldType(field_name: string): string {
    //in bigquery
    let value: string = '';
    switch (field_name) {
        case 'codigo_tipo':
            value = 'string';
            break;
        case 'tipo':
            value = 'string';
            break;
        case 'date_time_modified':
            value = 'Date';
            break;
        case 'id':
            value = 'number';
            break;
    }
    return value;
}

export function getTypeCounterExcelFieldName(excel_field: string): string {
    let value: string = '';
    switch (excel_field.trim().toLowerCase()) {
        case 'tipo':
            value = 'tipo';
            break;
        case 'código tipo':
            value = 'codigo_tipo';
            break;
        case 'codigo tipo':
            value = 'codigo_tipo';
            break;
        case 'fecha de modificación':
            value = 'date_time_modified';
            break;
        case 'fecha de modificacion':
            value = 'date_time_modified';
            break;
        case 'codigo_tipo':
            value = 'codigo_tipo';
            break;
        case 'date_time_modified':
            value = 'date_time_modified';
            break;
        case 'codigo_tipo':
            value = 'codigo_tipo';
            break;
        case 'date_time_modified':
            value = 'date_time_modified';
            break;
    }
    return value;
}

export function getTypeCounterExcelExportColumns(): string[] {
    const columns = ['Código tipo', 'Tipo', 'Fecha de Modificación'];
    return columns;
}

<mat-dialog-content>
    <div style="display: flex">
        <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Número de serie</mat-label>
            <input
                #inputSerialNumber
                type="text"
                [placeholder]="'Inserte número de serie ...'"
                aria-label="serialNumber"
                matInput
                [formControl]="myControl"
                [matAutocomplete]="auto"
            />
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                <p *ngIf="!loading">
                    <mat-option *ngFor="let option of serialNumbers" [value]="option">
                        {{ option }}
                    </mat-option>
                </p>
                <p *ngIf="loading" style="margin: 40px">
                    <mat-spinner
                        class="animate__animated animate__fadeIn animate__slow"
                    ></mat-spinner>
                </p>
            </mat-autocomplete>
        </mat-form-field>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="center">
    <button mat-button mat-dialog-close>Cancelar</button>
    <button
        mat-button
        [mat-dialog-close]="inputSerialNumber.value"
        [disabled]="!inputSerialNumber.value"
    >
        Aceptar
    </button>
</mat-dialog-actions>

import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataset } from 'chart.js';
import { TotalExecutedInAYearPerUser } from '../../../../interfaces/total-executed-in-a-year-per-user';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from '../../../../services/utils.service';
import { MiRutaFilter } from 'src/app/interfaces/mi-ruta-filter';
import { MiRutaUser } from 'src/app/interfaces/mi-ruta-user';
import { faInbox, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { TotalExecutedInAMonthPerUser } from '../../../../interfaces/total-executed-in-a-month-per-user';
import { TotalExecutedInADayPerUser } from '../../../../interfaces/total-executed-in-a-day-per-user';
import { TotalExecutedPerMonth } from '../../../../interfaces/total-executed-per-month';
import { TotalExecutedPerYear } from '../../../../interfaces/total-executed-per-year';
import { Location } from '@angular/common';
import { IpcService } from '../../../../services/ipc.service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-statistics-total',
    templateUrl: './statistics-total.component.html',
    styleUrls: ['./statistics-total.component.scss'],
})

export class StatisticsTotalComponent implements OnInit {

    faInbox: IconDefinition = faInbox;

    monthLabels: string[] = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    yearLabels: string[] = (() => {
        const currentYear = new Date().getFullYear();
        const years: string[] = [];
        for (let year = 2019; year <= currentYear; year++) {
            years.push(year.toString());
        }
        return years;
    })();

    totalYearUserGroup: FormGroup = new FormGroup({
        type: new FormControl(),
        users: new FormControl(),
    });
    
    totalMonthUserGroup: FormGroup = new FormGroup({
        type: new FormControl(),
        year: new FormControl(),
        users: new FormControl(),
    });
    
    totalDayUserGroup: FormGroup = new FormGroup({
        type: new FormControl(),
        year: new FormControl(),
        month: new FormControl(),
        users: new FormControl(),
    });
    
    totalYearGroup: FormGroup = new FormGroup({
        type: new FormControl(),
    });
    
    totalMonthGroup: FormGroup = new FormGroup({
        type: new FormControl(),
        year: new FormControl(),
    });
    
    loadingTotalYearUser: boolean = false;
    loadingTotalMonthUser: boolean = false;
    loadingTotalDayUser: boolean = false;
    loadingTotalYear: boolean = false;
    loadingTotalMonth: boolean = false;

    users: MiRutaUser[] = [];
    selectedTotalYearUsers: MiRutaUser[] = [];
    selectedTotalMonthUsers: MiRutaUser[] = [];
    selectedTotalDayUsers: MiRutaUser[] = [];

    public chartTypes: ChartType[] = ['line', 'bar', 'radar', 'pie', 'doughnut', 'polarArea', 'bubble', 'scatter'];
    
    public barChartOptions: ChartOptions = { 
        responsive: true 
    };
    
    public totalYearChartLabels: string[] = [...this.yearLabels];
    public totalYearChartType: ChartType = 'line';
    public totalYearChartLegend = true;
    public totalYearChartData: ChartDataset[] = [{ data: new Array(this.yearLabels.length).fill(0), label: 'Sin año' }];

    public totalMonthChartLabels: string[] = [...this.monthLabels];
    public totalMonthChartType: ChartType = 'bar';
    public totalMonthChartLegend = true;
    public totalMonthChartData: ChartDataset[] = [{ data: new Array(this.monthLabels.length).fill(0), label: 'Sin mes o año' }];

    
    public totalYearUserChartLabels: string[] = [...this.yearLabels];
    public totalYearUserChartType: ChartType = 'bar';
    public totalYearUserChartLegend = true;
    public totalYearUserChartData: ChartDataset[] = [{ data: new Array(this.yearLabels.length).fill(0), label: 'Sin selección de operarios' }];

    public totalMonthUserChartLabels: string[] = [...this.monthLabels];
    public totalMonthUserChartType: ChartType = 'bar';
    public totalMonthUserChartLegend = true;
    public totalMonthUserChartData: ChartDataset[] = [{ data: new Array(this.monthLabels.length).fill(0), label: 'Sin selección de operarios' }];


    public totalDayUserChartLabels: string[] = Array.from({length: 31}, (_, i) => (i + 1).toString());
    public totalDayUserChartType: ChartType = 'bar';
    public totalDayUserChartLegend = true;
    public totalDayUserChartData: ChartDataset[] = [{ data: new Array(31).fill(0), label: 'Sin selección de operarios' }];

    totalYearFilter: MiRutaFilter = {};
    totalMonthFilter: MiRutaFilter = {};
    totalYearUserFilter: MiRutaFilter = {};
    totalMonthUserFilter: MiRutaFilter = {};
    totalDayUserFilter: MiRutaFilter = {};

    dataTotalYear: TotalExecutedPerYear[] = [];
    dataTotalMonth: TotalExecutedPerMonth[] = [];
    dataTotalYearUser: TotalExecutedInAYearPerUser[] = [];
    dataTotalMonthUser: TotalExecutedInAMonthPerUser[] = [];
    dataTotalDayUser: TotalExecutedInADayPerUser[] = [];

    totalMonthSelectedYear: number = new Date().getFullYear();

    totalDaySelectedMonth: number = new Date().getMonth() + 1;
    totalDaySelectedYear: number = new Date().getFullYear();
    totalSelectedYear: number = new Date().getFullYear();

    totalYearUserFullScreen: boolean = false;
    totalMonthUserFullScreen: boolean = false;
    totalDayUserFullScreen: boolean = false;
    totalYearFullScreen: boolean = false;
    totalMonthFullScreen: boolean = false;

    constructor(
        private _apiService: ApiService,
        private _utilsService: UtilsService,
        public electronService: IpcService,
        public location: Location,

    ) { }

    async ngOnInit(): Promise<void> {
        this.users = await this._apiService.getUsers(['operario']);
        await this.setTotalYearUserActions();
        await this.setTotalMonthUserActions();
        await this.setTotalDayUserUserActions();
        await this.setTotalMonthActions();
        await this.setTotalYearActions();
    }
    
    async setTotalYearActions(): Promise<void> {
        this.totalYearGroup.controls['type'].setValue(this.totalYearChartType);
        this.totalYearGroup.controls['type'].valueChanges.subscribe((value) => {
            this.totalYearChartType = value;
        });
        await this.getTotalExecutedPerYear();
    }

    async setTotalYearUserActions(): Promise<void> {
        this.totalYearUserGroup.controls['type'].valueChanges.subscribe((value) => {
            this.totalYearUserChartType = value;
        });
        this.totalYearUserGroup.controls['users'].valueChanges.subscribe(async (value) => {
            await this.getTotalExecutedInAYearPerUser(value);
        });
        this.totalYearUserGroup.controls['type'].setValue(this.totalYearUserChartType);
    }

    async setTotalMonthActions(): Promise<void> {
        this.totalMonthGroup.controls['type'].valueChanges.subscribe((value) => {
            this.totalMonthChartType = value;
        });
        this.totalMonthGroup.controls['year'].valueChanges.subscribe(async (year) => {
            await this.getTotalExecutedPerMonth(year);
        });
        this.totalMonthGroup.controls['type'].setValue(this.totalMonthChartType);
        this.totalMonthGroup.controls['year'].setValue(new Date().getFullYear());
    }

    async setTotalMonthUserActions(): Promise<void> {
        this.totalMonthUserGroup.controls['type'].valueChanges.subscribe((value) => {
            this.totalMonthUserChartType = value;
        });
        this.totalMonthUserGroup.controls['year'].valueChanges.subscribe(async (year) => {
            const users = this.totalMonthUserGroup.controls['users'].value;
            await this.getTotalExecutedInAMonthPerUser(users, year);
        });
        this.totalMonthUserGroup.controls['users'].valueChanges.subscribe(async (users) => {
            const year = this.totalMonthUserGroup.controls['year'].value;
            await this.getTotalExecutedInAMonthPerUser(users, year);
        });
        this.totalMonthUserGroup.controls['type'].setValue(this.totalMonthUserChartType);
        this.totalMonthUserGroup.controls['year'].setValue(new Date().getFullYear());
    }

    async setTotalDayUserUserActions(): Promise<void> {
        this.totalDayUserGroup.controls['type'].valueChanges.subscribe((value) => {
            this.totalDayUserChartType = value;
        });
        this.totalDayUserGroup.controls['year'].valueChanges.subscribe(async (year) => {
            const month = this.totalDayUserGroup.controls['month'].value;
            const monthNumber = this.totalMonthUserChartLabels.indexOf(month) + 1;
            const users = this.totalDayUserGroup.controls['users'].value;
            await this.getTotalExecutedInADayPerUser(users, year, monthNumber);
        });
        this.totalDayUserGroup.controls['month'].valueChanges.subscribe(async (month) => {
            const monthNumber = this.totalMonthUserChartLabels.indexOf(month) + 1;
            const year = this.totalDayUserGroup.controls['year'].value;
            const users = this.totalDayUserGroup.controls['users'].value;
            await this.getTotalExecutedInADayPerUser(users, year, monthNumber);
        });
        this.totalDayUserGroup.controls['users'].valueChanges.subscribe(async (users) => {
            const month = this.totalDayUserGroup.controls['month'].value;
            const monthNumber = this.totalMonthUserChartLabels.indexOf(month) + 1;
            const year = this.totalDayUserGroup.controls['year'].value;
            await this.getTotalExecutedInADayPerUser(users, year, monthNumber);
        });
        this.totalDayUserGroup.controls['type'].setValue(this.totalDayUserChartType);
        this.totalDayUserGroup.controls['month'].setValue(this.monthLabels[new Date().getMonth()]);
        this.totalDayUserGroup.controls['year'].setValue(new Date().getFullYear());
    }

    async toggleTotalDayUserFullScreen(): Promise<void> {
        this.totalDayUserFullScreen = !this.totalDayUserFullScreen;
        this.getTotalExecutedInADayPerUser(this.selectedTotalDayUsers, this.totalDaySelectedYear, this.totalDaySelectedMonth);
    }

    async toggleTotalYearFullScreen(): Promise<void> {
        this.totalYearFullScreen = !this.totalYearFullScreen;
        this.getTotalExecutedPerYear();
    }

    async toggleTotalMonthFullScreen(): Promise<void> {
        this.totalMonthFullScreen = !this.totalMonthFullScreen;
        this.getTotalExecutedPerMonth(this.totalDaySelectedYear);
    }
    
    async toggleTotalMonthUserFullScreen(): Promise<void> {
        this.totalMonthUserFullScreen = !this.totalMonthUserFullScreen;
        this.getTotalExecutedInAMonthPerUser(this.selectedTotalMonthUsers, this.totalMonthSelectedYear);
    }
    
    async toggleTotalYearUserFullScreen(): Promise<void> {
        this.totalYearUserFullScreen = !this.totalYearUserFullScreen;
        this.getTotalExecutedInAYearPerUser(this.selectedTotalYearUsers);
    }

    async getTotalExecutedInADayPerUser(users: MiRutaUser[], year: number, month: number): Promise<void> {
        if(users && users.length && year && month) {
            this.loadingTotalDayUser = true;
            const userIds = users.map((item) => item.id.toString());
            this.totalDayUserFilter = {};
            this.totalDayUserFilter = this._utilsService.processFilter(this.totalDayUserFilter, userIds, 'last_modification_operator_uid', 'string', '', false);
            this.totalDayUserFilter = this._utilsService.processFilter(this.totalDayUserFilter, [year], '_year', 'number', '', false);
            this.totalDayUserFilter = this._utilsService.processFilter(this.totalDayUserFilter, [month], '_month', 'number', '', false);
            const where = this._utilsService.getWhereClauseFromFilter(this.totalDayUserFilter);
            this.dataTotalDayUser = await this._apiService.getTotalExecutedInADayPerUser(where);
            this.totalDayUserChartData = [];
            this.setTotalExecutedInADayPerUserChartData(users);
            this.loadingTotalDayUser = false;
        }
    }

    setTotalExecutedInADayPerUserChartData(users: MiRutaUser[]): void {
        for (const user of users) {
            const userData = this.dataTotalDayUser.filter((item) => +item.last_modification_operator_uid === user.id);
            let data: number[] = this.getTotalExecutedInADayPerUserData(userData);
            this.totalDayUserChartData.push({ data: data, label: user.nombre });
        }
    }

    getTotalExecutedInADayPerUserData(userData: TotalExecutedInADayPerUser[]): number[] {
        let data: number[] = [];
        for(let day = 1; day <= this.totalDayUserChartLabels.length; day++) {
            let total_executed_per_day = 0;
            for(const item of userData){
                if(item._day === day) total_executed_per_day = item.total_executed_per_day;                        
            }
            data.push(total_executed_per_day);
        }
        return data;
    }

    async getTotalExecutedPerMonth(year: number): Promise<void> {
        if(year) {
            this.loadingTotalMonth = true;
            this.totalMonthFilter = {}
            this.totalMonthFilter = this._utilsService.processFilter(this.totalMonthFilter, [year], '_year', 'number', '', false);
            const where = this._utilsService.getWhereClauseFromFilter(this.totalMonthFilter);
            this.dataTotalMonth = await this._apiService.getTotalExecutedPerMonth(where);
            this.totalMonthChartData = [];
            this.setTotalExecutedPerMonthChartData();
            this.loadingTotalMonth = false;
        }
    }

    setTotalExecutedPerMonthChartData(): void {
        let data: number[] = this.getTotalExecutedPerMonthData(this.dataTotalMonth);
        this.totalMonthChartData.push({ data: data, label: this.dataTotalMonth[0]._year.toString() });
    }
    
    getTotalExecutedPerMonthData(dataTotalMonth: TotalExecutedPerMonth[]): number[] {
        let data: number[] = [];
        for(let month = 1; month <= this.totalMonthChartLabels.length; month++) {
            let total_executed_per_month = 0;
            for(const item of dataTotalMonth){
                if(item._month === month) total_executed_per_month = item.total_executed_per_month;                        
            }
            data.push(total_executed_per_month);
        }
        return data;
    }

    async getTotalExecutedInAMonthPerUser(users: MiRutaUser[], year: number): Promise<void> {
        if(users && users.length && year) {
            this.loadingTotalMonthUser = true;
            const userIds = users.map((item) => item.id.toString());
            this.totalMonthUserFilter = {};
            this.totalMonthUserFilter = this._utilsService.processFilter(this.totalMonthUserFilter, userIds, 'last_modification_operator_uid', 'string', '', false);
            this.totalMonthUserFilter = this._utilsService.processFilter(this.totalMonthUserFilter, [year], '_year', 'number', '', false);
            const where = this._utilsService.getWhereClauseFromFilter(this.totalMonthUserFilter);
            this.dataTotalMonthUser = await this._apiService.getTotalExecutedInAMonthPerUser(where);
            this.totalMonthUserChartData = [];
            this.setTotalExecutedInAMonthPerUserChartData(users);
            this.loadingTotalMonthUser = false;
        }
    }

    setTotalExecutedInAMonthPerUserChartData(users: MiRutaUser[]): void {
        for (const user of users) {
            const userData = this.dataTotalMonthUser.filter((item) => +item.last_modification_operator_uid === user.id);
            let data: number[] = this.getTotalExecutedInAMonthPerUserData(userData);
            this.totalMonthUserChartData.push({ data: data, label: user.nombre });
        }
    }

    getTotalExecutedInAMonthPerUserData(userData: TotalExecutedInAMonthPerUser[]): number[] {
        let data: number[] = [];
        for(let month = 1; month <= this.totalMonthUserChartLabels.length; month++) {
            let total_executed_per_month = 0;
            for(const item of userData){
                if(item._month === month) total_executed_per_month = item.total_executed_per_month;                        
            }
            data.push(total_executed_per_month);
        }
        return data;
    }

    async getTotalExecutedPerYear() {
        this.loadingTotalYear = true;
        const where = this._utilsService.getWhereClauseFromFilter(this.totalYearFilter);
        this.dataTotalYear = await this._apiService.getTotalExecutedPerYear(where);
        this.totalYearChartData = [];
        this.setYearsPerYear(this.dataTotalYear);
        this.setTotalExecutedPerYearChartData();
        this.loadingTotalYear = false;
    }

    setTotalExecutedPerYearChartData(): void {
        let data: number[] = this.getTotalExecutedPerYearData(this.dataTotalYear);
        this.totalYearChartData.push({ data: data, label: 'Total por año' });
    }

    getTotalExecutedPerYearData(userData: TotalExecutedPerYear[]): number[] {
        let data: number[] = [];
        for(const year of this.totalYearChartLabels) {
            let total_executed_per_year = 0;
            for(const item of userData){
                if(item._year === +year) total_executed_per_year = item.total_executed_per_year;                        
            }
            data.push(total_executed_per_year);
        }
        return data;
    }

    async getTotalExecutedInAYearPerUser(users: MiRutaUser[]) {
        if(users && users.length) {
            this.loadingTotalYearUser = true;
            const userIds = users.map((item) => item.id.toString());
            this.totalYearUserFilter = {};
            this.totalYearUserFilter = this._utilsService.processFilter(this.totalYearUserFilter,
                userIds, 'last_modification_operator_uid','string', '', false,
            );
            const where = this._utilsService.getWhereClauseFromFilter(this.totalYearUserFilter);
            this.dataTotalYearUser = await this._apiService.getTotalExecutedInAYearPerUser(where);
            this.setYearsInAYearPerUser(this.dataTotalYearUser);
            this.setTotalExecutedInAYearPerUserChartData(users);
            this.loadingTotalYearUser = false;
        }
    }

    setTotalExecutedInAYearPerUserChartData(users: MiRutaUser[]): void {
        this.totalYearUserChartData = [];
        for (const user of users) {
            const userData = this.dataTotalYearUser.filter((item) => +item.last_modification_operator_uid === user.id);
            let data: number[] = this.getTotalExecutedInAYearPerUserData(userData);
            this.totalYearUserChartData.push({ data: data, label: user.nombre });
        }
    }

    getTotalExecutedInAYearPerUserData(userData: TotalExecutedInAYearPerUser[]): number[] {
        let data: number[] = [];
        for(const year of this.totalYearUserChartLabels) {
            let total_executed_per_year = 0;
            for(const item of userData){
                if(item._year === +year) total_executed_per_year = item.total_executed_per_year;                        
            }
            data.push(total_executed_per_year);
        }
        return data;
    }


    setYearsInAYearPerUser(userData: TotalExecutedInAYearPerUser[]) {
        this.totalYearUserChartLabels = [];
        const years = userData.map((item) => item._year);
        for (const year of years) {
            if(!this.totalYearUserChartLabels.includes(`${year}`)){
                this.totalYearUserChartLabels.push(`${year}`);
            } 
        }
        this.totalYearUserChartLabels.sort();
    }

    setYearsPerYear(userData: TotalExecutedPerYear[]) {
        this.totalYearChartLabels = [];
        const years = userData.map((item) => item._year);
        for (const year of years) {
            if(!this.totalYearChartLabels.includes(`${year}`)){
                this.totalYearChartLabels.push(`${year}`);
            } 
        }
        this.totalYearChartLabels.sort();
    }
}

import { Component, Input, OnInit } from '@angular/core';
import {FormControl} from '@angular/forms';
import {TooltipPosition} from '@angular/material/tooltip';

@Component({
  selector: 'mat-icon-tooltip-auto-hide',
  templateUrl: './tooltip-auto-hide-example.component.html',
  styleUrls: ['./tooltip-auto-hide-example.component.scss']
})
export class TooltipAutoHideExampleComponent implements OnInit {
  @Input() images: string[] = []

  constructor() { }

  ngOnInit(): void {
  }

}

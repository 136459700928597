import { FormControl, FormGroup } from '@angular/forms';

export interface Result {
    resultado: string;
    codigo_resultado: string;
    date_time_modified: Date;
    id: number;
}

export function getResultDisplayColumns(): string[] {
    const displayedColumns: string[] = ['Código resultado', 'Resultado', 'Fecha de Modificación'];
    return displayedColumns;
}

export function getResultField(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'Código resultado':
            value = 'codigo_resultado';
            break;
        case 'Resultado':
            value = 'resultado';
            break;
        case 'Fecha de Modificación':
            value = 'date_time_modified';
            break;
    }
    return value;
}

export function getResultFieldName(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'codigo_resultado':
            value = 'Código resultado';
            break;
        case 'resultado':
            value = 'Resultado';
            break;
        case 'date_time_modified':
            value = 'Fecha de Modificación';
            break;
    }
    return value;
}

export function getResultFormControls(): FormGroup {
    const resultFormData: FormGroup = new FormGroup({
        resultado: new FormControl(),
        codigo_resultado: new FormControl(),
        date_time_modified: new FormControl(),
        id: new FormControl(),
    });
    return resultFormData;
}

export function getResultFieldType(field_name: string): string {
    //in bigquery
    let value: string = '';
    switch (field_name) {
        case 'codigo_resultado':
            value = 'string';
            break;
        case 'resultado':
            value = 'string';
            break;
        case 'date_time_modified':
            value = 'Date';
            break;
        case 'id':
            value = 'number';
            break;
    }
    return value;
}

export function getResultExcelFieldName(excel_field: string): string {
    let value: string = '';
    switch (excel_field.trim().toLowerCase()) {
        case 'resultado':
            value = 'resultado';
            break;
        case 'código resultado':
            value = 'codigo_resultado';
            break;
        case 'codigo resultado':
            value = 'codigo_resultado';
            break;
        case 'fecha de modificación':
            value = 'date_time_modified';
            break;
        case 'fecha de modificacion':
            value = 'date_time_modified';
            break;
        case 'codigo_resultado':
            value = 'codigo_resultado';
            break;
        case 'date_time_modified':
            value = 'date_time_modified';
            break;
    }
    return value;
}

export function getResultExcelExportColumns(): string[] {
    const columns = ['Código resultado', 'Resultado', 'Fecha de Modificación'];
    return columns;
}

/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//Components *******************************************************************************
import { SplashComponent } from './components/splash/splash.component';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { TaskComponent } from './components/task/task.component';
import { ToolbarComponent } from './components/share/toolbar/toolbar.component';
import { DrawerComponent } from './components/share/drawer/drawer.component';
import { FilterComponent } from './components/share/filter/filter.component';
import { FilterConfigurationComponent } from './components/share/filter-configuration/filter-configuration.component';
import { QuestionDialogComponent } from './components/share/question-dialog/question-dialog.component';
import { TaskLocationComponent } from './components/task-location/task-location.component';
import { TableComponent } from './components/share/table/table.component';
import { ItacsComponent } from './components/itacs/itacs.component';
import { ItacComponent } from './components/itac/itac.component';
import { SelectorComponent } from './components/share/selector/selector.component';
import { DateRangeSelectorComponent } from './components/share/date-range-selector/date-range-selector.component';
import { TimeRangeSelectorComponent } from './components/share/time-range-selector/time-range-selector.component';
import { DateSelectorComponent } from './components/share/date-selector/date-selector.component';
import { TimeSelectorComponent } from './components/share/time-selector/time-selector.component';
import { WhatsappMessageComponent } from './components/share/whatsapp-message/whatsapp-message.component';
import { ServicesInformComponent } from './components/share/services-inform/services-inform.component';
import { FooterComponent } from './components/share/footer/footer.component';
import { ItacLocationComponent } from './components/itac-location/itac-location.component';
import { Section1Component } from './components/itac-sections/section1/section1.component';
import { Section2Component } from './components/itac-sections/section2/section2.component';
import { Section3Component } from './components/itac-sections/section3/section3.component';
import { Section4Component } from './components/itac-sections/section4/section4.component';
import { Section5Component } from './components/itac-sections/section5/section5.component';
import { CountersComponent } from './components/counters/counters.component';
import { CounterComponent } from './components/counter/counter.component';
import { WaterRoutesComponent } from './components/water-routes/water-routes.component';
import { WaterRouteComponent } from './components/water-route/water-route.component';
import { CalibersComponent } from './components/tables/calibers/calibers.component';
import { CaliberComponent } from './components/tables/caliber/caliber.component';
import { CauseComponent } from './components/tables/cause/cause.component';
import { CausesComponent } from './components/tables/causes/causes.component';
import { ClassCounterComponent } from './components/tables/class-counter/class-counter.component';
import { ClassCountersComponent } from './components/tables/class-counters/class-counters.component';
import { EmplacementsComponent } from './components/tables/emplacements/emplacements.component';
import { EmplacementComponent } from './components/tables/emplacement/emplacement.component';
import { LongitudesComponent } from './components/tables/longitudes/longitudes.component';
import { LongitudeComponent } from './components/tables/longitude/longitude.component';
import { MarkComponent } from './components/tables/mark/mark.component';
import { MarksComponent } from './components/tables/marks/marks.component';
import { ObservationComponent } from './components/tables/observation/observation.component';
import { ObservationsComponent } from './components/tables/observations/observations.component';
import { PartComponent } from './components/tables/part/part.component';
import { PartsComponent } from './components/tables/parts/parts.component';
import { ResultComponent } from './components/tables/result/result.component';
import { ResultsComponent } from './components/tables/results/results.component';
import { TypeCounterComponent } from './components/tables/type-counter/type-counter.component';
import { TypeCountersComponent } from './components/tables/type-counters/type-counters.component';
import { HelpComponent } from './components/share/help/help.component';
import { ZonesComponent } from './components/tables/zones/zones.component';
import { ZoneComponent } from './components/tables/zone/zone.component';
import { ZoneLocationComponent } from './components/tables/zone-location/zone-location.component';
import { CompanyComponent } from './components/tables/company/company.component';
import { CompaniesComponent } from './components/tables/companies/companies.component';
import { ManagerComponent } from './components/tables/manager/manager.component';
import { ManagersComponent } from './components/tables/managers/managers.component';
import { InfoComponent } from './components/tables/info/info.component';
import { InfosComponent } from './components/tables/infos/infos.component';
import { TeamsComponent } from './components/tables/teams/teams.component';
import { TeamComponent } from './components/tables/team/team.component';
import { UserComponent } from './components/user/user.component';
import { UsersComponent } from './components/users/users.component';
import { MultipleSelectorComponent } from './components/share/multiple-selector/multiple-selector.component';
import { InputSelectorComponent } from './components/share/input-selector/input-selector.component';
import { InformationDialogComponent } from './components/share/information-dialog/information-dialog.component';
import { TaskAssignComponent } from './components/share/task-assign/task-assign.component';
import { PartsSelectionComponent } from './components/share/parts-selection/parts-selection.component';
import { BarcodeDialogComponent } from './components/share/barcode-dialog/barcode-dialog.component';
import { CounterAssignComponent } from './components/share/counter-assign/counter-assign.component';
import { UserLocationComponent } from './components/user-location/user-location.component';
import { PhoneStatusComponent } from './components/share/phone-status/phone-status.component';
import { HeaderInterceptor } from './interceptor/header-interceptor';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DateTimeSelectorComponent } from './components/share/date-time-selector/date-time-selector.component';
import { DialogImageComponent } from './components/share/dialog-image/dialog-image.component';
import { ItacAssignComponent } from './components/share/itac-assign/itac-assign.component';
import { EditTextListComponent } from './components/share/edit-text-list/edit-text-list.component';
import { FillCounterComponent } from './components/share/fill-counter/fill-counter.component';
import { SerialNumberOptionSelectorComponent } from './components/share/serial-number-option-selector/serial-number-option-selector.component';
import { TypeRadiusComponent } from './components/tables/type-radius/type-radius.component';
import { TypeRadiussComponent } from './components/tables/type-radiuss/type-radiuss.component';
import { UserFilterConfigurationComponent } from './components/share/user-filter-configuration/user-filter-configuration.component';
import { UserFilterComponent } from './components/share/user-filter/user-filter.component';
import { ChangePasswordComponent } from './components/share/change-password/change-password.component';
import { ImageCarouselComponent } from './components/share/image-carousel/image-carousel.component';
//Components *******************************************************************************

//Material Modules *************************************************************************
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatChipsModule } from '@angular/material/chips';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule } from '@angular/material/menu';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTreeModule } from '@angular/material/tree';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCardModule } from '@angular/material/card';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRippleModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatSelectModule } from '@angular/material/select';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { CdkTableModule } from '@angular/cdk/table';
import { MtxTooltipModule } from '@ng-matero/extensions/tooltip';
//Material Modules **************************************************************************

//Modules ***********************************************************************************
import { PushNotificationService } from './services/push-notification.service';
import { GoogleMapsModule } from '@angular/google-maps';
import { GoogleMapsComponent } from './components/google-maps/google-maps.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxElectronModule } from 'ngx-electron';
import { NgxAudioPlayerModule } from 'ngx-audio-player';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { NgChartsModule } from 'ng2-charts';
//Modules ************************************************************************************

//Pipes ************************************************************************************
import { FieldPipe } from './pipes/field.pipe';
import { DatePipe } from '@angular/common';
import { PriorityPipe } from './pipes/priority.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { ManagerPipe } from './pipes/manager.pipe';
import { CompanyPipe } from './pipes/company.pipe';
import { TeamPipe } from './pipes/team.pipe';
import { UserPipe } from './pipes/user.pipe';
import { CounterStatusPipe } from './pipes/counter-status.pipe';
import { TaskStatusPipe } from './pipes/task-status.pipe';
import { TaskOrderPipe } from './pipes/task-order.pipe';
import { IncidencePipe } from './pipes/incidence.pipe';
import { DomseguroPipe } from './pipes/domseguro.pipe';
//Pipes ************************************************************************************

//Services ************************************************************************************
import { WindowRefService } from './services/window-ref.service';
import { SpinnerComponent } from './components/share/spinner/spinner.component';
import { AgrupationComponent } from './components/tables/agrupation/agrupation.component';
import { AgrupationsComponent } from './components/tables/agrupations/agrupations.component';
import { CounterActivatedStatusPipe } from './pipes/counter-activated-status.pipe';
import { AgrupationPipe } from './pipes/agrupation.pipe';
import { ActivationLogComponent } from './components/tables/activation-log/activation-log.component';
import { ActivationLogsComponent } from './components/tables/activation-logs/activation-logs.component';
import { GeolocationPipe } from './pipes/geolocation.pipe';
import { DataDialogComponent } from './components/share/data-dialog/data-dialog.component';
import { AssignZoneLocationComponent } from './components/share/assign-zone-location/assign-zone-location.component';
import { TooltipAutoHideExampleComponent } from './components/share/tooltip-auto-hide-example/tooltip-auto-hide-example.component';
import { RadiusModuleComponent } from './components/tables/radius-module/radius-module.component';
import { RadiusModulesComponent } from './components/tables/radius-modules/radius-modules.component';
import { IntegrationItelazpiComponent } from './components/tables/integration-itelazpi/integration-itelazpi.component';
import { IntegrationItelazpisComponent } from './components/tables/integration-itelazpis/integration-itelazpis.component';
import { CommonServicesInformComponent } from './components/share/common-services-inform/common-services-inform.component';
import { GlobalErrorHandler } from './services/global-error-handler';
import { NotificationCardComponent } from './components/share/notification-card/notification-card.component';

//Services ************************************************************************************
import { CalendarModule, CalendarDayModule, DateAdapter, CalendarCommonModule, CalendarWeekModule } from 'angular-calendar';
import { CalendarComponent } from './components/share/calendar/calendar.component';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { SidesComponent } from './components/sides/sides.component';
import { CalendarTableComponent } from './components/share/calendar-table/calendar-table.component';
import { StatisticsTotalComponent } from './components/share/statistics/statistics-total/statistics-total.component';
import { StatisticsAvgComponent } from './components/share/statistics/statistics-avg/statistics-avg.component';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { UserActivityComponent } from './components/share/statistics/user-activity/user-activity.component';
import { PlanningComponent } from './components/tables/planning/planning.component';
import { PlanningsComponent } from './components/tables/plannings/plannings.component';
import { ManagerMessageComponent } from './components/tables/manager-message/manager-message.component';
import { PlanningDetailComponent } from './components/tables/planning-detail/planning-detail.component';
import { PlanningDetailsComponent } from './components/tables/planning-details/planning-details.component';
import { PlanningDetailExtraComponent } from './components/tables/planning-detail-extra/planning-detail-extra.component';
import { PlanningDetailExtrasComponent } from './components/tables/planning-detail-extras/planning-detail-extras.component';

// Register the locale data
registerLocaleData(localeEs);

@NgModule({
    declarations: [
        AppComponent,
        SplashComponent,
        LoginComponent,
        HomeComponent,
        GoogleMapsComponent,
        FieldPipe,
        TaskComponent,
        ToolbarComponent,
        DrawerComponent,
        FilterComponent,
        QuestionDialogComponent,
        TaskLocationComponent,
        FilterConfigurationComponent,
        TableComponent,
        ItacsComponent,
        ItacComponent,
        SelectorComponent,
        PriorityPipe,
        DateRangeSelectorComponent,
        TimeRangeSelectorComponent,
        DateSelectorComponent,
        TimeSelectorComponent,
        WhatsappMessageComponent,
        ServicesInformComponent,
        FooterComponent,
        ItacLocationComponent,
        Section1Component,
        Section2Component,
        Section3Component,
        Section4Component,
        Section5Component,
        CountersComponent,
        CounterComponent,
        WaterRoutesComponent,
        WaterRouteComponent,
        CounterStatusPipe,
        TaskStatusPipe,
        TaskOrderPipe,
        IncidencePipe,
        CalibersComponent,
        CaliberComponent,
        CauseComponent,
        CausesComponent,
        ClassCounterComponent,
        ClassCountersComponent,
        EmplacementsComponent,
        EmplacementComponent,
        LongitudesComponent,
        LongitudeComponent,
        MarkComponent,
        MarksComponent,
        ObservationComponent,
        ObservationsComponent,
        PartComponent,
        PartsComponent,
        ResultComponent,
        ResultsComponent,
        TypeCounterComponent,
        TypeCountersComponent,
        HelpComponent,
        ZonesComponent,
        ZoneComponent,
        ZoneLocationComponent,
        CompanyComponent,
        CompaniesComponent,
        ManagerComponent,
        ManagersComponent,
        InfoComponent,
        InfosComponent,
        TeamsComponent,
        TeamComponent,
        UserComponent,
        UsersComponent,
        DomseguroPipe,
        SafePipe,
        ManagerPipe,
        MultipleSelectorComponent,
        InputSelectorComponent,
        InformationDialogComponent,
        CompanyPipe,
        TaskAssignComponent,
        PartsSelectionComponent,
        TeamPipe,
        UserPipe,
        BarcodeDialogComponent,
        CounterAssignComponent,
        UserLocationComponent,
        PhoneStatusComponent,
        DateTimeSelectorComponent,
        DialogImageComponent,
        ItacAssignComponent,
        EditTextListComponent,
        FillCounterComponent,
        SerialNumberOptionSelectorComponent,
        TypeRadiusComponent,
        TypeRadiussComponent,
        UserFilterConfigurationComponent,
        UserFilterComponent,
        SpinnerComponent,
        ChangePasswordComponent,
        AgrupationComponent,
        AgrupationsComponent,
        CounterActivatedStatusPipe,
        AgrupationPipe,
        ActivationLogsComponent,
        ActivationLogComponent,
        GeolocationPipe,
        DataDialogComponent,
        AssignZoneLocationComponent,
        TooltipAutoHideExampleComponent,
        RadiusModuleComponent,
        RadiusModulesComponent,
        IntegrationItelazpiComponent,        
        IntegrationItelazpisComponent, 
        CommonServicesInformComponent, 
        NotificationCardComponent, 
        CalendarComponent, 
        SidesComponent, 
        CalendarTableComponent, 
        StatisticsTotalComponent, 
        StatisticsAvgComponent, UserActivityComponent, PlanningComponent, PlanningsComponent, ManagerMessageComponent, PlanningDetailComponent, PlanningDetailsComponent, PlanningDetailExtraComponent, PlanningDetailExtrasComponent, ImageCarouselComponent, 
        //CalendarComponent,        
    ],
    imports: [
        NgChartsModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatInputModule,
        MatIconModule,
        MatFormFieldModule,
        MatButtonModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatTableModule,
        MatSortModule,
        MatSidenavModule,
        MatTabsModule,
        MatChipsModule,
        MatBadgeModule,
        MatTooltipModule,
        MatToolbarModule,
        MatGridListModule,
        MatMenuModule,
        MatAutocompleteModule,
        MatCheckboxModule,
        MatTreeModule,
        MatSnackBarModule,
        MatCardModule,
        MatPaginatorModule,
        MatRippleModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatMomentDateModule,
        MatSelectModule,
        ScrollingModule,
        TextFieldModule,
        MatProgressBarModule,
        MatListModule,
        MatSlideToggleModule,
        MatRadioModule,
        NgxMaterialTimepickerModule.setLocale('es-ES'),
        GoogleMapsModule,
        FontAwesomeModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        NgxSpinnerModule,
        NgxElectronModule,
        NgxAudioPlayerModule,
        CdkTableModule,
        DragDropModule,
        InfiniteScrollModule,
        NgxImageZoomModule,
        MtxTooltipModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
        // CalendarDayModule,
        // CalendarWeekModule
    ],
    providers: [
        PushNotificationService,
        DatePipe,
        WindowRefService,
        { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
        { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: LOCALE_ID, useValue: 'es' }
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}

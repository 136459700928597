/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { getItacFormControls, Itac } from 'src/app/interfaces/itac';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { ItacSectionData } from '../section1/section1.component';

@Component({
    selector: 'app-section3',
    templateUrl: './section3.component.html',
    styleUrls: ['./section3.component.scss'],
})
export class Section3Component implements OnInit {
    itac?: Itac;

    itacFormData: FormGroup = getItacFormControls();

    loading: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ItacSectionData,
        private _utilsService: UtilsService,
        private _apiService: ApiService,
        private _spinner: NgxSpinnerService
    ) {
        this.itac = data.itac;
        console.log(this.itac);
    }

    async saveData() {
        this.showLoading(true);
        this.saveFormData();
        this.calculatePoints();
        const result: boolean = await this._apiService.updateItac(
            this.itac!.id!.toString(),
            this.itac
        );
        this.showLoading(false);
        if (result) {
            this._utilsService.openSnackBar('Itac actualizada correctamente');
        } else {
            this._utilsService.openSnackBar('Error actualizando itac', 'error');
        }
        this._utilsService.closeSection3Dialog(result);
    }
    saveFormData() {
        const keys = Object.keys(this.itacFormData.controls);
        for (let key of keys) {
            let value = this.itacFormData.controls[key].value; //this.itac[key as keyof Itac];
            try {
                value = this._utilsService.getProcessedValue(key, value);
                this.itac![key as keyof Itac] = value;
            } catch (err) {
                console.log('============= err =============');
                console.log(err);
            }
        }
        this.itac!.date_time_modified = new Date();
    }

    ngOnInit(): void {
        this.itacFormData = this._utilsService.getFormFromItac(this.itac!);
    }

    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this._spinner.show('itacSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this._spinner.hide('itacSpinner');
        }
    }
    calculatePoints() {
        let puntuacion_seccion3 = 0;
        let espacio = this.itac!.espacio_para_trabajar!;
        let extra_desague = this.itac!.extras_desague!;
        let iluminacion = this.itac?.iluminacion;
        let iluminacion_extra = this.itac?.extras_iluminacion;

        if (espacio == 'Cómodo') {
            puntuacion_seccion3 += 0.5;
        } else if (espacio == 'Justo') {
            puntuacion_seccion3 += 0.3;
        } else if (espacio == 'Incómodo') {
            puntuacion_seccion3 += -1;
        } else if (espacio == 'Nulo') {
            puntuacion_seccion3 += -6;
        }

        if (this.itac!.desague == 'Si') {
            if (extra_desague == 'Funciona Bien') {
                puntuacion_seccion3 += 0.5;
            }
        }

        if (iluminacion == 'Natural') {
            puntuacion_seccion3 += 0.5;
        } else if (iluminacion == 'Suficiente') {
            puntuacion_seccion3 += 0.5;
        } else if (iluminacion == 'Poca') {
            if (iluminacion_extra == 'Cambiar Bombilla') {
                puntuacion_seccion3 += 0.2;
            }
        } else if (iluminacion == 'Nula') {
            if (iluminacion_extra == 'Cambiar Bombilla') {
                puntuacion_seccion3 += 0.2;
            }
        }
        this.itac!.puntuacion_seccion3 = puntuacion_seccion3;
    }
}

<mat-dialog-content class="animate__animated animate__fadeIn animate__slow">
    <div
        [formGroup]="itacFormData"
        class="animate__animated animate__fadeIn animate__slow container"
    >
        <div>
            <div class="container">
                <div class="row bd-highlight" class="row-content-center">
                    <mat-card class="mat-card-block">
                        <mat-card-subtitle style="margin: 6px; margin-bottom: 15px">
                            <p style="font-size: large">Detalles</p>
                        </mat-card-subtitle>
                        <mat-card-content>
                            <div class="row">
                                <mat-form-field appearance="fill">
                                    <mat-label>Acceso</mat-label>
                                    <textarea
                                        matInput
                                        formControlName="acceso"
                                        placeholder="Acceso"
                                        cdkTextareaAutosize
                                        #autosize="cdkTextareaAutosize"
                                        cdkAutosizeMinRows="3"
                                    >
                                    </textarea>
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Descripción</mat-label>
                                    <textarea
                                        matInput
                                        formControlName="descripcion"
                                        placeholder="Descripción"
                                        cdkTextareaAutosize
                                        #autosize="cdkTextareaAutosize"
                                        cdkAutosizeMinRows="3"
                                    >
                                    </textarea>
                                </mat-form-field>
                            </div>
                        </mat-card-content>
                    </mat-card>
                    <mat-card class="mat-card-block">
                        <mat-card-subtitle style="margin: 6px; margin-bottom: 15px">
                            <p style="font-size: large">Datos de Localización</p>
                        </mat-card-subtitle>
                        <mat-card-content>
                            <div class="row">
                                <mat-form-field style="width: 150px" appearance="fill">
                                    <mat-label>Prioridad</mat-label>
                                    <mat-select formControlName="prioridad">
                                        <mat-option
                                            *ngFor="let option of [1, 2, 3]"
                                            [value]="option"
                                            >{{ option | priority }}</mat-option
                                        >
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field style="flex: 2" appearance="fill">
                                    <mat-label>Dirección</mat-label>
                                    <input
                                        formControlName="itac"
                                        matInput
                                        type="text"
                                        placeholder="Dirección"
                                    />
                                </mat-form-field>
                                <mat-form-field style="width: 120px" appearance="fill">
                                    <mat-label>Sector P</mat-label>
                                    <mat-select formControlName="zona">
                                        <mat-option *ngFor="let option of zones" [value]="option">{{
                                            option
                                        }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>

        <div>
            <div class="container">
                <div class="row bd-highlight" class="row-content-center">
                    <mat-card class="mat-card-block-30">
                        <mat-card-subtitle style="margin: 6px; margin-bottom: 15px">
                            <p style="font-size: large">Administración Finca</p>
                        </mat-card-subtitle>
                        <mat-card-content>
                            <div class="row">
                                <mat-form-field appearance="fill">
                                    <mat-label>Nombre empresa</mat-label>
                                    <input
                                        formControlName="nombre_empresa_administracion"
                                        matInput
                                        type="text"
                                        placeholder="Nombre empresa"
                                    />
                                </mat-form-field>
                                <mat-form-field style="min-width: 100px" appearance="fill">
                                    <mat-label>Nombre responsable</mat-label>
                                    <input
                                        formControlName="nombre_responsable_administracion"
                                        matInput
                                        type="text"
                                        placeholder="Nombre responsable"
                                    />
                                </mat-form-field>
                            </div>
                            <div class="row">
                                <mat-form-field appearance="fill">
                                    <mat-label>Teléfono fijo</mat-label>
                                    <input
                                        formControlName="telefono_fijo_administracion"
                                        matInput
                                        type="text"
                                        placeholder="Teléfono fijo"
                                    />
                                </mat-form-field>
                                <mat-form-field style="min-width: 100px" appearance="fill">
                                    <mat-label>Teléfono móvil</mat-label>
                                    <input
                                        formControlName="telefono_movil_administracion"
                                        matInput
                                        type="text"
                                        placeholder="Teléfono móvil"
                                    />
                                </mat-form-field>
                            </div>
                            <div class="row">
                                <mat-form-field appearance="fill">
                                    <mat-label>Dirección oficina</mat-label>
                                    <input
                                        formControlName="direccion_oficina"
                                        matInput
                                        type="text"
                                        placeholder="Dirección oficina"
                                    />
                                </mat-form-field>
                                <mat-form-field style="min-width: 100px" appearance="fill">
                                    <mat-label>Correo</mat-label>
                                    <input
                                        formControlName="correo_administracion"
                                        matInput
                                        type="text"
                                        placeholder="Correo"
                                    />
                                </mat-form-field>
                            </div>
                        </mat-card-content>
                    </mat-card>

                    <mat-card class="mat-card-block-30">
                        <mat-card-subtitle style="margin: 6px; margin-bottom: 15px">
                            <p style="font-size: large">Presidente o Colaborador</p>
                        </mat-card-subtitle>
                        <mat-card-content>
                            <div class="row">
                                <mat-form-field appearance="fill">
                                    <mat-label>Nombre</mat-label>
                                    <input
                                        formControlName="nombre_presidente"
                                        matInput
                                        type="text"
                                        placeholder="Nombre"
                                    />
                                </mat-form-field>
                                <mat-form-field style="min-width: 100px" appearance="fill">
                                    <mat-label>Vivienda</mat-label>
                                    <input
                                        formControlName="vivienda_presidente"
                                        matInput
                                        type="text"
                                        placeholder="Vivienda"
                                    />
                                </mat-form-field>
                            </div>
                            <div class="row">
                                <mat-form-field appearance="fill">
                                    <mat-label>Teléfono fijo</mat-label>
                                    <input
                                        formControlName="telefono_fijo_presidente"
                                        matInput
                                        type="text"
                                        placeholder="Teléfono fijo"
                                    />
                                </mat-form-field>
                                <mat-form-field style="min-width: 100px" appearance="fill">
                                    <mat-label>Teléfono móvil</mat-label>
                                    <input
                                        formControlName="telefono_movil_presidente"
                                        matInput
                                        type="text"
                                        placeholder="Teléfono móvil"
                                    />
                                </mat-form-field>
                            </div>
                            <div class="row">
                                <mat-form-field appearance="fill">
                                    <mat-label>Correo</mat-label>
                                    <input
                                        formControlName="correo_presidente"
                                        matInput
                                        type="text"
                                        placeholder="Correo"
                                    />
                                </mat-form-field>
                            </div>
                        </mat-card-content>
                    </mat-card>

                    <mat-card class="mat-card-block-30">
                        <mat-card-subtitle style="margin: 6px; margin-bottom: 15px">
                            <p style="font-size: large">Encargado o Conserje</p>
                        </mat-card-subtitle>
                        <mat-card-content>
                            <div class="row">
                                <mat-form-field appearance="fill">
                                    <mat-label>Nombre</mat-label>
                                    <input
                                        formControlName="nombre_encargado"
                                        matInput
                                        type="text"
                                        placeholder="Nombre"
                                    />
                                </mat-form-field>
                                <mat-form-field style="min-width: 100px" appearance="fill">
                                    <mat-label>Vivienda</mat-label>
                                    <input
                                        formControlName="vivienda_encargado"
                                        matInput
                                        type="text"
                                        placeholder="Vivienda"
                                    />
                                </mat-form-field>
                            </div>
                            <div class="row">
                                <mat-form-field appearance="fill">
                                    <mat-label>Teléfono fijo</mat-label>
                                    <input
                                        formControlName="telefono_fijo_encargado"
                                        matInput
                                        type="text"
                                        placeholder="Teléfono fijo"
                                    />
                                </mat-form-field>
                                <mat-form-field style="min-width: 100px" appearance="fill">
                                    <mat-label>Teléfono móvil</mat-label>
                                    <input
                                        formControlName="telefono_movil_encargado"
                                        matInput
                                        type="text"
                                        placeholder="Teléfono móvil"
                                    />
                                </mat-form-field>
                            </div>
                            <div class="row">
                                <mat-form-field appearance="fill">
                                    <mat-label>Correo</mat-label>
                                    <input
                                        formControlName="correo_encargado"
                                        matInput
                                        type="text"
                                        placeholder="Correo"
                                    />
                                </mat-form-field>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="itac">Cancelar</button>
    <button mat-button cdkFocusInitial (click)="saveChanges()">
        <mat-icon color="primary">check</mat-icon> {{ 'Aceptar' }}
    </button>
</mat-dialog-actions>

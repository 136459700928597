import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '../services/utils.service';

@Pipe({
    name: 'fieldPipe',
})
export class FieldPipe implements PipeTransform {
    constructor(private _utilsService: UtilsService) {}
    transform(value: any, args?: string): string {
        return this._utilsService.fieldPipe(value, args);
    }
}

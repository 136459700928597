import { FormControl, FormGroup } from '@angular/forms';

export interface Manager {
    id?: number;
    codigo_gestor?: string;
    date_time_modified?: Date;
    photo?: string;
    photo_server_path?: string;
    gestor?: string;
    permisos?: string;
    company?: number;
    aqualia_codigo_servicio?: number;
}

export function getManagerDisplayColumns(): string[] {
    const displayedColumns: string[] = ['Código gestor', 'Gestor', 'Fecha de Modificación', 'Código de servicio de Aqualia'];
    return displayedColumns;
}

export function getManagerField(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'Código gestor':
            value = 'codigo_gestor';
            break;
        case 'Gestor':
            value = 'gestor';
            break;
        case 'Fecha de Modificación':
            value = 'date_time_modified';
            break;
        case 'Código de servicio de Aqualia':
            value = 'aqualia_codigo_servicio';
            break;
    }
    return value;
}

export function getManagerFieldName(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'codigo_gestor':
            value = 'Código gestor';
            break;
        case 'gestor':
            value = 'Gestor';
            break;
        case 'date_time_modified':
            value = 'Fecha de Modificación';
            break;
        case 'aqualia_codigo_servicio':
            value = 'Código de servicio de Aqualia';
            break;
    }
    return value;
}

export function getManagerFormControls(): FormGroup {
    const managerFormData: FormGroup = new FormGroup({
        codigo_gestor: new FormControl(),
        date_time_modified: new FormControl(),
        photo: new FormControl(),
        photo_server_path: new FormControl(),
        gestor: new FormControl(),
        id: new FormControl(),
        permisos: new FormControl(),
        company: new FormControl(),
        aqualia_codigo_servicio: new FormControl(),
    });
    return managerFormData;
}

export function getManagerFieldType(field_name: string): string {
    //in bigquery
    let value: string = '';
    switch (field_name) {
        case 'codigo_gestor':
            value = 'string';
            break;
        case 'gestor':
            value = 'string';
            break;
        case 'date_time_modified':
            value = 'Date';
            break;
        case 'id':
            value = 'number';
            break;
        case 'aqualia_codigo_servicio':
            value = 'number';
            break;
    }
    return value;
}

export function getManagerExcelFieldName(excel_field: string): string {
    let value: string = '';
    switch (excel_field.trim().toLowerCase()) {
        case 'gestor':
            value = 'gestor';
            break;
        case 'código gestor':
            value = 'codigo_gestor';
            break;
        case 'codigo gestor':
            value = 'codigo_gestor';
            break;
        case 'fecha de modificación':
            value = 'date_time_modified';
            break;
        case 'fecha de modificacion':
            value = 'date_time_modified';
            break;
        case 'Código de servicio de Aqualia':
            value = 'aqualia_codigo_servicio';
            break;
    }
    return value;
}

export function getManagerExcelExportColumns(): string[] {
    const columns = ['Código gestor', 'Gestor', 'Fecha de Modificación', 'Código de servicio de Aqualia'];
    return columns;
}

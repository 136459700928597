<mat-dialog-content class="animate__animated animate__fadeIn">
    <div [formGroup]="activationLogFormData" class="animate__animated animate__fadeIn">
        <div>
            <div class="container">
                <div class="row-content">
                    <mat-form-field style="width: 120px" appearance="fill">
                        <mat-label>Número de serie</mat-label>
                        <input
                            formControlName="counter_serial_number"
                            matInput
                            type="text"
                            placeholder="Número de serie"
                            readonly
                        />
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Número de módulo</mat-label>
                        <input
                            formControlName="counter_serial_module"
                            matInput
                            type="text"
                            placeholder="Número de módulo"
                            readonly
                        />
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Fecha de activación</mat-label>
                        <input
                            [value]="
                                activationLogFormData.get('date_time_modified')?.value
                                    | fieldPipe
                            "
                            matInput
                            type="text"
                            placeholder="Fecha de activación"
                            readonly
                        />
                    </mat-form-field>
                </div>
                <div class="row-content">
                    <mat-form-field appearance="fill" style="flex: 1">
                        <mat-label>Usuario activador</mat-label>
                        <input
                            [value]="
                                _utilsService.userPipe(activationLogFormData.get('user_id')?.value)
                            "
                            formControlName="user_id"
                            matInput
                            type="text"
                            placeholder="Usuario activador"
                            readonly
                        />
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Código de error de activación</mat-label>
                        <input
                            formControlName="error_code"
                            matInput
                            type="text"
                            placeholder="Código de error de activación"
                            readonly
                        />
                    </mat-form-field>
                </div>
                <div class="row-content">
                    <mat-form-field appearance="fill">
                        <mat-label>Respuesta</mat-label>
                        <textarea
                            matInput
                            formControlName="response_es"
                            placeholder="Respuesta..."
                            cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="2"
                            readonly
                        >
                        </textarea>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>URL Google Maps</mat-label>
                        <input
                            [value]="activationLogFormData.get('geolocation')?.value | geolocation"
                            matInput
                            type="text"
                            placeholder="URL Google Maps"
                            readonly
                        />
                        <button
                            *ngIf="activationLogFormData.controls['geolocation'].value"
                            matTooltip="Abrir ubicación de contador en Google Maps"
                            matSuffix
                            mat-icon-button
                            aria-label="Open google maps"
                        >
                            <a
                                [href]="
                                    activationLogFormData.get('geolocation')?.value | geolocation
                                "
                                target="_blank"
                            >
                                <img
                                    src="assets/img/google_maps_icon.svg"
                                    width="20"
                                    alt="Open google maps"
                                />
                            </a>
                        </button>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>

    <ngx-spinner
        [bdColor]="activationLog ? 'rgba(0, 0, 0, 0.2)' : 'rgba(255, 255, 255, 1)'"
        size="large"
        color="#368DCE"
        type="ball-scale-multiple"
        [fullScreen]="false"
        name="activationLogSpinner"
    >
    </ngx-spinner>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="activationLog">Aceptar</button>
</mat-dialog-actions>

/**
 * Created by Ing. Luis Alejandro Reyes Morales on 18/04/2024.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faBuilding, faFilePdf, faKey, faInbox } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { ApiService } from '../../../services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { NgxSpinnerService } from 'ngx-spinner';

import * as moment from 'moment';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Planning, getPlanningFormControls } from '../../../interfaces/planning';
@Component({
    selector: 'app-planning',
    templateUrl: './planning.component.html',
    styleUrls: ['./planning.component.scss']
})
export class PlanningComponent implements OnInit {
    faFilePdf = faFilePdf;
    faInbox = faInbox;
    faBuilding = faBuilding;
    faKey = faKey;
    loading: boolean = false;
    planningId: string = '';
    planning?: Planning;

    planningFormData: FormGroup = getPlanningFormControls();

    planningSubcription$?: Subscription;

    /**
     * Represents the PlanningComponent class.
     * This component is responsible for handling the planning data.
     */
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _apiService: ApiService,
        private _utilsService: UtilsService,
        private spinner: NgxSpinnerService,
    ) {
        this.planningId = data.planningId;
    }

    /**
     * Initializes the component after Angular has initialized all data-bound properties.
     * If a `planningId` is provided, it retrieves the corresponding planning document from the API
     * and populates the component with the retrieved data.
     * If the document is not found, an error message is displayed.
     * Finally, it sets up the form controls with the retrieved planning data.
     * @returns A promise that resolves when the initialization is complete.
     */
    async ngOnInit(): Promise<void> {
        if (this.planningId) {
            this.showLoading(true);

            this.planningSubcription$ = this._apiService
                .getDocument('planning', this.planningId)
                .subscribe(async (doc: any) => {
                    if (!doc) {
                        this._utilsService.openSnackBar(
                            'Error obteniendo datos de planning',
                            'error'
                        );
                        this.showLoading(false);
                        return;
                    }
                    const planning = doc as Planning;
                    planning.id = parseInt(this.planningId);
                    this.planning = planning;
                    const planningFormData = getPlanningFormControls();
                    const keys = Object.keys(planning);
                    for (let key of keys) {
                        let value: any = planning[key as keyof Planning];
                        if (value) {
                            if (this._utilsService.isValidDate(value)) {
                                value = value;
                            }
                            planningFormData.controls[key].setValue(value);
                        }
                    }
                    this.planningFormData = planningFormData;

                    this.showLoading(false);
                });
        }
    }

    /**
    * Lifecycle hook that is called when the component is destroyed.
    * It is used to perform any necessary cleanup before the component is removed from the DOM.
    */
    ngOnDestroy(): void {
        this.planningSubcription$?.unsubscribe();
    }

    /**
    * Shows or hides the loading spinner based on the provided state.
    * @param state - A boolean value indicating whether to show or hide the loading spinner.
    */
    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this.spinner.show('planningSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this.spinner.hide('planningSpinner');
        }
    }
    
    /**
     * Saves the form data by extracting values from the controls and assigning them to the `planning` object.
     * Converts moment.js objects to Date objects before assigning.
     */
    saveFormData() {
        const keys = Object.keys(this.planningFormData.controls);
        let planning: any = {};
        for (let key of keys) {
            let value = this.planningFormData.controls[key].value; //this.planning[key as keyof Planning];
            try {
                if (moment.isMoment(value)) {
                    value = value.toDate();
                }
                planning![key as keyof Planning] = value;
            } catch (error) {}
        }
        planning!['date_time_modified'] = new Date();
        this.planning = planning;
    }

    /**
     * Saves the changes made to the planning.
     * If the planning already exists, it updates the existing planning.
     * If the planning is new, it adds the planning to the database.
     * Displays a success or error message based on the result.
     */
    async saveChanges() {
        this.showLoading(true);
        this.saveFormData();
        if (this.planningId) {
            const planning: boolean = await this._apiService.updateDocument(
                'planning',
                this.planningId,
                this.planning
            );
            if (planning) {
                this._utilsService.openSnackBar('Planificación actualizada correctamente');
            } else {
                this._utilsService.openSnackBar('Error actualizando planificación', 'error');
            }
        } else {
            try {
                const planningId = await this._apiService.addDocument('planning', this.planning);
                if (planningId) {
                    this.planning!.id = planningId;
                    this._utilsService.openSnackBar('Planificación añadida correctamente');
                } else {
                    this._utilsService.openSnackBar('Error añadiendo planificación', 'error');
                }
            } catch (error) {
                this._utilsService.openSnackBar('Error añadiendo planificación', 'error');
            }
        }
        this.showLoading(false);
        this._utilsService.closePlanningDialog(this.planning!);
    }
}

/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface InformationDialogData {
    title: string;
    text: string[];
}
@Component({
    selector: 'app-information-dialog',
    templateUrl: './information-dialog.component.html',
    styleUrls: ['./information-dialog.component.scss'],
})
export class InformationDialogComponent implements OnInit {
    title?: string = 'Titulo';
    text?: string[] = ['Texto'];

    constructor(@Inject(MAT_DIALOG_DATA) public data: InformationDialogData) {
        this.title = data.title;
        this.text = data.text;
    }

    ngOnInit(): void {}
}

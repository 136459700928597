<div class="table-section">
    <div class="table-row">
        <div class="table-flex-container">
            <div>
                <app-filter-configuration
                    [filter]="filter"
                    [table_name]="table_name"
                    (sendFilterChange)="updateFilter($event)"
                >
                </app-filter-configuration>
            </div>

            <div>
                <form class="example-form">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>{{ column_name }}</mat-label>
                        <input
                            type="text"
                            [placeholder]="'Inserte ' + column_name"
                            aria-label="column_name"
                            matInput
                            [formControl]="myControl"
                            [matAutocomplete]="auto"
                        />
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                            <p *ngIf="!loading">
                                <mat-option *ngFor="let option of filteredOptions" [value]="option">
                                    {{ option }}
                                </mat-option>
                            </p>
                            <p *ngIf="loading" style="margin: 40px">
                                <mat-spinner
                                    class="animate__animated animate__fadeIn animate__slow"
                                ></mat-spinner>
                            </p>
                        </mat-autocomplete>
                    </mat-form-field>

                    <div style="margin-bottom: 10px">
                        <mat-checkbox
                            [(ngModel)]="not_empty"
                            name="not_empty"
                            color="primary"
                            (change)="checkedAll($event.checked)"
                        >
                            Todos
                        </mat-checkbox>
                    </div>

                    <div *ngIf="loading" style="display: flex; margin: 20px">
                        <p style="margin: auto">
                            <mat-spinner
                                diameter="50"
                                class="animate__animated animate__fadeIn animate__slow"
                            ></mat-spinner>
                        </p>
                    </div>

                    <div
                        *ngIf="!loading"
                        style="height: 20rem; overflow-y: auto; overflow-x: hidden"
                        infiniteScroll
                        [infiniteScrollDistance]="2"
                        [infiniteScrollThrottle]="filterLimit"
                        (scrolled)="onScroll()"
                        [scrollWindow]="false"
                    >
                        <ul>
                            <li *ngFor="let checkBox of checkBoxes">
                                <mat-checkbox
                                    [(ngModel)]="checkBox.checked"
                                    [name]="checkBox.displayName"
                                    color="primary"
                                    (change)="updateCheckBox(checkBox, $event.checked)"
                                >
                                    {{ checkBox.displayName }}
                                </mat-checkbox>
                            </li>
                        </ul>
                    </div>

                    <div mat-dialog-actions align="center">
                        <button mat-button (click)="cancelDialog()">Cancelar</button>
                        <button mat-button (click)="acceptDialog()" cdkFocusInitial>Aceptar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

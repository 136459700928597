/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { getItacFormControls, Itac } from 'src/app/interfaces/itac';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { ItacSectionData } from '../section1/section1.component';

@Component({
    selector: 'app-section2',
    templateUrl: './section2.component.html',
    styleUrls: ['./section2.component.scss'],
})
export class Section2Component implements OnInit {
    itac?: Itac;

    itacFormData: FormGroup = getItacFormControls();

    loading: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ItacSectionData,
        private _utilsService: UtilsService,
        private _apiService: ApiService,
        private _spinner: NgxSpinnerService
    ) {
        this.itac = data.itac;
        console.log(this.itac);
    }

    async saveData() {
        this.showLoading(true);
        this.saveFormData();
        this.calculatePoints();
        const result: boolean = await this._apiService.updateItac(
            this.itac!.id!.toString(),
            this.itac
        );
        this.showLoading(false);
        if (result) {
            this._utilsService.openSnackBar('Itac actualizada correctamente');
        } else {
            this._utilsService.openSnackBar('Error actualizando itac', 'error');
        }
        this._utilsService.closeSection2Dialog(result);
    }
    saveFormData() {
        const keys = Object.keys(this.itacFormData.controls);
        for (let key of keys) {
            let value = this.itacFormData.controls[key].value; //this.itac[key as keyof Itac];
            try {
                value = this._utilsService.getProcessedValue(key, value);
                this.itac![key as keyof Itac] = value;
            } catch (err) {
                console.log('============= err =============');
                console.log(err);
            }
        }
        this.itac!.date_time_modified = new Date();
    }

    ngOnInit(): void {
        this.itacFormData = this._utilsService.getFormFromItac(this.itac!);
    }

    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this._spinner.show('itacSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this._spinner.hide('itacSpinner');
        }
    }

    calculatePoints() {
        let sectionPoints: number = 0;
        const llave = this.itac?.tipo_llave;

        if (this.itac?.siempre_abierto == 'SI' || this.itac?.siempre_abierto == 'TRUE') {
            sectionPoints += 1.5;
        }
        if (llave == 'Llaves de exterior') {
            const extra_llaves = this.itac?.extras_llaves;
            if (extra_llaves == 'Allen' || extra_llaves == 'Triángulo') {
                sectionPoints += 1.5;
            } else if (
                extra_llaves == 'Cuadrado' ||
                extra_llaves == 'CABB' ||
                extra_llaves == 'Otros'
            ) {
                sectionPoints += 0.75;
            }
        } else if (llave == 'Llaves maestras') {
            const extra_llaves = this.itac?.extras_llaves;
            if (extra_llaves) {
                if (extra_llaves.includes('IBERDROLA') || extra_llaves.includes('Gas')) {
                    sectionPoints += 1.5;
                } else if (extra_llaves.includes('Del Gestor')) {
                    sectionPoints += 1;
                } else if (extra_llaves.includes('Otros')) {
                    sectionPoints += 0.75;
                }
            }
        } else if (llave == 'Llave especifica') {
            if (this.itac?.siempre_abierto == 'SI') {
                sectionPoints += -0.5;
            } else {
                sectionPoints = 0;
            }
        } else if (llave == 'Sin comprobar') {
            sectionPoints = 0;
        }

        if (sectionPoints > 1.5) {
            sectionPoints = 1.5;
        }
        this.itac!.puntuacion_seccion2 = sectionPoints;
    }
}

import { FormControl, FormGroup } from '@angular/forms';
import { MiRutaUser } from './mi-ruta-user';

export interface Team {
    cantidad_tareas: number;
    codigo_equipo_operario: string;
    date_time_created: Date;
    date_time_modified: Date;
    descripcion: string;
    equipo_operario: string;
    id: number;
    nombre_encargado: string;
    operarios: MiRutaUser[];
    telefono: string;
    company: number;
    manager: number;
}

export function getTeamDisplayColumns(): string[] {
    const displayedColumns: string[] = [
        'Código',
        'Equipo',
        'Descripción',
        'Operarios',
        'Nombre encargado',
        'Teléfono',
        'Cantidad de tareas',
        'Fecha de creación',
        'Fecha de modificación',
    ];
    return displayedColumns;
}

export function getTeamField(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'Código':
            value = 'codigo_equipo_operario';
            break;
        case 'Equipo':
            value = 'equipo_operario';
            break;
        case 'Descripción':
            value = 'descripcion';
            break;
        case 'Operarios':
            value = 'operarios';
            break;
        case 'Nombre encargado':
            value = 'nombre_encargado';
            break;
        case 'Teléfono':
            value = 'telefono';
            break;
        case 'Cantidad de tareas':
            value = 'cantidad_tareas';
            break;
        case 'Fecha de creación':
            value = 'date_time_created';
            break;
        case 'Fecha de modificación':
            value = 'date_time_modified';
            break;
    }
    return value;
}

export function getTeamFieldName(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'codigo_equipo_operario':
            value = 'Código';
            break;
        case 'equipo_operario':
            value = 'Equipo';
            break;
        case 'descripcion':
            value = 'Descripción';
            break;
        case 'operarios':
            value = 'Operarios';
            break;
        case 'nombre_encargado':
            value = 'Nombre encargado';
            break;
        case 'telefono':
            value = 'Teléfono';
            break;
        case 'cantidad_tareas':
            value = 'Cantidad de tareas';
            break;
        case 'date_time_created':
            value = 'Fecha de creación';
            break;
        case 'date_time_modified':
            value = 'Fecha de modificación';
            break;
    }
    return value;
}

export function getTeamFormControls(): FormGroup {
    const teamFormData: FormGroup = new FormGroup({
        equipo_operario: new FormControl(),
        descripcion: new FormControl(),
        operarios: new FormControl(),
        nombre_encargado: new FormControl(),
        telefono: new FormControl(),
        cantidad_tareas: new FormControl(),
        codigo_equipo_operario: new FormControl(),
        date_time_created: new FormControl(),
        manager: new FormControl(),
        company: new FormControl(),
        date_time_modified: new FormControl(),
        id: new FormControl(),
    });
    return teamFormData;
}

export function getTeamFieldType(field_name: string): string {
    //in bigquery
    let value: string = '';
    switch (field_name) {
        case 'equipo_operario':
            value = 'string';
            break;
        case 'descripcion':
            value = 'string';
            break;
        case 'operarios':
            value = 'string_array';
            break;
        case 'nombre_encargado':
            value = 'string';
            break;
        case 'telefono':
            value = 'string';
            break;
        case 'cantidad_tareas':
            value = 'number';
            break;
        case 'codigo_equipo_operario':
            value = 'string';
            break;
        case 'date_time_created':
            value = 'Date';
            break;
        case 'date_time_modified':
            value = 'Date';
            break;
        case 'id':
            value = 'number';
            break;
    }
    return value;
}

export function getTeamExcelFieldName(excel_field: string): string {
    let value: string = '';
    switch (excel_field.trim().toLowerCase()) {
        case 'equipo operario':
            value = 'equipo_operario';
            break;
        case 'equipo':
            value = 'equipo_operario';
            break;
        case 'descripcion':
            value = 'descripcion';
            break;
        case 'descripción':
            value = 'descripcion';
            break;
        case 'operarios':
            value = 'operarios';
            break;
        case 'nombre encargado':
            value = 'nombre_encargado';
            break;
        case 'encargado':
            value = 'nombre_encargado';
            break;
        case 'teléfono':
            value = 'telefono';
            break;
        case 'cantidad tareas':
            value = 'cantidad_tareas';
            break;
        case 'código':
            value = 'codigo_equipo_operario';
            break;
        case 'codigo':
            value = 'codigo_equipo_operario';
            break;
        case 'código equipo_operario':
            value = 'codigo_equipo_operario';
            break;
        case 'codigo equipo_operario':
            value = 'codigo_equipo_operario';
            break;
        case 'código equipo operario':
            value = 'codigo_equipo_operario';
            break;
        case 'codigo equipo operario':
            value = 'codigo_equipo_operario';
            break;
        case 'fecha de creación':
            value = 'date_time_created';
            break;
        case 'fecha de creacion':
            value = 'date_time_created';
            break;
        case 'fecha de modificación':
            value = 'date_time_modified';
            break;
        case 'fecha de modificacion':
            value = 'date_time_modified';
            break;
    }
    return value;
}

export function getTeamExcelExportColumns(): string[] {
    const columns = [
        'Código',
        'Equipo',
        'Descripción',
        'Operarios',
        'Nombre encargado',
        'Teléfono',
        'Cantidad de tareas',
        'Fecha de creación',
        'Fecha de modificación',
    ];
    return columns;
}

/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faBuilding, faFilePdf, faKey, faInbox } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { ApiService } from '../../../services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessagingService } from 'src/app/services/messaging.service';

import * as moment from 'moment';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Cause, getCauseFormControls } from 'src/app/interfaces/cause';

@Component({
    selector: 'app-cause',
    templateUrl: './cause.component.html',
    styleUrls: ['./cause.component.scss'],
})
export class CauseComponent implements OnInit {
    faFilePdf = faFilePdf;
    faInbox = faInbox;
    faBuilding = faBuilding;
    faKey = faKey;
    loading: boolean = false;
    causeId: string = '';
    cause?: Cause;

    causeFormData: FormGroup = getCauseFormControls();

    causeSubcription$?: Subscription;

    results: string[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _apiService: ApiService,
        private _utilsService: UtilsService,
        private spinner: NgxSpinnerService,
        private _messagingService: MessagingService
    ) {
        this.causeId = data.causeId;
        // this.activatedRoute.params.subscribe((params)=>{
        //   this.causeId = params['id'];
        // });
    }

    async ngOnInit(): Promise<void> {
        if (this.causeId) {
            this.showLoading(true);

            this.causeSubcription$ = this._apiService
                .getDocument('cause', this.causeId)
                .subscribe(async (doc: any) => {
                    if (!doc) {
                        this._utilsService.openSnackBar('Error obteniendo datos de cause', 'error');
                        this.showLoading(false);
                        return;
                    }
                    const cause = doc as Cause;
                    cause.id = parseInt(this.causeId);

                    this.cause = cause;

                    console.log(this.cause);

                    const causeFormData = getCauseFormControls();
                    const keys = Object.keys(cause);
                    for (let key of keys) {
                        let value: any = cause[key as keyof Cause];
                        if (value) {
                            if (this._utilsService.isValidDate(value)) {
                                value = value;
                            }
                            causeFormData.controls[key].setValue(value);
                        }
                    }
                    this.causeFormData = causeFormData;

                    this.showLoading(false);

                    this.getResults();
                });
        }
    }

    async getResults() {
        const obs = await this._apiService.getResults();
        this.results = obs.map((result) => result.codigo_resultado);
    }

    ngOnDestroy(): void {
        this.causeSubcription$?.unsubscribe();
    }

    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this.spinner.show('causeSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this.spinner.hide('causeSpinner');
        }
    }

    saveFormData() {
        const keys = Object.keys(this.causeFormData.controls);
        let cause: any = {};
        for (let key of keys) {
            let value = this.causeFormData.controls[key].value; //this.cause[key as keyof Cause];
            try {
                if (moment.isMoment(value)) {
                    value = value.toDate();
                }
                cause![key as keyof Cause] = value;
            } catch (error) {}
        }
        cause!['date_time_modified'] = new Date();
        this.cause = cause;
        // console.log(this.cause);
    }

    async saveChanges() {
        this.showLoading(true);
        this.saveFormData();
        if (this.causeId) {
            const result: boolean = await this._apiService.updateDocument(
                'cause',
                this.causeId,
                this.cause
            );
            if (result) {
                this._utilsService.openSnackBar('Causa actualizada correctamente');
            } else {
                this._utilsService.openSnackBar('Error actualizando causa', 'error');
            }
        } else {
            try {
                const causeId = await this._apiService.addDocument('cause', this.cause);
                if (causeId) {
                    this.cause!.id = causeId;
                    this._utilsService.openSnackBar('Causa añadido correctamente');
                } else {
                    this._utilsService.openSnackBar('Error añadiendo causa', 'error');
                }
            } catch (error) {
                this._utilsService.openSnackBar('Error añadiendo causa', 'error');
            }
        }
        this.showLoading(false);
        this._utilsService.closeCauseDialog(this.cause!);
    }
}

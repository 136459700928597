/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faBuilding, faFilePdf, faKey, faInbox } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { ApiService } from '../../../services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessagingService } from 'src/app/services/messaging.service';

import * as moment from 'moment';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Caliber, getCaliberFormControls } from 'src/app/interfaces/caliber';

@Component({
    selector: 'app-caliber',
    templateUrl: './caliber.component.html',
    styleUrls: ['./caliber.component.scss'],
})
export class CaliberComponent implements OnInit {
    faFilePdf = faFilePdf;
    faInbox = faInbox;
    faBuilding = faBuilding;
    faKey = faKey;
    loading: boolean = false;
    caliberId: string = '';
    caliber?: Caliber;

    caliberFormData: FormGroup = getCaliberFormControls();

    caliberSubcription$?: Subscription;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _apiService: ApiService,
        private _utilsService: UtilsService,
        private spinner: NgxSpinnerService,
        private _messagingService: MessagingService
    ) {
        this.caliberId = data.caliberId;
        // this.activatedRoute.params.subscribe((params)=>{
        //   this.caliberId = params['id'];
        // });
    }

    async ngOnInit(): Promise<void> {
        if (this.caliberId) {
            this.showLoading(true);

            this.caliberSubcription$ = this._apiService
                .getDocument('caliber', this.caliberId)
                .subscribe(async (doc: any) => {
                    if (!doc) {
                        this._utilsService.openSnackBar(
                            'Error obteniendo datos de caliber',
                            'error'
                        );
                        this.showLoading(false);
                        return;
                    }
                    const caliber = doc as Caliber;
                    caliber.id = parseInt(this.caliberId);

                    this.caliber = caliber;

                    const caliberFormData = getCaliberFormControls();
                    const keys = Object.keys(caliber);
                    for (let key of keys) {
                        let value: any = caliber[key as keyof Caliber];
                        if (value) {
                            if (this._utilsService.isValidDate(value)) {
                                value = value;
                            }
                            caliberFormData.controls[key].setValue(value);
                        }
                    }
                    this.caliberFormData = caliberFormData;

                    this.showLoading(false);
                });
        }
    }

    ngOnDestroy(): void {
        this.caliberSubcription$?.unsubscribe();
    }

    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this.spinner.show('caliberSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this.spinner.hide('caliberSpinner');
        }
    }

    saveFormData() {
        const keys = Object.keys(this.caliberFormData.controls);
        let caliber: any = {};
        for (let key of keys) {
            let value = this.caliberFormData.controls[key].value; //this.caliber[key as keyof Caliber];
            try {
                if (moment.isMoment(value)) {
                    value = value.toDate();
                }
                caliber![key as keyof Caliber] = value;
            } catch (error) {}
        }
        caliber!['date_time_modified'] = new Date();
        this.caliber = caliber;
        // console.log(this.caliber);
    }

    async saveChanges() {
        this.showLoading(true);
        this.saveFormData();
        if (this.caliberId) {
            const result: boolean = await this._apiService.updateDocument(
                'caliber',
                this.caliberId,
                this.caliber
            );
            if (result) {
                this._utilsService.openSnackBar('Calibre actualizada correctamente');
            } else {
                this._utilsService.openSnackBar('Error actualizando calibre', 'error');
            }
        } else {
            try {
                const caliberId = await this._apiService.addDocument('caliber', this.caliber);
                if (caliberId) {
                    this.caliber!.id = caliberId;
                    this._utilsService.openSnackBar('Calibre añadido correctamente');
                } else {
                    this._utilsService.openSnackBar('Error añadiendo calibre', 'error');
                }
            } catch (error) {
                this._utilsService.openSnackBar('Error añadiendo calibre', 'error');
            }
        }
        this.showLoading(false);
        this._utilsService.closeCaliberDialog(this.caliber!);
    }
}

import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '../services/utils.service';

@Pipe({
    name: 'agrupationPipe',
})
export class AgrupationPipe implements PipeTransform {
    constructor(private _utilsService: UtilsService) {}

    transform(value: string | number, ...args: unknown[]): string {
        let agrupationName = this._utilsService.agrupationPipe(value);
        return agrupationName;
    }
}

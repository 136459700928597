<mat-dialog-content class=" animate__animated animate__fadeIn">
    <div [formGroup]="planningDetailFormData"
    class=" animate__animated animate__fadeIn">

        <div>

            <div class="container">

                <div class="row bd-highlight" class="row-content-center">

                    <div>
                        <div style="margin-top: 5px; margin-bottom: 30px; margin-left: 5px;">                        
                            <p style="font-size: large;">Datos de planificación</p>
                        </div> 
                        <div>
                            <div class="row-content">
                                <mat-form-field appearance="fill">
                                    <mat-label>Código planificación</mat-label>
                                    <mat-select formControlName="codigo_planning">
                                        <mat-option
                                            *ngFor="let option of plannings"
                                            [value]="option"
                                            >{{ option }}</mat-option
                                        >
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field style="width: 120px;" appearance="fill">
                                    <mat-label>Código numérico</mat-label>
                                    <input formControlName="numeric_code" matInput type="text" placeholder="Código numérico">
                                </mat-form-field>

                                <mat-form-field style="width: 120px;" appearance="fill">
                                    <mat-label>Código detalle</mat-label>
                                    <input formControlName="codigo_planning_details" matInput type="text" placeholder="Código planificación">
                                </mat-form-field>
                            </div>
                            
                            <div class="row-content">
                                <mat-form-field appearance="fill">
                                    <mat-label>Explicación</mat-label>
                                    <input formControlName="explanation" matInput type="text" placeholder="Explicación">
                                </mat-form-field>
                            </div>
                            
                            <div class="row-content">
                                <mat-form-field appearance="fill">
                                    <mat-label>Detalle 1</mat-label>
                                    <input formControlName="detail1" matInput type="text" placeholder="Detalle 1">
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Detalle 2</mat-label>
                                    <input formControlName="detail2" matInput type="text" placeholder="Detalle 2">
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Detalle 3</mat-label>
                                    <input formControlName="detail3" matInput type="text" placeholder="Detalle 3">
                                </mat-form-field>
                            </div>

                            <div class="row-content">
                                <mat-form-field appearance="fill">
                                    <mat-label>Detalle 4</mat-label>
                                    <input formControlName="detail4" matInput type="text" placeholder="Detalle 4">
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Detalle 5</mat-label>
                                    <input formControlName="detail5" matInput type="text" placeholder="Detalle 5">
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Detalle 6</mat-label>
                                    <input formControlName="detail6" matInput type="text" placeholder="Detalle 6">
                                </mat-form-field>
                            </div>

                            <div class="row-content">
                                <mat-form-field appearance="fill">
                                    <mat-label>Texto completo</mat-label>
                                    <input formControlName="planning_details" matInput type="text" placeholder="Texto completo">
                                </mat-form-field>
                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>

    <ngx-spinner [bdColor] = "(planningDetail)?'rgba(0, 0, 0, 0.2)': 'rgba(255, 255, 255, 1)'" size = "large" color = "#368DCE" 
    type = "ball-scale-multiple" [fullScreen] = "false" name="planningDetailSpinner">
    </ngx-spinner>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="planningDetail">Cancelar</button>
    <button mat-button cdkFocusInitial
    matTooltip="Guardar cambios" 
    (click)="saveChanges()"> 
        <mat-icon color="primary">check</mat-icon> {{ (planningDetailId)?'Actualizar':'Añadir' }}
    </button>
</mat-dialog-actions>


<mat-dialog-content class=" animate__animated animate__fadeIn">
    <div [formGroup]="infoFormData"
    class=" animate__animated animate__fadeIn">

        <div>

            <div class="container">

                <div class="row bd-highlight" class="row-content-center">

                    <div>
                        <div style="margin-top: 5px; margin-bottom: 30px; margin-left: 5px;">                        
                            <p style="font-size: large;">Datos de info</p>
                        </div> 
                        <div>

                            <div class="row-content">

                                <mat-form-field style="width: 80px;" appearance="fill">
                                    <mat-label>Código</mat-label>
                                    <input formControlName="codigo_info" matInput type="text" placeholder="Código info...">
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Último ID exportación</mat-label>
                                    <input formControlName="lastIDExportacion" matInput type="number" placeholder="Último ID exportación...">
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Último ID orden</mat-label>
                                    <input formControlName="lastIDOrden" matInput type="number" placeholder="Último ID orden...">
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Último ID SAT</mat-label>
                                    <input formControlName="lastIDSAT" matInput type="number" placeholder="Último ID SAT...">
                                </mat-form-field>

                            </div>
                            
                            <div class="row-content">
                                <mat-form-field appearance="fill">
                                    <mat-label>Información</mat-label>
                                    <textarea matInput formControlName="info"
                                        placeholder="Información..."
                                        cdkTextareaAutosize
                                        #autosize="cdkTextareaAutosize"
                                        cdkAutosizeMinRows="2">
                                    </textarea>
                                </mat-form-field>   
                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>

    <ngx-spinner [bdColor] = "(info)?'rgba(0, 0, 0, 0.2)': 'rgba(255, 255, 255, 1)'" size = "large" color = "#368DCE" 
    type = "ball-scale-multiple" [fullScreen] = "false" name="infoSpinner">
    </ngx-spinner>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="info">Cancelar</button>
    <button mat-button cdkFocusInitial
    matTooltip="Guardar cambios" 
    (click)="saveChanges()"> 
        <mat-icon color="primary">check</mat-icon> {{ (infoId)?'Actualizar':'Añadir' }}
    </button>
</mat-dialog-actions>


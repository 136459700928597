// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    // url_server: 'https://nest.mywaterroute.xyz',
    url_server: 'https://nest.mirutaaguas.com',
    // url_server: 'http://localhost:3000',
    email_js_service_id: 'service_g5vpwpc',
    email_js_template: 'template_prampqu',
    email_js_public_key: 'w1In5DJwac-12HLwl',
    firebaseConfig: {
       apiKey: "AIzaSyA8U4lFgGoJ5CURrFqf15kchJrKcrBj_R8",
       authDomain: "deft-apparatus-357415.firebaseapp.com",
       projectId: "deft-apparatus-357415",
       storageBucket: "deft-apparatus-357415.appspot.com",
       messagingSenderId: "248709778553",
       appId: "1:248709778553:web:d196c65b24aa7b6c3998a8",
       measurementId: "G-87X25N1PCB"
    },
    fcmElectronSenderId: "248709778553",
    dev_user: 'Alejandro',
    dev_password: '26194Larm*',
    dev_server: 'https://nest.mywaterroute.xyz' 
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

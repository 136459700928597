/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faBuilding, faFilePdf, faKey, faInbox } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { ApiService } from '../../../services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessagingService } from 'src/app/services/messaging.service';

import * as moment from 'moment';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Result, getResultFormControls } from 'src/app/interfaces/result';

@Component({
    selector: 'app-result',
    templateUrl: './result.component.html',
    styleUrls: ['./result.component.scss'],
})
export class ResultComponent implements OnInit {
    faFilePdf = faFilePdf;
    faInbox = faInbox;
    faBuilding = faBuilding;
    faKey = faKey;
    loading: boolean = false;
    resultId: string = '';
    result?: Result;

    resultFormData: FormGroup = getResultFormControls();

    resultSubcription$?: Subscription;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _apiService: ApiService,
        private _utilsService: UtilsService,
        private spinner: NgxSpinnerService,
        private _messagingService: MessagingService
    ) {
        this.resultId = data.resultId;
        // this.activatedRoute.params.subscribe((params)=>{
        //   this.resultId = params['id'];
        // });
    }

    async ngOnInit(): Promise<void> {
        if (this.resultId) {
            this.showLoading(true);

            this.resultSubcription$ = this._apiService
                .getDocument('result', this.resultId)
                .subscribe(async (doc: any) => {
                    if (!doc) {
                        this._utilsService.openSnackBar(
                            'Error obteniendo datos de result',
                            'error'
                        );
                        this.showLoading(false);
                        return;
                    }
                    const result = doc as Result;
                    result.id = parseInt(this.resultId);

                    this.result = result;

                    console.log(this.result);

                    const resultFormData = getResultFormControls();
                    const keys = Object.keys(result);
                    for (let key of keys) {
                        let value: any = result[key as keyof Result];
                        if (value) {
                            if (this._utilsService.isValidDate(value)) {
                                value = value;
                            }
                            resultFormData.controls[key].setValue(value);
                        }
                    }
                    this.resultFormData = resultFormData;

                    this.showLoading(false);
                });
        }
    }

    ngOnDestroy(): void {
        this.resultSubcription$?.unsubscribe();
    }

    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this.spinner.show('resultSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this.spinner.hide('resultSpinner');
        }
    }

    saveFormData() {
        const keys = Object.keys(this.resultFormData.controls);
        let result: any = {};
        for (let key of keys) {
            let value = this.resultFormData.controls[key].value; //this.result[key as keyof Result];
            try {
                if (moment.isMoment(value)) {
                    value = value.toDate();
                }
                result![key as keyof Result] = value;
            } catch (error) {}
        }
        result!['date_time_modified'] = new Date();
        this.result = result;
        // console.log(this.result);
    }

    async saveChanges() {
        this.showLoading(true);
        this.saveFormData();
        if (this.resultId) {
            const result: boolean = await this._apiService.updateDocument(
                'result',
                this.resultId,
                this.result
            );
            if (result) {
                this._utilsService.openSnackBar('Resultado actualizada correctamente');
            } else {
                this._utilsService.openSnackBar('Error actualizando resultado', 'error');
            }
        } else {
            try {
                const resultId = await this._apiService.addDocument('result', this.result);
                if (resultId) {
                    this.result!.id = resultId;
                    this._utilsService.openSnackBar('Resultado añadido correctamente');
                } else {
                    this._utilsService.openSnackBar('Error añadiendo resultado', 'error');
                }
            } catch (error) {
                this._utilsService.openSnackBar('Error añadiendo resultado', 'error');
            }
        }
        this.showLoading(false);
        this._utilsService.closeResultDialog(this.result!);
    }
}

/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, OnInit } from '@angular/core';
import {
    getTypeRadiusField,
    TypeRadius,
    getTypeRadiusExcelExportColumns,
    getTypeRadiusExcelFieldName,
    getTypeRadiusFieldType,
    getTypeRadiusDisplayColumns,
} from '../../../interfaces/type-radius';
import { NgxSpinnerService } from 'ngx-spinner';
import { WindowRefService } from '../../../services/window-ref.service';
import { UtilsService } from '../../../services/utils.service';
import { ApiService } from '../../../services/api.service';
import { MessagingService } from '../../../services/messaging.service';
import { faInbox } from '@fortawesome/free-solid-svg-icons';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { Moment } from 'moment';
import * as XLSX from 'xlsx';

@Component({
    selector: 'app-type-radiuss',
    templateUrl: './type-radiuss.component.html',
    styleUrls: ['./type-radiuss.component.scss'],
})
export class TypeRadiussComponent implements OnInit {
    loading: boolean = true;
    tableName: string = 'type_radius';
    typeRadiussInPage: TypeRadius[] = [];
    dataSource: MatTableDataSource<TypeRadius> = new MatTableDataSource();

    length = 0; //task count in current table
    pageSize = 200; //limit of query
    lastPageIndex = 0;
    pageSizeOptions: number[] = [10, 50, 100, 200, 500];

    displayedColumns: string[] = getTypeRadiusDisplayColumns();
    fixedColumns = [];
    displayedColumnsField: string[] = this.displayedColumns.map((displayedColumn: string) =>
        getTypeRadiusField(displayedColumn)
    );
    clickedRows = new Set<TypeRadius>();

    lastSelectedRow: number = -1;

    filteredColumn?: string;
    orderedColumn?: string;

    loadingText = 'Cargando...';

    faInbox = faInbox;

    constructor(
        private _apiService: ApiService,
        private _utilsService: UtilsService,
        private _windowRefService: WindowRefService,
        private _spinner: NgxSpinnerService,
        private _messagingService: MessagingService
    ) {}

    async ngOnInit(): Promise<void> {
        await this.getTypeRadiuss();
    }

    async fileEvent(event: any) {
        if (event['file_option'] == 'Importar radios') {
            this.processExcelFile(event);
        }
    }
    async processExcelFile(event: any) {
        this._spinner.show();
        this.loadingText = `Cargando ...`;
        //TODO: add typeRadiuss from excel
        let workBook: XLSX.WorkBook;
        let jsonData = null;
        const reader = new FileReader();
        const file = event['file'].target.files[0];
        reader.onload = async (_) => {
            this._spinner.show();
            this.loadingText = `Procesando radios ...`;
            const data = reader.result;
            workBook = XLSX.read(data, { type: 'binary' });
            jsonData = workBook.SheetNames.reduce((initial: any, name: any) => {
                const sheet = workBook.Sheets[name];
                initial[name] = XLSX.utils.sheet_to_json(sheet);
                return initial;
            }, {});
            let sheets: string[] = [];
            let arrayTypeRadiuss: TypeRadius[] = [];

            Object.keys(jsonData).forEach((key: string) => {
                sheets.push(key);
            });
            for (let sheet of sheets) {
                for (let jsonTypeRadius of jsonData[sheet]) {
                    let typeRadiusData: any = {};
                    Object.keys(jsonTypeRadius).forEach((key) => {
                        let field: string = getTypeRadiusExcelFieldName(key);
                        if (this._utilsService.isFieldValid(field)) {
                            let value = jsonTypeRadius[key];
                            try {
                                if (typeof value === 'number') {
                                    if (getTypeRadiusFieldType(field) == 'number') {
                                        typeRadiusData[`${field}`] = value;
                                    } else {
                                        typeRadiusData[`${field}`] = value.toString().trim();
                                    }
                                } else if (
                                    typeof value === 'string' &&
                                    this._utilsService.isFieldValid(value)
                                ) {
                                    if (getTypeRadiusFieldType(field) == 'string') {
                                        typeRadiusData[`${field}`] = value.trim();
                                    } else if (getTypeRadiusFieldType(field) == 'boolean') {
                                        typeRadiusData[`${field}`] =
                                            value.trim().toUpperCase() == 'SI' ? true : false;
                                    } else {
                                        typeRadiusData[`${field}`] = parseInt(value);
                                    }
                                }
                            } catch (error) {
                                console.log(value);
                            }
                        }
                    });
                    let typeRadius = typeRadiusData as TypeRadius;
                    if (
                        typeRadius &&
                        this._utilsService.isFieldValid(typeRadius.codigo_radio)
                    ) {
                        typeRadius.codigo_radio = this._utilsService.addCeros(
                            typeRadius.codigo_radio,
                            3
                        );
                        arrayTypeRadiuss.push(typeRadius);
                    }
                }
            }
            console.log('============= arrayTypeRadiuss =============');
            console.log(arrayTypeRadiuss);
            const result = await this.importTypeRadiuss(arrayTypeRadiuss);
            this._spinner.hide();
        };
        reader.readAsBinaryString(file);

        this._spinner.hide();
    }

    async importTypeRadiuss(typeRadiuss: TypeRadius[]) {
        this._spinner.show();
        this.loadingText = `Añadiendo radios ...`;
        let i = 0,
            errorIds = [];
        for (let typeRadius of typeRadiuss) {
            this.loadingText = `Añadiendo radios ${++i} de ${typeRadiuss.length}`;
            if (!(await this._apiService.addDocument('type_radius', typeRadius))) {
                errorIds.push(typeRadius.codigo_radio);
            }
        }
        this._spinner.hide();
        if (errorIds.length > 0) {
            this._utilsService.openSnackBar(`Hubo errores añadiendo radios`, 'error');
        } else {
            this._utilsService.openSnackBar(`Calibres subidos correctamente`);
        }
        await this.getTypeRadiuss();
        return !(errorIds.length > 0);
    }

    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this._spinner.show('innerSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this._spinner.hide('innerSpinner');
        }
    }

    setTypeRadiussInTable(typeRadiuss: TypeRadius[]) {
        this.typeRadiussInPage = typeRadiuss;
        // this.dataSource.data = typeRadiuss.slice(0, this.sliceSize);
        this.dataSource.data = typeRadiuss;
        // this.dataSource = new TableVirtualScrollDataSource(typeRadiuss);// infiniteScroll
        // console.log(this.dataSource);
        this.showLoading(false);
    }

    async getTypeRadiuss() {
        this.setTypeRadiussInTable(await this.selectTypeRadiuss());
    }
    async selectTypeRadiuss() {
        this.showLoading(true);
        let docs = [];
        try {
            docs = await this._apiService.getDocuments<TypeRadius>('type_radius', undefined, [
                'codigo_radio',
                'asc',
            ]);
        } catch (err) {
            console.log('============= err =============');
            console.log(err);
        }
        this.length = docs.length;
        return docs;
        // undefined,/*where*/undefined, /*order_clause*/undefined, '0', this.pageSize.toString());
    }

    searchValue(event: any) {
        const filterValue: string = event;
        this.dataSource!.filter = filterValue.trim().toLowerCase();
    }

    async deleteTypeRadiuss() {
        let errorIds = [];
        if (this.clickedRows.size == 0) {
            this._utilsService.openSnackBar('Debe seleccionar al menos una radio', 'warning');
            return;
        }
        const result = await this._utilsService.openQuestionDialog(
            'Confirmación',
            '¿Desea eliminar las radios seleccionadas?'
        );
        if (result) {
            this._spinner.show();
            this.loadingText = `Eliminando radios ...`;
            let rowIndexes = new Set<number>();
            const oldDataSource = this.dataSource!.data;
            this.clickedRows.forEach(async (row) => {
                console.log(row.id);

                const index = this.dataSource!.data.indexOf(row, 0);
                if (index > -1) {
                    rowIndexes.add(index);
                }
            });
            let typeRadiuss = [];
            let deleteCount = 0;
            for (let i = 0; i < oldDataSource.length; i++) {
                if (!rowIndexes.has(i)) {
                    typeRadiuss.push(oldDataSource![i]);
                } else {
                    this.loadingText = `Eliminando radios ${++deleteCount} de ${rowIndexes.size}`;
                    if (
                        !(await this._apiService.deleteDocument(
                            'type_radius',
                            oldDataSource[i].id!.toString()
                        ))
                    ) {
                        console.log('****************** deleteTypeRadius error *************');
                        errorIds.push(oldDataSource[i].id);
                    } else {
                        console.log('****************** deleteTypeRadius success *************');
                    }
                }
            }
            if (errorIds.length > 0) {
                this._utilsService.openSnackBar(`Hubo errores eliminando radios`, 'error');
            } else {
                this._utilsService.openSnackBar(`Radios eliminados correctamente`);
            }
            this.length -= rowIndexes.size;
            this.setTypeRadiussInTable(typeRadiuss);
            this.clickedRows.clear();
            this._spinner.hide();
        }
    }

    async filterBy(column: string) {
        this.filteredColumn = getTypeRadiusField(column);
        if (
            getTypeRadiusFieldType(this.filteredColumn) == 'Date' ||
            getTypeRadiusFieldType(this.filteredColumn) == 'Timestamp'
        ) {
            const dates = await this._utilsService.openDateRangeSelectorDialog(
                'Seleccione rango de fechas'
            );
            this.onDateSelected(dates);
        } else {
            const options: any[] = this.dataSource.data.map(
                (typeRadius: TypeRadius) => typeRadius[this.filteredColumn as keyof TypeRadius]
            );
            const result = await this._utilsService.openSelectorDialog(
                'Seleccione opción',
                options
            );
            if (result) {
                 
                let docs = [];
                try {
                    docs = await this._apiService.getDocuments<TypeRadius>('type_radius', [
                        [this.filteredColumn, '==', result],
                    ]);
                } catch (err) {
                    console.log('============= err =============');
                    console.log(err);
                }
                this.length = docs.length;
                this.setTypeRadiussInTable(docs);
            }
        }
    }
    async onDateSelected(dateRange: Date[]) {
        if (dateRange) {
            const startDate: Moment = moment(dateRange[0]);
            const startDateString = startDate.format('YYYY-MM-DD HH:mm:ss');
            let endDate: Moment = moment(dateRange[1]);
            if (!endDate) {
                endDate = startDate;
            }
            const endDateString = endDate.add(1, 'days').format('YYYY-MM-DD HH:mm:ss'); //plus one to filter range
            const values = [startDateString, endDateString];
            let docs = [];
            try {
                docs = await this._apiService.getDocuments<TypeRadius>('type_radius', [
                    [this.filteredColumn!, '>=', startDate.toDate()],
                    [this.filteredColumn!, '<', endDate.toDate()],
                ]);
            } catch (err) {
                console.log('============= err =============');
                console.log(err);
            }
            this.length = docs.length;
            this.setTypeRadiussInTable(docs);
        } else {
            this._utilsService.openSnackBar('Rango fechas invalido', 'error');
        }
    }

    async orderBy(event: any) {
        const column = event.column;
        let orderType = event.orderType;
        if (orderType.toLowerCase().includes('asc')) {
            orderType = 'asc';
        } else {
            orderType = 'desc';
        }
        const orderedColumn = getTypeRadiusField(column);

        this.showLoading(true);
        const typeRadiuss = await this._apiService.getDocuments<TypeRadius>(
            'type_radius',
            undefined,
            [orderedColumn, orderType]
        );
        this.setTypeRadiussInTable(typeRadiuss);
    }

    async addNewRow(event: any) {
        console.log('**************** addNewRow ***************');
        const typeRadius = await this._utilsService.openTypeRadiusDialog('');
        console.log(typeRadius);
        if (typeRadius) {
            this.dataSource.data.push(typeRadius);
            const typeRadiuss = this.dataSource.data;
            this.dataSource.data = [];
            this.dataSource.data = typeRadiuss;
        }
    }
    async doubleClickedRow(row: any) {
        console.log('**************** doubleClickedRow ***************');
        console.log(row.id);
        const typeRadius = await this._utilsService.openTypeRadiusDialog(row.id);
        if (typeRadius) {
            this.dataSource.data[row.rowIndex] = typeRadius;
            const typeRadiuss = this.dataSource.data;
            this.dataSource.data = [];
            this.dataSource.data = typeRadiuss;
        }
    }

    clickedRow(receivedEvent: any) {
        const row = receivedEvent.row;
        const event = receivedEvent.event;
        const rowIndex = receivedEvent.rowIndex;

        const previousRow = this.lastSelectedRow;
        this.lastSelectedRow = rowIndex;

        if (event.button === 0) {
            if (!event.ctrlKey && !event.shiftKey) {
                this.clickedRows.clear();
                this.toggleRow(row);
                // console.log('**************** !event.ctrlKey && !event.shiftKey ***************');
            } else if (event.ctrlKey) {
                this.toggleRow(row);
                // console.log('**************** event.ctrlKey ***************');
            }
            if (event.shiftKey) {
                this.selectRowsBetweenIndexes(previousRow, rowIndex);
                console.log('**************** event.shiftKey ***************');
            }
        }
        if (this._windowRefService.nativeWindow.getSelection) {
            //remove selection in table with shift
            if (this._windowRefService.nativeWindow.getSelection().empty) {
                // Chrome
                this._windowRefService.nativeWindow.getSelection().empty();
            } else if (this._windowRefService.nativeWindow.getSelection().removeAllRanges) {
                // Firefox
                this._windowRefService.nativeWindow.getSelection().removeAllRanges();
            }
        }
    }
    selectRowsBetweenIndexes(lastSelectedRow: number, rowIndex: number) {
        let start, end;
        if (rowIndex > lastSelectedRow) {
            start = lastSelectedRow;
            end = rowIndex;
        } else {
            end = lastSelectedRow;
            start = rowIndex;
        }
        for (let i = start; i <= end; i++) {
            this.clickedRows.add(this.dataSource!.data[i]);
        }
    }
    toggleRow(row: any) {
        if (this.clickedRows.has(row)) {
            this.clickedRows.delete(row);
        } else {
            this.clickedRows.add(row);
        }
    }

    exportTypeRadiussInTable() {
        this.exportExcel(this.dataSource!.data);
    }
    exportExcel(waterTasks: any) {
        let excelFormatTasks = [];
        for (let waterTask of waterTasks) {
            let task: any = {};
            const columns = getTypeRadiusExcelExportColumns();
            columns.forEach((column) => {
                task[`${column}`] = this._utilsService.tableDataPipe(
                    waterTask[getTypeRadiusExcelFieldName(column)]
                );
            });
            excelFormatTasks.push(task);
        }
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelFormatTasks);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        /* save to file */
        XLSX.writeFile(wb, 'Radios_Exportados.xlsx');
    }
}

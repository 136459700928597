/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faBuilding, faFilePdf, faKey, faInbox } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { ApiService } from '../../services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { NgxSpinnerService } from 'ngx-spinner';

import * as moment from 'moment';
import { getWaterRouteFormControls, WaterRoute } from 'src/app/interfaces/water-route';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-water-route',
    templateUrl: './water-route.component.html',
    styleUrls: ['./water-route.component.scss'],
})
export class WaterRouteComponent implements OnInit {
    faFilePdf = faFilePdf;
    faInbox = faInbox;
    faBuilding = faBuilding;
    faKey = faKey;
    loading: boolean = false;
    waterRouteId: string = '';
    waterRoute?: WaterRoute;

    waterRouteFormData: FormGroup = getWaterRouteFormControls();

    waterRouteSubcription$?: Subscription;

    results: string[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _apiService: ApiService,
        private _utilsService: UtilsService,
        private spinner: NgxSpinnerService
    ) {
        this.waterRouteId = data.waterRouteId;
    }

    async ngOnInit(): Promise<void> {
        if (this.waterRouteId) {
            this.showLoading(true);

            this.waterRouteSubcription$ = this._apiService
                .getDocument('water-route', this.waterRouteId)
                .subscribe(async (doc: any) => {
                    if (!doc) {
                        this._utilsService.openSnackBar(
                            'Error obteniendo datos de waterRoute',
                            'error'
                        );
                        this.showLoading(false);
                        return;
                    }
                    const waterRoute = doc as WaterRoute;
                    waterRoute.id =  parseInt(this.waterRouteId);

                    this.waterRoute = waterRoute;

                    const waterRouteFormData = getWaterRouteFormControls();
                    const keys = Object.keys(waterRoute);
                    for (let key of keys) {
                        let value: any = waterRoute[key as keyof WaterRoute];
                        if (value) {
                            if (this._utilsService.isValidDate(value)) {
                                value = value;
                            }
                            waterRouteFormData.controls[key].setValue(value);
                        }
                    }
                    this.waterRouteFormData = waterRouteFormData;

                    this.showLoading(false);
                });
        }
    }

    ngOnDestroy(): void {
        this.waterRouteSubcription$?.unsubscribe();
    }

    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this.spinner.show('waterRouteSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this.spinner.hide('waterRouteSpinner');
        }
    }

    saveFormData() {
        const keys = Object.keys(this.waterRouteFormData.controls);
        let waterRoute: any = {};
        for (let key of keys) {
            let value = this.waterRouteFormData.controls[key].value; //this.waterRoute[key as keyof WaterRoute];
            try {
                if (moment.isMoment(value)) {
                    value = value.toDate();
                }
                waterRoute![key as keyof WaterRoute] = value;
            } catch (error) {}
        }
        waterRoute!['date_time_modified'] = new Date();
        this.waterRoute = waterRoute;
        // console.log(this.waterRoute);
    }

    async saveChanges() {
        this.showLoading(true);
        this.saveFormData();
        if (this.waterRouteId) {
            console.log('this.waterRoute ********');
            console.log(this.waterRoute);
            const result: boolean = await this._apiService.updateDocument(
                'water-route',
                this.waterRouteId,
                this.waterRoute
            );
            if (result) {
                this._utilsService.openSnackBar('Ruta actualizada correctamente');
            } else {
                this._utilsService.openSnackBar('Error actualizando ruta', 'error');
            }
        } else {
            try {
                const companyId = localStorage.getItem('company');
                const managerId = localStorage.getItem('manager');

                this.waterRoute!.company = parseInt(companyId!);
                this.waterRoute!.manager = parseInt(managerId!);

                const waterRouteId = await this._apiService.addDocument(
                    'water-route',
                    this.waterRoute
                );
                if (waterRouteId) {
                    this.waterRoute!.id = waterRouteId;
                    this.waterRouteId = waterRouteId;
                    this._utilsService.openSnackBar('Ruta añadido correctamente');
                } else {
                    this._utilsService.openSnackBar('Error añadiendo ruta', 'error');
                }
            } catch (error) {
                this._utilsService.openSnackBar('Error añadiendo ruta', 'error');
            }
        }
        this.showLoading(false);
        this._utilsService.closeWaterRouteDialog(this.waterRoute!);
    }
}

/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { FormControl, FormGroup } from '@angular/forms';
import { PosibleResults } from './posible-results';

export interface Cause {
    accion_ordenada: string;
    arealizar: string;
    causa: string;
    codigo_causa: string;
    date_time_modified: Date;
    id: number;
    dependencia_calibre: boolean;
    pautas_ejecucion: string;
    resultados_posibles: PosibleResults[];
    tipo_tarea: string;
}

export function getCauseDisplayColumns(): string[] {
    const displayedColumns: string[] = [
        'Código',
        'Causa',
        'A realizar',
        'Tipo tarea',
        'Acción ordenada',
        'Dependencia calibre',
        'Resultados posibles',
        'Pautas ejecución',
    ];
    return displayedColumns;
}

export function getCauseField(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'Código':
            value = 'codigo_causa';
            break;
        case 'Causa':
            value = 'causa';
            break;
        case 'Acción ordenada':
            value = 'accion_ordenada';
            break;
        case 'A realizar':
            value = 'arealizar';
            break;
        case 'Dependencia calibre':
            value = 'dependencia_calibre';
            break;
        case 'Pautas ejecución':
            value = 'pautas_ejecucion';
            break;
        case 'Tipo tarea':
            value = 'tipo_tarea';
            break;
        case 'Resultados posibles':
            value = 'resultados_posibles';
            break;
        case 'Modificación':
            value = 'date_time_modified';
            break;
    }
    return value;
}

export function getCauseFieldName(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'codigo_causa':
            value = 'Código';
            break;
        case 'causa':
            value = 'Causa';
            break;
        case 'accion_ordenada':
            value = 'Acción ordenada';
            break;
        case 'arealizar':
            value = 'A realizar';
            break;
        case 'dependencia_calibre':
            value = 'Dependencia calibre';
            break;
        case 'pautas_ejecucion':
            value = 'Pautas ejecución';
            break;
        case 'tipo_tarea':
            value = 'Tipo tarea';
            break;
        case 'resultados_posibles':
            value = 'Resultados posibles';
            break;
        case 'date_time_modified':
            value = 'Modificación';
            break;
    }
    return value;
}

export function getCauseFormControls(): FormGroup {
    const causeFormData: FormGroup = new FormGroup({
        accion_ordenada: new FormControl(),
        arealizar: new FormControl(),
        causa: new FormControl(),
        codigo_causa: new FormControl(),
        date_time_modified: new FormControl(),
        id: new FormControl(),
        dependencia_calibre: new FormControl(),
        pautas_ejecucion: new FormControl(),
        resultados_posibles: new FormControl(),
        tipo_tarea: new FormControl(),
    });
    return causeFormData;
}

export function getCauseFieldType(field_name: string): string {
    //in bigquery
    let value: string = '';
    switch (field_name) {
        case 'accion_ordenada':
            value = 'string';
            break;
        case 'arealizar':
            value = 'string';
            break;
        case 'causa':
            value = 'string';
            break;
        case 'codigo_causa':
            value = 'string';
            break;
        case 'date_time_modified':
            value = 'Date';
            break;
        case 'id':
            value = 'number';
            break;
        case 'dependencia_calibre':
            value = 'boolean';
            break;
        case 'pautas_ejecucion':
            value = 'string';
            break;
        case 'resultados_posibles':
            value = 'string';
            break;
        case 'tipo_tarea':
            value = 'string';
            break;
    }
    return value;
}

export function getCauseExcelFieldName(excel_field: string): string {
    let value: string = '';
    switch (excel_field.trim().toLowerCase()) {
        case 'causa':
            value = 'causa';
            break;
        case 'código causa':
            value = 'codigo_causa';
            break;
        case 'codigo causa':
            value = 'codigo_causa';
            break;
        case 'acción ordenada':
            value = 'accion_ordenada';
            break;
        case 'accion ordenada':
            value = 'accion_ordenada';
            break;
        case 'a realizar':
            value = 'arealizar';
            break;
        case 'dependencia calibre':
            value = 'dependencia_calibre';
            break;
        case 'pautas ejecución':
            value = 'pautas_ejecucion';
            break;
        case 'pautas ejecucion':
            value = 'pautas_ejecucion';
            break;
        case 'resultados posibles':
            value = 'resultados_posibles';
            break;
        case 'tipo tarea':
            value = 'tipo_tarea';
            break;
        case 'fecha de modificación':
            value = 'date_time_modified';
            break;
        case 'fecha de modificacion':
            value = 'date_time_modified';
            break;

        case 'accion_ordenada':
            value = 'accion_ordenada';
            break;
        case 'arealizar':
            value = 'arealizar';
            break;
        case 'causa':
            value = 'causa';
            break;
        case 'codigo_causa':
            value = 'codigo_causa';
            break;
        case 'date_time_modified':
            value = 'date_time_modified';
            break;
        case 'dependencia_calibre':
            value = 'dependencia_calibre';
            break;
        case 'pautas_ejecucion':
            value = 'pautas_ejecucion';
            break;
        case 'resultados_posibles':
            value = 'resultados_posibles';
            break;
        case 'tipo_tarea':
            value = 'tipo_tarea';
            break;
    }
    return value;
}

export function getCauseExcelExportColumns(): string[] {
    const columns = [
        'Código causa',
        'Causa',
        'Resultados posibles',
        'Acción ordenada',
        'A realizar',
        'Dependencia calibre',
        'Pautas ejecución',
        'Tipo tarea',
        'Fecha de Modificación',
    ];
    return columns;
}

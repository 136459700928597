/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FileSaverService } from 'src/app/services/file-saver.service';
import { UtilsService } from 'src/app/services/utils.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.scss'],
})
export class HelpComponent implements OnInit {

    constructor(
        public _utilsService: UtilsService, 
        private _apiService: ApiService,
        private _fileSaverService: FileSaverService
    ) {}

    ngOnInit(): void {}

    async downloadVersion() {
        const data = await this._apiService.listVersions();
        if(data.available){
            if(data.version_files.length){
                const version = data.version_files[data.version_files.length-1];
                const url = `${environment.url_server}/file-service/desktop-update/download/${version}`;
                this._utilsService.openSnackBar('Iniciando descarga ...');
                await this._fileSaverService.downloadFile(url, version);
                this._utilsService.openSnackBar(`${version} descargado correctamente`);
                return;
            }
        }
        else this._utilsService.openSnackBar('No hay versión de escritorio disponible', 'warning');
    }
}

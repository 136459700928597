<mat-dialog-content class="animate__animated animate__fadeIn">
    <div [formGroup]="managerFormData" class="animate__animated animate__fadeIn">
        <input
            class="input-hidden"
            type="file"
            (change)="uploadFile($event)"
            title="Upload file"
            placeholder="Upload file"
            #input_file
        />

        <div>
            <div class="container">
                <div class="row bd-highlight" class="row-content-center">
                    <div>
                        <div style="margin-top: 5px; margin-bottom: 30px; margin-left: 5px">
                            <div style="display: flex; justify-content: space-between">
                                <p style="font-size: large">Datos de gestor</p>
                                <div>
                                    <button
                                        mat-icon-button
                                        aria-label="User image"
                                        [matMenuTriggerFor]="userMenu"
                                        style="margin-top: 0px; margin-right: 20px"
                                    >
                                        <img
                                            *ngIf="manager?.photo"
                                            [src]="manager?.photo"
                                            id="photo"
                                            placeholder="assets/img/noimage.png"
                                            onerror="this.onerror=null; this.src='assets/img/noimage.png'"
                                            style="border-radius: 50%; object-fit: contain"
                                            width="45"
                                            height="45"
                                            title="photo"
                                        />
                                        <mat-icon
                                            *ngIf="!manager?.photo"
                                            style="font-size: 50px; color: #777777"
                                        >
                                            add_photo_alternate
                                        </mat-icon>
                                        <mat-progress-bar
                                            *ngIf="uploading"
                                            color="primary"
                                            mode="determinate"
                                            value="{{ percentage }}"
                                        ></mat-progress-bar>
                                    </button>
                                    <mat-menu #userMenu="matMenu">
                                        <button
                                            mat-menu-item
                                            (click)="selectFile(input_file)"
                                            style="text-align: center"
                                        >
                                            <span style="margin-bottom: 10px; text-align: center"
                                                >Cambiar</span
                                            >
                                        </button>
                                        <button mat-menu-item style="text-align: center">
                                            <span style="margin-bottom: 10px; text-align: center"
                                                >Mostrar</span
                                            >
                                        </button>
                                    </mat-menu>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="row-content">
                                <mat-form-field style="width: 120px" appearance="fill">
                                    <mat-label>Código gestor</mat-label>
                                    <input
                                        formControlName="codigo_gestor"
                                        matInput
                                        type="text"
                                        placeholder="Código gestor"
                                    />
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Gestor</mat-label>
                                    <input
                                        formControlName="gestor"
                                        matInput
                                        type="text"
                                        placeholder="Gestor"
                                    />
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Código de servicio</mat-label>
                                    <input
                                        formControlName="aqualia_codigo_servicio"
                                        matInput
                                        type="text"
                                        placeholder="Código de servicio"
                                    />
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ngx-spinner
        [bdColor]="manager ? 'rgba(0, 0, 0, 0.2)' : 'rgba(255, 255, 255, 1)'"
        size="large"
        color="#368DCE"
        type="ball-scale-multiple"
        [fullScreen]="false"
        name="managerSpinner"
    >
    </ngx-spinner>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="manager">Cancelar</button>
    <button mat-button cdkFocusInitial matTooltip="Guardar cambios" (click)="saveChanges()">
        <mat-icon color="primary">check</mat-icon> {{ managerId ? 'Actualizar' : 'Añadir' }}
    </button>
</mat-dialog-actions>

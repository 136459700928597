/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MiRutaUser } from 'src/app/interfaces/mi-ruta-user';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
    formData = new FormGroup({
        newPassword: new FormControl(),
        repeatedPassword: new FormControl(),
    });

    token: string = '';
    user?: MiRutaUser;
    chageSuccess: boolean = false;

    constructor(
        private _apiService: ApiService,
        public _utilsService: UtilsService,
        private spinner: NgxSpinnerService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.route.queryParams.subscribe((queryParams) => {
            this.token = queryParams['token'];
        });
    }

    async ngOnInit(): Promise<void> {
        localStorage.setItem('access_token', this.token);
        const res = await this._apiService.checkCurrentTokenUser();
        if (res) {
            this._utilsService.openSnackBar('Por favor, proceda a cambiar la contraseñas');
            this.user = res;
        } else {
            this.router.navigate(['/login']);
        }
    }

    showLoading(state: boolean) {
        if (state) {
            this.spinner.show('taskSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this.spinner.hide('taskSpinner');
        }
    }

    async setNewPassword(newPassword: string, repeatedPassword: string) {
        if (!this.token) {
            this._utilsService.openSnackBar(
                'No tiene permisos para cambiar la contraseña',
                'error'
            );
            return;
        }
        if (!newPassword) {
            this._utilsService.openSnackBar('Debe insertar la nueva contraseña', 'warning');
            return;
        }
        if (newPassword != repeatedPassword) {
            this._utilsService.openSnackBar('Las contraseñas no coinciden', 'warning');
            return;
        }
        this.showLoading(true);
        const res = await this._apiService.setNewPassword(this.token, newPassword);
        if (res) {
            this.chageSuccess = true;
            setTimeout(() => {
                if (this.user!.superusuario || this.user!.administrador || this.user!.cliente) {
                    this.router.navigate(['/login']);
                } else {
                }
            }, 4000);
        } else this._utilsService.openSnackBar('No se ha podido cambiar la contraseña', 'error');
        this.showLoading(false);
    }
}

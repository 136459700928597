import { MyLatLng } from './lat-lng';

export interface UserAction {
    id: number;
    user_id: number;
    action_type: string;
    action_screen: string;
    entity_type: string;
    entity_id: number;
    entity_data: string;
    entity_location_code: string;
    details: string;
    data: string;
    source: string; //android -desktop
    geolocation: MyLatLng;
    date_time_modified: Date;
}

export function convertUserActionFromServer(data: any): any {
    let geolocation;
    try {
        geolocation = JSON.parse(data['geolocation']) as MyLatLng;
    } catch (err) {
        geolocation = data['geolocation'];
    }
    let userAction: UserAction = {
        id: data['id'],
        user_id: data['user_id'],
        action_type: data['action_type'],
        action_screen: data['action_screen'],
        entity_type: data['entity_type'],
        entity_id: data['entity_id'],
        entity_data: data['entity_data'],
        entity_location_code: data['entity_location_code'],
        details: data['details'],
        data: data['data'],
        source: data['source'], 
        geolocation: geolocation,
        date_time_modified: new Date(data['date_time_modified']),
    };
    return userAction;
}

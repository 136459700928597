/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faBuilding, faFilePdf, faKey, faInbox } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { ApiService } from '../../../services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessagingService } from 'src/app/services/messaging.service';

import * as moment from 'moment';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Team, getTeamFormControls } from 'src/app/interfaces/team';
import { MiRutaUser } from 'src/app/interfaces/mi-ruta-user';

@Component({
    selector: 'app-team',
    templateUrl: './team.component.html',
    styleUrls: ['./team.component.scss'],
})
export class TeamComponent implements OnInit {
    faFilePdf = faFilePdf;
    faInbox = faInbox;
    faBuilding = faBuilding;
    faKey = faKey;
    loading: boolean = false;
    teamId: string = '';
    team?: Team;

    teamFormData: FormGroup = getTeamFormControls();

    teamSubscription$?: Subscription;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _apiService: ApiService,
        private _utilsService: UtilsService,
        private spinner: NgxSpinnerService,
        private _messagingService: MessagingService
    ) {
        this.teamId = data.teamId;
        // this.activatedRoute.params.subscribe((params)=>{
        //   this.teamId = params['id'];
        // });
    }

    async ngOnInit(): Promise<void> {
        if (this.teamId) {
            this.showLoading(true);

            this.teamSubscription$ = this._apiService
                .getDocument(`team`, this.teamId)
                .subscribe(async (doc: any) => {
                    if (!doc) {
                        this._utilsService.openSnackBar('Error obteniendo datos de team', 'error');
                        this.showLoading(false);
                        return;
                    }
                    const team = doc as Team;
                    team.id = parseInt(this.teamId);

                    this.team = team;

                    const teamFormData = getTeamFormControls();
                    const keys = Object.keys(team);
                    for (let key of keys) {
                        try {
                            let value: any = team[key as keyof Team];
                            // console.log(key, value);
                            if (value) {
                                if (this._utilsService.isValidDate(value)) {
                                    value = value;
                                }
                                try {
                                    teamFormData.controls[key].setValue(value);
                                } catch (err) {
                                    console.log('************* key *************');
                                    console.log(key);
                                    console.log('************* value *************');
                                    console.log(value);
                                }
                                // console.log('setting value');
                            }
                        } catch (err) {
                            console.log('************* err *************');
                            console.error(err);
                        }
                    }
                    this.teamFormData = teamFormData;

                    this.showLoading(false);
                });
        }
    }

    ngOnDestroy(): void {
        this.teamSubscription$?.unsubscribe();
    }

    async addUsers() {
        try {
            const users = await this._apiService.getUsers(['operario']);
            const alreadyInTeamUsers = this.teamFormData.controls['operarios'].value;
            const usersSelectedName = await this._utilsService.openMultipleOptionsDialog(
                'Seleccione operarios',
                users.map((user: MiRutaUser) => this._utilsService.userPipe(user.id)),
                alreadyInTeamUsers?.map((userId: any) => this._utilsService.userPipe(userId))
            );
            if (usersSelectedName && usersSelectedName.length > 0) {
                const usersSelected = users.filter((user: MiRutaUser) =>
                    usersSelectedName.includes(this._utilsService.userPipe(user.id))
                );
                if (usersSelected) {
                    this.teamFormData.controls['operarios'].setValue(usersSelected);
                }
            }
        } catch (err) {}
    }

    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this.spinner.show('teamSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this.spinner.hide('teamSpinner');
        }
    }

    saveFormData() {
        const keys = Object.keys(this.teamFormData.controls);
        let team: any = {};
        for (let key of keys) {
            let value = this.teamFormData.controls[key].value; //this.team[key as keyof Team];
            try {
                if (moment.isMoment(value)) {
                    value = value.toDate();
                }
                team![key as keyof Team] = value;
            } catch (error) {}
        }
        team!['date_time_modified'] = new Date();
        if (!this.teamId || !team['date_time_created']) {
            team['date_time_created'] = team!['date_time_modified'];
        }
        this.team = team;
        // console.log(this.team);
    }

    async saveChanges() {
        this.showLoading(true);
        this.saveFormData();
        if (this.teamId) {
            const company = localStorage.getItem('company');
            const manager = localStorage.getItem('manager');
            const result: boolean = await this._apiService.updateDocument(
                'team',
                this.teamId,
                this.team
            );
            if (result) {
                this._utilsService.openSnackBar('Equipo actualizada correctamente');
            } else {
                this._utilsService.openSnackBar('Error actualizando equipo', 'error');
            }
        } else {
            try {
                const company = localStorage.getItem('company');
                const manager = localStorage.getItem('manager');
                this.team!.company = parseInt(company!);
                this.team!.manager = parseInt(manager!);
                const teamId = await this._apiService.addDocument('team', this.team);
                if (teamId) {
                    this.team!.id = teamId;
                    this._utilsService.openSnackBar('Equipo añadido correctamente');
                } else {
                    this._utilsService.openSnackBar('Error añadiendo equipo', 'error');
                }
            } catch (error) {
                this._utilsService.openSnackBar('Error añadiendo equipo', 'error');
            }
        }
        this.showLoading(false);
        this._utilsService.closeTeamDialog(this.team!);
    }
}

/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { faBuilding, faFilePdf, faKey, faInbox } from '@fortawesome/free-solid-svg-icons';
import { UtilsService } from 'src/app/services/utils.service';
import { NgxSpinnerService } from 'ngx-spinner';

import { counter_status, getCounterFormControls } from 'src/app/interfaces/counter';
import { ClassCounter } from 'src/app/interfaces/class-counter';
import { ApiService } from 'src/app/services/api.service';
import { Mark } from '../../../interfaces/mark';
import { TypeCounter } from '../../../interfaces/type-counter';
import { Caliber } from '../../../interfaces/caliber';
import { Longitude } from '../../../interfaces/longitude';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TypeRadius } from '../../../interfaces/type-radius';
import { Agrupation } from 'src/app/interfaces/agrupation';

export interface CounterAssignData {
    assing_to_counters: false;
}
@Component({
    selector: 'app-counter-assign',
    templateUrl: './counter-assign.component.html',
    styleUrls: ['./counter-assign.component.scss'],
})
export class CounterAssignComponent implements OnInit {
    faFilePdf = faFilePdf;
    faInbox = faInbox;
    faBuilding = faBuilding;
    faKey = faKey;
    loading: boolean = false;
    counter?: any;

    classes?: string[];
    marks?: string[];
    typeCounters?: string[];
    calibers?: string[];
    longitudes?: string[];
    radios?: string[];
    agrupations?: string[];

    assing_to_counters: boolean = false;
    counterFormData: FormGroup = getCounterFormControls();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _utilsService: UtilsService,
        private _apiService: ApiService,
        private spinner: NgxSpinnerService
    ) {
        this.assing_to_counters = data.assing_to_counters;
        if (!this.assing_to_counters) {
            this.counterFormData.addControl('numero_serie_contador_start', new FormControl());
            this.counterFormData.addControl('numero_serie_contador_end', new FormControl());
        }
    }

    async ngOnInit(): Promise<void> {
        if (!this.assing_to_counters) {
            this.counterFormData.controls['numero_serie_contador_start'].valueChanges.subscribe(
                async (value: any) => {
                    if (value > this.counterFormData.controls['numero_serie_contador_end'].value) {
                        this.counterFormData.controls['numero_serie_contador_end'].setValue(value);
                    }
                }
            );
            this.counterFormData.controls['numero_serie_contador_end'].valueChanges.subscribe(
                async (value: any) => {
                    if (
                        value < this.counterFormData.controls['numero_serie_contador_start'].value
                    ) {
                        this.counterFormData.controls['numero_serie_contador_start'].setValue(
                            value
                        );
                    }
                }
            );
        }
        this.classes = (await this._apiService.getClassCounter())
            .map(
                (classCounter: ClassCounter) =>
                    `${classCounter.codigo_clase} - ${classCounter.clase}`
            )
            .sort();
        this.marks = (await this._apiService.getMarks())
            .map((mark: Mark) => `${mark.codigo_marca} - ${mark.marca} - ${mark.modelo}`)
            .sort();
        this.typeCounters = (await this._apiService.getDocuments<TypeCounter>('type_counter'))
            .map((typeCounter: TypeCounter) => `${typeCounter.tipo}`)
            .sort();
        this.calibers = (await this._apiService.getDocuments<Caliber>('caliber'))
            .map((caliber: Caliber) => parseInt(`${caliber.calibre}`.trim()))
            .sort((a, b) => a - b)
            .map((caliber: number) => `${caliber}`);
        this.longitudes = (await this._apiService.getDocuments<Longitude>('longitude'))
            .map((longitude: Longitude) => parseInt(`${longitude.longitud}`.trim()))
            .sort((a, b) => a - b)
            .map((longitude: number) => `${longitude}`);

        this.radios = (await this._apiService.getDocuments<TypeRadius>('type_radius'))
            .map((typeRadius: TypeRadius) => `${typeRadius.radio}`)
            .sort();

        this.agrupations = (await this._apiService.getAgrupations())
            .map((agrupation: Agrupation) => `${agrupation.agrupationId}`)
            .sort();
    }

    ngOnDestroy(): void {}

    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this.spinner.show('counterSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this.spinner.hide('counterSpinner');
        }
    }

    saveFormData() {
        const keys = Object.keys(this.counterFormData.controls);
        let counter: any = {};

        for (let key of keys) {
            let value = this.counterFormData.controls[key].value;
            try {
                const type = typeof value;
                counter![key] = type === 'string' || type === 'boolean' ? value : value.toString();
            } catch (error) {}
        }

        try {
            counter!['codigo_clase'] = counter!['clase'].split('-')[0].trim();
            counter!['clase'] = counter!['clase'].split('-')[1].trim();
        } catch (err) {}

        try {
            counter!['codigo_marca'] = counter!['marca'].split('-')[0].trim();
            counter!['modelo'] = counter!['marca'].split('-')[2].trim();
            counter!['marca'] = counter!['marca'].split('-')[1].trim();
        } catch (err) {}

        if (!this.assing_to_counters) {
            counter!['status_contador'] = counter_status.AVAILABLE;
        }
        counter!['date_time_modified'] = new Date();
        this.counter = counter;
    }

    async saveChanges() {
        // console.log(this.counterFormData.controls);
        if (!this.counterFormData.valid) {
            this._utilsService.openSnackBar('Rellene todos los campos requeridos', 'error');
            const keys = Object.keys(this.counterFormData.controls);
            for (let key of keys) {
                this.counterFormData.get(key)?.markAsTouched();
                this.counterFormData.get(key)?.markAsDirty();
            }
            return;
        }
        this.showLoading(true);
        this.saveFormData();
        this.showLoading(false);
        this._utilsService.closeCounterAssignDialog(this.counter!);
    }
}

<div style="display: flex; justify-content: space-between">
    <div style="display: flex">
        <p style="margin: auto; font-size: 20px">Configuración de filtro</p>
    </div>

    <div *ngIf="filterNotEmpty" style="margin-left: 20px">
        <button
            mat-mini-fab
            class="danger-color"
            (click)="clearFilter()"
            matTooltip="Limpiar filtro"
        >
            <mat-icon style="font-size: 22px">delete</mat-icon>
        </button>
    </div>
</div>

<div *ngIf="filterNotEmpty" style="overflow: auto; margin-top: 10px">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
        <!-- This is the tree node template for leaf nodes -->
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
            <!-- use a disabled button to provide padding for tree leaf -->
            <button mat-icon-button disabled></button>
            {{ node.name }}
        </mat-tree-node>
        <!-- This is the tree node template for expandable nodes -->
        <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
            <mat-checkbox
                color="primary"
                class="padding: 5px;"
                (change)="checkBoxChange($event.checked, node.name)"
                [checked]="isChecked(node)"
            >
            </mat-checkbox>
            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                <mat-icon class="mat-icon-rtl-mirror">
                    {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                </mat-icon>
            </button>
            {{ node.name }}
        </mat-tree-node>
    </mat-tree>
</div>

<div
    *ngIf="!filterNotEmpty"
    style="display: flex; height: 100%; padding-top: 30px; padding-bottom: 30px"
>
    <div style="margin: auto">
        <div style="text-align: center">
            <fa-icon style="font-size: 40px; color: #777777" [icon]="faInbox"></fa-icon>
        </div>
        <div style="text-align: center; margin: 10px">
            <span style="font-size: 16px; color: #777777">No hay filtro</span>
        </div>
    </div>
</div>

/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faBuilding, faFilePdf, faKey, faInbox } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { ApiService } from '../../../services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessagingService } from 'src/app/services/messaging.service';
import * as moment from 'moment';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TypeCounter, getTypeCounterFormControls } from 'src/app/interfaces/type-counter';

@Component({
    selector: 'app-type-counter',
    templateUrl: './type-counter.component.html',
    styleUrls: ['./type-counter.component.scss'],
})
export class TypeCounterComponent implements OnInit {
    faFilePdf = faFilePdf;
    faInbox = faInbox;
    faBuilding = faBuilding;
    faKey = faKey;
    loading: boolean = false;
    typeCounterId: string = '';
    typeCounter?: TypeCounter;

    typeCounterFormData: FormGroup = getTypeCounterFormControls();

    typeCounterSubcription$?: Subscription;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _apiService: ApiService,
        private _utilsService: UtilsService,
        private spinner: NgxSpinnerService
    ) {
        this.typeCounterId = data.typeCounterId;
    }

    async ngOnInit(): Promise<void> {
        if (this.typeCounterId) {
            this.showLoading(true);

            this.typeCounterSubcription$ = this._apiService
                .getDocument('type_counter', this.typeCounterId)
                .subscribe(async (doc: any) => {
                    if (!doc) {
                        this._utilsService.openSnackBar(
                            'Error obteniendo datos de typeCounter',
                            'error'
                        );
                        this.showLoading(false);
                        return;
                    }
                    const typeCounter = doc as TypeCounter;
                    typeCounter.id = parseInt(this.typeCounterId);

                    this.typeCounter = typeCounter;

                    console.log(this.typeCounter);

                    const typeCounterFormData = getTypeCounterFormControls();
                    const keys = Object.keys(typeCounter);
                    for (let key of keys) {
                        let value: any = typeCounter[key as keyof TypeCounter];
                        if (value) {
                            if (this._utilsService.isValidDate(value)) {
                                value = value;
                            }
                            typeCounterFormData.controls[key].setValue(value);
                        }
                    }
                    this.typeCounterFormData = typeCounterFormData;

                    this.showLoading(false);
                });
        }
    }

    ngOnDestroy(): void {
        this.typeCounterSubcription$?.unsubscribe();
    }

    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this.spinner.show('typeCounterSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this.spinner.hide('typeCounterSpinner');
        }
    }

    saveFormData() {
        const keys = Object.keys(this.typeCounterFormData.controls);
        let typeCounter: any = {};
        for (let key of keys) {
            let value = this.typeCounterFormData.controls[key].value; //this.typeCounter[key as keyof TypeCounter];
            try {
                if (moment.isMoment(value)) {
                    value = value.toDate();
                }
                typeCounter![key as keyof TypeCounter] = value;
            } catch (error) {}
        }
        typeCounter!['date_time_modified'] = new Date();
        this.typeCounter = typeCounter;
        // console.log(this.typeCounter);
    }

    async saveChanges() {
        this.showLoading(true);
        this.saveFormData();
        if (this.typeCounterId) {
            const result: boolean = await this._apiService.updateDocument(
                'type_counter',
                this.typeCounterId,
                this.typeCounter
            );
            if (result) {
                this._utilsService.openSnackBar('Tipo actualizada correctamente');
            } else {
                this._utilsService.openSnackBar('Error actualizando tipo', 'error');
            }
        } else {
            try {
                const typeCounterId = await this._apiService.addDocument(
                    'type_counter',
                    this.typeCounter
                );
                if (typeCounterId) {
                    this.typeCounter!.id = typeCounterId;
                    this._utilsService.openSnackBar('Tipo añadido correctamente');
                } else {
                    this._utilsService.openSnackBar('Error añadiendo tipo', 'error');
                }
            } catch (error) {
                this._utilsService.openSnackBar('Error añadiendo tipo', 'error');
            }
        }
        this.showLoading(false);
        this._utilsService.closeTypeCounterDialog(this.typeCounter!);
    }
}

<mat-dialog-content [formGroup]="formData">
    <div style="display: flex;">
        <mat-form-field style="flex: 3;" appearance="fill">
            <mat-label>{{ placeHolderText }}</mat-label>
            <input formControlName="currentValue" matInput
            [type]="inputType" placeholder="Inserte ..." #input>
        </mat-form-field>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="center">
    <button mat-button mat-dialog-close>Cancelar</button>
    <button mat-button [mat-dialog-close]="input.value"
    [disabled]="!(input.value)">
    Aceptar
    </button>
</mat-dialog-actions>

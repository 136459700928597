<div class="container">
    <div style="margin-top: 0px">
        <p>
            <strong class="name">MI RUTA</strong>
        </p>
    </div>
    <div>
        <p>
            <strong class="title">V{{ _utilsService.app_version }}</strong>
        </p>
    </div>

    <div style="margin: 40px"></div>

    <div>
        <p class="title">CEO</p>
    </div>
    <div>
        <p>
            <strong class="name">Michel Roman Morales</strong>
        </p>
    </div>
    <div>
        <p class="email">mraguascontadores@gmail.com</p>
    </div>

    <div style="margin: 40px"></div>

    <div>
        <p class="title">Programador</p>
    </div>
    <div>
        <p>
            <strong class="name">Luis Alejandro Reyes Morales</strong>
        </p>
    </div>
    <div>
        <p class="email">inglreyesm@gmail.com</p>
    </div>

    <div style="margin: 40px"></div>

    <div>
        <p class="title">Programador</p>
    </div>
    <div>
        <p>
            <strong class="name">Adrián Nieves de la Cruz</strong>
        </p>
    </div>
    <div>
        <p class="email">adriannnieves201912@gmail.com</p>
    </div>

    <div style="margin: 40px"></div>

    <div>
        <p class="title">Diseñador</p>
    </div>
    <div>
        <p>
            <strong class="name">Eduardo Huet Lezcano</strong>
        </p>
    </div>
    <div>
        <p class="email">ehuet7@gmail.com</p>
    </div>

    <div style="margin-top: 40px; margin-bottom: 40px">
        <hr style="margin-left: -40px" />
    </div>

    <div>
        <button
        mat-raised-button
        color="primary"
        class="button"
        (click)="downloadVersion()"
        >
            <mat-icon style="margin-right: 10px;">cloud_download</mat-icon>Descargar versión de escritorio
        </button>
    </div>

    <div style="margin-top: 40px; margin-bottom: 40px">
        <hr style="margin-left: -40px" />
    </div>

    <div style="margin-bottom: 20px">
        <p class="link">
            <a href="http://www.mraguas.com" target="_blank">www.mraguas.com</a>
        </p>
    </div>
</div>

/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit,
    ViewChild,
    ViewContainerRef,
    QueryList,
    ElementRef,
    ViewChildren,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {
    faFilter,
    faSortAmountDown,
    faSortAmountUpAlt,
    faLock,
    faUnlock,
    faHeadset,
} from '@fortawesome/free-solid-svg-icons';
import { MatMenuTrigger } from '@angular/material/menu';
import { WaterTask, priority_status, task_status } from '../../../interfaces/water-task';
import { UtilsService } from 'src/app/services/utils.service';
import { IpcService } from 'src/app/services/ipc.service';
import { getField } from 'src/app/interfaces/water-task';
import { ApiService } from 'src/app/services/api.service';
import { getItacField } from '../../../interfaces/itac';
import { getSideField } from '../../../interfaces/side';
import * as moment from 'moment';
import { DateStatus } from '../../../interfaces/date-status';

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {
    @ViewChild('table') table?: ElementRef;
    @ViewChildren('matrow', { read: ViewContainerRef }) rows?: QueryList<ViewContainerRef>;
    @ViewChildren('matcolumn', { read: ViewContainerRef }) columns?: QueryList<ViewContainerRef>;
    @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger?: MatMenuTrigger;

    @Input() dataSource: MatTableDataSource<any> = new MatTableDataSource();
    @Input() hoursList: any[] = [];
    @Input() displayedColumns: any[] = [];
    @Input() displayedColumnsField: any[] = [];
    @Input() clickedRows = new Set<any>();
    @Input() tableStyle = 'width: 12000px;';
    @Input() tableName = '';
    @Input() menuOptions: string[] = [];
    @Input() additionalMenuOptions: string[] = [];
    @Input() fromTask: boolean = false;
    @Input() fromTasksOrItacs: boolean = false;
    @Input() fromUsers: boolean = false;
    @Input() tableType: string = '';
    @Output() sendFilterBy: EventEmitter<string>;
    @Output() sendOrderBy: EventEmitter<any>;
    @Output() sendDoubleClickedRow: EventEmitter<any>;
    @Output() sendClickedRow: EventEmitter<any>;
    @Output() sendMenuOptionSelected: EventEmitter<string>;
    @Output() sendSelectedRows: EventEmitter<any>;
    @Output() sendReload: EventEmitter<any>;

    additionalMenuActive: boolean = false;

    // reference to the MatMenuTrigger in the DOM

    high_priority_value = priority_status.HIGH;

    company?: string;

    menuTopLeftPosition = { x: '0', y: '0' };

    faFilter = faFilter;
    faSortAmountDown = faSortAmountDown;
    faHeadset = faHeadset;
    faSortAmountUpAlt = faSortAmountUpAlt;
    faLock = faLock;
    faUnlock = faUnlock;

    options: string[] = [];

    lastSelectedIndex = -1;

    loopIndexing = -1;
    loopIndexingEnd = -1;
    dateStatuses: DateStatus[] = [];
    defaultStatus?: DateStatus;

    /**
     * Constructs a new instance of the TableComponent class.
     * @param _utilsService - The UtilsService instance.
     * @param _electronService - The IpcService instance.
     * @param _apiService - The ApiService instance.
     */
    constructor(
        public _utilsService: UtilsService,
        public _electronService: IpcService,
        private _apiService: ApiService
    ) {
        this.sendFilterBy = new EventEmitter();
        this.sendOrderBy = new EventEmitter();
        this.sendDoubleClickedRow = new EventEmitter();
        this.sendClickedRow = new EventEmitter();
        this.sendMenuOptionSelected = new EventEmitter();
        this.sendReload = new EventEmitter();
        this.sendSelectedRows = new EventEmitter();
    }

    /**
     * Initializes the component and performs necessary setup tasks.
     * This method is called after the component's inputs have been initialized.
     * @returns A promise that resolves when the initialization is complete.
     */
    async ngOnInit(): Promise<void> {
        this.company = localStorage.getItem('company')!;

        this.setTimeoutDoubleClickRowIndex();

        this.additionalMenuActive = false;
        if (this.additionalMenuOptions && this.additionalMenuOptions.length > 0){
            this.options = this.menuOptions.concat(['Más opciones...']);
        }
        else this.options = this.menuOptions;
        this.dateStatuses = await this._apiService.getDateStatuses();
        this.defaultStatus = this.dateStatuses.find(d => d.default == true);
    }

    /**
     * Sets a timeout to handle the double-clicked row index.
     * Retrieves the last double-clicked row index from local storage and performs the necessary actions based on the index.
     */
    setTimeoutDoubleClickRowIndex(){
        setTimeout(() => {
            const index = localStorage.getItem('lastDoubleClickedRowIndex');
            localStorage.setItem('lastDoubleClickedRowIndex', '');
            try {
                if (index) {
                    const intIndex = parseInt(index);
                    if (this.dataSource.data.length < intIndex) {
                        this.loopIndexingEnd = intIndex;
                        this.indexingFuntion();
                    } else this.showElement(intIndex);
                }
            } catch (err) {
                console.log('============= err =============');
                console.log(err);
            }
        }, 50);
    }

    /**
     * Checks if the given column is a normal filter field based on the current table name.
     * @param column - The name of the column to check.
     * @returns A boolean value indicating whether the column is a normal filter field.
     */
    isNormalFilterField(column: string): boolean {
        if (this.tableName == 'tasks') {
            if (this._utilsService.notNormalFilterFields.includes(getField(column))) {
                return false;
            }
        }
        if (this.tableName == 'calendar-table') {
            if (this._utilsService.notNormalFilterFields.includes(getField(column))) {
                return false;
            }
        }
        else if(this.tableName == 'sides'){
            if (this._utilsService.notNormalFilterFields.includes(getSideField(column))) {
                return false;
            }
        }
        else if(this.tableName == 'itacs'){
            if (this._utilsService.notNormalFilterFields.includes(getItacField(column))) {
                return false;
            }
        }
        return true;
    }

    /**
     * Checks if a column should be sticky.
     * @param column - The name of the column.
     * @returns A boolean indicating whether the column should be sticky.
     */
    isStickyColumn(column: string): boolean {
        if(this.tableName == 'calendar-table'){
            if(this.isNotHourColumn(column)) return true;
        }
        return false;
    }

    /**
     * Checks if a task has server images.
     * 
     * @param task - The task to check.
     * @returns A boolean value indicating whether the task has server images or not.
     */
    checkIfTaskHasServerImage(task: any): boolean { 
        return this._utilsService.checkIfTaskHasServerImages(task);
    }

    /**
     * Checks if the given ITAC has server images.
     * 
     * @param itac - The ITAC object to check.
     * @returns A boolean value indicating whether the ITAC has server images or not.
     */
    checkIfItacHasServerImage(itac: any): boolean { 
        return this._utilsService.checkIfItacHasServerImages(itac);
    }

    /**
     * Retrieves the image URL for a given task.
     * 
     * @param task - The task object.
     * @returns The image URL for the task.
     */
    getTaskImage(task: any): string {
        return this._utilsService.getTaskPhoto(task);
    }
    
    /**
     * Retrieves the images associated with the given row.
     * @param row - The row object for which to retrieve the images.
     * @returns An array of strings representing the image URLs.
     */
    getImages(row: any): string[] {
        if(row.NUMIN) return this.getTaskImages(row);
        if(row.codigo_itac) return this.getItacImages(row);
        return [];
    }
    
    /**
     * Retrieves the images associated with a task.
     * @param task - The task object.
     * @returns An array of strings representing the task images.
     */
    getTaskImages(task: any): string[] {
        return this._utilsService.getTaskPhotos(task);
    }
    
    /**
     * Retrieves the images associated with the given itac.
     * 
     * @param itac - The itac object.
     * @returns An array of strings representing the image URLs.
     */
    getItacImages(itac: any): string[] {
        return this._utilsService.getItacPhotos(itac);
    }
    
    /**
     * Checks if the given task has correct phones.
     * 
     * @param task - The task object to check.
     * @returns A boolean value indicating whether the task has correct phones or not.
     */
    hasCorrectPhones(task: any): boolean {
        return this._utilsService.isNeededHighlightPhone1(task) 
        || this._utilsService.isNeededHighlightPhone2(task);
    }

    /**
     * Checks if a task is deprecated.
     * @param task - The task to check.
     * @returns A boolean indicating whether the task is deprecated or not.
     */
    checkIfIsDepreceated(task: any): boolean {
        const waterTask: WaterTask = task as WaterTask;
        if (waterTask.status_tarea != task_status.IDLE) return false;
        if (waterTask.fecha_hora_cita_end)
            return this._utilsService.checkIfDateIsDepreceated(waterTask.fecha_hora_cita_end);
        if (waterTask.fecha_hora_cita)
            return this._utilsService.checkIfDateIsDepreceated(waterTask.fecha_hora_cita);
        return false;
    }

    /**
     * Returns the tooltip text for the given task's phones.
     * 
     * @param task - The task object.
     * @returns The tooltip text for the task's phones.
     */
    getPhonesToolTip(task: any) {
        let toolTip: string = '';
        if(task){
            if(this._utilsService.isNeededHighlightPhone1(task)){
                if(task.telefono1) toolTip = task.telefono1 + '\n\n';
            }
            if(this._utilsService.isNeededHighlightPhone2(task)){
                if(task.telefono2) toolTip += task.telefono2;
            }
        }
        return toolTip.trim();
    }

    /**
     * Returns the tooltip text for a given task.
     * 
     * @param task - The task object for which the tooltip is generated.
     * @returns The tooltip text.
     */
    getToolTip(task: any) {
        let tooltip: string = '';
        if (this.tableName == 'tasks' || this.tableName == 'calendar-table') {
            tooltip += this._utilsService.getDirOfTask(task, false) + '\n';
            tooltip += this._utilsService.getTaskSubscriberTextLine(task) + '\n';
            tooltip += this._utilsService.getTaskCounterTextLine(task) + '\n';
            if (task.ubicacion_en_bateria) tooltip += `UBICACIÓN BATERÍA - ${task.ubicacion_en_bateria}` + '\n';
            if (task.cita_pendiente) tooltip += `CITA - ${task.nuevo_citas}` + '\n';
        }
        else if(this.tableName == 'sides'){
            tooltip += this._utilsService.getDirOfTask(task, false) + '\n';
        }
        return tooltip.trim();
    }

    /**
     * Performs indexing operation by incrementing the loopIndexing value and showing the element.
     * If the loopIndexing reaches the loopIndexingEnd, it shows the element for the loopIndexingEnd value.
     */
    indexingFuntion() {
        if (this.loopIndexing < this.loopIndexingEnd) {
            this.loopIndexing += 50;
            setTimeout(() => {
                this.showElement(this.loopIndexing);
                this.indexingFuntion();
            }, 50);
        } else {
            this.showElement(this.loopIndexingEnd);
        }
    }

    /**
     * Shows the element at the specified index in the table.
     * If the index is out of range or the table is empty, nothing happens.
     * 
     * @param index - The index of the element to show.
     */
    showElement(index: number) {
        if (!this.rows || this.dataSource.data.length < index) return;

        this.clickedRows.clear();

        let rowToMove = index;
        if (this.dataSource.data.length > index + 10) rowToMove += 10;
        else rowToMove = this.dataSource.data.length - 1; //to shift e little more down the table

        let row = this.rows.get(rowToMove);

        if (row) {
            row.element.nativeElement.scrollIntoView(false, { behavior: 'instant' });
            this.clickedRows.add(this.dataSource.data[index]);
            return;
        }
    }

    /**
     * Handles the drop event on the table.
     * Reorders the displayed columns based on the drop event and saves the updated column order.
     * Emits a reload event to refresh the table.
     * @param event - The drop event object.
     */
    async tableDrop(event: any) {
        if (this._utilsService.isClientUser()) return;
        if (event) {
            let currentIndex = event.currentIndex + 1;
            let prevIndex = event.previousIndex + 1;

            let temp = this.displayedColumns[prevIndex];

            this.displayedColumns.splice(prevIndex, 1);
            this.displayedColumns.splice(currentIndex, 0, temp);

            const columnString = JSON.stringify(this.displayedColumns);
            localStorage.setItem('displayedColumns_' + this.tableName, columnString);

            await this._apiService.saveColumnsOnUser(this.tableName, columnString);
            this.sendReload.emit();
        }
    }

    /**
     * Handles the right-click event on the table component.
     * @param event - The MouseEvent object representing the right-click event.
     * @param item - The item associated with the right-click event.
     */
    onRightClick(event: MouseEvent, item: any) {
        // preventDefault avoids to show the visualization of the right-click menu of the browser
        event.preventDefault();

        if (this.menuOptions.length == 0) return;

        if (
            this.additionalMenuActive &&
            this.additionalMenuOptions &&
            this.additionalMenuOptions.length > 0
        )
            this.options = this.menuOptions.concat(['Más opciones...']);

        // we record the mouse position in our object
        this.menuTopLeftPosition.x = event.clientX + 'px';
        this.menuTopLeftPosition.y = event.clientY + 'px';

        if (this.matMenuTrigger) {
            // we open the menu
            // we pass to the menu the information about our object
            this.matMenuTrigger.menuData = { item: item };

            // we open the menu
            this.matMenuTrigger.openMenu();
        }
    }

    /**
     * Handles the selection of a menu option.
     * @param option - The selected menu option.
     */
    onMenuOptionSelected(option: string): void {
        if (option === 'Más opciones...') {
            this.options = this.menuOptions.concat(this.additionalMenuOptions);
            setTimeout(() => {
                if (this.matMenuTrigger) {
                    this.matMenuTrigger.openMenu();
                    this.additionalMenuActive = true;
                }
            }, 500);
        } else this.sendMenuOptionSelected.emit(option);
    }

    /**
     * Filters the table data by the specified column.
     * @param column - The column to filter by.
     */
    filterBy(column: string): void {
        this.sendFilterBy.emit(column);
    }

    /**
     * Orders the table data based on the specified column and order type.
     * 
     * @param column - The column to order by.
     * @param orderType - The order type (e.g., 'asc' for ascending, 'desc' for descending).
     */
    orderBy(column: string, orderType: string): void {
        this.sendOrderBy.emit({ column: column, orderType: orderType });
    }

    /**
     * Handles the double click event on a row in the table.
     * 
     * @param row - The data object representing the clicked row.
     * @param rowIndex - The index of the clicked row.
     */
    doubleClickedRow(row: any, rowIndex: number) {
        row['rowIndex'] = rowIndex;
        this.sendDoubleClickedRow.emit(row);
        localStorage.setItem('lastDoubleClickedRowIndex', rowIndex.toString());
    }

    /**
     * Handles the click event on a row in the table.
     * @param row - The clicked row object.
     * @param event - The click event object.
     * @param rowIndex - The index of the clicked row.
     */
    clickedRow(row: any, event: any, rowIndex: any) {
        this.sendClickedRow.emit({ row: row, event: event, rowIndex: rowIndex });
    }

    /**
     * Handles the mouse down event on a table row.
     * 
     * @param index - The index of the row that was clicked.
     */
    mouseDown(index: number) {
        this.lastSelectedIndex = index;
    }

    /**
     * Handles the mouse up event for a table row.
     * 
     * @param index - The index of the row that was clicked.
     */
    mouseUp(index: number) {
        const rows = Math.abs(index - this.lastSelectedIndex) + 1;
        if (rows > 1) this._utilsService.openSnackBar(`Seleccionadas ${rows} filas`);
        this.lastSelectedIndex = -1;
    }

    /**
     * Handles the mouse enter event for a table row.
     * Emits an event with the selected rows based on the last selected index and the current index.
     * @param index - The index of the row that was entered.
     */
    mouseEnter(index: number) {
        if (this.lastSelectedIndex == -1) return;
        if (index < this.lastSelectedIndex)
            this.sendSelectedRows.emit([index, this.lastSelectedIndex]);
        else this.sendSelectedRows.emit([this.lastSelectedIndex, index]);
    }

    /**
     * Checks if the given row belongs to a company.
     * @param row - The row to check.
     * @returns True if the row belongs to a company, false otherwise.
     */
    isCompanyUser(row: any) {
        return row.companies && row.companies.includes(this.company);
    }

    /**
     * Gets the background color for a table cell based on the element and column.
     * @param element - The element representing a row in the table.
     * @param column - The column name.
     * @returns The background color for the table cell.
     */
    getBackgroundColor(element: any, column: string) {
        if(this.isTaskDateInTimeRange(element, column)){
            if(this.isCriticHourColumn(element, column)) {
                return element.date_status?.critic_color || this.defaultStatus?.critic_color;
            }
            else return element.date_status?.status_color || this.defaultStatus?.status_color
        }
        if(this.isPhoneField(column)){
            return this.setBackgroundPhoneColor(element, column);
        }
        return '';
    }

    /**
     * Sets the background color for a table cell based on the value and column name.
     * @param element - The element containing the cell value.
     * @param column - The name of the column.
     * @returns The background color for the cell.
     */
    setBackgroundPhoneColor(element: any, column: string) {
        if(column.toLowerCase().includes('telefono 1')) {
            if(this._utilsService.isRightPhone1(element)) return '#82D060';
            if(this._utilsService.isWrongPhone1(element)) return '#f44336';
        }
        if(column.toLowerCase().includes('telefono 2')) {
            if(this._utilsService.isRightPhone2(element)) return '#82D060';
            if(this._utilsService.isWrongPhone2(element)) return '#f44336';
        }
        return '';
    }

    /**
     * Checks if the given column represents a phone field.
     * @param column - The column name to check.
     * @returns A boolean value indicating whether the column represents a phone field.
     */
    isPhoneField(column: string): boolean {
        if(column) return column.toLowerCase().includes('telefono');
        return false;
    }

    /**
     * Checks if the task date is within the specified time range.
     * @param task - The WaterTask object to check.
     * @param range - The time range to compare the task date against.
     * @returns A boolean indicating whether the task date is within the time range.
     */
    isTaskDateInTimeRange(task: WaterTask, range: string): boolean {
        if(this.isHourColumn(range) && range.includes('-')) {
            const [startTime, endTime] = range.split('-');
            return this._utilsService.isTaskDateInTimeRange(task, startTime, endTime);
        }
        return false;
    }

    /**
     * Checks if a given column represents an hour.
     * @param column - The column to check.
     * @returns True if the column represents an hour, false otherwise.
     */
    isHourColumn(column: string): boolean {
        return this.hoursList.includes(column);
    }

    /**
     * Checks if the given column is not an hour column.
     * 
     * @param column - The name of the column to check.
     * @returns `true` if the column is not an hour column, `false` otherwise.
     */
    isNotHourColumn(column: string): boolean {
        return !this.isHourColumn(column);
    }

    /**
     * Checks if the given column represents an end hour.
     * 
     * @param column - The column to check.
     * @returns `true` if the column represents an end hour, `false` otherwise.
     */
    isEndHourColumn(column: string): boolean {
        if(this.isHourColumn(column)){
            if(column.includes('-')){
                const split = column.split('-');
                if(split.length > 1){
                    return split[1].includes(':00');
                }
            }
        }
        return false;
    }

    /**
     * Checks if the given column represents a start hour.
     * 
     * @param column - The column to check.
     * @returns `true` if the column represents a start hour, `false` otherwise.
     */
    isStartHourColumn(column: string): boolean {
        if(this.isHourColumn(column)){
            if(column.includes('-')){
                const split = column.split('-');
                if(split.length > 1){
                    return split[0].includes(':00');
                }
            }
        }
        return false;
    }

    /**
     * Checks if the given task falls within the specified time range and has a critical hour status.
     * @param task - The water task to check.
     * @param range - The time range to compare against.
     * @returns True if the task falls within the time range and has a critical hour status, false otherwise.
     */
    isCriticHourColumn(task: WaterTask, range: string): boolean {
        if(task.date_status?.value === 2 && range.includes('-')) {
            const [_, endTime] = range.split('-');
            const taskEndTime = moment(task.fecha_hora_cita_end, 'HH:mm');
            const rangeEndTime = moment(task.fecha_hora_cita).set(this._utilsService.getTime(endTime));
            const rangeEndTimeMinus20Minutes = rangeEndTime.clone().subtract(20, 'minutes'); // Subtract 20 minutes from rangeEndTime
            // Check if taskEndTime is after rangeEndTimeMinus20Minutes and  taskEndTime is same or before rangeEndTime 
            if(taskEndTime.isSameOrAfter(rangeEndTimeMinus20Minutes) 
                && taskEndTime.isSameOrBefore(rangeEndTime)) return true; 
        }
        return false;
    }
}

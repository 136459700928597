/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';

import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { getItacFormControls, Itac } from 'src/app/interfaces/itac';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { ItacSectionData } from '../section1/section1.component';

@Component({
    selector: 'app-section5',
    templateUrl: './section5.component.html',
    styleUrls: ['./section5.component.scss'],
})
export class Section5Component implements OnInit {
    statuses: string[] = ['SIN COMPROBAR', 'EN BUEN ESTADO', 'ESTADO REGULAR', 'EN MAL ESTADO'];

    valve_options: string[] = [
        'Sin comprobar',
        'No tiene',
        'De Bola',
        'De Compuerta',
        'De Batería Bola',
        'De Batería M.T.C',
        'De Batería M.P.F',
        'De Batería M.M.V',
    ];

    itac?: Itac;

    itacFormData: FormGroup = getItacFormControls();

    loading: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ItacSectionData,
        private _utilsService: UtilsService,
        private _apiService: ApiService,
        private _spinner: NgxSpinnerService
    ) {
        this.itac = data.itac;
        console.log(this.itac);
    }

    async saveData() {
        this.showLoading(true);
        this.saveFormData();
        this.calculatePoints();
        const result: boolean = await this._apiService.updateItac(
            this.itac!.id!.toString(),
            this.itac
        );
        this.showLoading(false);
        if (result) {
            this._utilsService.openSnackBar('Itac actualizada correctamente');
        } else {
            this._utilsService.openSnackBar('Error actualizando itac', 'error');
        }
        this._utilsService.closeSection5Dialog(result);
    }
    saveFormData() {
        const keys = Object.keys(this.itacFormData.controls);
        for (let key of keys) {
            let value = this.itacFormData.controls[key].value; //this.itac[key as keyof Itac];
            try {
                value = this._utilsService.getProcessedValue(key, value);
                this.itac![key as keyof Itac] = value;
            } catch (err) {
                console.log('************* err *************');
                console.log(err);
            }
        }
        this.itac!.date_time_modified = new Date();
    }

    ngOnInit(): void {
        this.itacFormData = this._utilsService.getFormFromItac(this.itac!);
    }

    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this._spinner.show('itacSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this._spinner.hide('itacSpinner');
        }
    }

    calculatePoints() {
        let sectionPoints = 0;

        let name = this.itac!.valvula_general;
        let estado = this.itac!.extras_valvula_general;

        if (name?.toLowerCase().includes('No Tiene'.toLowerCase())) {
            sectionPoints += -0.8;
        } else if (name?.toLowerCase().includes('Bola'.toLowerCase())) {
            if (estado == 'SIN COMPROBAR' || estado == 'ESTADO REGULAR') {
                sectionPoints += 0.8;
            } else if (estado == 'EN BUEN ESTADO') {
                sectionPoints += 1;
            } else if (estado == 'EN MAL ESTADO') {
                sectionPoints += -0.8;
            }
        } else if (name?.toLowerCase().includes('Compuerta'.toLowerCase())) {
            if (estado == 'SIN COMPROBAR' || estado == 'ESTADO REGULAR') {
                sectionPoints += 0.3;
            } else if (estado == 'EN BUEN ESTADO') {
                sectionPoints += 0.8;
            } else if (estado == 'EN MAL ESTADO') {
                sectionPoints += -0.8;
            }
        } else if (name?.toLowerCase().includes('Mariposa'.toLowerCase())) {
            if (estado == 'SIN COMPROBAR' || estado == 'ESTADO REGULAR') {
                sectionPoints += 0.8;
            } else if (estado == 'EN BUEN ESTADO') {
                sectionPoints += 1;
            } else if (estado == 'EN MAL ESTADO') {
                sectionPoints += -0.8;
            }
        }

        name = this.itac!.valvula_entrada;
        estado = this.itac!.extras_valvula_entrada;
        if (name?.toLowerCase().includes('No Tiene'.toLowerCase())) {
            sectionPoints += -3;
        } else if (name?.toLowerCase().includes('De Bola'.toLowerCase())) {
            if (estado == 'SIN COMPROBAR' || estado == 'ESTADO REGULAR') {
                sectionPoints += 2.8;
            } else if (estado == 'EN BUEN ESTADO') {
                sectionPoints += 3;
            } else if (estado == 'EN MAL ESTADO') {
                sectionPoints += -3;
            }
        } else if (name?.toLowerCase().includes('Compuerta'.toLowerCase())) {
            if (estado == 'SIN COMPROBAR' || estado == 'ESTADO REGULAR') {
                sectionPoints += 2;
            } else if (estado == 'EN BUEN ESTADO') {
                sectionPoints += 2.8;
            } else if (estado == 'EN MAL ESTADO') {
                sectionPoints += -3;
            }
        } else if (name?.toLowerCase().includes('Batería Bola'.toLowerCase())) {
            if (estado == 'SIN COMPROBAR' || estado == 'ESTADO REGULAR') {
                sectionPoints += 2;
            } else if (estado == 'EN BUEN ESTADO') {
                sectionPoints += 2.8;
            } else if (estado == 'EN MAL ESTADO') {
                sectionPoints += -3;
            }
        } else if (name?.toLowerCase().includes('M.T.C'.toLowerCase())) {
            if (estado == 'SIN COMPROBAR' || estado == 'ESTADO REGULAR') {
                sectionPoints += 2.3;
            } else if (estado == 'EN BUEN ESTADO') {
                sectionPoints += 2.5;
            } else if (estado == 'EN MAL ESTADO') {
                sectionPoints += -3;
            }
        } else if (name?.toLowerCase().includes('M.P.F'.toLowerCase())) {
            if (estado == 'SIN COMPROBAR' || estado == 'ESTADO REGULAR') {
                sectionPoints += 2;
            } else if (estado == 'EN BUEN ESTADO') {
                sectionPoints += 2.3;
            } else if (estado == 'EN MAL ESTADO') {
                sectionPoints += -3;
            }
        } else if (name?.toLowerCase().includes('M.M.V'.toLowerCase())) {
            if (estado == 'SIN COMPROBAR' || estado == 'ESTADO REGULAR') {
                sectionPoints += 0;
            } else if (estado == 'EN BUEN ESTADO') {
                sectionPoints += 1.5;
            } else if (estado == 'EN MAL ESTADO') {
                sectionPoints += -3;
            }
        }

        name = this.itac!.valvula_salida;
        estado = this.itac!.extras_valvula_salida;
        if (name?.toLowerCase().includes('No Tiene'.toLowerCase())) {
            sectionPoints += -1;
        } else if (name?.toLowerCase().includes('De Bola'.toLowerCase())) {
            if (estado == 'SIN COMPROBAR' || estado == 'ESTADO REGULAR') {
                sectionPoints += 0.3;
            } else if (estado == 'EN BUEN ESTADO') {
                sectionPoints += 0.5;
            } else if (estado == 'EN MAL ESTADO') {
                sectionPoints += -1;
            }
        } else if (name?.toLowerCase().includes('Compuerta'.toLowerCase())) {
            if (estado == 'SIN COMPROBAR' || estado == 'ESTADO REGULAR') {
                sectionPoints += 0.15;
            } else if (estado == 'EN BUEN ESTADO') {
                sectionPoints += 0.3;
            } else if (estado == 'EN MAL ESTADO') {
                sectionPoints += -1;
            }
        } else if (name?.toLowerCase().includes('Batería Bola'.toLowerCase())) {
            if (estado == 'SIN COMPROBAR' || estado == 'ESTADO REGULAR') {
                sectionPoints += 0.3;
            } else if (estado == 'EN BUEN ESTADO') {
                sectionPoints += 0.5;
            } else if (estado == 'EN MAL ESTADO') {
                sectionPoints += -1;
            }
        } else if (name?.toLowerCase().includes('M.T.C'.toLowerCase())) {
            if (estado == 'SIN COMPROBAR' || estado == 'ESTADO REGULAR') {
                sectionPoints += 0.2;
            } else if (estado == 'EN BUEN ESTADO') {
                sectionPoints += 0.25;
            } else if (estado == 'EN MAL ESTADO') {
                sectionPoints += -1;
            }
        } else if (name?.toLowerCase().includes('M.P.F'.toLowerCase())) {
            if (estado == 'SIN COMPROBAR' || estado == 'ESTADO REGULAR') {
                sectionPoints += 0.15;
            } else if (estado == 'EN BUEN ESTADO') {
                sectionPoints += 0.3;
            } else if (estado == 'EN MAL ESTADO') {
                sectionPoints += -1;
            }
        } else if (name?.toLowerCase().includes('M.M.V'.toLowerCase())) {
            if (estado == 'SIN COMPROBAR' || estado == 'ESTADO REGULAR') {
                sectionPoints += 0.1;
            } else if (estado == 'EN BUEN ESTADO') {
                sectionPoints += 0.2;
            } else if (estado == 'EN MAL ESTADO') {
                sectionPoints += -1;
            }
        }

        name = this.itac!.valvula_antiretorno;
        estado = this.itac!.extras_valvula_antiretorno;
        if (name?.toLowerCase().includes('No Tiene'.toLowerCase())) {
            sectionPoints += -1;
        } else if (name?.toLowerCase().includes('de Salida'.toLowerCase())) {
            if (estado == 'SIN COMPROBAR' || estado == 'ESTADO REGULAR') {
                sectionPoints += 0.25;
            } else if (estado == 'EN BUEN ESTADO') {
                sectionPoints += 0.5;
            } else if (estado == 'EN MAL ESTADO') {
                sectionPoints += 0;
            }
        } else if (name?.toLowerCase().includes('Cilindro'.toLowerCase())) {
            if (estado == 'SIN COMPROBAR' || estado == 'ESTADO REGULAR') {
                sectionPoints += 0.25;
            } else if (estado == 'EN BUEN ESTADO') {
                sectionPoints += 0.5;
            } else if (estado == 'EN MAL ESTADO') {
                sectionPoints += -1;
            }
        } else if (name?.toLowerCase().includes('Escuadra'.toLowerCase())) {
            if (estado == 'SIN COMPROBAR' || estado == 'ESTADO REGULAR') {
                sectionPoints += 0.2;
            } else if (estado == 'EN BUEN ESTADO') {
                sectionPoints += 0.3;
            } else if (estado == 'EN MAL ESTADO') {
                sectionPoints += -1;
            }
        }
        this.itac!.puntuacion_seccion5 = sectionPoints;
    }
}

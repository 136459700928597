/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomPaginator } from 'src/app/configurations/custom-paginator-configuration';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    providers: [
        { provide: MatPaginatorIntl, useValue: CustomPaginator() }, // Here
    ],
})
export class FooterComponent implements OnInit {
    @Input() length: number = 0;
    @Input() showPaginator: boolean = true;
    @Input() pageSize: number = 200;
    @Input() pageIndex: number = 0;
    @Input() pageSizeOptions: number[] = [10, 50, 100, 200, 500];
    @Input() showMapButton: boolean = true;
    @Input() showNewButton: boolean = true;
    @Input() showDeleteButton: boolean = true;
    @Input() showExcelButton: boolean = true;
    @Input() imageLogo?: string;

    @Output() sendPageEvent: EventEmitter<any>;
    @Output() sendOpenMaps: EventEmitter<void>;
    @Output() sendExportTable: EventEmitter<void>;
    @Output() sendAddRow: EventEmitter<void>;
    @Output() sendDelete: EventEmitter<void>;
    @Output() sendUpdate: EventEmitter<void>;

    constructor(public _utilsService: UtilsService) {
        this.sendPageEvent = new EventEmitter();
        this.sendOpenMaps = new EventEmitter();
        this.sendExportTable = new EventEmitter();
        this.sendAddRow = new EventEmitter();
        this.sendDelete = new EventEmitter();
        this.sendUpdate = new EventEmitter();
    }

    ngOnInit(): void {}

    openMaps() {
        this.sendOpenMaps.emit();
    }
    exportTable() {
        this.sendExportTable.emit();
    }
    addRow() {
        this.sendAddRow.emit();
    }
    deleteRows() {
        this.sendDelete.emit();
    }
    updateTasks() {
        this.sendUpdate.emit();
    }
    pageEvent(event: any) {
        this.sendPageEvent.emit(event);
    }
    showCompanyAndManager() {
        this._utilsService.showCompanyAndManager();
    }
}

import { Component, Inject, OnInit } from '@angular/core';
import { getPlanningDetailExtraFormControls, PlanningDetailExtra } from '../../../interfaces/planning-detail-extra';
import { FormGroup, FormControl } from '@angular/forms';
import { faBuilding, faFilePdf, faKey, faInbox } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { ApiService } from '../../../services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { NgxSpinnerService } from 'ngx-spinner';

import * as moment from 'moment';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Planning } from '../../../interfaces/planning';
import { PlanningDetail } from 'src/app/interfaces/planning-detail';

@Component({
  selector: 'app-planning-detail-extra',
  templateUrl: './planning-detail-extra.component.html',
  styleUrls: ['./planning-detail-extra.component.scss']
})
export class PlanningDetailExtraComponent implements OnInit {
    plannings: string[] = [];
    planningDetails: string[] = [];

    faFilePdf = faFilePdf;
    faInbox = faInbox;
    faBuilding = faBuilding;
    faKey = faKey;
    loading: boolean = false;
    planningDetailExtraId: string = '';
    planningDetailExtra?: PlanningDetailExtra;

    planningDetailExtraFormData: FormGroup = getPlanningDetailExtraFormControls();

    planningDetailExtraSubcription$?: Subscription;

    /**
     * Represents the PlanningDetailExtraComponent class.
     * This component is responsible for handling the planningDetailExtra data.
     */
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _apiService: ApiService,
        private _utilsService: UtilsService,
        private spinner: NgxSpinnerService,
    ) {
        this.planningDetailExtraId = data.planningDetailExtraId;
    }

    /**
     * Initializes the component after Angular has initialized all data-bound properties.
     * If a `planningDetailExtraId` is provided, it retrieves the corresponding planningDetailExtra document from the API
     * and populates the component with the retrieved data.
     * If the document is not found, an error message is displayed.
     * Finally, it sets up the form controls with the retrieved planningDetailExtra data.
     * @returns A promise that resolves when the initialization is complete.
     */
    async ngOnInit(): Promise<void> {
        await this.addTablesValues();
        if (this.planningDetailExtraId) {
            this.showLoading(true);
            this.planningDetailExtraSubcription$ = this._apiService
                .getDocument('planning-detail-extra', this.planningDetailExtraId)
                .subscribe(async (doc: any) => {
                    await this.addData(doc);
                });
        }
        else await this.addFromControls();
    }

    /**
     * Adds data to the planning detail extra.
     * 
     * @param doc - The document containing the data.
     * @returns Promise<void> - A promise that resolves when the data is added.
     */
    async addData(doc: any) { 
        if (!doc) {
            this._utilsService.openSnackBar(
                'Error obteniendo datos de planningDetailExtra',
                'error'
            );
            this.showLoading(false);
            return;             
        }
        const planningDetailExtra = doc as PlanningDetailExtra;
        planningDetailExtra.id = parseInt(this.planningDetailExtraId);
        this.planningDetailExtra = planningDetailExtra;
        const planningDetailExtraFormData = getPlanningDetailExtraFormControls();
        const keys = Object.keys(planningDetailExtra);
        for (let key of keys) {
            let value: any = planningDetailExtra[key as keyof PlanningDetailExtra];
            if (value) {
                if (this._utilsService.isValidDate(value)) value = value;
                planningDetailExtraFormData.controls[key].setValue(value);
            }
        }
        this.planningDetailExtraFormData = planningDetailExtraFormData;
        await this.addFromControls();
        this.showLoading(false);
    }

    /**
     * Retrieves the planning values from the API and assigns them to the 'plannings' property.
     * @returns A promise that resolves when the values are successfully retrieved and assigned.
     */
    async addTablesValues(): Promise<void> {
        const plannings = await this._apiService.getPlannings();
        this.plannings = plannings.map((planning: Planning) => `${planning.codigo_planning}`);
        const planningDetails = await this._apiService.getPlanningDetails();
        this.planningDetails = planningDetails.map((planningDetails: PlanningDetail) => `${planningDetails.codigo_planning_details}`);
    }

    /**
     * Adds event listeners to the form controls and updates the value of 'planning_details' based on the changes in 'codigo_planning_details' and 'explanation'.
     * 
     * @returns A Promise that resolves when the event listeners are added.
     */
    async addFromControls(): Promise<void> {
        // this.planningDetailExtraFormData.controls['planning_details'].disable();

        // this.planningDetailExtraFormData.controls['numeric_code'].valueChanges.subscribe(async (_: string) => {
        //     this.buildPlanningDetailExtra();
        // });  
        // this.planningDetailExtraFormData.controls['codigo_planning_details'].valueChanges.subscribe(async (_: string) => {
        //     this.buildPlanningDetailExtra();
        // });   
        // this.planningDetailExtraFormData.controls['explanation'].valueChanges.subscribe(async (_: string) => {
        //     this.buildPlanningDetailExtra();
        // });
    }

    /**
     * Builds the planning detail extra.
     */
    buildPlanningDetailExtra(): void { 
        // let codigo_planning_details = this.planningDetailExtraFormData.controls['codigo_planning_details'].value;
        // let explanation = this.planningDetailExtraFormData.controls['explanation'].value;
        // let numeric_code = this.planningDetailExtraFormData.controls['numeric_code'].value;
        // if(this._utilsService.isFieldNotValid(codigo_planning_details)) codigo_planning_details = '';
        // if(this._utilsService.isFieldNotValid(explanation)) explanation = '';
        // if(this._utilsService.isFieldNotValid(numeric_code)) numeric_code = '';
        // this.planningDetailExtraFormData.controls['planning_details'].setValue(`${numeric_code} - ${codigo_planning_details} - ${explanation}`);
    }

    /**
    * Lifecycle hook that is called when the component is destroyed.
    * It is used to perform any necessary cleanup before the component is removed from the DOM.
    */
    ngOnDestroy(): void {
        this.planningDetailExtraSubcription$?.unsubscribe();
    }

    /**
    * Shows or hides the loading spinner based on the provided state.
    * @param state - A boolean value indicating whether to show or hide the loading spinner.
    */
    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this.spinner.show('planningDetailExtraSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this.spinner.hide('planningDetailExtraSpinner');
        }
    }
    
    /**
     * Saves the form data by extracting values from the controls and assigning them to the `planningDetailExtra` object.
     * Converts moment.js objects to Date objects before assigning.
     */
    saveFormData() {
        const keys = Object.keys(this.planningDetailExtraFormData.controls);
        let planningDetailExtra: any = {};
        for (let key of keys) {
            let value = this.planningDetailExtraFormData.controls[key].value; //this.planningDetailExtra[key as keyof PlanningDetailExtra];
            try {
                if (moment.isMoment(value)) {
                    value = value.toDate();
                }
                planningDetailExtra![key as keyof PlanningDetailExtra] = value;
            } catch (error) {}
        }
        planningDetailExtra!['date_time_modified'] = new Date();
        this.planningDetailExtra = planningDetailExtra;
    }

    /**
     * Saves the changes made to the planningDetailExtra.
     * If the planningDetailExtra already exists, it updates the existing planningDetailExtra.
     * If the planningDetailExtra is new, it adds the planningDetailExtra to the database.
     * Displays a success or error message based on the result.
     */
    async saveChanges() {
        if (this.isNotValidPlanningDetailExtra()) {
            this._utilsService.openSnackBar('Por favor, los campos de código planning, código común, detalle', 'error');
            return;
        }
        this.showLoading(true);
        this.saveFormData();
        if (this.planningDetailExtraId) await this.updatePlanningDetailExtra();
        else await this.addPlanningDetailExtra();
                
        this.showLoading(false);
        this._utilsService.closePlanningDetailExtraDialog(this.planningDetailExtra!);
    }

    /**
     * Adds a planning detail extra.
     *
     * @returns {Promise<void>} A promise that resolves when the planning detail extra is added successfully.
     * @throws {Error} If there is an error adding the planning detail extra.
     */
    async addPlanningDetailExtra(): Promise<void> { 
        try {
            const planningDetailExtraId = await this._apiService.addDocument('planning-detail-extra', this.planningDetailExtra);
            if (planningDetailExtraId) {
                this.planningDetailExtra!.id = planningDetailExtraId;
                this._utilsService.openSnackBar('Planificación añadida correctamente');
            } else {
                this._utilsService.openSnackBar('Error añadiendo planificación', 'error');
            }
        } catch (error) {
            this._utilsService.openSnackBar('Error añadiendo planificación', 'error');
        }
    }

    /**
     * Updates the planning detail extra.
     * 
     * @returns A promise that resolves to void.
     */
    async updatePlanningDetailExtra(): Promise<void> { 
        const planningDetailExtra: boolean = await this._apiService.updateDocument(
            'planning-detail-extra',
            this.planningDetailExtraId,
            this.planningDetailExtra
        );
        if (planningDetailExtra) {
            this._utilsService.openSnackBar('Planificación actualizada correctamente');
        } else {
            this._utilsService.openSnackBar('Error actualizando planificación', 'error');
        }
    }

    /**
     * Checks if the planning detail extra is not valid.
     * 
     * @returns A boolean indicating whether the planning detail extra is not valid.
     */
    isNotValidPlanningDetailExtra(): boolean { 
        return !this.isValidPlanningDetailExtra();
    }

    /**
     * Checks if the planning detail extra is valid.
     * 
     * @returns {boolean} True if the planning detail extra is valid, false otherwise.
     */
    isValidPlanningDetailExtra(): boolean {
        if (this.planningDetailExtraFormData.controls['common_code'].value
            && this.planningDetailExtraFormData.controls['codigo_planning'].value
            && this.planningDetailExtraFormData.controls['codigo_planning_details'].value
            && this.planningDetailExtraFormData.controls['detail'].value) {
            return true;
        }
        return false;
    }
}

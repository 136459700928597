

<mat-dialog-content>
    
    <div [formGroup]="itacFormData">
        
        <div class="container">

            <div class="row bd-highlight" class="row-content-center">

                <p style="font-size: large; margin: 6px; margin-bottom: 20px;">
                    Sección <i class="fa fa-500px" aria-hidden="true"></i> Estado de Válvulas
                </p>

                <div class="row">
                    <mat-form-field appearance="fill">
                        <mat-label>Válvula General</mat-label>
                        <mat-select formControlName="valvula_general">
                            <mat-option *ngFor="let option of ['Sin comprobar','No tiene','De Bola','De Compuerta','De Mariposa']" [value]="option">{{ option }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Estado Válvula General</mat-label>
                        <mat-select formControlName="extras_valvula_general">
                            <mat-option *ngFor="let option of statuses" [value]="option">{{ option }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Válvula Antiretorno</mat-label>
                        <mat-select formControlName="valvula_antiretorno">
                            <mat-option *ngFor="let option of ['Sin comprobar','No tiene','Dentro de Válvula de Salida','Modelo Cilindro','De Escuadra']" [value]="option">{{ option }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Estado Válvula Antiretorno</mat-label>
                        <mat-select formControlName="extras_valvula_antiretorno">
                            <mat-option *ngFor="let option of statuses" [value]="option">{{ option }}</mat-option>
                        </mat-select>
                    </mat-form-field> 
                </div>

                <div class="row">
                    <mat-form-field appearance="fill">
                        <mat-label>Válvula de Entrada al Contador</mat-label>
                        <mat-select formControlName="valvula_entrada">
                            <mat-option *ngFor="let option of valve_options" [value]="option">{{ option }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Estado Válvula de Entrada</mat-label>
                        <mat-select formControlName="extras_valvula_entrada">
                            <mat-option *ngFor="let option of statuses" [value]="option">{{ option }}</mat-option>
                        </mat-select>
                    </mat-form-field> 
                    <mat-form-field appearance="fill">
                        <mat-label>Válvula de Salida del Contador</mat-label>
                        <mat-select formControlName="valvula_salida">
                            <mat-option *ngFor="let option of valve_options" [value]="option">{{ option }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Estado Válvula de Salida</mat-label>
                        <mat-select formControlName="extras_valvula_salida">
                            <mat-option *ngFor="let option of statuses" [value]="option">{{ option }}</mat-option>
                        </mat-select>
                    </mat-form-field> 
                </div>  

                <div class="row" style="display: flex;">

                    <mat-form-field style="flex: 3;" appearance="fill">
                        <mat-label>Nota</mat-label>
                        <textarea matInput formControlName="estado_de_valvulas_nota"
                            placeholder="Nota"
                            cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="3">
                        </textarea>
                    </mat-form-field> 

                </div>               
        </div>
    </div>
    
</div>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false">Cancelar</button>
    <button mat-button cdkFocusInitial
    (click)="saveData()"> 
    <mat-icon color="primary">check</mat-icon> Actualizar
</button>
</mat-dialog-actions>

<ngx-spinner [bdColor] = "(itac)?'rgba(0, 0, 0, 0.2)': 'rgba(255, 255, 255, 1)'" size = "large" color = "#368DCE" 
type = "ball-scale-multiple" [fullScreen] = "true" name="itacSpinner">
</ngx-spinner>
<div style="margin: 0;">
    <mat-icon color="primary"
        [mtxTooltip]="tooltipTpl"
        [mtxTooltipPosition]="'right'"
        aria-label="Icon"
    >image
    </mat-icon>
</div>

<ng-template #tooltipTpl>
    <div style="background: gray; border-radius: 5px; margin: auto;">
        <img *ngFor="let image of images"[src]="image" alt="" 
        style="margin: 5px; margin-top: 5px; margin-bottom: -2px; object-fit: cover;" 
        width="240" height="180"
        >
    </div>
</ng-template>
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safe'
})
// To mark the passed HTML as trusted so that Angulars DOM sanitizer doesn't strip parts of 
export class SafePipe implements PipeTransform {
  
  constructor(private sanitizer: DomSanitizer){}

  transform(value: any, args?: any): any {
    return this.sanitizer.bypassSecurityTrustHtml(value);
    // return this.sanitizer.bypassSecurityTrustStyle(style);
    // return this.sanitizer.bypassSecurityTrustXxx(style); - see docs
  }
}

//<div [innerHTML]="myField | safeHtml"></div> //Example of using this pipe
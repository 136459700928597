import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '../services/utils.service';

@Pipe({
    name: 'companyPipe',
})
export class CompanyPipe implements PipeTransform {
    constructor(private _utilsService: UtilsService) {}

    transform(value: string, ...args: unknown[]): string {
        let companyName = this._utilsService.companyPipe(value);
        return companyName;
    }
}

<div *ngIf="electronService.isElectronApp()" class="floating-top-left" (click)="location.back()">
    <button mat-fab color="primary">
        <mat-icon style="margin-left: 10px" matTooltip="Ir atrás">arrow_back_ios</mat-icon>
    </button>
</div>

<div class="screen">
    <div class="row1">
        
        <div [formGroup]="avgInADayPerYearPerUserGroup" 
            [ngClass]="{
                'avg_in_a_day_per_year_per_user': !avgInADayPerYearPerUserFullScreen, 
                'full_screen': avgInADayPerYearPerUserFullScreen
            }">
            <div class="tag_container">
                <h3 class="h3_title"  style="margin-left: 80px;">
                    Promedio diario al año por operario
                </h3>
                <mat-form-field appearance="standard" style="width: 60px">
                    <mat-label>Tipo</mat-label>
                    <mat-select formControlName="type">
                        <mat-option *ngFor="let type of chartTypes" [value]="type">{{ type }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Operarios</mat-label>
                    <mat-select multiple formControlName="users">
                        <mat-option *ngFor="let user of users" [value]="user">{{ user | user }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <button mat-icon-button matTooltip="Pantalla completa" color="primary"
                    (click)="toggleAvgInADayPerYearPerUserFullScreen();">
                    <mat-icon *ngIf="!avgInADayPerYearPerUserFullScreen">open_in_new</mat-icon>
                    <mat-icon *ngIf="avgInADayPerYearPerUserFullScreen">minimize</mat-icon>
                </button>
            </div>
            <div class="chart_container">
                <canvas *ngIf="!loadingAvgInADayPerYearPerUser" baseChart
                    [options]="barChartOptions"
                    [datasets]="avgInADayPerYearPerUserChartData"
                    [labels]="avgInADayPerYearPerUserChartLabels"
                    [legend]="avgInADayPerYearPerUserChartLegend"
                    [type]="avgInADayPerYearPerUserChartType">
                </canvas>
                <div *ngIf="loadingAvgInADayPerYearPerUser">
                    <mat-spinner style="margin: auto;" diameter="50"></mat-spinner>
                </div>
            </div>
        </div>

        <div [formGroup]="avgInADayPerYearGroup"
            [ngClass]="{
                'avg_in_a_day_per_year': !avgInADayPerYearFullScreen, 
                'full_screen': avgInADayPerYearFullScreen
            }">
            <div class="tag_container">
                <h3 class="h3_title">
                    Promedio diario al año (Todos los operarios)
                </h3>
                <mat-form-field appearance="standard" style="width: 60px">
                    <mat-label>Tipo</mat-label>
                    <mat-select formControlName="type">
                        <mat-option *ngFor="let type of chartTypes" [value]="type">{{ type }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <button mat-icon-button matTooltip="Pantalla completa" color="primary"
                    (click)="toggleAvgInADayPerYearFullScreen();">
                    <mat-icon *ngIf="!avgInADayPerYearFullScreen">open_in_new</mat-icon>
                    <mat-icon *ngIf="avgInADayPerYearFullScreen">minimize</mat-icon>
                </button>
            </div>
            <div class="chart_container">
                <canvas *ngIf="!loadingAvgInADayPerYear" baseChart
                    [options]="barChartOptions"
                    [datasets]="avgInADayPerYearChartData"
                    [labels]="avgInADayPerYearChartLabels"
                    [legend]="avgInADayPerYearChartLegend"
                    [type]="avgInADayPerYearChartType">
                </canvas>
                <div *ngIf="loadingAvgInADayPerYear">
                    <mat-spinner style="margin: auto;" diameter="50"></mat-spinner>
                </div>
            </div>
        </div>

    </div>

    <div class="row2">

        <div [formGroup]="avgInADayPerMonthPerUserGroup" 
            [ngClass]="{
                'avg_in_a_day_per_month_per_user': !avgInADayPerMonthPerUserFullScreen, 
                'full_screen': avgInADayPerMonthPerUserFullScreen
            }">
            <div class="tag_container">
                <h3 class="h3_title">
                    Promedio diario por mes por operario
                </h3>
                <mat-form-field appearance="standard" style="width: 60px">
                    <mat-label>Tipo</mat-label>
                    <mat-select formControlName="type">
                        <mat-option *ngFor="let type of chartTypes" [value]="type">{{ type }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" style="max-width: 100px;">
                    <mat-label>Año</mat-label>
                    <mat-select formControlName="year">
                        <mat-option *ngFor="let year of yearLabels" [value]="+year">{{ year }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Operarios</mat-label>
                    <mat-select multiple formControlName="users">
                        <mat-option *ngFor="let user of users" [value]="user">{{ user | user }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <button mat-icon-button matTooltip="Pantalla completa" color="primary"
                    (click)="toggleAvgInADayPerMonthPerUserFullScreen();">
                    <mat-icon *ngIf="!avgInADayPerMonthPerUserFullScreen">open_in_new</mat-icon>
                    <mat-icon *ngIf="avgInADayPerMonthPerUserFullScreen">minimize</mat-icon>
                </button>
            </div>
            <div class="chart_container">
                    <canvas *ngIf="!loadingAvgInADayPerMonthPerUser" baseChart
                        [options]="barChartOptions"
                        [datasets]="avgInADayPerMonthPerUserChartData"
                        [labels]="monthLabels"
                        [legend]="avgInADayPerMonthPerUserChartLegend"
                        [type]="avgInADayPerMonthPerUserChartType">
                    </canvas>
                    <div *ngIf="loadingAvgInADayPerMonthPerUser">
                        <mat-spinner style="margin: auto;" diameter="50"></mat-spinner>
                    </div>
            </div>
        </div>

        <div [formGroup]="avgInADayPerMonthGroup" 
            [ngClass]="{
                'avg_in_a_day_per_month': !avgInADayPerMonthFullScreen, 
                'full_screen': avgInADayPerMonthFullScreen
            }">
            <div class="tag_container">
                <h3 class="h3_title">
                    Promedio diario por Mes (Todos)
                </h3>
                <mat-form-field appearance="standard" style="width: 60px">
                    <mat-label>Tipo</mat-label>
                    <mat-select formControlName="type">
                        <mat-option *ngFor="let type of chartTypes" [value]="type">{{ type }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" style="max-width: 100px;">
                    <mat-label>Año</mat-label>
                    <mat-select formControlName="year">
                        <mat-option *ngFor="let year of yearLabels" [value]="+year">{{ year }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <button mat-icon-button matTooltip="Pantalla completa" color="primary"
                    (click)="toggleAvgInADayPerMonthFullScreen();">
                    <mat-icon *ngIf="!avgInADayPerMonthFullScreen">open_in_new</mat-icon>
                    <mat-icon *ngIf="avgInADayPerMonthFullScreen">minimize</mat-icon>
                </button>
            </div>
            <div class="chart_container">
                    <canvas *ngIf="!loadingAvgInADayPerMonth" baseChart
                        [options]="barChartOptions"
                        [datasets]="avgInADayPerMonthChartData"
                        [labels]="monthLabels"
                        [legend]="avgInADayPerMonthChartLegend"
                        [type]="avgInADayPerMonthChartType">
                    </canvas>
                    <div *ngIf="loadingAvgInADayPerMonth">
                        <mat-spinner style="margin: auto;" diameter="50"></mat-spinner>
                    </div>
            </div>
        </div>

    </div>

</div>
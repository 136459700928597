import { Injectable } from '@angular/core';
import { initializeApp, messaging } from 'firebase/app';
import 'firebase/messaging';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { UtilsService } from './utils.service';

@Injectable({
    providedIn: 'root'
})
export class PushNotificationService {
    messagingFirebase: firebase.messaging.Messaging;

    constructor(private _utilsService: UtilsService) {
        initializeApp(environment.firebaseConfig);
        this.messagingFirebase = messaging();
    }

    async requestPermission(): Promise<string> {
        return new Promise(async (resolve, _) => {
            if (!('Notification' in window)) {
                this.showNotificationNotSupportError();
            } else if (Notification.permission === 'granted') {
                await this.getAndSaveFirebaseToken();
                resolve('granted');
            } else if (Notification.permission !== 'denied') {
                await this.askPermission();
                resolve('granted');
            } else this.showNotificationError();
        });
    }

    async askPermission(){
        return new Promise(async (resolve, _) => {
            Notification.requestPermission().then(async (permission) => {
                if (permission === 'granted') {
                    await this.getAndSaveFirebaseToken();
                    resolve(permission);
                } else this.showNotificationError();
            }); 
        });
    }

    async getAndSaveFirebaseToken(){
        const token = await this.messagingFirebase.getToken();
        this._utilsService.saveFirebaseToken(token);
    }

    showNotificationNotSupportError(){
        const text = 'Este navegador no soporta la mensajería';
        this._utilsService.openSnackBar(text, 'error', 6000, '💬', ()=>{
            const info = 'Debe cambiar de navegador para poder recibir las notificaciones';
            this._utilsService.openSnackBar(info);
        });
    }

    showNotificationError(){
        const text = 'Son necesarios los permisos de notificación para la mensajería';
        this._utilsService.openSnackBar(text, 'error', 6000, '💬', ()=>{
            const info = 'Debe abrir la configuración del navegador y permitir manualmente las notificaciones';
            this._utilsService.openSnackBar(info);
        });
    }

    private messaginObservable = new Observable<any>(observe => {
        this.messagingFirebase.onMessage((payload: any) => {
            observe.next(payload)
        })
    })

    receiveMessage() {
        return this.messaginObservable;
    }

    // private backgroundMessageObservable = new Observable<any>(observe => {
    //     this.messagingFirebase.onBackgroundMessage((payload: any) => {
    //         observe.next(payload)
    //     })
    // })

    // receiveBackgroundMessage() {
    //     return this.backgroundMessageObservable;
    // }
}
/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import * as moment from 'moment';
import { Moment } from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import {
    Caliber,
    getCaliberDisplayColumns,
    getCaliberExcelExportColumns,
    getCaliberExcelFieldName,
    getCaliberField,
    getCaliberFieldType,
} from 'src/app/interfaces/caliber';
import { ApiService } from 'src/app/services/api.service';
import { MessagingService } from 'src/app/services/messaging.service';
import { UtilsService } from 'src/app/services/utils.service';
import { WindowRefService } from 'src/app/services/window-ref.service';
import * as XLSX from 'xlsx';
import { faInbox } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-calibers',
    templateUrl: './calibers.component.html',
    styleUrls: ['./calibers.component.scss'],
})
export class CalibersComponent implements OnInit {
    loading: boolean = true;
    tableName: string = 'calibers';
    calibersInPage: Caliber[] = [];
    dataSource: MatTableDataSource<Caliber> = new MatTableDataSource();

    length = 0; //task count in current table
    pageSize = 200; //limit of query
    lastPageIndex = 0;
    pageSizeOptions: number[] = [10, 50, 100, 200, 500];

    displayedColumns: string[] = getCaliberDisplayColumns();
    fixedColumns = [];
    displayedColumnsField: string[] = this.displayedColumns.map((displayedColumn: string) =>
        getCaliberField(displayedColumn)
    );
    clickedRows = new Set<Caliber>();

    lastSelectedRow: number = -1;

    filteredColumn?: string;
    orderedColumn?: string;

    loadingText = 'Cargando...';

    faInbox = faInbox;

    constructor(
        private _apiService: ApiService,
        private _utilsService: UtilsService,
        private _windowRefService: WindowRefService,
        private _spinner: NgxSpinnerService,
        private _messagingService: MessagingService
    ) {}

    async ngOnInit(): Promise<void> {
        await this.getCalibers();
    }

    async fileEvent(event: any) {
        if (event['file_option'] == 'Importar calibres') {
            this.processExcelFile(event);
        }
    }
    async processExcelFile(event: any) {
        this._spinner.show();
        this.loadingText = `Cargando ...`;
        //TODO: add calibers from excel
        let workBook: XLSX.WorkBook;
        let jsonData = null;
        const reader = new FileReader();
        const file = event['file'].target.files[0];
        reader.onload = async (_) => {
            this._spinner.show();
            this.loadingText = `Procesando calibres ...`;
            const data = reader.result;
            workBook = XLSX.read(data, { type: 'binary' });
            jsonData = workBook.SheetNames.reduce((initial: any, name) => {
                const sheet = workBook.Sheets[name];
                initial[name] = XLSX.utils.sheet_to_json(sheet);
                return initial;
            }, {});
            let sheets: string[] = [];
            let arrayCalibers: Caliber[] = [];

            Object.keys(jsonData).forEach((key: string) => {
                sheets.push(key);
            });
            for (let sheet of sheets) {
                for (let jsonCaliber of jsonData[sheet]) {
                    let caliberData: any = {};
                    Object.keys(jsonCaliber).forEach((key) => {
                        let field: string = getCaliberExcelFieldName(key);
                        if (this._utilsService.isFieldValid(field)) {
                            let value = jsonCaliber[key];
                            try {
                                if (typeof value === 'number') {
                                    if (getCaliberFieldType(field) == 'number') {
                                        caliberData[`${field}`] = value;
                                    } else {
                                        caliberData[`${field}`] = value.toString().trim();
                                    }
                                } else if (
                                    typeof value === 'string' &&
                                    this._utilsService.isFieldValid(value)
                                ) {
                                    if (getCaliberFieldType(field) == 'string') {
                                        caliberData[`${field}`] = value.trim();
                                    } else {
                                        caliberData[`${field}`] = parseInt(value);
                                    }
                                }
                            } catch (error) {
                                console.log(value);
                            }
                        }
                    });
                    let caliber = caliberData as Caliber;
                    if (caliber && this._utilsService.isFieldValid(caliber.codigo_calibre)) {
                        caliber.codigo_calibre = this._utilsService.addCeros(
                            caliber.codigo_calibre,
                            3
                        );
                        arrayCalibers.push(caliber);
                    }
                }
            }
            console.log('============= arrayCalibers =============');
            console.log(arrayCalibers);
            const result = await this.importCalibers(arrayCalibers);
            this._spinner.hide();
        };
        reader.readAsBinaryString(file);

        this._spinner.hide();
    }

    async importCalibers(calibers: Caliber[]) {
        this._spinner.show();
        this.loadingText = `Añadiendo calibres ...`;
        let i = 0,
            errorIds = [];
        for (let caliber of calibers) {
            this.loadingText = `Añadiendo calibres ${++i} de ${calibers.length}`;
            if (!(await this._apiService.addDocument('caliber', caliber))) {
                errorIds.push(caliber.codigo_calibre);
            }
        }
        this._spinner.hide();
        if (errorIds.length > 0) {
            this._utilsService.openSnackBar(`Hubo errores añadiendo calibres`, 'error');
        } else {
            this._utilsService.openSnackBar(`Calibres subidos correctamente`);
        }
        await this.getCalibers();
        return !(errorIds.length > 0);
    }

    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this._spinner.show('innerSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this._spinner.hide('innerSpinner');
        }
    }

    setCalibersInTable(calibers: Caliber[]) {
        this.calibersInPage = calibers;
        this.dataSource.data = calibers;
        this.showLoading(false);
    }

    async getCalibers() {
        this.setCalibersInTable(await this.selectCalibers());
    }
    async selectCalibers() {
        this.showLoading(true);
        let docs = [];
        try {
            docs = await this._apiService.getDocuments<Caliber>('caliber', undefined, [
                'codigo_calibre',
                'asc',
            ]);
        } catch (err) {
            console.log('============= err =============');
            console.log(err);
        }
        this.length = docs.length;
        return docs;
        // undefined,/*where*/undefined, /*order_clause*/undefined, '0', this.pageSize.toString());
    }

    searchValue(event: any) {
        const filterValue: string = event;
        this.dataSource!.filter = filterValue.trim().toLowerCase();
    }

    async deleteCalibers() {
        let errorIds = [];
        if (this.clickedRows.size == 0) {
            this._utilsService.openSnackBar('Debe seleccionar al menos un calibre', 'warning');
            return;
        }
        const result = await this._utilsService.openQuestionDialog(
            'Confirmación',
            '¿Desea eliminar los calibres seleccionados?'
        );
        if (result) {
            this._spinner.show();
            this.loadingText = `Eliminando calibres ...`;
            let rowIndexes = new Set<number>();
            const oldDataSource = this.dataSource!.data;
            this.clickedRows.forEach(async (row) => {
                console.log(row.id);

                const index = this.dataSource!.data.indexOf(row, 0);
                if (index > -1) {
                    rowIndexes.add(index);
                }
            });
            let calibers = [];
            let deleteCount = 0;
            for (let i = 0; i < oldDataSource.length; i++) {
                if (!rowIndexes.has(i)) {
                    calibers.push(oldDataSource![i]);
                } else {
                    this.loadingText = `Eliminando calibres ${++deleteCount} de ${rowIndexes.size}`;
                    if (
                        !(await this._apiService.deleteDocument(
                            'caliber',
                            oldDataSource[i].id!.toString()
                        ))
                    ) {
                        console.log('****************** deleteCaliber error *************');
                        errorIds.push(oldDataSource[i].id);
                    } else {
                        console.log('****************** deleteCaliber success *************');
                    }
                }
            }
            if (errorIds.length > 0) {
                this._utilsService.openSnackBar(`Hubo errores eliminando calibres`, 'error');
            } else {
                this._utilsService.openSnackBar(`Calibres eliminados correctamente`);
            }
            this.length -= rowIndexes.size;
            this.setCalibersInTable(calibers);
            this.clickedRows.clear();
            this._spinner.hide();
        }
    }

    async filterBy(column: string) {
        this.filteredColumn = getCaliberField(column);
        if (
            getCaliberFieldType(this.filteredColumn) == 'Date' ||
            getCaliberFieldType(this.filteredColumn) == 'Timestamp'
        ) {
            const dates = await this._utilsService.openDateRangeSelectorDialog(
                'Seleccione rango de fechas'
            );
            this.onDateSelected(dates);
        } else {
            const options: any[] = this.dataSource.data.map(
                (caliber: Caliber) => caliber[this.filteredColumn as keyof Caliber]
            );
            const result = await this._utilsService.openSelectorDialog(
                'Seleccione opción',
                options
            );
            if (result) {
                 
                let docs = [];
                try {
                    docs = await this._apiService.getDocuments<Caliber>('caliber', [
                        [this.filteredColumn, '==', result],
                    ]);
                } catch (err) {
                    console.log('============= err =============');
                    console.log(err);
                }
                this.length = docs.length;
                this.setCalibersInTable(docs);
            }
        }
    }
    async onDateSelected(dateRange: Date[]) {
        if (dateRange) {
            const startDate: Moment = moment(dateRange[0]);
            const startDateString = startDate.format('YYYY-MM-DD HH:mm:ss');
            let endDate: Moment = moment(dateRange[1]);
            if (!endDate) {
                endDate = startDate;
            }
            const endDateString = endDate.add(1, 'days').format('YYYY-MM-DD HH:mm:ss'); //plus one to filter range
            const values = [startDateString, endDateString];
            let docs = [];
            try {
                docs = await this._apiService.getDocuments<Caliber>('caliber', [
                    [this.filteredColumn!, '>=', startDate.toDate()],
                    [this.filteredColumn!, '<', endDate.toDate()],
                ]);
            } catch (err) {
                console.log('============= err =============');
                console.log(err);
            }
            this.length = docs.length;
            this.setCalibersInTable(docs);
        } else {
            this._utilsService.openSnackBar('Rango fechas invalido', 'error');
        }
    }

    async orderBy(event: any) {
        const column = event.column;
        let orderType = event.orderType;
        if (orderType.toLowerCase().includes('asc')) {
            orderType = 'asc';
        } else {
            orderType = 'desc';
        }
        const orderedColumn = getCaliberField(column);

        this.showLoading(true);
        const calibers = await this._apiService.getDocuments<Caliber>('caliber', undefined, [
            orderedColumn,
            orderType,
        ]);
        this.setCalibersInTable(calibers);
    }

    async addNewRow(event: any) {
        console.log('**************** addNewRow ***************');
        const caliber = await this._utilsService.openCaliberDialog('');
        console.log(caliber);
        if (caliber) {
            this.dataSource.data.push(caliber);
            const calibers = this.dataSource.data;
            this.dataSource.data = [];
            this.dataSource.data = calibers;
        }
    }
    async doubleClickedRow(row: any) {
        console.log('**************** doubleClickedRow ***************');
        console.log(row.id);
        const caliber = await this._utilsService.openCaliberDialog(row.id);
        if (caliber) {
            this.dataSource.data[row.rowIndex] = caliber;
            const calibers = this.dataSource.data;
            this.dataSource.data = [];
            this.dataSource.data = calibers;
        }
    }

    clickedRow(receivedEvent: any) {
        const row = receivedEvent.row;
        const event = receivedEvent.event;
        const rowIndex = receivedEvent.rowIndex;

        const previousRow = this.lastSelectedRow;
        this.lastSelectedRow = rowIndex;

        if (event.button === 0) {
            if (!event.ctrlKey && !event.shiftKey) {
                this.clickedRows.clear();
                this.toggleRow(row);
                // console.log('**************** !event.ctrlKey && !event.shiftKey ***************');
            } else if (event.ctrlKey) {
                this.toggleRow(row);
                // console.log('**************** event.ctrlKey ***************');
            }
            if (event.shiftKey) {
                this.selectRowsBetweenIndexes(previousRow, rowIndex);
                console.log('**************** event.shiftKey ***************');
            }
        }
        if (this._windowRefService.nativeWindow.getSelection) {
            //remove selection in table with shift
            if (this._windowRefService.nativeWindow.getSelection().empty) {
                // Chrome
                this._windowRefService.nativeWindow.getSelection().empty();
            } else if (this._windowRefService.nativeWindow.getSelection().removeAllRanges) {
                // Firefox
                this._windowRefService.nativeWindow.getSelection().removeAllRanges();
            }
        }
    }
    selectRowsBetweenIndexes(lastSelectedRow: number, rowIndex: number) {
        let start, end;
        if (rowIndex > lastSelectedRow) {
            start = lastSelectedRow;
            end = rowIndex;
        } else {
            end = lastSelectedRow;
            start = rowIndex;
        }
        for (let i = start; i <= end; i++) {
            this.clickedRows.add(this.dataSource!.data[i]);
        }
    }
    toggleRow(row: any) {
        if (this.clickedRows.has(row)) {
            this.clickedRows.delete(row);
        } else {
            this.clickedRows.add(row);
        }
    }

    exportCalibersInTable() {
        this.exportExcel(this.dataSource!.data);
    }
    exportExcel(calibers: any) {
        let excelFormatTasks = [];
        for (let caliber of calibers) {
            let data: any = {};
            const columns = getCaliberExcelExportColumns();
            columns.forEach((column) => {
                data[`${column}`] = this._utilsService.tableDataPipe(
                    caliber[getCaliberExcelFieldName(column)]
                );
            });
            excelFormatTasks.push(data);
        }
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelFormatTasks);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        /* save to file */
        XLSX.writeFile(wb, 'Calibres_Exportados.xlsx');
    }
}

import { FormControl, FormGroup } from '@angular/forms';

export interface Mark {
    codigo_marca: string;
    date_time_modified: Date;
    id: number;
    marca: string;
    modelo: string;
}

export function getMarkDisplayColumns(): string[] {
    const displayedColumns: string[] = ['Código marca', 'Marca', 'Modelo', 'Fecha de Modificación'];
    return displayedColumns;
}

export function getMarkField(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'Código marca':
            value = 'codigo_marca';
            break;
        case 'Marca':
            value = 'marca';
            break;
        case 'Modelo':
            value = 'modelo';
            break;
        case 'Fecha de Modificación':
            value = 'date_time_modified';
            break;
    }
    return value;
}

export function getMarkFieldName(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'codigo_marca':
            value = 'Código marca';
            break;
        case 'marca':
            value = 'Marca';
            break;
        case 'modelo':
            value = 'Modelo';
            break;
        case 'date_time_modified':
            value = 'Fecha de Modificación';
            break;
    }
    return value;
}

export function getMarkFormControls(): FormGroup {
    const markFormData: FormGroup = new FormGroup({
        codigo_marca: new FormControl(),
        date_time_modified: new FormControl(),
        id: new FormControl(),
        marca: new FormControl(),
        modelo: new FormControl(),
    });
    return markFormData;
}

export function getMarkFieldType(field_name: string): string {
    //in bigquery
    let value: string = '';
    switch (field_name) {
        case 'codigo_marca':
            value = 'string';
            break;
        case 'marca':
            value = 'string';
            break;
        case 'modelo':
            value = 'string';
            break;
        case 'date_time_modified':
            value = 'Date';
            break;
        case 'id':
            value = 'number';
            break;
    }
    return value;
}

export function getMarkExcelFieldName(excel_field: string): string {
    let value: string = '';
    switch (excel_field.trim().toLowerCase()) {
        case 'marca':
            value = 'marca';
            break;
        case 'modelo':
            value = 'modelo';
            break;
        case 'código marca':
            value = 'codigo_marca';
            break;
        case 'codigo marca':
            value = 'codigo_marca';
            break;
        case 'fecha de modificación':
            value = 'date_time_modified';
            break;
        case 'fecha de modificacion':
            value = 'date_time_modified';
            break;
        case 'codigo_marca':
            value = 'codigo_marca';
            break;
        case 'date_time_modified':
            value = 'date_time_modified';
            break;
    }
    return value;
}

export function getMarkExcelExportColumns(): string[] {
    const columns = ['Código marca', 'Marca', 'Modelo', 'Fecha de Modificación'];
    return columns;
}

<div>
    <div style="display: flex;">
        <mat-form-field appearance="fill" style="padding: 5px;">
            <mat-label>Código de país</mat-label>
            <mat-select name="country_code" #selectedCountryCode 
            [(value)]="selectedCountryCodeValue" required>
                <mat-option *ngFor="let country_code of country_codes" [value]="country_code" >
                    {{ country_code }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" style="padding: 5px;">
            <mat-label>Teléfono</mat-label>
            <mat-select name="phone" #selectedPhone 
            [(value)]="selectedPhoneValue" required>
                <mat-option *ngFor="let phone of phones" [value]="phone" >
                    {{ phone }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>  
    <div class="edit_button_div">
        <button mat-icon-button matTooltip="Editar mensaje" (click)="editMessage()">
            <mat-icon>edit</mat-icon>
        </button>
    </div>  
    <div>
        <textarea matInput cdkAutosizeMinRows="20">{{ messageText }}</textarea>
    </div>
</div>
    
<mat-dialog-actions align="center">
    <button mat-button [mat-dialog-close]="false">Cancelar</button>
    <button mat-button cdkFocusInitial
    (click)="sendMessage()"
    [mat-dialog-close]="true"> 
        <mat-icon color="primary">send</mat-icon> Enviar
    </button>
</mat-dialog-actions>
import { FormControl, FormGroup } from '@angular/forms';

export interface WaterRoute {
    ID?: number;
    barrio?: string;
    calle?: string;
    codigo_ruta?: string;
    date_time_modified?: Date;
    distrito?: string;
    id?: number;
    municipio?: string;
    portal?: string;
    radio_portal?: string;
    radio_exist?: boolean;
    ruta?: string;
    company?: number;
    manager?: number;
}

export function getWaterRouteDisplayColumns(): string[] {
    const displayedColumns: string[] = [
        'ID',
        'Codigo Ruta',
        'Calle',
        'Barrio',
        'Radio Portal',
        'Distrito',
        'Municipio',
        'Portal',
        'Ruta',
        'Fecha de Modificación',
    ];
    return displayedColumns;
}

export function getWaterRouteField(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'ID':
            value = 'ID';
            break;
        case 'Codigo Ruta':
            value = 'codigo_ruta';
            break;
        case 'Calle':
            value = 'calle';
            break;
        case 'Barrio':
            value = 'barrio';
            break;
        case 'Radio Portal':
            value = 'radio_portal';
            break;
        case 'Distrito':
            value = 'distrito';
            break;
        case 'Municipio':
            value = 'municipio';
            break;
        case 'Portal':
            value = 'portal';
            break;
        case 'Ruta':
            value = 'ruta';
            break;
        case 'Fecha de Modificación':
            value = 'date_time_modified';
            break;
    }
    return value;
}

export function getWaterRouteFieldName(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'ID':
            value = 'ID';
            break;
        case 'codigo_ruta':
            value = 'Codigo Ruta';
            break;
        case 'calle':
            value = 'Calle';
            break;
        case 'barrio':
            value = 'Barrio';
            break;
        case 'radio_portal':
            value = 'Radio Portal';
            break;
        case 'distrito':
            value = 'Distrito';
            break;
        case 'municipio':
            value = 'Municipio';
            break;
        case 'portal':
            value = 'Portal';
            break;
        case 'ruta':
            value = 'Ruta';
            break;
        case 'date_time_modified':
            value = 'Fecha de Modificación';
            break;
    }
    return value;
}

export function getWaterRouteFormControls(): FormGroup {
    const waterRouteFormData: FormGroup = new FormGroup({
        barrio: new FormControl(),
        calle: new FormControl(),
        codigo_ruta: new FormControl(),
        date_time_modified: new FormControl(),
        distrito: new FormControl(),
        id: new FormControl(),
        municipio: new FormControl(),
        portal: new FormControl(),
        radio_portal: new FormControl(),
        radio_exist: new FormControl(),
        ruta: new FormControl(),
        company: new FormControl(),
        manager: new FormControl(),
    });
    return waterRouteFormData;
}

export function getWaterRouteFieldType(field_name: string): string {
    //in bigquery
    let value: string = '';
    switch (field_name) {
        case 'barrio':
            value = 'string';
            break;
        case 'calle':
            value = 'string';
            break;
        case 'codigo_ruta':
            value = 'string';
            break;
        case 'date_time_modified':
            value = 'Date';
            break;
        case 'distrito':
            value = 'string';
            break;
        case 'id':
            value = 'number';
            break;
        case 'municipio':
            value = 'string';
            break;
        case 'portal':
            value = 'string';
            break;
        case 'radio_portal':
            value = 'string';
            break;
        case 'radio_exist':
            value = 'boolean';
            break;
        case 'ruta':
            value = 'string';
            break;
    }
    return value;
}

export function getWaterRouteExcelFieldName(excel_field: string): string {
    let value: string = '';
    switch (excel_field.trim().toLowerCase()) {
        case 'codigo ruta':
            value = 'codigo_ruta';
            break;
        case 'codigo de ruta':
            value = 'codigo_ruta';
            break;
        case 'código ruta':
            value = 'codigo_ruta';
            break;
        case 'código de ruta':
            value = 'codigo_ruta';
            break;
        case 'calle':
            value = 'calle';
            break;
        case 'barrio':
            value = 'barrio';
            break;
        case 'radio Portal':
            value = 'radio_portal';
            break;
        case 'distrito':
            value = 'distrito';
            break;
        case 'municipio':
            value = 'municipio';
            break;
        case 'portal':
            value = 'portal';
            break;
        case 'ruta':
            value = 'ruta';
            break;
        case 'fecha de modificación':
            value = 'date_time_modified';
            break;
        case 'fecha de modificacion':
            value = 'date_time_modified';
            break;

        case 'codigo_ruta':
            value = 'codigo_ruta';
            break;
        case 'date_time_modified':
            value = 'date_time_modified';
            break;
        case 'distrito':
            value = 'distrito';
            break;
        case 'radio_portal':
            value = 'radio_portal';
            break;
        case 'radio_exist':
            value = 'radio_exist';
            break;
    }
    return value;
}

export function getWaterRouteExcelExportColumns(): string[] {
    const columns = [
        'Codigo Ruta',
        'Calle',
        'Barrio',
        'Radio Portal',
        'Distrito',
        'Municipio',
        'Portal',
        'Ruta',
        'Fecha de Modificación',
    ];
    return columns;
}

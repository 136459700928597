<h3 mat-dialog-title>
    <b>{{ title }}</b>
</h3>
<mat-dialog-content class="mat-typography">
    <!-- <h3>{{ title }}</h3> -->
    <pre>{{ data }}</pre>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>{{ cancelText }}</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>{{ acceptText }}</button>
</mat-dialog-actions>

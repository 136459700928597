/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap, map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ImageService {
    constructor(private httpClient: HttpClient) {}

    getData(url: string): Observable<string> {
        let headers: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        });
        return this.httpClient
            .get(url, {
                responseType: 'blob',
                headers: headers,
            })
            .pipe(switchMap((response) => this.readFile(response)));
    }

    private readFile(blob: Blob): Observable<string> {
        return Observable.create((obs: any) => {
            const reader = new FileReader();

            reader.onerror = (err) => obs.error(err);
            reader.onabort = (err) => obs.error(err);
            reader.onload = () => obs.next(reader.result);
            reader.onloadend = () => obs.complete();

            return reader.readAsDataURL(blob);
        });
    }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.scss']
})
export class ImageCarouselComponent implements OnInit {
    @Input() images: string[] = [];
    @Output() downloadImage: EventEmitter<string>;
    currentIndex: number = 0;

    constructor() { 
        this.downloadImage = new EventEmitter();
    }

    ngOnInit(): void {
    }

    previousSlide(): void {
        this.currentIndex = (this.currentIndex > 0) ? this.currentIndex - 1 : this.images.length - 1;
    }

    nextSlide(): void {
        this.currentIndex = (this.currentIndex < this.images.length - 1) ? this.currentIndex + 1 : 0;
    }

    goToSlide(index: number): void {
        this.currentIndex = index;
    }

    download(): void { 
        this.downloadImage.emit(this.images[this.currentIndex]);
    }

    closeCarousel(): void {
        this.images = []; // This will hide the carousel by clearing images.
    }
}

import { FormControl, FormGroup } from "@angular/forms";
import { ItelazpiDevice } from "./itelazpi-device";
import { MyLatLng } from "./lat-lng";

export interface IntegrationItelazpi {
    ID?: number;
    id?: number;
    device?: ItelazpiDevice;
    counter_serial_number?: string;
    counter_serial_module?: string;
    error_code?: number;
    error?: string;
    response?: string;
    user?: string;
    geolocation?: MyLatLng;
    company?: number;
    date_time_modified?: Date;
}

export function getIntegrationItelazpiDisplayColumns(): string[] {
    const displayedColumns: string[] = [
        'ID',
        'Dispositivo',
        'Serie contador',
        'Serie de módulo',
        'Código de error',
        'Error',
        'Respuesta',
        'Usuario',
        'Empresa',
        'Fecha Modificación',
    ];
    return displayedColumns;
}

export function getIntegrationItelazpiField(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'ID':
            value = 'ID';
            break;
        case 'Dispositivo':
            value = 'device';
            break;
        case 'Serie contador':
            value = 'counter_serial_number';
            break;
        case 'Serie de módulo':
            value = 'counter_serial_module';
            break;
        case 'Código de error':
            value = 'error_code';
            break;
        case 'Error':
            value = 'error';
            break;
        case 'Respuesta':
            value = 'response';
            break;
        case 'Usuario':
            value = 'user';
            break;
        case 'Empresa':
            value = 'company';
            break;
        case 'Fecha Modificación':
            value = 'date_time_modified';
            break;
    }
    return value;
}

export function getIntegrationItelazpiFieldName(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'ID':
            value = 'ID';
            break;
        case 'device':
            value = 'Dispositivo';
            break;
        case 'counter_serial_number':
            value = 'Serie contador';
            break;
        case 'counter_serial_module':
            value = 'Serie de módulo';
            break;
        case 'error_code':
            value = 'Código de error';
            break;
        case 'error':
            value = 'Error';
            break;
        case 'response':
            value = 'Respuesta';
            break;
        case 'user':
            value = 'Usuario';
            break;
        case 'company':
            value = 'Empresa';
            break;
        case 'date_time_modified':
            value = 'Fecha Modificación';
            break;
    }
    return value;
}

export function getIntegrationItelazpiFormControls(): FormGroup {
    const integrationItelazpiFormData: FormGroup = new FormGroup({
        id: new FormControl(),
        device: new FormControl(),
        counter_serial_number: new FormControl(),
        counter_serial_module: new FormControl(),
        error_code: new FormControl(),
        error: new FormControl(),
        response: new FormControl(),
        user: new FormControl(),
        geolocation: new FormControl(),
        company: new FormControl(),
        date_time_modified: new FormControl(),
    });
    return integrationItelazpiFormData;
}

export function getIntegrationItelazpiFieldType(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'id':
            value = 'number';
            break;
        case 'device':
            value = 'string';
            break;
        case 'counter_serial_number':
            value = 'string';
            break;
        case 'counter_serial_module':
            value = 'string';
            break;
        case 'error_code':
            value = 'number';
            break;
        case 'error':
            value = 'string';
            break;
        case 'response':
            value = 'string';
            break;
        case 'user':
            value = 'string';
            break;
        case 'company':
            value = 'number';
            break;
        case 'date_time_modified':
            value = 'Date';
            break;
    }
    return value;
}

export function getIntegrationItelazpiExcelFieldName(excel_field: string): string {
    let value: string = '';
    switch (excel_field.trim().toLowerCase()) {
        case 'Fecha':
            value = 'date_time_modified';
            break;
        case 'Número de serie':
            value = 'counter_serial_number';
            break;
        case 'Número de módulo':
            value = 'counter_serial_module';
            break;
        case 'Activación':
            value = 'error_code';
            break;
        case 'Respuesta':
            value = 'response_es';
            break;
        case 'Usuario activador':
            value = 'user_id';
            break;
        case 'Ubicación':
            value = 'geolocation';
            break;
    }
    return value;
}

export function getIntegrationItelazpiExcelExportColumns(): string[] {
    const columns = [
        'Dispositivo',
        'Serie contador',
        'Serie de módulo',
        'Código de error',
        'Error',
        'Respuesta',
        'Usuario',
        'Empresa',
        'Fecha Modificación',
    ];
    return columns;
}

/**
 * Created by Ing. Luis Alejandro Reyes Morales on 25/11/2023.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
/**
 * @fileoverview
 * @module GlobalErrorHandler
 * @description
 * This module contains the GlobalErrorHandler class, which implements the Angular ErrorHandler interface for global error handling.
 * If debug mode is enabled, it logs the error and downloads an error file with the stack trace.
 */
import { Injectable, ErrorHandler } from '@angular/core';
import { UtilsService } from './utils.service';

/**
 * @class
 * @classdesc
 * GlobalErrorHandler class that implements the Angular ErrorHandler interface.
 * Provides global error handling for the Angular application.
 * @implements ErrorHandler
 */
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    /**
     * @constructor
     * @param {UtilsService} _utilsService - An instance of the UtilsService for utility functions.
     */
    constructor(private _utilsService: UtilsService) {}

    /**
     * @function
     * @description
     * Handles errors globally and performs additional actions if debug mode is enabled.
     * @param {any} error - The error object to be handled.
     * @return {void}
     * @implements ErrorHandler.handleError
     */
    handleError(error: any): void {
        this._utilsService.handleError(error);
    }
}

import { MyLatLng } from "./lat-lng";
import { FormControl, FormGroup } from '@angular/forms';

export interface RadiusModule {
    ID?: number; //useless only for displaying info
    id?: number;
    emitter_ref?: string;	
    radius_module_hrid?: string;	
    dl_sn?: string;	
    deveui?: string;	
    appeui?: string;	
    appkey?: string;	
    sigfox_id?: string;	
    sigfox_pac?: string;	
    date?: Date;	
    meter_ref?: string;	
    meter_caliber?: string;	
    meter_serial_number?: string;	
    meter_index?: string;	
    meter_pulse?: string;	
    rf_config?: string;	
    albaran?: string;	
    pedido?: string;	
    division?: string;
    company?: number;
    manager?: number;
    image?: string;
    geolocalizacion?: MyLatLng;
    date_time_modified?: Date;
}

export function getRadiusModuleDisplayColumns(): string[] {
    const displayedColumns: string[] = [
        'ID',
        'EMITTER_REF',
        'SERIE MÓDULO',
        'DL_SN',
        'DEVEUI',
        'APPEUI',
        'APPKEY',
        'SIGFOX_ID',
        'SIGFOX_PAC',
        'DATE',
        'METER_REF',
        'CALIBRE',
        'SERIE CONTADOR',
        'METER_INDEX',
        'METER_PULSE',
        'RF_CONFIG',
        'ALBARAN',
        'PEDIDO',
        'DIVISION',
        'Fecha de Modificación',
    ];
    return displayedColumns;
}

export function getRadiusModuleField(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'ID':
            value = 'ID';
            break;
        case 'EMITTER_REF':
            value = 'emitter_ref';
            break;
        case 'SERIE MÓDULO':
            value = 'radius_module_hrid';
            break;
        case 'DL_SN':
            value = 'dl_sn';
            break;
        case 'DEVEUI':
            value = 'deveui';
            break;
        case 'APPEUI':
            value = 'appeui';
            break;
        case 'APPKEY':
            value = 'appkey';
            break;
        case 'SIGFOX_ID':
            value = 'sigfox_id';
            break;
        case 'SIGFOX_PAC':
            value = 'sigfox_pac';
            break;
        case 'DATE':
            value = 'date';
            break;
        case 'METER_REF':
            value = 'meter_ref';
            break;
        case 'CALIBRE':
            value = 'meter_caliber';
            break;
        case 'SERIE CONTADOR':
            value = 'meter_serial_number';
            break;
        case 'METER_INDEX':
            value = 'meter_index';
            break;
        case 'METER_PULSE':
            value = 'meter_pulse';
            break;
        case 'RF_CONFIG':
            value = 'rf_config';
            break;
        case 'ALBARAN':
            value = 'albaran';
            break;
        case 'PEDIDO':
            value = 'pedido';
            break;
        case 'DIVISION':
            value = 'division';
            break;
        case 'geolocalizacion':
            value = 'geolocalizacion';
            break;
        case 'FOTO':
            value = 'image';
            break;
        case 'Fecha de Modificación':
            value = 'date_time_modified';
            break;
    }
    return value;
}

export function getRadiusModuleFieldName(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'ID':
            value = 'ID';
            break;
        case 'emitter_ref':
            value = 'EMITTER_REF';
            break;
        case 'radius_module_hrid':
            value = 'SERIE MÓDULO';
            break;
        case 'dl_sn':
            value = 'DL_SN';
            break;
        case 'deveui':
            value = 'DEVEUI';
            break;
        case 'appeui':
            value = 'APPEUI';
            break;
        case 'appkey':
            value = 'APPKEY';
            break;
        case 'sigfox_id':
            value = 'SIGFOX_ID';
            break;
        case 'sigfox_pac':
            value = 'SIGFOX_PAC';
            break;
        case 'date':
            value = 'DATE';
            break;
        case 'meter_ref':
            value = 'METER_REF';
            break;
        case 'meter_caliber':
            value = 'CALIBRE';
            break;
        case 'meter_serial_number':
            value = 'SERIE CONTADOR';
            break;
        case 'meter_index':
            value = 'METER_INDEX';
            break;
        case 'meter_pulse':
            value = 'METER_PULSE';
            break;
        case 'rf_config':
            value = 'RF_CONFIG';
            break;
        case 'albaran':
            value = 'ALBARAN';
            break;
        case 'pedido':
            value = 'PEDIDO';
            break;
        case 'division':
            value = 'DIVISION';
            break;
        case 'geolocalizacion':
            value = 'geolocalizacion';
            break;
        case 'image':
            value = 'FOTO';
            break;
        case 'date_time_modified':
            value = 'Fecha de Modificación';
            break;
    }
    return value;
}

export function getRadiusModuleFormControls(): FormGroup {
    const RadiusModuleFormData: FormGroup = new FormGroup({
        id: new FormControl(),
        emitter_ref: new FormControl(),
        radius_module_hrid: new FormControl(),
        dl_sn: new FormControl(),
        deveui: new FormControl(),
        appeui: new FormControl(),
        appkey: new FormControl(),
        sigfox_id: new FormControl(),
        sigfox_pac: new FormControl(),
        date: new FormControl(),
        meter_ref: new FormControl(),
        meter_caliber: new FormControl(),
        meter_serial_number: new FormControl(),
        meter_index: new FormControl(),
        meter_pulse: new FormControl(),
        rf_config: new FormControl(),
        albaran: new FormControl(),
        pedido: new FormControl(),
        division: new FormControl(),
        company: new FormControl(),
        manager: new FormControl(),
        image: new FormControl(),
        geolocalizacion: new FormControl(),
        date_time_modified: new FormControl(),
    });
    return RadiusModuleFormData;
}

export function getRadiusModuleFieldType(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'id':
            value = 'number';
            break;
        case 'emitter_ref':
            value = 'string';
            break;	
        case 'radius_module_hrid':
            value = 'string';
            break;	
        case 'dl_sn':
            value = 'string';
            break;	
        case 'deveui':
            value = 'string';
            break;	
        case 'appeui':
            value = 'string';
            break;	
        case 'appkey':
            value = 'string';
            break;	
        case 'sigfox_id':
            value = 'string';
            break;	
        case 'sigfox_pac':
            value = 'string';
            break;	
        case 'date':
            value = 'Date';
            break;	
        case 'meter_ref':
            value = 'string';
            break;	
        case 'meter_caliber':
            value = 'string';
            break;	
        case 'meter_serial_number':
            value = 'string';
            break;	
        case 'meter_index':
            value = 'string';
            break;	
        case 'meter_pulse':
            value = 'string';
            break;	
        case 'rf_config':
            value = 'string';
            break;	
        case 'albaran':
            value = 'string';
            break;	
        case 'pedido':
            value = 'string';
            break;	
        case 'division':
            value = 'string';
            break;
        case 'company':
            value = 'number';
            break;
        case 'manager':
            value = 'number';
            break;
        case 'image':
            value = 'string';
            break;
        case 'geolocalizacion':
            value = 'string';
            break;
        case 'date_time_modified':
            value = 'Date';
            break;
    }
    return value;
}

export function getRadiusModuleExcelFieldName(excel_field: string): string {
    let value: string = '';
    switch (excel_field.trim().toUpperCase()) {
        case 'EMITTER_REF':
            value = 'emitter_ref';
            break;	
        case 'SERIALNUMBERS':
            value = 'radius_module_hrid';
            break;		
        case 'HRID':
            value = 'radius_module_hrid';
            break;	
        case 'DL_SN':
            value = 'dl_sn';
            break;	
        case 'DEVEUI':
            value = 'deveui';
            break;	
        case 'APPEUI':
            value = 'appeui';
            break;	
        case 'APPKEY':
            value = 'appkey';
            break;	
        case 'SIGFOX_ID':
            value = 'sigfox_id';
            break;	
        case 'SIGFOX_PAC':
            value = 'sigfox_pac';
            break;	
        case 'FECHA':
            value = 'date';
            break;	
        case 'DATE':
            value = 'date';
            break;	
        case 'METER_REF':
            value = 'meter_ref';
            break;	
        case 'METER_DN':
            value = 'meter_caliber';
            break;	
        case 'METER_SN':
            value = 'meter_serial_number';
            break;	
        case 'METER_INDEX':
            value = 'meter_index';
            break;	
        case 'METER_PULSE':
            value = 'meter_pulse';
            break;	
        case 'RF_CONFIG':
            value = 'rf_config';
            break;	
        case 'ALBARAN':
            value = 'albaran';
            break;	
        case 'PEDIDO':
            value = 'pedido';
            break;	
        case 'DIVISION':
            value = 'division';
            break;
    }
    return value;
}

export function getRadiusModuleExcelExportColumns(): string[] {
    const columns = [
        'EMITTER_REF',
        'SERIE MÓDULO',
        'DL_SN',
        'DEVEUI',
        'APPEUI',
        'APPKEY',
        'SIGFOX_ID',
        'SIGFOX_PAC',
        'DATE',
        'METER_REF',
        'CALIBRE',
        'SERIE CONTADOR',
        'METER_INDEX',
        'METER_PULSE',
        'RF_CONFIG',
        'ALBARAN',
        'PEDIDO',
        'DIVISION',
        'FOTO',
        'geolocalizacion',
        'Fecha de Modificación',
    ];
    return columns;
}

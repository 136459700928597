import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';

@Pipe({
  name: 'team'
})
export class TeamPipe implements PipeTransform {

  constructor(
    private _utilsService: UtilsService,
    ){
  }
  
  transform(value: unknown, ...args: unknown[]): unknown {
    const teamName = this._utilsService.teamPipe(value);
    // console.log('************* transform *************');
    // console.log(teamName);
    return teamName;
  }

}

<mat-dialog-content [formGroup]="range">
    <div style="padding: 15px">
        <div style="padding: 5px; margin-left: 10px">
            <span style="font-size: 1.1em">Hora inicial</span>
        </div>
        <ngx-timepicker-field
            [toggleIcon]="icon"
            formControlName="start"
            [format]="24"
            (timeChanged)="onStartTimeChanged($event)"
        ></ngx-timepicker-field>
        <ng-template #icon>
            <mat-icon>query_builder</mat-icon>
        </ng-template>
    </div>
    <div style="padding: 15px">
        <div style="padding: 5px; margin-left: 10px">
            <span style="font-size: 1.1em">Hora final</span>
        </div>
        <ngx-timepicker-field
            [toggleIcon]="icon"
            formControlName="end"
            [min]="minTime"
            [format]="24"
            (timeChanged)="onEndTimeChanged($event)"
        ></ngx-timepicker-field>
        <ng-template #icon>
            <mat-icon>query_builder</mat-icon>
        </ng-template>
    </div>
    <mat-dialog-content>
        <mat-dialog-actions align="center" style="margin: 5px">
            <button mat-button mat-dialog-close>Cancelar</button>
            <button
                mat-button
                (click)="onSelectedTime()"
                [mat-dialog-close]="time_range"
                [disabled]="!(range.controls['start'].value && range.controls['end'].value)"
            >
                Aceptar
            </button>
        </mat-dialog-actions>
    </mat-dialog-content>
</mat-dialog-content>

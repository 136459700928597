import { Component, OnInit } from '@angular/core';
import { getPlanningDetailDisplayColumns, getPlanningDetailField, PlanningDetail, getPlanningDetailExcelFieldName, getPlanningDetailFieldType, getPlanningDetailExcelExportColumns } from '../../../interfaces/planning-detail';
import * as moment from 'moment';
import { Moment } from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import * as XLSX from 'xlsx';
import { faInbox } from '@fortawesome/free-solid-svg-icons';
import { ApiService } from '../../../services/api.service';
import { UtilsService } from '../../../services/utils.service';
import { WindowRefService } from '../../../services/window-ref.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-planning-details',
  templateUrl: './planning-details.component.html',
  styleUrls: ['./planning-details.component.scss']
})
export class PlanningDetailsComponent implements OnInit {
    loading: boolean = true;
    tableName: string = 'plannings';
    planningsInPage: PlanningDetail[] = [];
    dataSource: MatTableDataSource<PlanningDetail> = new MatTableDataSource();

    length = 0; //task count in current table
    pageSize = 200; //limit of query
    lastPageIndex = 0;
    pageSizeOptions: number[] = [10, 50, 100, 200, 500];

    displayedColumns: string[] = getPlanningDetailDisplayColumns();
    fixedColumns = [];
    displayedColumnsField: string[] = this.displayedColumns.map((displayedColumn: string) =>
        getPlanningDetailField(displayedColumn)
    );
    clickedRows = new Set<PlanningDetail>();

    lastSelectedRow: number = -1;

    filteredColumn?: string;
    orderedColumn?: string;

    loadingText = 'Cargando...';

    faInbox = faInbox;

    /**
    * Represents the PlanningDetailsComponent class.
    * This component is responsible for managing the plannings table.
    */
    constructor(
        private _apiService: ApiService,
        private _utilsService: UtilsService,
        private _windowRefService: WindowRefService,
        private _spinner: NgxSpinnerService,
    ) {}

    async ngOnInit(): Promise<void> {
        await this.getPlanningDetails();
    }

    /**
    * Handles the file event triggered by the user.
    * If the file option is "Importar planificaciones", it processes the Excel file.
    * 
    * @param event - The file event object.
    */
  async fileEvent(event: any) {
        if (event['file_option'] == 'Importar detalles de planificación') {
            this.processExcelFile(event);
        }
    }

    /**
    * Processes the selected Excel file.
    * 
    * @param event - The event object containing the selected file.
    * @returns A Promise that resolves when the plannings are imported.
    */
    async processExcelFile(event: any) {
        this._spinner.show();
        this.loadingText = `Cargando ...`;
        let workBook: XLSX.WorkBook;
        let jsonData = null;
        const reader = new FileReader();
        const file = event['file'].target.files[0];
        reader.onload = async (_) => {
            this._spinner.show();
            this.loadingText = `Procesando detalles de planificaciones ...`;
            const data = reader.result;
            workBook = XLSX.read(data, { type: 'binary' });
            jsonData = workBook.SheetNames.reduce((initial: any, name) => {
                const sheet = workBook.Sheets[name];
                initial[name] = XLSX.utils.sheet_to_json(sheet);
                return initial;
            }, {});
            let sheets: string[] = [];
            let arrayPlanningDetails: PlanningDetail[] = [];

            Object.keys(jsonData).forEach((key: string) => {
                sheets.push(key);
            });
            for (let sheet of sheets) {
              for (let jsonPlanningDetail of jsonData[sheet]) {
                    let planningData: any = {};
                    Object.keys(jsonPlanningDetail).forEach((key) => {
                      let field: string = getPlanningDetailExcelFieldName(key);
                        if (this._utilsService.isFieldValid(field)) {
                          let value = jsonPlanningDetail[key];
                            try {
                                if (typeof value === 'number') {
                                    if (getPlanningDetailFieldType(field) == 'number') {
                                        planningData[`${field}`] = value;
                                    } else {
                                        planningData[`${field}`] = value.toString().trim();
                                    }
                                } else if (
                                    typeof value === 'string' &&
                                    this._utilsService.isFieldValid(value)
                                ) {
                                    if (getPlanningDetailFieldType(field) == 'string') {
                                        planningData[`${field}`] = value.trim();
                                    } else if (getPlanningDetailFieldType(field) == 'boolean') {
                                        planningData[`${field}`] =
                                            value.trim().toUpperCase() == 'SI' ? true : false;
                                    } else {
                                        planningData[`${field}`] = parseInt(value);
                                    }
                                }
                            } catch (error) {
                                console.log(value);
                            }
                        }
                    });
                    let planning = planningData as PlanningDetail;
                    if (planning && this._utilsService.isFieldValid(planning.codigo_planning_details)) {
                        arrayPlanningDetails.push(planning);
                    }
                }
            }
            await this.importPlanningDetails(arrayPlanningDetails);
            this._spinner.hide();
        };
        reader.readAsBinaryString(file);

        this._spinner.hide();
    }

    /**
    * Imports an array of PlanningDetail objects.
    * 
    * @param plannings - An array of PlanningDetail objects to import.
    * @returns A boolean indicating whether the import was successful or not.
    */
    async importPlanningDetails(plannings: PlanningDetail[]) {
        this._spinner.show();
        this.loadingText = `Añadiendo detalles de planificaciones ...`;
        let i = 0,
            errorIds = [];
        for (let planning of plannings) {
            this.loadingText = `Añadiendo detalles de planificaciones ${++i} de ${plannings.length}`;
            if (!(await this._apiService.addDocument('planning-details', planning))) {
                errorIds.push(planning.codigo_planning);
            }
        }
        this._spinner.hide();
        if (errorIds.length > 0) {
            this._utilsService.openSnackBar(`Hubo errores añadiendo detalles de planificaciones`, 'error');
        } else {
            this._utilsService.openSnackBar(`Detalle de planificaciones subidos correctamente`);
        }
        await this.getPlanningDetails();
        return !(errorIds.length > 0);
    }

    /**
    * Shows or hides the loading state and displays a spinner accordingly.
    * @param state - A boolean value indicating whether to show or hide the loading state.
    */
    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this._spinner.show('innerSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this._spinner.hide('innerSpinner');
        }
    }

    /**
    * Sets the provided plannings in the table.
    * 
    * @param plannings - An array of PlanningDetail objects to be displayed in the table.
    */
    setPlanningDetailsInTable(plannings: PlanningDetail[]) {
        this.planningsInPage = plannings;
        // this.dataSource.data = plannings.slice(0, this.sliceSize);
        this.dataSource.data = plannings;
        // this.dataSource = new TableVirtualScrollDataSource(plannings);// infiniteScroll
        // console.log(this.dataSource);
        this.showLoading(false);
    }

    /**
    * Retrieves the plannings and sets them in the table.
    * @returns {Promise<void>} A promise that resolves when the plannings are set in the table.
    */
    async getPlanningDetails() {
        this.setPlanningDetailsInTable(await this.selectPlanningDetails());
    }

    /**
    * Retrieves and returns a list of planning documents.
    * 
    * @returns {Promise<PlanningDetail[]>} A promise that resolves to an array of PlanningDetail objects.
    */
    async selectPlanningDetails() {
        this.showLoading(true);
        let docs = [];
        try {
            docs = await this._apiService.getDocuments<PlanningDetail>('planning-details', undefined, [
                'codigo_planning',
                'asc',
            ]);
        } catch (err) {
            console.log('============= err =============');
            console.log(err);
        }
        this.length = docs.length;
        return docs;
        // undefined,/*where*/undefined, /*order_clause*/undefined, '0', this.pageSize.toString());
    }

    /**
    * Filters the data source based on the provided search value.
    * @param event - The search value to filter the data source with.
    */
    searchValue(event: any) {
        const filterValue: string = event;
        this.dataSource!.filter = filterValue.trim().toLowerCase();
    }

    /**
    * Deletes the selected plannings.
    * If no planning is selected, displays a warning message.
    * Asks for confirmation before deleting the selected plannings.
    * Deletes the plannings one by one and updates the table accordingly.
    * Displays a success message if all plannings are deleted successfully,
    * otherwise displays an error message.
    */
    async deletePlanningDetails() {
        let errorIds = [];
        if (this.clickedRows.size == 0) {
            this._utilsService.openSnackBar('Debe seleccionar al menos un detalle de planificación', 'warning');
            return;
        }
        const planning = await this._utilsService.openQuestionDialog(
            'Confirmación',
            '¿Desea eliminar los detalles de planificaciones seleccionadas?'
        );
        if (planning) {
            this._spinner.show();
            this.loadingText = `Eliminando detalles de planificaciones ...`;
            let rowIndexes = new Set<number>();
            const oldDataSource = this.dataSource!.data;
            this.clickedRows.forEach(async (row) => {
                console.log(row.id);

                const index = this.dataSource!.data.indexOf(row, 0);
                if (index > -1) {
                    rowIndexes.add(index);
                }
            });
            let plannings = [];
            let deleteCount = 0;
            for (let i = 0; i < oldDataSource.length; i++) {
                if (!rowIndexes.has(i)) {
                    plannings.push(oldDataSource![i]);
                } else {
                    this.loadingText = `Eliminando detalles de planificaciones ${++deleteCount} de ${
                        rowIndexes.size
                    }`;
                    if (
                        !(await this._apiService.deleteDocument(
                            'planning-details',
                            oldDataSource[i].id!.toString()
                        ))
                    ) {
                        errorIds.push(oldDataSource[i].id);
                    }
                }
            }
            if (errorIds.length > 0) {
                this._utilsService.openSnackBar(`Hubo errores eliminando detalles de planificaciones`, 'error');
            } else {
                this._utilsService.openSnackBar(`Detalle de planificaciones eliminados correctamente`);
            }
            this.length -= rowIndexes.size;
            this.setPlanningDetailsInTable(plannings);
            this.clickedRows.clear();
            this._spinner.hide();
        }
    }

    /**
    * Filters the data based on the specified column.
    * @param column - The column to filter by.
    */
    async filterBy(column: string) {
        try {
            this.filteredColumn = getPlanningDetailField(column);
            if (
                getPlanningDetailFieldType(this.filteredColumn) == 'Date' ||
                getPlanningDetailFieldType(this.filteredColumn) == 'Timestamp'
            ) {
                const dates = await this._utilsService.openDateRangeSelectorDialog(
                    'Seleccione rango de fechas'
                );
                this.onDateSelected(dates);
            } else {
                const options: any[] = this.dataSource.data.map(
                    (planning: PlanningDetail) => planning[this.filteredColumn as keyof PlanningDetail]
                );
                const planning = await this._utilsService.openSelectorDialog(
                    'Seleccione opción',
                    options
                );
                if (planning) {
                    let docs = [];
                    try {
                        docs = await this._apiService.getDocuments<PlanningDetail>('planning-details', [
                            [this.filteredColumn, '==', planning],
                        ]);
                    } catch (err) {
                        console.log('============= err =============');
                        console.log(err);
                    }
                    this.length = docs.length;
                    this.setPlanningDetailsInTable(docs);
                }
            }
        } catch (err) {}
    }

    /**
    * Handles the event when a date range is selected.
    * @param dateRange - The selected date range.
    */
    async onDateSelected(dateRange: Date[]) {
        if (dateRange) {
            const startDate: Moment = moment(dateRange[0]);
            const startDateString = startDate.format('YYYY-MM-DD HH:mm:ss');
            let endDate: Moment = moment(dateRange[1]);
            if (!endDate) {
                endDate = startDate;
            }
            const endDateString = endDate.add(1, 'days').format('YYYY-MM-DD HH:mm:ss'); //plus one to filter range
            const values = [startDateString, endDateString];
            let docs = [];
            try {
                docs = await this._apiService.getDocuments<PlanningDetail>('planning-details', [
                    [this.filteredColumn!, '>=', startDate.toDate()],
                    [this.filteredColumn!, '<', endDate.toDate()],
                ]);
            } catch (err) {
                console.log('============= err =============');
                console.log(err);
            }
            this.length = docs.length;
            this.setPlanningDetailsInTable(docs);
        } else {
            this._utilsService.openSnackBar('Rango fechas invalido', 'error');
        }
    }

    /**
    * Orders the plannings based on the specified column and order type.
    * @param event - The event object containing the column and order type.
    */
    async orderBy(event: any) {
        const column = event.column;
        let orderType = event.orderType;
        if (orderType.toLowerCase().includes('asc')) {
            orderType = 'asc';
        } else {
            orderType = 'desc';
        }
        const orderedColumn = getPlanningDetailField(column);

        this.showLoading(true);
        const planningDetails = await this._apiService.getDocuments<PlanningDetail>('planning-details', undefined, [
            orderedColumn,
            orderType,
        ]);
        this.setPlanningDetailsInTable(planningDetails);
    }

    /**
    * Adds a new row to the data source.
    * @param event - The event object.
    */
    async addNewRow(event: any) {
        const planningDetail = await this._utilsService.openPlanningDetailDialog('');
        if (planningDetail) {
            this.dataSource.data.push(planningDetail);
            const planningDetails = this.dataSource.data;
            this.dataSource.data = [];
            this.dataSource.data = planningDetails;
        }
    }
    /**
    * Handles the double click event on a row in the table.
    * @param row - The row object that was double clicked.
    * @returns A Promise that resolves to the updated planning data, or null if no planning was updated.
    */
    async doubleClickedRow(row: any) {
        const planningDetail = await this._utilsService.openPlanningDetailDialog(row.id);
        if (planningDetail) {
            this.dataSource.data[row.rowIndex] = planningDetail;
            const plannings = this.dataSource.data;
            this.dataSource.data = [];
            this.dataSource.data = plannings;
        }
    }

    /**
    * Handles the click event on a row in the table.
    * @param receivedEvent - The event object containing information about the clicked row.
    */
    clickedRow(receivedEvent: any) {
        const row = receivedEvent.row;
        const event = receivedEvent.event;
        const rowIndex = receivedEvent.rowIndex;

        const previousRow = this.lastSelectedRow;
        this.lastSelectedRow = rowIndex;

        if (event.button === 0) {
            if (!event.ctrlKey && !event.shiftKey) {
                this.clickedRows.clear();
                this.toggleRow(row);
            } else if (event.ctrlKey) {
                this.toggleRow(row);
            }
            if (event.shiftKey) {
                this.selectRowsBetweenIndexes(previousRow, rowIndex);
            }
        }
        if (this._windowRefService.nativeWindow.getSelection) {
            //remove selection in table with shift
            if (this._windowRefService.nativeWindow.getSelection().empty) {
                // Chrome
                this._windowRefService.nativeWindow.getSelection().empty();
            } else if (this._windowRefService.nativeWindow.getSelection().removeAllRanges) {
                // Firefox
                this._windowRefService.nativeWindow.getSelection().removeAllRanges();
            }
        }
    }

    /**
    * Selects rows between the given indexes.
    * @param lastSelectedRow The index of the last selected row.
    * @param rowIndex The index of the current row.
    */
    selectRowsBetweenIndexes(lastSelectedRow: number, rowIndex: number) {
        let start, end;
        if (rowIndex > lastSelectedRow) {
            start = lastSelectedRow;
            end = rowIndex;
        } else {
            end = lastSelectedRow;
            start = rowIndex;
        }
        for (let i = start; i <= end; i++) {
            this.clickedRows.add(this.dataSource!.data[i]);
        }
    }

    /**
    * Toggles the clicked state of a row.
    * If the row is already clicked, it will be unclicked.
    * If the row is not clicked, it will be clicked.
    * 
    * @param row - The row to toggle.
    */
    toggleRow(row: any) {
        if (this.clickedRows.has(row)) {
            this.clickedRows.delete(row);
        } else {
            this.clickedRows.add(row);
        }
    }

    /**
    * Exports the plannings data in a table to Excel.
    */
    exportPlanningDetailsInTable() {
        this.exportExcel(this.dataSource!.data);
    }

    /**
    * Exports the given plannings to an Excel file.
    * @param planningDetails - The plannings to export.
    */
    exportExcel(planningDetails: any) {
        let excelFormatPlanningDetails = [];
        for (let planningDetail of planningDetails) {
            let task: any = {};
            const columns = getPlanningDetailExcelExportColumns();
            columns.forEach((column) => {
                task[`${column}`] = this._utilsService.tableDataPipe(
                    planningDetail[getPlanningDetailExcelFieldName(column)]
                );
            });
            excelFormatPlanningDetails.push(task);
        }
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelFormatPlanningDetails);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        /* save to file */
        XLSX.writeFile(wb, 'Detalle_Planificaciones_Exportados.xlsx');
    }
}

/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FilterNode, MiRutaFilter } from 'src/app/interfaces/mi-ruta-filter';
import { UtilsService } from 'src/app/services/utils.service';
import { faInbox } from '@fortawesome/free-solid-svg-icons';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MySqlService } from 'src/app/services/mysql.service';
import { FlatNode } from '../../../interfaces/flat-node';
import { FilterConfigurationDialogData } from '../../../interfaces/filter-configuration-dialog-data';

@Component({
    selector: 'app-user-filter-configuration',
    templateUrl: './user-filter-configuration.component.html',
    styleUrls: ['./user-filter-configuration.component.scss'],
})
export class UserFilterConfigurationComponent implements OnInit {
    table_name: string;
    @Input() filter?: MiRutaFilter;
    @Output() sendFilterChange: EventEmitter<MiRutaFilter>;

    filterNotEmpty: boolean = false;

    faInbox = faInbox;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: FilterConfigurationDialogData,
        private _utilsService: UtilsService,
        private _mySqlService: MySqlService
    ) {
        this.table_name = 'water-task';
        if (data.filter) {
            this.filter = data.filter;
        }
        this.sendFilterChange = new EventEmitter();
    }
    ngOnInit(): void {
        if (this.filter && this.filter.fields) {
            if (this.filter.fields!.length > 0) {
                this.filterNotEmpty = true;
            }
        } else {
            this.filterNotEmpty = false;
        }

        const TREE_DATA: FilterNode[] = this._utilsService.getConvertedFilterToNodes(
            this.table_name!,
            this.filter!
        );
        if (TREE_DATA) {
            this.dataSource.data = TREE_DATA;
        }
    }

    private _transformer = (node: FilterNode, level: number) => {
        return {
            expandable: !!node.children && node.children.length > 0,
            name: node.name,
            level: level,
        };
    };

    treeControl = new FlatTreeControl<FlatNode>(
        (node) => node.level,
        (node) => node.expandable
    );

    treeFlattener = new MatTreeFlattener(
        this._transformer,
        (node) => node.level,
        (node) => node.expandable,
        (node) => node.children
    );

    dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    checkBoxChange(checked: boolean, node_name: string) {
        for (let i = 0; i < this.filter!.fields!.length; i++) {
            const fieldName = this._utilsService.getRightFieldName(
                this.table_name!,
                this.filter!.fields![i].field_name
            );
            // console.log(fieldName);
            if (node_name == fieldName) {
                this.filter!.fields![i].active = checked;
            }
        }
        this.sendFilterChange.emit(this.filter);
    }

    isChecked(node: any): boolean {
        for (let i = 0; i < this.filter!.fields!.length; i++) {
            const fieldName = this._utilsService.getRightFieldName(
                this.table_name!,
                this.filter!.fields![i].field_name
            );
            if (node.name == fieldName) {
                return this.filter!.fields![i].active!;
            }
        }
        return false;
    }

    hasChild = (_: number, node: FlatNode) => node.expandable;

    clearFilter() {
        this.filter!.fields = [];
        this.filter!.Or_field = [];
        this.filter!.And_field = [];
        this.sendFilterChange.emit(this.filter);
        this.filterNotEmpty = false;
        sessionStorage.setItem('filterUsers', '');
    }
}

/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { faBuilding, faFilePdf, faKey, faInbox } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { ApiService } from '../../../services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivationLog, getActivationLogFormControls } from '../../../interfaces/activation-log';

@Component({
    selector: 'app-activation-log',
    templateUrl: './activation-log.component.html',
    styleUrls: ['./activation-log.component.scss'],
})
export class ActivationLogComponent implements OnInit {
    faFilePdf = faFilePdf;
    faInbox = faInbox;
    faBuilding = faBuilding;
    faKey = faKey;
    loading: boolean = false;
    activationLogId: string = '';
    activationLog?: ActivationLog;

    activationLogFormData: FormGroup = getActivationLogFormControls();

    activationLogSubcription$?: Subscription;

    percentage: number = 0;
    uploading = false;

    /**
     * Represents the ActivationLogComponent class.
     * This component is responsible for displaying the activation log.
     */
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _apiService: ApiService,
        public _utilsService: UtilsService,
        private spinner: NgxSpinnerService,
        public formBuilder: FormBuilder
    ) {
        this.activationLogId = data.activationLogId;
    }

    /**
     * Initializes the component.
     * If `activationLogId` is provided, it retrieves the activation log data from the API
     * and populates the form controls with the retrieved data.
     * If the activation log data is not found, it displays an error message.
     */
    async ngOnInit(): Promise<void> {
        if (this.activationLogId) {
            this.showLoading(true);
            this.activationLogSubcription$ = this._apiService
                .getDocument(`activation-logs`, this.activationLogId)
                .subscribe(async (doc: any) => {
                    if (!doc) {
                        this._utilsService.openSnackBar(
                            'Error obteniendo datos de activationLog',
                            'error'
                        );
                        this.showLoading(false);
                        return;
                    }
                    const activationLog = doc as ActivationLog;
                    activationLog.id = parseInt(this.activationLogId);

                    this.activationLog = activationLog;

                    const activationLogFormData = getActivationLogFormControls();
                    const keys = Object.keys(activationLog);
                    for (let key of keys) {
                        let value: any = activationLog[key as keyof ActivationLog];
                        if (value) {
                            if (this._utilsService.isValidDate(value)) value = value;
                            activationLogFormData.controls[key].setValue(value);
                        }
                    }
                    this.activationLogFormData = activationLogFormData;

                    this.showLoading(false);
                });
        }
    }

    /**
     * Lifecycle hook that is called when the component is destroyed.
     * It unsubscribes from the activationLogSubcription$ observable if it exists.
     */
    ngOnDestroy(): void {
        this.activationLogSubcription$?.unsubscribe();
    }

    /**
     * Sets the loading state and shows or hides the spinner accordingly.
     * @param state - The loading state to set. If `true`, the spinner will be shown; if `false`, the spinner will be hidden.
     */
    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this.spinner.show('activationLogSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this.spinner.hide('activationLogSpinner');
        }
    }
}

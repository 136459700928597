/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { UtilsService } from './utils.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
    constructor(private router: Router, private _utilsService: UtilsService) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (
            this._utilsService.isSuperUser() ||
            this._utilsService.isClientUser() ||
            this._utilsService.isAdmin()
        ) {
            return true;
        }
        this.router.navigate(['/login']);
        return false;
    }
}

<div id="carrousel" class="container">
    <div class="box" (click)="onBoxClick()">
        <button [disabled]="prev" mat-fab color="primary" (click)="prevPhoto()">
            <fa-icon [icon]="faAngleLeft"></fa-icon>
        </button>

        <div class="col">
            <div class="row fontLabel">
                <h4
                    style="
                        background-color: white;
                        border-radius: 20px;
                        font-weight: bold;
                        padding-left: 10px;
                        padding-right: 10px;
                    "
                    t
                >
                    {{ title }}
                </h4>
            </div>
            <div [class]="animation">
                <lib-ngx-image-zoom
                    #myImageElement
                    (click)="onImageClick()"
                    [thumbImage]="myThumbnail"
                    [fullImage]="myFullresImage"
                    [enableScrollZoom]="true"
                    [zoomMode]="zoomMode"
                ></lib-ngx-image-zoom>
            </div>
        </div>

        <button [disabled]="next" mat-fab color="primary" (click)="nextPhoto()">
            <fa-icon [icon]="faAngleRight"></fa-icon>
        </button>
    </div>
</div>

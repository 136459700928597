/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { WindowRefService } from 'src/app/services/window-ref.service';
import {
    getItacDisplayColumns,
    getItacExcelExportColumns,
    getItacExcelFieldName,
    getItacField,
    getItacFieldType,
    Itac,
} from '../../interfaces/itac';
import * as XLSX from 'xlsx';
import { IpcService } from 'src/app/services/ipc.service';
import { MySqlService } from 'src/app/services/mysql.service';
import { isItacFileField } from '../../interfaces/itac';
import { Team } from '../../interfaces/team';
import { getFieldType } from '../../interfaces/water-task';
import { FileSaverService } from 'src/app/services/file-saver.service';
import { faInbox } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-itacs',
    templateUrl: './itacs.component.html',
    styleUrls: ['./itacs.component.scss'],
})
export class ItacsComponent implements OnInit {
    loading: boolean = true;
    itacs: Itac[] = [];
    dataSource: MatTableDataSource<Itac> = new MatTableDataSource();

    length = 0; //itacs count in current table
    pageSize = 50; //limit of query
    scrollOffset: number = 50;
    rowsLimit: number = 50;
    lastPageIndex = 0;
    pageSizeOptions: number[] = [10, 50, 100, 200, 500];

    tableName: string = 'itacs';
    displayedColumns: string[] = [];
    fixedColumns = [];
    displayedColumnsField: string[];
    clickedRows = new Set<Itac>();
    allSelected = false;
    lastSelectedRow: number = -1;

    filteredColumn?: string;
    orderedColumn?: string;
    faInbox = faInbox;

    loadingText = 'Cargando...';

    menuOptions: string[] = ['Descargar fotos', 'Asignar campos', 'Asignar equipo', 'Eliminar'];

    imageLogo?: string;

    async onScroll() {
        this.scrollOffset += this.rowsLimit;
        if (this.scrollOffset > this.pageSize) return;
        this.dataSource.data = [];
        this.dataSource.data = this.itacs.slice(0, this.scrollOffset);
    }

    constructor(
        private _apiService: ApiService,
        private _mySqlService: MySqlService,
        public _utilsService: UtilsService,
        private _windowRefService: WindowRefService,
        private _spinner: NgxSpinnerService,
        private _fileSaverService: FileSaverService,
        private _electronService: IpcService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.pageSize = parseInt(localStorage.getItem('itac_pageSize') || `${this.rowsLimit}`);

        this.displayedColumns = this._utilsService.setDisplayColumns(
            this.displayedColumns,
            this.tableName,
            getItacDisplayColumns,
        );

        this.displayedColumnsField = this.displayedColumns.map((displayedColumn: string) =>
            getItacField(displayedColumn)
        );
        document.addEventListener('visibilitychange', async () => {
            if (document.hidden) {
            } else {
                const updateNeeded = localStorage.getItem('itacUpdateNeeded');
                if (updateNeeded == 'true') {
                    this.scrollOffset = 50;
                    localStorage.setItem('itacUpdateNeeded', 'false');
                    this.showLoading(true);
                    this.setItacsInTable(await this._mySqlService.getLastItacsPage());
                    this.showLoading(false);
                }
            }
        });
    }

    async ngOnInit(): Promise<void> {
        //? setting default order on first request
        this._utilsService.processOrder(
            this._mySqlService.itacsTableName,
            this._utilsService.orderItacs,
            'itac',
            'ASC'
        );
    }

    async onMenuOptionSelected(option: string) {
        if (option == 'Descargar fotos') {
            await this.downloadPhotos();
        } else if (option == 'Asignar campos') {
            await this.assignFields();
        } else if (option == 'Asignar equipo') {
            await this.assignTeamToItacs();
        } else if (option == 'Eliminar') {
            await this.deleteItacs();
        }
    }

    reload() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['./'], { relativeTo: this.route });
    }
    async downloadPhotos() {
        if (this.clickedRows.size == 0) {
            this._utilsService.openSnackBar('Debe seleccionar al menos una itac', 'warning');
            return;
        }
        try {
            this._spinner.show();
            this.loadingText = `Descargando fotos de itacs ...`;

            const size = this.clickedRows.size;
            let i = 0;
            const companySelected = JSON.parse(localStorage.getItem('companyJson') || '');
            const managerSelected = JSON.parse(localStorage.getItem('managerJson') || '');
            for (let itac of this.clickedRows) {
                this.loadingText = `Descargando fotos de itac ${i++} de ${size}...`;
                const photos: any = this.getDownloadablePhotos(itac);
                for (let photo of photos) {
                    if (this._electronService.isElectronApp()) {
                        const path = `C:\\Mi_Ruta\\Empresas\\${
                            companySelected.nombre_empresa || companySelected.id
                        }\\Gestores\\${
                            managerSelected.gestor || managerSelected.id
                        }\\fotos_itacs\\${itac.codigo_itac}`;
                        const photoBlob = await this._fileSaverService.downloadFileAndGetBlob(
                            photo['photo']
                        );
                        const photoBase64 = await this._utilsService.convertBlobToBase64(photoBlob);

                        const message = {
                            message: 'download_photo_itac',
                            photoBase64: photoBase64,
                            path: path,
                            filename: photo['downloadName'],
                        };
                        this._electronService.sendMessageSync(message);
                    } else {
                        try {
                            await this._fileSaverService.downloadFile(
                                photo['photo'],
                                photo['downloadName']
                            );
                        } catch (err) {}
                    }
                }
            }
            this._utilsService.openSnackBar(`Fotos descargadas correctamente`);
            this._spinner.hide();
            if (this._electronService.isElectronApp()) {
                const path = `C:\\Mi_Ruta\\Empresas\\${
                    companySelected.nombre_empresa || companySelected.id
                }\\Gestores\\${managerSelected.gestor || managerSelected.id}\\fotos_itacs\\`;
                this._electronService.sendMessage('open-folder', {
                    path: path,
                });
            }
        } catch (err) {
            this._utilsService.openSnackBar(`Hubo errores descargando fotos`, 'error');
        }
    }

    getDownloadablePhotos(itac: Itac) {
        let photos = [];
        if (itac.foto_1 && itac.foto_1.includes('http') && itac.foto_1_server_path) {
            const split = itac.foto_1.split('/');
            let downloadName = split[split.length - 1];
            photos.push({
                photo: itac.foto_1,
                downloadName: downloadName,
            });
        }
        if (itac.foto_2 && itac.foto_2.includes('http') && itac.foto_2_server_path) {
            const split = itac.foto_2.split('/');
            let downloadName = split[split.length - 1];
            photos.push({
                photo: itac.foto_2,
                downloadName: downloadName,
            });
        }
        if (itac.foto_3 && itac.foto_3.includes('http') && itac.foto_3_server_path) {
            const split = itac.foto_3.split('/');
            let downloadName = split[split.length - 1];
            photos.push({
                photo: itac.foto_3,
                downloadName: downloadName,
            });
        }
        if (itac.foto_4 && itac.foto_4.includes('http') && itac.foto_4_server_path) {
            const split = itac.foto_4.split('/');
            let downloadName = split[split.length - 1];
            photos.push({
                photo: itac.foto_4,
                downloadName: downloadName,
            });
        }
        if (itac.foto_5 && itac.foto_5.includes('http') && itac.foto_5_server_path) {
            const split = itac.foto_5.split('/');
            let downloadName = split[split.length - 1];
            photos.push({
                photo: itac.foto_5,
                downloadName: downloadName,
            });
        }
        if (itac.foto_6 && itac.foto_6.includes('http') && itac.foto_6_server_path) {
            const split = itac.foto_6.split('/');
            let downloadName = split[split.length - 1];
            photos.push({
                photo: itac.foto_6,
                downloadName: downloadName,
            });
        }
        if (itac.foto_7 && itac.foto_7.includes('http') && itac.foto_7_server_path) {
            const split = itac.foto_7.split('/');
            let downloadName = split[split.length - 1];
            photos.push({
                photo: itac.foto_7,
                downloadName: downloadName,
            });
        }
        if (itac.foto_8 && itac.foto_8.includes('http') && itac.foto_8_server_path) {
            const split = itac.foto_8.split('/');
            let downloadName = split[split.length - 1];
            photos.push({
                photo: itac.foto_8,
                downloadName: downloadName,
            });
        }
        if (itac.foto_9 && itac.foto_9.includes('http') && itac.foto_9_server_path) {
            const split = itac.foto_9.split('/');
            let downloadName = split[split.length - 1];
            photos.push({
                photo: itac.foto_9,
                downloadName: downloadName,
            });
        }
        if (
            itac.audio_detalle &&
            itac.audio_detalle.includes('http') &&
            itac.audio_detalle_server_path
        ) {
            const split = itac.audio_detalle.split('/');
            let downloadName = split[split.length - 1];
            photos.push({
                photo: itac.audio_detalle,
                downloadName: downloadName,
            });
        }
        return photos;
    }

    async assignTeamToItacs() {
        if (this.clickedRows.size == 0) {
            this._utilsService.openSnackBar('Debe seleccionar al menos una itac', 'warning');
            return;
        }
        try {
            let errorIds = [];
            let waterTasksLocationsToUpdate: string[] = [];
            let updateCount = 0;
            const teams = await this._apiService.getTeams();
            if (!teams || teams.length == 0) {
                this._utilsService.openSnackBar(
                    'No hay equipos para esta empresa y gestor',
                    'warning'
                );
                return;
            }
            const teamSelectedName = await this._utilsService.openSelectorDialog(
                'Seleccione equipo',
                teams.map((team: Team) => team.equipo_operario)
            );
            let now = new Date();
            if (teamSelectedName) {
                const teamId = teams.find((team: Team) => team.equipo_operario == teamSelectedName)!
                    .id!;
                if (teamId) {
                    let res = true;
                    if (this.allSelected) {
                        res = await this._utilsService.openQuestionDialog(
                            'Tipo de asignación',
                            'Desea asignar solo a la página actual o a todas las tareas del filtro seleccionado',
                            'Página',
                            'Todas'
                        );
                    }
                    this._spinner.show();
                    this.loadingText = `Asignando ${teamSelectedName} a itacs ...`;
                    const data = { equipo: teamId, date_time_modified: now };
                    const keys = Object.keys(data);

                    if (res) {
                        let ids: number[] = [];
                        for (let row of this.clickedRows) {
                            if (row.id) ids.push(row.id);
                        }
                        try {
                            const result = await this._apiService.updateItacs(ids, data, false);
                            if (result) {
                                for (let row of this.clickedRows) {
                                    const index = this.itacs.indexOf(row, 0);
                                    row['equipo'] = teamId;
                                    row['date_time_modified'] = now;
                                    this.itacs[index] = row;
                                    if (
                                        this._utilsService.isFieldValid(row.geolocalizacion)
                                    ) {
                                        waterTasksLocationsToUpdate.push(
                                            JSON.stringify(row.geolocalizacion)
                                        );
                                    }
                                }
                            } else {
                                errorIds = ids;
                            }
                        } catch (err) {
                            errorIds = ids;
                        }
                    } else {
                        try {
                            let where_clause = '';
                            if (
                                this._utilsService.filterItacs &&
                                this._utilsService.filterItacs.fields
                            ) {
                                where_clause = this._utilsService.getWhereClauseFromFilter(
                                    this._utilsService.filterItacs!
                                );
                            }
                            const result = await this._apiService.updateMultipleItacs(
                                where_clause,
                                data,
                                false
                            );
                            if (result) {
                                this.updateTasksData(where_clause, data);
                                this.updateActualPageInfo(data, keys);
                            } else if (this.itacs.length > 0) errorIds.push(this.itacs[0]);
                        } catch (err) {
                            if (this.itacs.length > 0) errorIds.push(this.itacs[0]);
                            console.log('============= err =============');
                            console.log(err);
                        }
                    }
                    if (errorIds.length > 0) {
                        this._utilsService.openSnackBar(`Hubo errores asignando itacs`, 'error');
                    } else {
                        if (waterTasksLocationsToUpdate.length > 0) {
                            this.loadingText = `Buscando tareas para asignar equipo`;
                            this.updateTasksDataWithLocations(waterTasksLocationsToUpdate, data);
                        }
                        this._utilsService.openSnackBar(`Itacs asignados correctamente`);
                    }
                    this._spinner.hide();
                }
            }
        } catch (err) {
            console.log('============= err =============');
            console.log(err);
        }
    }

    async updateTasksData(itacs_where_clause: string, data: any) {
        let selectJson: any = {};
        selectJson['fields'] = [`geolocalizacion`];
        selectJson['distincts'] = [`geolocalizacion`];
        const jsonString = JSON.stringify(selectJson);
        const query_result_locations = await this._mySqlService.getItacsFieldValues(
            jsonString,
            itacs_where_clause
        );
        let itacLocations: string[] = [];
        for (let json of query_result_locations) {
            if (json.geolocalizacion) itacLocations.push(json.geolocalizacion.toString());
        }
        await this.updateTasksDataWithLocations(itacLocations, data);
    }

    async updateTasksDataWithLocations(locations: string[], data: any) {
        if (locations && locations.length > 0) {
            const where_clause_tasks = this._utilsService.getWhereClauseFromFilter(
                this._utilsService.processFilter(
                    {},
                    locations,
                    'codigo_de_localizacion',
                    getFieldType('codigo_de_localizacion'),
                    this._mySqlService.tasksTableName,
                    false
                )
            );
            let selectJson: any = {};
            selectJson['fields'] = [`id`];
            selectJson['distincts'] = [`id`];
            const jsonString = JSON.stringify(selectJson);
            const query_result_ids = await this._mySqlService.getTasksFieldValues(
                jsonString,
                where_clause_tasks
            );
            const taskIds = query_result_ids.map((json) => json.id!);
            if (taskIds && taskIds.length > 0) {
                const res = await this._apiService.updateTasks(taskIds, data, false);
                if (res) this._utilsService.openSnackBar('Equipo asignado a tareas de itacs');
                else this._utilsService.openSnackBar('Error asignando equipo a tareas de itacs');
            }
        }
    }

    async assignFields() {
        if (this.clickedRows.size == 0) {
            this._utilsService.openSnackBar('Debe seleccionar al menos una itac', 'warning');
            return;
        }
        try {
            let updateCount = 0;
            let errorIds = [];
            let itac = await this._utilsService.openItacAssignDialog();
            if (itac) {
                this.loadingText = `Asignando campos a itacs ...`;
                const keys = Object.keys(itac);

                let res = true;
                if (this.allSelected) {
                    res = await this._utilsService.openQuestionDialog(
                        'Tipo de asignación',
                        'Desea asignar solo a la página actual o a todas las itacs del filtro seleccionado',
                        'Página',
                        'Todas'
                    );
                }
                this._spinner.show();
                if (res) {
                    let ids: number[] = [];
                    for (let row of this.clickedRows) {
                        if (row.id) ids.push(row.id);
                    }
                    try {
                        const result = await this._apiService.updateItacs(ids, itac, false);
                        if (result) {
                            this.updateActualPageInfo(itac, keys);
                        } else {
                            errorIds = ids;
                        }
                    } catch (err) {
                        errorIds = ids;
                    }
                } else {
                    try {
                        let where_clause = '';
                        if (
                            this._utilsService.filterItacs &&
                            this._utilsService.filterItacs.fields
                        ) {
                            where_clause = this._utilsService.getWhereClauseFromFilter(
                                this._utilsService.filterItacs!
                            );
                        }
                        const result = await this._apiService.updateMultipleItacs(
                            where_clause,
                            itac,
                            false
                        );
                        if (result) this.updateActualPageInfo(itac, keys);
                        else if (this.itacs.length > 0) errorIds.push(this.itacs[0]);
                    } catch (err) {
                        if (this.itacs.length > 0) errorIds.push(this.itacs[0]);
                        console.log('============= err =============');
                        console.log(err);
                    }
                }
                if (errorIds.length > 0) {
                    this._utilsService.openSnackBar(`Hubo errores asignando itacs`, 'error');
                } else {
                    this._utilsService.openSnackBar(`Itacs asignadas correctamente`);
                }
                this._spinner.hide();
            }
        } catch (err) {}
    }

    updateActualPageInfo(itac: any, keys: any) {
        for (let row of this.clickedRows) {
            const index = this.itacs.indexOf(row, 0);
            for (let key of keys) {
                const keyIndex = key as keyof Itac;
                let value = itac[keyIndex]!; //this.task[key as keyof Itac];
                row[keyIndex] = value as any;
            }
            this.itacs[index] = row;
        }
    }

    async fileEvent(event: any) {
        if (event['file_option'] == 'Importar itacs') {
            this.processExcelFile(event);
        }
    }
    async processExcelFile(event: any) {
        this._spinner.show();
        this.loadingText = `Cargando ...`;
        //TODO: add itacs from excel
        let workBook: XLSX.WorkBook;
        let jsonData = null;
        const reader = new FileReader();
        const file = event['file'].target.files[0];
        reader.onload = async (_) => {
            this._spinner.show();
            this.loadingText = `Procesando itacs ...`;
            const data = reader.result;
            workBook = XLSX.read(data, { type: 'binary' });
            jsonData = workBook.SheetNames.reduce((initial: any, name) => {
                const sheet = workBook.Sheets[name];
                initial[name] = XLSX.utils.sheet_to_json(sheet);
                return initial;
            }, {});
            let sheets: string[] = [];
            let arrayItacs: Itac[] = [];

            Object.keys(jsonData).forEach((key: string) => {
                sheets.push(key);
            });
            for (let sheet of sheets) {
                for (let jsonItac of jsonData[sheet]) {
                    let itacData: any = {};
                    Object.keys(jsonItac).forEach((key) => {
                        let field: string = getItacExcelFieldName(key);
                        if (this._utilsService.isFieldValid(field)) {
                            let value = jsonItac[key];
                            try {
                                if (field === 'gestor') {
                                } else if (typeof value === 'number') {
                                    if (getItacFieldType(field) == 'number') {
                                        itacData[`${field}`] = value;
                                    } else if (getItacFieldType(field) == 'string') {
                                        itacData[`${field}`] = value.toString().trim();
                                    } else if (getItacFieldType(field) == 'boolean') {
                                        itacData[`${field}`] = value ? true : false;
                                    } else if (getItacFieldType(field) == 'Date') {
                                        itacData[`${field}`] = new Date(value);
                                    }
                                } else if (
                                    //https://mywateroute.com/Mi_Ruta/Empresas/MR_Aaguas_S_L_/Gestores/Ayuntamiento%20Hernani/fotos_ITACs/
                                    typeof value === 'string' &&
                                    this._utilsService.isFieldValid(value)
                                ) {
                                    if (getItacFieldType(field) == 'string') {
                                        itacData[`${field}`] = value.trim();
                                    } else if (getItacFieldType(field) == 'number') {
                                        itacData[`${field}`] = parseInt(value);
                                    } else if (getItacFieldType(field) == 'boolean') {
                                        itacData[`${field}`] = value ? true : false;
                                    } else if (getItacFieldType(field) == 'Date') {
                                        itacData[`${field}`] = new Date(value);
                                    } else if (getItacFieldType(field) == 'MyLatLng') {
                                        const geolocation =
                                            this._utilsService.getMyLatLngFromString(value);
                                        if (geolocation) itacData[`${field}`] = geolocation;
                                    }
                                }
                                if (isItacFileField(field) && itacData['codigo_itac']) {
                                    const prefix =
                                        'https://mywateroute.com/Mi_Ruta/Empresas/MR_Aaguas_S_L_/Gestores/Ayuntamiento Hernani/fotos_ITACs/';
                                    itacData[`${field}`] =
                                        prefix +
                                        itacData['codigo_itac'] +
                                        '/' +
                                        itacData[`${field}`];
                                }
                            } catch (error) {
                                // console.log(value);
                            }
                        }
                    });
                    if (
                        itacData['gestor'] === 2 ||
                        !itacData['itac'] ||
                        !itacData['itac'].includes('HERNANI')
                    )
                        continue;
                    let itac = itacData as Itac;
                    if (itac['codigo_itac']) {
                        let split = itac['codigo_itac'].split('.');
                        if (split.length > 2) {
                            const lastNumbers = split[split.length - 1];
                            split.splice(split.length - 1, 1);
                            itac['codigo_itac'] = '0' + split.join('') + '.' + lastNumbers;
                        }
                    }
                    if (!itac['codigo_itac']?.includes('.')) {
                        const length = itac['codigo_itac']?.length!;
                        itac['codigo_itac'] =
                            itac['codigo_itac']?.substring(0, length - 3) +
                            '.' +
                            itac['codigo_itac']?.substring(length - 3);
                    }
                    if (itac && this._utilsService.isFieldValid(itac.codigo_itac)) {
                        const { id, ...itacWithoutId } = itac;
                        const company = localStorage.getItem('company');
                        const manager = localStorage.getItem('manager');
                        itacData['company'] = parseInt(company!);
                        itacData['gestor'] = parseInt(manager!);
                        if (itac.gestor) arrayItacs.push(itacWithoutId);
                    }
                }
            }
            console.log('============= arrayItacs =============');
            console.log(arrayItacs);
            const result = await this.importItacs(arrayItacs);
            this._spinner.hide();
        };
        reader.readAsBinaryString(file);

        this._spinner.hide();
    }

    async importItacs(itacs: Itac[]) {
        this._spinner.show();
        this.loadingText = `Añadiendo itacs ...`;
        let i = 0,
            errorIds = [];
        for (let itac of itacs) {
            this.loadingText = `Añadiendo itacs ${++i} de ${itacs.length}`;
            if (!(await this._apiService.addItac(itac))) {
                errorIds.push(itac.codigo_itac);
            }
        }
        this._spinner.hide();
        if (errorIds.length > 0) {
            this._utilsService.openSnackBar(`Hubo errores añadiendo itacs`, 'error');
        } else {
            this._utilsService.openSnackBar(`itacs subidos correctamente`);
        }
        await this.getItacs();
        return !(errorIds.length > 0);
    }

    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this._spinner.show('innerSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this._spinner.hide('innerSpinner');
        }
    }

    setItacsInTable(itacs: Itac[]) {
        this.dataSource.data = [];
        this.itacs = itacs;
        for (let [index, itac] of this.itacs.entries()) {
            itac.ID = index + 1;
        }
        this.dataSource.data = this.itacs.slice(0, this.rowsLimit);
        this.showLoading(false);
    }

    async getItacs() {
        const managerSelected = JSON.parse(localStorage.getItem('managerJson') || '{}');
        this.imageLogo = managerSelected.photo;

        this.setItacsInTable(await this.selectItacs());
    }
    async selectItacs(status?: string) {
        this.showLoading(true);
        let where = '';

        this.length = await this._mySqlService.getItacsCount(where);
        const order = this._utilsService.orderItacs;
        let order_clause = undefined;
        if (order.length > 0) {
            order_clause = this._utilsService.getOrderClauseFromOrder(order);
        }

        let offset = '0';
        const lastPageIndex = localStorage.getItem('lastPageIndexItac');
        if (lastPageIndex) {
            localStorage.setItem('lastPageIndexItac', '');
            this.lastPageIndex = parseInt(lastPageIndex);
            offset = (this.lastPageIndex * this.pageSize).toString();
        }

        return await this._mySqlService.getItacs(
            undefined,
            /*where*/ undefined,
            order_clause,
            offset,
            this.pageSize.toString()
        );
    }

    async selectAll() {
        this.clickedRows.clear();
        const itacs = this.itacs;
        for (const itac of itacs) {
            if (!this.clickedRows.has(itac)) {
                this.clickedRows.add(itac);
            }
        }
        this.allSelected = true;
        this._utilsService.openSnackBar(`Seleccionadas ${this.clickedRows.size} itacs`);
    }

    searchValue(event: any) {
        const filterValue: string = event;
        this.dataSource!.filter = filterValue.trim().toLowerCase();
    }

    openMaps() {}

    async deleteItacs() {
        let errorIds = [];
        if (this.clickedRows.size == 0) {
            this._utilsService.openSnackBar('Debe seleccionar al menos una itac', 'warning');
            return;
        }
        const result = await this._utilsService.openQuestionDialog(
            'Confirmación',
            '¿Desea eliminar las itacs seleccionadas?'
        );
        if (result) {
            this._spinner.show();
            this.loadingText = `Eliminando itacs ...`;
            let rowIndexes = new Set<number>();
            const oldDataSource = this.itacs;
            this.clickedRows.forEach(async (row) => {
                console.log(row.id);

                const index = this.itacs.indexOf(row, 0);
                if (index > -1) {
                    rowIndexes.add(index);
                }
            });
            let itacs = [];
            let deleteCount = 0;
            for (let i = 0; i < oldDataSource.length; i++) {
                if (!rowIndexes.has(i)) {
                    itacs.push(oldDataSource![i]);
                } else {
                    this.loadingText = `Eliminando itacs ${++deleteCount} de ${rowIndexes.size}`;
                    if (!(await this._apiService.deleteItac(oldDataSource[i].id!.toString()))) {
                        console.log('****************** deleteItac error *************');
                        errorIds.push(oldDataSource[i].id);
                    } else {
                        console.log('****************** deleteItac success *************');
                    }
                }
            }
            if (errorIds.length > 0) {
                this._utilsService.openSnackBar(`Hubo errores eliminando itacs`, 'error');
            } else {
                this._utilsService.openSnackBar(`Tareas eliminadas correctamente`);
            }
            this.length -= rowIndexes.size;
            this.setItacsInTable(itacs);
            this.allSelected = false;
            this.clickedRows.clear();
            this._spinner.hide();
        }
    }

    async pageEvent(event: any) {
        if (this.lastPageIndex != event.pageIndex) {
            this.showLoading(true);
            if (this.lastPageIndex < event.pageIndex) {
                this.setItacsInTable(
                    await this._mySqlService.getNextItacsPage(event.pageIndex - this.lastPageIndex)
                );
            } else {
                this.setItacsInTable(
                    await this._mySqlService.getPreviousItacsPage(
                        this.lastPageIndex - event.pageIndex
                    )
                );
            }
            this.lastPageIndex = event.pageIndex;
        }
        if (this.pageSize != event.pageSize) {
            this.pageSize = event.pageSize;
            localStorage.setItem('itac_pageSize', this.pageSize.toString());
            await this.getItacs();
        }
        this.scrollOffset = 50;
    }

    async filterItac(where?: string) {
        // console.log('************** filterItac ***************');
        this.length = await this._mySqlService.getItacsCount(where);
        const order = this._utilsService.orderItacs;
        let order_clause = undefined;
        if (order.length > 0) {
            order_clause = this._utilsService.getOrderClauseFromOrder(order);
        }
        return await this._mySqlService.getItacs(
            undefined,
            where,
            order_clause,
            '0',
            this.pageSize.toString()
        );
    }

    async applyFilter(values: any, column: string, not_empty: boolean = false, empties_checked: boolean = false) {
        const where_clause = this._utilsService.getWhereClauseFromFilter(
            this._utilsService.processFilter(
                this._utilsService.filterItacs!,
                values,
                column,
                getItacFieldType(column),
                this._mySqlService.itacsTableName,
                true, 
                not_empty,
                empties_checked
            )
        );
        this.showLoading(true);
        localStorage.setItem('lastPageIndexItac', '');
        this.lastPageIndex = 0;
        this.setItacsInTable(await this.filterItac(where_clause));
    }

    async filterBy(column: string) {
        this.filteredColumn = getItacField(column);
        if (getItacFieldType(this.filteredColumn) == 'Date') {
            const dates = await this._utilsService.openDateRangeSelectorDialog(
                'Seleccione rango de fechas'
            );
            let times: Date[];
            try {
                times = await this._utilsService.openTimeRangeSelectorDialog(
                    'Seleccione rango de horas'
                );
                this.onDateSelected(dates, times);
            } catch (err) {
                this.onDateSelected(dates);
            }
        } else {
            const result = await this._utilsService.openFilterDialog(
                column,
                this.filteredColumn,
                this._mySqlService.itacsTableName,
                this._utilsService.filterItacs
            );
            if (result && result.data) {
                 this.applyFilter(result.data, result.column, result.not_empty, result.empties_checked);
            }
        }
    }

    async onDateSelected(dateRange: Date[], timeRange?: Date[]) {
        if (dateRange) {
            const values = this._utilsService.getDateRangeString(dateRange, timeRange);
            await this.applyFilter(values, this.filteredColumn!);
        } else {
            this._utilsService.openSnackBar('Rango fechas inválido', 'error');
        }
    }

    async orderBy(event: any) {
        const column = event.column;
        const orderType = event.orderType;

        const orderedColumn = getItacField(column);
        const order_clause = this._utilsService.getOrderClauseFromOrder(
            this._utilsService.processOrder(
                this._mySqlService.itacsTableName,
                this._utilsService.orderItacs,
                orderedColumn,
                orderType
            )
        );
        const filter = this._utilsService.filterItacs;
        let where_clause = undefined;
        if (filter && filter.fields) {
            where_clause = this._utilsService.getWhereClauseFromFilter(filter);
        }
        this.showLoading(true);
        const itacs = await this._mySqlService.getItacs(
            undefined,
            where_clause,
            order_clause,
            '0',
            this.pageSize.toString()
        );
        localStorage.setItem('lastPageIndexItac', '');
        this.lastPageIndex = 0;
        this.setItacsInTable(itacs);
    }

    async addNewRow(event: any) {
        const sameTab = this._utilsService.getLocalStorageBoolean('openTaskInSameTab');
        if (this._electronService.isElectronApp() || sameTab) {
            this.router.navigate(['/itac', 'new']); // open task in the same tab
        } else {
            const url = this.router.serializeUrl(this.router.createUrlTree(['/itac', 'new']));
            window.open(url, '_blank');
        }
    }

    doubleClickedRow(row: any) {
        const sameTab = this._utilsService.getLocalStorageBoolean('openTaskInSameTab');
        localStorage.setItem('lastPageIndexItac', this.lastPageIndex.toString());
        if (this._electronService.isElectronApp() || sameTab) {
            this.router.navigate(['/itac', row.id]); // open itac in the same tab
        } else {
            // this.router.navigate(['/itac', row.id]); // open itac in the same tab
            const url = this.router.serializeUrl(this.router.createUrlTree(['/itac', row.id]));
            window.open(url, '_blank');
        }
    }

    clickedRow(receivedEvent: any) {
        const row = receivedEvent.row;
        const event = receivedEvent.event;
        const rowIndex = receivedEvent.rowIndex;

        const previousRow = this.lastSelectedRow;
        this.lastSelectedRow = rowIndex;

        if (event.button === 0) {
            if (!event.ctrlKey && !event.shiftKey) {
                this.allSelected = false;

                this.clickedRows.clear();
                this.toggleRow(row);
                // console.log('**************** !event.ctrlKey && !event.shiftKey ***************');
            } else if (event.ctrlKey) {
                this.toggleRow(row);
                // console.log('**************** event.ctrlKey ***************');
            }
            if (event.shiftKey) {
                this.selectRowsBetweenIndexes(previousRow, rowIndex);
                console.log('**************** event.shiftKey ***************');
            }
        }
        if (this._windowRefService.nativeWindow.getSelection) {
            //remove selection in table with shift
            if (this._windowRefService.nativeWindow.getSelection().empty) {
                // Chrome
                this._windowRefService.nativeWindow.getSelection().empty();
            } else if (this._windowRefService.nativeWindow.getSelection().removeAllRanges) {
                // Firefox
                this._windowRefService.nativeWindow.getSelection().removeAllRanges();
            }
        }
    }

    selectedRows(event: any) {
        this.allSelected = false;
        this.clickedRows.clear();
        this.selectRowsBetweenIndexes(event[0], event[1], false);
    }

    selectRowsBetweenIndexes(
        lastSelectedRow: number,
        rowIndex: number,
        showSnackBar: boolean = true
    ) {
        let start, end;
        if (rowIndex > lastSelectedRow) {
            start = lastSelectedRow;
            end = rowIndex;
        } else {
            end = lastSelectedRow;
            start = rowIndex;
        }
        for (let i = start; i <= end; i++) {
            this.clickedRows.add(this.itacs[i]);
        }
        if (this.clickedRows.size > 1 && showSnackBar) {
            this._utilsService.openSnackBar(`Seleccionadas ${this.clickedRows.size} tareas`);
        }
    }
    toggleRow(row: any) {
        if (this.clickedRows.has(row)) {
            this.clickedRows.delete(row);
        } else {
            this.clickedRows.add(row);
        }
    }

    async exportItacsInTable() {
        let result: boolean = false;
        if (this.clickedRows.size) {
            result = await this._utilsService.openQuestionDialog(
                'Seleccione',
                '¿Exportar solo las itacs seleccionadas?',
                'Seleccionadas',
                'Todas'
            );
        }
        if (result) this.exportExcel(this.clickedRows);
        else {
            this.loadingText = `Descargando para exportación ...`;
            this._spinner.show();
            const savedOffset = this._mySqlService.last_itac_offset;
            let itacs: Itac[] = [];
            const filter = this._utilsService.filterItacs;
            let where_clause = undefined;
            if (filter && filter.fields) {
                where_clause = this._utilsService.getWhereClauseFromFilter(filter);
            }
            const count = await this._mySqlService.getItacsCount(where_clause);
            const order = this._utilsService.orderItacs;
            let order_clause = undefined;
            if (order.length > 0) order_clause = this._utilsService.getOrderClauseFromOrder(order);
            let limit: number = 1000;
            for (let offset = 0; offset < count; offset += limit) {
                this.loadingText = `Descargando para exportación (${offset} de ${count}) ...`;
                const serverItacs = await this._mySqlService.getItacs(
                    undefined,
                    where_clause,
                    order_clause,
                    offset.toString(),
                    limit.toString()
                );
                itacs = itacs.concat(serverItacs);
            }
            this._mySqlService.last_itac_offset = savedOffset;
            this.exportExcel(itacs);
            this._spinner.hide();
        }
    }

    exportExcel(itacs: any) {
        let excelFormatTasks = [];
        for (let itac of itacs) {
            let data: any = {};
            const columns = getItacExcelExportColumns();
            columns.forEach((column) => {
                data[`${column}`] = this._utilsService.tableDataPipe(
                    itac[getItacExcelFieldName(column)]
                );
            });
            excelFormatTasks.push(data);
        }
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelFormatTasks);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        /* save to file */
        XLSX.writeFile(wb, 'Itacs_Exportados.xlsx');
    }

    async openSettings() {
        await this._utilsService.openFilterConfigurationDialog(
            this._mySqlService.itacsTableName,
            this._utilsService.filterItacs
        );
    }
}

<mat-dialog-content>
    <div [formGroup]="itacFormData">
        <div class="container">
            <div class="row bd-highlight" class="row-content-center">
                <p style="font-size: large; margin: 6px; margin-bottom: 20px">
                    Sección 4 Estado de Tuberías
                </p>

                <div class="row">
                    <mat-form-field appearance="fill">
                        <mat-label>Tubo de alimentación</mat-label>
                        <mat-select formControlName="tubo_de_alimentacion">
                            <mat-option *ngFor="let option of statuses" [value]="option">{{
                                option
                            }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Colector</mat-label>
                        <mat-select formControlName="colector">
                            <mat-option *ngFor="let option of statuses" [value]="option">{{
                                option
                            }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Tuberías de entrada del contador</mat-label>
                        <mat-select formControlName="tuberias_de_entrada_contador">
                            <mat-option *ngFor="let option of statuses" [value]="option">{{
                                option
                            }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Tuberías de salida del contador</mat-label>
                        <mat-select formControlName="tuberias_de_salida_contador">
                            <mat-option *ngFor="let option of statuses" [value]="option">{{
                                option
                            }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="row">
                    <mat-form-field appearance="fill">
                        <mat-label>Nº PUNTOS DE AGUA TOTAL</mat-label>
                        <input
                            formControlName="puntos_agua_total"
                            matInput
                            type="number"
                            placeholder="Nº PUNTOS DE AGUA TOTAL"
                        />
                    </mat-form-field>
                    <mat-form-field style="flex: 1" appearance="fill">
                        <mat-label>Nº PUNTOS DE AGUA CON CONTADOR</mat-label>
                        <input
                            formControlName="puntos_agua_con_contador"
                            matInput
                            type="number"
                            placeholder="Nº PUNTOS DE AGUA CON CONTADOR"
                        />
                    </mat-form-field>
                    <mat-form-field style="flex: 1" appearance="fill">
                        <mat-label>Nº PUNTOS DE AGUA CON CONTADOR MÁS TAREA ABIERTA</mat-label>
                        <input
                            formControlName="puntos_agua_con_contador_mas_tarea"
                            matInput
                            type="number"
                            placeholder="Nº PUNTOS DE AGUA CON CONTADOR MÁS TAREA ABIERTA"
                        />
                    </mat-form-field>
                </div>

                <div class="row">
                    <mat-form-field style="flex: 3" appearance="fill">
                        <mat-label>Nota</mat-label>
                        <textarea
                            matInput
                            formControlName="estado_de_valvulas_nota"
                            placeholder="Nota"
                            cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="3"
                        >
                        </textarea>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false">Cancelar</button>
    <button mat-button cdkFocusInitial (click)="saveData()">
        <mat-icon color="primary">check</mat-icon> Actualizar
    </button>
</mat-dialog-actions>

<ngx-spinner
    [bdColor]="itac ? 'rgba(0, 0, 0, 0.2)' : 'rgba(255, 255, 255, 1)'"
    size="large"
    color="#368DCE"
    type="ball-scale-multiple"
    [fullScreen]="true"
    name="itacSpinner"
>
</ngx-spinner>

import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Location } from '@angular/common';
import { IpcService } from '../../../../services/ipc.service';
import { ChartOptions, ChartType, ChartDataset } from 'chart.js';
import { UtilsService } from '../../../../services/utils.service';
import { FormControl, FormGroup } from '@angular/forms';
import { MiRutaUser } from 'src/app/interfaces/mi-ruta-user';
import { WaterTaskUpdate } from '../../../../interfaces/water-task-update';
import * as moment from 'moment';

@Component({
    selector: 'app-user-activity',
    templateUrl: './user-activity.component.html',
    styleUrls: ['./user-activity.component.scss']
})
export class UserActivityComponent implements OnInit {

    timeGroup: FormGroup = new FormGroup({
        type: new FormControl(),
        date: new FormControl(),
        user: new FormControl(),
    });
    timeFullScreen: boolean = false;

    minHour: number = 8;
    maxHour: number = 18; 
    minutesRange: number = 5;

    timeLabels: string[] = Array.from({length: (this.maxHour - this.minHour)*(60/this.minutesRange)}, (_, i) => {
        const hours = this.minHour + Math.floor(i / (60/this.minutesRange));
        const minutes = (i % (60/this.minutesRange)) * this.minutesRange;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    });

    public timeChartLabels: string[] = [...this.timeLabels];
    public timeChartType: ChartType = 'line';
    public timeChartLegend = true;
    public timeChartData: ChartDataset[] = [{ data: new Array(this.timeLabels.length).fill(0), label: 'Sin día o operario seleccionado' }];

    public chartTypes: ChartType[] = ['line', 'bar', 'radar', 'pie', 'doughnut', 'polarArea', 'bubble', 'scatter'];
        
    public barChartOptions: ChartOptions = { 
        responsive: true 
    };

    loadingTime: boolean = false;
    
    users: MiRutaUser[] = [];

    /**
     * Represents the UserActivityComponent class.
     * This component is responsible for managing user activity statistics.
     */
    constructor(
        private _apiService: ApiService,
        private _utilsService: UtilsService,
        public electronService: IpcService,
        public location: Location,
    ) { }

    /**
     * Initializes the component and performs necessary setup tasks.
     * This method is automatically called by Angular after the component is created.
     * @returns A Promise that resolves when the initialization is complete.
     */
    async ngOnInit(): Promise<void> {
        this.users = await this._apiService.getUsers(['operario']);
        await this.setTotalMonthActions();
        this.loadData();
    }

    /**
     * Loads the user activity data from session storage and populates the form controls.
     */
    loadData(){
        const storedUser = sessionStorage.getItem('user-activity-user');
        const storedDate = sessionStorage.getItem('user-activity-date');
        if (storedUser && storedDate) {
            const date = new Date(storedDate);
            this.timeGroup.controls['date'].setValue(moment(date));
            const miRutaUser: MiRutaUser = JSON.parse(storedUser);
            const user = this.users.find(u => u.id === miRutaUser.id);
            this.timeGroup.controls['user'].setValue(user);
        }
    }

    /**
     * Sets the total month actions and subscribes to value changes of the timeGroup controls.
     * 
     * @returns A promise that resolves when the total month actions are set.
     */
    async setTotalMonthActions(): Promise<void> {
        this.timeGroup.controls['type'].valueChanges.subscribe((value) => {
            this.timeChartType = value;
        });
        this.timeGroup.controls['date'].valueChanges.subscribe(async (date: moment.Moment) => {
            const user = this.timeGroup.controls['user'].value;
            await this.getData(user, date);
        });
        this.timeGroup.controls['user'].valueChanges.subscribe(async (user: MiRutaUser) => {
            const date = this.timeGroup.controls['date'].value;
            await this.getData(user, date);
        });
        this.timeGroup.controls['type'].setValue(this.timeChartType);
        this.timeGroup.controls['date'].setValue(moment());
    }

    /**
     * Toggles the full screen mode for the time display.
     * 
     * @returns A promise that resolves when the full screen mode is toggled.
     */
    async toggleTimeFullScreen(): Promise<void> {
        const date = this.timeGroup.controls['date'].value;
        const user = this.timeGroup.controls['user'].value;
        this.timeFullScreen = !this.timeFullScreen;
        this.getData(user, date);
    }

    /**
     * Retrieves data for a user's activity on a specific date.
     * @param user - The user for whom to retrieve the activity data.
     * @param date - The date for which to retrieve the activity data.
     * @returns A Promise that resolves when the data is retrieved.
     */
    async getData(user: MiRutaUser, date: moment.Moment): Promise<void> {
        if (user && date) {
            sessionStorage.setItem('user-activity-user', JSON.stringify(user));
            sessionStorage.setItem('user-activity-date', date.toDate().toString());
            this.loadingTime = true;
            const name = this._utilsService.getUserFullName(user);
            const updates: WaterTaskUpdate[] = await this._apiService.getTaskUpdatesUser(user.username, date.toDate(), 1000);
            if(updates.length) {
                let data: number[] = this.getDataFromUpdates(updates);
                this.timeChartData = [];
                this.timeChartData.push({ data: data, label: user.nombre });
            }
            else {
                const text = [`${name} no tiene actividad el día ${moment(date).format('DD/MM/YYYY')}`];
                this._utilsService.openInformationDialog('Sin actividad', text);
            }
            this.loadingTime = false;
        }
    }

    /**
     * Retrieves data from updates and returns an array of numbers representing the activity count for each time label.
     * @param updates - An array of WaterTaskUpdate objects.
     * @returns An array of numbers representing the activity count for each time label.
     */
    getDataFromUpdates(updates: WaterTaskUpdate[]) {
        let data: number[] = new Array(this.timeLabels.length).fill(0);
        for(const update of updates) {
            const time = moment(update.update_date).format('HH:mm');
            const timeMoment = moment(time, 'HH:mm');
            const index = this.timeLabels.findIndex(label => {
                const labelMoment = moment(label, 'HH:mm');
                return Math.abs(timeMoment.diff(labelMoment, 'minutes')) < this.minutesRange;
            });
            if(index !== -1) {
                if(update.type == 'UPDATE') data[index] += 1;
                else if(data[index] == 0) data[index] += 1;
            }
        }
        return data;
    }
}

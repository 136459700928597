<mat-dialog-content [formGroup]="formData">
    <div style="display: flex">
        <mat-form-field style="flex: 3" appearance="fill">
            <mat-label>{{ placeHolderText }}</mat-label>
            <mat-select
                name="option"
                required
                formControlName="currentOptionsSelected"
                multiple
                #selectedOption
            >
                <mat-option *ngFor="let option of options" [value]="option">
                    {{ option }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="center">
    <button mat-button mat-dialog-close>Cancelar</button>
    <button
        mat-button
        [mat-dialog-close]="formData.controls['currentOptionsSelected'].value"
        [disabled]="!formData.controls['currentOptionsSelected'].value"
    >
        Aceptar
    </button>
</mat-dialog-actions>

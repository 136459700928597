<mat-dialog-content class="animate__animated animate__fadeIn">
    <div [formGroup]="miRutaUserFormData" class="animate__animated animate__fadeIn">
        <input class="input-hidden" type="file" (change)="uploadFile($event)" #input_file />

        <div>
            <div class="container">
                <div class="row bd-highlight" class="row-content-center">
                    <div>
                        <div style="margin-top: 5px; margin-bottom: 30px; margin-left: 5px">
                            <div style="display: flex; justify-content: space-between">
                                <p style="font-size: large">Usuario</p>
                                <div style="flex: 2"></div>
                                <div style="margin-left: 50px;">
                                    <mat-slide-toggle
                                        style="margin-left: 5%; margin-top: 2%"
                                        color="primary"
                                        (change)="onReadNotificationsChange($event)"
                                        [checked]="this.miRutaUser?.settings?.read_notifications? true: false"
                                        >Ver notificationes</mat-slide-toggle>
                                    <mat-slide-toggle
                                        style="margin-left: 5%; margin-top: 2%"
                                        color="primary"
                                        (change)="onReadMessagesChange($event)"
                                        [checked]="this.miRutaUser?.settings?.read_messages? true: false"
                                        >Ver mensajes</mat-slide-toggle> 
                                    <mat-slide-toggle
                                        style="margin-left: 5%; margin-top: 2%"
                                        color="primary"
                                        (change)="onDebugErrorsChange($event)"
                                        [checked]="this.miRutaUser?.settings?.debug_front_errors? true: false"
                                        >Depuración de errores</mat-slide-toggle>                               
                                </div>
                                <div style="width: 10px"></div>
                                <button
                                    matTooltip="Cambiar contraseña"
                                    style="max-height: 50px; margin-top: 5px"
                                    mat-raised-button
                                    color="primary"
                                    (click)="changePassword()"
                                >
                                    Cambiar contraseña
                                </button>
                                <div style="width: 10px"></div>
                                <button
                                    *ngIf="_utilsService.isClient(miRutaUser!)"
                                    matTooltip="Añadir restriciones al usuario en algún campo correspondiente a las tareas"
                                    style="max-height: 50px; margin-top: 5px"
                                    mat-raised-button
                                    color="primary"
                                    (click)="showRestrictions()"
                                >
                                    Restriciones
                                </button>
                                <div style="width: 10px"></div>
                                <button
                                    *ngIf="_utilsService.isClient(miRutaUser!)"
                                    matTooltip="Eliminar todas las restriciones de este usuario y concederle acceso a todas las tareas de su empresa y gestor"
                                    class="danger-color"
                                    style="max-height: 50px; margin-top: 5px; color: white"
                                    mat-raised-button
                                    color="terciary"
                                    (click)="clearRestrictions()"
                                >
                                    Eliminar restriciones
                                </button>
                                <button
                                    mat-icon-button
                                    aria-label="Calendar"
                                    style="margin-top: 0px; margin-right: 10px"
                                    matTooltip="Ver jornada de fontanero"
                                    (click)="openTrace()"
                                >
                                    <mat-icon style="font-size: 40px; color: #777777">
                                        calendar
                                    </mat-icon>
                                </button>
                                <button
                                    mat-icon-button
                                    aria-label="User image"
                                    style="margin-top: 0px; margin-right: 10px"
                                    matTooltip="Ver localización de fontanero"
                                    (click)="openTrace()"
                                >
                                    <mat-icon style="font-size: 40px; color: #777777">
                                        location_on
                                    </mat-icon>
                                </button>
                                <div>
                                    <button
                                        mat-icon-button
                                        aria-label="User image"
                                        [matMenuTriggerFor]="userMenu"
                                        style="margin-top: 0px; margin-right: 20px"
                                    >
                                        <img
                                            *ngIf="miRutaUser?.photo"
                                            [src]="miRutaUser?.photo"
                                            id="photo"
                                            placeholder="assets/img/noimage.png"
                                            onerror="this.onerror=null; this.src='assets/img/noimage.png'"
                                            style="border-radius: 50%; object-fit: cover"
                                            width="45"
                                            height="45"
                                        />
                                        <mat-icon
                                            *ngIf="!miRutaUser?.photo"
                                            matTooltip="Añadir foto de usuario"
                                            style="font-size: 50px; color: #777777"
                                        >
                                            add_photo_alternate
                                        </mat-icon>
                                        <mat-progress-bar
                                            *ngIf="uploading"
                                            color="primary"
                                            mode="determinate"
                                            value="{{ percentage }}"
                                        ></mat-progress-bar>
                                    </button>
                                    <mat-menu #userMenu="matMenu">
                                        <button
                                            mat-menu-item
                                            (click)="selectFile(input_file)"
                                            style="text-align: center"
                                        >
                                            <span style="margin-bottom: 10px; text-align: center"
                                                >Cambiar</span
                                            >
                                        </button>
                                        <button mat-menu-item style="text-align: center">
                                            <span style="margin-bottom: 10px; text-align: center"
                                                >Mostrar</span
                                            >
                                        </button>
                                    </mat-menu>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="row-content">
                                <mat-form-field style="width: 120px" appearance="fill">
                                    <mat-label>Correo</mat-label>
                                    <input
                                        formControlName="username"
                                        matInput
                                        type="text"
                                        placeholder="Correo..."
                                        require
                                    />
                                </mat-form-field>
                                <mat-form-field *ngIf="!miRutaUserId" appearance="fill">
                                    <mat-label>Contraseña</mat-label>
                                    <input
                                        formControlName="password"
                                        matInput
                                        type="text"
                                        placeholder="Contraseña..."
                                        require
                                    />
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Teléfono</mat-label>
                                    <input
                                        formControlName="phone"
                                        matInput
                                        type="text"
                                        placeholder="Telefono..."
                                    />
                                </mat-form-field>
                            </div>

                            <div class="row-content">
                                <mat-form-field style="width: 120px" appearance="fill">
                                    <mat-label>Código</mat-label>
                                    <input
                                        formControlName="codigo"
                                        matInput
                                        type="text"
                                        placeholder="Código..."
                                    />
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Nombre</mat-label>
                                    <input
                                        formControlName="nombre"
                                        matInput
                                        type="text"
                                        placeholder="Nombre..."
                                    />
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Apellido 1</mat-label>
                                    <input
                                        formControlName="apellido1"
                                        matInput
                                        type="text"
                                        placeholder="Apellido 1..."
                                    />
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Apellido 2</mat-label>
                                    <input
                                        formControlName="apellido2"
                                        matInput
                                        type="text"
                                        placeholder="Apellido 2..."
                                    />
                                </mat-form-field>
                            </div>

                            <div class="row-content">
                                <mat-form-field appearance="fill">
                                    <mat-label>Superusuario</mat-label>
                                    <mat-select formControlName="superusuario">
                                        <mat-option
                                            *ngFor="let option of [true, false]"
                                            [value]="option"
                                            >{{ option ? 'Si' : 'No' }}</mat-option
                                        >
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Administrador</mat-label>
                                    <mat-select formControlName="administrador">
                                        <mat-option
                                            *ngFor="let option of [true, false]"
                                            [value]="option"
                                            >{{ option ? 'Si' : 'No' }}</mat-option
                                        >
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Operario</mat-label>
                                    <mat-select formControlName="operario">
                                        <mat-option
                                            *ngFor="let option of [true, false]"
                                            [value]="option"
                                            >{{ option ? 'Si' : 'No' }}</mat-option
                                        >
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Cliente</mat-label>
                                    <mat-select formControlName="cliente">
                                        <mat-option
                                            *ngFor="let option of [true, false]"
                                            [value]="option"
                                            >{{ option ? 'Si' : 'No' }}</mat-option
                                        >
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="row-content">
                                <mat-form-field style="flex: 3" appearance="fill">
                                    <mat-label>Empresas</mat-label>
                                    <input
                                        [value]="
                                            miRutaUserFormData.get('companies')?.value | companyPipe
                                        "
                                        matInput
                                        type="text"
                                        placeholder="Empresas"
                                        readonly
                                    />
                                    <button
                                        matTooltip="Añadir empresas"
                                        matSuffix
                                        mat-icon-button
                                        aria-label="Open companies selection"
                                        (click)="addCompanies()"
                                    >
                                        <mat-icon color="primary">edit</mat-icon>
                                    </button>
                                </mat-form-field>

                                <mat-form-field style="flex: 3" appearance="fill">
                                    <mat-label>Gestores</mat-label>
                                    <input
                                        [value]="
                                            miRutaUserFormData.get('managers')?.value | managerPipe
                                        "
                                        matInput
                                        type="text"
                                        placeholder="Gestores"
                                        readonly
                                    />
                                    <button
                                        matTooltip="Añadir gestor"
                                        matSuffix
                                        mat-icon-button
                                        aria-label="Open manager selection"
                                        (click)="addManagers()"
                                    >
                                        <mat-icon color="primary">edit</mat-icon>
                                    </button>
                                </mat-form-field>
                            </div>

                            <div class="row-content">
                                <mat-form-field appearance="fill">
                                    <mat-label>Descripción</mat-label>
                                    <textarea
                                        matInput
                                        formControlName="descripcion"
                                        placeholder="Descripción..."
                                        cdkTextareaAutosize
                                        #autosize="cdkTextareaAutosize"
                                        cdkAutosizeMinRows="2"
                                    >
                                    </textarea>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ngx-spinner
        [bdColor]="miRutaUser ? 'rgba(0, 0, 0, 0.2)' : 'rgba(255, 255, 255, 1)'"
        size="large"
        color="#368DCE"
        type="ball-scale"
        [fullScreen]="false"
        name="miRutaUserSpinner"
    >
    </ngx-spinner>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="miRutaUser">Cancelar</button>
    <button mat-button cdkFocusInitial matTooltip="Guardar cambios" (click)="saveChanges()">
        <mat-icon color="primary">check</mat-icon> {{ miRutaUserId ? 'Actualizar' : 'Añadir' }}
    </button>
</mat-dialog-actions>

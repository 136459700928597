/**
 * Created by Ing. Luis Alejandro Reyes Morales on 18/04/2024.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { faBuilding, faFilePdf, faKey, faInbox } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { ApiService } from '../../../services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { NgxSpinnerService } from 'ngx-spinner';

import * as moment from 'moment';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PlanningDetail, getPlanningDetailFormControls } from '../../../interfaces/planning-detail';
import { Planning } from '../../../interfaces/planning';

@Component({
    selector: 'app-planning-detail',
    templateUrl: './planning-detail.component.html',
    styleUrls: ['./planning-detail.component.scss']
})
export class PlanningDetailComponent implements OnInit {
    plannings: string[] = [];

    faFilePdf = faFilePdf;
    faInbox = faInbox;
    faBuilding = faBuilding;
    faKey = faKey;
    loading: boolean = false;
    planningDetailId: string = '';
    planningDetail?: PlanningDetail;

    planningDetailFormData: FormGroup = getPlanningDetailFormControls();

    planningDetailSubcription$?: Subscription;

    /**
     * Represents the PlanningDetailComponent class.
     * This component is responsible for handling the planningDetail data.
     */
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _apiService: ApiService,
        private _utilsService: UtilsService,
        private spinner: NgxSpinnerService,
    ) {
        this.planningDetailId = data.planningDetailId;
    }

    /**
     * Initializes the component after Angular has initialized all data-bound properties.
     * If a `planningDetailId` is provided, it retrieves the corresponding planningDetail document from the API
     * and populates the component with the retrieved data.
     * If the document is not found, an error message is displayed.
     * Finally, it sets up the form controls with the retrieved planningDetail data.
     * @returns A promise that resolves when the initialization is complete.
     */
    async ngOnInit(): Promise<void> {
        await this.addTablesValues();
        if (this.planningDetailId) {
            this.showLoading(true);
            this.planningDetailSubcription$ = this._apiService
                .getDocument('planning-details', this.planningDetailId)
                .subscribe(async (doc: any) => {
                    await this.addData(doc);
                });
        }
        else await this.addFromControls();
    }

    /**
     * Adds data to the planning detail.
     * 
     * @param doc - The document containing the data.
     * @returns Promise<void> - A promise that resolves when the data is added.
     */
    async addData(doc: any) { 
        if (!doc) {
            this._utilsService.openSnackBar(
                'Error obteniendo datos de planningDetail',
                'error'
            );
            this.showLoading(false);
            return;             
        }
        const planningDetail = doc as PlanningDetail;
        planningDetail.id = parseInt(this.planningDetailId);
        this.planningDetail = planningDetail;
        const planningDetailFormData = getPlanningDetailFormControls();
        const keys = Object.keys(planningDetail);
        for (let key of keys) {
            let value: any = planningDetail[key as keyof PlanningDetail];
            if (value) {
                if (this._utilsService.isValidDate(value)) value = value;
                planningDetailFormData.controls[key].setValue(value);
            }
        }
        this.planningDetailFormData = planningDetailFormData;
        await this.addFromControls();
        this.showLoading(false);
    }

    /**
     * Retrieves the planning values from the API and assigns them to the 'plannings' property.
     * @returns A promise that resolves when the values are successfully retrieved and assigned.
     */
    async addTablesValues(): Promise<void> {
        const plannings = await this._apiService.getPlannings();
        this.plannings = plannings.map((planning: Planning) => `${planning.codigo_planning}`);
    }

    /**
     * Adds event listeners to the form controls and updates the value of 'planning_details' based on the changes in 'codigo_planning_details' and 'explanation'.
     * 
     * @returns A Promise that resolves when the event listeners are added.
     */
    async addFromControls(): Promise<void> {
        this.planningDetailFormData.controls['planning_details'].disable();

        this.planningDetailFormData.controls['numeric_code'].valueChanges.subscribe(async (_: string) => {
            this.buildPlanningDetail();
        });  
        this.planningDetailFormData.controls['codigo_planning_details'].valueChanges.subscribe(async (_: string) => {
            this.buildPlanningDetail();
        });   
        this.planningDetailFormData.controls['explanation'].valueChanges.subscribe(async (_: string) => {
            this.buildPlanningDetail();
        });
    }

    /**
     * Builds the planning detail.
     */
    buildPlanningDetail(): void { 
        let codigo_planning_details = this.planningDetailFormData.controls['codigo_planning_details'].value;
        let explanation = this.planningDetailFormData.controls['explanation'].value;
        let numeric_code = this.planningDetailFormData.controls['numeric_code'].value;
        if(this._utilsService.isFieldNotValid(codigo_planning_details)) codigo_planning_details = '';
        if(this._utilsService.isFieldNotValid(explanation)) explanation = '';
        if(this._utilsService.isFieldNotValid(numeric_code)) numeric_code = '';
        this.planningDetailFormData.controls['planning_details'].setValue(`${numeric_code} - ${codigo_planning_details} - ${explanation}`);
    }

    /**
    * Lifecycle hook that is called when the component is destroyed.
    * It is used to perform any necessary cleanup before the component is removed from the DOM.
    */
    ngOnDestroy(): void {
        this.planningDetailSubcription$?.unsubscribe();
    }

    /**
    * Shows or hides the loading spinner based on the provided state.
    * @param state - A boolean value indicating whether to show or hide the loading spinner.
    */
    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this.spinner.show('planningDetailSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this.spinner.hide('planningDetailSpinner');
        }
    }
    
    /**
     * Saves the form data by extracting values from the controls and assigning them to the `planningDetail` object.
     * Converts moment.js objects to Date objects before assigning.
     */
    saveFormData() {
        const keys = Object.keys(this.planningDetailFormData.controls);
        let planningDetail: any = {};
        for (let key of keys) {
            let value = this.planningDetailFormData.controls[key].value; //this.planningDetail[key as keyof PlanningDetail];
            try {
                if (moment.isMoment(value)) {
                    value = value.toDate();
                }
                planningDetail![key as keyof PlanningDetail] = value;
            } catch (error) {}
        }
        planningDetail!['date_time_modified'] = new Date();
        this.planningDetail = planningDetail;
    }

    /**
     * Saves the changes made to the planningDetail.
     * If the planningDetail already exists, it updates the existing planningDetail.
     * If the planningDetail is new, it adds the planningDetail to the database.
     * Displays a success or error message based on the result.
     */
    async saveChanges() {
        if (this.isNotValidPlanningDetail()) {
            this._utilsService.openSnackBar('Por favor, los campos de código planning, código numérico, código detalle y explicación', 'error');
            return;
        }
        this.showLoading(true);
        this.saveFormData();
        if (this.planningDetailId) await this.updatePlanningDetail();
        else await this.addPlanningDetail();
                
        this.showLoading(false);
        this._utilsService.closePlanningDetailDialog(this.planningDetail!);
    }

    /**
     * Adds a planning detail.
     *
     * @returns {Promise<void>} A promise that resolves when the planning detail is added successfully.
     * @throws {Error} If there is an error adding the planning detail.
     */
    async addPlanningDetail(): Promise<void> { 
        try {
            const planningDetailId = await this._apiService.addDocument('planning-details', this.planningDetail);
            if (planningDetailId) {
                this.planningDetail!.id = planningDetailId;
                this._utilsService.openSnackBar('Planificación añadida correctamente');
            } else {
                this._utilsService.openSnackBar('Error añadiendo planificación', 'error');
            }
        } catch (error) {
            this._utilsService.openSnackBar('Error añadiendo planificación', 'error');
        }
    }

    /**
     * Updates the planning detail.
     * 
     * @returns A promise that resolves to void.
     */
    async updatePlanningDetail(): Promise<void> { 
        const planningDetail: boolean = await this._apiService.updateDocument(
            'planning-details',
            this.planningDetailId,
            this.planningDetail
        );
        if (planningDetail) {
            this._utilsService.openSnackBar('Planificación actualizada correctamente');
        } else {
            this._utilsService.openSnackBar('Error actualizando planificación', 'error');
        }
    }

    /**
     * Checks if the planning detail is not valid.
     * 
     * @returns A boolean indicating whether the planning detail is not valid.
     */
    isNotValidPlanningDetail(): boolean { 
        return !this.isValidPlanningDetail();
    }

    /**
     * Checks if the planning detail is valid.
     * 
     * @returns {boolean} True if the planning detail is valid, false otherwise.
     */
    isValidPlanningDetail(): boolean {
        if (this.planningDetailFormData.controls['codigo_planning_details'].value
            && this.planningDetailFormData.controls['numeric_code'].value
            && this.planningDetailFormData.controls['planning_details'].value
            && this.planningDetailFormData.controls['codigo_planning'].value
            && this.planningDetailFormData.controls['explanation'].value) {
            return true;
        }
        return false;
    }
}

import { MyLatLng } from './lat-lng';
export interface Footprint {
    id: number;
    geolocation: MyLatLng;
    date: Date;
}

export function convertFootprintFromServer(data: any): any {
    let geolocation;
    try {
        geolocation = JSON.parse(data['geolocation']) as MyLatLng;
    } catch (err) {
        geolocation = data['geolocation'];
    }
    let footprint = {
        id: data['id'],
        geolocation: geolocation,
        date: new Date(data['date']),
    };
    return footprint;
}

import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '../services/utils.service';

@Pipe({
  name: 'counterStatus'
})
export class CounterStatusPipe implements PipeTransform {

  constructor(private _utilsService: UtilsService) {}

  transform(value: any, ...args: unknown[]): string {
    const counterStatusString = this._utilsService.getCounterStatusFromInt(value);
    return counterStatusString;
  }

}


<mat-dialog-content class=" animate__animated animate__fadeIn animate__slow">
    <div [formGroup]="waterRouteFormData"
    class=" animate__animated animate__fadeIn animate__slow">

        <div>

            <div class="container">

                <div class="row bd-highlight" class="row-content-center">

                    <div>
                        <div style="margin-top: 5px; margin-bottom: 30px; margin-left: 5px;">                        
                            <p style="font-size: large;">Datos de ruta</p>
                        </div> 
                        <div>

                            <div class="row-content">

                                <mat-form-field style="width: 120px;" appearance="fill">
                                    <mat-label>Codigo Emplazamiento</mat-label>
                                    <input formControlName="codigo_ruta" matInput type="text" placeholder="Codigo Emplazamiento">
                                </mat-form-field>
                                <mat-form-field style="width: 120px;" appearance="fill">
                                    <mat-label>Ruta</mat-label>
                                    <input formControlName="ruta" matInput type="text" placeholder="Ruta">
                                </mat-form-field>
                                <mat-form-field style="width: 120px;" appearance="fill">
                                    <mat-label>Portal</mat-label>
                                    <input formControlName="portal" matInput type="text" placeholder="Portal">
                                </mat-form-field>
                                <mat-form-field style="width: 120px;" appearance="fill">
                                    <mat-label>Radio de portal</mat-label>
                                    <input formControlName="radio_portal" matInput type="text" placeholder="Radio de portal">
                                </mat-form-field>

                            </div>
                            
                            <div class="row-content">
                                    
                                <mat-form-field appearance="fill">
                                    <mat-label>Calle</mat-label>
                                    <input formControlName="calle" matInput type="text" placeholder="Calle">
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Barrio</mat-label>
                                    <input formControlName="barrio" matInput type="text" placeholder="Barrio">
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Distrito</mat-label>
                                    <input formControlName="distrito" matInput type="text" placeholder="Distrito">
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Municipio</mat-label>
                                    <input formControlName="municipio" matInput type="text" placeholder="Municipio">
                                </mat-form-field>

                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>

    <ngx-spinner [bdColor] = "(waterRoute)?'rgba(0, 0, 0, 0.2)': 'rgba(255, 255, 255, 1)'" size = "large" color = "#368DCE" 
    type = "ball-scale-multiple" [fullScreen] = "false" name="waterRouteSpinner">
    </ngx-spinner>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="waterRoute">Cancelar</button>
    <button mat-button cdkFocusInitial
    matTooltip="Guardar cambios" 
    (click)="saveChanges()"> 
        <mat-icon color="primary">check</mat-icon> {{ (waterRouteId)?'Actualizar':'Añadir' }}
    </button>
</mat-dialog-actions>

/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import * as moment from 'moment';
import { Moment } from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import {
    Company,
    getCompanyDisplayColumns,
    getCompanyExcelExportColumns,
    getCompanyExcelFieldName,
    getCompanyField,
    getCompanyFieldType,
} from 'src/app/interfaces/company';
import { ApiService } from 'src/app/services/api.service';
import { MessagingService } from 'src/app/services/messaging.service';
import { UtilsService } from 'src/app/services/utils.service';
import { WindowRefService } from 'src/app/services/window-ref.service';
import * as XLSX from 'xlsx';

@Component({
    selector: 'app-companies',
    templateUrl: './companies.component.html',
    styleUrls: ['./companies.component.scss'],
})
export class CompaniesComponent implements OnInit {
    loading: boolean = true;
    companiesInPage: Company[] = [];
    dataSource: MatTableDataSource<Company> = new MatTableDataSource();

    length = 0; //task count in current table
    pageSize = 200; //limit of query
    lastPageIndex = 0;
    pageSizeOptions: number[] = [10, 50, 100, 200, 500];

    displayedColumns: string[] = getCompanyDisplayColumns();
    fixedColumns = [];
    displayedColumnsField: string[] = this.displayedColumns.map((displayedColumn: string) =>
        getCompanyField(displayedColumn)
    );
    clickedRows = new Set<Company>();

    lastSelectedRow: number = -1;

    filteredColumn?: string;
    orderedColumn?: string;

    loadingText = 'Cargando...';

    constructor(
        private _apiService: ApiService,
        private _utilsService: UtilsService,
        private _windowRefService: WindowRefService,
        private _spinner: NgxSpinnerService,
        private _messagingService: MessagingService
    ) {}

    async ngOnInit(): Promise<void> {
        await this.getCompanies();
    }

    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this._spinner.show('innerSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this._spinner.hide('innerSpinner');
        }
    }

    setCompaniesInTable(companies: Company[]) {
        this.companiesInPage = companies;
        // this.dataSource.data = companies.slice(0, this.sliceSize);
        this.dataSource.data = companies;
        // this.dataSource = new TableVirtualScrollDataSource(companies);// infiniteScroll
        // console.log(this.dataSource);
        this.showLoading(false);
    }

    async getCompanies() {
        this.setCompaniesInTable(await this.selectCompanies());
    }

    async selectCompanies() {
        this.showLoading(true);
        let docs = [];
        try {
            docs = await this._apiService.getDocuments<Company>('company', undefined, [
                'codigo_empresa',
                'asc',
            ]);
        } catch (err) {
            console.log('============= err =============');
            console.log(err);
        }
        localStorage.setItem('companys', JSON.stringify(docs));
        this.length = docs.length;
        return docs;
        // undefined,/*where*/undefined, /*order_clause*/undefined, '0', this.pageSize.toString());
    }

    searchValue(event: any) {
        const filterValue: string = event;
        this.dataSource!.filter = filterValue.trim().toLowerCase();
    }

    async deleteCompanies() {
        let errorIds = [];
        if (this.clickedRows.size == 0) {
            this._utilsService.openSnackBar('Debe seleccionar al menos una empresa', 'warning');
            return;
        }
        const result = await this._utilsService.openQuestionDialog(
            'Confirmación',
            '¿Desea eliminar las empresas seleccionadas?'
        );
        if (result) {
            this._spinner.show();
            this.loadingText = `Eliminando empresas ...`;
            let rowIndexes = new Set<number>();
            const oldDataSource = this.dataSource!.data;
            this.clickedRows.forEach(async (row) => {
                console.log(row.id);

                const index = this.dataSource!.data.indexOf(row, 0);
                if (index > -1) {
                    rowIndexes.add(index);
                }
            });
            let companies = [];
            let deleteCount = 0;
            for (let i = 0; i < oldDataSource.length; i++) {
                if (!rowIndexes.has(i)) {
                    companies.push(oldDataSource![i]);
                } else {
                    this.loadingText = `Eliminando empresas ${++deleteCount} de ${rowIndexes.size}`;
                    if (
                        !(await this._apiService.deleteDocument(
                            'company',
                            oldDataSource[i].id!.toString()
                        ))
                    ) {
                        console.log('****************** deleteCompany error *************');
                        errorIds.push(oldDataSource[i].id);
                    } else {
                        console.log('****************** deleteCompany success *************');
                    }
                }
            }
            if (errorIds.length > 0) {
                this._utilsService.openSnackBar(`Hubo errores eliminando empresas`, 'error');
            } else {
                this._utilsService.openSnackBar(`Empresas eliminadas correctamente`);
            }
            this.length -= rowIndexes.size;
            this.setCompaniesInTable(companies);
            this.clickedRows.clear();
            this._spinner.hide();
        }
    }

    async filterBy(column: string) {
        this.filteredColumn = getCompanyField(column);
        if (
            getCompanyFieldType(this.filteredColumn) == 'Date' ||
            getCompanyFieldType(this.filteredColumn) == 'Timestamp'
        ) {
            const dates = await this._utilsService.openDateRangeSelectorDialog(
                'Seleccione rango de fechas'
            );
            this.onDateSelected(dates);
        } else {
            const options: any[] = this.dataSource.data.map(
                (company: Company) => company[this.filteredColumn as keyof Company]
            );
            const result = await this._utilsService.openSelectorDialog(
                'Seleccione opción',
                options
            );
            if (result) {
                 
                let docs = [];
                try {
                    docs = await this._apiService.getDocuments<Company>('company', [
                        [this.filteredColumn, '==', result],
                    ]);
                } catch (err) {
                    console.log('============= err =============');
                    console.log(err);
                }
                this.length = docs.length;
                this.setCompaniesInTable(docs);
            }
        }
    }

    async onDateSelected(dateRange: Date[]) {
        if (dateRange) {
            const startDate: Moment = moment(dateRange[0]);
            const startDateString = startDate.format('YYYY-MM-DD HH:mm:ss');
            let endDate: Moment = moment(dateRange[1]);
            if (!endDate) {
                endDate = startDate;
            }
            const endDateString = endDate.add(1, 'days').format('YYYY-MM-DD HH:mm:ss'); //plus one to filter range
            const values = [startDateString, endDateString];
            let docs = [];
            try {
                docs = await this._apiService.getDocuments<Company>('company', [
                    [this.filteredColumn!, '>=', startDate.toDate()],
                    [this.filteredColumn!, '<', endDate.toDate()],
                ]);
            } catch (err) {
                console.log('============= err =============');
                console.log(err);
            }
            this.length = docs.length;
            this.setCompaniesInTable(docs);
        } else {
            this._utilsService.openSnackBar('Rango fechas invalido', 'error');
        }
    }

    async orderBy(event: any) {
        const column = event.column;
        let orderType = event.orderType;
        if (orderType.toLowerCase().includes('asc')) {
            orderType = 'asc';
        } else {
            orderType = 'desc';
        }
        const orderedColumn = getCompanyField(column);

        this.showLoading(true);
        const companies = await this._apiService.getDocuments<Company>('company', undefined, [
            orderedColumn,
            orderType,
        ]);
        this.setCompaniesInTable(companies);
    }

    async addNewRow(event: any) {
        console.log('**************** addNewRow ***************');
        const company = await this._utilsService.openCompanyDialog('');
        console.log(company);
        if (company) {
            this.dataSource.data.push(company);
            const companies = this.dataSource.data;
            this.dataSource.data = [];
            this.dataSource.data = companies;
        }
    }

    async doubleClickedRow(row: any) {
        console.log('**************** doubleClickedRow ***************');
        console.log(row.id);
        const company = await this._utilsService.openCompanyDialog(row.id);
        if (company) {
            this.dataSource.data[row.rowIndex] = company;
            const companies = this.dataSource.data;
            this.dataSource.data = [];
            this.dataSource.data = companies;
        }
    }

    clickedRow(receivedEvent: any) {
        const row = receivedEvent.row;
        const event = receivedEvent.event;
        const rowIndex = receivedEvent.rowIndex;

        const previousRow = this.lastSelectedRow;
        this.lastSelectedRow = rowIndex;

        if (event.button === 0) {
            if (!event.ctrlKey && !event.shiftKey) {
                this.clickedRows.clear();
                this.toggleRow(row);
                // console.log('**************** !event.ctrlKey && !event.shiftKey ***************');
            } else if (event.ctrlKey) {
                this.toggleRow(row);
                // console.log('**************** event.ctrlKey ***************');
            }
            if (event.shiftKey) {
                this.selectRowsBetweenIndexes(previousRow, rowIndex);
                console.log('**************** event.shiftKey ***************');
            }
        }
        if (this._windowRefService.nativeWindow.getSelection) {
            //remove selection in table with shift
            if (this._windowRefService.nativeWindow.getSelection().empty) {
                // Chrome
                this._windowRefService.nativeWindow.getSelection().empty();
            } else if (this._windowRefService.nativeWindow.getSelection().removeAllRanges) {
                // Firefox
                this._windowRefService.nativeWindow.getSelection().removeAllRanges();
            }
        }
    }

    selectRowsBetweenIndexes(lastSelectedRow: number, rowIndex: number) {
        let start, end;
        if (rowIndex > lastSelectedRow) {
            start = lastSelectedRow;
            end = rowIndex;
        } else {
            end = lastSelectedRow;
            start = rowIndex;
        }
        for (let i = start; i <= end; i++) {
            this.clickedRows.add(this.dataSource!.data[i]);
        }
    }

    toggleRow(row: any) {
        if (this.clickedRows.has(row)) {
            this.clickedRows.delete(row);
        } else {
            this.clickedRows.add(row);
        }
    }

    exportCompaniesInTable() {
        this.exportExcel(this.dataSource!.data);
    }

    exportExcel(waterTasks: any) {
        let excelFormatTasks = [];
        for (let waterTask of waterTasks) {
            let task: any = {};
            const columns = getCompanyExcelExportColumns();
            columns.forEach((column) => {
                task[`${column}`] = this._utilsService.tableDataPipe(
                    waterTask[getCompanyExcelFieldName(column)]
                );
            });
            excelFormatTasks.push(task);
        }
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelFormatTasks);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        /* save to file */
        XLSX.writeFile(wb, 'Empresas_Exportados.xlsx');
    }
}

import { FormControl, FormGroup } from '@angular/forms';

export interface PlanningDetail {
    id: number;
    numeric_code: string;
    codigo_planning: string;
    codigo_planning_details: string;
    explanation: string;
    detail1: string;
    detail2: string;
    detail3: string;
    detail4: string;
    detail5: string;
    detail6: string;
    planning_details: string;
    date_time_modified: Date;
}

export function getPlanningDetailDisplayColumns(): string[] {
    const displayedColumns: string[] = ['Código planificación', 'Código numérico', 'Código detalle', 'Texto completo', 'Fecha de Modificación'];
    return displayedColumns;
}

export function getPlanningDetailField(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'Código numérico':
            value = 'numeric_code';
            break;
        case 'Código planificación':
            value = 'codigo_planning';
            break;
        case 'Código detalle':
            value = 'codigo_planning_details';
            break;
        case 'Explicación':
            value = 'explanation';
            break;
        case 'DETALLE1':
            value = 'detail1';
            break;
        case 'DETALLE2':
            value = 'detail2';
            break;
        case 'DETALLE3':
            value = 'detail3';
            break;
        case 'DETALLE4':
            value = 'detail4';
            break;
        case 'DETALLE5':
            value = 'detail5';
            break;
        case 'DETALLE6':
            value = 'detail6';
            break;
        case 'Texto completo':
            value = 'planning_details';
            break;
        case 'Fecha de Modificación':
            value = 'date_time_modified';
            break;
    }
    return value;
}

export function getPlanningDetailFieldName(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'numeric_code':
            value = 'Código numérico';
            break;
        case 'codigo_planning':
            value = 'Código planificación';
            break;
        case 'codigo_planning_details':
            value = 'Código detalle';
            break;
        case 'explanation':
            value = 'Explicación';
            break;
        case 'detail1':
            value = 'DETALLE1';
            break;
        case 'detail2':
            value = 'DETALLE2';
            break;
        case 'detail3':
            value = 'DETALLE3';
            break;
        case 'detail4':
            value = 'DETALLE4';
            break;
        case 'detail5':
            value = 'DETALLE5';
            break;
        case 'detail6':
            value = 'DETALLE6';
            break;
        case 'planning_details':
            value = 'Texto completo';
            break;
        case 'date_time_modified':
            value = 'Fecha de Modificación';
            break;
    }
    return value;
}

export function getPlanningDetailFormControls(): FormGroup {
    const planningFormData: FormGroup = new FormGroup({
        numeric_code: new FormControl(),
        codigo_planning: new FormControl(),
        codigo_planning_details: new FormControl(),
        explanation: new FormControl(),
        detail1: new FormControl(),
        detail2: new FormControl(),
        detail3: new FormControl(),
        detail4: new FormControl(),
        detail5: new FormControl(),
        detail6: new FormControl(),
        planning_details: new FormControl(),
        date_time_modified: new FormControl(),
        id: new FormControl(),
    });
    return planningFormData;
}

export function getPlanningDetailFieldType(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'numeric_code':
            value = 'string';
            break;
        case 'codigo_planning':
            value = 'string';
            break;
        case 'planning_details':
            value = 'string';
            break;
        case 'codigo_planning_details':
            value = 'string';
            break;
        case 'explanation':
            value = 'string';
            break;
        case 'detail1':
            value = 'string';
            break;
        case 'detail2':
            value = 'string';
            break;
        case 'detail3':
            value = 'string';
            break;
        case 'detail4':
            value = 'string';
            break;
        case 'detail5':
            value = 'string';
            break;
        case 'detail6':
            value = 'string';
            break;
        case 'date_time_modified':
            value = 'Date';
            break;
        case 'id':
            value = 'number';
            break;
    }
    return value;
}

export function getPlanningDetailExcelFieldName(excel_field: string): string {
    let value: string = '';
    switch (excel_field.trim().toLowerCase()) {
        case 'código numérico':
            value = 'numeric_code';
            break;
        case 'código':
            value = 'codigo_planning_details';
            break;
        case 'código detalle':
            value = 'codigo_planning_details';
            break;
        case 'código planificación':
            value = 'codigo_planning';
            break;
        case 'codigo planificacion':
            value = 'codigo_planning';
            break;
        case 'codigo_planning':
            value = 'codigo_planning';
            break;
        case 'explicación':
            value = 'explanation';
            break;
        case 'explicacion':
            value = 'explanation';
            break;
        case 'detalle1':
            value = 'detail1';
            break;
        case 'detalle2':
            value = 'detail2';
            break;
        case 'detalle3':
            value = 'detail3';
            break;
        case 'detalle4':
            value = 'detail4';
            break;
        case 'detalle5':
            value = 'detail5';
            break;
        case 'detalle6':
            value = 'detail6';
            break;
        case 'texto completo':
            value = 'planning_details';
            break;
        case 'date_time_modified':
            value = 'date_time_modified';
            break;
        case 'fecha de modificación':
            value = 'date_time_modified';
            break;
        case 'fecha de modificacion':
            value = 'date_time_modified';
            break;
    }
    return value;
}

export function getPlanningDetailExcelExportColumns(): string[] {
    const columns = ['Código planificación', 'Código numérico', 'Código detalle', 'Texto completo', 'Fecha de Modificación'];
    return columns;
}

/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faBuilding, faFilePdf, faKey, faInbox } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { ApiService } from '../../../services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessagingService } from 'src/app/services/messaging.service';

import * as moment from 'moment';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Mark, getMarkFormControls } from 'src/app/interfaces/mark';

@Component({
    selector: 'app-mark',
    templateUrl: './mark.component.html',
    styleUrls: ['./mark.component.scss'],
})
export class MarkComponent implements OnInit {
    faFilePdf = faFilePdf;
    faInbox = faInbox;
    faBuilding = faBuilding;
    faKey = faKey;
    loading: boolean = false;
    markId: string = '';
    mark?: Mark;

    markFormData: FormGroup = getMarkFormControls();

    markSubcription$?: Subscription;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _apiService: ApiService,
        private _utilsService: UtilsService,
        private spinner: NgxSpinnerService,
        private _messagingService: MessagingService
    ) {
        this.markId = data.markId;
        // this.activatedRoute.params.subscribe((params)=>{
        //   this.markId = params['id'];
        // });
    }

    async ngOnInit(): Promise<void> {
        if (this.markId) {
            this.showLoading(true);

            this.markSubcription$ = this._apiService
                .getDocument('mark', this.markId)
                .subscribe(async (doc: any) => {
                    if (!doc) {
                        this._utilsService.openSnackBar('Error obteniendo datos de mark', 'error');
                        this.showLoading(false);
                        return;
                    }
                    const mark = doc as Mark;
                    mark.id = parseInt(this.markId);

                    this.mark = mark;

                    const markFormData = getMarkFormControls();
                    const keys = Object.keys(mark);
                    for (let key of keys) {
                        let value: any = mark[key as keyof Mark];
                        if (value) {
                            if (this._utilsService.isValidDate(value)) {
                                value = value;
                            }
                            markFormData.controls[key].setValue(value);
                        }
                    }
                    this.markFormData = markFormData;

                    this.showLoading(false);
                });
        }
    }

    ngOnDestroy(): void {
        this.markSubcription$?.unsubscribe();
    }

    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this.spinner.show('markSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this.spinner.hide('markSpinner');
        }
    }

    saveFormData() {
        const keys = Object.keys(this.markFormData.controls);
        let mark: any = {};
        for (let key of keys) {
            let value = this.markFormData.controls[key].value; //this.mark[key as keyof Mark];
            try {
                if (moment.isMoment(value)) {
                    value = value.toDate();
                }
                mark![key as keyof Mark] = value;
            } catch (error) {}
        }
        mark!['date_time_modified'] = new Date();
        this.mark = mark;
        // console.log(this.mark);
    }

    async saveChanges() {
        this.showLoading(true);
        this.saveFormData();
        if (this.markId) {
            const result: boolean = await this._apiService.updateDocument(
                'mark',
                this.markId,
                this.mark
            );
            if (result) {
                this._utilsService.openSnackBar('Marca actualizada correctamente');
            } else {
                this._utilsService.openSnackBar('Error actualizando marca', 'error');
            }
        } else {
            try {
                const markId = await this._apiService.addDocument('mark', this.mark);
                if (markId) {
                    this.mark!.id = markId;
                    this._utilsService.openSnackBar('Marca añadido correctamente');
                } else {
                    this._utilsService.openSnackBar('Error añadiendo marca', 'error');
                }
            } catch (error) {
                this._utilsService.openSnackBar('Error añadiendo marca', 'error');
            }
        }
        this.showLoading(false);
        this._utilsService.closeMarkDialog(this.mark!);
    }
}

<mat-dialog-content class="animate__animated animate__fadeIn">
    <div [formGroup]="classCounterFormData" class="animate__animated animate__fadeIn">
        <div>
            <div class="container">
                <div class="row bd-highlight" class="row-content-center">
                    <div>
                        <div style="margin-top: 5px; margin-bottom: 30px; margin-left: 5px">
                            <p style="font-size: large">Datos de clase</p>
                        </div>
                        <div>
                            <div class="row-content">
                                <mat-form-field style="width: 100px" appearance="fill">
                                    <mat-label>Código clase</mat-label>
                                    <input
                                        formControlName="codigo_clase"
                                        matInput
                                        type="text"
                                        placeholder="Código clase"
                                    />
                                </mat-form-field>
                                <mat-form-field style="flex: 2" appearance="fill">
                                    <mat-label>Clase</mat-label>
                                    <input
                                        formControlName="clase"
                                        matInput
                                        type="text"
                                        placeholder="Clase"
                                    />
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ngx-spinner
        [bdColor]="classCounter ? 'rgba(0, 0, 0, 0.2)' : 'rgba(255, 255, 255, 1)'"
        size="large"
        color="#368DCE"
        type="ball-scale-multiple"
        [fullScreen]="false"
        name="classCounterSpinner"
    >
    </ngx-spinner>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="classCounter">Cancelar</button>
    <button mat-button cdkFocusInitial matTooltip="Guardar cambios" (click)="saveChanges()">
        <mat-icon color="primary">check</mat-icon> {{ classCounterId ? 'Actualizar' : 'Añadir' }}
    </button>
</mat-dialog-actions>

/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faInbox } from '@fortawesome/free-solid-svg-icons';
import { MiRutaFilter } from 'src/app/interfaces/mi-ruta-filter';
import { UtilsService } from 'src/app/services/utils.service';
import { MySqlService } from '../../../services/mysql.service';
import { getFieldType } from '../../../interfaces/water-task';
import { DialogData } from '../../../interfaces/dialog-data';
import { CheckBoxModel } from '../../../interfaces/check-box-model';

@Component({
    selector: 'app-user-filter',
    templateUrl: './user-filter.component.html',
    styleUrls: ['./user-filter.component.scss'],
})
export class UserFilterComponent implements OnInit {
    checkBoxes: CheckBoxModel[] = [];
    myControl = new FormControl();
    options: string[] = [];
    filteredOptions!: string[];

    column?: string = '';
    column_name?: string = '';
    table_name: string;
    filter?: MiRutaFilter;
    not_empty: boolean = false;

    loading = false;
    faInbox = faInbox;

    timerInputChanged: any;

    filterLimit = 15;

    offset = 0;
    optionsSelected: any[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        public dialogRef: MatDialogRef<UserFilterComponent>,
        private _mySqlService: MySqlService, //MysqlService BigQueryService
        private _utilsService: UtilsService
    ) {
        this.column = data.column!;
        this.column_name = data.column_name!;
        this.table_name = 'water-task';
        this.filter = data.filter!;

        this._utilsService.setRightFilter(this.table_name, this.filter);
    }

    updateFilter(event: MiRutaFilter) {
        this.filter = event;
        this._utilsService.setRightFilter(this.table_name, this.filter);
    }

    acceptDialog(): void {
        this.checkBoxes.forEach((checkBox: CheckBoxModel) => {
            if (checkBox.checked) {
                if (!this.optionsSelected.includes(checkBox.name))
                    this.optionsSelected.push(checkBox.name);
            }
        });
        if (this.optionsSelected.length == 0) {
            this._utilsService.openSnackBar('Debe seleccionar alguna opción', 'warning');
            return;
        }
        let result = {
            column: this.column,
            not_empty: this.not_empty,
            data: this.optionsSelected,
        };
        this.dialogRef.close(result);
    }
    cancelDialog(): void {
        this.dialogRef.close();
    }

    async inputChanged(value: string) {
        this.loading = true;
        this.options = [];
        this.filteredOptions = [];
        let whereJsonArray: any[] = [];
        let whereJson: any = {};
        whereJson['field'] = this.column;
        const field_type = getFieldType(this.column!);
        if (field_type == 'number') whereJson['value'] = value;
        else whereJson['value'] = `%${value}%`;
        whereJson['type'] = 'AND';
        whereJsonArray.push({ ...whereJson });

        const query_result = await this.getRightFieldValues(JSON.stringify(whereJsonArray));
        this.options = query_result.map((data: any) => data[`${this.column}`]);
        console.log(this.options);
        this.loading = false;
    }
    async getRightFieldValues(where_clause: string) {
        let query_result;
        let orderJson: any = {};
        orderJson['field'] = `${this.column}`;
        orderJson['type'] = 'ASC';

        let selectJson: any = {};
        selectJson['fields'] = [`${this.column}`];
        selectJson['distincts'] = [`${this.column}`];
        const jsonString = JSON.stringify(selectJson);

        query_result = await this._mySqlService.getTasksFieldValues(
            jsonString,
            where_clause,
            JSON.stringify(orderJson),
            undefined,
            this.filterLimit.toString()
        );
        return query_result;
    }

    async getLastRightFieldValues() {
        let query_result;

        query_result = await this._mySqlService.getLastTasksFieldValues(
            this.offset.toString()
        );

        return query_result;
    }

    async getOptions() {
        this.loading = true;
        let whereJson: any = {};

        whereJson['field'] = this.column;
        whereJson['nullity'] = 'IS NOT NULL';
        whereJson['type'] = 'AND';

        let where_clause = '';
        if (this.filter && this.filter.fields) {
            let whereJsonArray: any = [];
            whereJsonArray = JSON.parse(this._utilsService.getWhereClauseFromFilter(this.filter));
            whereJsonArray.push(whereJson);
            where_clause += JSON.stringify(whereJsonArray);
        }
        const query_result = await this.getRightFieldValues(where_clause);
        this.options = query_result.map((data: any) => data[`${this.column}`]);
        this.loading = false;
    }
    async ngOnInit(): Promise<void> {
        await this.getOptions();
        this.filteredOptions = this.options.filter((data) =>
            this._utilsService.isFieldValid(data)
        );
        this.fillCheckBoxes();
        this.myControl.valueChanges.subscribe(async (value: any) => {
            clearTimeout(this.timerInputChanged);
            if(this._utilsService.validateStringForJsonValue(this.myControl)) return;
            this.timerInputChanged = setTimeout(async () => {
                await this.inputChanged(value);
                this.filteredOptions = this._filter(value);
                this.fillCheckBoxes();
            }, 1000);
        });
    }

    private _filter(value: string): string[] {
        const filterValue = value.toString().toLowerCase();
        const filterOptions = this.options.filter((option) =>
            option.toString().toLowerCase().includes(filterValue)
        );
        this.checkBoxes = [];
        this.not_empty = false;

        return filterOptions;
    }
    fillCheckBoxes() {
        let i = 0;
        this.filteredOptions.forEach((option: string) => {
            let displayName = option;
            if (this._utilsService.specialFields.includes(this.column_name!)) {
                displayName = this._utilsService.specialFieldPipe(displayName, this.column_name);
            }
            const checkBox: CheckBoxModel = {
                name: option,
                displayName: displayName,
                checked: this.not_empty,
                field_name: this.column!,
                transformation: false,
                position: i,
            };
            i++;
            this.checkBoxes.push(checkBox);
        });
    }

    updateCheckBox(checkBox: CheckBoxModel, checked: boolean) {
        this.checkBoxes[checkBox.position].checked = checked;
        let name = this.checkBoxes[checkBox.position].name;
        if (checked) {
            if (!this.optionsSelected.includes(name)) {
                this.optionsSelected.push(name);
            }
        } else {
            const index = this.optionsSelected.indexOf(name);
            if (index >= 0) {
                this.optionsSelected.splice(index, 1);
            }
        }
    }
    checkedAll(checked: boolean) {
        this.not_empty = checked;
        for (let i = 0; i < this.checkBoxes.length; i++) {
            this.checkBoxes[i].checked = checked;
        }
    }

    async onScroll() {
        this.offset += this.filterLimit;
        const query_result = await this.getLastRightFieldValues();
        let addOptions = query_result.map((data: any) => data[`${this.column}`]);
        console.log(addOptions);
        for (let i = 0; i < addOptions.length; i++) {
            this.options.push(addOptions[i]);
        }
        this.filteredOptions = this.options.filter((data) =>
            this._utilsService.isFieldValid(data)
        );
        this.checkBoxes = [];
        this.fillCheckBoxes();
    }
}

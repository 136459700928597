import { FormControl, FormGroup } from '@angular/forms';

export interface Emplacement {
    emplazamiento: string;
    codigo_emplazamiento: string;
    date_time_modified: Date;
    id: number;
    resto: string;
}

export function getEmplacementDisplayColumns(): string[] {
    const displayedColumns: string[] = [
        'Código emplazamiento',
        'Emplazamiento',
        'Resto',
        'Fecha de Modificación',
    ];
    return displayedColumns;
}

export function getEmplacementField(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'Código emplazamiento':
            value = 'codigo_emplazamiento';
            break;
        case 'Emplazamiento':
            value = 'emplazamiento';
            break;
        case 'Resto':
            value = 'resto';
            break;
        case 'Fecha de Modificación':
            value = 'date_time_modified';
            break;
    }
    return value;
}

export function getEmplacementFieldName(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'codigo_emplazamiento':
            value = 'Código emplazamiento';
            break;
        case 'emplazamiento':
            value = 'Emplazamiento';
            break;
        case 'date_time_modified':
            value = 'Fecha de Modificación';
            break;
        case 'resto':
            value = 'Resto';
            break;
    }
    return value;
}

export function getEmplacementFormControls(): FormGroup {
    const emplacementFormData: FormGroup = new FormGroup({
        emplazamiento: new FormControl(),
        codigo_emplazamiento: new FormControl(),
        date_time_modified: new FormControl(),
        id: new FormControl(),
        resto: new FormControl(),
    });
    return emplacementFormData;
}

export function getEmplacementFieldType(field_name: string): string {
    //in bigquery
    let value: string = '';
    switch (field_name) {
        case 'codigo_emplazamiento':
            value = 'string';
            break;
        case 'emplazamiento':
            value = 'string';
            break;
        case 'date_time_modified':
            value = 'Date';
            break;
        case 'id':
            value = 'number';
            break;
        case 'resto':
            value = 'string';
            break;
    }
    return value;
}

export function getEmplacementExcelFieldName(excel_field: string): string {
    let value: string = '';
    switch (excel_field.trim().toLowerCase()) {
        case 'emplazamiento':
            value = 'emplazamiento';
            break;
        case 'código emplazamiento':
            value = 'codigo_emplazamiento';
            break;
        case 'codigo emplazamiento':
            value = 'codigo_emplazamiento';
            break;
        case 'resto':
            value = 'resto';
            break;
        case 'fecha de modificación':
            value = 'date_time_modified';
            break;
        case 'fecha de modificacion':
            value = 'date_time_modified';
            break;
        case 'codigo_emplazamiento':
            value = 'codigo_emplazamiento';
            break;
        case 'date_time_modified':
            value = 'date_time_modified';
            break;
        case 'resto':
            value = 'resto';
            break;
    }
    return value;
}

export function getEmplacementExcelExportColumns(): string[] {
    const columns = ['Código emplazamiento', 'Emplazamiento', 'Resto', 'Fecha de Modificación'];
    return columns;
}

/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faBuilding, faFilePdf, faKey, faInbox } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { ApiService } from '../../../services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { NgxSpinnerService } from 'ngx-spinner';

import * as moment from 'moment';
import { getRadiusModuleFormControls, RadiusModule } from 'src/app/interfaces/radius-module';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-radius-module',
    templateUrl: './radius-module.component.html',
    styleUrls: ['./radius-module.component.scss'],
})
export class RadiusModuleComponent implements OnInit {
    faFilePdf = faFilePdf;
    faInbox = faInbox;
    faBuilding = faBuilding;
    faKey = faKey;
    loading: boolean = false;
    radiusModuleId: string = '';
    radiusModule?: RadiusModule;

    radiusModuleFormData: FormGroup = getRadiusModuleFormControls();

    radiusModuleSubcription$?: Subscription;

    results: string[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _apiService: ApiService,
        private _utilsService: UtilsService,
        private spinner: NgxSpinnerService
    ) {
        this.radiusModuleId = data.radiusModuleId;
    }

    async ngOnInit(): Promise<void> {
        if (this.radiusModuleId) {
            this.showLoading(true);

            this.radiusModuleSubcription$ = this._apiService
                .getDocument('radius-module', this.radiusModuleId)
                .subscribe(async (doc: any) => {
                    this.parseDoc(doc);
                    this.showLoading(false);
                });
        }
    }

    ngOnDestroy(): void {
        this.radiusModuleSubcription$?.unsubscribe();
    }

    parseDoc(doc: any){
      if (!doc) {
          this._utilsService.openSnackBar(
              'Error obteniendo datos de módulo de radio',
              'error'
          );
          this.showLoading(false);
          return;
      }
      const radiusModule = doc as RadiusModule;
      radiusModule.id =  parseInt(this.radiusModuleId);
      this.radiusModule = radiusModule;

      this.fillFormData(radiusModule);
    }

    fillFormData(radiusModule: RadiusModule){
        const radiusModuleFormData = getRadiusModuleFormControls();
        const keys = Object.keys(radiusModule);
        for (let key of keys) {
            let value: any = radiusModule[key as keyof RadiusModule];
            if (value) {
                if (this._utilsService.isValidDate(value)) {
                    value = value;
                }
                radiusModuleFormData.controls[key].setValue(value);
            }
        }
        this.radiusModuleFormData = radiusModuleFormData;
    }

    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this.spinner.show('radiusModuleSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this.spinner.hide('radiusModuleSpinner');
        }
    }

    saveFormData() {
        const keys = Object.keys(this.radiusModuleFormData.controls);
        let radiusModule: any = {};
        for (let key of keys) {
            let value = this.radiusModuleFormData.controls[key].value; //this.radiusModule[key as keyof RadiusModule];
            try {
                if (moment.isMoment(value)) {
                    value = value.toDate();
                }
                radiusModule![key as keyof RadiusModule] = value;
            } catch (error) {}
        }
        radiusModule!['date_time_modified'] = new Date();
        this.radiusModule = radiusModule;
        // console.log(this.radiusModule);
    }

    async saveChanges() {
        this.showLoading(true);
        this.saveFormData();
        if (this.radiusModuleId) {
            console.log('this.radiusModule ********');
            console.log(this.radiusModule);
            const result: boolean = await this._apiService.updateDocument(
                'radius-module',
                this.radiusModuleId,
                this.radiusModule
            );
            if (result) {
                this._utilsService.openSnackBar('Módulo de radio actualizada correctamente');
            } else {
                this._utilsService.openSnackBar('Error actualizando módulo de radio', 'error');
            }
        } else {
            try {
                const companyId = localStorage.getItem('company');
                const managerId = localStorage.getItem('manager');

                this.radiusModule!.company = parseInt(companyId!);
                this.radiusModule!.manager = parseInt(managerId!);

                const radiusModuleId = await this._apiService.addDocument(
                    'radius-module',
                    this.radiusModule
                );
                if (radiusModuleId) {
                    this.radiusModule!.id = radiusModuleId;
                    this.radiusModuleId = radiusModuleId;
                    this._utilsService.openSnackBar('Ruta añadido correctamente');
                } else {
                    this._utilsService.openSnackBar('Error añadiendo módulo de radio', 'error');
                }
            } catch (error) {
                this._utilsService.openSnackBar('Error añadiendo módulo de radio', 'error');
            }
        }
        this.showLoading(false);
        this._utilsService.closeRadiusModuleDialog(this.radiusModule!);
    }
}

/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { MatPaginatorIntl } from '@angular/material/paginator';

export function CustomPaginator() {
    const customPaginatorIntl = new MatPaginatorIntl();
    customPaginatorIntl.itemsPerPageLabel = 'Filas por página:';
    customPaginatorIntl.lastPageLabel = 'Última página';
    customPaginatorIntl.firstPageLabel = 'Primera página';
    customPaginatorIntl.nextPageLabel = 'Página siguiente';
    customPaginatorIntl.previousPageLabel = 'Página anterior';
    return customPaginatorIntl;
}

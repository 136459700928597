import { Manager } from './manager';
import { Company } from './company';
import { FormGroup, FormControl } from '@angular/forms';

export interface ManagerMessage {    
    id: number;
    message: string;
    default: boolean;
    itac_message: boolean;
    manager: Manager;
    company: Company;
    date_time_modified: Date;
}

export function getManagerMessageFormControls(): FormGroup {
    const managerMessageFormData: FormGroup = new FormGroup({
        id: new FormControl(),
        message: new FormControl(),
        default: new FormControl(),
        itac_message: new FormControl(),
        // manager: new FormControl(),
        // company: new FormControl(),
        date_time_modified: new FormControl(),
    });
    return managerMessageFormData;
}
/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import {
    IntegrationItelazpi,
    getIntegrationItelazpiDisplayColumns,
    getIntegrationItelazpiExcelExportColumns,
    getIntegrationItelazpiExcelFieldName,
    getIntegrationItelazpiField,
    getIntegrationItelazpiFieldType,
} from 'src/app/interfaces/integration-itelazpi';
import { UtilsService } from 'src/app/services/utils.service';
import { WindowRefService } from 'src/app/services/window-ref.service';
import * as XLSX from 'xlsx';
import { faInbox, faBroadcastTower } from '@fortawesome/free-solid-svg-icons';
import { MySqlService } from '../../../services/mysql.service';
import { IpcService } from 'src/app/services/ipc.service';
import { ElectronService } from 'ngx-electron';

@Component({
    selector: 'app-integration-itelazpis',
    templateUrl: './integration-itelazpis.component.html',
    styleUrls: ['./integration-itelazpis.component.scss'],
})
export class IntegrationItelazpisComponent implements OnInit {
    @ViewChild('drawer') homeDrawer?: any;

    faBroadcastTower = faBroadcastTower;
    faInbox = faInbox;
    loading: boolean = true;
    integrationItelazpis: IntegrationItelazpi[] = [];
    dataSource: MatTableDataSource<IntegrationItelazpi> = new MatTableDataSource();

    length = 0; //integrationItelazpi count in current table
    pageSize = 50; //limit of query
    scrollOffset: number = 50;
    rowsLimit: number = 50;
    lastPageIndex = 0;
    pageSizeOptions: number[] = [10, 50, 100, 200, 500];

    tableName: string = 'integrationItelazpis';
    displayedColumns: string[];
    fixedColumns = [];
    displayedColumnsField: string[];
    clickedRows = new Set<IntegrationItelazpi>();
    allSelected = false;

    lastSelectedRow: number = -1;

    filteredColumn?: string;
    orderedColumn?: string;

    serial_number?: string;

    loadingText = 'Cargando...';

    is_pendent_activations?: boolean;

    menuOptions: string[] = ['Exportar a EXCEL'];

    async onScroll() {
        this.scrollOffset += this.rowsLimit;
        if (this.scrollOffset > this.pageSize) return;
        this.dataSource.data = [];
        this.dataSource.data = this.integrationItelazpis.slice(0, this.scrollOffset);
    }

    constructor(
        private _mySqlService: MySqlService,
        private _utilsService: UtilsService,
        private _windowRefService: WindowRefService,
        private router: Router,
        private route: ActivatedRoute,
        private _electronService: IpcService,
        private _electronServiceAnswer: ElectronService,
        private _spinner: NgxSpinnerService
    ) {
        this.route.params.subscribe((params) => {
            this.serial_number = params['serial'];
        });
        this.pageSize = parseInt(
            localStorage.getItem('integrationItelazpi_pageSize') || `${this.rowsLimit}`
        );

        this.displayedColumns = getIntegrationItelazpiDisplayColumns();
        const displayedColumns = localStorage.getItem('displayedColumns_' + this.tableName);
        if (this._electronService.isElectronApp()) {
            if (!displayedColumns || displayedColumns.length == 0)
                this._electronService.sendMessage('load-columns', {
                    table: this.tableName,
                });
            this._electronServiceAnswer.ipcRenderer.on(
                `send-load-columns-${this.tableName}`,
                async (event: any, arg: any) => {
                    try {
                        const decode = this._utilsService.decodeBase64(arg);
                        this.displayedColumns = JSON.parse(decode);
                        localStorage.setItem(
                            'displayedColumns_' + this.tableName,
                            JSON.stringify(this.displayedColumns)
                        );
                        this.reload();
                    } catch (err) {}
                    this._electronServiceAnswer.ipcRenderer.send(
                        'sync-reply',
                        'received and answer'
                    );
                }
            );
        }
        if (displayedColumns) {
            try {
                const columns = JSON.parse(displayedColumns);
                if (Array.isArray(columns)) this.displayedColumns = columns;
                else this.displayedColumns = getIntegrationItelazpiDisplayColumns();
            } catch (err) {
                this.displayedColumns = getIntegrationItelazpiDisplayColumns();
            }
        } else {
            this.displayedColumns = getIntegrationItelazpiDisplayColumns();
            localStorage.setItem(
                'displayedColumns_' + this.tableName,
                JSON.stringify(this.displayedColumns)
            );
        }

        this.displayedColumnsField = this.displayedColumns.map((displayedColumn: string) =>
            getIntegrationItelazpiField(displayedColumn)
        );
        document.addEventListener('visibilitychange', async () => {
            if (document.hidden) {
            } else {
                const updateNeeded = localStorage.getItem('integrationItelazpiUpdateNeeded');
                if (updateNeeded == 'true') {
                    this.scrollOffset = 50;
                    localStorage.setItem('integrationItelazpiUpdateNeeded', 'false');
                    this.showLoading(true);
                    this.setIntegrationItelazpisInTable(
                        await this._mySqlService.getLastIntegrationItelazpisPage()
                    );
                    this.showLoading(false);
                }
            }
        });
    }

    async ngOnInit(): Promise<void> {
        this.clearIntegrationItelazpiFilterAndOrder();
        await this.getIntegrationItelazpis();
    }

    clearIntegrationItelazpiFilterAndOrder() {
        this._utilsService.orderIntegrationItelazpis = [];
        this._utilsService.filterIntegrationItelazpis = {};
        sessionStorage.setItem('orderIntegrationItelazpis', '');
        sessionStorage.setItem('filterIntegrationItelazpis', '');
    }

    reload() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['./'], { relativeTo: this.route });
    }

    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this._spinner.show('innerSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this._spinner.hide('innerSpinner');
        }
    }

    setIntegrationItelazpisInTable(integrationItelazpis: IntegrationItelazpi[]) {
        this.dataSource.data = [];
        this.integrationItelazpis = integrationItelazpis;
        for (let [index, integrationItelazpi] of this.integrationItelazpis.entries()) {
            integrationItelazpi.ID = index + 1;
        }
        this.dataSource.data = this.integrationItelazpis.slice(0, this.rowsLimit);
        this.showLoading(false);
    }

    async getIntegrationItelazpis() {
        if (!this.serial_number) this.setIntegrationItelazpisInTable(await this.selectIntegrationItelazpis());
        else await this.applyFilter([this.serial_number], 'counter_serial_number');
    }

    async selectIntegrationItelazpis() {
        this.showLoading(true);
        this.length = await this._mySqlService.getIntegrationItelazpisCount('');
        const order = this._utilsService.orderIntegrationItelazpis;
        let order_clause = undefined;
        if (order.length > 0) {
            order_clause = this._utilsService.getOrderClauseFromOrder(order);
        }

        let offset = '0';
        const lastPageIndex = localStorage.getItem('lastPageIndexIntegrationItelazpi');
        if (lastPageIndex) {
            localStorage.setItem('lastPageIndexIntegrationItelazpi', '');
            this.lastPageIndex = parseInt(lastPageIndex);
            offset = (this.lastPageIndex * this.pageSize).toString();
        }

        return await this._mySqlService.getIntegrationItelazpis(
            undefined,
            undefined,
            order_clause,
            offset,
            this.pageSize.toString()
        );
    }

    async selectAll() {
        this.clickedRows.clear();
        const integrationItelazpis = this.integrationItelazpis;
        for (const integrationItelazpi of integrationItelazpis) {
            if (!this.clickedRows.has(integrationItelazpi)) {
                this.clickedRows.add(integrationItelazpi);
            }
        }
        this.allSelected = true;
        this._utilsService.openSnackBar(`Seleccionadas ${this.clickedRows.size} activaciones`);
    }

    searchValue(event: any) {
        const filterValue: string = event;
        this.dataSource!.filter = filterValue.trim().toLowerCase();
    }

    async pageEvent(event: any) {
        if (this.lastPageIndex != event.pageIndex) {
            this.showLoading(true);
            if (this.lastPageIndex < event.pageIndex) {
                this.setIntegrationItelazpisInTable(
                    await this._mySqlService.getNextIntegrationItelazpisPage(
                        event.pageIndex - this.lastPageIndex
                    )
                );
            } else {
                this.setIntegrationItelazpisInTable(
                    await this._mySqlService.getPreviousIntegrationItelazpisPage(
                        this.lastPageIndex - event.pageIndex
                    )
                );
            }
            this.lastPageIndex = event.pageIndex;
        }
        if (this.pageSize != event.pageSize) {
            this.pageSize = event.pageSize;
            localStorage.setItem('integrationItelazpi_pageSize', this.pageSize.toString());
            await this.getIntegrationItelazpis();
        }
        this.scrollOffset = 50;
    }

    async filterIntegrationItelazpi(where?: string) {
        this.length = await this._mySqlService.getIntegrationItelazpisCount(where);
        const order = this._utilsService.orderIntegrationItelazpis;
        let order_clause = undefined;
        if (order.length > 0) {
            order_clause = this._utilsService.getOrderClauseFromOrder(order);
        }
        let integrationItelazpis: IntegrationItelazpi[] = [];
        try {
            integrationItelazpis = await this._mySqlService.getIntegrationItelazpis(
                undefined,
                where,
                order_clause,
                '0',
                this.pageSize.toString()
            );
        } catch (err) {
            console.log('============= err =============');
            console.log(err);
        }
        return integrationItelazpis;
    }

    async applyFilter(values: any, column: string, not_empty: boolean = false, empties_checked: boolean = false) {
        let where_clause = this._utilsService.getWhereClauseFromFilter(
            this._utilsService.processFilter(
                this._utilsService.filterIntegrationItelazpis!,
                values,
                column,
                getIntegrationItelazpiFieldType(column),
                this._mySqlService.integrationItelazpisTableName,
                true, 
                not_empty,
                empties_checked
            )
        );
        this.showLoading(true);
        localStorage.setItem('lastPageIndexIntegrationItelazpi', '');
        this.lastPageIndex = 0;
        this.setIntegrationItelazpisInTable(await this.filterIntegrationItelazpi(where_clause));
    }

    async filterBy(column: string) {
        this.filteredColumn = getIntegrationItelazpiField(column);
        if (getIntegrationItelazpiFieldType(this.filteredColumn) == 'Date') {
            const dates = await this._utilsService.openDateRangeSelectorDialog(
                'Seleccione rango de fechas'
            );
            let times: Date[];
            try {
                times = await this._utilsService.openTimeRangeSelectorDialog(
                    'Seleccione rango de horas'
                );
                this.onDateSelected(dates, times);
            } catch (err) {
                this.onDateSelected(dates);
            }
        } else {
            const result = await this._utilsService.openFilterDialog(
                column,
                this.filteredColumn,
                this._mySqlService.integrationItelazpisTableName,
                this._utilsService.filterIntegrationItelazpis
            );
            if (result && result.data) {
                 this.applyFilter(result.data, result.column, result.not_empty, result.empties_checked);
            }
        }
    }

    async onDateSelected(dateRange: Date[], timeRange?: Date[]) {
        if (dateRange) {
            const values = this._utilsService.getDateRangeString(dateRange, timeRange);
            await this.applyFilter(values, this.filteredColumn!);
        } else {
            this._utilsService.openSnackBar('Rango fechas inválido', 'error');
        }
    }

    async orderBy(event: any) {
        const column = event.column;
        const orderType = event.orderType;

        const orderedColumn = getIntegrationItelazpiField(column);
        const order_clause = this._utilsService.getOrderClauseFromOrder(
            this._utilsService.processOrder(
                this._mySqlService.integrationItelazpisTableName,
                this._utilsService.orderIntegrationItelazpis,
                orderedColumn,
                orderType
            )
        );
        const filter = this._utilsService.filterIntegrationItelazpis;
        let where_clause = undefined;
        if (filter && filter.fields) {
            where_clause = this._utilsService.getWhereClauseFromFilter(filter);
        }
        this.showLoading(true);
        const integrationItelazpis = await this._mySqlService.getIntegrationItelazpis(
            undefined,
            where_clause,
            order_clause,
            '0',
            this.pageSize.toString()
        );
        localStorage.setItem('lastPageIndexIntegrationItelazpi', '');
        this.lastPageIndex = 0;
        this.setIntegrationItelazpisInTable(integrationItelazpis);
    }

    async addNewRow(event: any) {
        const integrationItelazpi = await this._utilsService.openIntegrationItelazpiDialog('');
        console.log(integrationItelazpi);
        if (integrationItelazpi) {
            this.integrationItelazpis.push(integrationItelazpi);
            this.dataSource.data = [];
            this.dataSource.data = this.integrationItelazpis.slice(0, this.scrollOffset);
        }
    }

    async doubleClickedRow(row: any) {
        localStorage.setItem('lastPageIndexIntegrationItelazpi', this.lastPageIndex.toString());

        let integrationItelazpi = await this._utilsService.openIntegrationItelazpiDialog(row.id);

        if (integrationItelazpi) {
            integrationItelazpi.ID = this.integrationItelazpis[row.rowIndex].ID;
            this.integrationItelazpis[row.rowIndex] = integrationItelazpi;
            this.dataSource.data = [];
            this.dataSource.data = this.integrationItelazpis.slice(0, this.scrollOffset);
        }
    }

    clickedRow(receivedEvent: any) {
        const row = receivedEvent.row;
        const event = receivedEvent.event;
        const rowIndex = receivedEvent.rowIndex;

        const previousRow = this.lastSelectedRow;
        this.lastSelectedRow = rowIndex;

        if (event.button === 0) {
            if (!event.ctrlKey && !event.shiftKey) {
                this.allSelected = false;
                this.clickedRows.clear();
                this.toggleRow(row);
                // console.log('**************** !event.ctrlKey && !event.shiftKey ***************');
            } else if (event.ctrlKey) {
                this.toggleRow(row);
                // console.log('**************** event.ctrlKey ***************');
            }
            if (event.shiftKey) {
                this.selectRowsBetweenIndexes(previousRow, rowIndex);
                console.log('**************** event.shiftKey ***************');
            }
        }
        if (this._windowRefService.nativeWindow.getSelection) {
            //remove selection in table with shift
            if (this._windowRefService.nativeWindow.getSelection().empty) {
                // Chrome
                this._windowRefService.nativeWindow.getSelection().empty();
            } else if (this._windowRefService.nativeWindow.getSelection().removeAllRanges) {
                // Firefox
                this._windowRefService.nativeWindow.getSelection().removeAllRanges();
            }
        }
    }

    selectedRows(event: any) {
        this.allSelected = false;
        this.clickedRows.clear();
        this.selectRowsBetweenIndexes(event[0], event[1], false);
    }

    selectRowsBetweenIndexes(
        lastSelectedRow: number,
        rowIndex: number,
        showSnackBar: boolean = true
    ) {
        let start, end;
        if (rowIndex > lastSelectedRow) {
            start = lastSelectedRow;
            end = rowIndex;
        } else {
            end = lastSelectedRow;
            start = rowIndex;
        }
        for (let i = start; i <= end; i++) {
            this.clickedRows.add(this.integrationItelazpis[i]);
        }
        if (this.clickedRows.size > 1 && showSnackBar) {
            this._utilsService.openSnackBar(`Seleccionadas ${this.clickedRows.size} activaciones`);
        }
    }

    toggleRow(row: any) {
        if (this.clickedRows.has(row)) {
            this.clickedRows.delete(row);
        } else {
            this.clickedRows.add(row);
        }
    }

    exportIntegrationItelazpisInTable() {
        if (this.clickedRows.size > 0) this.exportExcel(this.clickedRows);
        else this.exportExcel(this.integrationItelazpis);
    }

    exportExcel(integrationItelazpis: any) {
        let excelFormatIntegrationItelazpis = [];
        for (let integrationItelazpi of integrationItelazpis) {
            let data: any = {};
            const columns = getIntegrationItelazpiExcelExportColumns();
            columns.forEach((column) => {
                data[column] = this._utilsService.fieldPipe(
                    integrationItelazpi[getIntegrationItelazpiExcelFieldName(column)],
                    column
                );
            });
            excelFormatIntegrationItelazpis.push(data);
        }
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelFormatIntegrationItelazpis);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        /* save to file */
        XLSX.writeFile(wb, 'Activaciones_Exportados.xlsx');
    }

    updateActualPageInfo(integrationItelazpi: any, keys: any) {
        for (let row of this.clickedRows) {
            const index = this.integrationItelazpis.indexOf(row, 0);
            for (let key of keys) {
                const keyIndex = key as keyof IntegrationItelazpi;
                let value = integrationItelazpi[keyIndex]!; //this.task[key as keyof Itac];
                row[keyIndex] = value as any;
            }
            this.integrationItelazpis[index] = row;
        }
    }
}

/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { getFormControls, order_status, priority_status, WaterTask } from 'src/app/interfaces/water-task';
import { UtilsService } from 'src/app/services/utils.service';

import { ApiService } from 'src/app/services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { task_status } from '../../../interfaces/water-task';
import { TypeRadius } from '../../../interfaces/type-radius';

export interface ServicesInformData {
    task: WaterTask;
}

@Component({
    selector: 'app-services-inform',
    templateUrl: './services-inform.component.html',
    styleUrls: ['./services-inform.component.scss'],
})
export class ServicesInformComponent implements OnInit {
    taskFormData: FormGroup = getFormControls();
    task: WaterTask = {};

    suplies: string[] = [];
    services: string[];
    loading: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ServicesInformData,
        private _apiService: ApiService,
        private _spinner: NgxSpinnerService,
        private _utilsService: UtilsService
    ) {
        this.task = data.task;
        this.services = this._utilsService.services;
    }

    async ngOnInit(): Promise<void> {
        this.taskFormData = this._utilsService.getFormFromTask(this.task);
        this.suplies = this._utilsService.suplies;
        const radios = (await this._apiService.getDocuments<TypeRadius>('type_radius'))
            .map((typeRadius: TypeRadius) => `${typeRadius.radio}`)
            .sort();
        for (const radio of radios) {
            const suply = `Módulo ${radio}`;
            if (!this.suplies.includes(suply)) this.suplies.push(suply);
        }
    }

    async closeTask() {
        this.showLoading(true);
        this.saveFormData();
        const result: boolean = await this._apiService.updateTask(
            this.task.id!.toString(),
            this.task,
            true
        );
        this.showLoading(false);
        if (result) {
            this._utilsService.openSnackBar('Tarea cerrada correctamente');
        } else {
            this._utilsService.openSnackBar('Error cerrando tarea', 'error');
        }
        this._utilsService.closeServicesInformDialog(result);
    }

    saveFormData() {
        const keys = Object.keys(this.taskFormData.controls);
        for (let key of keys) {
            let value = this.taskFormData.controls[key].value; 
            try {
                if (key == 'OPERARIO') {
                } else if (Array.isArray(value) && value.length > 0) {
                    let values = [];
                    for (const v of value) {
                        if (typeof v == 'string') values.push({ value: v });
                    }
                    value = values;
                } else if (moment.isMoment(value)) {
                    value = value.toDate();
                }
                this.task[key as keyof WaterTask] = value;
            } catch (err) {}
        }
        if (this.task.servicios?.length) this.task.last_service = this.task.servicios[0].value;
        if (!this.task!.TIPORDEN) this.task!.TIPORDEN = order_status.DAILY;
        if (!this.task!.prioridad) this.task!.prioridad = priority_status.HIBERNATE;
        
        this.task.status_tarea = task_status.CLOSED;
        this.task.date_time_modified = new Date();
        this.task.FECH_CIERRE = this.task.date_time_modified;
    }

    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this._spinner.show('taskSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this._spinner.hide('taskSpinner');
        }
    }
}

/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { getItacFormControls, Itac } from 'src/app/interfaces/itac';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { ItacSectionData } from '../section1/section1.component';

@Component({
    selector: 'app-section4',
    templateUrl: './section4.component.html',
    styleUrls: ['./section4.component.scss'],
})
export class Section4Component implements OnInit {
    statuses: string[] = [
        'Sin comprobar',
        'Excelente',
        'Regular',
        'Poco fiable',
        'Mal estado',
        'Otros',
    ];

    itac?: Itac;

    itacFormData: FormGroup = getItacFormControls();

    loading: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ItacSectionData,
        private _utilsService: UtilsService,
        private _apiService: ApiService,
        private _spinner: NgxSpinnerService
    ) {
        this.itac = data.itac;
        console.log(this.itac);
    }

    async saveData() {
        this.showLoading(true);
        this.saveFormData();
        this.calculatePoints();
        const result: boolean = await this._apiService.updateItac(
            this.itac!.id!.toString(),
            this.itac
        );
        this.showLoading(false);
        if (result) {
            this._utilsService.openSnackBar('Itac actualizada correctamente');
        } else {
            this._utilsService.openSnackBar('Error actualizando itac', 'error');
        }
        this._utilsService.closeSection4Dialog(result);
    }
    saveFormData() {
        const keys = Object.keys(this.itacFormData.controls);
        for (let key of keys) {
            let value = this.itacFormData.controls[key].value; //this.itac[key as keyof Itac];
            try {
                value = this._utilsService.getProcessedValue(key, value);
                this.itac![key as keyof Itac] = value;
            } catch (err) {
                console.log('============= err =============');
                console.log(err);
            }
        }
        this.itac!.date_time_modified = new Date();
    }

    ngOnInit(): void {
        this.itacFormData = this._utilsService.getFormFromItac(this.itac!);
    }

    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this._spinner.show('itacSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this._spinner.hide('itacSpinner');
        }
    }

    calculatePoints() {
        let sectionPoints = 0;
        let tubo_de_alimentacion = this.itac!.tubo_de_alimentacion;
        let colector = this.itac!.colector;
        let tuberias_de_entrada = this.itac!.tuberias_de_entrada_contador;
        let tuberias_de_salida = this.itac!.tuberias_de_salida_contador;

        if (tubo_de_alimentacion) {
            if (tubo_de_alimentacion == 'Excelente' || tubo_de_alimentacion == 'Regular') {
                sectionPoints += 0.5;
            }
            if (tubo_de_alimentacion == 'Poco fiable') {
                sectionPoints += 0.2;
            }
            if (tubo_de_alimentacion == 'Mal estado') {
                ///Otros!!!!?????
                sectionPoints += -2;
            }
        }
        if (colector) {
            if (colector == 'Excelente' || colector == 'Regular') {
                sectionPoints += 0.5;
            }
            if (colector == 'Poco fiable') {
                sectionPoints += 0.2;
            }
            if (colector == 'Mal estado') {
                ///Otros!!!!?????
                sectionPoints += -2;
            }
        }
        if (tuberias_de_entrada) {
            if (tuberias_de_entrada == 'Excelente' || tuberias_de_entrada == 'Regular') {
                sectionPoints += 0.5;
            }
            if (tuberias_de_entrada == 'Poco fiable') {
                sectionPoints += 0.2;
            }
            if (tuberias_de_entrada == 'Mal estado') {
                ///Otros!!!!?????
                sectionPoints += -6;
            }
        }
        if (tuberias_de_salida) {
            if (tuberias_de_salida == 'Excelente' || tuberias_de_salida == 'Regular') {
                sectionPoints += 0.5;
            }
            if (tuberias_de_salida == 'Poco fiable') {
                sectionPoints += 0.2;
            }
            if (tuberias_de_salida == 'Mal estado') {
                ///Otros!!!!?????
                sectionPoints += -3;
            }
        }

        this.itac!.puntuacion_seccion4 = sectionPoints;
    }
}

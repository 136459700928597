/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, OnInit } from '@angular/core';
import { faBarcode, faPen } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-serial-number-option-selector',
    templateUrl: './serial-number-option-selector.component.html',
    styleUrls: ['./serial-number-option-selector.component.scss'],
})
export class SerialNumberOptionSelectorComponent implements OnInit {
    faBarcode = faBarcode;
    faPen = faPen;

    constructor() {}

    ngOnInit(): void {}
}

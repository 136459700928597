import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '../services/utils.service';

@Pipe({
    name: 'priority',
})
export class PriorityPipe implements PipeTransform {
    constructor(private _utilsService: UtilsService) {}
    transform(value: any): string {
        return this._utilsService.getPriorityFromInt(value);
    }
}

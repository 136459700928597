<mat-dialog-content height="200">
    <div>
        <button type="button" mat-button [mat-dialog-close]="'Barcode'">
            <fa-icon class="fa-icon-column" [icon]="faBarcode"></fa-icon>
            Ver código de barras sin digito de control
        </button>
        <div style="height: 10px"></div>
        <button type="button" mat-button [mat-dialog-close]="'Barcode with digit'">
            <fa-icon class="fa-icon-column" [icon]="faBarcode"></fa-icon>
            Ver código de barras con digito de control
        </button>
        <div style="height: 10px"></div>
        <button type="button" mat-button [mat-dialog-close]="'Add counter'">
            <fa-icon class="fa-icon-column" [icon]="faPen"></fa-icon>
            Insertar contador
        </button>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="center">
    <button mat-button mat-dialog-close>Cancelar</button>
</mat-dialog-actions>

<table
    #table
    mat-table
    [dataSource]="dataSource"
    class="miruta-table"
    [style]="tableStyle"
    cdkDropList
    cdkDropListOrientation="horizontal"
    (cdkDropListDropped)="tableDrop($event)"
>
    <div *ngFor="let column of displayedColumns; let i = index">
        <ng-container *ngIf="column == 'ID'" [sticky]="true" matColumnDef="{{ column }}">
            <th mat-header-cell *matHeaderCellDef class="column-header">
                <b>{{ column }}</b>
            </th>
            <td mat-cell *matCellDef="let element" class="right-aligned-cell">
                <div style="display: flex;">
                    <fa-icon *ngIf="hasCorrectPhones(element)" 
                        [matTooltip]="getPhonesToolTip(element)"
                        matTooltipClass="phone-mat-tooltip"
                        class="fa-icon-in-row" [icon]="faHeadset">
                    </fa-icon>
                    <div *ngIf="!hasCorrectPhones(element)" class="div-space"></div>
                    <mat-icon-tooltip-auto-hide *ngIf="checkIfTaskHasServerImage(element) || checkIfItacHasServerImage(element)" 
                        [images]="getImages(element)">loading
                    </mat-icon-tooltip-auto-hide>
                    <div *ngIf="!checkIfTaskHasServerImage(element) && !checkIfItacHasServerImage(element)" class="div-space"></div>
                    <div class="id-column">{{ element[displayedColumnsField[i]] }}</div>
                </div>
            </td>
        </ng-container>

        <ng-container *ngIf="column !== 'ID'" [sticky]="isStickyColumn(column)" matColumnDef="{{ column }}">
            <th
                mat-header-cell
                *matHeaderCellDef
                class="column-header"
                cdkDrag
                cdkDragLockAxis="x"
                cdkDragBoundary="mat-header-row"
                [style.borderRightColor]="isEndHourColumn(column) ? 'black' : ''"
                [style.borderLeftColor]="isStartHourColumn(column) ? 'black' : ''"
                [style.color]="isHourColumn(column) ? 'black' : ''"
                [style.backgroundColor]="isEndHourColumn(column) ? '#FFA726' : (isStartHourColumn(column) ? '#FFE0B2' : (isHourColumn(column) ? '#FFCC80' : ''))"
            >
                <b>{{ column }}</b>
                <button
                    *ngIf="isNormalFilterField(column) && !hoursList.includes(column)"
                    mat-icon-button
                    [matMenuTriggerFor]="menu"
                >
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="filterBy(column)">
                        <fa-icon class="fa-icon-column" [icon]="faFilter"></fa-icon>
                        <span>Filtrar</span>
                    </button>
                    <button mat-menu-item (click)="orderBy(column, 'DESC')">
                        <fa-icon class="fa-icon-column" [icon]="faSortAmountDown"></fa-icon>
                        <span style="margin-bottom: 10px">Mayor a menor</span>
                    </button>
                    <button mat-menu-item (click)="orderBy(column, 'ASC')">
                        <fa-icon class="fa-icon-column" [icon]="faSortAmountUpAlt"></fa-icon>
                        <span>Menor a mayor</span>
                    </button>
                </mat-menu>
            </th>
            <td mat-cell *matCellDef="let element"
                [style.backgroundColor]="getBackgroundColor(element, column)"
                [style.borderRightColor]="isEndHourColumn(column) ? 'black' : ''"
                [style.borderLeftColor]="isStartHourColumn(column) ? 'black' : ''"
            >
                {{ element[displayedColumnsField[i]] | fieldPipe : column }}
            </td>
        </ng-container>
    </div>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr
        #matrow
        mat-row
        [matTooltip]="getToolTip(row)"
        matTooltipClass="wider-mat-tooltip"
        *matRowDef="let row; columns: displayedColumns; let rowIndex = index"
        (contextmenu)="onRightClick($event, { content: 'Item ' + rowIndex })"
        (dblclick)="doubleClickedRow(row, rowIndex)"
        (click)="clickedRow(row, $event, rowIndex)"
        (mouseenter)="mouseEnter(rowIndex)"
        (mousedown)="mouseDown(rowIndex)"
        (mouseup)="mouseUp(rowIndex)"
        [class.is-last-modification-android]="fromTasksOrItacs && row.last_modification_android"
        [class.is-date-depreceated-task]="fromTask && checkIfIsDepreceated(row)"
        [class.is-date-depreceated-task-and-last-modification-android]="
            fromTask && checkIfIsDepreceated(row) && row.last_modification_android
        "
        [class.is-high-priority-task]="
            fromTasksOrItacs &&
            !row.last_modification_android &&
            row.prioridad &&
            row.prioridad === high_priority_value
        "
        [class.is-high-priority-task-and-last-modification-android]="
            fromTasksOrItacs &&
            row.last_modification_android &&
            row.prioridad &&
            row.prioridad === high_priority_value
        "
        [class.is-company-user]="fromUsers && isCompanyUser(row)"
        [class.demo-row-is-clicked]="clickedRows.has(row)"
    >
    </tr>
</table>

<div
    style="visibility: hidden; position: fixed"
    [style.left]="menuTopLeftPosition.x"
    [style.top]="menuTopLeftPosition.y"
    [matMenuTriggerFor]="rightMenu"
></div>

<mat-menu #rightMenu="matMenu">
    <ng-template matMenuContent let-item="item">
        <button *ngFor="let option of options" mat-menu-item (click)="onMenuOptionSelected(option)">
            {{ option }}
        </button>
    </ng-template>
</mat-menu>

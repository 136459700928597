/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { FormControl, FormGroup } from '@angular/forms';
import { MyLatLng } from './lat-lng';
import { Manager } from './manager';
import { Agrupation } from './agrupation';

export interface Company {
    id?: number;
    codigo_empresa?: string;
    date_time_modified?: Date;
    descripcion?: string;
    empresa?: string;
    foto?: string;
    geolocalizacion?: MyLatLng;
    nombre_empresa?: string;
    permisos?: string;
    managers?: Manager[];
    entity_user?: string;
    entity_pasword?: string;
    entity?: string;
    entityId?: number;
    nonActiveEntity?: number;
    nonAssignedEntity?: number;
    assignedEntity?: number;
    agrupations?: Agrupation[];
}

export function getCompanyDisplayColumns(): string[] {
    const displayedColumns: string[] = [
        'Código empresa',
        'Nombre empresa',
        'Descripción',
        'Fecha de Modificación',
    ];
    return displayedColumns;
}

export function getCompanyField(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'Código empresa':
            value = 'codigo_empresa';
            break;
        case 'Empresa':
            value = 'empresa';
            break;
        case 'Nombre empresa':
            value = 'nombre_empresa';
            break;
        case 'Descripción':
            value = 'descripcion';
            break;
        case 'Fecha de Modificación':
            value = 'date_time_modified';
            break;
    }
    return value;
}

export function getCompanyFieldName(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'codigo_empresa':
            value = 'Código empresa';
            break;
        case 'empresa':
            value = 'Empresa';
            break;
        case 'nombre_empresa':
            value = 'Nombre empresa';
            break;
        case 'descripcion':
            value = 'Descripción';
            break;
        case 'date_time_modified':
            value = 'Fecha de Modificación';
            break;
    }
    return value;
}

export function getCompanyFormControls(): FormGroup {
    const companyFormData: FormGroup = new FormGroup({
        codigo_empresa: new FormControl(),
        date_time_modified: new FormControl(),
        descripcion: new FormControl(),
        empresa: new FormControl(),
        foto: new FormControl(),
        geolocalizacion: new FormControl(),
        id: new FormControl(),
        nombre_empresa: new FormControl(),
        permisos: new FormControl(),
        managers: new FormControl(),
        entity_user: new FormControl(),
        entity_pasword: new FormControl(),
        entity: new FormControl(),
        entityId: new FormControl(),
        nonActiveEntity: new FormControl(),
        nonAssignedEntity: new FormControl(),
        assignedEntity: new FormControl(),
        agrupations: new FormControl(),
    });
    return companyFormData;
}

export function getCompanyFieldType(field_name: string): string {
    //in bigquery
    let value: string = '';
    switch (field_name) {
        case 'codigo_empresa':
            value = 'string';
            break;
        case 'date_time_modified':
            value = 'Date';
            break;
        case 'descripcion':
            value = 'string';
            break;
        case 'empresa':
            value = 'string';
            break;
        case 'foto':
            value = 'string';
            break;
        case 'geolocalizacion':
            value = 'string';
            break;
        case 'id':
            value = 'number';
            break;
        case 'nombre_empresa':
            value = 'string';
            break;
        case 'permisos':
            value = 'string';
            break;
    }
    return value;
}

export function getCompanyExcelFieldName(excel_field: string): string {
    let value: string = '';
    switch (excel_field.trim().toLowerCase()) {
        case 'empresa':
            value = 'empresa';
            break;
        case 'código empresa':
            value = 'codigo_empresa';
            break;
        case 'codigo empresa':
            value = 'codigo_empresa';
            break;
        case 'nombre empresa':
            value = 'nombre_empresa';
            break;
        case 'descripción':
            value = 'descripcion';
            break;
        case 'descripcion':
            value = 'descripcion';
            break;
        case 'fecha de modificación':
            value = 'date_time_modified';
            break;
        case 'fecha de modificacion':
            value = 'date_time_modified';
            break;
    }
    return value;
}

export function getCompanyExcelExportColumns(): string[] {
    const columns = ['Código empresa', 'Nombre empresa', 'Descripción', 'Fecha de Modificación'];
    return columns;
}

/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faInbox } from '@fortawesome/free-solid-svg-icons';
import { MiRutaFilter } from 'src/app/interfaces/mi-ruta-filter';
import { UtilsService } from 'src/app/services/utils.service';
import { MySqlService } from '../../../services/mysql.service';
import { getFieldType } from '../../../interfaces/water-task';
import { DialogData } from '../../../interfaces/dialog-data';
import { getItacFieldType } from '../../../interfaces/itac';
import { getCounterFieldType } from '../../../interfaces/counter';
import { getWaterRouteFieldType } from '../../../interfaces/water-route';
import { CheckBoxModel } from '../../../interfaces/check-box-model';
import { getIntegrationItelazpiFieldType } from 'src/app/interfaces/integration-itelazpi';
import { getActivationLogFieldType } from 'src/app/interfaces/activation-log';
import { getSideFieldType } from '../../../interfaces/side';

@Component({
    selector: 'app-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {
    checkBoxes: CheckBoxModel[] = [];
    myControl = new FormControl();
    options: string[] = [];
    filteredOptions!: string[];

    column?: string = '';
    column_name?: string = '';
    table_name: string;
    filter?: MiRutaFilter;
    select_all: boolean = false;
    not_empty: boolean = false;
    empties_checked: boolean = false;

    loading = false;
    faInbox = faInbox;

    timerInputChanged: any;

    filterLimit = 15;

    offset = 0;
    optionsSelected: any[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        public dialogRef: MatDialogRef<FilterComponent>,
        private _mySqlService: MySqlService, //MysqlService BigQueryService
        private _utilsService: UtilsService
    ) {
        this.column = data.column!;
        this.column_name = data.column_name!;
        this.table_name = data.table_name!;
        this.filter = data.filter!;

        this._utilsService.setRightFilter(this.table_name, this.filter);
    }

    /**
     * Initializes the component and sets up the filter functionality.
     * This method is called after the component has been initialized and the input properties have been set.
     * @returns A Promise that resolves when the initialization is complete.
     */
    async ngOnInit(): Promise<void> {
        await this.initFilter();
        this.myControl.valueChanges.subscribe(async (value: any) => {
            clearTimeout(this.timerInputChanged);
            if(this._utilsService.validateStringForJsonValue(this.myControl)) return;
            this.timerInputChanged = setTimeout(async () => {
                await this.inputChanged(value);
                this.filteredOptions = this._filter(value);
                this.fillCheckBoxes();
            }, 1000);
        });
    }

    async initFilter(){
        this.optionsSelected = [];
        this.checkBoxes = [];
        await this.getOptions();
        this.filteredOptions = this.options.filter((data) =>
            this._utilsService.isFieldValid(data)
        );
        this.fillCheckBoxes();
    }

    async updateFilter(event: MiRutaFilter) {
        this.filter = event;
        this._utilsService.setRightFilter(this.table_name, this.filter);
        await this.initFilter();
    }

    checkIfOptionIsAlreadySelected(option: any){
        return this.optionsSelected.includes(option);
    }

    async inputChanged(value: string) {
        this.loading = true;
        this.options = [];
        this.filteredOptions = [];
        let whereJsonArray: any[] = [];
        let whereJson: any = {};
        whereJson['field'] = this.column;
        let field_type;

        if(this.column) {
            if (this.table_name == this._mySqlService.tasksTableName) {
                field_type = getFieldType(this.column);
            } else if (this.table_name == this._mySqlService.itacsTableName) {
                field_type = getItacFieldType(this.column);
            } else if (this.table_name == this._mySqlService.countersTableName) {
                field_type = getCounterFieldType(this.column);
            } else if (this.table_name == this._mySqlService.waterRoutesTableName) {
                field_type = getWaterRouteFieldType(this.column);
            } else if (this.table_name == this._mySqlService.activationLogsTableName) {
                field_type = getActivationLogFieldType(this.column);
            } else if (this.table_name == this._mySqlService.integrationItelazpisTableName) {
                field_type = getIntegrationItelazpiFieldType(this.column);
            } else if (this.table_name == this._mySqlService.sidesTableName) {
                field_type = getSideFieldType(this.column);
            }
        }

        if (field_type == 'number') whereJson['value'] = value;
        else whereJson['value'] = `%${value}%`;
        whereJson['type'] = 'AND';
        whereJsonArray.push({ ...whereJson });

        const query_result = await this.getRightFieldValues(JSON.stringify(whereJsonArray));
        this.options = query_result.map((data: any) => data[`${this.column}`]);
        this._setCheckBoxesValue(false);
        this.loading = false;
    }

    async getRightFieldValues(where_clause: string) {
        let query_result;
        let orderJson: any = {};
        orderJson['field'] = `${this.column}`;
        orderJson['type'] = 'ASC';

        let selectJson: any = {};
        selectJson['fields'] = [`${this.column}`];
        selectJson['distincts'] = [`${this.column}`];
        const jsonString = JSON.stringify(selectJson);

        if (this.table_name == this._mySqlService.tasksTableName) {
            where_clause = this._utilsService.addStatusToWhereClause(where_clause);
            query_result = await this._mySqlService.getTasksFieldValues(
                jsonString,
                where_clause,
                JSON.stringify(orderJson),
                undefined,
                this.filterLimit.toString()
            );
        } else if (this.table_name == this._mySqlService.itacsTableName) {
            query_result = await this._mySqlService.getItacsFieldValues(
                jsonString,
                where_clause,
                JSON.stringify(orderJson),
                undefined,
                this.filterLimit.toString()
            );
        } else if (this.table_name == this._mySqlService.countersTableName) {
            query_result = await this._mySqlService.getCountersFieldValues(
                JSON.stringify(selectJson),
                where_clause,
                JSON.stringify(orderJson),
                undefined,
                this.filterLimit.toString()
            );
        } else if (this.table_name == this._mySqlService.waterRoutesTableName) {
            query_result = await this._mySqlService.getWaterRouteFieldValues(
                JSON.stringify(selectJson),
                where_clause,
                JSON.stringify(orderJson),
                undefined,
                this.filterLimit.toString()
            );
        } else if (this.table_name == this._mySqlService.activationLogsTableName) {
            query_result = await this._mySqlService.getActivationLogsFieldValues(
                JSON.stringify(selectJson),
                where_clause,
                JSON.stringify(orderJson),
                undefined,
                this.filterLimit.toString()
            );
        } else if (this.table_name == this._mySqlService.integrationItelazpisTableName) {
            query_result = await this._mySqlService.getIntegrationItelazpisFieldValues(
                JSON.stringify(selectJson),
                where_clause,
                JSON.stringify(orderJson),
                undefined,
                this.filterLimit.toString()
            );
        } else if (this.table_name == this._mySqlService.radiusModulesTableName) {
            query_result = await this._mySqlService.getRadiusModulesFieldValues(
                JSON.stringify(selectJson),
                where_clause,
                JSON.stringify(orderJson),
                undefined,
                this.filterLimit.toString()
            );
        } else if (this.table_name == this._mySqlService.sidesTableName) {
            query_result = await this._mySqlService.getSidesFieldValues(
                jsonString,
                where_clause,
                JSON.stringify(orderJson),
                undefined,
                this.filterLimit.toString()
            );
        } else {
            throw new Error('No table name register');
        }
        return query_result;
    }

    async getLastRightFieldValues() {
        let query_result = [];
        if (this.table_name == this._mySqlService.tasksTableName) {
            query_result = await this._mySqlService.getLastTasksFieldValues(
                this.offset.toString()
            );
        } else if (this.table_name == this._mySqlService.itacsTableName) {
            query_result = await this._mySqlService.getLastItacsFieldValues(
                this.offset.toString()
            );
        }else if (this.table_name == this._mySqlService.countersTableName) {
            query_result = await this._mySqlService.getLastCountersFieldValues(
                this.offset.toString()
            );
        } else if (this.table_name == this._mySqlService.waterRoutesTableName) {
            query_result = await this._mySqlService.getLastWaterRouteTableFieldValues(
                this.table_name,
                this.offset.toString()
            );
        } else if (this.table_name == this._mySqlService.activationLogsTableName) {
            query_result = await this._mySqlService.getLastActivationLogTableFieldValues(
                this.table_name,
                this.offset.toString()
            );
        } else if (this.table_name == this._mySqlService.integrationItelazpisTableName) {
            query_result = await this._mySqlService.getLastIntegrationItelazpiTableFieldValues(
                this.table_name,
                this.offset.toString()
            );
        } else if (this.table_name == this._mySqlService.sidesTableName) {
            query_result = await this._mySqlService.getLastSidesFieldValues(
                this.offset.toString()
            );
        }
        return query_result;
    }

    async getOptions() {
        this.loading = true;
        let whereJson: any = {};

        whereJson['field'] = this.column;
        whereJson['nullity'] = 'IS NOT NULL';
        whereJson['type'] = 'AND';

        let where_clause = '';

        if (this.filter && this.filter.fields) {
            let whereJsonArray: any = [];
            whereJsonArray = JSON.parse(this._utilsService.getWhereClauseFromFilter(this.filter));
            whereJsonArray.push(whereJson);
            where_clause += JSON.stringify(whereJsonArray);
        }
        const query_result = await this.getRightFieldValues(where_clause);
        this.options = query_result.map((data: any) => data[`${this.column}`]);
        this.loading = false;
    }

    private _filter(value: string): string[] {
        const filterValue = value.toString().toLowerCase();
        const filterOptions = this.options.filter((option) =>
            option.toString().toLowerCase().includes(filterValue)
        );
        this.checkBoxes = [];
        this.not_empty = false;

        return filterOptions;
    }
    fillCheckBoxes() {
        let i = 0;
        this.filteredOptions.forEach((option: string) => {
            let displayName = option;
            if (this._utilsService.specialFields.includes(this.column_name!)) {
                displayName = this._utilsService.specialFieldPipe(displayName, this.column_name);
            }
            if (displayName.length > 35) displayName = displayName.substring(0, 35) + '...';
            const checkBox: CheckBoxModel = {
                name: option || '0',
                displayName: displayName || '0 o vacío',
                checked: this.select_all || this.checkIfOptionIsAlreadySelected(option),
                field_name: this.column!,
                transformation: false,
                position: i,
            };
            i++;
            this.checkBoxes.push(checkBox);
        });
    }

    updateCheckBox(checkBox: CheckBoxModel, checked: boolean) {
        this.checkBoxes[checkBox.position].checked = checked;
        let name = this.checkBoxes[checkBox.position].name;
        if (checked) {
            this.empties_checked = false;
            if (!this.optionsSelected.includes(name)) {
                this.optionsSelected.push(name);
            }
        } else {
            const index = this.optionsSelected.indexOf(name);
            if (index >= 0) {
                this.optionsSelected.splice(index, 1);
            }
        }
    }

    private _setCheckBoxesValue(checked: boolean){
        for (let i = 0; i < this.checkBoxes.length; i++) {
            this.checkBoxes[i].checked = checked;
        }
    }

    selectEmpties(checked: boolean) { 
        this.empties_checked = checked;
        if(checked){
            this.not_empty = false;
            this.select_all = false;
            this._setCheckBoxesValue(false);
        }            
    }

    checkedAll(checked: boolean) { 
        if (checked) { 
            this.not_empty = false;
            this.empties_checked = false;
        }
        this.select_all = checked;
        this._setCheckBoxesValue(checked);
    }


    notEmpty(checked: boolean) { 
        if (checked) { 
            this.select_all = false;
            this.empties_checked = false;
            this._setCheckBoxesValue(false);
        }
        this.not_empty = checked;
    }

    async onScroll() {
        this.offset += this.filterLimit;
        const query_result = await this.getLastRightFieldValues();
        if(query_result){
            let addOptions = query_result.map((data: any) => data[`${this.column}`]);
            for (let i = 0; i < addOptions.length; i++) {
                this.options.push(addOptions[i]);
            }
            this.filteredOptions = this.options.filter((data) =>
                this._utilsService.isFieldValid(data)
            );
            this.checkBoxes = [];
            this.fillCheckBoxes();
        }
    }

    
    acceptDialog(): void {
        this.checkBoxes.forEach((checkBox: CheckBoxModel) => {
            if (checkBox.checked) {
                if (!this.optionsSelected.includes(checkBox.name))
                    this.optionsSelected.push(checkBox.name);
            }
        });
        if (this.optionsSelected.length == 0 && !this.not_empty && !this.empties_checked) {
            this._utilsService.openSnackBar('Debe seleccionar alguna opción', 'warning');
            return;
        }
        const textSearch = this.myControl.value;
        let data = this.optionsSelected;

        if (this.not_empty) {
            if (textSearch) data = [`%${textSearch}%`]
            else data = [];
        }
        let result = {
            column: this.column,
            not_empty: this.not_empty,
            empties_checked: this.empties_checked,
            data: data,
        };
        this.dialogRef.close(result);
    }
    
    cancelDialog(): void {
        this.dialogRef.close();
    }
}

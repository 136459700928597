/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CountryCode } from 'src/app/interfaces/country-code';
import { Itac } from 'src/app/interfaces/itac';
import { UtilsService } from 'src/app/services/utils.service';
import { WaterTask } from '../../../interfaces/water-task';
import { ApiService } from 'src/app/services/api.service';
import { ManagerMessage } from 'src/app/interfaces/manager-message';

export interface WhatsappMessageData {
    task: WaterTask;
    itac: Itac;
}
@Component({
    selector: 'app-whatsapp-message',
    templateUrl: './whatsapp-message.component.html',
    styleUrls: ['./whatsapp-message.component.scss'],
})
export class WhatsappMessageComponent implements OnInit {
    @Input() task?: WaterTask;
    @Input() itac?: Itac;

    @ViewChild('autosize') autosize?: CdkTextareaAutosize;

    country_codes: string[] = [];
    phones: string[] = [];
    selectedPhoneValue?: string;
    selectedCountryCodeValue?: string;
    messageText: string = '';
    managerMessage?: ManagerMessage;

    /**
     * Represents the WhatsappMessageComponent class.
     * This component is responsible for handling the WhatsApp message functionality.
     */
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: WhatsappMessageData,
        private _apiService: ApiService,
        private _utilsService: UtilsService
    ) {
        this.task = data.task;
        this.itac = data.itac;
        this.country_codes = this._utilsService.country_codes.map(
            (country_code: CountryCode) => `${country_code.name_es} ${country_code.dial_code}`
        );
        this.selectedCountryCodeValue = 'España +34';
    }

    /**
     * Initializes the component.
     * This method is called after the component has been created and initialized.
     * It retrieves manager messages and managers from the API service,
     * and performs additional tasks based on the presence of `task` or `itac`.
     */
    async ngOnInit(): Promise<void> {
        await this.getMessage();
        await this._apiService.getManagers();
        if (this.task) {
            this.searchTaskPhones();
            this.fillMessage();
        } else if (this.itac) {
            this.searchItacPhones();
            this.fillItacMessage();
        }
    }

    async editMessage(): Promise<void> {
        try {
            const itac_message = this.task ? false: true;
            if(this.managerMessage?.company && this.managerMessage?.manager) {
                const mess = await this._utilsService.openManagerMessageDialog(this.managerMessage!.id.toString(), itac_message);
                this.messageText = mess.message;
            }
            else {
                const mess = await this._utilsService.openManagerMessageDialog('', itac_message);
                this.messageText = mess.message;
            }
            if (this.task) this.fillMessage();
            else if (this.itac) this.fillItacMessage();
        } catch (err) {}
    }

    /**
     * Retrieves a message from the API based on the company, manager, and itac_message values.
     * If a matching message is found, it sets the messageText property to the retrieved message.
     * If no matching message is found, it falls back to default messages.
     */
    async getMessage(): Promise<void> {
        const company = localStorage.getItem('company');
        const manager = localStorage.getItem('manager');
        const itac_message = this.task ? 0: 1;
        let where = [['company', '==', company],['manager', '==', manager],['itac_message', '==', itac_message]];
        let messages = await this._apiService.getDocuments<ManagerMessage>('manager-message', where);
        
        if(messages && messages.length) {
            this.managerMessage = messages[0];
            this.messageText = this.managerMessage!.message;
        }
        else {
            where = [['company', '==', company],['default', '==', '1'],['itac_message', '==', itac_message]];
            messages = await this._apiService.getDocuments<ManagerMessage>('manager-message', where);
            if(messages && messages.length) {
                this.managerMessage = messages[0];
                this.messageText = this.managerMessage!.message;
            }
            else {
                where = [['default', '==', '1'],['itac_message', '==', itac_message]];
                messages = await this._apiService.getDocuments<ManagerMessage>('manager-message', where);
                if(messages && messages.length) {
                    this.managerMessage = messages[0];
                    this.messageText = this.managerMessage!.message;
                }
            }
        }
    }

    /**
     * Searches for task phones and updates the `phones` array and `selectedPhoneValue`.
     */
    searchTaskPhones() {
        if(this.task){
            if (this.task && this.task.telefono1) {
                this.phones.push(this.task.telefono1!);
                this.selectedPhoneValue = this.task.telefono1!;
            }
            if (this.task && this.task.telefono2) {
                this.phones.push(this.task.telefono2!);
                if (!this.selectedPhoneValue) {
                    this.selectedPhoneValue = this.task.telefono2!;
                }
            }
        }
    }

    /**
     * Searches for phone numbers related to the ITAC and populates the `phones` array.
     * If a phone number is found, it is added to the `phones` array and if `selectedPhoneValue` is not set, it is assigned to `selectedPhoneValue`.
     */
    searchItacPhones() {
        if(this.itac){
            if (this.itac.telefono_fijo_administracion) {
                this.phones.push(this.itac.telefono_fijo_administracion);
                if (!this.selectedPhoneValue) {
                    this.selectedPhoneValue = this.itac.telefono_fijo_administracion;
                }
            }
            if (this.itac.telefono_fijo_encargado) {
                this.phones.push(this.itac.telefono_fijo_encargado);
                if (!this.selectedPhoneValue) {
                    this.selectedPhoneValue = this.itac.telefono_fijo_encargado;
                }
            }
            if (this.itac.telefono_fijo_presidente) {
                this.phones.push(this.itac.telefono_fijo_presidente);
                if (!this.selectedPhoneValue) {
                    this.selectedPhoneValue = this.itac.telefono_fijo_presidente;
                }
            }
            if (this.itac.telefono_movil_administracion) {
                this.phones.push(this.itac.telefono_movil_administracion);
                if (!this.selectedPhoneValue) {
                    this.selectedPhoneValue = this.itac.telefono_movil_administracion;
                }
            }
            if (this.itac.telefono_movil_encargado) {
                this.phones.push(this.itac.telefono_movil_encargado);
                if (!this.selectedPhoneValue) {
                    this.selectedPhoneValue = this.itac.telefono_movil_encargado;
                }
            }
            if (this.itac.telefono_movil_presidente) {
                this.phones.push(this.itac.telefono_movil_presidente);
                if (!this.selectedPhoneValue) {
                    this.selectedPhoneValue = this.itac.telefono_movil_presidente;
                }
            }
        }
    }

    /**
     * Fills the message text based on the task details.
     */
    fillMessage() {
        const gestor = this._utilsService.managerPipe(this.task?.GESTOR);
        const dir = this._utilsService.getDirOfTask(this.task!, false);
        const abonado = this.task?.Numero_de_ABONADO!;
        this.replaceTexts(gestor, dir, abonado);
    }

    /**
     * Replaces placeholders in the message text with the provided values.
     * @param gestor - The value to replace the '_gestor_' placeholder with.
     * @param dir - The value to replace the '_dir_' placeholder with.
     * @param abonado - The value to replace the '_abonado_' placeholder with. (optional)
     */
    replaceTexts(gestor: string, dir: string, abonado?: string) {
        let regex;
        if(abonado) {
            regex = /_abonado_/gi;
            this.messageText = this.messageText.replace(regex, abonado); 
        }
        regex = /_dir_/gi;
        this.messageText = this.messageText.replace(regex, dir); 
        
        regex = /_gestor_/gi;
        this.messageText = this.messageText.replace(regex, gestor); 
        
        regex = /_endline_/gi;
        this.messageText = this.messageText.replace(regex, '\n'); 
    }

    /**
     * Fills the message text based on the task company and ITAC information.
     */
    fillItacMessage() {
        const gestor = this._utilsService.managerPipe(this.itac?.gestor);
        const dir = this.itac?.itac!;
        this.replaceTexts(gestor, dir);
        console.log('============= this.messageText  =============');
        console.log(this.messageText);
    }

    /**
     * Sends a WhatsApp message with the selected phone number and message text.
     */
    sendMessage(): void {
        const country_code: CountryCode = this._utilsService.country_codes.find(
            (countryCode) =>
                `${countryCode.name_es} ${countryCode.dial_code}` == this.selectedCountryCodeValue
        )!;
        const number = `${country_code.dial_code}${this.selectedPhoneValue}`;
        const regex = /\n/gi; //replaceAll
        let encodeString = this.messageText.replace(regex, '%0A'); //replaceAll
        this._utilsService.goToLink(
            'https://api.whatsapp.com/send?phone=' + number + '&text=' + encodeString
        );
    }
}

/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import * as moment from 'moment';
import { Moment } from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import {
    Emplacement,
    getEmplacementDisplayColumns,
    getEmplacementExcelExportColumns,
    getEmplacementExcelFieldName,
    getEmplacementField,
    getEmplacementFieldType,
} from 'src/app/interfaces/emplacement';
import { ApiService } from 'src/app/services/api.service';
import { MessagingService } from 'src/app/services/messaging.service';
import { UtilsService } from 'src/app/services/utils.service';
import { WindowRefService } from 'src/app/services/window-ref.service';
import * as XLSX from 'xlsx';
import { faInbox } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-emplacements',
    templateUrl: './emplacements.component.html',
    styleUrls: ['./emplacements.component.scss'],
})
export class EmplacementsComponent implements OnInit {
    loading: boolean = true;
    tableName: string = 'emplacements';
    emplacementsInPage: Emplacement[] = [];
    dataSource: MatTableDataSource<Emplacement> = new MatTableDataSource();

    length = 0; //task count in current table
    pageSize = 200; //limit of query
    lastPageIndex = 0;
    pageSizeOptions: number[] = [10, 50, 100, 200, 500];

    displayedColumns: string[] = getEmplacementDisplayColumns();
    fixedColumns = [];
    displayedColumnsField: string[] = this.displayedColumns.map((displayedColumn: string) =>
        getEmplacementField(displayedColumn)
    );
    clickedRows = new Set<Emplacement>();

    lastSelectedRow: number = -1;

    filteredColumn?: string;
    orderedColumn?: string;

    loadingText = 'Cargando...';

    faInbox = faInbox;

    constructor(
        private _apiService: ApiService,
        private _utilsService: UtilsService,
        private _windowRefService: WindowRefService,
        private _spinner: NgxSpinnerService,
        private _messagingService: MessagingService
    ) {}

    async ngOnInit(): Promise<void> {
        await this.getEmplacements();
    }

    async fileEvent(event: any) {
        if (event['file_option'] == 'Importar emplazamientos') {
            this.processExcelFile(event);
        }
    }
    async processExcelFile(event: any) {
        this._spinner.show();
        this.loadingText = `Cargando ...`;
        //TODO: add emplacements from excel
        let workBook: XLSX.WorkBook;
        let jsonData = null;
        const reader = new FileReader();
        const file = event['file'].target.files[0];
        reader.onload = async (_) => {
            this._spinner.show();
            this.loadingText = `Procesando emplazamientos ...`;
            const data = reader.result;
            workBook = XLSX.read(data, { type: 'binary' });
            jsonData = workBook.SheetNames.reduce((initial: any, name) => {
                const sheet = workBook.Sheets[name];
                initial[name] = XLSX.utils.sheet_to_json(sheet);
                return initial;
            }, {});
            let sheets: string[] = [];
            let arrayEmplacements: Emplacement[] = [];

            Object.keys(jsonData).forEach((key: string) => {
                sheets.push(key);
            });
            for (let sheet of sheets) {
                for (let jsonEmplacement of jsonData[sheet]) {
                    let emplacementData: any = {};
                    Object.keys(jsonEmplacement).forEach((key) => {
                        let field: string = getEmplacementExcelFieldName(key);
                        if (this._utilsService.isFieldValid(field)) {
                            let value = jsonEmplacement[key];
                            try {
                                if (typeof value === 'number') {
                                    if (getEmplacementFieldType(field) == 'number') {
                                        emplacementData[`${field}`] = value;
                                    } else {
                                        emplacementData[`${field}`] = value.toString().trim();
                                    }
                                } else if (
                                    typeof value === 'string' &&
                                    this._utilsService.isFieldValid(value)
                                ) {
                                    if (getEmplacementFieldType(field) == 'string') {
                                        emplacementData[`${field}`] = value.trim();
                                    } else if (getEmplacementFieldType(field) == 'boolean') {
                                        emplacementData[`${field}`] =
                                            value.trim().toUpperCase() == 'SI' ? true : false;
                                    } else {
                                        emplacementData[`${field}`] = parseInt(value);
                                    }
                                }
                            } catch (error) {
                                console.log(value);
                            }
                        }
                    });
                    let emplacement = emplacementData as Emplacement;
                    if (
                        emplacement &&
                        this._utilsService.isFieldValid(emplacement.codigo_emplazamiento)
                    ) {
                        arrayEmplacements.push(emplacement);
                    }
                }
            }
            console.log('============= arrayEmplacements =============');
            console.log(arrayEmplacements);
            const result = await this.importEmplacements(arrayEmplacements);
            this._spinner.hide();
        };
        reader.readAsBinaryString(file);

        this._spinner.hide();
    }

    async importEmplacements(emplacements: Emplacement[]) {
        this._spinner.show();
        this.loadingText = `Añadiendo emplazamientos ...`;
        let i = 0,
            errorIds = [];
        for (let emplacement of emplacements) {
            this.loadingText = `Añadiendo emplazamientos ${++i} de ${emplacements.length}`;
            if (!(await this._apiService.addDocument('emplacement', emplacement))) {
                errorIds.push(emplacement.codigo_emplazamiento);
            }
        }
        this._spinner.hide();
        if (errorIds.length > 0) {
            this._utilsService.openSnackBar(`Hubo errores añadiendo emplazamientos`, 'error');
        } else {
            this._utilsService.openSnackBar(`Calibres subidos correctamente`);
        }
        await this.getEmplacements();
        return !(errorIds.length > 0);
    }

    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this._spinner.show('innerSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this._spinner.hide('innerSpinner');
        }
    }

    setEmplacementsInTable(emplacements: Emplacement[]) {
        this.emplacementsInPage = emplacements;
        // this.dataSource.data = emplacements.slice(0, this.sliceSize);
        this.dataSource.data = emplacements;
        // this.dataSource = new TableVirtualScrollDataSource(emplacements);// infiniteScroll
        // console.log(this.dataSource);
        this.showLoading(false);
    }

    async getEmplacements() {
        this.setEmplacementsInTable(await this.selectEmplacements());
    }
    async selectEmplacements() {
        this.showLoading(true);
        let docs = [];
        try {
            docs = await this._apiService.getDocuments<Emplacement>('emplacement', undefined, [
                'codigo_emplazamiento',
                'asc',
            ]);
        } catch (err) {
            console.log('============= err =============');
            console.log(err);
        }
        this.length = docs.length;
        return docs;
        // undefined,/*where*/undefined, /*order_clause*/undefined, '0', this.pageSize.toString());
    }

    searchValue(event: any) {
        const filterValue: string = event;
        this.dataSource!.filter = filterValue.trim().toLowerCase();
    }

    async deleteEmplacements() {
        let errorIds = [];
        if (this.clickedRows.size == 0) {
            this._utilsService.openSnackBar(
                'Debe seleccionar al menos un emplazamiento',
                'warning'
            );
            return;
        }
        const result = await this._utilsService.openQuestionDialog(
            'Confirmación',
            '¿Desea eliminar los emplazamientos seleccionados?'
        );
        if (result) {
            this._spinner.show();
            this.loadingText = `Eliminando emplazamientos ...`;
            let rowIndexes = new Set<number>();
            const oldDataSource = this.dataSource!.data;
            this.clickedRows.forEach(async (row) => {
                console.log(row.id);

                const index = this.dataSource!.data.indexOf(row, 0);
                if (index > -1) {
                    rowIndexes.add(index);
                }
            });
            let emplacements = [];
            let deleteCount = 0;
            for (let i = 0; i < oldDataSource.length; i++) {
                if (!rowIndexes.has(i)) {
                    emplacements.push(oldDataSource![i]);
                } else {
                    this.loadingText = `Eliminando emplazamientos ${++deleteCount} de ${
                        rowIndexes.size
                    }`;
                    if (
                        !(await this._apiService.deleteDocument(
                            'emplacement',
                            oldDataSource[i].id!.toString()
                        ))
                    ) {
                        console.log('****************** deleteEmplacement error *************');
                        errorIds.push(oldDataSource[i].id);
                    } else {
                        console.log('****************** deleteEmplacement success *************');
                    }
                }
            }
            if (errorIds.length > 0) {
                this._utilsService.openSnackBar(`Hubo errores eliminando emplazamientos`, 'error');
            } else {
                this._utilsService.openSnackBar(`Emplazamientos eliminados correctamente`);
            }
            this.length -= rowIndexes.size;
            this.setEmplacementsInTable(emplacements);
            this.clickedRows.clear();
            this._spinner.hide();
        }
    }

    async filterBy(column: string) {
        this.filteredColumn = getEmplacementField(column);
        if (
            getEmplacementFieldType(this.filteredColumn) == 'Date' ||
            getEmplacementFieldType(this.filteredColumn) == 'Timestamp'
        ) {
            const dates = await this._utilsService.openDateRangeSelectorDialog(
                'Seleccione rango de fechas'
            );
            this.onDateSelected(dates);
        } else {
            const options: any[] = this.dataSource.data.map(
                (emplacement: Emplacement) => emplacement[this.filteredColumn as keyof Emplacement]
            );
            const result = await this._utilsService.openSelectorDialog(
                'Seleccione opción',
                options
            );
            if (result) {
                 
                let docs = [];
                try {
                    docs = await this._apiService.getDocuments<Emplacement>('emplacement', [
                        [this.filteredColumn, '==', result],
                    ]);
                } catch (err) {
                    console.log('============= err =============');
                    console.log(err);
                }
                this.length = docs.length;
                this.setEmplacementsInTable(docs);
            }
        }
    }
    async onDateSelected(dateRange: Date[]) {
        if (dateRange) {
            const startDate: Moment = moment(dateRange[0]);
            const startDateString = startDate.format('YYYY-MM-DD HH:mm:ss');
            let endDate: Moment = moment(dateRange[1]);
            if (!endDate) {
                endDate = startDate;
            }
            const endDateString = endDate.add(1, 'days').format('YYYY-MM-DD HH:mm:ss'); //plus one to filter range
            const values = [startDateString, endDateString];
            let docs = [];
            try {
                docs = await this._apiService.getDocuments<Emplacement>('emplacement', [
                    [this.filteredColumn!, '>=', startDate.toDate()],
                    [this.filteredColumn!, '<', endDate.toDate()],
                ]);
            } catch (err) {
                console.log('============= err =============');
                console.log(err);
            }
            this.length = docs.length;
            this.setEmplacementsInTable(docs);
        } else {
            this._utilsService.openSnackBar('Rango fechas invalido', 'error');
        }
    }

    async orderBy(event: any) {
        const column = event.column;
        let orderType = event.orderType;
        if (orderType.toLowerCase().includes('asc')) {
            orderType = 'asc';
        } else {
            orderType = 'desc';
        }
        const orderedColumn = getEmplacementField(column);

        this.showLoading(true);
        const emplacements = await this._apiService.getDocuments<Emplacement>(
            'emplacement',
            undefined,
            [orderedColumn, orderType]
        );
        this.setEmplacementsInTable(emplacements);
    }

    async addNewRow(event: any) {
        console.log('**************** addNewRow ***************');
        const emplacement = await this._utilsService.openEmplacementDialog('');
        console.log(emplacement);
        if (emplacement) {
            this.dataSource.data.push(emplacement);
            const emplacements = this.dataSource.data;
            this.dataSource.data = [];
            this.dataSource.data = emplacements;
        }
    }
    async doubleClickedRow(row: any) {
        console.log('**************** doubleClickedRow ***************');
        console.log(row.id);
        const emplacement = await this._utilsService.openEmplacementDialog(row.id);
        if (emplacement) {
            this.dataSource.data[row.rowIndex] = emplacement;
            const emplacements = this.dataSource.data;
            this.dataSource.data = [];
            this.dataSource.data = emplacements;
        }
    }

    clickedRow(receivedEvent: any) {
        const row = receivedEvent.row;
        const event = receivedEvent.event;
        const rowIndex = receivedEvent.rowIndex;

        const previousRow = this.lastSelectedRow;
        this.lastSelectedRow = rowIndex;

        if (event.button === 0) {
            if (!event.ctrlKey && !event.shiftKey) {
                this.clickedRows.clear();
                this.toggleRow(row);
                // console.log('**************** !event.ctrlKey && !event.shiftKey ***************');
            } else if (event.ctrlKey) {
                this.toggleRow(row);
                // console.log('**************** event.ctrlKey ***************');
            }
            if (event.shiftKey) {
                this.selectRowsBetweenIndexes(previousRow, rowIndex);
                console.log('**************** event.shiftKey ***************');
            }
        }
        if (this._windowRefService.nativeWindow.getSelection) {
            //remove selection in table with shift
            if (this._windowRefService.nativeWindow.getSelection().empty) {
                // Chrome
                this._windowRefService.nativeWindow.getSelection().empty();
            } else if (this._windowRefService.nativeWindow.getSelection().removeAllRanges) {
                // Firefox
                this._windowRefService.nativeWindow.getSelection().removeAllRanges();
            }
        }
    }
    selectRowsBetweenIndexes(lastSelectedRow: number, rowIndex: number) {
        let start, end;
        if (rowIndex > lastSelectedRow) {
            start = lastSelectedRow;
            end = rowIndex;
        } else {
            end = lastSelectedRow;
            start = rowIndex;
        }
        for (let i = start; i <= end; i++) {
            this.clickedRows.add(this.dataSource!.data[i]);
        }
    }
    toggleRow(row: any) {
        if (this.clickedRows.has(row)) {
            this.clickedRows.delete(row);
        } else {
            this.clickedRows.add(row);
        }
    }

    exportEmplacementsInTable() {
        this.exportExcel(this.dataSource!.data);
    }
    exportExcel(waterTasks: any) {
        let excelFormatTasks = [];
        for (let waterTask of waterTasks) {
            let task: any = {};
            const columns = getEmplacementExcelExportColumns();
            columns.forEach((column) => {
                task[`${column}`] = this._utilsService.tableDataPipe(
                    waterTask[getEmplacementExcelFieldName(column)]
                );
            });
            excelFormatTasks.push(task);
        }
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelFormatTasks);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        /* save to file */
        XLSX.writeFile(wb, 'Emplazamientos_Exportados.xlsx');
    }
}

<mat-dialog-content class="animate__animated animate__fadeIn animate__slow">
    <div [formGroup]="counterFormData">
        <div>
            <div class="container">
                <div class="row bd-highlight" class="row-content-center">
                    <div>
                        <div
                            class="row-content-tittle"
                            style="margin-top: 5px; margin-bottom: 30px; margin-right: 1%"
                        >
                            <p style="font-size: large">Datos de contador</p>
                            <mat-slide-toggle
                                style="margin-left: 5%"
                                color="primary"
                                formControlName="activate_in_field_enabled"
                                >Activación en campo</mat-slide-toggle
                            >
                        </div>

                        <div>
                            <div class="row-content">
                                <mat-form-field style="width: 120px" appearance="fill">
                                    <mat-label>Encargado</mat-label>
                                    <input
                                        formControlName="encargado"
                                        matInput
                                        type="text"
                                        placeholder="Encargado"
                                    />
                                </mat-form-field>

                                <mat-form-field *ngIf="assing_to_counters" appearance="fill">
                                    <mat-label>Estado</mat-label>
                                    <mat-select formControlName="status_contador">
                                        <mat-option
                                            *ngFor="let option of [0, 1]"
                                            [value]="option"
                                            >{{ option | counterStatus }}</mat-option
                                        >
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field
                                    *ngIf="!assing_to_counters"
                                    style="width: 120px"
                                    appearance="fill"
                                >
                                    <mat-label>Año o prefijo</mat-label>
                                    <input
                                        formControlName="anno_o_prefijo"
                                        matInput
                                        type="text"
                                        placeholder="Número serie"
                                        [required]="!assing_to_counters"
                                    />
                                </mat-form-field>
                                <mat-form-field
                                    *ngIf="!assing_to_counters"
                                    style="width: 120px"
                                    appearance="fill"
                                >
                                    <mat-label>Número serie inicial</mat-label>
                                    <input
                                        formControlName="numero_serie_contador_start"
                                        matInput
                                        type="number"
                                        placeholder="Número serie"
                                        [required]="!assing_to_counters"
                                    />
                                </mat-form-field>
                                <mat-form-field
                                    *ngIf="!assing_to_counters"
                                    style="width: 120px"
                                    appearance="fill"
                                >
                                    <mat-label>Número serie final</mat-label>
                                    <input
                                        formControlName="numero_serie_contador_end"
                                        matInput
                                        type="number"
                                        placeholder="Número serie"
                                        [required]="!assing_to_counters"
                                    />
                                </mat-form-field>
                            </div>

                            <div class="row-content">
                                <mat-form-field appearance="fill">
                                    <mat-label>Calibre</mat-label>
                                    <mat-select
                                        formControlName="calibre"
                                        [required]="!assing_to_counters"
                                    >
                                        <mat-option
                                            *ngFor="let option of calibers"
                                            [value]="option"
                                            >{{ option }}</mat-option
                                        >
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field appearance="fill">
                                    <mat-label>Longitud</mat-label>
                                    <mat-select
                                        formControlName="longitud"
                                        [required]="!assing_to_counters"
                                    >
                                        <mat-option
                                            *ngFor="let option of longitudes"
                                            [value]="option"
                                            >{{ option }}</mat-option
                                        >
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field appearance="fill">
                                    <mat-label>Ruedas</mat-label>
                                    <input
                                        formControlName="ruedas"
                                        matInput
                                        type="text"
                                        placeholder="Ruedas"
                                    />
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Tipo de fluido</mat-label>
                                    <mat-select
                                        formControlName="tipo_fluido"
                                        [required]="!assing_to_counters"
                                    >
                                        <mat-option
                                            *ngFor="let option of typeCounters"
                                            [value]="option"
                                            >{{ option }}</mat-option
                                        >
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="row-content">
                                <mat-form-field appearance="fill">
                                    <mat-label>Marca</mat-label>
                                    <mat-select
                                        formControlName="marca"
                                        [required]="!assing_to_counters"
                                    >
                                        <mat-option *ngFor="let option of marks" [value]="option">{{
                                            option
                                        }}</mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field appearance="fill">
                                    <mat-label>Lectura inicial</mat-label>
                                    <input
                                        formControlName="lectura_inicial"
                                        matInput
                                        type="number"
                                        placeholder="Lectura inicial"
                                    />
                                </mat-form-field>
                            </div>

                            <div class="row-content">
                                <mat-form-field appearance="fill">
                                    <mat-label>Clase</mat-label>
                                    <mat-select
                                        formControlName="clase"
                                        [required]="!assing_to_counters"
                                    >
                                        <mat-option
                                            *ngFor="let option of classes"
                                            [value]="option"
                                            >{{ option }}</mat-option
                                        >
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field appearance="fill">
                                    <mat-label>Tipo de radio</mat-label>
                                    <mat-select
                                        formControlName="tipo_radio"
                                        [required]="!assing_to_counters"
                                    >
                                        <mat-option
                                            *ngFor="let option of radios"
                                            [value]="option"
                                            >{{ option }}</mat-option
                                        >
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Agrupación</mat-label>
                                    <mat-select formControlName="agrupationId">
                                        <mat-option
                                            *ngFor="let option of agrupations"
                                            [value]="option"
                                            >{{ option | agrupationPipe }}</mat-option
                                        >
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ngx-spinner
        [bdColor]="counter ? 'rgba(0, 0, 0, 0.2)' : 'rgba(255, 255, 255, 1)'"
        size="large"
        color="#368DCE"
        type="ball-scale-multiple"
        [fullScreen]="false"
        name="counterSpinner"
    >
    </ngx-spinner>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="counter">Cancelar</button>
    <button
        mat-button
        cdkFocusInitial
        matTooltip="Añadir rango de contadores"
        (click)="saveChanges()"
    >
        <mat-icon color="primary">check</mat-icon> {{ 'Actualizar' }}
    </button>
</mat-dialog-actions>

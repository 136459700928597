/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { faBuilding, faFilePdf, faKey, faInbox } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { ApiService } from '../../../services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IntegrationItelazpi, getIntegrationItelazpiFormControls } from '../../../interfaces/integration-itelazpi';

@Component({
    selector: 'app-integration-itelazpi',
    templateUrl: './integration-itelazpi.component.html',
    styleUrls: ['./integration-itelazpi.component.scss'],
})
export class IntegrationItelazpiComponent implements OnInit {
    faFilePdf = faFilePdf;
    faInbox = faInbox;
    faBuilding = faBuilding;
    faKey = faKey;
    loading: boolean = false;
    integrationItelazpiId: string = '';
    integrationItelazpi?: IntegrationItelazpi;

    integrationItelazpiFormData: FormGroup = getIntegrationItelazpiFormControls();

    integrationItelazpiSubcription$?: Subscription;

    percentage: number = 0;
    uploading = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _apiService: ApiService,
        public _utilsService: UtilsService,
        private spinner: NgxSpinnerService,
        public formBuilder: FormBuilder
    ) {
        this.integrationItelazpiId = data.integrationItelazpiId;
    }

    async ngOnInit(): Promise<void> {
        if (this.integrationItelazpiId) {
            this.showLoading(true);
            this.integrationItelazpiSubcription$ = this._apiService
                .getDocument(`integration-itelazpi`, this.integrationItelazpiId)
                .subscribe(async (doc: any) => {
                    if (!doc) {
                        this._utilsService.openSnackBar(
                            'Error obteniendo datos de integrationItelazpi',
                            'error'
                        );
                        this.showLoading(false);
                        return;
                    }
                    const integrationItelazpi = doc as IntegrationItelazpi;
                    integrationItelazpi.id = parseInt(this.integrationItelazpiId);

                    this.integrationItelazpi = integrationItelazpi;

                    const integrationItelazpiFormData = getIntegrationItelazpiFormControls();
                    const keys = Object.keys(integrationItelazpi);
                    for (let key of keys) {
                        let value: any = integrationItelazpi[key as keyof IntegrationItelazpi];
                        if (value) {
                            if (this._utilsService.isValidDate(value)) {
                                value = value;
                            }
                            integrationItelazpiFormData.controls[key].setValue(value);
                        }
                    }
                    this.integrationItelazpiFormData = integrationItelazpiFormData;

                    this.showLoading(false);
                });
        }
    }

    ngOnDestroy(): void {
        this.integrationItelazpiSubcription$?.unsubscribe();
    }

    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this.spinner.show('integrationItelazpiSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this.spinner.hide('integrationItelazpiSpinner');
        }
    }
}

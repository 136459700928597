/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { UtilsService } from './utils.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuardClientsService implements CanActivate {
    constructor(private _utilsService: UtilsService, private router: Router) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this._utilsService.isClientUser()) {
            return true;
        }
        this._utilsService.openSnackBar('No tiene permisos suficientes', 'error');
        this.router.navigate(['/login']);
        return false;
    }
}

/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import {
    getRadiusModuleExcelFieldName,
    getRadiusModuleDisplayColumns,
    getRadiusModuleField,
    getRadiusModuleFieldType,
    RadiusModule,
    getRadiusModuleExcelExportColumns,
} from 'src/app/interfaces/radius-module';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { WindowRefService } from 'src/app/services/window-ref.service';
import * as XLSX from 'xlsx';
import { MySqlService } from '../../../services/mysql.service';
import { MatTableDataSource } from '@angular/material/table';
import { faInbox } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-radius-modules',
    templateUrl: './radius-modules.component.html',
    styleUrls: ['./radius-modules.component.scss'],
})
export class RadiusModulesComponent implements OnInit {
    loading: boolean = true;
    radiusModules: RadiusModule[] = [];
    dataSource: MatTableDataSource<RadiusModule> = new MatTableDataSource();

    length = 0; //task count in current table
    pageSize = 50; //limit of query
    scrollOffset: number = 50;
    rowsLimit: number = 50;
    lastPageIndex = 0;
    pageSizeOptions: number[] = [10, 50, 100, 200, 500];

    tableName: string = 'radiusModules';
    displayedColumns: string[] = [];
    fixedColumns = [];
    displayedColumnsField: string[];
    clickedRows = new Set<RadiusModule>();

    lastSelectedRow: number = -1;

    filteredColumn?: string;
    orderedColumn?: string;

    serial_number?: string;

    loadingText = 'Cargando...';

    faInbox = faInbox;

    async onScroll() {
        this.scrollOffset += this.rowsLimit;
        if (this.scrollOffset > this.pageSize) return;
        this.dataSource.data = [];
        this.dataSource.data = this.radiusModules.slice(0, this.scrollOffset);
    }

    constructor(
        private _apiService: ApiService,
        private _mySqlService: MySqlService,
        private _utilsService: UtilsService,
        private _windowRefService: WindowRefService,
        private router: Router,
        private route: ActivatedRoute,
        private _spinner: NgxSpinnerService
    ) {
        this.route.params.subscribe((params) => {
          this.serial_number = params['serial'];
        });

        this.pageSize = parseInt(
            localStorage.getItem('radiusModule_pageSize') || `${this.rowsLimit}`
        );

        this.displayedColumns = this._utilsService.setDisplayColumns(
            this.displayedColumns,
            this.tableName,
            getRadiusModuleDisplayColumns,
        );

        this.displayedColumnsField = this.displayedColumns.map((displayedColumn: string) =>
            getRadiusModuleField(displayedColumn)
        );
        document.addEventListener('visibilitychange', async () => {
            if (document.hidden) {
            } else {
                const updateNeeded = localStorage.getItem('radiusModuleUpdateNeeded');
                if (updateNeeded == 'true') {
                    this.scrollOffset = 50;
                    localStorage.setItem('radiusModuleUpdateNeeded', 'false');
                    this.showLoading(true);
                    this.setRadiusModulesInTable(await this._mySqlService.getLastRadiusModulesPage());
                    this.showLoading(false);
                }
            }
        });
    }

    async ngOnInit(): Promise<void> {
        await this.getRadiusModules();
    }

    reload() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['./'], { relativeTo: this.route });
    }

    async fileEvent(event: any) {
        if (event['file_option'] == 'Importar módulos de radio') {
            this.processExcelFile(event);
        }
    }

    async processExcelFile(event: any) {
        this._spinner.show();
        this.loadingText = `Cargando ...`;
        let workBook: XLSX.WorkBook;
        let jsonData = null;
        const reader = new FileReader();
        const file = event['file'].target.files[0];
        reader.onload = async (_) => {
            this._spinner.show();
            this.loadingText = `Procesando módulos de radio ...`;
            const data = reader.result;
            workBook = XLSX.read(data, { type: 'binary' });
            jsonData = workBook.SheetNames.reduce((initial: any, name) => {
                const sheet = workBook.Sheets[name];
                initial[name] = XLSX.utils.sheet_to_json(sheet);
                return initial;
            }, {});
            let sheets: string[] = [];
            let arrayRadiusModules: RadiusModule[] = [];

            Object.keys(jsonData).forEach((key: string) => sheets.push(key));
            for (let sheet of sheets) {
                for (let jsonRadiusModule of jsonData[sheet]) {
                    let radiusModuleData: any = {};
                    Object.keys(jsonRadiusModule).forEach((key) => {
                        let field: string = getRadiusModuleExcelFieldName(key);
                        if (this._utilsService.isFieldValid(field)) {
                            this._utilsService.setJsonFieldData(
                                jsonRadiusModule, 
                                radiusModuleData, 
                                key, 
                                field, 
                                getRadiusModuleFieldType
                            );
                        }
                    });
                    let radiusModule = radiusModuleData as RadiusModule;
                    if (radiusModule && radiusModule.deveui) arrayRadiusModules.push(this.addCompanyAndManger(radiusModule));
                }
            }
            const result = await this.importRadiusModules(arrayRadiusModules);
            this._spinner.hide();
        };
        reader.readAsBinaryString(file);

        this._spinner.hide();
    }

    addCompanyAndManger(radiusModule: RadiusModule){
        const companyId = localStorage.getItem('company');
        const managerId = localStorage.getItem('manager');
        radiusModule.company = parseInt(companyId!);
        radiusModule.manager = parseInt(managerId!);
        return radiusModule;
    }

    async importRadiusModules(radiusModules: RadiusModule[]) {
        this._spinner.show();
        this.loadingText = `Añadiendo módulos de radio ...`;
        let i = 0,
            errorIds = [];
        for (let radiusModule of radiusModules) {
            this.loadingText = `Añadiendo módulos de radio ${++i} de ${radiusModules.length}`;
            if (!(await this._apiService.addDocument('radius-module', radiusModule))) {
                errorIds.push(radiusModule.radius_module_hrid);
            }
        }
        this._spinner.hide();
        if (errorIds.length > 0) {
            this._utilsService.openSnackBar(`Hubo errores añadiendo módulos de radio`, 'error');
        } else {
            this._utilsService.openSnackBar(`módulos de radio subidos correctamente`);
        }
        await this.getRadiusModules();
        return !(errorIds.length > 0);
    }

    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this._spinner.show('innerSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this._spinner.hide('innerSpinner');
        }
    }

    setRadiusModulesInTable(radiusModules: RadiusModule[]) {
        this.radiusModules = radiusModules;
        this.dataSource.data = [];
        for (let [index, radiusModules] of this.radiusModules.entries()) {
            radiusModules.ID = index + 1;
        }
        this.dataSource.data = this.radiusModules.slice(0, this.rowsLimit);
        this.showLoading(false);
    }

    async getRadiusModules() {
        if (!this.serial_number) this.setRadiusModulesInTable(await this.selectRadiusModules());
        else await this.applyFilter([this.serial_number], 'meter_serial_number');
    }

    async selectRadiusModules(status?: string) {
        this.showLoading(true);
        let where = '';
        this.length = await this._mySqlService.getRadiusModulesCount(where);
        const order = this._utilsService.orderRadiusModules;
        let order_clause = undefined;
        if (order.length > 0) {
            order_clause = this._utilsService.getOrderClauseFromOrder(order);
        }
        return await this._mySqlService.getRadiusModules(
            undefined,
            /*where*/ undefined,
            /*order_clause*/ undefined,
            '0',
            this.pageSize.toString()
        );
    }

    searchValue(event: any) {
        const filterValue: string = event;
        this.dataSource!.filter = filterValue.trim().toLowerCase();
    }

    openMaps() {}

    async exportRadiusModulesInTable() {
        let result: boolean = false;
        if (this.clickedRows.size) {
            result = await this._utilsService.openQuestionDialog(
                'Seleccione',
                '¿Exportar solo las módulos de radio seleccionadas?',
                'Seleccionadas',
                'Todas'
            );
        }
        if (result) this.exportExcel(this.clickedRows);
        else await this.exportToExcelInFilter();
    }

    async exportToExcelInFilter(){
        this.loadingText = `Descargando para exportación ...`;
        this._spinner.show();
        const savedOffset = this._mySqlService.last_radiusModule_offset;
        let radiusModules: RadiusModule[] = [];
        const filter = this._utilsService.filterRadiusModules;
        let where_clause = undefined;
        if (filter && filter.fields) {
            where_clause = this._utilsService.getWhereClauseFromFilter(filter);
        }
        const count = await this._mySqlService.getRadiusModulesCount(where_clause);
        const order = this._utilsService.orderRadiusModules;
        let order_clause = undefined;
        if (order.length > 0) order_clause = this._utilsService.getOrderClauseFromOrder(order);
        let limit: number = 1000;
        for (let offset = 0; offset < count; offset += limit) {
            this.loadingText = `Descargando para exportación (${offset} de ${count}) ...`;
            const serverRadiusModules = await this._mySqlService.getRadiusModules(
                undefined,
                where_clause,
                order_clause,
                offset.toString(),
                limit.toString()
            );
            radiusModules = radiusModules.concat(serverRadiusModules);
        }
        this._mySqlService.last_radiusModule_offset = savedOffset;
        this.exportExcel(radiusModules);
        this._spinner.hide();
    }

    exportExcel(RadiusModules: any) {
        let excelFormatTasks = [];
        for (let radiusModule of RadiusModules) {
            let data: any = {};
            const columns = getRadiusModuleExcelExportColumns();
            columns.forEach((column) => {
                data[`${column}`] = this._utilsService.tableDataPipe(
                    radiusModule[getRadiusModuleExcelFieldName(column)]
                );
            });
            excelFormatTasks.push(data);
        }
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelFormatTasks);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        /* save to file */
        XLSX.writeFile(wb, 'módulos de radio_Exportadas.xlsx');
    }

    async deleteRadiusModules() {
        let errorIds = [];
        if (this.clickedRows.size == 0) {
            this._utilsService.openSnackBar('Debe seleccionar al menos una radiusModule', 'warning');
            return;
        }
        const result = await this._utilsService.openQuestionDialog(
            'Confirmación',
            '¿Desea eliminar los módulos de radio seleccionados?'
        );
        if (result) {
            this._spinner.show();
            this.loadingText = `Eliminando radiusModules ...`;
            let rowIndexes = new Set<number>();
            const oldDataSource = this.radiusModules;
            this.clickedRows.forEach(async (row) => {
                console.log(row.id);

                const index = this.radiusModules.indexOf(row, 0);
                if (index > -1) {
                    rowIndexes.add(index);
                }
            });
            let radiusModules = [];
            let deleteCount = 0;
            for (let i = 0; i < oldDataSource.length; i++) {
                if (!rowIndexes.has(i)) {
                    radiusModules.push(oldDataSource![i]);
                } else {
                    this.loadingText = `Eliminando radiusModules ${++deleteCount} de ${
                        rowIndexes.size
                    }`;
                    if (
                        !(await this._apiService.deleteDocument(
                            'radius-module',
                            oldDataSource[i].id!.toString()
                        ))
                    ) {
                        console.log('****************** deleteRadiusModule error *************');
                        errorIds.push(oldDataSource[i].id);
                    } 
                }
            }
            if (errorIds.length > 0) {
                this._utilsService.openSnackBar(`Hubo errores eliminando radiusModules`, 'error');
            } else {
                this._utilsService.openSnackBar(`Tareas eliminadas correctamente`);
            }
            this.length -= rowIndexes.size;
            this.setRadiusModulesInTable(radiusModules);
            this.clickedRows.clear();
            this._spinner.hide();
        }
    }

    async selectAll() {
        this.clickedRows.clear();
        const radiusModules = this.radiusModules;
        for (const radiusModule of radiusModules) {
            if (!this.clickedRows.has(radiusModule)) {
                this.clickedRows.add(radiusModule);
            }
        }
        this._utilsService.openSnackBar(`Seleccionadas ${this.clickedRows.size} módulos de radio`);
    }

    async pageEvent(event: any) {
        console.log(`length ${event.length}`);
        console.log(`pageSize ${event.pageSize}`);
        console.log(`pageIndex ${event.pageIndex}`);
        if (this.lastPageIndex != event.pageIndex) {
            this.showLoading(true);
            if (this.lastPageIndex < event.pageIndex) {
                this.setRadiusModulesInTable(
                    await this._mySqlService.getNextRadiusModulesPage(
                        event.pageIndex - this.lastPageIndex
                    )
                );
            } else {
                this.setRadiusModulesInTable(
                    await this._mySqlService.getPreviousRadiusModulesPage(
                        this.lastPageIndex - event.pageIndex
                    )
                );
            }
            this.lastPageIndex = event.pageIndex;
        }
        if (this.pageSize != event.pageSize) {
            this.pageSize = event.pageSize;
            localStorage.setItem('radiusModule_pageSize', this.pageSize.toString());
            await this.getRadiusModules();
        }
        this.scrollOffset = 50;
    }

    async filterRadiusModule(where?: string) {
        // console.log('************** filterRadiusModule ***************');
        this.length = await this._mySqlService.getRadiusModulesCount(where);
        const order = this._utilsService.orderRadiusModules;
        let order_clause = undefined;
        if (order.length > 0) {
            order_clause = this._utilsService.getOrderClauseFromOrder(order);
        }
        return await this._mySqlService.getRadiusModules(
            undefined,
            where,
            order_clause,
            '0',
            this.pageSize.toString()
        );
    }

    async applyFilter(values: any, column: string, not_empty: boolean = false, empties_checked: boolean = false) {
        const where_clause = this._utilsService.getWhereClauseFromFilter(
            this._utilsService.processFilter(
                this._utilsService.filterRadiusModules!,
                values,
                column,
                getRadiusModuleFieldType(column),
                this._mySqlService.radiusModulesTableName,
                true,
                not_empty,
                empties_checked
            )
        );
        this.showLoading(true);
        this.lastPageIndex = 0;
        this.setRadiusModulesInTable(await this.filterRadiusModule(where_clause));
    }

    async filterBy(column: string) {
        this.filteredColumn = getRadiusModuleField(column);
        if (getRadiusModuleFieldType(this.filteredColumn) == 'Date') {
            const dates = await this._utilsService.openDateRangeSelectorDialog(
                'Seleccione rango de fechas'
            );
            let times: Date[];
            try {
                times = await this._utilsService.openTimeRangeSelectorDialog(
                    'Seleccione rango de horas'
                );
                this.onDateSelected(dates, times);
            } catch (err) {
                this.onDateSelected(dates);
            }
        } else {
            const result = await this._utilsService.openFilterDialog(
                column,
                this.filteredColumn,
                this._mySqlService.radiusModulesTableName,
                this._utilsService.filterRadiusModules
            );
            if (result && result.data) {
                 this.applyFilter(result.data, result.column, result.not_empty, result.empties_checked);
            }
        }
    }
    async onDateSelected(dateRange: Date[], timeRange?: Date[]) {
        if (dateRange) {
            const values = this._utilsService.getDateRangeString(dateRange, timeRange);
            await this.applyFilter(values, this.filteredColumn!);
        } else {
            this._utilsService.openSnackBar('Rango fechas inválido', 'error');
        }
    }

    async orderBy(event: any) {
        const column = event.column;
        const orderType = event.orderType;

        const orderedColumn = getRadiusModuleField(column);
        const order_clause = this._utilsService.getOrderClauseFromOrder(
            this._utilsService.processOrder(
                this._mySqlService.radiusModulesTableName,
                this._utilsService.orderRadiusModules,
                orderedColumn,
                orderType
            )
        );
        const filter = this._utilsService.filterRadiusModules;
        let where_clause = undefined;
        if (filter && filter.fields) {
            where_clause = this._utilsService.getWhereClauseFromFilter(filter);
        }
        this.showLoading(true);
        const radiusModules = await this._mySqlService.getRadiusModules(
            undefined,
            where_clause,
            order_clause,
            '0',
            this.pageSize.toString()
        );
        this.lastPageIndex = 0;
        this.setRadiusModulesInTable(radiusModules);
    }

    async addNewRow(event: any) {
        console.log('**************** addNewRow ***************');
        const radiusModule = await this._utilsService.openRadiusModuleDialog('');
        console.log(radiusModule);
        if (radiusModule) {
            this.radiusModules.push(radiusModule);
            this.dataSource.data = [];
            this.dataSource.data = this.radiusModules.slice(0, this.scrollOffset);
        }
    }
    async doubleClickedRow(row: any) {
        let radiusModule = await this._utilsService.openRadiusModuleDialog(row.id);

        if (radiusModule) {
            radiusModule.ID = this.radiusModules[row.rowIndex].ID;
            this.radiusModules[row.rowIndex] = radiusModule;
            this.dataSource.data = [];
            this.dataSource.data = this.radiusModules.slice(0, this.scrollOffset);
        }
    }

    clickedRow(receivedEvent: any) {
        const row = receivedEvent.row;
        const event = receivedEvent.event;
        const rowIndex = receivedEvent.rowIndex;

        const previousRow = this.lastSelectedRow;
        this.lastSelectedRow = rowIndex;

        if (event.button === 0) {
            if (!event.ctrlKey && !event.shiftKey) {
                this.clickedRows.clear();
                this.toggleRow(row);
                // console.log('**************** !event.ctrlKey && !event.shiftKey ***************');
            } else if (event.ctrlKey) {
                this.toggleRow(row);
                // console.log('**************** event.ctrlKey ***************');
            }
            if (event.shiftKey) {
                this.selectRowsBetweenIndexes(previousRow, rowIndex);
                console.log('**************** event.shiftKey ***************');
            }
        }
        if (this._windowRefService.nativeWindow.getSelection) {
            //remove selection in table with shift
            if (this._windowRefService.nativeWindow.getSelection().empty) {
                // Chrome
                this._windowRefService.nativeWindow.getSelection().empty();
            } else if (this._windowRefService.nativeWindow.getSelection().removeAllRanges) {
                // Firefox
                this._windowRefService.nativeWindow.getSelection().removeAllRanges();
            }
        }
    }
    selectRowsBetweenIndexes(lastSelectedRow: number, rowIndex: number) {
        let start, end;
        if (rowIndex > lastSelectedRow) {
            start = lastSelectedRow;
            end = rowIndex;
        } else {
            end = lastSelectedRow;
            start = rowIndex;
        }
        for (let i = start; i <= end; i++) {
            this.clickedRows.add(this.radiusModules[i]);
        }
    }
    toggleRow(row: any) {
        if (this.clickedRows.has(row)) {
            this.clickedRows.delete(row);
        } else {
            this.clickedRows.add(row);
        }
    }

    async openSettings() {
        await this._utilsService.openFilterConfigurationDialog(
            this._mySqlService.radiusModulesTableName,
            this._utilsService.filterRadiusModules
        );
    }
}

import { FormControl, FormGroup } from '@angular/forms';
import { MyLatLng } from './lat-lng';

export interface Zone {
    codigo_zona?: string;
    date_time_modified?: Date;
    bloque?: string;
    geolocalizacion?: MyLatLng;
    id?: number;
    company?: number;
    zona?: string;
}

export function getZoneDisplayColumns(): string[] {
    const displayedColumns: string[] = [
        'Código sector',
        'Sector P',
        'Bloque',
        'Fecha de Modificación',
    ];
    return displayedColumns;
}

export function getZoneField(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'Código sector':
            value = 'codigo_zona';
            break;
        case 'Sector P':
            value = 'zona';
            break;
        case 'Bloque':
            value = 'bloque';
            break;
        case 'Fecha de Modificación':
            value = 'date_time_modified';
            break;
    }
    return value;
}

export function getZoneFieldName(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'codigo_zona':
            value = 'Código sector';
            break;
        case 'zona':
            value = 'Sector P';
            break;
        case 'bloque':
            value = 'Bloque';
            break;
        case 'date_time_modified':
            value = 'Fecha de Modificación';
            break;
    }
    return value;
}

export function getZoneFormControls(): FormGroup {
    const zoneFormData: FormGroup = new FormGroup({
        zona: new FormControl(),
        codigo_zona: new FormControl(),
        date_time_modified: new FormControl(),
        bloque: new FormControl(),
        geolocalizacion: new FormControl(),
        company: new FormControl(),
        id: new FormControl(),
    });
    return zoneFormData;
}

export function getZoneFieldType(field_name: string): string {
    //in bigquery
    let value: string = '';
    switch (field_name) {
        case 'codigo_zona':
            value = 'string';
            break;
        case 'zona':
            value = 'string';
            break;
        case 'date_time_modified':
            value = 'Date';
            break;
        case 'bloque':
            value = 'string';
            break;
        case 'dia_predeterminado':
            value = 'string';
            break;
        case 'id':
            value = 'number';
            break;
    }
    return value;
}

export function getZoneExcelFieldName(excel_field: string): string {
    let value: string = '';
    switch (excel_field.trim().toLowerCase()) {
        case 'zona':
            value = 'zona';
            break;
        case 'código sector':
            value = 'codigo_zona';
            break;
        case 'codigo sector':
            value = 'codigo_zona';
            break;
        case 'bloque':
            value = 'bloque';
            break;
        case 'fecha de modificación':
            value = 'date_time_modified';
            break;
        case 'fecha de modificacion':
            value = 'date_time_modified';
            break;
        case 'codigo_zona':
            value = 'codigo_zona';
            break;
        case 'date_time_modified':
            value = 'date_time_modified';
            break;
        case 'geolocalizacion':
            value = 'geolocalizacion';
            break;
        case 'company':
            value = 'company';
            break;
        case 'dia_predeterminado':
            value = 'bloque';
            break;
    }
    return value;
}

export function getZoneExcelExportColumns(): string[] {
    const columns = ['Código sector', 'Sector P', 'Bloque', 'Fecha de Modificación'];
    return columns;
}

import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '../services/utils.service';

@Pipe({
    name: 'incidence'
})
export class IncidencePipe implements PipeTransform {

    constructor(private _utilsService: UtilsService){
    
    }
    transform(value: any): string {
        return this._utilsService.getIncidenceFromBoolean(value);
    }
}

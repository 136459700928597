import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { UtilsService } from '../../../services/utils.service';
import { getFieldType, WaterTask, getField, task_status } from '../../../interfaces/water-task';
import { NgxSpinnerService } from 'ngx-spinner';
import { MySqlService } from '../../../services/mysql.service';
import { IconDefinition, faFilter, faInbox, faSortAmountDown, faSortAmountUpAlt } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { IpcService } from '../../../services/ipc.service';
import { WindowRefService } from '../../../services/window-ref.service';
import { ApiService } from '../../../services/api.service';
import { MiRutaUser } from '../../../interfaces/mi-ruta-user';
import { deleteFilterField } from '../../../interfaces/mi-ruta-filter';
import { Itac } from '../../../interfaces/itac';
import { DateStatus } from 'src/app/interfaces/date-status';
import { FormGroup, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { Location } from '@angular/common';

@Component({
    selector: 'app-calendar-table',
    templateUrl: './calendar-table.component.html',
    styleUrls: ['./calendar-table.component.scss']
})
/**
 * @class
 * Represents the CalendarTableComponent class.
 * This component is responsible for displaying a calendar table with tasks.
 */
export class CalendarTableComponent implements OnInit {
        
    settingsGroup: FormGroup = new FormGroup({
        columnsShiftedEnable: new FormControl(),
        date: new FormControl(),
        view: new FormControl(),
        username: new FormControl(),
    });
    
    screenWidth: number = window.innerWidth;
    faFilter: IconDefinition = faFilter;
    faSortAmountDown: IconDefinition = faSortAmountDown;
    faSortAmountUpAlt: IconDefinition = faSortAmountUpAlt;
    faInbox: IconDefinition = faInbox;
    tableName: string = 'calendar-table';

    menuOptions: string[] = [];

    filteredColumn?: string;
    orderedColumn?: string;

    dataSource: MatTableDataSource<any> = new MatTableDataSource();
    taskColumns: string[] = [
        'OPERARIO',
        'MUNICIPIO',
        'CALLE',
        'PORTAL',
        'BIS',
        'PISO',
        'MANO',
        // 'C.EMPLAZAMIENTO',
        // 'ABONADO',
        // 'CITA',
        // 'FIN DE CITA',
    ];
    displayedColumns: string[] = [];
    displayedColumnsField: any[] = [];

    minHour = 8;
    maxHour = 18;

    hoursList: string[];

    clickedRows = new Set<WaterTask>();
    loading = false;
    waterTasks: WaterTask[] = [];

    length = 0; //task count in current table
    pageSize = 50; //limit of query
    scrollOffset: number = 50;
    rowsLimit: number = 50;
    lastPageIndex = 0;
    pageSizeOptions: number[] = [10, 20, 30, 50, 100, 200, 500];

    loadingText = 'Cargando...';
    
    lastSelectedIndex = -1;
    lastSelectedRow: number = -1;

    allSelected = false;

    usernames: string[] = ['Todos'];
    dateStatuses: DateStatus[] = [];
    dayAvailability: any = {};

    timerColumnsShifted: any;

    /**
     * Constructs a new instance of the CalendarTableComponent.
     * @param _utilsService - The UtilsService instance.
     * @param _mySqlService - The MySqlService instance.
     * @param _spinner - The NgxSpinnerService instance.
     * @param router - The Router instance.
     * @param route - The ActivatedRoute instance.
     * @param electronService - The IpcService instance.
     * @param windowRefService - The WindowRefService instance.
     * @param _apiService - The ApiService instance.
     */
    constructor(
        public _utilsService: UtilsService, 
        private _mySqlService: MySqlService, 
        private _spinner: NgxSpinnerService,
        private router: Router,
        private route: ActivatedRoute,
        public electronService: IpcService,
        public location: Location,
        private windowRefService: WindowRefService,
        private _apiService: ApiService,

    ) { 
        this.setControls();
        this.hoursList = this.setHours(this.minHour, 0, this.maxHour);
        this.displayedColumns = this.taskColumns.concat(this.hoursList);
        this.displayedColumnsField = this.taskColumns.map((column) => getField(column)).concat(this.hoursList);
        this.checkIfUpdateViewNeeded();
        this.setMenuOptions();
        this.setUsers();
    }

    /**
     * @brief Initializes the component.
     * This method is called after the component's data-bound properties have been initialized.
     * It is a lifecycle hook that is called once after the first ngOnChanges.
     */
    async ngOnInit(): Promise<void> {
        this.dateStatuses = await this._apiService.getDateStatuses();
    }

    /**
     * Lifecycle hook that is called when the component is destroyed.
     * It clears the interval for shifting columns in the calendar table.
     */
    ngOnDestroy(): void {
        clearInterval(this.timerColumnsShifted);
    }

    /**
     * @brief Initializes the selection process.
     * Retrieves the last selected date from the session storage and sets it as the selected date.
     * Calls the `onDateSelected` method with the selected date.
     */
    async initialSelect(){
        this.setDefaultOrder();
        try {
            const calendarTableSelectedDate = sessionStorage.getItem('calendarTableSelectedDate');
            if(calendarTableSelectedDate) {
                const date = new Date(calendarTableSelectedDate);
                this.settingsGroup.controls['date'].setValue(moment(date));
            }
            else this.settingsGroup.controls['date'].setValue(moment().startOf('day'));
            
            const calendarTableSelectedShift = sessionStorage.getItem('calendarTableSelectedShift');
            if(calendarTableSelectedShift) {
                const enable = calendarTableSelectedShift == 'true' ? true : false;
                this.setShiftColumnsTimeout(enable);
                this.settingsGroup.controls['columnsShiftedEnable'].setValue(enable);
            }
        } catch (err) {}
        // await this.getDaysAvailability();
    }

    /**
     * Sets up the controls for the calendar table component.
     * This method initializes the default values for the controls and subscribes to their value changes.
     */
    setControls() {
        this.settingsGroup.controls['columnsShiftedEnable'].setValue(true);
        this.settingsGroup.controls['date'].setValue(moment().startOf('day'));
        this.settingsGroup.controls['view'].setValue('daily');
        this.settingsGroup.controls['username'].setValue('Todos');

        this.settingsGroup.controls['columnsShiftedEnable'].valueChanges.subscribe(async (value: boolean) => {
            this.setShiftColumnsTimeout(value);
        });
        this.settingsGroup.controls['date'].valueChanges.subscribe(async (value: moment.Moment) => {
            this.onDateChange(value);
        });
        this.settingsGroup.controls['view'].valueChanges.subscribe(async (value: string) => {
            this.onViewChange(value);
        });
        this.settingsGroup.controls['username'].valueChanges.subscribe(async (value: string) => {
            this.onUsernameChange(value);
        });
    }
    
    /**
     * Updates the hours list based on the given time.
     * @param time - The moment object representing the current time.
     */
    updateHourslist(time: moment.Moment): void {
        const currentHour = time.hour();
        if(this.minHour <= currentHour && currentHour < this.maxHour) {
            const currentMinute = Math.floor(time.minute() / 20) * 20;
            this.hoursList = this.setHours(currentHour, currentMinute, this.maxHour);
        }
        else this.hoursList = this.setHours(this.minHour, 0, this.maxHour);

        this.displayedColumns = this.taskColumns.concat(this.hoursList);
        this.displayedColumnsField = this.taskColumns.map((column) => getField(column)).concat(this.hoursList);
    }

    /**
     * Generates a list of time slots in the format "HH:mm-HH:mm" based on the given parameters.
     * 
     * @param initHour - The initial hour of the time slots.
     * @param initMinute - The initial minute of the time slots.
     * @param maxHour - The maximum hour of the time slots.
     * @returns An array of strings representing the time slots.
     */
    setHours(initHour: number, initMinute: number, maxHour: number): string[] {
        const hoursList: string[] = [];
        for(let i = initHour; i < maxHour; i++) {
            for(let j = (i === initHour ? initMinute : 0); j < 60; j += 20) {
                const startHour = i.toString().padStart(2, '0');
                const startMinute = j.toString().padStart(2, '0');
                const endHour = (j + 20 >= 60) ? (i + 1).toString().padStart(2, '0') : startHour;
                const endMinute = ((j + 20) % 60).toString().padStart(2, '0');
                hoursList.push(`${startHour}:${startMinute}-${endHour}:${endMinute}`);
            }
        }
        return hoursList;
    }

    /**
     * Sets a timeout for shifting columns in the calendar table.
     */
    setShiftColumnsTimeout(value: boolean): void {
        if(value) {
            if(this.timerColumnsShifted) return;
            this.timerColumnsShifted = setInterval(async () => {
                if (this.isComponentActive()) {
                    if(this.settingsGroup.controls['columnsShiftedEnable'].value) {
                        const date = this.settingsGroup.controls['date'].value; 
                        this.onDateChange(date);
                    }
                }
            }, 20 * 60 * 1000); //20 min
        }
        else clearInterval(this.timerColumnsShifted);

        const date = this.settingsGroup.controls['date'].value; 
        this.onDateChange(date);
    }

    /**
     * Checks if the component is active.
     * 
     * @param component - The component to check.
     * @returns A boolean indicating whether the component is active.
     */
    isComponentActive(): boolean {
        const currentUrl = this.router.url;
        if(currentUrl.includes('/calendar-table')) return true;
        return false;
    }

    /**
     * @brief Sets the users for the calendar table.
     * 
     * @returns A promise that resolves when the users are set.
     */
    async setUsers(): Promise<void> {
        const company = localStorage.getItem('company');
        let users = await this._apiService.getUsers(['operario']);
        users = users.filter((user) => this._utilsService.checkCompany(user.companies, company || ''));
        const names = users.map((user: MiRutaUser) => this._utilsService.userPipe(user.id));
        this.usernames = ['Todos', ...names];
    }

    /**
     * @brief Sets the menu options for the calendar table.
     * 
     * @remarks
     * This method initializes the `menuOptions` property with an array of strings representing the available menu options.
     */
    setMenuOptions(): void {
        this.menuOptions = [
            'Mostrar en Mapa',
            'Modificar cita',
            'Asignar a operario',
            'Ver semana de operario',
        ];
    }

    /**
     * @brief Updates the view of the calendar table.
     * 
     * This method clears the data source and water tasks, sets the scroll offset to 50,
     * and shows the loading indicator. It then retrieves the tasks count using the
     * `_mySqlService.getTasksCount` method and sets the length property accordingly.
     * After that, it retrieves the last tasks page using the `_mySqlService.getLastTasksPage`
     * method and sets the tasks in the table using the `setTasksInTable` method. Finally,
     * it hides the loading indicator.
     * 
     * @return A promise that resolves when the view is updated.
     */
    async updateView(): Promise<void> {
        this.dataSource.data = [];
        this.waterTasks = [];
        this.scrollOffset = 50;
        this.showLoading(true);
        const { waterTasks, count } = await this._mySqlService.getLastTasksPage();
        this.length = count;
        this.setTasksInTable(waterTasks);
        this.showLoading(false);
    }

    /**
     * Checks if an update to the view is needed when the visibility of the document changes.
     */
    checkIfUpdateViewNeeded(): void {
        document.addEventListener('visibilitychange', async () => {
            if (!document.hidden) { //isShown
                const updateNeeded = localStorage.getItem('taskUpdateNeeded');
                if (updateNeeded == 'true') {
                    localStorage.setItem('taskUpdateNeeded', 'false');
                    if(this.electronService.isElectronApp()) await this.updateView();
                    else this.reload();
                }
            }
        });
    }

    /**
     * Handles the event when a date is selected.
     * @param dateRange - An array of selected dates.
     * @param timeRange - An optional array of selected times.
     * @returns A Promise that resolves when the filter is applied.
     */
    async onDateSelected(dateRange: Date[], timeRange?: Date[]) {
        if (dateRange) {
            this.addIdleStatus();
            const values = this._utilsService.getDateRangeString(dateRange, timeRange);
            await this.applyFilter(values, 'fecha_hora_cita_end');
        } else {
            this._utilsService.openSnackBar('Rango fechas inválido', 'error');
        }
    }

    /**
     * Adds idle status to the filter tasks.
     */
    addIdleStatus(): void {
        const column = 'status_tarea';
        deleteFilterField(this._utilsService.filterTasks!, column);
        this._utilsService.processFilter(
            this._utilsService.filterTasks!,
            [task_status.IDLE],
            column,
            getFieldType(column),
            this._mySqlService.tasksTableName,
            true,
        );
    }

    /**
     * Applies a filter to the tasks in the calendar table.
     * 
     * @param values - The filter values.
     * @param column - The column to filter on.
     * @param not_empty - Indicates whether to filter for non-empty values. Default is `false`.
     * @param empties_checked - Indicates whether to include empty values in the filter. Default is `false`.
     * @param custom_join_json - Custom join JSON for the filter. Default is `null`.
     */
    async applyFilter(
        values: any,
        column: string,
        not_empty: boolean = false,
        empties_checked: boolean = false,
        custom_join_json: any = null
    ) {
        this._utilsService.processFilter(
            this._utilsService.filterTasks!,
            values,
            column,
            getFieldType(column),
            this._mySqlService.tasksTableName,
            true,
            not_empty,
            empties_checked,
            custom_join_json
        );
        await this.getTasks();
    }

    /**
     * @brief Retrieves tasks based on the filter conditions and sets them in the table.
     * 
     * @returns {Promise<void>} A promise that resolves when the tasks are retrieved and set in the table.
     */
    async getTasks(): Promise<void> {
        let where_clause = this._utilsService.getTasksFilterWhereString();
        this.lastPageIndex = 0;
        localStorage.setItem('lastPageCalendarIndex', '');
        this.showLoading(true);
        this.setTasksInTable(await this.filterTask(where_clause));
    }

    /**
     * @brief Handles the event when the date is changed.
     * 
     * @param event - The event object containing the new date value.
     * @returns A Promise that resolves when the date change is handled.
     */
    async onDateChange(selectedDate: moment.Moment): Promise<void> {
        const date = selectedDate.toDate();
        sessionStorage.setItem('calendarTableSelectedDate', date.toString());
        const enable = this.settingsGroup.controls['columnsShiftedEnable'].value;

        if(enable && selectedDate.isSame(moment(), 'day')) {
            await this.onDateSelectedToday(selectedDate);
        } 
        else {
            const time = moment().set({ hour: this.minHour, minute:0 });
            this.updateHourslist(time);
            await this.onDateSelected([date]);
        }
    }

    /**
     * Handles the event when a date is selected today.
     * 
     * @param selectedDate - The selected date as a moment.Moment object.
     */
    async onDateSelectedToday(selectedDate: moment.Moment) {
        const date = selectedDate.toDate();
        const time = moment().add(1, 'minutes');
        // const time = moment().set({ hour:9, minute:41 }); //? only for testing
        
        let startTime: moment.Moment;
        if(time.hour() < this.maxHour) startTime = time.clone();
        else startTime = selectedDate.clone().startOf('day');
        this.updateHourslist(time);
        const endTime = selectedDate.clone().endOf('day');
        await this.onDateSelected([date], [startTime.toDate(), endTime.toDate()]);
    }

    /**
     * @brief Handles the change event of the view selection.
     * 
     * @param event - The event object containing the new value of the selection.
     */
    async onViewChange(view: string): Promise<void> {
        if(view == 'weekly') {
            const user = await this._apiService.selectUserForWeekCalendar();
            const date = this.settingsGroup.controls['date'].value;
            sessionStorage.setItem('calendarSelectedDate', date.toString());
            if(user) this.router.navigate(['/calendar']);
            else this.settingsGroup.controls['view'].setValue('daily');
        }
    }

    /**
     * @brief Sets the tasks in the table.
     * 
     * @param tasks - An array of WaterTask objects representing the tasks to be set in the table.
     */
    setTasksInTable(tasks: WaterTask[]) {
        this.clickedRows.clear();
        this.dataSource.data = [];
        this.waterTasks = tasks;
        for (let [index, task] of this.waterTasks.entries()) {
            task.ID = index + 1;
        }
        this.dataSource.data = this.waterTasks.slice(0, this.rowsLimit);
        this.showLoading(false);
    }

    /**
     * Sets the default order for the calendar table.
     * The default order is determined by the `_utilsService.processOrder` method,
     * which processes the order parameters and returns the order clause.
     * The order clause is then passed to the `_utilsService.getOrderClauseFromOrder` method
     * to set the default order for the tasks table in the calendar.
     */
    setDefaultOrder(): void {
        this.setOrder('OPERARIO.id','DESC');
        this.setOrder('date_status','DESC');
        this.setOrder('fecha_hora_cita','ASC');
    }

    setOrder(column: string, orderType: string): void {
        this._utilsService.processOrder(
            this._mySqlService.tasksTableName,
            this._utilsService.orderTasks,
            column,
            orderType
        );
    }

    /**
     * @brief Filters tasks based on the specified condition.
     *
     * @param where - The condition to filter tasks. (Optional)
     * @returns A promise that resolves to an array of filtered tasks.
     */
    async filterTask(where?: string): Promise<WaterTask[]> {
        const order = this._utilsService.orderTasks;
        let order_clause = undefined;
        if (order.length > 0) {
            order_clause = this._utilsService.getOrderClauseFromOrder(order);
        }
        const { waterTasks, count } = await this._mySqlService.getTasks(
            undefined,
            where,
            order_clause,
            undefined,
            '0',
            this.pageSize.toString()
        );
        this.length = count;
        return waterTasks;
    }

    /**
     * Sets the loading state and shows or hides the spinner accordingly.
     * @param state - A boolean value indicating whether to show or hide the loading spinner.
     */
    showLoading(state: boolean): void {
        this.loading = state;
        if (state) {
            this._spinner.show('innerSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this._spinner.hide('innerSpinner');
        }
    }

    /**
     * Reloads the current route.
     */
    reload(): void {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['./'], { relativeTo: this.route });
    }

    /**
     * Opens the Google Maps page and sets the current selected tasks in the session storage.
     * If tasks are provided, it filters the tasks based on their IDs and sets the filtered tasks in the session storage.
     * If no tasks are provided, it sets an empty object in the session storage.
     * It also removes the current task status and additional task status from the session storage.
     */
    openMaps(tasks?: Set<WaterTask>): void {
        let ids = [];
        if (tasks) {
            for (let waterTask of tasks) {
                ids.push(waterTask.id);
            }
            const where_clause = this._utilsService.getWhereClauseFromFilter(
                this._utilsService.processFilter(
                    this._utilsService.filterTasks!,
                    ids,
                    'id',
                    getFieldType('id'),
                    this._mySqlService.tasksTableName
                )
            );
            sessionStorage.setItem('currentWhereTasksSelected', where_clause);
        } else {
            sessionStorage.setItem('currentWhereTasksSelected', '{}');
        }
        sessionStorage.removeItem('currentTaskStatus');
        sessionStorage.removeItem('additionalTaskStatus');

        this.router.navigate(['/google_maps']);
    }

    
    /**
     * Checks if the given column is a normal filter field.
     * @param column - The name of the column to check.
     * @returns A boolean value indicating whether the column is a normal filter field.
     */
    isNormalFilterField(column: string): boolean {
        if (this._utilsService.notNormalFilterFields.includes(getField(column))) {
            return false;
        }
        return true;
    }
    

    /**
     * Filters the data based on the specified column.
     * @param column - The name of the column to filter by.
     */
    async filterBy(column: string): Promise<void> {
        this.filteredColumn = getField(column);
        if (getFieldType(this.filteredColumn) == 'Date') {
            await this.filterByDates();
        } else {
            const res = await this.openFilterDialog(column, this.filteredColumn);
            if (res && res.data){
                this.applyFilter(res.data, res.column, res.not_empty, res.empties_checked);
            }
        }
    }

    /**
     * Filters the data by selected dates and times.
     * Opens a dialog to select a date range and optionally a time range.
     * Calls the onDateSelected method with the selected dates and times.
     */
    async filterByDates(): Promise<void> {
        try {
            const dates = await this._utilsService.openDateRangeSelectorDialog(
                'Seleccione rango de fechas'
            );
            try {
                const times = await this._utilsService.openTimeRangeSelectorDialog(
                    'Seleccione rango de horas'
                );
                this.onDateSelected(dates, times);
            } catch (err) {
                this.onDateSelected(dates);
            }
        } catch (err) {}
    }

    /**
     * Opens a filter dialog for the specified column.
     * @param column_name - The name of the column.
     * @param column - The column to filter.
     * @returns A promise that resolves when the filter dialog is closed.
     */
    async openFilterDialog(column_name: string, column: string): Promise<any> {
        return await this._utilsService.openFilterDialog(
            column_name,
            column,
            this._mySqlService.tasksTableName,
            this._utilsService.filterTasks
        );
    }

    /**
     * Filters the data source based on the provided search value.
     * @param event - The search value entered by the user.
     */
    searchValue(event: any): void {
        const filterValue: string = event;
        this.dataSource!.filter = filterValue.trim().toLowerCase();
    }

    
    /**
     * Selects all the tasks in the calendar table.
     */
    async selectAll(): Promise<void> {
        this.clickedRows.clear();
        const tasks = this.waterTasks;
        for (const counter of tasks) {
            if (!this.clickedRows.has(counter)) {
                this.clickedRows.add(counter);
            }
        }
        this.allSelected = true;
        this._utilsService.openSnackBar(`Seleccionadas ${this.clickedRows.size} tareas`);
    }

    /**
     * Orders the tasks in the calendar table based on the specified column and order type.
     * 
     * @param event - The event object containing the column and order type.
     */
    async orderBy(event: any): Promise<void> {
        const column = event.column;
        const orderType = event.orderType;

        const orderedColumn = getField(column);
        const order_clause = this._utilsService.getOrderClauseFromOrder(
            this._utilsService.processOrder(
                this._mySqlService.tasksTableName,
                this._utilsService.orderTasks,
                orderedColumn,
                orderType
            )
        );

        let where_clause = this._utilsService.getTasksFilterWhereString();

        this.showLoading(true);
        const { waterTasks, count } = await this._mySqlService.getTasks(
            undefined,
            where_clause,
            order_clause,
            undefined,
            '0',
            this.pageSize.toString()
        );
        this.lastPageIndex = 0;
        localStorage.setItem('lastPageCalendarIndex', '');

        this.setTasksInTable(waterTasks);
    }

    /**
     * Handles the double click event on a row in the calendar table.
     * @param row - The clicked row object.
     */
    doubleClickedRow(row: any): void {
        localStorage.setItem('lastPageCalendarIndex', this.lastPageIndex.toString());
        if (this.electronService.isElectronApp()) this.router.navigate(['/task', row.id]);
        else {
            const url = this.router.serializeUrl(this.router.createUrlTree(['/task', row.id]));
            window.open(url, '_blank');
        }
    }

    /**
     * Handles the click event on a row in the calendar table.
     * @param receivedEvent - The event object containing information about the clicked row.
     */
    clickedRow(receivedEvent: any): void {
        const row = receivedEvent.row;
        const event = receivedEvent.event;
        const rowIndex = receivedEvent.rowIndex;
        const previousRow = this.lastSelectedRow;
        this.lastSelectedRow = rowIndex;

        if (event.button === 0) {
            if (!event.ctrlKey && !event.shiftKey) {
                this.allSelected = false;
                this.clickedRows.clear();
                this.toggleRow(row);
            } 
            else if (event.ctrlKey) this.toggleRow(row);
            if (event.shiftKey) this.selectRowsBetweenIndexes(previousRow, rowIndex);
        }
        if (this.windowRefService.nativeWindow.getSelection) {
            //remove selection in table with shift
            if (this.windowRefService.nativeWindow.getSelection().empty) {
                // Chrome
                this.windowRefService.nativeWindow.getSelection().empty();
            } else if (this.windowRefService.nativeWindow.getSelection().removeAllRanges) {
                // Firefox
                this.windowRefService.nativeWindow.getSelection().removeAllRanges();
            }
        }
    }

    /**
     * Handles the selection of a menu option in the calendar table.
     * @param option - The selected menu option.
     * @returns A promise that resolves when the menu option is processed.
     */
    async onMenuOptionSelected(option: string): Promise<void> {
        if (option == 'Mostrar en Mapa') this.openMaps(this.clickedRows);
        else if (option == 'Modificar cita') await this.assignDateToTask();
        else if (option == 'Asignar a operario') await this.assignOperatorToTasks();
        else if (option == 'Ver semana de operario') await this.openWeek();
        
    }

    /**
     * Opens the week calendar for the selected task.
     * If multiple users are assigned to the task, prompts the user to select one.
     * Navigates to the calendar page with the selected date and user.
     * 
     * @returns A promise that resolves when the week calendar is opened.
     */
    async openWeek(): Promise<void> {
        if (this.nothingSelectedWarning()) return;
        const firstTask = Array.from(this.clickedRows)[0];
        const currUsers = firstTask.OPERARIO;
        if(currUsers && currUsers.length) {
            let user: MiRutaUser | null;
            if(currUsers.length == 1) user = currUsers[0];
            else user = await this._apiService.selectUserForWeekCalendar(currUsers);
            if(user) {
                const date = this.settingsGroup.controls['date'].value;
                sessionStorage.setItem('calendarSelectedDate', date.toDate().toString());
                sessionStorage.setItem('calendarSelectedUser', JSON.stringify(user));
                this.router.navigate(['/calendar']);
            }
        }
    }

    /**
     * Assigns operators to selected tasks.
     * 
     * @returns A Promise that resolves when the operation is complete.
     */
    async assignOperatorToTasks(): Promise<void> {
        if (this.nothingSelectedWarning()) return;
        try {
            const users = await this._apiService.selectUsers();
            const userId = this._apiService.getLoggedUserId();
            if (users) {
                this._spinner.show();
                let codes: string[] = [];
                let ids: number[] = Array.from(this.clickedRows).map((t)=> t.id!);
                for (let task of this.clickedRows) {
                    if (task.codigo_de_geolocalizacion) codes.push(task.codigo_de_geolocalizacion);
                }
                this.loadingText = `Asignando operarios a tareas ...`;
                await this.updateTasksUsers(users, ids, userId); // Pass the array of numbers as an argument
                await this.updateItacsUsers(users, codes, userId);
                this._spinner.hide();
            }
        } catch (err) {}
    }

    /**
     * Updates the tasks assigned to users.
     * 
     * @param users - An array of MiRutaUser objects representing the users to assign the tasks to.
     * @param ids - An array of numbers representing the IDs of the tasks to update.
     * @param userId - A string representing the ID of the user performing the update.
     */
    async updateTasksUsers(users: MiRutaUser[], ids: number[], userId: string){
        let errorIds = [];
        try {
            const data = { OPERARIO: users, ultima_modificacion: userId }
            const result = await this._apiService.updateTasks(ids, data, false);
            if (result) {
                for (let row of this.clickedRows) {
                    const index = this.waterTasks.indexOf(row, 0);
                    row['OPERARIO'] = users;
                    this.waterTasks[index] = row;
                }
            } else {
                errorIds = ids;
            }
        } catch (err) {
            errorIds = ids;
        }
        if (errorIds.length > 0) this._utilsService.openSnackBar(`Hubo errores asignando tareas`, 'error');
        else this._utilsService.openSnackBar(`Tareas asignadas correctamente`);
    }

    /**
     * Updates the users assigned to the specified ITACs.
     * @param users - An array of MiRutaUser objects representing the users to be assigned.
     * @param codes - An array of strings representing the ITAC codes.
     * @param userId - A string representing the ID of the user performing the update.
     */
    async updateItacsUsers(users: MiRutaUser[], codes: string[], userId: string){
        let errorIds = [];
        const itacs = await this._apiService.getItacs([['codigo_itac', '==', codes]])
        const ids: number[] = itacs.map((itac: Itac)=> itac.id!);
        try {
            const data = { operario: users, ultima_modificacion: userId };
            const result = await this._apiService.updateItacs(ids, data, false);
            if (!result) errorIds = ids;
        } catch (err) {
            errorIds = ids;
        }
        if (errorIds.length > 0) this._utilsService.openSnackBar(`Hubo errores asignando itacs`, 'error');
        else this._utilsService.openSnackBar(`Itacs asignadas correctamente`);
    }

    /**
     * Assigns a selected date to the task(s).
     * 
     * @returns {Promise<void>} A promise that resolves when the task(s) have been updated.
     */
    async assignDateToTask(): Promise<void> {
        if (this.nothingSelectedWarning()) return;
        const firstTask = Array.from(this.clickedRows)[0];
        const userId = this._apiService.getLoggedUserId();
        let data = await this._utilsService.selectDateAppointment(firstTask);
        if(data) {
            data = { ...data, ultima_modificacion: userId };
            this._spinner.show();
            this.loadingText = `Asignando cita a tarea ...`;
            for (let task of this.clickedRows) {
                await this.updateTaskDate(data, task, userId);
            }
            this._spinner.hide();
            await this.updateView();
        }
    }

    /**
     * @brief Retrieves the availability for the selected day.
     * @returns {Promise<void>} A promise that resolves when the availability is retrieved.
     */
    async getDayAvailability(): Promise<void> {
        const date = this.settingsGroup.controls['date'].value;
        const currentDay = date.format('YYYY-MM-DD HH:mm:ss');
        this.dayAvailability = await this._apiService.getDayAvailability(currentDay);
    }

    /**
     * @brief Retrieves the availability for the selected date and the next day.
     * @returns {Promise<void>} A promise that resolves when the availability is retrieved.
     */
    async getDaysAvailability(): Promise<void> {
        const date = this.settingsGroup.controls['date'].value;
        const currentDay = date.format('YYYY-MM-DD HH:mm:ss');
        const tomorrow = date.clone().add(1, 'days').format('YYYY-MM-DD HH:mm:ss');
        await this._apiService.getDaysAvailability([currentDay, currentDay]);
    }

    /**
     * @brief Updates the date of a task.
     * 
     * @param dateData - The updated date data.
     * @param task - The task to update.
     * @param userId - The ID of the user performing the update.
     * @returns A promise that resolves to the updated task if successful, otherwise false.
     */
    async updateTaskDate(dateData: any, task: WaterTask, userId: string){
        if(task){
            dateData = { ...dateData , ...this._utilsService.resetTaskPriority(task) };
            try {
                const data = { ...dateData , ultima_modificacion: userId };
                return await this._apiService.updateTask(task.id!.toString(), data, false);
            } catch (err) {}
        }
        return false;
    }

    /**
     * @brief Checks if any rows are selected and displays a warning message if none are selected.
     * @returns {boolean} True if no rows are selected, false otherwise.
     */
    nothingSelectedWarning(): boolean { 
        if (this.clickedRows.size == 0) {
            this._utilsService.openSnackBar('Debe seleccionar al menos una tarea', 'warning');
            return true;
        }
        return false;
    }

    /**
     * @brief Clears the clicked rows and selects rows between the given indexes.
     * 
     * @param event - The event containing the selected row indexes.
     */
    selectedRows(event: any) {
        this.clickedRows.clear();
        this.selectRowsBetweenIndexes(event[0], event[1], false);
    }

    /**
     * @brief Toggles the row selection.
     * 
     * @param {any} row - The row to toggle.
     */
    toggleRow(row: any) {
        if (this.clickedRows.has(row)) {
            this.clickedRows.delete(row);
        } else {
            this.clickedRows.add(row);
        }
        if (this.clickedRows.size > 1) {
            this._utilsService.openSnackBar(`Seleccionadas ${this.clickedRows.size} tareas`);
        }
    }

    /**
     * @brief Selects rows between two indexes.
     * 
     * @param lastSelectedRow - The index of the last selected row.
     * @param rowIndex - The index of the current row.
     * @param showSnackBar - Optional parameter to indicate whether to show a snackbar. Default is true.
     */
    selectRowsBetweenIndexes(
        lastSelectedRow: number,
        rowIndex: number,
        showSnackBar: boolean = true
    ) {
        this.allSelected = false;
        let start, end;
        if (rowIndex > lastSelectedRow) {
            start = lastSelectedRow;
            end = rowIndex;
        } else {
            end = lastSelectedRow;
            start = rowIndex;
        }
        for (let i = start; i <= end; i++) {
            this.clickedRows.add(this.waterTasks[i]);
        }
        if (this.clickedRows.size > 1 && showSnackBar) {
            this._utilsService.openSnackBar(`Seleccionadas ${this.clickedRows.size} tareas`);
        }
    }

    /**
     * @brief Handles the scroll event and updates the data source.
     * @returns A promise that resolves to void.
     */
    async onScroll(): Promise<void> {
        this.scrollOffset += this.rowsLimit;
        if (this.scrollOffset > this.pageSize) return;
        this.dataSource.data = [];
        this.dataSource.data = this.waterTasks.slice(0, this.scrollOffset);
    }

    /**
     * @brief Opens the settings for the calendar table.
     * This method calls the `_utilsService.openFilterConfigurationDialog` method to open the filter configuration dialog.
     * 
     * @returns {Promise<void>} A promise that resolves when the settings dialog is closed.
     */
    async openSettings() {
        await this._utilsService.openFilterConfigurationDialog(
            this._mySqlService.tasksTableName,
            this._utilsService.filterTasks
        );
    }

    /**
     * @brief Handles the page event triggered by the calendar table.
     * 
     * @param event - The page event object.
     * @returns A promise that resolves when the page event is handled.
     */
    async pageEvent(event: any): Promise<void> {
        if (this.lastPageIndex != event.pageIndex) {
            this.showLoading(true);
            localStorage.setItem('lastPageCalendarIndex', event.pageIndex.toString());
            if (this.lastPageIndex < event.pageIndex) {
                const { waterTasks, count } = await this._mySqlService.getNextTasksPage(event.pageIndex - this.lastPageIndex);
                this.setTasksInTable(waterTasks);
            } else {
                const { waterTasks, count } = await this._mySqlService.getPreviousTasksPage(this.lastPageIndex - event.pageIndex);
                this.setTasksInTable(waterTasks);
            }
            this.lastPageIndex = event.pageIndex;
        }
        if (this.pageSize != event.pageSize) {
            this.pageSize = event.pageSize;
            localStorage.setItem('calendar_pageSize', this.pageSize.toString());
            await this.getTasks();
        }
        this.scrollOffset = 50;
    }

    /**
     * @brief Handles the change event of the username input field.
     * 
     * @param event - The event object containing the new value of the input field.
     * @returns A promise that resolves when the function completes.
     */
    async onUsernameChange(selectedUser: string): Promise<void> {
        this._utilsService.filterTasks = deleteFilterField(this._utilsService.filterTasks!, 'OPERARIO');
        if(selectedUser == 'Todos') {
            await this.getTasks();
        }
        else await this.filterOperator(selectedUser);
    }

    /**
     * Filters the operator based on the user selected value.
     * 
     * @param userSelected The selected user value.
     * @returns A promise that resolves when the filtering is complete.
     */
    async filterOperator(userSelected: string): Promise<void> {
        const users = await this._apiService.getUsers(['operario']);
        const userSelectedId = users.find(
            (user) => this._utilsService.userPipe(user.id) == userSelected
        )?.id;
        if (userSelectedId) this.applyFilter([userSelectedId], 'OPERARIO');
    }
}

/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit {
    @Input() loadingText = '';
    @Input() name = 'spinner';
    @Input() type = 'square-jelly-box';
    @Input() bdColor = 'rgba(0, 0, 0, 0.1)';
    @Input() color = '#368DCE';
    @Input() fullScreen = true;

    constructor() {}

    ngOnInit(): void {}
}

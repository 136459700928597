<mat-dialog-content class="animate__animated animate__fadeIn">
    <div [formGroup]="agrupationFormData" class="animate__animated animate__fadeIn">
        <div>
            <div class="container">
                <div class="row bd-highlight" class="row-content-center">
                    <div>
                        <div>
                            <div class="row-content">
                                <mat-form-field style="width: 120px" appearance="fill">
                                    <mat-label>ID Agrupación</mat-label>
                                    <input
                                        formControlName="agrupationId"
                                        matInput
                                        type="text"
                                        placeholder="ID Agrupación"
                                    />
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Agrupación</mat-label>
                                    <input
                                        formControlName="agrupation"
                                        matInput
                                        type="text"
                                        placeholder="Agrupación"
                                    />
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ngx-spinner
        [bdColor]="agrupation ? 'rgba(0, 0, 0, 0.2)' : 'rgba(255, 255, 255, 1)'"
        size="large"
        color="#368DCE"
        type="ball-scale-multiple"
        [fullScreen]="false"
        name="agrupationSpinner"
    >
    </ngx-spinner>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="agrupation">Cancelar</button>
    <button mat-button cdkFocusInitial matTooltip="Guardar cambios" (click)="saveChanges()">
        <mat-icon color="primary">check</mat-icon> {{ agrupationId ? 'Actualizar' : 'Añadir' }}
    </button>
</mat-dialog-actions>

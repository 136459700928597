/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { faBuilding, faFilePdf, faKey, faInbox } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { ApiService } from '../../services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessagingService } from 'src/app/services/messaging.service';

import * as moment from 'moment';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MiRutaUser, getMiRutaUserFormControls } from 'src/app/interfaces/mi-ruta-user';
import { Company } from 'src/app/interfaces/company';
import { Manager } from 'src/app/interfaces/manager';
import { Router } from '@angular/router';
import { MiRutaFilter } from '../../interfaces/mi-ruta-filter';
import { getField } from 'src/app/interfaces/water-task';
import { getDisplayColumns, getFieldType } from '../../interfaces/water-task';
import { MySqlService } from '../../services/mysql.service';
import emailjs, { EmailJSResponseStatus } from '@emailjs/browser';
import { environment } from 'src/environments/environment';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit {
    faFilePdf = faFilePdf;
    faInbox = faInbox;
    faBuilding = faBuilding;
    faKey = faKey;
    loading: boolean = false;
    miRutaUserId: string = '';
    miRutaUser?: MiRutaUser;

    miRutaUserFormData: FormGroup = getMiRutaUserFormControls();

    miRutaUserSubscription$?: Subscription;

    companiesIds: string[] = [];

    percentage: number = 0;
    uploading = false;

    form: FormGroup;
    user: any;

    debugErrors: boolean = false;
    readNotifications: boolean = false;
    readMessages: boolean = false;

    /**
     * @brief Constructor for the component.
     *
     * Initializes the component and its dependencies, including services and form controls.
     *
     * @param data - The data passed to the component via MAT_DIALOG_DATA.
     * @param _apiService - An instance of ApiService used for making API requests.
     * @param _utilsService - An instance of UtilsService for utility functions.
     * @param spinner - An instance of NgxSpinnerService for managing loading spinners.
     * @param router - An instance of Router for navigation.
     * @param _messagingService - An instance of MessagingService for handling messaging functionality.
     * @param _mySqlService - An instance of MysqlService for MySQL-related operations.
     * @param formBuilder - An instance of FormBuilder for building and managing forms.
     * @return {void}
     */
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _apiService: ApiService,
        public _utilsService: UtilsService,
        private spinner: NgxSpinnerService,
        private router: Router,
        public _messagingService: MessagingService,
        public _mySqlService: MySqlService,
        public formBuilder: FormBuilder
    ) {
        this.form = this.formBuilder.group({ image: [null] });
        this.miRutaUserId = data.miRutaUserId;
        this.user = this._utilsService.getLoggedInUser();
    }

    /**
     * @brief Initializes the component and retrieves initial data.
     *
     * This function is called when the component is initialized. It performs the following tasks:
     * 1. Displays a loading indicator.
     * 2. Retrieves the list of companies and managers if not already available.
     * 3. Retrieves user data based on the provided 'miRutaUserId'.
     * 4. Populates the component with user data.
     * 5. Hides the loading indicator once data retrieval is complete.
     *
     * @return {Promise<void>} A Promise that resolves when the initialization process is complete.
     */
    async ngOnInit(): Promise<void> {
        this.showLoading(true);
        if (!this._utilsService.companies)  await this._apiService.getCompanies();
        if (!this._utilsService.managers) await this._apiService.getManagers();
        this.companiesIds = this._utilsService.companies.map((company) => company.id!.toString());

        if (this.miRutaUserId) {
            this.miRutaUserSubscription$ = this._apiService
                .getDocument('user', this.miRutaUserId)
                .subscribe(async (doc: any) => {
                    if (!doc) {
                        this._utilsService.openSnackBar(
                            'Error obteniendo datos de usuario',
                            'error'
                        );
                        this.showLoading(false);
                        return;
                    }
                    this.setCurrentUserData(doc);
                    this.debugErrors = this.miRutaUser?.settings?.debug_front_errors ? true: false;
                    this.readNotifications = this.miRutaUser?.settings?.read_notifications ? true: false;
                    this.readMessages = this.miRutaUser?.settings?.read_messages ? true: false;
                    this.showLoading(false);
                });
        } else {
            this.showLoading(false);
        }
    }

    /**
     * @brief Sets the current user's data and populates a form with the user's information.
     *
     * This function is responsible for setting the current user's data by converting a document into a MiRutaUser object.
     * It also populates a form with the user's information and associates it with the current user.
     *
     * @param doc - The document containing user data to be set.
     * @return {void}
     */
    setCurrentUserData(doc: any): void {
        const miRutaUser = doc as MiRutaUser;
        miRutaUser.id = parseInt(this.miRutaUserId);
        this.miRutaUser = miRutaUser;
        const miRutaUserFormData = getMiRutaUserFormControls();
        const keys = Object.keys(miRutaUser);
        for (let key of keys) {
            let value: any = miRutaUser[key as keyof MiRutaUser];
            if (value) {
                try {
                    miRutaUserFormData.controls[key].setValue(value);
                } catch (err) {
                    console.log('============= err =============');
                    console.log(err);
                }
            }
        }
        this.miRutaUserFormData = miRutaUserFormData;
    }

    ngOnDestroy(): void {
        this.miRutaUserSubscription$?.unsubscribe();
    }

    openTrace() {
        this._utilsService.closeMiRutaUserDialog(undefined);
        this.router.navigate(['/user-location', this.miRutaUserId]);
    }
    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this.spinner.show('miRutaUserSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this.spinner.hide('miRutaUserSpinner');
        }
    }

    saveFormData() {
        const keys = Object.keys(this.miRutaUserFormData.controls);
        let miRutaUser: any = {};
        for (let key of keys) {
            let value = this.miRutaUserFormData.controls[key].value; //this.miRutaUser[key as keyof MiRutaUser];
            try {
                if (moment.isMoment(value)) {
                    value = value.toDate();
                }
                miRutaUser![key as keyof MiRutaUser] = value;
            } catch (error) {}
        }
        miRutaUser!['date_time_modified'] = new Date();
        if (!this.miRutaUserId) {
            miRutaUser['date_time_created'] = miRutaUser!['date_time_modified'];
        }
        this.miRutaUser = miRutaUser;
        const company = localStorage.getItem('company');
        if (!this.miRutaUser?.companies) {
            this.miRutaUser!.companies = [];
        }
        if (!this.miRutaUserId && this.miRutaUser?.companies) {
            if (!this._utilsService.checkCompany(this.miRutaUser?.companies, company!)) {
                this.miRutaUser?.companies.push({ id: parseInt(company!) });
            }
        }
        this.setSettings();
    }

    setSettings(){
        if(this.miRutaUser!.settings) {
            this.miRutaUser!.settings.debug_front_errors = this.debugErrors;
            this.miRutaUser!.settings.read_notifications = this.readNotifications;
            this.miRutaUser!.settings.read_messages = this.readMessages;
        }
        else {
            this.miRutaUser!.settings = { debug_front_errors: this.debugErrors };
            this.miRutaUser!.settings = { read_notifications: this.readNotifications };
            this.miRutaUser!.settings = { read_messages: this.readMessages };
        }
    }

    async saveChanges() {
        this.showLoading(true);
        this.saveFormData();
        if (this.miRutaUserId) {
            const result: boolean = await this._apiService.updateDocument(
                'user',
                this.miRutaUserId,
                this.miRutaUser
            );
            if (result) {
                this._utilsService.openSnackBar('Usuario actualizado correctamente');
            } else {
                this._utilsService.openSnackBar('Error actualizando usuario', 'error');
            }
            this._utilsService.closeMiRutaUserDialog(this.miRutaUser!);
        } else {
            try {
                const createdUser = await this._apiService.createUser(this.miRutaUser!);
                this._utilsService.closeMiRutaUserDialog(createdUser);
            } catch (error) {
                this._utilsService.openSnackBar('Error añadiendo usuario', 'error');
                this._utilsService.closeMiRutaUserDialog(undefined);
            }
        }
        this.showLoading(false);
    }

    /**
     * @brief Displays a file selection dialog and triggers the file upload process.
     *
     * This function is responsible for showing a file selection dialog, allowing the user to choose a file for upload.
     * It also performs basic validation to ensure a user's email is inserted before proceeding with the upload.
     *
     * @param input_file - The HTML input element for file selection.
     * @return {Promise<void>} A Promise that resolves when the file selection process is complete.
     */
    async selectFile(input_file: any): Promise<void> {
        this.showLoading(true);
        if (!this.miRutaUserFormData.controls['username'].value) {
            this._utilsService.openSnackBar('Inserte correo de usuario', 'error');
            this.showLoading(false);
            return;
        }
        this.showLoading(false);
        input_file.click();
    }

    /**
     * @brief Handles the uploaded file and initiates the file upload process.
     *
     * This function is called when a file is selected by the user. It processes the selected file,
     * updates the form, and initiates the file upload procedure.
     *
     * @param event - The event object containing the selected file.
     * @return {void}
     */
    async uploadFile(event: any): Promise<void> {
        const file = (event?.target as HTMLInputElement as any).files[0];
        if (file) {
            this.form.patchValue({ image: file });
            this.form?.get('image')?.updateValueAndValidity();
            this.uploadImage();
        }
    }

    async addCompanies() {
        try {
            const companies = await this._apiService.getCompanies();
            const alreadyInCompanies = this.miRutaUserFormData.controls['companies'].value;
            const companiesSelectedName = await this._utilsService.openMultipleOptionsDialog(
                'Seleccione empresas',
                companies.map((company: Company) => this._utilsService.companyPipe(company.id)),
                alreadyInCompanies?.map((company: Company) =>
                    this._utilsService.companyPipe(company.id)
                )
            );
            if (companiesSelectedName && companiesSelectedName.length > 0) {
                const companiesSelected = companies.filter((company: Company) =>
                    companiesSelectedName.includes(this._utilsService.companyPipe(company.id))
                );
                if (companiesSelected) {
                    this.miRutaUserFormData.controls['companies'].setValue(companiesSelected);
                }
            }
        } catch (err) {}
    }
    async addManagers() {
        try {
            const managers = await this._apiService.getManagers();
            const alreadyInManagers = this.miRutaUserFormData.controls['managers'].value;
            const managersSelectedName = await this._utilsService.openMultipleOptionsDialog(
                'Seleccione gestores',
                managers.map((manager: Manager) => this._utilsService.managerPipe(manager.id)),
                alreadyInManagers?.map((manager: Manager) =>
                    this._utilsService.managerPipe(manager.id)
                )
            );
            if (managersSelectedName && managersSelectedName.length > 0) {
                const managersSelected = managers.filter((manager: Manager) =>
                    managersSelectedName.includes(this._utilsService.managerPipe(manager.id))
                );
                if (managersSelected) {
                    this.miRutaUserFormData.controls['managers'].setValue(managersSelected);
                }
            }
        } catch (err) {
            console.log('************* err *************');
            console.log(err);
        }
    }

    async uploadImage() {
        var formData: any = new FormData();
        formData.append('image', this.form?.get('image')?.value);

        try {
            const userData = await this._apiService.uploadUserImage(formData, this.miRutaUser?.id!);
            this.miRutaUser = userData;
            this.miRutaUserFormData.controls['photo'].setValue(this.miRutaUser?.photo);

            if (this.miRutaUser?.id! == this.user.id) {
                sessionStorage.setItem('user', JSON.stringify(userData));
                this._messagingService.sendMessage('User image changed');
            }
        } catch (err) {
            console.log('************* err *************');
            console.log(err);
        }
    }

    async clearRestrictions() {
        const res = await this._utilsService.openQuestionDialog(
            'Confirmación',
            '¿Desea reiniciar los permisos de este usuario?'
        );
        if (res) {
            this.miRutaUser!.permisos = '';
            this.miRutaUserFormData.controls['permisos'].setValue('');
        }
    }

    onDebugErrorsChange(event: MatSlideToggleChange) {
        this.debugErrors = event.checked;
    }

    onReadNotificationsChange(event: MatSlideToggleChange) {
        this.readNotifications = event.checked;
    }

    onReadMessagesChange(event: MatSlideToggleChange) {
        this.readMessages = event.checked;
    }

    async changePassword() {
        this.showLoading(true);
        const confirmLink = await this._apiService.changePassword(this.miRutaUser?.username!);
        if (confirmLink) {
            try {
                const result: EmailJSResponseStatus = await emailjs.send(
                    environment.email_js_service_id,
                    environment.email_js_template,
                    {
                        message: confirmLink.link,
                        to_email: this.miRutaUser?.username,
                    },
                    environment.email_js_public_key
                );
                console.log('============= result =============');
                console.log(result);
                if (result.status == 200) {
                    this._utilsService.openSnackBar(
                        'El enlace ha sido enviado correctamente al correo del usuario'
                    );
                } else {
                    this._utilsService.openSnackBar(
                        'No se ha podido enviar el enlace para el cambio de contraseña',
                        'error'
                    );
                }
            } catch (err) {
                console.log('============= err =============');
                console.log(err);
            }
        }
        this.showLoading(false);
    }

    async showRestrictions() {
        try {
            let filter: MiRutaFilter = {};
            const column = await this._utilsService.openSelectorDialog(
                'Seleccione campo',
                getDisplayColumns()
            );
            if (column) {
                const fieldColumn = getField(column);
                if (this.miRutaUser && this.miRutaUser.permisos) {
                    filter = JSON.parse(this.miRutaUser.permisos);
                }
                const result = await this._utilsService.openUserFilterDialog(
                    column,
                    fieldColumn,
                    filter
                );
                if (result && result.data) {
                    filter = this._utilsService.processFilter(
                        filter,
                        result.data,
                        result.column,
                        getFieldType(result.column),
                        this._mySqlService.tasksTableName
                    );
                    this.miRutaUser!.permisos = JSON.stringify(filter);
                    this.miRutaUserFormData.controls['permisos'].setValue(JSON.stringify(filter));
                }
            }
        } catch (err) {}
    }
}

/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import * as moment from 'moment';
import { UtilsService } from '../../../services/utils.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DateStatus } from '../../../interfaces/date-status';
import { ApiService } from '../../../services/api.service';
import { WaterTask } from '../../../interfaces/water-task';
import { Router } from '@angular/router';
import { MiRutaUser } from 'src/app/interfaces/mi-ruta-user';
import { Itac } from '../../../interfaces/itac';


export interface DateRangeSelectorData {
    placeHolderText: string;
    placeHolderTextStart: string;
    placeHolderTextEnd: string;
    show_date_status: boolean;
    currentDateObject: WaterTask; 
    is_date_range: boolean;
}

@Component({
    selector: 'app-date-time-selector',
    templateUrl: './date-time-selector.component.html',
    styleUrls: ['./date-time-selector.component.scss'],
})
export class DateTimeSelectorComponent implements OnInit {
    date = new FormGroup({
        dateValue: new FormControl(),
        dateStatus: new FormControl(),
        start: new FormControl(),
        end: new FormControl(),
    });

    dateStatuses: DateStatus[] = [];

    placeHolderText: string = 'Seleccione fecha';

    minTime = '12:00 pm';
    maxTime = '12:00 pm';

    placeHolderTextStart?: string = 'Hora inicial';
    placeHolderTextEnd?: string = 'Hora final';

    thisMoment = moment(new Date()).set({ hour: 12, minute: 0, second: 0, });
    time_range: Date[] = [this.thisMoment.toDate(), this.thisMoment.toDate()];
    show_date_status: boolean = false;
    currentDateObject: WaterTask | undefined //| Itac;

    darkTheme: NgxMaterialTimepickerTheme = {
        container: {
            bodyBackgroundColor: '#424242',
            buttonColor: '#fff',
        },
        dial: {
            dialBackgroundColor: '#555',
        },
        clockFace: {
            clockFaceBackgroundColor: '#555',
            clockHandColor: '#368DCE',
            clockFaceTimeInactiveColor: '#fff',
        },
    };

    is_date_range: boolean = false;

    dateSelected: Date = new Date();
    dateSelectedStart: Date = new Date();
    dateSelectedEnd: Date = new Date();

    /**
     * @brief Represents the DateTimeSelectorComponent class.
     * This component is responsible for handling date and time selection.
     */
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DateRangeSelectorData, 
        private _utilService: UtilsService,
        private _apiService: ApiService,
        private router: Router,
    ) {
        if(data.placeHolderText) this.placeHolderText = data.placeHolderText;
        if(data.placeHolderTextStart) this.placeHolderTextStart = data.placeHolderTextStart;
        if(data.placeHolderTextEnd) this.placeHolderTextEnd = data.placeHolderTextEnd;
        if(data.show_date_status) this.show_date_status = data.show_date_status;
        if(data.currentDateObject) this.currentDateObject = data.currentDateObject;
        if (data.is_date_range) this.is_date_range = data.is_date_range;
        this.setDefaultTime();
    }

    /**
     * @brief Initializes the component.
     * This method is called after the component's data-bound properties have been initialized,
     * and the component's view has been fully initialized.
     * It is a lifecycle hook that is called by Angular when the component is created.
     * @returns A promise that resolves when the initialization is complete.
     */
    async ngOnInit(): Promise<void> {    
        await this.setDateStatuses();
        this.setDefaultDate();
        this.onChange();

        this.date.controls['dateValue'].valueChanges.subscribe(async (value: any) => {
            this.onChange();
        });
        this.date.controls['start'].valueChanges.subscribe(async (value: any) => {
            this.onChange();
        });
        this.date.controls['end'].valueChanges.subscribe(async (value: any) => {
            this.onChange();
        });
    }

    /**
     * Handles the change event for the date-time selector.
     * Calls the `onStartTimeChanged` and `onEndTimeChanged` methods with the minimum and maximum time values.
     */
    onChange(): void {
        this.onStartTimeChanged(this.minTime);
        this.onEndTimeChanged(this.maxTime);
    }

    /**
     * Opens the week calendar for the current date task.
     * If there is only one user assigned to the task, it directly navigates to the calendar page.
     * If there are multiple users assigned to the task, it prompts the user to select a user for the week calendar.
     * Once a user is selected, it sets the selected date and user in the session storage and navigates to the calendar page.
     * Finally, it closes the date-time range selector dialog.
     *
     * @returns A promise that resolves when the operation is complete.
     */
    async openWeek(): Promise<void> {
        if (this.currentDateObject && 'OPERARIO' in this.currentDateObject) {
            const currUsers = this.currentDateObject.OPERARIO;
            if (currUsers && currUsers.length) {
                let user: MiRutaUser | null;
                if (currUsers.length == 1) user = currUsers[0];
                else user = await this._apiService.selectUserForWeekCalendar(currUsers);
                if (user) {
                    const date = this.date.controls['dateValue'].value;
                    sessionStorage.setItem('calendarSelectedDate', date.toString());
                    sessionStorage.setItem('calendarSelectedUser', JSON.stringify(user));
                    this.router.navigate(['/calendar', this.currentDateObject.id]);
                    this._utilService.closeDateTimeRangeSelectorDialog(this.getDateTimeRange());
                }
            }
        }
    }

    /**
     * @brief Sets the default date for the date-time selector component.
     * If `currentDateTask` and `currentDateTask.fecha_hora_cita` are defined,
     * it sets the date value to `currentDateTask.fecha_hora_cita`.
     * Otherwise, it sets the date value to `dateSelected`.
     */
    setDefaultDate() {
        if (this.currentDateObject && this.currentDateObject.fecha_hora_cita) {
            const dateStart = moment(this.currentDateObject.fecha_hora_cita);
            this.dateSelected = dateStart.toDate();
            this.dateSelectedStart = dateStart.toDate();
            this.dateSelectedEnd = dateStart.toDate();
            this.date.controls['dateValue'].setValue(dateStart);
            this.date.controls['start'].setValue(dateStart);
            this.date.controls['end'].setValue(dateStart);
        }
        else {
            this.date.controls['dateValue'].setValue(moment(this.dateSelected));
            this.date.controls['start'].setValue(moment(this.dateSelectedStart));
            this.date.controls['end'].setValue(moment(this.dateSelectedEnd));
        }
        if (this.currentDateObject && this.currentDateObject.fecha_hora_cita_end) {
            const dateEnd = moment(this.currentDateObject.fecha_hora_cita_end);
            this.dateSelectedEnd = dateEnd.toDate();
            this.date.controls['end'].setValue(dateEnd);
        }
    }

    /**
     * @brief Sets the default time for the current date task.
     * If the `currentDateTask` has a `fecha_hora_cita` value, it sets the `fecha_hora_cita_end` value to `fecha_hora_cita` if it is not already set.
     * Then, it calculates the minimum and maximum time based on the `fecha_hora_cita` and `fecha_hora_cita_end` values and updates the `minTime` and `maxTime` properties accordingly.
     */
    setDefaultTime() {
        if(this.currentDateObject && this.currentDateObject.fecha_hora_cita) {
            if(!this.currentDateObject.fecha_hora_cita_end) {
                this.currentDateObject.fecha_hora_cita_end = this.currentDateObject.fecha_hora_cita;
            }
            const dateStart = moment(this.currentDateObject.fecha_hora_cita);
            const dateEnd = moment(this.currentDateObject.fecha_hora_cita_end);
            this.minTime = dateStart.format('hh:mm A').toLowerCase();
            this.maxTime = dateEnd.format('hh:mm A').toLowerCase();
        }
    }

    /**
     * Gets the minimum range value.
     * If `time_range` is defined and has a valid first element, it returns the formatted time in 'hh:mm A' format.
     * Otherwise, it returns the value of `minTime`.
     * 
     * @returns The minimum range value.
     */
    getMinRange(): string {
        if (this.time_range && this.time_range.length && this.time_range[0]) { 
            return moment(this.time_range[0]).format('hh:mm A').toLowerCase();
        }
        return this.minTime;
    }


    /**
     * Gets the maximum range value.
     * If `time_range` is defined and has a length greater than 1, it returns the formatted time value of the second element.
     * Otherwise, it returns the value of `minTime`.
     * 
     * @returns The maximum range value.
     */
    getMaxRange(): string {
        if (this.time_range && this.time_range.length > 1 && this.time_range[1]) { 
            return moment(this.time_range[1]).format('hh:mm A').toLowerCase();
        }
        return this.maxTime;
    }

    /**
     * @brief Sets the date statuses by calling the _apiService.getDateStatuses() method.
     * If the options are available, it sets the default date status as the first option.
     * If a default option is specified, it sets the date status to the default option.
     * @returns A promise that resolves when the date statuses are set.
     */
    async setDateStatuses(): Promise<void> {
        this.dateStatuses = await this._apiService.getDateStatuses();
        if(this.currentDateObject && ('date_status' in this.currentDateObject) && this.currentDateObject.date_status) {
            const date_status = this.currentDateObject.date_status;
            const option = this.dateStatuses.find(option => option.value === date_status.value);
            this.date.controls['dateStatus'].setValue(option);
        }
        else if(this.dateStatuses && this.dateStatuses.length) {
            this.date.controls['dateStatus'].setValue(this.dateStatuses[0]);
            for(const option of this.dateStatuses) {
                if(option.default) {
                    this.date.controls['dateStatus'].setValue(option);
                    break;
                }
            }
        }
    }

    /**
     * @brief Handles the event when the start time is changed.
     * @param event - The event object containing the new start time value.
     */
    onStartTimeChanged(event: any) {
        const value = this.date.controls['dateValue'].value;
        const valueStart = this.date.controls['start'].value;
        if (value) this.dateSelected = value.toDate();
        if (valueStart) this.dateSelectedStart = valueStart.toDate();
        
        let momentDate = this.is_date_range ? moment(this.dateSelectedStart): moment(this.dateSelected);
        const startTimeString: string = event.replace('a. m.', 'AM').replace('p. m.', 'PM');
        const startTime = moment(startTimeString, 'hh:mm A');
        momentDate.set({
            hour: startTime.toDate().getHours(),
            minute: startTime.toDate().getMinutes(),
            second: 0,
        });
        this.time_range[0] = momentDate.toDate();
        if (!this.time_range[1]) this.onEndTimeChanged(event);
        this.minTime = startTime.format('hh:mm A');
    }

    /**
     * @brief Handles the change event when the end time is selected.
     * @param event - The event object containing the selected end time.
     */
    onEndTimeChanged(event: any) {
        const value = this.date.controls['dateValue'].value;
        const valueEnd = this.date.controls['end'].value ?? this.date.controls['start'].value;
        if (value) this.dateSelected = value.toDate();
        if (valueEnd) this.dateSelectedEnd = valueEnd.toDate();

        let momentDate = this.is_date_range ? moment(this.dateSelectedEnd): moment(this.dateSelected);
        const endTimeString: string = event.replace('a. m.', 'AM').replace('p. m.', 'PM');
        const endTime = moment(endTimeString, 'hh:mm A');
        momentDate.set({
            hour: endTime.toDate().getHours(),
            minute: endTime.toDate().getMinutes(),
            second: 0,
        });
        if(this.time_range[0] && momentDate.isBefore(this.time_range[0])) return;
        this.time_range[1] = momentDate.toDate();
        if (!this.time_range[0]) this.onStartTimeChanged(event);
        this.maxTime = endTime.format('hh:mm A');
    }

    /**
     * @brief Retrieves the date and time range.
     * 
     * @returns {Array<Date | string>} The date and time range.
     */
    getDateTimeRange(): Array<Date | string> {
        if(this.time_range[0] && this.time_range[1]) {
            if(this.time_range[0] > this.time_range[1]) {
                this._utilService.openSnackBar('La fecha final debe ser mayor a la inicial, se reajustará automaticamente al aceptar', 'warning');
                this.time_range[1] = this.time_range[0];
            }
            if(this.show_date_status) {
                const dateStatus = this.date.controls['dateStatus'].value;
                return [...this.time_range, dateStatus];
            } 
            return this.time_range;
        }
        else {
            const date_range = [new Date(), new Date()];
            if(this.show_date_status) {
                const dateStatus = this.date.controls['dateStatus'].value;
                return [...date_range, dateStatus];
            }
            return date_range;
        }
    }
}

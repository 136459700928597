/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { FormGroup, FormControl } from '@angular/forms';
export interface Agrupation {
    id?: number;
    agrupation?: string;
    agrupationId?: number;
    nonActiveAgrupation?: number;
    nonAssignedAgrupation?: number;
    assignedAgrupation?: number;
    company?: number;
    date_time_modified?: Date;
}

export function getAgrupationDisplayColumns(): string[] {
    const displayedColumns: string[] = ['ID Agrupación', 'Agrupación', 'Fecha de Modificación'];
    return displayedColumns;
}

export function getAgrupationField(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'ID Agrupación':
            value = 'agrupationId';
            break;
        case 'Agrupación':
            value = 'agrupation';
            break;
        case 'Fecha de Modificación':
            value = 'date_time_modified';
            break;
    }
    return value;
}

export function getAgrupationFieldName(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'agrupationId':
            value = 'ID Agrupación';
            break;
        case 'agrupation':
            value = 'Agrupación';
            break;
        case 'date_time_modified':
            value = 'Fecha de Modificación';
            break;
    }
    return value;
}

export function getAgrupationFormControls(): FormGroup {
    const agrupationFormData: FormGroup = new FormGroup({
        id: new FormControl(),
        agrupation: new FormControl(),
        agrupationId: new FormControl(),
        nonActiveAgrupation: new FormControl(),
        nonAssignedAgrupation: new FormControl(),
        assignedAgrupation: new FormControl(),
        company: new FormControl(),
        date_time_modified: new FormControl(),
    });
    return agrupationFormData;
}

export function getAgrupationFieldType(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'agrupationId':
            value = 'string';
            break;
        case 'agrupation':
            value = 'string';
            break;
        case 'date_time_modified':
            value = 'Date';
            break;
        case 'id':
            value = 'number';
            break;
    }
    return value;
}

export function getAgrupationExcelFieldName(excel_field: string): string {
    let value: string = '';
    switch (excel_field.trim().toLowerCase()) {
        case 'agrupation':
            value = 'agrupation';
            break;
        case 'código gestor':
            value = 'agrupationId';
            break;
        case 'codigo gestor':
            value = 'agrupationId';
            break;
        case 'fecha de modificación':
            value = 'date_time_modified';
            break;
        case 'fecha de modificacion':
            value = 'date_time_modified';
            break;
    }
    return value;
}

export function getAgrupationExcelExportColumns(): string[] {
    const columns = ['ID Agrupación', 'Agrupación', 'Fecha de Modificación'];
    return columns;
}

/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { faBuilding, faFilePdf, faKey, faInbox } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { ApiService } from '../../../services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Manager, getManagerFormControls } from 'src/app/interfaces/manager';
import { Company } from '../../../interfaces/company';

@Component({
    selector: 'app-manager',
    templateUrl: './manager.component.html',
    styleUrls: ['./manager.component.scss'],
})
export class ManagerComponent implements OnInit {
    faFilePdf = faFilePdf;
    faInbox = faInbox;
    faBuilding = faBuilding;
    faKey = faKey;
    loading: boolean = false;
    managerId: string = '';
    manager?: Manager;

    managerFormData: FormGroup = getManagerFormControls();

    managerSubcription$?: Subscription;

    percentage: number = 0;
    uploading = false;

    form: FormGroup;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _apiService: ApiService,
        private _utilsService: UtilsService,
        private spinner: NgxSpinnerService,
        public formBuilder: FormBuilder
    ) {
        this.form = this.formBuilder.group({
            image: [null],
        });

        this.managerId = data.managerId;
    }

    async ngOnInit(): Promise<void> {
        if (this.managerId) {
            this.showLoading(true);
            this.managerSubcription$ = this._apiService
                .getDocument(`manager`, this.managerId)
                .subscribe(async (doc: any) => {
                    if (!doc) {
                        this._utilsService.openSnackBar(
                            'Error obteniendo datos de manager',
                            'error'
                        );
                        this.showLoading(false);
                        return;
                    }
                    const manager = doc as Manager;
                    manager.id = parseInt(this.managerId);

                    this.manager = manager;

                    console.log(this.manager);

                    const managerFormData = getManagerFormControls();
                    const keys = Object.keys(manager);
                    for (let key of keys) {
                        let value: any = manager[key as keyof Manager];
                        if (value) {
                            if (this._utilsService.isValidDate(value)) {
                                value = value;
                            }
                            managerFormData.controls[key].setValue(value);
                        }
                    }
                    this.managerFormData = managerFormData;

                    this.showLoading(false);
                });
        }
    }

    ngOnDestroy(): void {
        this.managerSubcription$?.unsubscribe();
    }

    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this.spinner.show('managerSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this.spinner.hide('managerSpinner');
        }
    }

    saveFormData() {
        const keys = Object.keys(this.managerFormData.controls);
        let manager: any = {};
        for (let key of keys) {
            let value = this.managerFormData.controls[key].value; //this.manager[key as keyof Manager];
            try {
                if (moment.isMoment(value)) {
                    value = value.toDate();
                }
                manager![key as keyof Manager] = value;
            } catch (error) {}
        }
        manager!['date_time_modified'] = new Date();
        this.manager = manager;
    }

    async saveChanges() {
        this.showLoading(true);
        this.saveFormData();
        if (this.managerId) {
            //When is already created
            const result: boolean = await this._apiService.updateDocument(
                `manager`,
                this.managerId,
                this.manager
            );
            if (result) {
                this._utilsService.openSnackBar('Gestor actualizado correctamente');
            } else {
                this._utilsService.openSnackBar('Error actualizando gestor', 'error');
            }
        } else {
            // When is new manager
            try {
                const companyId = localStorage.getItem('company');
                this.manager!.company = parseInt(companyId!);
                const managerId = await this._apiService.addDocument(`manager`, this.manager);
                if (managerId) {
                    this.manager!.id = managerId;

                    const company = await this._apiService.getDocumentSync<Company>(
                        'company',
                        companyId!
                    );
                    if (company) {
                        if (!company.managers) company.managers = [];
                        company.managers.push(this.manager!);
                    }
                    await this._apiService.updateDocument('company', companyId!, company);

                    this._utilsService.openSnackBar('Gestor añadido correctamente');
                } else {
                    this._utilsService.openSnackBar('Error añadiendo gestor', 'error');
                }
            } catch (error) {
                this._utilsService.openSnackBar('Error añadiendo gestor', 'error');
            }
        }
        this.showLoading(false);
        this._utilsService.closeManagerDialog(this.manager!);
    }

    async selectFile(input_file: any) {
        this.showLoading(true);
        console.log('********* selectFile ***********');
        if (!this.managerFormData.controls['gestor'].value) {
            this._utilsService.openSnackBar('Inserte nombre de gestor', 'error');
            this.showLoading(false);
            return;
        }
        this.showLoading(false);
        input_file.click();
        console.log('********* input_file click ***********');
    }
    uploadFile(event: any): void {
        const file = (event?.target as HTMLInputElement as any).files[0];
        if (file) {
            this.form.patchValue({
                image: file,
            });
            this.form?.get('image')?.updateValueAndValidity();

            console.log('============= file =============');
            console.log(file);
            this.uploadImage();
        }
    }
    async uploadImage() {
        var formData: any = new FormData();
        formData.append('image', this.form?.get('image')?.value);
        try {
            const managerData = await this._apiService.uploadManagerImage(
                formData,
                this.manager?.id!
            );
            this.manager = managerData;
            this.managerFormData.controls['photo'].setValue(this.manager?.photo);
        } catch (err) {
            console.log('************* err *************');
            console.log(err);
        }
    }
}

/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { FormControl, FormGroup } from '@angular/forms';

export interface Caliber {
    calibre: string;
    codigo_calibre: string;
    date_time_modified: Date;
    id: number;
}

export function getCaliberDisplayColumns(): string[] {
    const displayedColumns: string[] = ['Código calibre', 'Calibre', 'Fecha de Modificación'];
    return displayedColumns;
}

export function getCaliberField(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'Código calibre':
            value = 'codigo_calibre';
            break;
        case 'Calibre':
            value = 'calibre';
            break;
        case 'Fecha de Modificación':
            value = 'date_time_modified';
            break;
    }
    return value;
}

export function getCaliberFieldName(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'codigo_calibre':
            value = 'Código calibre';
            break;
        case 'calibre':
            value = 'Calibre';
            break;
        case 'date_time_modified':
            value = 'Fecha de Modificación';
            break;
    }
    return value;
}

export function getCaliberFormControls(): FormGroup {
    const caliberFormData: FormGroup = new FormGroup({
        calibre: new FormControl(),
        codigo_calibre: new FormControl(),
        date_time_modified: new FormControl(),
        id: new FormControl(),
    });
    return caliberFormData;
}

export function getCaliberFieldType(field_name: string): string {
    //in bigquery
    let value: string = '';
    switch (field_name) {
        case 'codigo_calibre':
            value = 'string';
            break;
        case 'calibre':
            value = 'string';
            break;
        case 'date_time_modified':
            value = 'Date';
            break;
        case 'id':
            value = 'number';
            break;
    }
    return value;
}

export function getCaliberExcelFieldName(excel_field: string): string {
    let value: string = '';
    switch (excel_field.trim().toLowerCase()) {
        case 'calibre':
            value = 'calibre';
            break;
        case 'código calibre':
            value = 'codigo_calibre';
            break;
        case 'codigo calibre':
            value = 'codigo_calibre';
            break;
        case 'fecha de modificación':
            value = 'date_time_modified';
            break;
        case 'fecha de modificacion':
            value = 'date_time_modified';
            break;
        case 'codigo_calibre':
            value = 'codigo_calibre';
            break;
        case 'date_time_modified':
            value = 'date_time_modified';
            break;
    }
    return value;
}

export function getCaliberExcelExportColumns(): string[] {
    const columns = ['Código calibre', 'Calibre', 'Fecha de Modificación'];
    return columns;
}

<mat-dialog-content class=" animate__animated animate__fadeIn">
    <div [formGroup]="planningDetailExtraFormData"
    class=" animate__animated animate__fadeIn">

        <div>

            <div class="container">

                <div class="row bd-highlight" class="row-content-center">

                    <div>
                        <div style="margin-top: 5px; margin-bottom: 30px; margin-left: 5px;">                        
                            <p style="font-size: large;">Datos de planificación</p>
                        </div> 
                        <div>
                            <div class="row-content">

                                <mat-form-field appearance="fill">
                                    <mat-label>Código común</mat-label>
                                    <input formControlName="common_code" matInput type="text" placeholder="Código común">
                                </mat-form-field>
                                
                                <mat-form-field appearance="fill">
                                    <mat-label>Código planificación</mat-label>
                                    <mat-select formControlName="codigo_planning">
                                        <mat-option
                                        *ngFor="let option of plannings"
                                        [value]="option"
                                        >{{ option }}</mat-option
                                        >
                                    </mat-select>
                                </mat-form-field>
                                
                                <mat-form-field appearance="fill">
                                    <mat-label>Código detalle planificación</mat-label>
                                    <mat-select formControlName="codigo_planning_details">
                                        <mat-option
                                            *ngFor="let option of planningDetails"
                                            [value]="option"
                                            >{{ option }}</mat-option
                                        >
                                    </mat-select>
                                </mat-form-field>

                            </div>
                            
                            <div class="row-content">

                                <mat-form-field appearance="fill">
                                    <mat-label>Detalle</mat-label>
                                    <input formControlName="detail" matInput type="text" placeholder="Detalle">
                                </mat-form-field>

                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>

    <ngx-spinner [bdColor] = "(planningDetailExtra)?'rgba(0, 0, 0, 0.2)': 'rgba(255, 255, 255, 1)'" size = "large" color = "#368DCE" 
    type = "ball-scale-multiple" [fullScreen] = "false" name="planningDetailExtraSpinner">
    </ngx-spinner>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="planningDetailExtra">Cancelar</button>
    <button mat-button cdkFocusInitial
    matTooltip="Guardar cambios" 
    (click)="saveChanges()"> 
        <mat-icon color="primary">check</mat-icon> {{ (planningDetailExtraId)?'Actualizar':'Añadir' }}
    </button>
</mat-dialog-actions>


<div class="shape"></div>
<div>
    <a href="http://www.mraguas.com" target="_blank" rel="noopener">
        <img style="width: 200px; padding: 15px" src="assets/img/logo_badge.svg" alt="logo" />
    </a>
</div>
<div class="mat-drawer-items">
    <div *ngIf="!_utilsService.isClientUser()">
        <button class="drawer-button" mat-flat-button (click)="selectTable()">
            <mat-icon class="mat-icon-drawer">table_chart</mat-icon>
            Tablas
        </button>
    </div>
    <div>
        <input
            class="input-hidden"
            type="file"
            (change)="onFileChange($event)"
            #input_file
            title="File"
        />
        <button class="drawer-button" mat-flat-button (click)="openFolderOptions(input_file)">
            <mat-icon class="mat-icon-drawer">folder</mat-icon>
            Archivo
        </button>
    </div>
    <div *ngIf="_utilsService.isSuperUser()">
        <button class="drawer-button" mat-flat-button (click)="openStatisticsTotal()">
            <mat-icon class="mat-icon-drawer">insert_chart</mat-icon>
            Estadísticas
        </button>
    </div>
    <div>
        <button class="drawer-button" mat-flat-button (click)="openSettings()">
            <mat-icon class="mat-icon-drawer">settings</mat-icon>
            Configuración
        </button>
    </div>
    <div>
        <button class="drawer-button" mat-flat-button (click)="openHelp()">
            <mat-icon class="mat-icon-drawer">help</mat-icon>
            Ayuda
        </button>
    </div>
</div>

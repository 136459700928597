/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, OnInit, Inject } from '@angular/core';
import { Company } from '../../../interfaces/company';
import { Agrupation, getAgrupationFormControls } from '../../../interfaces/agrupation';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ApiService } from '../../../services/api.service';
import { UtilsService } from '../../../services/utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
    selector: 'app-agrupation',
    templateUrl: './agrupation.component.html',
    styleUrls: ['./agrupation.component.scss'],
})
export class AgrupationComponent implements OnInit {
    loading: boolean = false;
    agrupationId: string = '';
    agrupation?: Agrupation;

    agrupationFormData: FormGroup = getAgrupationFormControls();

    agrupationSubcription$?: Subscription;

    percentage: number = 0;
    uploading = false;

    form: FormGroup;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _apiService: ApiService,
        private _utilsService: UtilsService,
        private spinner: NgxSpinnerService,
        public formBuilder: FormBuilder
    ) {
        this.form = this.formBuilder.group({
            image: [null],
        });

        this.agrupationId = data.agrupationId;
    }

    async ngOnInit(): Promise<void> {
        if (this.agrupationId) {
            this.showLoading(true);
            this.agrupationSubcription$ = this._apiService
                .getDocument(`agrupation`, this.agrupationId)
                .subscribe(async (doc: any) => {
                    if (!doc) {
                        this._utilsService.openSnackBar(
                            'Error obteniendo datos de agrupation',
                            'error'
                        );
                        this.showLoading(false);
                        return;
                    }
                    const agrupation = doc as Agrupation;
                    agrupation.id = parseInt(this.agrupationId);

                    this.agrupation = agrupation;

                    console.log(this.agrupation);

                    const agrupationFormData = getAgrupationFormControls();
                    const keys = Object.keys(agrupation);
                    for (let key of keys) {
                        let value: any = agrupation[key as keyof Agrupation];
                        if (value) {
                            if (this._utilsService.isValidDate(value)) {
                                value = value;
                            }
                            agrupationFormData.controls[key].setValue(value);
                        }
                    }
                    this.agrupationFormData = agrupationFormData;

                    this.showLoading(false);
                });
        }
    }

    ngOnDestroy(): void {
        this.agrupationSubcription$?.unsubscribe();
    }

    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this.spinner.show('agrupationSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this.spinner.hide('agrupationSpinner');
        }
    }

    saveFormData() {
        const keys = Object.keys(this.agrupationFormData.controls);
        let agrupation: any = {};
        for (let key of keys) {
            let value = this.agrupationFormData.controls[key].value; //this.agrupation[key as keyof Agrupation];
            try {
                if (moment.isMoment(value)) {
                    value = value.toDate();
                }
                agrupation![key as keyof Agrupation] = value;
            } catch (error) {}
        }
        agrupation!['date_time_modified'] = new Date();
        this.agrupation = agrupation;
        // console.log(this.agrupation);
    }

    async saveChanges() {
        this.showLoading(true);
        this.saveFormData();
        if (this.agrupationId) {
            //When is already created
            const result: boolean = await this._apiService.updateDocument(
                `agrupation`,
                this.agrupationId,
                this.agrupation
            );
            if (result) {
                this._utilsService.openSnackBar('Agrupación actualizada correctamente');
            } else {
                this._utilsService.openSnackBar('Error actualizando agrupación', 'error');
            }
        } else {
            // When is new agrupation
            try {
                const companyId = localStorage.getItem('company');
                this.agrupation!.company = parseInt(companyId!);
                const agrupationId = await this._apiService.addDocument(
                    `agrupation`,
                    this.agrupation
                );
                if (agrupationId) {
                    this.agrupation!.id = agrupationId;

                    const company = await this._apiService.getDocumentSync<Company>(
                        'company',
                        companyId!
                    );
                    if (company) {
                        if (!company.agrupations) company.agrupations = [];
                        company.agrupations.push(this.agrupation!);
                    }
                    await this._apiService.updateDocument('company', companyId!, company);

                    this._utilsService.openSnackBar('Agrupación añadida correctamente');
                } else {
                    this._utilsService.openSnackBar('Error añadiendo agrupación', 'error');
                }
            } catch (error) {
                this._utilsService.openSnackBar('Error añadiendo agrupación', 'error');
            }
        }
        this.showLoading(false);
        this._utilsService.closeAgrupationDialog(this.agrupation!);
    }
}

/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FilterNode, MiRutaFilter } from 'src/app/interfaces/mi-ruta-filter';
import { UtilsService } from 'src/app/services/utils.service';
import { faInbox } from '@fortawesome/free-solid-svg-icons';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MySqlService } from 'src/app/services/mysql.service';
import { FlatNode } from '../../../interfaces/flat-node';
import { FilterConfigurationDialogData } from '../../../interfaces/filter-configuration-dialog-data';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
    selector: 'app-filter-configuration',
    templateUrl: './filter-configuration.component.html',
    styleUrls: ['./filter-configuration.component.scss'],
})
/**
 * Represents the filter configuration component.
 */
export class FilterConfigurationComponent implements OnInit {
    /**
     * The name of the table.
     */
    @Input() table_name?: string;

    /**
     * The filter object.
     */
    @Input() filter?: MiRutaFilter;

    /**
     * Event emitter for filter change.
     */
    @Output() sendFilterChange: EventEmitter<MiRutaFilter>;

    /**
     * The form group for boolean configurations.
     */
    formGroup = this._formBuilder.group({
        restartFilters: false,
        showNotifications: true,
        openTaskInSameTab: false,
    });

    /**
     * Indicates whether the filter is not empty.
     */
    filterNotEmpty: boolean = false;

    /**
     * Font Awesome icon for inbox.
     */
    faInbox = faInbox;

    private _transformer = (node: FilterNode, level: number) => {
        return {
            expandable: !!node.children && node.children.length > 0,
            name: node.name,
            level: level,
        };
    };

    treeControl = new FlatTreeControl<FlatNode>(
        (node) => node.level,
        (node) => node.expandable
    );

    treeFlattener = new MatTreeFlattener(
        this._transformer,
        (node) => node.level,
        (node) => node.expandable,
        (node) => node.children
    );

    dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    /**
     * Initializes a new instance of the FilterConfigurationComponent class.
     * @param data - The dialog data.
     * @param _utilsService - The utility service.
     * @param _formBuilder - The form builder.
     * @param _mySqlService - The MySQL service.
     */
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: FilterConfigurationDialogData,
        private _utilsService: UtilsService,
        private _formBuilder: FormBuilder,
        private _mySqlService: MySqlService
    ) {
        this.setBooleanConfigurations();
        if (data.table_name) this.table_name = data.table_name;
        if (data.filter) this.filter = data.filter;
        this.sendFilterChange = new EventEmitter();
    }

    /**
     * Lifecycle hook that is called after data-bound properties of the component are initialized.
     */
    ngOnInit(): void {
        this.setBooleanConfigurationControls();
        this.filterNotEmpty = this._utilsService.isFilterValid(this.filter);
        
        const tree_data: FilterNode[] = this._utilsService.getConvertedFilterToNodes(
            this.table_name!,
            this.filter!
        );
        if (tree_data) this.dataSource.data = tree_data;
    }

    setBooleanConfigurationControls() {
        this.formGroup.controls['restartFilters'].valueChanges.subscribe(async (value: any) => {
            localStorage.setItem('restartFilters', value);
        });
        this.formGroup.controls['showNotifications'].valueChanges.subscribe(async (value: any) => {
            localStorage.setItem('showNotifications', value);
        });
        this.formGroup.controls['openTaskInSameTab'].valueChanges.subscribe(async (value: any) => {
            localStorage.setItem('openTaskInSameTab', value);
        });
    }
    /**
     * Gets whether the tree is expanded.
     * @returns True if the tree is expanded, false otherwise.
     */
    get isTreeExpanded(): boolean {
        return this.treeControl.dataNodes.some(node => this.treeControl.isExpanded(node));
    }

    /**
     * Sets the boolean configurations.
     */
    setBooleanConfigurations(){
        this.setBooleanConfiguration('restartFilters');
        this.setBooleanConfiguration('showNotifications');
        this.setBooleanConfiguration('openTaskInSameTab');
    }

    /**
     * Sets a boolean configuration.
     * @param configurationName - The name of the configuration.
     */
    setBooleanConfiguration(configurationName: string) {
        const value = localStorage.getItem(configurationName);
        if (value && value == 'true') this.formGroup.controls[configurationName].setValue(true);
        else this.formGroup.controls[configurationName].setValue(false);
    }
    
    /**
     * Handles the change event of the checkbox.
     * @param checked - Indicates whether the checkbox is checked.
     * @param node_name - The name of the node.
     */
    checkBoxChange(checked: boolean, node_name: string) {
        for (let i = 0; i < this.filter!.fields!.length; i++) {
            const fieldName = this._utilsService.getRightFieldName(
                this.table_name!,
                this.filter!.fields![i].field_name
            );
            if (node_name == fieldName) {
                this.filter!.fields![i].active = checked;
            }
        }
        this.sendFilterChange.emit(this.filter);
    }

    /**
     * Checks whether a node is checked.
     * @param node - The node to check.
     * @returns True if the node is checked, false otherwise.
     */
    isChecked(node: any): boolean {
        for (let i = 0; i < this.filter!.fields!.length; i++) {
            const fieldName = this._utilsService.getRightFieldName(
                this.table_name!,
                this.filter!.fields![i].field_name
            );
            if (node.name == fieldName) {
                return this.filter!.fields![i].active!;
            }
        }
        return false;
    }

    /**
     * Checks whether a node has child nodes.
     * @param _ - The index of the node.
     * @param node - The node to check.
     * @returns True if the node has child nodes, false otherwise.
     */
    hasChild = (_: number, node: FlatNode) => node.expandable;

    /**
     * Clears the filter.
     */
    clearFilter() {
        this.filter!.fields = [];
        this.filter!.Or_field = [];
        this.filter!.And_field = [];
        this.sendFilterChange.emit(this.filter);
        this.filterNotEmpty = false;
        if (this.table_name == this._mySqlService.tasksTableName) {
            sessionStorage.setItem('filterTasks', '');
        } else if (this.table_name == this._mySqlService.itacsTableName) {
            sessionStorage.setItem('filterItacs', '');
        } else if (this.table_name == this._mySqlService.countersTableName) {
            sessionStorage.setItem('filterCounters', '');
        } else if (this.table_name == this._mySqlService.waterRoutesTableName) {
            sessionStorage.setItem('filterWaterRoutes', '');
        } else if (this.table_name == this._mySqlService.sidesTableName) {
            sessionStorage.setItem('filterSides', '');
        }
    }
}

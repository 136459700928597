import { FormControl, FormGroup } from '@angular/forms';

export interface TypeRadius {
    radio: string;
    codigo_radio: string;
    date_time_modified: Date;
    id: number;
}

export function getTypeRadiusDisplayColumns(): string[] {
    const displayedColumns: string[] = ['Código radio', 'Radio', 'Fecha de Modificación'];
    return displayedColumns;
}

export function getTypeRadiusField(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'Código radio':
            value = 'codigo_radio';
            break;
        case 'Radio':
            value = 'radio';
            break;
        case 'Fecha de Modificación':
            value = 'date_time_modified';
            break;
    }
    return value;
}

export function getTypeRadiusFieldName(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'codigo_radio':
            value = 'Código radio';
            break;
        case 'radio':
            value = 'Radio';
            break;
        case 'date_time_modified':
            value = 'Fecha de Modificación';
            break;
    }
    return value;
}

export function getTypeRadiusFormControls(): FormGroup {
    const typeRadiusFormData: FormGroup = new FormGroup({
        radio: new FormControl(),
        codigo_radio: new FormControl(),
        date_time_modified: new FormControl(),
        id: new FormControl(),
    });
    return typeRadiusFormData;
}

export function getTypeRadiusFieldType(field_name: string): string {
    //in bigquery
    let value: string = '';
    switch (field_name) {
        case 'codigo_radio':
            value = 'string';
            break;
        case 'radio':
            value = 'string';
            break;
        case 'date_time_modified':
            value = 'Date';
            break;
        case 'id':
            value = 'number';
            break;
    }
    return value;
}

export function getTypeRadiusExcelFieldName(excel_field: string): string {
    let value: string = '';
    switch (excel_field.trim().toLowerCase()) {
        case 'radio':
            value = 'radio';
            break;
        case 'código radio':
            value = 'codigo_radio';
            break;
        case 'codigo radio':
            value = 'codigo_radio';
            break;
        case 'fecha de modificación':
            value = 'date_time_modified';
            break;
        case 'fecha de modificacion':
            value = 'date_time_modified';
            break;
        case 'codigo_radio':
            value = 'codigo_radio';
            break;
        case 'date_time_modified':
            value = 'date_time_modified';
            break;
        case 'codigo_radio':
            value = 'codigo_radio';
            break;
        case 'date_time_modified':
            value = 'date_time_modified';
            break;
    }
    return value;
}

export function getTypeRadiusExcelExportColumns(): string[] {
    const columns = ['Código radio', 'Radio', 'Fecha de Modificación'];
    return columns;
}

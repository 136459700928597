<mat-dialog-content>

    <div [formGroup]="itacFormData">

        <div class="container">

            <div class="row bd-highlight" class="row-content-center">
                
                <p style="font-size: large; margin: 6px; margin-bottom: 20px;">
                    Sección 3 Estado de conservación y Diseño de zona de trabajo
                </p>

                <div class="row">
                    <mat-form-field appearance="fill">
                        <mat-label>Espacio Para Trabajar</mat-label>
                        <mat-select formControlName="espacio_para_trabajar">
                            <mat-option *ngFor="let option of ['Cómodo','Justo','Incómodo','Nulo']" [value]="option">{{ option }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Desague</mat-label>
                        <mat-select formControlName="desague" #selectDrain>
                            <mat-option *ngFor="let option of ['Si','No','Sin Comprobar']" [value]="option">{{ option }}</mat-option>
                        </mat-select>
                    </mat-form-field> 
                    <mat-form-field *ngIf="selectDrain.value == 'Si'" appearance="fill">
                        <mat-label>Estado de desague</mat-label>
                        <mat-select formControlName="extras_desague">
                            <mat-option *ngFor="let option of ['Funciona Bien','No Operativo','Sin Comprobar']" [value]="option">{{ option }}</mat-option>
                        </mat-select>
                    </mat-form-field> 
                    <mat-form-field appearance="fill">
                        <mat-label>Iluminación</mat-label>
                        <mat-select formControlName="iluminacion" #selectIllumination>
                            <mat-option *ngFor="let option of ['Natural','Suficiente','Poca','Nula']" [value]="option">{{ option }}</mat-option>
                        </mat-select>
                    </mat-form-field> 
                    <mat-form-field *ngIf="selectIllumination.value == 'Poca' || selectIllumination.value == 'Nula'" appearance="fill">
                        <mat-label>Estado de iluminación</mat-label>
                        <mat-select formControlName="extras_iluminacion">
                            <mat-option *ngFor="let option of ['Cambiar Bombilla','Llevar Luz Propia','Otros']" [value]="option">{{ option }}</mat-option>
                        </mat-select>
                    </mat-form-field> 
                </div>
                
                <div class="row">
                    <mat-form-field style="flex: 3;" appearance="fill">
                        <mat-label>Nota</mat-label>
                        <textarea matInput formControlName="estado_de_conservacion_nota"
                            placeholder="Nota"
                            cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="3">
                        </textarea>
                    </mat-form-field>  
                </div>                    

            </div>

        </div>
    </div>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false">Cancelar</button>
    <button mat-button cdkFocusInitial
    (click)="saveData()"> 
        <mat-icon color="primary">check</mat-icon> Actualizar
    </button>
</mat-dialog-actions>

<ngx-spinner [bdColor] = "(itac)?'rgba(0, 0, 0, 0.2)': 'rgba(255, 255, 255, 1)'" size = "large" color = "#368DCE" 
type = "ball-scale-multiple" [fullScreen] = "true" name="itacSpinner">
</ngx-spinner>
/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faBuilding, faFilePdf, faKey, faInbox } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { ApiService } from '../../../services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessagingService } from 'src/app/services/messaging.service';

import * as moment from 'moment';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Company, getCompanyFormControls } from 'src/app/interfaces/company';
import { Manager } from 'src/app/interfaces/manager';
import { Info } from '../../../interfaces/info';

@Component({
    selector: 'app-company',
    templateUrl: './company.component.html',
    styleUrls: ['./company.component.scss'],
})
export class CompanyComponent implements OnInit {
    faFilePdf = faFilePdf;
    faInbox = faInbox;
    faBuilding = faBuilding;
    faKey = faKey;
    loading: boolean = false;
    companyId: string = '';
    company?: Company;

    companyFormData: FormGroup = getCompanyFormControls();

    companySubcription$?: Subscription;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _apiService: ApiService,
        private _utilsService: UtilsService,
        private spinner: NgxSpinnerService,
        private _messagingService: MessagingService
    ) {
        this.companyId = data.companyId;
        // this.activatedRoute.params.subscribe((params)=>{
        //   this.companyId = params['id'];
        // });
    }

    async ngOnInit(): Promise<void> {
        if (this.companyId) {
            this.showLoading(true);

            this.companySubcription$ = this._apiService
                .getDocument('company', this.companyId)
                .subscribe(async (doc: any) => {
                    if (!doc) {
                        this._utilsService.openSnackBar(
                            'Error obteniendo datos de company',
                            'error'
                        );
                        this.showLoading(false);
                        return;
                    }
                    const company = doc as Company;
                    company.id = parseInt(this.companyId);

                    this.company = company;

                    console.log(this.company);

                    const companyFormData = getCompanyFormControls();
                    const keys = Object.keys(company);
                    for (let key of keys) {
                        let value: any = company[key as keyof Company];
                        if (value) {
                            if (this._utilsService.isValidDate(value)) {
                                value = value;
                            }
                            companyFormData.controls[key].setValue(value);
                        }
                    }
                    this.companyFormData = companyFormData;

                    this.showLoading(false);

                    // this.companyFormData.controls['codigo_empresa'].valueChanges.subscribe(async (value: any) => {
                    // });
                });
        }
    }

    ngOnDestroy(): void {
        this.companySubcription$?.unsubscribe();
    }

    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this.spinner.show('companySpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this.spinner.hide('companySpinner');
        }
    }

    saveFormData() {
        const keys = Object.keys(this.companyFormData.controls);
        let company: any = {};
        for (let key of keys) {
            let value = this.companyFormData.controls[key].value; //this.company[key as keyof Company];
            try {
                if (moment.isMoment(value)) {
                    value = value.toDate();
                }
                company![key as keyof Company] = value;
            } catch (error) {}
        }
        company!['date_time_modified'] = new Date();
        this.company = company;
        // console.log(this.company);
    }

    async saveChanges() {
        this.showLoading(true);
        this.saveFormData();
        if (this.companyId) {
            const result: boolean = await this._apiService.updateDocument(
                'company',
                this.companyId,
                this.company
            );
            if (result) {
                this._utilsService.openSnackBar('Empresa actualizada correctamente');
            } else {
                this._utilsService.openSnackBar('Error actualizando empresa', 'error');
            }
        } else {
            if (
                !this.companyFormData.controls['codigo_empresa'].value ||
                !this.companyFormData.controls['nombre_empresa'].value
            ) {
                this._utilsService.openSnackBar(
                    'Inserte el código y el nombre de la empresa',
                    'warning'
                );
                this.showLoading(false);
                return;
            }
            const companiesString = localStorage.getItem('companys');
            if (companiesString) {
                const companies = JSON.parse(companiesString);
                if (companies && companies.length > 0) {
                    for (let company of companies) {
                        if (
                            company.codigo_empresa ===
                            this.companyFormData.controls['codigo_empresa'].value
                        ) {
                            this._utilsService.openSnackBar(
                                'Ya esta registrado este código de empresa',
                                'warning'
                            );
                            this.showLoading(false);
                            return;
                        }
                        if (
                            company.nombre_empresa ===
                            this.companyFormData.controls['nombre_empresa'].value
                        ) {
                            this._utilsService.openSnackBar(
                                'Ya esta registrado este nombre de empresa',
                                'warning'
                            );
                            this.showLoading(false);
                            return;
                        }
                    }
                }
            }
            try {
                const managerId = await this.createDefaultManager(); //On this type of relation the object needs to be created to add it later to the original class
                if (managerId) {
                    this.company!.managers = [{ id: managerId }];
                    console.log('============= this.company =============');
                    console.log(this.company);
                    const companyId = await this._apiService.addDocument('company', this.company);

                    try {
                        await this._apiService.updateDocument('manager', managerId.toString(), {
                            company: companyId,
                        });
                    } catch (err) {
                        console.log('============= err =============');
                        console.log(err);
                    }

                    console.log('============= companyId =============');
                    console.log(companyId);

                    if (companyId) {
                        this.company!.id = companyId;
                        try {
                            await this.createDefaultInfos(companyId, managerId);
                        } catch (err) {}
                        try {
                            const userId = this._apiService.getLoggedUserId();
                            if (userId) {
                                const user = await this._apiService.getUser(userId);
                                if (user) {
                                    user.companies.push({ id: companyId });
                                    user.managers.push({ id: managerId });
                                    this._apiService.updateDocument('user', userId, {
                                        companies: user.companies,
                                        managers: user.managers,
                                    });
                                }
                            }
                        } catch (err) {}
                        this._utilsService.openSnackBar('Empresa añadida correctamente');
                    } else {
                        this._utilsService.openSnackBar('Error añadiendo empresa', 'error');
                        this.company = undefined;
                    }
                } else {
                    this._utilsService.openSnackBar('Error añadiendo empresa', 'error');
                    this.company = undefined;
                }
            } catch (err) {
                console.log('============= err =============');
                console.log(err);
                this._utilsService.openSnackBar('Error añadiendo empresa', 'error');
                this.company = undefined;
            }
        }
        this.showLoading(false);
        this._utilsService.closeCompanyDialog(this.company!);
    }

    async createDefaultManager() {
        const defaultManager: Manager = {
            date_time_modified: new Date(),
            gestor: `Gestor por defecto de ${this.company!.nombre_empresa}`,
            codigo_gestor: '001',
        };
        const managerId = await this._apiService.addDocument('manager', defaultManager);
        if (managerId) {
            setTimeout(() => {
                this._utilsService.openSnackBar('Se ha añadido un gestor por defecto');
            }, 3000);
        } else {
            setTimeout(() => {
                this._utilsService.openSnackBar('Error añadiendo gestor por defecto', 'error');
            }, 3000);
        }
        return managerId;
    }
    async createDefaultInfos(companyId: number, managerId: number) {
        const defaultInfo: Info = {
            date_time_modified: new Date(),
            info: `Información por defecto de ${this.company!.nombre_empresa}`,
            codigo_info: this.company?.codigo_empresa,
            lastIDOrden: 0,
            lastIDExportacion: 0,
            lastIDSAT: 0,
            company: companyId,
            manager: managerId,
        };
        const infoId = await this._apiService.addDocument('info', defaultInfo);
        if (infoId) {
            setTimeout(() => {
                this._utilsService.openSnackBar('Se ha añadido información por defecto');
            }, 4000);
        } else {
            setTimeout(() => {
                this._utilsService.openSnackBar('Error añadiendo información por defecto', 'error');
            }, 4000);
        }
        return infoId;
    }
}

<mat-dialog-content class=" animate__animated animate__fadeIn">
    <div [formGroup]="zoneFormData"
    class=" animate__animated animate__fadeIn">

        <div>

            <div class="container">

                <div class="row bd-highlight" class="row-content-center">

                    <div>
                        <div style="margin-top: 5px; margin-bottom: 30px; margin-left: 5px;">                        
                            <p style="font-size: large;">Datos de zona</p>
                        </div> 
                        <div>

                            <div class="row-content">

                                <mat-form-field style="width: 120px;" appearance="fill">
                                    <mat-label>Código sector</mat-label>
                                    <input formControlName="codigo_zona" matInput type="text" placeholder="Código sector">
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Zona</mat-label>
                                    <input formControlName="zona" matInput type="text" placeholder="Zona">
                                </mat-form-field>
                                <mat-form-field style="max-width: 140px;" appearance="fill">
                                    <mat-label>Bloque</mat-label>
                                    <mat-select formControlName="bloque">
                                      <mat-option *ngFor="let option of ['', '1','2','3','4','5']" [value]="option">{{ (option)?option:'Ninguno' }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <button mat-raised-button class="map-button"
                                    matTooltip="Mostrar posición en mapa" (click)="openZoneLocation()">
                                    <mat-icon>place</mat-icon>
                                </button>

                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>

    <ngx-spinner [bdColor] = "(zone)?'rgba(0, 0, 0, 0.2)': 'rgba(255, 255, 255, 1)'" size = "large" color = "#368DCE" 
    type = "ball-scale-multiple" [fullScreen] = "false" name="zoneSpinner">
    </ngx-spinner>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="zone">Cancelar</button>
    <button mat-button cdkFocusInitial
    matTooltip="Guardar cambios" 
    (click)="saveChanges()"> 
        <mat-icon color="primary">check</mat-icon> {{ (zoneId)?'Actualizar':'Añadir' }}
    </button>
</mat-dialog-actions>

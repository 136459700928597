<mat-dialog-content class="animate__animated animate__fadeIn">
    <div [formGroup]="typeRadiusFormData" class="animate__animated animate__fadeIn">
        <div>
            <div class="container">
                <div class="row bd-highlight" class="row-content-center">
                    <div>
                        <div style="margin-top: 5px; margin-bottom: 30px; margin-left: 5px">
                            <p style="font-size: large">Datos de radio</p>
                        </div>
                        <div>
                            <div class="row-content">
                                <mat-form-field style="width: 120px" appearance="fill">
                                    <mat-label>Código radio</mat-label>
                                    <input
                                        formControlName="codigo_radio"
                                        matInput
                                        type="text"
                                        placeholder="Código radio"
                                    />
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Tipo</mat-label>
                                    <input
                                        formControlName="radio"
                                        matInput
                                        type="text"
                                        placeholder="Tipo"
                                    />
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ngx-spinner
        [bdColor]="typeRadius ? 'rgba(0, 0, 0, 0.2)' : 'rgba(255, 255, 255, 1)'"
        size="large"
        color="#368DCE"
        type="ball-scale-multiple"
        [fullScreen]="false"
        name="typeRadiusSpinner"
    >
    </ngx-spinner>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="typeRadius">Cancelar</button>
    <button mat-button cdkFocusInitial matTooltip="Guardar cambios" (click)="saveChanges()">
        <mat-icon color="primary">check</mat-icon> {{ typeRadiusId ? 'Actualizar' : 'Añadir' }}
    </button>
</mat-dialog-actions>

/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import {
    ActivationLog,
    getActivationLogDisplayColumns,
    getActivationLogExcelExportColumns,
    getActivationLogExcelFieldName,
    getActivationLogField,
    getActivationLogFieldType,
} from 'src/app/interfaces/activation-log';
import { UtilsService } from 'src/app/services/utils.service';
import { WindowRefService } from 'src/app/services/window-ref.service';
import * as XLSX from 'xlsx';
import { faInbox, faBroadcastTower } from '@fortawesome/free-solid-svg-icons';
import { MySqlService } from '../../../services/mysql.service';
import { IpcService } from 'src/app/services/ipc.service';
import { ElectronService } from 'ngx-electron';

@Component({
    selector: 'app-activation-logs',
    templateUrl: './activation-logs.component.html',
    styleUrls: ['./activation-logs.component.scss'],
})
export class ActivationLogsComponent implements OnInit {
    @ViewChild('drawer') homeDrawer?: any;

    faBroadcastTower = faBroadcastTower;
    faInbox = faInbox;
    loading: boolean = true;
    activationLogs: ActivationLog[] = [];
    dataSource: MatTableDataSource<ActivationLog> = new MatTableDataSource();

    length = 0; //activationLog count in current table
    pageSize = 50; //limit of query
    scrollOffset: number = 50;
    rowsLimit: number = 50;
    lastPageIndex = 0;
    pageSizeOptions: number[] = [10, 50, 100, 200, 500];

    tableName: string = 'activationLogs';
    displayedColumns: string[];
    fixedColumns = [];
    displayedColumnsField: string[];
    clickedRows = new Set<ActivationLog>();
    allSelected = false;

    lastSelectedRow: number = -1;

    filteredColumn?: string;
    orderedColumn?: string;

    serial_number?: string;

    loadingText = 'Cargando...';

    is_pendent_activations?: boolean;

    menuOptions: string[] = ['Exportar a EXCEL'];

    /**
     * Constructs an instance of the ActivationLogsComponent.
     * @param _mySqlService - The MySqlService instance.
     * @param _utilsService - The UtilsService instance.
     * @param _windowRefService - The WindowRefService instance.
     * @param router - The Router instance.
     * @param route - The ActivatedRoute instance.
     * @param _electronService - The IpcService instance.
     * @param _electronServiceAnswer - The ElectronService instance.
     * @param _spinner - The NgxSpinnerService instance.
     */
    constructor(
        private _mySqlService: MySqlService,
        private _utilsService: UtilsService,
        private _windowRefService: WindowRefService,
        private router: Router,
        private route: ActivatedRoute,
        private _electronService: IpcService,
        private _electronServiceAnswer: ElectronService,
        private _spinner: NgxSpinnerService
    ) {
        this.route.params.subscribe((params) => {
            this.serial_number = params['serial'];
        });
        this.pageSize = parseInt(
            localStorage.getItem('activationLog_pageSize') || `${this.rowsLimit}`
        );

        this.displayedColumns = getActivationLogDisplayColumns();
        const displayedColumns = localStorage.getItem('displayedColumns_' + this.tableName);
        if (this._electronService.isElectronApp()) {
            if (!displayedColumns || displayedColumns.length == 0)
                this._electronService.sendMessage('load-columns', {
                    table: this.tableName,
                });
            this._electronServiceAnswer.ipcRenderer.on(
                `send-load-columns-${this.tableName}`,
                async (event: any, arg: any) => {
                    try {
                        const decode = this._utilsService.decodeBase64(arg);
                        this.displayedColumns = JSON.parse(decode);
                        localStorage.setItem(
                            'displayedColumns_' + this.tableName,
                            JSON.stringify(this.displayedColumns)
                        );
                        this.reload();
                    } catch (err) {}
                    this._electronServiceAnswer.ipcRenderer.send(
                        'sync-reply',
                        'received and answer'
                    );
                }
            );
        }
        if (displayedColumns) {
            try {
                const columns = JSON.parse(displayedColumns);
                if (Array.isArray(columns)) this.displayedColumns = columns;
                else this.displayedColumns = getActivationLogDisplayColumns();
            } catch (err) {
                this.displayedColumns = getActivationLogDisplayColumns();
            }
        } else {
            this.displayedColumns = getActivationLogDisplayColumns();
            localStorage.setItem(
                'displayedColumns_' + this.tableName,
                JSON.stringify(this.displayedColumns)
            );
        }

        this.displayedColumnsField = this.displayedColumns.map((displayedColumn: string) =>
            getActivationLogField(displayedColumn)
        );
        document.addEventListener('visibilitychange', async () => {
            if (document.hidden) {
            } else {
                const updateNeeded = localStorage.getItem('activationLogUpdateNeeded');
                if (updateNeeded == 'true') {
                    this.scrollOffset = 50;
                    localStorage.setItem('activationLogUpdateNeeded', 'false');
                    this.showLoading(true);
                    this.setActivationLogsInTable(
                        await this._mySqlService.getLastActivationLogsPage()
                    );
                    this.showLoading(false);
                }
            }
        });
    }

    /**
     * Handles the scroll event and updates the data source.
     */
    async onScroll() {
        this.scrollOffset += this.rowsLimit;
        if (this.scrollOffset > this.pageSize) return;
        this.dataSource.data = [];
        this.dataSource.data = this.activationLogs.slice(0, this.scrollOffset);
    }

    /**
     * Initializes the component.
     * This method is called after the component has been created and initialized.
     * It clears the activation log filter and order, and retrieves the activation logs.
     * @returns A promise that resolves when the initialization is complete.
     */
    async ngOnInit(): Promise<void> {
        this.clearActivationLogFilterAndOrder();
        this.setDefaultOrder();
        await this.getActivationLogs();
    }

    /**
     * Sets the default order for the activation logs.
     */
    setDefaultOrder(): void {
        this.setOrder('id','DESC');
    }

    /**
     * Sets the order for the activation logs table based on the specified column and order type.
     * 
     * @param column - The column to order by.
     * @param orderType - The order type (e.g., 'asc' for ascending, 'desc' for descending).
     */
    setOrder(column: string, orderType: string): void {
        const orderedColumn = getActivationLogField(column);
        this._utilsService.processOrder(
            this._mySqlService.activationLogsTableName,
            this._utilsService.orderActivationLogs,
            orderedColumn,
            orderType
        )
    }

    /**
     * Clears the filter and order settings for activation logs.
     * This method resets the orderActivationLogs and filterActivationLogs properties
     * in the _utilsService and clears the corresponding session storage items.
     */
    clearActivationLogFilterAndOrder() {
        this._utilsService.orderActivationLogs = [];
        this._utilsService.filterActivationLogs = {};
        sessionStorage.setItem('orderActivationLogs', '');
        sessionStorage.setItem('filterActivationLogs', '');
    }

    /**
     * Reloads the current route.
     */
    reload() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['./'], { relativeTo: this.route });
    }

    /**
     * Sets the loading state and displays or hides the spinner accordingly.
     * @param state - A boolean value indicating whether to show or hide the loading spinner.
     */
    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this._spinner.show('innerSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this._spinner.hide('innerSpinner');
        }
    }

    /**
     * Sets the activation logs in the table.
     * 
     * @param activationLogs - An array of ActivationLog objects.
     */
    setActivationLogsInTable(activationLogs: ActivationLog[]) {
        this.dataSource.data = [];
        this.activationLogs = activationLogs;
        for (let [index, activationLog] of this.activationLogs.entries()) {
            activationLog.ID = index + 1;
        }
        this.dataSource.data = this.activationLogs.slice(0, this.rowsLimit);
        this.showLoading(false);
    }

    /**
     * Retrieves the activation logs.
     * If a serial number is provided, applies a filter based on the serial number.
     */
    async getActivationLogs() {
        if (!this.serial_number) this.setActivationLogsInTable(await this.selectActivationLogs());
        else await this.applyFilter([this.serial_number], 'counter_serial_number');
    }

    /**
     * Retrieves activation logs from the MySQL service.
     * 
     * @returns {Promise<any>} A promise that resolves to the activation logs.
     */
    async selectActivationLogs() {
        this.showLoading(true);
        this.length = await this._mySqlService.getActivationLogsCount('');
        const order = this._utilsService.orderActivationLogs;
        let order_clause = undefined;
        if (order.length > 0) {
            order_clause = this._utilsService.getOrderClauseFromOrder(order);
        }

        let offset = '0';
        const lastPageIndex = localStorage.getItem('lastPageIndexActivationLog');
        if (lastPageIndex) {
            localStorage.setItem('lastPageIndexActivationLog', '');
            this.lastPageIndex = parseInt(lastPageIndex);
            offset = (this.lastPageIndex * this.pageSize).toString();
        }

        return await this._mySqlService.getActivationLogs(
            undefined,
            undefined,
            order_clause,
            offset,
            this.pageSize.toString()
        );
    }

    /**
     * Selects all activation logs in the table.
     */
    async selectAll() {
        this.clickedRows.clear();
        const activationLogs = this.activationLogs;
        for (const activationLog of activationLogs) {
            if (!this.clickedRows.has(activationLog)) {
                this.clickedRows.add(activationLog);
            }
        }
        this.allSelected = true;
        this._utilsService.openSnackBar(`Seleccionadas ${this.clickedRows.size} activaciones`);
    }

    /**
     * Filters the data source based on the provided search value.
     * @param event - The search value to filter the data source.
     */
    searchValue(event: any) {
        const filterValue: string = event;
        this.dataSource!.filter = filterValue.trim().toLowerCase();
    }

    async pageEvent(event: any) {
        console.log(`length ${event.length}`);
        console.log(`pageSize ${event.pageSize}`);
        console.log(`pageIndex ${event.pageIndex}`);
        if (this.lastPageIndex != event.pageIndex) {
            this.showLoading(true);
            if (this.lastPageIndex < event.pageIndex) {
                this.setActivationLogsInTable(
                    await this._mySqlService.getNextActivationLogsPage(
                        event.pageIndex - this.lastPageIndex
                    )
                );
            } else {
                this.setActivationLogsInTable(
                    await this._mySqlService.getPreviousActivationLogsPage(
                        this.lastPageIndex - event.pageIndex
                    )
                );
            }
            this.lastPageIndex = event.pageIndex;
        }
        if (this.pageSize != event.pageSize) {
            this.pageSize = event.pageSize;
            localStorage.setItem('activationLog_pageSize', this.pageSize.toString());
            await this.getActivationLogs();
        }
        this.scrollOffset = 50;
    }

    async filterActivationLog(where?: string) {
        this.length = await this._mySqlService.getActivationLogsCount(where);
        const order = this._utilsService.orderActivationLogs;
        let order_clause = undefined;
        if (order.length > 0) {
            order_clause = this._utilsService.getOrderClauseFromOrder(order);
        }
        let activationLogs: ActivationLog[] = [];
        try {
            activationLogs = await this._mySqlService.getActivationLogs(
                undefined,
                where,
                order_clause,
                '0',
                this.pageSize.toString()
            );
        } catch (err) {
            console.log('============= err =============');
            console.log(err);
        }
        return activationLogs;
    }

    async applyFilter(values: any, column: string, not_empty: boolean = false, empties_checked: boolean = false) {
        let where_clause = this._utilsService.getWhereClauseFromFilter(
            this._utilsService.processFilter(
                this._utilsService.filterActivationLogs!,
                values,
                column,
                getActivationLogFieldType(column),
                this._mySqlService.activationLogsTableName,
                true, 
                not_empty,
                empties_checked
            )
        );
        this.showLoading(true);
        localStorage.setItem('lastPageIndexActivationLog', '');
        this.lastPageIndex = 0;
        this.setActivationLogsInTable(await this.filterActivationLog(where_clause));
    }

    async filterBy(column: string) {
        this.filteredColumn = getActivationLogField(column);
        if (getActivationLogFieldType(this.filteredColumn) == 'Date') {
            const dates = await this._utilsService.openDateRangeSelectorDialog(
                'Seleccione rango de fechas'
            );
            let times: Date[];
            try {
                times = await this._utilsService.openTimeRangeSelectorDialog(
                    'Seleccione rango de horas'
                );
                this.onDateSelected(dates, times);
            } catch (err) {
                this.onDateSelected(dates);
            }
        } else {
            const result = await this._utilsService.openFilterDialog(
                column,
                this.filteredColumn,
                this._mySqlService.activationLogsTableName,
                this._utilsService.filterActivationLogs
            );
            if (result && result.data) {
                this.applyFilter(result.data, result.column, result.not_empty, result.empties_checked);
            }
        }
    }

    /**
     * Handles the selection of a date range and applies the filter based on the selected dates.
     * @param dateRange - The selected date range.
     * @param timeRange - The optional selected time range.
     */
    async onDateSelected(dateRange: Date[], timeRange?: Date[]) {
        if (dateRange) {
            const values = this._utilsService.getDateRangeString(dateRange, timeRange);
            await this.applyFilter(values, this.filteredColumn!);
        } else {
            this._utilsService.openSnackBar('Rango fechas inválido', 'error');
        }
    }

    /**
     * Orders the activation logs based on the specified column and order type.
     * @param event - The event object containing the column and order type.
     */
    async orderBy(event: any) {
        const column = event.column;
        const orderType = event.orderType;

        const orderedColumn = getActivationLogField(column);
        const order_clause = this._utilsService.getOrderClauseFromOrder(
            this._utilsService.processOrder(
                this._mySqlService.activationLogsTableName,
                this._utilsService.orderActivationLogs,
                orderedColumn,
                orderType
            )
        );
        const filter = this._utilsService.filterActivationLogs;
        let where_clause = undefined;
        if (filter && filter.fields) {
            where_clause = this._utilsService.getWhereClauseFromFilter(filter);
        }
        this.showLoading(true);
        const activationLogs = await this._mySqlService.getActivationLogs(
            undefined,
            where_clause,
            order_clause,
            '0',
            this.pageSize.toString()
        );
        localStorage.setItem('lastPageIndexActivationLog', '');
        this.lastPageIndex = 0;
        this.setActivationLogsInTable(activationLogs);
    }

    /**
     * Adds a new row to the activation logs table.
     * 
     * @param event - The event object triggered by the user action.
     * @returns A Promise that resolves to void.
     */
    async addNewRow(event: any) {
        const activationLog = await this._utilsService.openActivationLogDialog('');
        console.log(activationLog);
        if (activationLog) {
            this.activationLogs.push(activationLog);
            this.dataSource.data = [];
            this.dataSource.data = this.activationLogs.slice(0, this.scrollOffset);
        }
    }

    /**
     * Handles the double click event on a row in the activation logs table.
     * @param row - The clicked row object.
     * @returns void
     */
    async doubleClickedRow(row: any) {
        localStorage.setItem('lastPageIndexActivationLog', this.lastPageIndex.toString());

        let activationLog = await this._utilsService.openActivationLogDialog(row.id);

        if (activationLog) {
            activationLog.ID = this.activationLogs[row.rowIndex].ID;
            this.activationLogs[row.rowIndex] = activationLog;
            this.dataSource.data = [];
            this.dataSource.data = this.activationLogs.slice(0, this.scrollOffset);
        }
    }

    /**
     * Handles the click event on a row in the activation logs table.
     * @param receivedEvent - The event object containing information about the clicked row.
     */
    clickedRow(receivedEvent: any) {
        const row = receivedEvent.row;
        const event = receivedEvent.event;
        const rowIndex = receivedEvent.rowIndex;

        const previousRow = this.lastSelectedRow;
        this.lastSelectedRow = rowIndex;

        if (event.button === 0) {
            if (!event.ctrlKey && !event.shiftKey) {
                this.allSelected = false;
                this.clickedRows.clear();
                this.toggleRow(row);
            } else if (event.ctrlKey) {
                this.toggleRow(row);
            }
            if (event.shiftKey) {
                this.selectRowsBetweenIndexes(previousRow, rowIndex);
                console.log('**************** event.shiftKey ***************');
            }
        }
        if (this._windowRefService.nativeWindow.getSelection) {
            //remove selection in table with shift
            if (this._windowRefService.nativeWindow.getSelection().empty) {
                // Chrome
                this._windowRefService.nativeWindow.getSelection().empty();
            } else if (this._windowRefService.nativeWindow.getSelection().removeAllRanges) {
                // Firefox
                this._windowRefService.nativeWindow.getSelection().removeAllRanges();
            }
        }
    }

    /**
     * Handles the selection of rows in the activation logs table.
     * 
     * @param event - The selection event containing the selected rows.
     */
    selectedRows(event: any) {
        this.allSelected = false;
        this.clickedRows.clear();
        this.selectRowsBetweenIndexes(event[0], event[1], false);
    }

    /**
     * Selects rows between two indexes and adds them to the clickedRows set.
     * If showSnackBar is true and more than one row is selected, it displays a snackbar with the number of selected rows.
     *
     * @param lastSelectedRow - The index of the last selected row.
     * @param rowIndex - The index of the current row.
     * @param showSnackBar - Optional. Specifies whether to show a snackbar. Default is true.
     */
    selectRowsBetweenIndexes(lastSelectedRow: number, rowIndex: number, showSnackBar: boolean = true) {
        let start, end;
        if (rowIndex > lastSelectedRow) {
            start = lastSelectedRow;
            end = rowIndex;
        } else {
            end = lastSelectedRow;
            start = rowIndex;
        }
        for (let i = start; i <= end; i++) {
            this.clickedRows.add(this.activationLogs[i]);
        }
        if (this.clickedRows.size > 1 && showSnackBar) {
            this._utilsService.openSnackBar(`Seleccionadas ${this.clickedRows.size} activaciones`);
        }
    }

    /**
     * Toggles the clicked state of a row.
     * If the row is already clicked, it will be unclicked.
     * If the row is not clicked, it will be clicked.
     * 
     * @param row - The row to toggle.
     */
    toggleRow(row: any) {
        if (this.clickedRows.has(row)) {
            this.clickedRows.delete(row);
        } else {
            this.clickedRows.add(row);
        }
    }

    /**
     * Exports the activation logs in a table format.
     * If there are clicked rows, it exports only the selected rows.
     * If there are no clicked rows, it exports all the activation logs.
     */
    exportActivationLogsInTable() {
        if (this.clickedRows.size > 0) this.exportExcel(this.clickedRows);
        else this.exportExcel(this.activationLogs);
    }

    /**
     * Exports the activation logs to an Excel file.
     * 
     * @param activationLogs - The activation logs to export.
     */
    exportExcel(activationLogs: any) {
        let excelFormatActivationLogs = [];
        for (let activationLog of activationLogs) {
            let data: any = {};
            const columns = getActivationLogExcelExportColumns();
            columns.forEach((column) => {
                data[column] = this._utilsService.fieldPipe(
                    activationLog[getActivationLogExcelFieldName(column)],
                    column
                );
            });
            excelFormatActivationLogs.push(data);
        }
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelFormatActivationLogs);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        /* save to file */
        XLSX.writeFile(wb, 'Activaciones_Exportados.xlsx');
    }

    /**
     * Updates the actual page information for the activation log.
     * 
     * @param activationLog - The activation log object.
     * @param keys - An array of keys to update in the activation log object.
     */
    updateActualPageInfo(activationLog: any, keys: any) {
        for (let row of this.clickedRows) {
            const index = this.activationLogs.indexOf(row, 0);
            for (let key of keys) {
                const keyIndex = key as keyof ActivationLog;
                let value = activationLog[keyIndex]!; //this.task[key as keyof Itac];
                row[keyIndex] = value as any;
            }
            this.activationLogs[index] = row;
        }
    }
}

import { Component, HostListener, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { WindowRefService } from 'src/app/services/window-ref.service';
import { IpcService } from 'src/app/services/ipc.service';
import { MySqlService } from 'src/app/services/mysql.service';
import { faInbox } from '@fortawesome/free-solid-svg-icons';
import { getSideField, getSideDisplayColumns, Side, getSideFieldType, getSideOrderField, getSideExcelFieldName, getSideExcelExportColumns } from '../../interfaces/side';
import { MiRutaUser } from '../../interfaces/mi-ruta-user';
import { WaterTask } from 'src/app/interfaces/water-task';
import { Itac } from '../../interfaces/itac';
import { Team } from '../../interfaces/team';
import { getFieldType, priority_status, task_status, getFieldName, getFilterDateTypes, getField } from '../../interfaces/water-task';
import { MiRutaFilter, deepCopyMiRutaFilter, deleteFilterField } from '../../interfaces/mi-ruta-filter';
import * as XLSX from 'xlsx';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-sides',
    templateUrl: './sides.component.html',
    styleUrls: ['./sides.component.scss']
})
export class SidesComponent implements OnInit {

    screenWidth: number = window.innerWidth;
    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.screenWidth = event.target.innerWidth;
    }

    tabs: string[] = [];
    selected = new FormControl(0);
    currentStatus: number = -1;
    additionalStatus: number = -1; //to add absents and dates to where clause

    loading: boolean = true;
    sides: Side[] = [];
    dataSource: MatTableDataSource<Side> = new MatTableDataSource();

    length = 0; //sides count in current table
    pageSize = 50; //limit of query
    scrollOffset: number = 50;
    rowsLimit: number = 50;
    lastPageIndex = 0;
    pageSizeOptions: number[] = [10, 50, 100, 200, 500];

    tableName: string = 'sides';
    displayedColumns: string[] = [];
    fixedColumns = [];
    displayedColumnsField: string[];
    clickedRows = new Set<Side>();
    allSelected = false;
    lastSelectedRow: number = -1;

    filteredColumn?: string;
    orderedColumn?: string;
    faInbox = faInbox;

    loadingText = 'Cargando...';

    menuOptions: string[] = ['Ver tareas de bandos','Asignar a operario', 'Asignar equipo', 'Añadir aviso portal', 'Asignar acceso'];

    imageLogo?: string;

    timerInputChanged: any;

    constructor(
        private _mySqlService: MySqlService,
        public _utilsService: UtilsService,
        private _windowRefService: WindowRefService,
        private _spinner: NgxSpinnerService,
        private _apiService: ApiService,
        private _electronService: IpcService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.pageSize = parseInt(localStorage.getItem('side_pageSize') || `${this.rowsLimit}`);
        this.displayedColumns = getSideDisplayColumns();
        this.displayedColumnsField = this.displayedColumns.map((displayedColumn: string) =>
            getSideField(displayedColumn)
        );
        this.tabs = this._utilsService.getTabs();
    }

    async ngOnInit(): Promise<void> {
        await this._apiService.getSectors();
        this._utilsService.processOrder(
            this._mySqlService.sidesTableName,
            this._utilsService.orderSides,
            'codigo_de_geolocalizacion',
            'ASC'
        );
    }
    
    async onScroll() {
        this.scrollOffset += this.rowsLimit;
        if (this.scrollOffset > this.pageSize) return;
        this.dataSource.data = [];
        this.dataSource.data = this.sides.slice(0, this.scrollOffset);
    }

    async onTabChanged(index: number, initIndex: boolean = true) {
        if (this.selected.value != index) this.selected.setValue(index);
        if (initIndex) {
            this.lastPageIndex = 0;
            localStorage.setItem('lastPageIndexSide', '');
        }
        this.scrollOffset = 50;
        this.setSidesInTable(await this.selectSides(this.tabs[this.selected.value]));
    }
    
    async getTaskTypeSummaryByEmplacement() {
        if(this.sides.length) {
            let codes: string[] = this.sides.map((side: Side)=> side.codigo_de_geolocalizacion);
            const absent: boolean = this.currentStatus == task_status.IDLE && this.additionalStatus == 1;
            const pendent_date: boolean = this.currentStatus == task_status.IDLE && this.additionalStatus == 2;
            const totalArray = await this._mySqlService.getTaskTypeSummaryByEmplacement(
                codes, this.currentStatus, absent, pendent_date
            );
            for (const side of this.sides) {
                side.total = '';
                const matches = totalArray.filter(item => item.codigo_de_geolocalizacion === side.codigo_de_geolocalizacion);
                for(const match of matches) {
                    if(side.total) side.total += `, ${match['total']} ${match['tipo_tarea']}`;
                    else side.total = `${match['total']} ${match['tipo_tarea']}`;
                }
            }
        }
    }

    async onMenuOptionSelected(option: string) {
        if (option == 'Ver tareas de bandos') await this.openTasksScreen();
        else if (option == 'Asignar a operario') await this.assignOperatorToTasks();
        else if (option == 'Asignar equipo') await this.assignTeamToSides();
        else if (option == 'Añadir aviso portal') await this.assignDateToSides();
        else if (option == 'Asignar acceso') await this.assignAccessToSides();
    }

    async assignOperatorToTasks() {
        if (this.nothingSelectedWarning()) return;
        try {
            const users = await this._apiService.selectUsers();
            const userId = this._apiService.getLoggedUserId();
            if (users) {
                this._spinner.show();
                let codes: string[] = [];
                let missing_codes: string[] = [];
                for (let row of this.clickedRows) {
                    if (row.codigo_de_geolocalizacion) codes.push(row.codigo_de_geolocalizacion);
                    
                    if(this._utilsService.isFieldValid(row.codigo_itac)) continue;
                    missing_codes.push(row.codigo_de_geolocalizacion);
                }
                await this.createMissingCodes(missing_codes);
                this.loadingText = `Asignando operarios a bandos ...`;
                await this.updateTasksUsers(users, codes, userId);
                await this.updateItacsUsers(users, codes, userId);
                this._spinner.hide();
            }
        } catch (err) {}
    }

    resetItacPriority(itac: Itac) {
        if(itac && (itac.hibernacion || itac.prioridad == priority_status.HIBERNATE)){
            let data: any = {};
            if(itac.end_hibernation_priority) data['prioridad'] = itac.end_hibernation_priority;
            else data['prioridad'] = priority_status.LOW;
            data['hibernacion'] = false;
            return data;
        }
        return {}
    }

    async updateItacsDate(dateData: any, codes: string[], userId: string){
        let errorIds = [];
        const itacs = await this._apiService.getItacs([['codigo_itac', '==', codes]])
        const ids: number[] = itacs.map((itac: Itac)=> itac.id!);
        try {
            const data = { ...dateData , ultima_modificacion: userId };
            const result = await this._apiService.updateItacs(ids, data, false);
            if (!result) errorIds = ids;
        } catch (err) {
            errorIds = ids;
        }
        if (errorIds.length > 0) this._utilsService.openSnackBar(`Hubo errores asignando itacs`, 'error');
        else this._utilsService.openSnackBar(`Itacs asignadas correctamente`);
    }

    /**
     * Updates the date of an ITAC (Internal Technical Assistance Call) and returns a boolean indicating the success of the update.
     * @param dateData - The new date data to be updated.
     * @param itac - The ITAC object to be updated.
     * @param userId - The ID of the user performing the update.
     * @returns A boolean indicating whether the update was successful or not.
     */
    async updateItacDate(dateData: any, itac: Itac, userId: string){
        if(itac){
            dateData = { ...dateData , ...this.resetItacPriority(itac) };
            try {
                const data = { ...dateData , ultima_modificacion: userId };
                return await this._apiService.updateItac(itac.id!.toString(), data, false);
            } catch (err) {}
        }
        return false;
    }

    /**
     * Updates the date of a task and returns the result of the update operation.
     * @param dateData - The new date data for the task.
     * @param task - The task to update.
     * @param userId - The ID of the user performing the update.
     * @returns A promise that resolves to the result of the update operation.
     */
    async updateTaskDate(dateData: any, task: WaterTask, userId: string){
        if(task){
            dateData = { ...dateData , ...this._utilsService.resetTaskPriority(task) };
            try {
                const data = { ...dateData , ultima_modificacion: userId };
                return await this._apiService.updateTask(task.id!.toString(), data, false);
            } catch (err) {}
        }
        return false;
    }

    /**
     * Assigns access to sides.
     * 
     * @returns {Promise<void>} A promise that resolves when the access is assigned.
     */
    async assignAccessToSides(): Promise<void> {
        if (this.nothingSelectedWarning()) return;
        try {
            const access = await this._utilsService.openInputSelectorDialog('Inserte acceso', '');
            if(access){
                this._spinner.show();
                const codes: string[] = [];
                let missing_codes: string[] = [];
                for (let row of this.clickedRows) {
                    if (row.codigo_de_geolocalizacion) codes.push(row.codigo_de_geolocalizacion);
                    
                    if(this._utilsService.isFieldValid(row.codigo_itac)) continue;
                    missing_codes.push(row.codigo_de_geolocalizacion);
                } 
                await this.createMissingCodes(missing_codes);
                this.loadingText = `Asignando acceso a bandos ...`;
                const itacs: Itac[] = await this._apiService.getItacs([['codigo_itac', '==', codes]]);

                const tasks: WaterTask[]  = await this._apiService.getTasks([['codigo_de_geolocalizacion', '==', codes]]);
    
                const userId = this._apiService.getLoggedUserId();
                const dataTask = { acceso_devuelto: access, ultima_modificacion: userId };
                const dataItac = { acceso: access, ultima_modificacion: userId };
                if(itacs && itacs.length) await this._apiService.updateItacs(itacs.map((i:Itac)=>i.id!), dataItac);
                if(tasks && tasks.length) await this._apiService.updateTasks(tasks.map((t:WaterTask)=>t.id!), dataTask);

                this.updateAccessInTable(dataTask);

                this._spinner.hide();
            }
        } catch (err) {
            
        }
    }

    /**
     * Updates the access in the table for the clicked rows.
     * @param data - The data containing the updated access information.
     */
    updateAccessInTable(data: any): void {
        for (let side of this.clickedRows) {
            side.acceso_devuelto = data['acceso_devuelto'];
        }
    }

    /**
     * Assigns a date to the sides.
     * 
     * This method checks if a warning is displayed when nothing is selected. 
     * If not, it retrieves the logged user's ID and prompts the user to select a date for the appointment.
     * The selected date is then assigned to the sides, along with the user's ID as the last modification.
     * 
     * If there are any missing codes in the clicked rows, it creates those missing codes.
     * It then retrieves the ITACs (Individual Time and Attendance Card) based on the codes and updates their dates.
     * If any errors occur during the update, it keeps track of the error IDs.
     * Finally, it updates the dates in the table and displays a success or error message.
     * 
     * @returns {Promise<void>} A promise that resolves when the assignment is complete.
     */
    async assignDateToSides(): Promise<void> {
        if (this.nothingSelectedWarning()) return;
        const userId = this._apiService.getLoggedUserId();
        let data = await this._utilsService.selectDateAppointment(undefined, true);
        if(data) {
            data = { ...data, ultima_modificacion: userId };
            this._spinner.show();
            const codes: string[] = [];
            let missing_codes: string[] = [];
            for (let row of this.clickedRows) {
                if (row.codigo_de_geolocalizacion) codes.push(row.codigo_de_geolocalizacion);

                if(this._utilsService.isFieldValid(row.codigo_itac)) continue;
                missing_codes.push(row.codigo_de_geolocalizacion);
            }
            await this.createMissingCodes(missing_codes);
            this.loadingText = `Asignando cita a bandos ...`;
            const itacs: Itac[] = await this._apiService.getItacs([['codigo_itac', '==', codes]])
            const errorIds = []
            for(const itac of itacs) {
                const res = await this.updateItacDate(data, itac, userId);
                if(!res) errorIds.push(itac.id);
            }
            this.updateDatesInTable(data);
            if(errorIds.length) this._utilsService.openSnackBar(`Erorres asignando bandos`, 'error');
            else this._utilsService.openSnackBar(`Bandos asignados correctamente`);
            this._spinner.hide();
        }
    }

    /**
     * Creates missing codes using the provided array of codes.
     * @param codes - An array of codes to create missing itacs from.
     * @returns A Promise that resolves when all itacs have been created.
     */
    async createMissingCodes(codes: string[]): Promise<void> {
        for(const [index, code] of codes.entries()){
            this.loadingText = `Creando itacs desde bandos (${index+1}/${codes.length})...`;
            await this.createItac(code);
        }
    }

    /**
     * Updates the dates in the table for the clicked rows.
     * @param data - The data containing the new dates.
     */
    updateDatesInTable(data: any): void {
        for (let side of this.clickedRows) {
            side.advice_portal = data['fecha_hora_cita'];
            side.advice_portal_end = data['fecha_hora_cita_end'];
        }
    }

    /**
     * Assigns a team to the selected sides.
     * 
     * @returns {Promise<void>} A promise that resolves when the team is assigned to the sides.
     */
    async assignTeamToSides(): Promise<void> {
        if (this.nothingSelectedWarning()) return;
        try {
            const team: Team | null = await this._apiService.selectTeam();
            const userId = this._apiService.getLoggedUserId();
            if (team) {
                this._spinner.show();
                let codes: string[] = [];
                let missing_codes: string[] = [];
                for (let row of this.clickedRows) {
                    if (row.codigo_de_geolocalizacion) codes.push(row.codigo_de_geolocalizacion);

                    if(this._utilsService.isFieldValid(row.codigo_itac)) continue;
                    missing_codes.push(row.codigo_de_geolocalizacion);
                }
                await this.createMissingCodes(missing_codes);
                this.loadingText = `Asignando equipo a bandos ...`;
                await this.updateTasksTeam(team, codes, userId);
                await this.updateItacsTeam(team, codes, userId);
                this._spinner.hide();
            }
        } catch (err) {}
    }
    
    /**
     * Updates tasks for users.
     * 
     * @param users - An array of MiRutaUser objects representing the users.
     * @param codes - An array of strings representing the codes.
     * @param userId - A string representing the user ID.
     */
    async updateTasksUsers(users: MiRutaUser[], codes: string[], userId: string){
        let errorIds = [];
        const tasks = await this._apiService.getTasks([['codigo_de_geolocalizacion', '==', codes]])
        const ids: number[] = tasks.map((task: WaterTask)=> task.id!);
        try {
            const data = { OPERARIO: users, ultima_modificacion: userId }
            const result = await this._apiService.updateTasks(ids, data, false);
            if (result) {
                for (let row of this.clickedRows) {
                    const index = this.sides.indexOf(row, 0);
                    row['OPERARIO'] = users.map((user: MiRutaUser) => user.id).join(',');
                    this.sides[index] = row;
                }
            } else {
                errorIds = ids;
            }
        } catch (err) {
            errorIds = ids;
        }
        if (errorIds.length > 0) this._utilsService.openSnackBar(`Hubo errores asignando tareas`, 'error');
        else this._utilsService.openSnackBar(`Tareas asignadas correctamente`);
    }
    
    /**
     * Updates tasks for a given team.
     * @param team - The team to update tasks for.
     * @param codes - An array of codes used to filter tasks.
     * @param userId - The ID of the user performing the update.
     */
    async updateTasksTeam(team: Team, codes: string[], userId: string){
        let errorIds = [];
        const tasks = await this._apiService.getTasks([['codigo_de_geolocalizacion', '==', codes]])
        const ids: number[] = tasks.map((task: WaterTask)=> task.id!);
        try {
            const data = { equipo: team.id, ultima_modificacion: userId }
            const result = await this._apiService.updateTasks(ids, data, false);
            if (!result) errorIds = ids;
        } catch (err) {
            errorIds = ids;
        }
        if (errorIds.length > 0) this._utilsService.openSnackBar(`Hubo errores asignando tareas`, 'error');
        else this._utilsService.openSnackBar(`Tareas asignadas correctamente`);
    }

    /**
     * Updates the specified ITACs with the given users and user ID.
     * @param users - An array of MiRutaUser objects representing the users to be assigned to the ITACs.
     * @param codes - An array of strings representing the codes of the ITACs to be updated.
     * @param userId - A string representing the ID of the user performing the update.
     */
    async updateItacsUsers(users: MiRutaUser[], codes: string[], userId: string){
        let errorIds = [];
        const itacs = await this._apiService.getItacs([['codigo_itac', '==', codes]])
        const ids: number[] = itacs.map((itac: Itac)=> itac.id!);
        try {
            const data = { operario: users, ultima_modificacion: userId };
            const result = await this._apiService.updateItacs(ids, data, false);
            if (!result) errorIds = ids;
        } catch (err) {
            errorIds = ids;
        }
        if (errorIds.length > 0) this._utilsService.openSnackBar(`Hubo errores asignando itacs`, 'error');
        else this._utilsService.openSnackBar(`Itacs asignadas correctamente`);
    }

    /**
     * Updates the team assigned to the specified ITACs.
     * @param team The team to assign to the ITACs.
     * @param codes The codes of the ITACs to update.
     * @param userId The ID of the user performing the update.
     */
    async updateItacsTeam(team: Team, codes: string[], userId: string){
        let errorIds = [];
        const itacs = await this._apiService.getItacs([['codigo_itac', '==', codes]])
        const ids: number[] = itacs.map((itac: Itac)=> itac.id!);
        try {
            const data = { equipo: team.id, ultima_modificacion: userId };
            const result = await this._apiService.updateItacs(ids, data, false);
            if (!result) errorIds = ids;
        } catch (err) {
            errorIds = ids;
        }
        if (errorIds.length > 0) this._utilsService.openSnackBar(`Hubo errores asignando itacs`, 'error');
        else this._utilsService.openSnackBar(`Itacs asignadas correctamente`);
    }

    /**
     * Checks if any row is selected and displays a warning message if no rows are selected.
     * @returns {boolean} - True if no rows are selected, false otherwise.
     */
    nothingSelectedWarning(): boolean { 
        if (this.clickedRows.size == 0) {
            this._utilsService.openSnackBar('Debe seleccionar al menos un bando', 'warning');
            return true;
        }
        return false;
    }

    /**
     * Reloads the current route.
     */
    reload() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['./'], { relativeTo: this.route });
    }

    /**
     * Sets the loading state and shows or hides the spinner accordingly.
     * @param state - The loading state to set. If `true`, the spinner will be shown. If `false`, the spinner will be hidden.
     */
    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this._spinner.show('innerSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this._spinner.hide('innerSpinner');
        }
    }

    /**
     * Sets the sides in the table and updates the data source.
     * 
     * @param sides - An array of Side objects representing the sides to be set in the table.
     * @returns A Promise that resolves when the sides have been set in the table.
     */
    async setSidesInTable(sides: Side[]) {
        this.dataSource.data = [];
        this.sides = sides;
        for (let [index, side] of this.sides.entries()) {
            side.ID = index + 1;
        }
        this.dataSource.data = this.sides.slice(0, this.rowsLimit);
        await this.getTaskTypeSummaryByEmplacement();
        this.showLoading(false);
    }

    /**
     * Retrieves the sides and performs necessary actions based on the last selected status.
     */
    async getSides() {
        const managerSelected = JSON.parse(localStorage.getItem('managerJson') || '{}');
        this.imageLogo = managerSelected.photo;
        if (localStorage.getItem('last_status')) {
            const index = localStorage.getItem('last_selected_index') || '0';
            this.onTabChanged(parseInt(index), false);
        } else {
            this.onTabChanged(0, true);
        }
    }


    /**
     * Retrieves a list of sides based on the provided status string.
     * If a status string is not provided, all sides will be retrieved.
     * 
     * @param statusString - Optional. The status string to filter the sides.
     * @returns A promise that resolves to an array of sides.
     */
    async selectSides(statusString?: string) {
        this.showLoading(true);

        const { status, additionalStatus } = this._utilsService.getStatus(statusString);
        
        this.additionalStatus = additionalStatus;
        this.currentStatus = status;

        const restartFilters = localStorage.getItem('restartFilters');
        if (restartFilters == 'true') this._utilsService.clearFiltersAndOrders();

        localStorage.setItem('currentStatus', this.currentStatus.toString());
        localStorage.setItem('additionalStatus', this.additionalStatus.toString());

        let where_clause = this._utilsService.getSidesFilterWhereString();

        localStorage.setItem('last_status', statusString || '');
        localStorage.setItem('last_selected_index', this.selected.value.toString());

        let offset = '0';
        const lastPageIndex = localStorage.getItem('lastPageIndexSide');
        if (lastPageIndex) {
            localStorage.setItem('lastPageIndexSide', '');
            this.lastPageIndex = parseInt(lastPageIndex);
            offset = (this.lastPageIndex * this.pageSize).toString();
        }

        where_clause = this._utilsService.addStatusToWhereClause(where_clause);

        this._mySqlService.getSidesCount(where_clause).then((count) => {
            this.length = count;
        });

        const order = this._utilsService.orderSides;
        let order_clause = undefined;
        if (order.length > 0) order_clause = this._utilsService.getOrderClauseFromOrder(order);
        
        const sides = await this._mySqlService.getSides(
            undefined,
            where_clause,
            order_clause,
            offset,
            this.pageSize.toString()
        );
        return sides;
    }

    /**
     * Selects all sides and updates the clickedRows set.
     */
    async selectAll() {
        this.clickedRows.clear();
        const sides = this.sides;
        for (const side of sides) {
            if (!this.clickedRows.has(side)) {
                this.clickedRows.add(side);
            }
        }
        this.allSelected = true;
        this._utilsService.openSnackBar(`Seleccionadas ${this.clickedRows.size} bandos`);
    }

    /**
     * Searches for a value asynchronously.
     * @param term - The search term to be used.
     * @returns A Promise that resolves when the search is complete.
     */
    async searchValue(term: string): Promise<void> {
        clearTimeout(this.timerInputChanged);
        this.timerInputChanged = setTimeout(async () => {
            await this.searchTerm(term);
        }, 1000);
    }

    /**
     * Searches for a term in the sides component.
     * @param term - The term to search for.
     * @returns A promise that resolves when the search is complete.
     */
    async searchTerm(term: string): Promise<void> {
        this._utilsService.deleteFieldFromFilter(this._utilsService.filterTasks!, 'term');
        if(term) {
            this._utilsService.processFilter(
                this._utilsService.filterSides!, term, 'term', 'string',
                this._mySqlService.sidesTableName, true, false, false, null, true
            );
        }
        let where_clause = this._utilsService.getSidesFilterWhereString();
        where_clause = this._utilsService.addStatusToWhereClause(where_clause);
        this.lastPageIndex = 0;
        localStorage.setItem('lastPageIndexSide', '');
        this.showLoading(true);
        await this.setSidesInTable(await this.filterSide(where_clause));
    }

    /**
     * Sets the rows selected filter based on the clicked rows.
     */
    setRowsSelectedFilter(){
        let codes: string[] = [];
        for (let row of this.clickedRows) {
            if (row.codigo_de_geolocalizacion) codes.push(row.codigo_de_geolocalizacion);
        }
        this._utilsService.clearTasksFilter();
        this._utilsService.processFilter(
            this._utilsService.filterTasks!,
            codes,
            'codigo_de_geolocalizacion',
            getFieldType('codigo_de_geolocalizacion'),
            this._mySqlService.tasksTableName
        );
    }

    /**
     * Sets the configured filter for the sides component.
     * If the filterSides property is set in the utilsService, it copies the filter to the filterTasks property.
     * If the filterSides property is empty, it processes the filter with default values.
     */
    setConfiguredFilter(){
        if(this._utilsService.filterSides) {
            this._utilsService.filterTasks = deepCopyMiRutaFilter(this._utilsService.filterSides);
        }
        if(this._utilsService.checkIfFilterIsEmpty(this._utilsService.filterSides)) {
            this._utilsService.processFilter(
                this._utilsService.filterTasks!,
                [],
                'codigo_de_geolocalizacion',
                getFieldType('codigo_de_geolocalizacion'),
                this._mySqlService.tasksTableName,
                true,
                true
            );
        }
    }

    /**
     * Sets the filter for the next screen based on the selected rows or configured filter.
     * Stores the current and additional task status in the session storage.
     */
    setFilterToNextScreen() {
        if (this.clickedRows.size) this.setRowsSelectedFilter();
        else this.setConfiguredFilter();
        sessionStorage.setItem('currentTaskStatus', this.currentStatus!.toString());
        sessionStorage.setItem('additionalTaskStatus', this.additionalStatus!.toString());
    }

    /**
     * Opens the tasks screen and sets the filter for the next screen.
     * Navigates to the '/home' route.
     */
    openTasksScreen() {
        this.setFilterToNextScreen();
        this.router.navigate(['/home']);
    }

    /**
     * Opens the Google Maps screen and sets the filter for the next screen.
     */
    openMaps() {
        this.setFilterToNextScreen();
        this.router.navigate(['/google_maps']);
    }

    /**
     * Handles the page event triggered by the pagination component.
     * @param event - The page event object containing information about the page index and page size.
     */
    async pageEvent(event: any) {
        if (this.lastPageIndex != event.pageIndex) {
            this.showLoading(true);
            if (this.lastPageIndex < event.pageIndex) {
                await this.setSidesInTable(
                    await this._mySqlService.getNextSidesPage(event.pageIndex - this.lastPageIndex)
                );
            } else {
                await this.setSidesInTable(
                    await this._mySqlService.getPreviousSidesPage(
                        this.lastPageIndex - event.pageIndex
                    )
                );
            }
            this.lastPageIndex = event.pageIndex;
        }
        if (this.pageSize != event.pageSize) {
            this.pageSize = event.pageSize;
            localStorage.setItem('side_pageSize', this.pageSize.toString());
            await this.getSides();
        }
        this.scrollOffset = 50;
    }

    
    /**
     * Filters the team based on user selection.
     * Retrieves the list of teams from the API service and opens a selector dialog for the user to choose a team.
     * Applies the selected team as a filter to the component.
     */
    async filterTeam(): Promise<void> {
        const teams = await this._apiService.getTeams();
        const teamSelected = await this._utilsService.openSelectorDialog(
            'Seleccione equipo',
            teams.map((team) => team.equipo_operario)
        );
        const teamSelectedId = teams.find((team) => team.equipo_operario == teamSelected)?.id;
        if (teamSelectedId) {
            this.applyFilter([teamSelectedId], 'equipo');
        }
    }

    /**
     * Filters the operator based on user selection.
     * Retrieves a list of users with the role 'operario' and prompts the user to select an operator.
     * Applies the selected operator as a filter to the sides.
     */
    async filterOperator(): Promise<void> {
        const users = await this._apiService.getUsers(['operario']);
        const userSelected = await this._utilsService.openSelectorDialog(
            'Seleccione operario',
            users.map((user) => this._utilsService.userPipe(user.id))
        );
        const userSelectedId = users.find(
            (user) => this._utilsService.userPipe(user.id) == userSelected
        )?.id;
        if (userSelectedId) {
            deleteFilterField(this._utilsService.filterSides!, 'OPERARIO');
            const json = this._utilsService.getFieldJoinJson('OPERARIO');
            const replace = this._utilsService.getReplaceFieldValue('OPERARIO');
            await this.applyFilter([userSelectedId], replace, false, false, json);
        }
    }

    /**
     * Filters the sectors and applies the selected sector as a filter.
     * @returns {Promise<void>} A promise that resolves when the filter is applied.
     */
    async filterSector(): Promise<void> {
        const sectors = await this._apiService.getSectors();
        const sectorSelected = await this._utilsService.openSelectorDialog(
            'Seleccione perímetro',
            sectors.map((sector) => sector.name)
        );
        const sectorSelectedId = sectors.find((sector) => sector.name == sectorSelected)?.id;
        if (sectorSelectedId) {
            deleteFilterField(this._utilsService.filterSides!, 'sectors');
            const json = this._utilsService.getFieldJoinJson('sectors');
            const replace = this._utilsService.getReplaceFieldValue('sectors');
            await this.applyFilter([sectorSelectedId], replace, false, false, json);
        }
    }

    /**
     * Filters the correct phone numbers.
     * Removes the 'telefonos_cliente' filter field from the '_utilsService.filterSides' object
     * and applies a new filter with the value '%Nº correcto%' to the 'telefonos_cliente' field.
     */
    async filterCorrectPhone(): Promise<void> {
        deleteFilterField(this._utilsService.filterSides!, 'telefonos_cliente');
        const json = this._utilsService.getFieldJoinJson('telefonos_cliente');
        const replace = this._utilsService.getReplaceFieldValue('telefonos_cliente');
        await this.applyFilter(['TEL1 Nº correcto', 'TEL2 Nº correcto'], replace, false, false, json);
    }

    /**
     * Filters the last operator modifier based on user selection.
     * Retrieves a list of users with the role 'operario' and opens a selector dialog to choose an operator.
     * Applies a filter to the component based on the selected operator's ID.
     */
    async filterLastOperatorModifier(): Promise<void> {
        const users = await this._apiService.getUsers(['operario']);
        const userSelected = await this._utilsService.openSelectorDialog(
            'Seleccione operario',
            users.map((user) => this._utilsService.userPipe(user.id))
        );
        const userSelectedId = users.find(
            (user) => this._utilsService.userPipe(user.id) == userSelected
        )?.id;
        if (userSelectedId) {
            this.applyFilter([userSelectedId.toString()], 'last_modification_operator_uid');
        }
    }

    /**
     * Filters the data based on the selected user's ID.
     * Retrieves the list of users and opens a dialog to select a user.
     * Applies a filter based on the selected user's ID.
     */
    async filterModifier(): Promise<void> {
        const users = await this._apiService.getUsers(['administrador', 'operario']);
        const userSelected = await this._utilsService.openSelectorDialog(
            'Seleccione usuario',
            users.map((user) => this._utilsService.userPipe(user.id))
        );
        const userSelectedId = users.find(
            (user) => this._utilsService.userPipe(user.id) == userSelected
        )?.id;
        if (userSelectedId) this.applyFilter([userSelectedId.toString()], 'ultima_modificacion');
    }

    /**
     * Filters the service based on user selection.
     * Opens a selector dialog to allow the user to choose a service.
     * If a service is selected, applies the filter using the selected service.
     */
    async filterService(): Promise<void> {
        try {
            const services = this._utilsService._services;
            const serviceSelected = await this._utilsService.openSelectorDialog(
                'Seleccione servicio',
                services
            );
            if (serviceSelected) {
                this.applyFilter([serviceSelected], 'last_service');
            }
        } catch (err) {}
    }

    /**
     * Filters the supplier based on user selection.
     * @returns {Promise<void>} A promise that resolves when the filter is applied.
     */
    async filterSupplier(): Promise<void> {
        try {
            const suplies = this._utilsService._suplies;
            const supplySelected = await this._utilsService.openSelectorDialog(
                'Seleccione suministro',
                suplies
            );
            if (supplySelected) {
                deleteFilterField(this._utilsService.filterSides!, 'suministros');
                const json = this._utilsService.getFieldJoinJson('suministros');
                const replace = this._utilsService.getReplaceFieldValue('suministros');
                await this.applyFilter([supplySelected], replace, false, false, json);
            }
        } catch (err) {}
    }

    /**
     * Filters the data by date type.
     * @returns {Promise<void>} A promise that resolves when the filtering is complete.
     */
    async filterDateType(): Promise<void> {
        const dateTypes: any = getFilterDateTypes();
        const keys = Object.keys(dateTypes);
        try {
            const option = await this._utilsService.openSelectorDialog('Seleccione tipo de fecha', keys);
            if (option) await this.filterBy(getFieldName(dateTypes[option]));
        } catch (err) {}
    }

    /**
     * Filters the cause based on user selection.
     * Opens a filter dialog for 'ANOMALIA' and 'CALIBRE' and applies the selected filters.
     * Orders the results by 'CALIBRE' and 'ANOMALIA' in ascending order.
     * 
     * @returns {Promise<void>} A promise that resolves when the filtering is complete.
     */
    async filterCause(): Promise<void> {
        let result = await this.openFilterDialog('ANOMALIA', 'ANOMALIA');
        if (result && result.data) {
            this.processFilter(result.data, result.column);
            result = await this.openFilterDialog('CALIBRE', 'CALIBRE');
            if (result && result.data) {
                this.processOrder('CALIBRE', 'ASC');
                this.processOrder('ANOMALIA', 'ASC');
                this.applyFilter(
                    result.data,
                    result.column,
                    result.not_empty,
                    result.empties_checked
                );
            }
        }
    }

    /**
     * Filters the address based on user input.
     * Opens filter dialogs for 'MUNICIPIO', 'CALLE', and 'NUME' columns.
     * Processes the filter data and applies the filter to the address.
     * Orders the address by 'MUNICIPIO', 'CALLE', 'NUME', 'BIS', 'PISO', 'MANO'.
     */
    async filterAddress(): Promise<void> {
        let res = await this.openFilterDialog('MUNICIPIO', 'MUNICIPIO');
        if (res && res.data) {
            this.processFilter(res.data, res.column);
            res = await this.openFilterDialog('CALLE', 'CALLE');
            if (res && res.data) {
                this.processFilter(res.data, res.column);
                res = await this.openFilterDialog('NUME', 'NUME');
                if (res && res.data) {
                    // Order by MUNICIPIO, CALLE, NUME, BIS, PISO, MANO
                    this.processOrder('MANO','ASC');
                    this.processOrder('PISO','ASC');
                    this.processOrder('BIS','ASC');
                    this.processOrder('NUME','ASC');
                    this.processOrder('CALLE','ASC');
                    this.processOrder('MUNICIPIO','ASC');
                    this.applyFilter(res.data, res.column, res.not_empty, res.empties_checked);
                }
            }
        }
    }

    async openFilterDialog(column_name: string, column: string): Promise<any> {
        return await this._utilsService.openFilterDialog(
            column_name,
            column,
            this._mySqlService.sidesTableName,
            this._utilsService.filterSides
        );
    }

    processFilter(values: any, column: string): void {
        this._utilsService.filterSides = this._utilsService.processFilter(
            this._utilsService.filterSides!,
            values,
            column,
            getFieldType(column),
            this._mySqlService.sidesTableName
        );
    }

    /**
     * Processes the order of the sides based on the specified field and direction.
     * 
     * @param field - The field to order the sides by.
     * @param dir - The direction of the ordering (ascending or descending).
     */
    processOrder(field: string, dir: string): void {
        this._utilsService.processOrder(
            this._mySqlService.sidesTableName,
            this._utilsService.orderSides,
            field,
            dir
        );
    }

    /**
     * Applies a custom filter to the specified column.
     * @param column - The name of the column to apply the filter to.
     */
    async applyCustomFilter(column: string) {
        let result = await this.openFilterDialog(getFieldName(column), column);
        if (result && result.data) {
            this.processOrder(column, 'ASC');
            this.applyFilter(
                result.data,
                result.column,
                result.not_empty,
                result.empties_checked
            );
        }
    }
    
    async customFilterBy(filterType: string) {
        try {
            if (filterType === 'Dirección') await this.filterAddress(); //ok
            else if (filterType === 'Abonado') await this.applyCustomFilter('Numero_de_ABONADO');//ok
            else if (filterType === 'Sin revisar') await this.applyFilter([1], 'last_modification_android');//ok
            else if (filterType === 'Incidencias') await this.applyFilter([1], 'incidence');//ok
            else if (filterType === 'Titular') await this.applyCustomFilter('NOMBRE_ABONADO');//ok
            else if (filterType === 'Serie') await this.applyCustomFilter('SERIE');//ok
            else if (filterType === 'Equipo') await this.filterTeam();//ok
            else if (filterType === 'last_modification_operator_uid') await this.filterLastOperatorModifier();//ok
            else if (filterType === 'ultima_modificacion') await this.filterModifier();//ok
            else if (filterType === 'Servicio') await this.filterService();//ok
            else if (filterType === 'Código de emplazamiento') await this.applyCustomFilter('codigo_de_geolocalizacion');//ok
            else if (filterType === 'Sector P') await this.applyCustomFilter('zona');//ok
            else if (filterType === 'Fecha') await this.filterDateType(); //ok
            else if (filterType === 'Teléfono correcto') await this.filterCorrectPhone();//ok
            else if (filterType === 'Suministros') await this.filterSupplier();//ok
            else if (filterType === 'Causa Origen') await this.filterCause();
            else if (filterType === 'Operario') await this.filterOperator();//ok
            else if (filterType === 'Perímetro') await this.filterSector();//ok
            else await this.applyCustomFilter(filterType);
        } catch (err) {}
    }

    /**
     * Filters the sides based on the specified condition.
     * 
     * @param where - The condition to filter the sides.
     * @returns A promise that resolves to the filtered sides.
     */
    async filterSide(where?: string) {
        where = this._utilsService.addStatusToWhereClause(where);

        this.length = await this._mySqlService.getSidesCount(where);
        const order = this._utilsService.orderSides;
        let order_clause = undefined;
        if (order.length > 0) {
            order_clause = this._utilsService.getOrderClauseFromOrder(order);
        }
        return await this._mySqlService.getSides(
            undefined,
            where,
            order_clause,
            '0',
            this.pageSize.toString()
        );
    }

    /**
     * Applies a filter to the sides component.
     * 
     * @param values - The filter values.
     * @param column - The column to filter on.
     * @param not_empty - Indicates whether to include only non-empty values in the filter.
     * @param empties_checked - Indicates whether to include empty values in the filter.
     * @param custom_join_json - Custom join JSON for the filter.
     */
    async applyFilter(
        values: any, column: string, 
        not_empty: boolean = false, 
        empties_checked: boolean = false,
        custom_join_json: any = null
        ) {
        let fieldType = getSideFieldType(column);
        if(!fieldType) fieldType = getFieldType(column)
        const where_clause = this._utilsService.getWhereClauseFromFilter(
            this._utilsService.processFilter(
                this._utilsService.filterSides!,
                values,
                column,
                fieldType,
                this._mySqlService.sidesTableName,
                true, 
                not_empty,
                empties_checked,
                custom_join_json
            )
        );
        this.showLoading(true);
        localStorage.setItem('lastPageIndexSide', '');
        this.lastPageIndex = 0;
        await this.setSidesInTable(await this.filterSide(where_clause));
    }

    /**
     * Filters the data by the specified column.
     * 
     * @param column - The column to filter by.
     */
    async filterBy(column: string) {
        this.filteredColumn = getSideField(column);
        if(!this.filteredColumn) this.filteredColumn = getField(column);
        let fielType = getSideFieldType(this.filteredColumn);
        if(!fielType) fielType = getFieldType(this.filteredColumn);
        if (fielType == 'Date') await this.filterDate();
        else await this.filterField(column);
    }

    /**
     * Filters the data based on the selected date and time range.
     * Opens a dialog to select the date range and optionally the time range.
     * Calls the `onDateSelected` method with the selected dates and times (if available).
     */
    async filterDate(){
        try {
            const dates = await this._utilsService.openDateRangeSelectorDialog(
                'Seleccione rango de fechas'
            );
            let times: Date[];
            try {
                times = await this._utilsService.openTimeRangeSelectorDialog(
                    'Seleccione rango de horas'
                );
                this.onDateSelected(dates, times);
            } catch (err) {
                this.onDateSelected(dates);
            }
        } catch (err) {}
    }

    /**
     * Filters the field based on the specified column.
     * @param column - The column to filter.
     */
    async filterField(column: string) {
        try {
            this.filteredColumn = getSideField(column);
            if(!this.filteredColumn) this.filteredColumn = getField(column);
            const result = await this._utilsService.openFilterDialog(
                column,
                this.filteredColumn,
                this._mySqlService.sidesTableName,
                this._utilsService.filterSides
            );
            if (result && result.data) {
                if(this._utilsService.isFieldJoinNeeded(this.filteredColumn!)) {
                    const json = this._utilsService.getFieldJoinJson(this.filteredColumn!);
                    const replace = this._utilsService.getReplaceFieldValue(this.filteredColumn!);
                    await this.applyFilter(result.data, replace, false, false, json);
                }
                this.applyFilter(result.data, result.column, result.not_empty, result.empties_checked);
            }
        } catch (err) {}
    }

    /**
     * Handles the event when a date is selected.
     * @param dateRange - An array of selected dates.
     * @param timeRange - An optional array of selected times.
     */
    async onDateSelected(dateRange: Date[], timeRange?: Date[]) {
        if (dateRange) {
            const values = this._utilsService.getDateRangeString(dateRange, timeRange);
            if(this._utilsService.isFieldJoinNeeded(this.filteredColumn!)) {
                const json = this._utilsService.getFieldJoinJson(this.filteredColumn!);
                const replace = this._utilsService.getReplaceFieldValue(this.filteredColumn!);
                await this.applyFilter(values, replace, false, false, json);
            }
            else await this.applyFilter(values, this.filteredColumn!, false, false);
            
        } else this._utilsService.openSnackBar('Rango fechas inválido', 'error');
    }

    /**
     * Orders the sides based on the specified column and order type.
     * @param event - The event object containing the column and order type.
     */
    async orderBy(event: any) {
        const column = event.column;
        const orderType = event.orderType;

        const orderedColumn = getSideOrderField(column);
        const order_clause = this._utilsService.getOrderClauseFromOrder(
            this._utilsService.processOrder(
                this._mySqlService.sidesTableName,
                this._utilsService.orderSides,
                orderedColumn,
                orderType
            )
        );
        const filter = this._utilsService.filterSides;
        let where_clause = undefined;
        if (filter && filter.fields) {
            where_clause = this._utilsService.getWhereClauseFromFilter(filter);
        }
        this.showLoading(true);
        const sides = await this._mySqlService.getSides(
            undefined,
            where_clause,
            order_clause,
            '0',
            this.pageSize.toString()
        );
        localStorage.setItem('lastPageIndexSide', '');
        this.lastPageIndex = 0;
        await this.setSidesInTable(sides);
    }

    /**
     * Handles the double click event on a row.
     * @param row - The row object that was double clicked.
     */
    async doubleClickedRow(row: any) {
        localStorage.setItem('lastPageIndexSide', this.lastPageIndex.toString());
        await this.tryOpenITAC(row.codigo_de_geolocalizacion);
    }

    /**
     * Tries to open an ITAC with the given code.
     * If the ITAC exists, it is opened. If not, the user is prompted to create a new ITAC.
     * @param code The code of the ITAC to open.
     */
    async tryOpenITAC(code: string) {
        this.showLoading(true);
        const itac = await this._apiService.getItacWithCode(code);
        if (itac) {
            this.showLoading(false);
            this.openItac(itac.id!);
        } else {
            this.showLoading(false);
            if (this._utilsService.isClientUser()) {
                this._utilsService.openSnackBar('No hay Itac disponible', 'warning');
                return;
            }
            const result = await this._utilsService.openQuestionDialog(
                'No exite ITAC',
                '¿Desea crear nuevo ITAC?'
            );
            this.showLoading(true);
            if (result) {
                const itacId = await this.createItac(code);
                if (itacId) this.openItac(itacId);
            }
        }
        this.showLoading(false);
    }

    /**
     * Creates a new ITAC (Inspección Técnica de Antenas y Circuitos) based on the provided code.
     * @param code - The code used to search for the ITAC.
     * @returns A promise that resolves to the ID of the created ITAC, or -1 if the ITAC could not be created.
     */
    async createItac(code: string): Promise<number> {
        const tasks = await this._apiService.getTasks([['codigo_de_geolocalizacion', '==', [code]]]);
        if(tasks && tasks.length) {
            const task = tasks[0];
            let company = localStorage.getItem('company');
            const itacId = await this._apiService.addDocument('itac', {
                codigo_itac: code,
                acceso: task.acceso_devuelto,
                company: parseInt(company!),
                itac: this._utilsService.getDirOfTask(task, false),
                zona: task.zona,
                gestor: task.GESTOR,
                equipo: task.equipo,
                operario: task.OPERARIO,
                prioridad: task.prioridad,
                bloque: task.bloque,
                descripcion: 'Nueva itac',
                geolocalizacion: task?.codigo_de_localizacion ?? task?.geolocalizacion,
            });
            if (itacId) return itacId;
        }
        return -1;
    }

    /**
     * Opens the ITAC with the specified ID.
     * If the application is running in an Electron environment, it navigates to the ITAC route in the same tab.
     * If the application is running in a web browser, it opens the ITAC route in a new tab.
     * 
     * @param itacId - The ID of the ITAC to open.
     */
    openItac(itacId: number) {
        if (this._electronService.isElectronApp()) {
            this.router.navigate(['/itac', itacId]); // open itac in the same tab
        } else {
            const url = this.router.serializeUrl(this.router.createUrlTree(['/itac', itacId]));
            window.open(url, '_blank');
        }
    }

    /**
     * Handles the click event on a row.
     * 
     * @param receivedEvent - The event object containing information about the clicked row.
     */
    clickedRow(receivedEvent: any) {
        const row = receivedEvent.row;
        const event = receivedEvent.event;
        const rowIndex = receivedEvent.rowIndex;

        const previousRow = this.lastSelectedRow;
        this.lastSelectedRow = rowIndex;

        if (event.button === 0) {
            if (!event.ctrlKey && !event.shiftKey) {
                this.allSelected = false;
                this.clickedRows.clear();
                this.toggleRow(row);
            } else if (event.ctrlKey) {
                this.toggleRow(row);
            }
            if (event.shiftKey) {
                this.selectRowsBetweenIndexes(previousRow, rowIndex);
            }
        }
        if (this._windowRefService.nativeWindow.getSelection) {
            //remove selection in table with shift
            if (this._windowRefService.nativeWindow.getSelection().empty) {
                // Chrome
                this._windowRefService.nativeWindow.getSelection().empty();
            } else if (this._windowRefService.nativeWindow.getSelection().removeAllRanges) {
                // Firefox
                this._windowRefService.nativeWindow.getSelection().removeAllRanges();
            }
        }
    }

    /**
     * Handles the selection of rows in the component.
     * 
     * @param event - The event containing the selected rows.
     */
    selectedRows(event: any) {
        this.allSelected = false;
        this.clickedRows.clear();
        this.selectRowsBetweenIndexes(event[0], event[1], false);
    }

    /**
     * Selects rows between two indexes and adds them to the clickedRows set.
     * 
     * @param lastSelectedRow - The index of the last selected row.
     * @param rowIndex - The index of the current row.
     * @param showSnackBar - Optional parameter to indicate whether to show a snackbar. Default is true.
     */
    selectRowsBetweenIndexes(
        lastSelectedRow: number,
        rowIndex: number,
        showSnackBar: boolean = true
    ) {
        let start, end;
        if (rowIndex > lastSelectedRow) {
            start = lastSelectedRow;
            end = rowIndex;
        } else {
            end = lastSelectedRow;
            start = rowIndex;
        }
        for (let i = start; i <= end; i++) {
            this.clickedRows.add(this.sides[i]);
        }
        if (this.clickedRows.size > 1 && showSnackBar) {
            this._utilsService.openSnackBar(`Seleccionadas ${this.clickedRows.size} bandos`);
        }
    }

    /**
     * Toggles the specified row in the component's clickedRows set.
     * If the row is already in the set, it will be removed. Otherwise, it will be added.
     * 
     * @param row - The row to toggle.
     */
    toggleRow(row: any) {
        if (this.clickedRows.has(row)) {
            this.clickedRows.delete(row);
        } else {
            this.clickedRows.add(row);
        }
    }

    async openSettings() {
        await this._utilsService.openFilterConfigurationDialog(
            this._mySqlService.sidesTableName,
            this._utilsService.filterSides
        );
    }

    exportSidesInTable() {
        this.exportExcel(this.dataSource!.data);
    }
    exportExcel(sides: any) {
        let excelFormatTasks = [];
        for (let side of sides) {
            let data: any = {};
            const columns = getSideExcelExportColumns();
            columns.forEach((column) => {
                data[`${column}`] = this._utilsService.fieldPipe(
                    side[getSideExcelFieldName(column)], column
                );
            });
            excelFormatTasks.push(data);
        }
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelFormatTasks);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        /* save to file */
        XLSX.writeFile(wb, 'Bandos_Exportados.xlsx');
    }
}

<mat-drawer-container
    class="animate__animated animate__fadeIn"
    style="width: 100%; height: 100%"
    autosize
>
    <mat-drawer #drawer mode="side" class="drawer">
        <div
            *ngIf="drawerOption === _enumDrawerOption.SHOW_TASKS_CARDS"
            style="width: 100%; height: 100%"
        >
            <div
                *ngIf="!loadingMarkerInfo"
                class="div-direction-info animate__animated animate__fadeIn"
            >
                <span style="font-size: 1.2em">{{ dirSelected }} ({{ markerTasks.length }})</span>
            </div>

            <div *ngIf="loadingMarkerInfo" style="display: flex; width: 300px; height: 90%">
                <mat-spinner
                    style="margin: auto"
                    class="animate__animated animate__fadeIn animate__slow"
                ></mat-spinner>
            </div>

            <div
                *ngIf="!loadingMarkerInfo && markerTasks.length > 0"
                class="animate__animated animate__fadeIn"
            >
                <mat-card
                    *ngFor="let task of markerTasks; let i = index"
                    class="home-card"
                    (click)="openTask(task)"
                >
                    <mat-card-content class="home-card-content">
                        <img
                            mat-card-image
                            [src]="getTaskImage(task)"
                            onerror="this.onerror=null; this.src='assets/img/noimage.png'"
                            alt="Photo of Task"
                        />
                        <div
                            style="
                                padding: 10px !important;
                                margin-top: 15px !important;
                                text-align: left !important;
                            "
                        >
                            <div *ngIf="getDirOfTask(task).length < 33">
                                <b>{{ getDirOfTask(task) }}</b>
                            </div>
                            <marquee *ngIf="getDirOfTask(task).length >= 33">
                                <b>{{ getDirOfTask(task) }}</b>
                            </marquee>
                            <div>{{ getTaskSubscriberTextLine(task) }}</div>
                            <div>{{ getTaskCounterTextLine(task) }}</div>
                        </div>
                    </mat-card-content>

                    <div style="position: absolute; top: 5px; left: 88px; display: flex">
                        <mat-icon *ngIf="hasRadius(task)" style="font-size: 16px"
                            >wifi_tethering</mat-icon
                        >
                        <mat-icon *ngIf="task?.upload_pendent" style="font-size: 16px"
                            >sync_problem_rounded</mat-icon
                        >
                        <mat-icon
                            *ngIf="task?.status_tarea! > status_done_and_plus"
                            style="font-size: 16px"
                            >check_circle_outline_rounded</mat-icon
                        >
                        <mat-icon *ngIf="task?.incidence">priority_high</mat-icon>
                        <div *ngIf="task?.last_modification_android">
                            <mat-icon>phone_android</mat-icon>
                            <div
                                style="
                                    position: absolute;
                                    bottom: 8px;
                                    right: 5px;
                                    background-color: #82d060;
                                    height: 5px;
                                    width: 5px;
                                    border-radius: 50%;
                                "
                            ></div>
                        </div>
                    </div>

                    <div style="position: absolute; top: 3px; right: 10px; display: flex">
                        <mat-icon style="font-size: 15px; margin-top: 2px"
                            >date_range_rounded</mat-icon
                        >
                        <div>
                            {{
                                task.cita_pendiente
                                    ? 'CITA ' + (task.fecha_hora_cita | fieldPipe)
                                    : 'MOD ' + (task.date_time_modified | fieldPipe)
                            }}
                        </div>
                    </div>

                    <div
                        style="
                            position: absolute;
                            bottom: 5px;
                            right: 5px;
                            height: 8px;
                            width: 8px;
                            border-radius: 50%;
                        "
                        [ngStyle]="{ 'background-color': getTaskPriorityColor(task) }"
                    ></div>
                </mat-card>
            </div>
        </div>
        <div *ngIf="drawerOption === _enumDrawerOption.SHOW_SECTORS" style="height: 100%">
            <div class="div-direction-info animate__animated animate__fadeIn">
                <span style="font-size: 1.2em">Perímetros</span>
            </div>

            <div
                *ngFor="let polygon of polygons; let i = index"
                style="width: 100%; display: flex; margin: 20px"
                [matTooltip]="getPolygonTooltip(polygon)"
            >
                <mat-checkbox
                    style="margin-right: auto; margin-top: auto; margin-bottom: auto"
                    color="primary"
                    (change)="togglePolygon(polygon, map)"
                >
                    {{ polygon.name }}
                </mat-checkbox>
                <button
                    *ngIf="!_utilsService.isClientUser()"
                    (click)="editSector(polygon)"
                    mat-button
                    matTooltip="Editar perímetro"
                >
                    <mat-icon>edit</mat-icon>
                </button>
                <button
                    *ngIf="!_utilsService.isClientUser()"
                    style="margin-right: 20px"
                    (click)="deleteSector(polygon, i)"
                    mat-button
                    matTooltip="Eliminar perímetro"
                >
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </div>
    </mat-drawer>

    <mat-drawer-content>
        <div style="width: 100%; height: 100%">
            <google-map
                height="100%"
                width="100%"
                [zoom]="zoom"
                [center]="center"
                [options]="options"
                (dblclick)="mapDlclick($event, drawer)"
                (mapClick)="mapClick($event, drawer)"
                (mapMousemove)="mouseMove($event, drawer)"
                #map
            >
                    <div style="background-color: red; color: red; z-index: 999999;"
                        *ngIf="
                            drawingActive &&
                            polylineOptionsDrawing &&
                            polylineOptionsDrawing.path &&
                            polylineOptionsDrawing.path.length > 1
                        "
                        (click)="toggleDrawing()"
                    >
                        <map-polyline [options]="polylineOptionsDrawing"></map-polyline>
                        <map-polygon *ngIf="!painting" [options]="drewPolygon"></map-polygon>
                    </div>

                    <div *ngIf="formGroup.controls['enableSectors'].value && polygons.length > 0">
                        <div *ngFor="let polygon of polygons; let i = index">
                            <map-polygon *ngIf="polygon.enabled" [options]="polygon"> </map-polygon>
                        </div>
                    </div>

                    <div *ngIf="formGroup.controls['enableMarkers'].value">
                        <div *ngFor="let marker of markers; let i = index">
                            <map-marker
                                *ngIf="marker.getVisible()"
                                #markerElem
                                [position]="marker.getPosition()!"
                                [title]="marker.getTitle()!"
                                [options]="drawingActive? drawingMarkerOption: markerOptionsMap.get(marker.getPosition()!.toString())!"
                                (mapClick)="onClickedMarker(marker, drawer)"
                                (mapDragend)="onDragEnd($event, marker)"
                            >
                            </map-marker>
                        </div>
                    </div>
                    <div *ngIf="footprintMarkers && footprintMarkers.size > 0">
                        <map-marker
                            *ngFor="let marker of footprintMarkers; let i = index"
                            [position]="marker.getPosition()!"
                            [title]="marker.getTitle()!"
                            [options]="footprintMarkerOption"
                        >
                        </map-marker>

                        <map-polyline style="z-index: 3" [options]="polylineOptions">
                        </map-polyline>

                        <map-marker
                            *ngFor="let marker of endPositionMarkers; let i = index"
                            [position]="marker.getPosition()!"
                            [title]="marker.getTitle()!"
                            [options]="endMarkerOption"
                        >
                        </map-marker>
                        <map-marker
                            *ngFor="let marker of startPositionMarkers; let i = index"
                            [position]="marker.getPosition()!"
                            [title]="marker.getTitle()!"
                            [options]="startMarkerOption"
                        >
                        </map-marker>
                    </div>
                    <div *ngIf="formGroup.controls['enableUserMarkers'].value">
                        <map-marker
                            #userMarkerElem
                            *ngFor="let marker of usersMarkers; let i = index"
                            [position]="marker.getPosition()!"
                            [title]="marker.getTitle()!"
                            [options]="userMarkerOption"
                            (mapClick)="onClickedUserMarker(marker, drawer)"
                        >
                        </map-marker>
                    </div>
                <map-info-window>Hello Google Maps</map-info-window>
            </google-map>

            <div class="search-input" *ngIf="!drawer.opened">
                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Ubicación</mat-label>
                    <input
                        type="text"
                        placeholder="Seleccione lugar ..."
                        aria-label="Number"
                        matInput
                        [formControl]="inputSearchControl"
                        [matAutocomplete]="auto"
                    />
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option
                            *ngFor="let prediction of placePredictions"
                            [value]="prediction.description"
                            [matTooltip]="prediction.description"
                            (click)="searchPlace(prediction)"
                        >
                            {{ prediction.description }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>

            <div *ngIf="!loading" class="top-left-button-filter">
                <button
                    mat-raised-button
                    class="raise-filter-button"
                    matTooltip="Filtrar tareas"
                    [matMenuTriggerFor]="filterMenu"
                >
                    <fa-icon class="fa-icon-column" [icon]="faFilter"></fa-icon>
                </button>
                <mat-menu #filterMenu="matMenu">
                    <button mat-menu-item matTooltip="Modificar filtro" (click)="openSettings()">
                        <mat-icon style="margin-left: 5px; font-size: 20px">settings</mat-icon>
                        <span style="margin-bottom: 15px">Configuración de filtro</span>
                    </button>
                    <button mat-menu-item (click)="customFilterBy('Sin revisar')">
                        <fa-icon class="fa-icon-column" [icon]="faMobile"></fa-icon>
                        <span style="margin-bottom: 10px">Sin revisar</span>
                    </button>
                    <button mat-menu-item (click)="customFilterBy('Dirección')">
                        <fa-icon class="fa-icon-column" [icon]="faHome"></fa-icon>
                        <span style="margin-bottom: 10px">Dirección</span>
                    </button>
                    <button mat-menu-item (click)="customFilterBy('Incidencias')">
                        <fa-icon class="fa-icon-column" [icon]="faCircleExclamation"></fa-icon>
                        <span style="margin-bottom: 10px">Incidencias en android</span>
                    </button>
                    <button
                        *ngIf="!_utilsService.isClientUser()"
                        mat-menu-item
                        (click)="customFilterBy('Causa Origen')"
                    >
                        <fa-icon class="fa-icon-column" [icon]="faAsterisk"></fa-icon>
                        <span style="margin-bottom: 10px">Causa Origen</span>
                    </button>
                    <button
                        *ngIf="!_utilsService.isClientUser()"
                        mat-menu-item
                        (click)="customFilterBy('Titular')"
                    >
                        <fa-icon class="fa-icon-column" [icon]="faHouseUser"></fa-icon>
                        <span style="margin-bottom: 10px">Titular</span>
                    </button>
                    <button mat-menu-item (click)="customFilterBy('Abonado')">
                        <fa-icon class="fa-icon-column" [icon]="faUserCog"></fa-icon>
                        <span style="margin-bottom: 10px">Abonado</span>
                    </button>
                    <button mat-menu-item (click)="customFilterBy('Servicio')">
                        <fa-icon class="fa-icon-column" [icon]="faToolbox"></fa-icon>
                        <span style="margin-bottom: 10px">Servicio</span>
                    </button>
                    <button mat-menu-item (click)="customFilterBy('Suministros')">
                        <fa-icon class="fa-icon-column" [icon]="faTools"></fa-icon>
                        <span style="margin-bottom: 10px">Suministros</span>
                    </button>
                    <button
                        *ngIf="!_utilsService.isClientUser()"
                        mat-menu-item
                        (click)="customFilterBy('Equipo')"
                    >
                        <fa-icon class="fa-icon-column" [icon]="faUsers"></fa-icon>
                        <span style="margin-bottom: 10px">Equipo</span>
                    </button>
                    <button
                        *ngIf="!_utilsService.isClientUser()"
                        mat-menu-item
                        (click)="customFilterBy('Operario')"
                    >
                        <fa-icon
                            class="fa-icon-column"
                            [icon]="faUserGroup"
                            style="margin-left: 3px !important"
                        ></fa-icon>
                        <span style="margin-bottom: 10px; margin-left: 2px !important"
                            >Operarios</span
                        >
                    </button>
                    <button
                        *ngIf="!_utilsService.isClientUser()"
                        mat-menu-item
                        (click)="customFilterBy('last_modification_operator_uid')"
                    >
                        <fa-icon
                            class="fa-icon-column"
                            [icon]="faIdBadge"
                            style="margin-left: 3px !important"
                        ></fa-icon>
                        <span style="margin-bottom: 10px; margin-left: 2px !important"
                            >Operario modificador</span
                        >
                    </button>
                    <button mat-menu-item (click)="customFilterBy('Serie')">
                        <fa-icon
                            class="fa-icon-column"
                            [icon]="faBarcode"
                            style="margin-left: 0.5px !important"
                        ></fa-icon>
                        <span style="margin-bottom: 10px">Serie</span>
                    </button>
                    <button
                        *ngIf="!_utilsService.isClientUser()"
                        mat-menu-item
                        (click)="customFilterBy('Código de emplazamiento')"
                    >
                        <fa-icon class="fa-icon-column" [icon]="faMapMarkedAlt"></fa-icon>
                        <span style="margin-bottom: 10px">Código de emplazamiento</span>
                    </button>
                    <button
                        *ngIf="!_utilsService.isClientUser()"
                        mat-menu-item
                        (click)="customFilterBy('Sector P')"
                    >
                        <fa-icon class="fa-icon-column" [icon]="faMap"></fa-icon>
                        <span style="margin-bottom: 10px">Sector P</span>
                    </button>

                    <button mat-menu-item (click)="customFilterBy('TELEFONO 1')">
                        <fa-icon class="fa-icon-column" [icon]="faPhoneAlt"></fa-icon>
                        <span style="margin-bottom: 10px">TELÉFONO 1</span>
                    </button>
                    <button mat-menu-item (click)="customFilterBy('Fecha')">
                        <fa-icon class="fa-icon-column" [icon]="faCalendar"></fa-icon>
                        <span style="margin-bottom: 10px">Fecha</span>
                    </button>
                    <button
                        *ngIf="!_utilsService.isClientUser()"
                        mat-menu-item
                        (click)="customFilterBy('Otro campo')"
                    >
                        <mat-icon style="font-size: 20px; margin-left: 3px" color="primary"
                            >more_vert</mat-icon
                        >
                        <span style="margin-bottom: 10px">Otro campo</span>
                    </button>
                </mat-menu>
            </div>

            <div
                *ngIf="!loading"
                class="top-left-button-filter"
                style="left: 270px"
                [formGroup]="formGroup"
            >
                <button
                    mat-raised-button
                    class="raise-filter-button"
                    matTooltip="Configuración de mapa"
                    [matMenuTriggerFor]="configurationMenu"
                >
                    <mat-icon style="font-size: 20px">settings</mat-icon>
                </button>
                <mat-menu #configurationMenu="matMenu">
                    <button mat-menu-item>
                        <mat-slide-toggle color="primary" formControlName="map3D"
                            >Mapa en 3D</mat-slide-toggle>
                    </button>
                    <button mat-menu-item>
                        <mat-slide-toggle color="primary" formControlName="enableMarkers"
                            >Mostrar marcadores</mat-slide-toggle
                        >
                    </button>
                    <button mat-menu-item>
                        <mat-slide-toggle color="primary" formControlName="enableUserMarkers"
                            >Mostrar operarios</mat-slide-toggle>
                    </button>
                    <button mat-menu-item>
                        <mat-slide-toggle color="primary" formControlName="enableSectors"
                            >Mostrar perímetros</mat-slide-toggle>
                    </button>
                    <button mat-menu-item>
                        <mat-slide-toggle color="primary" formControlName="enableMarkerDrag"
                            >Mover marcadores</mat-slide-toggle>
                    </button>
                    <button
                        mat-menu-item
                        matTooltip="Seleccionar perímetros del mapa"
                        (click)="loadSectors()"
                    >
                        <fa-icon class="fa-icon-column" [icon]="faChartPie"></fa-icon>
                        <span style="margin-bottom: 10px">Seleccionar perímetros</span>
                    </button>
                    <button
                        *ngIf="!loading && footprintMarkers && footprintMarkers.size > 0"
                        mat-menu-item
                        matTooltip="Dejar de mostrar trayectoria"
                        (click)="removeTrace()"
                    >
                        <fa-icon class="fa-icon-column" [icon]="faLowVision"></fa-icon>
                        <span style="margin-bottom: 10px">Ocultar trayectoria</span>
                    </button>
                </mat-menu>
            </div>

            <div
                *ngIf="!loading && !_utilsService.isClientUser()"
                class="top-left-button-filter"
                style="left: 345px"
            >
                <button
                    mat-raised-button
                    [matTooltip]="drawingActive ? 'Limpiar trazado' : 'Dibujar zona en mapa'"
                    class="raise-filter-button"
                    (click)="toggleDrawing()"
                >
                    <fa-icon *ngIf="!drawingActive" class="fa-icon-column" [icon]="faPen"></fa-icon>
                    <fa-icon
                        *ngIf="drawingActive"
                        class="fa-icon-column"
                        [icon]="faEraser"
                    ></fa-icon>
                </button>
            </div>
            <div
                *ngIf="
                    !loading &&
                    drawingActive &&
                    polylineOptionsDrawing &&
                    polylineOptionsDrawing.path &&
                    polylineOptionsDrawing.path.length > 2
                "
                class="top-left-button-filter"
                style="left: 420px"
            >
                <button
                    title="save"
                    mat-raised-button
                    matTooltip="Salvar zona dibujada"
                    class="raise-filter-button"
                    (click)="saveDrewZone()"
                >
                    <fa-icon class="fa-icon-column" [icon]="faSave"></fa-icon>
                </button>
            </div>
        </div>
    </mat-drawer-content>
</mat-drawer-container>

<div class="floating-top-right" (click)="location.back()">
    <button mat-raised-button class="raise-filter-button" matTooltip="Ir atrás">
        <mat-icon style="font-size: 20px; margin-top: 3px; margin-left: 8px"
            >arrow_back_ios</mat-icon
        >
    </button>
</div>

<div class="floating-top-right" style="right: 135px" (click)="reload()">
    <button mat-raised-button class="raise-filter-button" matTooltip="Recargar información">
        <mat-icon style="font-size: 25px; margin-bottom: 3px">sync</mat-icon>
    </button>
</div>

<div class="floating-top-right" style="right: 210px" (click)="showInTable()">
    <button mat-raised-button class="raise-filter-button" matTooltip="Mostrar en tabla de tareas">
        <mat-icon style="font-size: 25px; margin-bottom: 3px">list</mat-icon>
    </button>
</div>

<div class="floating-top-right" style="right: 285px" (click)="showInSides()">
    <button mat-raised-button class="raise-filter-button" matTooltip="Mostrar bandos">
        <fa-icon class="fa-icon-column" [icon]="faBuilding"></fa-icon>
    </button>
</div>

<div class="floating-top-right" style="margin-top: 50px;" (click)="showTasksCount()">
    <button mat-raised-button class="raise-filter-button" 
    style="width: 100px; height: 35px;" 
    matTooltip="Total de tareas"
    >
        <mat-icon style="font-size: 20px;">grain</mat-icon>
        {{ taskCount }}
    </button>
</div>

<ngx-spinner
    bdColor="rgba(0, 0, 0, 0.3)"
    size="large"
    color="#43B0FF"
    type="ball-scale-multiple"
    [fullScreen]="true"
    name="mapSpinner"
>
    <div
        *ngIf="_utilsService.resposeError"
        class="reload-loading-button animate__animated animate__fadeInUp"
        (click)="reload()"
    >
        <button mat-icon-button matTooltip="Reintentar" color="primary">
            <mat-icon style="font-size: 25px; color: white">refresh</mat-icon>
        </button>
    </div>
    <div
        *ngIf="_utilsService.resposeError"
        class="close-loading-button animate__animated animate__fadeInDown"
        (click)="showLoading(false)"
    >
        <button mat-icon-button matTooltip="Cancelar petición" color="primary">
            <mat-icon style="font-size: 25px; color: white">close</mat-icon>
        </button>
    </div>
</ngx-spinner>

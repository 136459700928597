<div [formGroup]="managerMessageFormData"
class=" animate__animated animate__fadeIn card">
    <div style="margin-top: 5px; margin-bottom: 30px; margin-left: 5px;">                        
        <p style="font-size: large;">{{ (managerMessageId)? 'Editar mensaje de gestor': 'Nuevo mensaje de gestor'}}</p>
    </div> 
    <div>
        <div class="row-content">
            <mat-form-field appearance="fill">
                <mat-label>Mensaje</mat-label>
                <textarea
                    matInput
                    formControlName="message"
                    placeholder="Mensaje"
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="3"
                >
                </textarea>
            </mat-form-field>
        </div>
    </div>
</div>

<ngx-spinner [bdColor] = "(managerMessage)?'rgba(0, 0, 0, 0.2)': 'rgba(255, 255, 255, 1)'" size = "large" color = "#368DCE" 
type = "ball-scale-multiple" [fullScreen] = "false" name="managerMessageSpinner">
</ngx-spinner>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="managerMessage">Cancelar</button>
    <button mat-button cdkFocusInitial
    matTooltip="Guardar cambios" 
    (click)="saveChanges()"> 
        <mat-icon color="primary">check</mat-icon> {{ (managerMessageId)?'Actualizar':'Añadir' }}
    </button>
</mat-dialog-actions>


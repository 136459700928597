import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';


export interface DialogData {
  title: string;
  data: any;
  acceptText: string;
  cancelText: string;
}

@Component({
  selector: 'app-data-dialog',
  templateUrl: './data-dialog.component.html',
  styleUrls: ['./data-dialog.component.scss']
})
export class DataDialogComponent implements OnInit {
  title: string = 'Titulo';
  data: any;
  acceptText: string = 'Aceptar';
  cancelText: string = 'Cancelar';

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: DialogData) {
      this.title = dialogData.title;
      const json = JSON.parse(dialogData.data);
      if(json) this.data = JSON.stringify(json, null, 4);
      if (dialogData.acceptText) this.acceptText = dialogData.acceptText;
      if (dialogData.cancelText) this.cancelText = dialogData.cancelText;
  }

  ngOnInit(): void {}

}

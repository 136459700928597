<div
    *ngIf="itac || itacId == 'new'"
    [formGroup]="itacFormData"
    class="animate__animated animate__fadeIn animate__slow"
    style="background-color: #f7f7f7"
>
    <div>
        <div class="container">
            <div class="row bd-highlight" class="row-content-center">
                <mat-card class="mat-card-block">
                    <mat-card-subtitle style="margin: 6px; margin-bottom: 15px">
                        <p style="font-size: large">Datos de Importación</p>
                    </mat-card-subtitle>
                    <mat-card-content>
                        <div class="row">
                            <mat-form-field style="width: 120px" appearance="fill">
                                <mat-label>Equipo</mat-label>
                                <input
                                    matInput
                                    [value]="itacFormData.get('equipo')?.value | team"
                                    type="text"
                                    placeholder="Equipo"
                                    readonly
                                />
                            </mat-form-field>
                            <mat-form-field style="max-width: 160px" appearance="fill">
                                <mat-label>Operarios</mat-label>
                                <mat-select formControlName="operario" multiple>
                                    <mat-option
                                        *ngFor="let option of itac?.operario"
                                        style="color: black !important"
                                        [value]="option"
                                        disabled
                                        >{{ option | user }}</mat-option
                                    >
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field style="width: 60px" appearance="fill">
                                <mat-label>Prioridad</mat-label>
                                <mat-select formControlName="prioridad">
                                    <mat-option *ngFor="let option of [1, 2, 3]" [value]="option">{{
                                        option | priority
                                    }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field style="width: 140px" appearance="fill">
                                <mat-label>Fecha Creación</mat-label>
                                <input
                                    matInput
                                    [matDatepicker]="picker_fecha_importacion"
                                    formControlName="fecha_importacion"
                                    placeholder="Fecha Creación"
                                />
                                <mat-datepicker-toggle
                                    matSuffix
                                    [for]="picker_fecha_importacion"
                                ></mat-datepicker-toggle>
                                <mat-datepicker #picker_fecha_importacion></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </mat-card-content>
                </mat-card>

                <mat-card class="mat-card-block">
                    <mat-card-subtitle style="margin: 6px; margin-bottom: 15px">
                        <p style="font-size: large">Datos de Localización</p>
                    </mat-card-subtitle>
                    <mat-card-content>
                        <div class="row">
                            <mat-form-field style="width: 80px" appearance="fill">
                                <mat-label>Código de emplazamiento</mat-label>
                                <input
                                    formControlName="codigo_itac"
                                    matInput
                                    type="text"
                                    placeholder="Código de emplazamiento"
                                    required
                                />
                            </mat-form-field>
                            <mat-form-field style="flex: 2" appearance="fill">
                                <mat-label>Dirección</mat-label>
                                <input
                                    formControlName="itac"
                                    matInput
                                    type="text"
                                    placeholder="Dirección"
                                    required
                                />
                            </mat-form-field>
                            <mat-form-field style="width: 120px" appearance="fill">
                                <mat-label>Sector P</mat-label>
                                <mat-select formControlName="zona">
                                    <mat-option *ngFor="let option of zones" [value]="option">{{
                                        option
                                    }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div style="display: flex; margin: 5px; height: 50px;">
                                <mat-slide-toggle style="margin: auto;"
                                    color="warn" 
                                    formControlName="pendent_location"
                                    (change)="pendentLocationChange($event)"
                                    >Mal localizada
                                </mat-slide-toggle>
                            </div>
                            <button
                                *ngIf="itacId"
                                mat-raised-button
                                [ngClass]="{
                                    'map-button': !itacFormData.get('pendent_location')?.value, 
                                    'map-button-red': itacFormData.get('pendent_location')?.value
                                }"
                                matTooltip="Mostrar posición en mapa"
                                (click)="openItacLocation()"
                            >
                                <mat-icon>place</mat-icon>
                            </button>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>

    <div>
        <div class="container">
            <div class="row bd-highlight" class="row-content-center">
                <mat-card class="mat-card-block">
                    <mat-card-subtitle style="margin: 6px; margin-bottom: 15px">
                        <p style="font-size: large">Detalles</p>
                    </mat-card-subtitle>
                    <mat-card-content>
                        <div class="row">
                            <mat-form-field appearance="fill">
                                <mat-label>Acceso</mat-label>
                                <textarea
                                    matInput
                                    formControlName="acceso"
                                    placeholder="Acceso"
                                    cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize"
                                    cdkAutosizeMinRows="3"
                                >
                                </textarea>
                            </mat-form-field>
                            <mat-form-field appearance="fill">
                                <mat-label>Descripción</mat-label>
                                <textarea
                                    matInput
                                    formControlName="descripcion"
                                    placeholder="Descripción"
                                    cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize"
                                    cdkAutosizeMinRows="3"
                                >
                                </textarea>
                            </mat-form-field>
                        </div>

                        <div class="row">
                            <mat-form-field appearance="fill">
                                <mat-label>Planificación</mat-label>
                                <input
                                    type="text"
                                    placeholder="Planificación ..."
                                    aria-label="Planning"
                                    matInput
                                    [formControl]="planningsControl"
                                    [matAutocomplete]="autoPlanning"
                                />
                                <mat-autocomplete #autoPlanning="matAutocomplete">
                                    <mat-option
                                        *ngFor="let planning of plannings"
                                        [value]="planning"
                                        [matTooltip]="planning"
                                    >
                                        {{ planning }}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>

                            <mat-form-field appearance="fill">
                                <mat-label>Detalle de planificación</mat-label>
                                <mat-select formControlName="planning_details">
                                    <mat-option *ngFor="let option of planningDetails"
                                        [matTooltip]="option"
                                        [value]="option">{{option}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="row">
                            <mat-form-field style="width: 350px" appearance="fill">
                                <mat-label>Extras planificación</mat-label>
                                <mat-select 
                                formControlName="planning_detail_extras" 
                                (closed)="onPlannigExtraEditEnds()"
                                multiple>
                                    <mat-option
                                        *ngFor="let option of planningDetailExtras"
                                        [matTooltip]="option"
                                        [value]="option"
                                        >{{ option }}</mat-option
                                    >
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </mat-card-content>
                </mat-card>

                <mat-card class="mat-card-block">
                    <mat-card-subtitle style="margin: 6px; margin-bottom: 15px">
                        <p style="font-size: large">Secciones de Itac</p>
                    </mat-card-subtitle>
                    <mat-card-content>
                        <div class="row">
                            <button
                                mat-raised-button
                                class="section-itac-button"
                                matTooltip="Sección 1 Acceso y Ubicación"
                                (click)="openSection1()"
                            >
                                <fa-icon [icon]="faBuilding"></fa-icon>
                                Acceso y Ubicación
                            </button>
                            <button
                                mat-raised-button
                                class="section-itac-button"
                                matTooltip="Sección 2 Llaves de Puertas"
                                (click)="openSection2()"
                            >
                                <fa-icon [icon]="faKey"></fa-icon>
                                <span>Llaves de Puertas</span>
                            </button>
                            <button
                                mat-raised-button
                                class="section-itac-button"
                                matTooltip="Sección 3 Estado de conservación y Diseño de zona de trabajo"
                                (click)="openSection3()"
                            >
                                <img src="assets/img/status.svg" />
                                Estado y Diseño
                            </button>
                        </div>

                        <div class="row">
                            <button
                                mat-raised-button
                                class="section-itac-button"
                                matTooltip="Sección 4 Estado de tuberías"
                                (click)="openSection4()"
                            >
                                <img src="assets/img/pipe.svg" />
                                Estado de tuberías
                            </button>
                            <button
                                mat-raised-button
                                class="section-itac-button"
                                matTooltip="Sección 5 Estado de las válvulas"
                                (click)="openSection5()"
                            >
                                <img src="assets/img/valve.svg" />
                                Estado de Válvulas
                            </button>
                            <button
                                mat-raised-button
                                class="section-itac-button"
                                matTooltip="Resumir estado de instalación"
                                (click)="openStateSummary()"
                            >
                                <img src="assets/img/flash.svg" />
                                Resumir estado
                            </button>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>

    <div>
        <div class="container">
            <div class="row bd-highlight" class="row-content-center">
                <mat-card class="mat-card-block-30">
                    <mat-card-subtitle style="margin: 6px; margin-bottom: 15px">
                        <p style="font-size: large">Administración Finca</p>
                    </mat-card-subtitle>
                    <mat-card-content>
                        <div class="row">
                            <mat-form-field appearance="fill">
                                <mat-label>Nombre empresa</mat-label>
                                <input
                                    formControlName="nombre_empresa_administracion"
                                    matInput
                                    type="text"
                                    placeholder="Nombre empresa"
                                />
                            </mat-form-field>
                            <mat-form-field style="min-width: 100px" appearance="fill">
                                <mat-label>Nombre responsable</mat-label>
                                <input
                                    formControlName="nombre_responsable_administracion"
                                    matInput
                                    type="text"
                                    placeholder="Nombre responsable"
                                />
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field appearance="fill">
                                <mat-label>Teléfono fijo</mat-label>
                                <input
                                    formControlName="telefono_fijo_administracion"
                                    matInput
                                    type="text"
                                    placeholder="Teléfono fijo"
                                />
                            </mat-form-field>
                            <mat-form-field style="min-width: 100px" appearance="fill">
                                <mat-label>Teléfono móvil</mat-label>
                                <input
                                    formControlName="telefono_movil_administracion"
                                    matInput
                                    type="text"
                                    placeholder="Teléfono móvil"
                                />
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field appearance="fill">
                                <mat-label>Dirección oficina</mat-label>
                                <input
                                    formControlName="direccion_oficina"
                                    matInput
                                    type="text"
                                    placeholder="Dirección oficina"
                                />
                            </mat-form-field>
                            <mat-form-field style="min-width: 100px" appearance="fill">
                                <mat-label>Correo</mat-label>
                                <input
                                    formControlName="correo_administracion"
                                    matInput
                                    type="text"
                                    placeholder="Correo"
                                />
                            </mat-form-field>
                        </div>
                    </mat-card-content>
                </mat-card>

                <mat-card class="mat-card-block-30">
                    <mat-card-subtitle style="margin: 6px; margin-bottom: 15px">
                        <p style="font-size: large">Presidente o Colaborador</p>
                    </mat-card-subtitle>
                    <mat-card-content>
                        <div class="row">
                            <mat-form-field appearance="fill">
                                <mat-label>Nombre</mat-label>
                                <input
                                    formControlName="nombre_presidente"
                                    matInput
                                    type="text"
                                    placeholder="Nombre"
                                />
                            </mat-form-field>
                            <mat-form-field style="min-width: 100px" appearance="fill">
                                <mat-label>Vivienda</mat-label>
                                <input
                                    formControlName="vivienda_presidente"
                                    matInput
                                    type="text"
                                    placeholder="Vivienda"
                                />
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field appearance="fill">
                                <mat-label>Teléfono fijo</mat-label>
                                <input
                                    formControlName="telefono_fijo_presidente"
                                    matInput
                                    type="text"
                                    placeholder="Teléfono fijo"
                                />
                            </mat-form-field>
                            <mat-form-field style="min-width: 100px" appearance="fill">
                                <mat-label>Teléfono móvil</mat-label>
                                <input
                                    formControlName="telefono_movil_presidente"
                                    matInput
                                    type="text"
                                    placeholder="Teléfono móvil"
                                />
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field appearance="fill">
                                <mat-label>Correo</mat-label>
                                <input
                                    formControlName="correo_presidente"
                                    matInput
                                    type="text"
                                    placeholder="Correo"
                                />
                            </mat-form-field>
                        </div>
                    </mat-card-content>
                </mat-card>

                <mat-card class="mat-card-block-30">
                    <mat-card-subtitle style="margin: 6px; margin-bottom: 15px">
                        <p style="font-size: large">Encargado o Conserje</p>
                    </mat-card-subtitle>
                    <mat-card-content>
                        <div class="row">
                            <mat-form-field appearance="fill">
                                <mat-label>Nombre</mat-label>
                                <input
                                    formControlName="nombre_encargado"
                                    matInput
                                    type="text"
                                    placeholder="Nombre"
                                />
                            </mat-form-field>
                            <mat-form-field style="min-width: 100px" appearance="fill">
                                <mat-label>Vivienda</mat-label>
                                <input
                                    formControlName="vivienda_encargado"
                                    matInput
                                    type="text"
                                    placeholder="Vivienda"
                                />
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field appearance="fill">
                                <mat-label>Teléfono fijo</mat-label>
                                <input
                                    formControlName="telefono_fijo_encargado"
                                    matInput
                                    type="text"
                                    placeholder="Teléfono fijo"
                                />
                            </mat-form-field>
                            <mat-form-field style="min-width: 100px" appearance="fill">
                                <mat-label>Teléfono móvil</mat-label>
                                <input
                                    formControlName="telefono_movil_encargado"
                                    matInput
                                    type="text"
                                    placeholder="Teléfono móvil"
                                />
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field appearance="fill">
                                <mat-label>Correo</mat-label>
                                <input
                                    formControlName="correo_encargado"
                                    matInput
                                    type="text"
                                    placeholder="Correo"
                                />
                            </mat-form-field>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>

    <input class="input-hidden" type="file" (change)="uploadFile($event)" #input_file />

    <div>
        <div class="container">
            <div class="row" class="row-content-center">
                <mat-card class="mat-card-photo">
                    <mat-card-subtitle style="margin: 6px; margin-bottom: 15px">
                        <p style="font-size: 1.2em; text-align: center">Foto 1</p>
                    </mat-card-subtitle>
                    <mat-card-content>
                        <mat-form-field appearance="fill">
                            <mat-label>Descripción</mat-label>
                            <textarea
                                matInput
                                formControlName="descripcion_foto_1"
                                placeholder="Descripción..."
                                cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize"
                                cdkAutosizeMinRows="1"
                            >
                            </textarea>
                        </mat-form-field>
                        <img
                            width="220"
                            style="border-radius: 7px"
                            [src]="itac?.foto_1"
                            id="foto_1"
                            onerror="this.onerror=null; this.src='assets/img/noimage.png'"
                        />
                        <mat-progress-bar
                            *ngIf="uploading && photoColumn == 'foto_1'"
                            color="primary"
                            mode="determinate"
                            value="{{ percentage }}"
                        ></mat-progress-bar>
                    </mat-card-content>
                    <mat-card-actions style="text-align: center">
                        <button
                            *ngIf="!_utilsService.isClientUser()"
                            mat-button
                            (click)="selectFile(input_file, 'foto_1')"
                        >
                            Cambiar
                        </button>
                        <button
                            *ngIf="!_utilsService.isClientUser()"
                            mat-icon-button
                            (click)="rotateImage('foto_1')"
                        >
                            <mat-icon>crop_rotate</mat-icon>
                        </button>
                        <button mat-button (click)="showImage('foto_1')">Mostrar</button>
                    </mat-card-actions>
                </mat-card>

                <mat-card class="mat-card-photo">
                    <mat-card-subtitle style="margin: 6px; margin-bottom: 15px">
                        <p style="font-size: 1.2em; text-align: center">Foto 2</p>
                    </mat-card-subtitle>
                    <mat-card-content>
                        <mat-form-field appearance="fill">
                            <mat-label>Descripción</mat-label>
                            <textarea
                                matInput
                                formControlName="descripcion_foto_2"
                                placeholder="Descripción..."
                                cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize"
                                cdkAutosizeMinRows="1"
                            >
                            </textarea>
                        </mat-form-field>
                        <img
                            width="220"
                            style="border-radius: 7px"
                            [src]="itac?.foto_2"
                            id="foto_2"
                            onerror="this.onerror=null; this.src='assets/img/noimage.png'"
                        />
                        <mat-progress-bar
                            *ngIf="uploading && photoColumn == 'foto_2'"
                            color="primary"
                            mode="determinate"
                            value="{{ percentage }}"
                        ></mat-progress-bar>
                    </mat-card-content>
                    <mat-card-actions style="text-align: center">
                        <button
                            *ngIf="!_utilsService.isClientUser()"
                            mat-button
                            (click)="selectFile(input_file, 'foto_2')"
                        >
                            Cambiar
                        </button>
                        <button
                            *ngIf="!_utilsService.isClientUser()"
                            mat-icon-button
                            (click)="rotateImage('foto_2')"
                        >
                            <mat-icon>crop_rotate</mat-icon>
                        </button>
                        <button mat-button (click)="showImage('foto_2')">Mostrar</button>
                    </mat-card-actions>
                </mat-card>

                <mat-card class="mat-card-photo">
                    <mat-card-subtitle style="margin: 6px; margin-bottom: 15px">
                        <p style="font-size: 1.2em; text-align: center">Foto 3</p>
                    </mat-card-subtitle>
                    <mat-card-content>
                        <mat-form-field appearance="fill">
                            <mat-label>Descripción</mat-label>
                            <textarea
                                matInput
                                formControlName="descripcion_foto_3"
                                placeholder="Descripción..."
                                cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize"
                                cdkAutosizeMinRows="1"
                            >
                            </textarea>
                        </mat-form-field>
                        <img
                            width="220"
                            style="border-radius: 7px"
                            [src]="itac?.foto_3"
                            id="foto_3"
                            onerror="this.onerror=null; this.src='assets/img/noimage.png'"
                        />
                        <mat-progress-bar
                            *ngIf="uploading && photoColumn == 'foto_3'"
                            color="primary"
                            mode="determinate"
                            value="{{ percentage }}"
                        ></mat-progress-bar>
                    </mat-card-content>
                    <mat-card-actions style="text-align: center">
                        <button
                            *ngIf="!_utilsService.isClientUser()"
                            mat-button
                            (click)="selectFile(input_file, 'foto_3')"
                        >
                            Cambiar
                        </button>
                        <button
                            *ngIf="!_utilsService.isClientUser()"
                            mat-icon-button
                            (click)="rotateImage('foto_3')"
                        >
                            <mat-icon>crop_rotate</mat-icon>
                        </button>
                        <button mat-button (click)="showImage('foto_3')">Mostrar</button>
                    </mat-card-actions>
                </mat-card>

                <mat-card class="mat-card-photo">
                    <mat-card-subtitle style="margin: 6px; margin-bottom: 15px">
                        <p style="font-size: 1.2em; text-align: center">Foto 4</p>
                    </mat-card-subtitle>
                    <mat-card-content>
                        <mat-form-field appearance="fill">
                            <mat-label>Descripción</mat-label>
                            <textarea
                                matInput
                                formControlName="descripcion_foto_4"
                                placeholder="Descripción..."
                                cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize"
                                cdkAutosizeMinRows="1"
                            >
                            </textarea>
                        </mat-form-field>
                        <img
                            width="220"
                            style="border-radius: 7px"
                            [src]="itac?.foto_4"
                            id="foto_4"
                            onerror="this.onerror=null; this.src='assets/img/noimage.png'"
                        />
                        <mat-progress-bar
                            *ngIf="uploading && photoColumn == 'foto_4'"
                            color="primary"
                            mode="determinate"
                            value="{{ percentage }}"
                        ></mat-progress-bar>
                    </mat-card-content>
                    <mat-card-actions style="text-align: center">
                        <button
                            *ngIf="!_utilsService.isClientUser()"
                            mat-button
                            (click)="selectFile(input_file, 'foto_4')"
                        >
                            Cambiar
                        </button>
                        <button
                            *ngIf="!_utilsService.isClientUser()"
                            mat-icon-button
                            (click)="rotateImage('foto_4')"
                        >
                            <mat-icon>crop_rotate</mat-icon>
                        </button>
                        <button mat-button (click)="showImage('foto_4')">Mostrar</button>
                    </mat-card-actions>
                </mat-card>
            </div>
        </div>
    </div>

    <div>
        <div class="container">
            <div class="row" class="row-content-center">
                <mat-card class="mat-card-photo">
                    <mat-card-subtitle style="margin: 6px; margin-bottom: 15px">
                        <p style="font-size: 1.2em; text-align: center">Foto 5</p>
                    </mat-card-subtitle>
                    <mat-card-content>
                        <mat-form-field appearance="fill">
                            <mat-label>Descripción</mat-label>
                            <textarea
                                matInput
                                formControlName="descripcion_foto_5"
                                placeholder="Descripción..."
                                cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize"
                                cdkAutosizeMinRows="1"
                            >
                            </textarea>
                        </mat-form-field>
                        <img
                            width="220"
                            style="border-radius: 7px"
                            [src]="itac?.foto_5"
                            id="foto_5"
                            onerror="this.onerror=null; this.src='assets/img/noimage.png'"
                        />
                        <mat-progress-bar
                            *ngIf="uploading && photoColumn == 'foto_5'"
                            color="primary"
                            mode="determinate"
                            value="{{ percentage }}"
                        ></mat-progress-bar>
                    </mat-card-content>
                    <mat-card-actions style="text-align: center">
                        <button
                            *ngIf="!_utilsService.isClientUser()"
                            mat-button
                            (click)="selectFile(input_file, 'foto_5')"
                        >
                            Cambiar
                        </button>
                        <button
                            *ngIf="!_utilsService.isClientUser()"
                            mat-icon-button
                            (click)="rotateImage('foto_5')"
                        >
                            <mat-icon>crop_rotate</mat-icon>
                        </button>
                        <button mat-button (click)="showImage('foto_5')">Mostrar</button>
                    </mat-card-actions>
                </mat-card>

                <mat-card class="mat-card-photo">
                    <mat-card-subtitle style="margin: 6px; margin-bottom: 15px">
                        <p style="font-size: 1.2em; text-align: center">Foto 6</p>
                    </mat-card-subtitle>
                    <mat-card-content>
                        <mat-form-field appearance="fill">
                            <mat-label>Descripción</mat-label>
                            <textarea
                                matInput
                                formControlName="descripcion_foto_6"
                                placeholder="Descripción..."
                                cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize"
                                cdkAutosizeMinRows="1"
                            >
                            </textarea>
                        </mat-form-field>
                        <img
                            width="220"
                            style="border-radius: 7px"
                            [src]="itac?.foto_6"
                            id="foto_6"
                            onerror="this.onerror=null; this.src='assets/img/noimage.png'"
                        />
                        <mat-progress-bar
                            *ngIf="uploading && photoColumn == 'foto_6'"
                            color="primary"
                            mode="determinate"
                            value="{{ percentage }}"
                        ></mat-progress-bar>
                    </mat-card-content>
                    <mat-card-actions style="text-align: center">
                        <button
                            *ngIf="!_utilsService.isClientUser()"
                            mat-button
                            (click)="selectFile(input_file, 'foto_6')"
                        >
                            Cambiar
                        </button>
                        <button
                            *ngIf="!_utilsService.isClientUser()"
                            mat-icon-button
                            (click)="rotateImage('foto_6')"
                        >
                            <mat-icon>crop_rotate</mat-icon>
                        </button>
                        <button mat-button (click)="showImage('foto_6')">Mostrar</button>
                    </mat-card-actions>
                </mat-card>

                <mat-card class="mat-card-photo">
                    <mat-card-subtitle style="margin: 6px; margin-bottom: 15px">
                        <p style="font-size: 1.2em; text-align: center">Foto 7</p>
                    </mat-card-subtitle>
                    <mat-card-content>
                        <mat-form-field appearance="fill">
                            <mat-label>Descripción</mat-label>
                            <textarea
                                matInput
                                formControlName="descripcion_foto_7"
                                placeholder="Descripción..."
                                cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize"
                                cdkAutosizeMinRows="1"
                            >
                            </textarea>
                        </mat-form-field>
                        <img
                            width="220"
                            style="border-radius: 7px"
                            [src]="itac?.foto_7"
                            id="foto_7"
                            onerror="this.onerror=null; this.src='assets/img/noimage.png'"
                        />
                        <mat-progress-bar
                            *ngIf="uploading && photoColumn == 'foto_7'"
                            color="primary"
                            mode="determinate"
                            value="{{ percentage }}"
                        ></mat-progress-bar>
                    </mat-card-content>
                    <mat-card-actions style="text-align: center">
                        <button
                            *ngIf="!_utilsService.isClientUser()"
                            mat-button
                            (click)="selectFile(input_file, 'foto_7')"
                        >
                            Cambiar
                        </button>
                        <button
                            *ngIf="!_utilsService.isClientUser()"
                            mat-icon-button
                            (click)="rotateImage('foto_7')"
                        >
                            <mat-icon>crop_rotate</mat-icon>
                        </button>
                        <button mat-button (click)="showImage('foto_7')">Mostrar</button>
                    </mat-card-actions>
                </mat-card>

                <mat-card class="mat-card-photo">
                    <mat-card-subtitle style="margin: 6px; margin-bottom: 15px">
                        <p style="font-size: 1.2em; text-align: center">Foto 8</p>
                    </mat-card-subtitle>
                    <mat-card-content>
                        <mat-form-field appearance="fill">
                            <mat-label>Descripción</mat-label>
                            <textarea
                                matInput
                                formControlName="descripcion_foto_8"
                                placeholder="Descripción..."
                                cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize"
                                cdkAutosizeMinRows="1"
                            >
                            </textarea>
                        </mat-form-field>
                        <img
                            width="220"
                            style="border-radius: 7px"
                            [src]="itac?.foto_8"
                            id="foto_8"
                            onerror="this.onerror=null; this.src='assets/img/noimage.png'"
                        />
                        <mat-progress-bar
                            *ngIf="uploading && photoColumn == 'foto_8'"
                            color="primary"
                            mode="determinate"
                            value="{{ percentage }}"
                        ></mat-progress-bar>
                    </mat-card-content>
                    <mat-card-actions style="text-align: center">
                        <button
                            *ngIf="!_utilsService.isClientUser()"
                            mat-button
                            (click)="selectFile(input_file, 'foto_8')"
                        >
                            Cambiar
                        </button>
                        <button
                            *ngIf="!_utilsService.isClientUser()"
                            mat-icon-button
                            (click)="rotateImage('foto_8')"
                        >
                            <mat-icon>crop_rotate</mat-icon>
                        </button>
                        <button mat-button (click)="showImage('foto_8')">Mostrar</button>
                    </mat-card-actions>
                </mat-card>
            </div>
        </div>
    </div>

    <div>
        <div class="container">
            <div class="row" class="row-content-center">
                <mat-card class="mat-card-photo">
                    <mat-card-subtitle style="margin: 6px; margin-bottom: 15px">
                        <p style="font-size: 1.2em; text-align: center">Firma</p>
                    </mat-card-subtitle>
                    <mat-card-content>
                        <img
                            width="220"
                            style="border-radius: 7px"
                            [src]="itac?.foto_9"
                            id="foto_9"
                            onerror="this.onerror=null; this.src='assets/img/noimage.png'"
                        />
                        <mat-progress-bar
                            *ngIf="uploading && photoColumn == 'foto_9'"
                            color="primary"
                            mode="determinate"
                            value="{{ percentage }}"
                        ></mat-progress-bar>
                    </mat-card-content>
                    <mat-card-actions style="text-align: center">
                        <button
                            *ngIf="!_utilsService.isClientUser()"
                            mat-button
                            (click)="selectFile(input_file, 'foto_9')"
                        >
                            Cambiar
                        </button>
                        <button
                            *ngIf="!_utilsService.isClientUser()"
                            mat-icon-button
                            (click)="rotateImage('foto_9')"
                        >
                            <mat-icon>crop_rotate</mat-icon>
                        </button>
                        <button mat-button (click)="showImage('foto_9')">Mostrar</button>
                    </mat-card-actions>
                </mat-card>

                <mat-card class="mat-card-block-30" style="max-height: 300px; margin: 40px">
                    <mat-card-subtitle style="margin: 6px; margin-bottom: 15px">
                        <p style="font-size: large">Datos de Firmante</p>
                    </mat-card-subtitle>
                    <mat-card-content>
                        <div class="row">
                            <mat-form-field appearance="fill">
                                <mat-label>Nombre firmante</mat-label>
                                <input
                                    formControlName="nombre_firmante"
                                    matInput
                                    type="text"
                                    placeholder="Nombre firmante"
                                />
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field appearance="fill">
                                <mat-label>Carnet firmante</mat-label>
                                <input
                                    formControlName="carnet_firmante"
                                    matInput
                                    type="text"
                                    placeholder="Carnet firmante"
                                />
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-form-field appearance="fill">
                                <mat-label>Nuevo Citas</mat-label>
                                <input
                                    formControlName="nuevo_citas"
                                    matInput
                                    type="text"
                                    placeholder="Nuevo Citas"
                                />
                                <button
                                    *ngIf="itacFormData.controls['nuevo_citas'].value"
                                    matSuffix
                                    mat-icon-button
                                    aria-label="Clear"
                                    (click)="clearDate()"
                                >
                                    <mat-icon>close</mat-icon>
                                </button>
                            </mat-form-field>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>

    <div style="height: 82px"></div>

    <div
        *ngIf="_electronService.isElectronApp()"
        class="floating-top-left"
        (click)="location.back()"
    >
        <button mat-fab color="primary">
            <mat-icon style="margin-left: 10px" matTooltip="Ir atrás">arrow_back_ios</mat-icon>
        </button>
    </div>

    <div *ngIf="itac?.last_modification_android" class="floating-top-right">
        <mat-icon
            style="font-size: 50px"
            color="primary"
            matTooltip="Última modificación en aplicación móvil"
            >phone_android</mat-icon
        >
        <div
            style="
                position: absolute;
                bottom: -25px;
                right: -20px;
                background-color: #82d060;
                height: 12px;
                width: 12px;
                border-radius: 50%;
            "
        ></div>
    </div>

    <footer>
        <div class="floating-bottom-left">
            <ngx-audio-player
                *ngIf="
                    itac?.audio_detalle && itac?.audio_detalle?.includes('http') && msaapPlaylist
                "
                style="
                    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4) !important;
                "
                [playlist]="msaapPlaylist"
                [displayTitle]="msaapDisplayTitle"
                [autoPlay]="false"
                muted="muted"
                [displayPlaylist]="msaapDisplayPlayList"
                [displayVolumeControls]="msaapDisplayVolumeControls"
                [displayRepeatControls]="msaapDisplayRepeatControls"
                [disablePositionSlider]="msaapDisablePositionSlider"
                [displayArtist]="msaapDisplayArtist"
                [displayDuration]="msaapDisplayDuration"
                [expanded]="true"
            ></ngx-audio-player>
        </div>

        <section>
            <div class="example-button-row">
                <div class="example-flex-container">
                    <div *ngIf="!_utilsService.isClientUser()" class="example-button-container">
                        <button
                            mat-fab
                            color="primary"
                            aria-label="save icon"
                            matTooltip="Guardar cambios"
                            (click)="saveChanges()"
                        >
                            <mat-icon>save</mat-icon>
                        </button>
                    </div>
                    <div *ngIf="!_utilsService.isClientUser()" class="example-button-container">
                        <button
                            mat-fab
                            color="primary"
                            class="white-color"
                            aria-label="date_range icon"
                            matTooltip="Añadir cita a la tarea"
                            (click)="selectDateAppointment()"
                        >
                            <mat-icon color="primary">date_range</mat-icon>
                        </button>
                    </div>
                    <div *ngIf="!_utilsService.isClientUser()" class="example-button-container">
                        <button
                            mat-fab
                            class="whatsapp-color"
                            aria-label="Example icon button with a heart icon"
                            matTooltip="Enviar mensaje por whatsapp"
                            (click)="sendMessage()"
                        >
                            <img src="assets/img/whatsapp_icon.svg" />
                        </button>
                    </div>
                    <div class="example-button-container">
                        <button
                            mat-fab
                            class="acrobat-reader-color"
                            aria-label="Example icon button with a heart icon"
                            matTooltip="Opciones de PDF"
                            (click)="openPDF()"
                        >
                            <fa-icon style="font-size: 20px" [icon]="faFilePdf"></fa-icon>
                        </button>
                    </div>
                </div>
            </div>
        </section>

        <!-- <button mat-raised-button color="primary" class="close-button"
        matTooltip="Cerrar tarea" (click)="onCloseTask()">
            <span>Cerrar tarea</span>
        </button> -->
    </footer>
</div>

<div *ngIf="!loading && !itac && itacId != 'new'" class="center-child">
    <div style="text-align: center">
        <fa-icon style="font-size: 80px; color: #777777" [icon]="faInbox"></fa-icon>
    </div>
    <div style="margin: 15px">
        <span style="font-size: 20px; color: #777777">No hay información</span>
    </div>
</div>

<ngx-spinner
    [bdColor]="itac ? 'rgba(0, 0, 0, 0.2)' : 'rgba(255, 255, 255, 1)'"
    size="large"
    color="#368DCE"
    type="ball-scale-multiple"
    [fullScreen]="true"
    name="itacSpinner"
>
    <div
        *ngIf="_utilsService.resposeError"
        class="reload-loading-button animate__animated animate__fadeInUp"
        (click)="reload()"
    >
        <button mat-icon-button matTooltip="Reintentar" color="primary">
            <mat-icon style="font-size: 25px; color: white">refresh</mat-icon>
        </button>
    </div>
    <div
        *ngIf="_utilsService.resposeError"
        class="close-loading-button animate__animated animate__fadeInDown"
        (click)="showLoading(false)"
    >
        <button mat-icon-button matTooltip="Cancelar petición" color="primary">
            <mat-icon style="font-size: 25px; color: white">close</mat-icon>
        </button>
    </div>
</ngx-spinner>

/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, OnInit, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilsService } from 'src/app/services/utils.service';

export interface EditTextListDialogData {
    list: string[];
}

@Component({
    selector: 'app-edit-text-list',
    templateUrl: './edit-text-list.component.html',
    styleUrls: ['./edit-text-list.component.scss'],
})
export class EditTextListComponent implements OnInit {
    myControl = new FormControl();

    textList: string[];

    /**
     * Constructs an instance of EditTextListComponent.
     * 
     * @param data - The data injected into the dialog, containing the list of text items to be edited.
     * @param _utilsService - A utility service for various helper functions.
     */
    constructor(@Inject(MAT_DIALOG_DATA) public data: EditTextListDialogData, private _utilsService: UtilsService) {
        this.textList = data.list;
    }

    /**
     * Lifecycle hook that is called after data-bound properties of a directive are initialized.
     * Initialize the component or perform any necessary setup here.
     * This method is called once, after the first ngOnChanges.
     */
    ngOnInit(): void {}

    /**
     * Deletes a message from the text list at the specified index.
     *
     * @param i - The index of the message to delete.
     */
    deleteMessage(i: number) {
        this.textList.splice(i, 1);
    }

    /**
     * Adds a message to the text list if the current value of the control is valid.
     * Utilizes the `_utilsService` to check the validity of the field.
     * If the field is valid, the value is pushed to the `textList` array and the control value is reset.
     *
     * @returns {void}
     */
    addMessage(): void {
        if (this._utilsService.isFieldNotValid(this.myControl.value)) return;
        this.textList.push(this.myControl.value);
        this.myControl.setValue('');
    }
    
    /**
     * Closes the edit text list dialog.
     * 
     * This method first adds a message and then uses the utility service to close the dialog,
     * passing the current text list as a parameter.
     */
    closeDialog() {
        this.addMessage();
        this._utilsService.closeEditTextListDialog(this.textList);
    }
}

<mat-dialog-content>
    <mat-form-field appearance="fill" style="padding: 10px">
        <mat-label> {{ placeHolderText }} </mat-label>
        <mat-date-range-input [formGroup]="range" [rangePicker]="rangePicker">
            <input matStartDate formControlName="start" placeholder="Fecha inicial" />
            <input matEndDate formControlName="end" placeholder="Fecha final" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
        <mat-date-range-picker style="z-index: 999" #rangePicker>
            <mat-date-range-picker-actions>
                <button mat-button matDateRangePickerCancel>Cancelar</button>
                <button mat-raised-button color="primary" matDateRangePickerApply>Aceptar</button>
            </mat-date-range-picker-actions>
        </mat-date-range-picker>

        <mat-error *ngIf="range.controls['start'].hasError('matStartDateInvalid')"
            >Invalid start date</mat-error
        >
        <mat-error *ngIf="range.controls['end'].hasError('matEndDateInvalid')"
            >Invalid end date</mat-error
        >
    </mat-form-field>
    <mat-dialog-content>
        <mat-dialog-actions align="center">
            <button style="z-index: 999999 !important" mat-button mat-dialog-close>Cancelar</button>
            <button
                style="z-index: 999999 !important"
                mat-button
                (click)="onSelectedDate()"
                [mat-dialog-close]="date_range"
                [disabled]="!range.controls['start'].value"
            >
                Aceptar
            </button>
        </mat-dialog-actions>
    </mat-dialog-content></mat-dialog-content
>

/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { IpcService } from '../../services/ipc.service';
import { FormControl, FormGroup } from '@angular/forms';
import { ElectronService } from 'ngx-electron';
import { faEye, faEyeSlash, faUserTie } from '@fortawesome/free-solid-svg-icons';
/**
 * Represents the LoginComponent class.
 * This component is responsible for handling the login functionality.
 */
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
    /**
     * Represents the form data for the login component.
     */
    formData = new FormGroup({
        user_name: new FormControl(),
        password: new FormControl(),
    });

    /**
     * Represents the type of the password input field.
     */
    passworType = 'password';

    /**
     * Represents whether to show the cancel button or not.
     */
    showCancel = false;

    /**
     * Represents the font awesome eye icon.
     */
    faEye = faEye;

    /**
     * Represents the font awesome eye slash icon.
     */
    faEyeSlash = faEyeSlash;

    /**
     * Represents the font awesome user tie icon.
     */
    faUserTie = faUserTie;

    constructor(
        private router: Router,
        private _apiService: ApiService,
        public _utilsService: UtilsService,
        private spinner: NgxSpinnerService,
        private _ipcService: IpcService,
        private route: ActivatedRoute,
        public dialog: MatDialog
    ) {}

    /**
     * Lifecycle hook that is called after the component has been initialized.
     * It clears certain items from local storage and session storage.
     * If the application is running in Electron, it loads the saved credentials.
     */
    async ngOnInit(): Promise<void> {
        this._utilsService.clearLocalStorage([
            'displayedColumns_tasks',
            'displayedColumns_itacs',
            'displayedColumns_counters',
            'displayedColumns_waterRoutes',
            'displayedColumns_radiusModules',
            'restartFilters',
            'showNotifications',
            'openTaskInSameTab',
        ]);
        sessionStorage.clear();
        if (this._ipcService.isElectronApp()) this.loadCredentials();
    }

    /**
     * Lifecycle hook that is called when the component is about to be destroyed.
     */
    ngOnDestroy(): void {}

    /**
     * Toggles the visibility of the password input field.
     */
    togglePasswordVisibility() {
        if (this.passworType == 'password') this.passworType = 'text';
        else if (this.passworType == 'text') this.passworType = 'password';
    }

    /**
     * Performs the login operation.
     * @param email - The email address of the user.
     * @param password - The password of the user.
     */
    async login(email: string, password: string) {
        this.openLoading();
        localStorage.setItem('access_token', '');
        try {
            if (await this._apiService.login(email, password)) {
                if (this._ipcService.isElectronApp()) this.saveCredentials(email, password);
                try {
                    const token = localStorage.getItem('notificacion-token');
                    if (token) await this._apiService.addFCMToken(token);
                } catch (err) {}
                this.router.navigate(['/home']);
            } else {
                this._utilsService.openSnackBar('Nombre de usuario o contraseña incorrecto', 'error');
            }
        } catch (err) {
            this._utilsService.openSnackBar('Nombre de usuario o contraseña incorrecto', 'error');
        }
        this.closeLoading();
    }

    /**
     * Saves the login credentials in the Electron app.
     * @param username - The username to be saved.
     * @param password - The password to be saved.
     */
    saveCredentials(username: string, password: string) {
        const credentials = { username: username, password: password };
        const credentialsString = JSON.stringify(credentials);
        const enc = this._utilsService.encodeBase64(credentialsString);
        this._ipcService.sendMessage('save-credentials', {
            credentials: enc,
        });
    }

    /**
     * Loads the saved credentials from the Electron app.
     */
    async loadCredentials() {
        const credentials = await this._utilsService.loadCredentials(this._ipcService);
        if (credentials && credentials.username && credentials.password) {
            this.formData.controls['user_name'].setValue(credentials.username);
            this.formData.controls['password'].setValue(credentials.password);
        }
    }

    /**
     * Reloads the current route.
     */
    reload() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['./'], { relativeTo: this.route });
    }

    /**
     * Opens the loading spinner.
     */
    openLoading() {
        this.spinner.show();
    }

    /**
     * Closes the loading spinner.
     */
    closeLoading() {
        this.spinner.hide();
    }
}

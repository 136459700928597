
import { Component, Inject, OnInit, ElementRef, ViewChild  } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../data-dialog/data-dialog.component';
import { ApiService } from '../../../services/api.service';
import { Notification, NotificationActions, NotificationAndCount } from '../../../interfaces/notification';
import { UtilsService } from '../../../services/utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessagingService } from '../../../services/messaging.service';
import { Router } from '@angular/router';
import { IpcService } from '../../../services/ipc.service';
import { faInbox } from '@fortawesome/free-solid-svg-icons';
@Component({
    selector: 'app-notification-card',
    templateUrl: './notification-card.component.html',
    styleUrls: ['./notification-card.component.scss']
})
export class NotificationCardComponent implements OnInit {
    notifications: Notification[] = [];
    loading: boolean = false;
    total: number = 0;
    new_notifications_count = 0;
    page = 0;
    faInbox = faInbox;

    constructor(
        private _apiService: ApiService,
        private _utilsService: UtilsService,
        private _messagingService: MessagingService, 
        private spinner: NgxSpinnerService,
        private router: Router,
        private _electronService: IpcService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.showLoading(true);
        this.new_notifications_count = await this._apiService.getNewNotificationCount();
        const notificationAndCount = await this._apiService.getNotificationsAndCount(this.page++);
        this.total = notificationAndCount.total;
        this.notifications = notificationAndCount.notifications;
        this.showLoading(false);
    }

    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this.spinner.show('loadingSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else this.spinner.hide('loadingSpinner');
    }

    getCountText(): string {
        if(this.total == 0) return '';
        let text = '';
        let left = (this.page - 1)*20;
        if(left <= 0) left = 1;

        if(this.page * 20 > this.total) text = `${left}-${this.total} de ${this.total}`
        else  text = `${left}-${this.page * 20} de ${this.total}`
        return text;
    }

    async openNotification(index: number): Promise<void> {
        if(!this.notifications[index].is_seen) {
            const id = this.notifications[index].id;
            await this._apiService.updateNotification(id);
            this.notifications[index] = await this._apiService.getNotificationSync(id);
        } 
        this._messagingService.sendMessage('Update notification count');
        await this.executeNotificationActions(this.notifications[index]);
    }

    async executeNotificationActions(notification: Notification): Promise<void> {
        try {
            if(!notification.actions) return;
            if(notification.actions.type === 'OPEN_TASK') {
                await this.openTask(notification.actions);
            }
        } catch (err) {}
    }

    async openTask(notificationActions: NotificationActions): Promise<void> {
        const companyId = localStorage.getItem('company');
        const managerId = localStorage.getItem('manager');
        const action_data = JSON.parse(notificationActions.action_data);
        if(action_data.manager != managerId || action_data.company != companyId){
            const q = 'La tarea que está apunto de abrir no se encuentra en el gestor actual seleccionado.'+
                        '¿Desea abrirla de todas formas, no se va a cambiar de el gestor actual?';
            try {
                const res = await this._utilsService.openQuestionDialog('Cambio de gestor', q);
                if(!res) return;
            } catch (err) { return; }
        }
        if(action_data.task_id){
            if (this._electronService.isElectronApp()) {
                this.router.navigate(['/task', action_data.task_id]);
                this._utilsService.closeNotificationDialogDialog();
            } else {
                const url = this.router.serializeUrl(this.router.createUrlTree(['/task', action_data.task_id]));
                window.open(url, '_blank');
            }
        }
    }

    async onScroll(): Promise<void> {
        if(this.page * 20 > this.total) return;
        const notificationAndCount = await this._apiService.getNotificationsAndCount(this.page++);
        this.total = notificationAndCount.total;
        this.notifications = [ ...this.notifications, ...notificationAndCount.notifications];
    }

}

import { Component, OnInit, Inject } from '@angular/core';
import { ManagerMessage, getManagerMessageFormControls } from '../../../interfaces/manager-message';
import * as moment from 'moment';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '../../../services/api.service';
import { UtilsService } from '../../../services/utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Company } from '../../../interfaces/company';
import { Manager } from '../../../interfaces/manager';

/**
 * Represents the Manager Message Component.
 */
@Component({
    selector: 'app-manager-message',
    templateUrl: './manager-message.component.html',
    styleUrls: ['./manager-message.component.scss']
})
export class ManagerMessageComponent implements OnInit {
    loading: boolean = false;
    itac_message: boolean = false;
    managerMessageId: string = '';
    managerMessage?: ManagerMessage;
    managerMessageFormData: FormGroup = getManagerMessageFormControls();
    managerMessageSubcription$?: Subscription;

    /**
     * Represents the ManagerMessageComponent class.
     * This component is responsible for managing messages for the manager.
     */
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _apiService: ApiService,
        private _utilsService: UtilsService,
        private spinner: NgxSpinnerService,
    ) {
        this.managerMessageId = data.managerMessageId;
        this.itac_message = data.itac_message;
    }

    /**
    * Initializes the component.
    */
    async ngOnInit(): Promise<void> {
        if (this.managerMessageId) {
            this.showLoading(true);
            this.managerMessageSubcription$ = this._apiService
                .getDocument('manager-message', this.managerMessageId)
                .subscribe(async (doc: any) => {
                    if (!doc) {
                        this._utilsService.openSnackBar(
                            'Error obteniendo datos de mensaje de gestor',
                            'error'
                        );
                        this.showLoading(false);
                        return;
                    }
                    this.managerMessageFormData = this.convertToManagerMessageGroup(doc);
                    this.showLoading(false);
                });
        }
    }

    /**
     * Converts a document to a ManagerMessage FormGroup.
     * 
     * @param doc - The document to be converted.
     * @returns The converted ManagerMessage FormGroup.
     */
    convertToManagerMessageGroup(doc: any): FormGroup {
        const managerMessage = doc as ManagerMessage;
        managerMessage.id = parseInt(this.managerMessageId);
        this.managerMessage = managerMessage;
        const managerMessageFormData = getManagerMessageFormControls();
        const keys = Object.keys(managerMessage);
        for (let key of keys) {
            let value: any = managerMessage[key as keyof ManagerMessage];
            if (this._utilsService.isValidDate(value)) value = value;
            managerMessageFormData.controls[key].setValue(value);
        }
        return managerMessageFormData;
    }

    /**
    * Lifecycle hook that is called when the component is destroyed.
    * It is used to perform any necessary cleanup before the component is removed from the DOM.
    */
    ngOnDestroy(): void {
        this.managerMessageSubcription$?.unsubscribe();
    }

    /**
    * Shows or hides the loading spinner based on the provided state.
    * @param state - A boolean value indicating whether to show or hide the loading spinner.
    */
    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
        this.spinner.show('managerMessageSpinner', {
            type: this._utilsService.getRandomNgxSpinnerType(),
        });
        } else {
        this.spinner.hide('managerMessageSpinner');
        }
    }
    
    /**
    * Saves the form data to the managerMessage object.
    */
    saveFormData() {
        const keys = Object.keys(this.managerMessageFormData.controls);
        let managerMessage: any = {};
        for (let key of keys) {
        let value = this.managerMessageFormData.controls[key].value; 
        try {
            if (moment.isMoment(value)) {
            value = value.toDate();
            }
            managerMessage![key as keyof ManagerMessage] = value;
        } catch (error) {}
        }
        managerMessage!['date_time_modified'] = new Date();
        this.managerMessage = managerMessage;
    }

    /**
    * Saves the changes made to the managerMessage.
    */
    async saveChanges() {
        this.showLoading(true);
        this.saveFormData();
        if (this.managerMessageId) {
            const managerMessage: boolean = await this._apiService.updateDocument(
                'manager-message',
                this.managerMessageId,
                this.managerMessage
            );
            if (managerMessage) this._utilsService.openSnackBar('Mensaje de gestor actualizado correctamente');
            else  this._utilsService.openSnackBar('Error actualizando mensaje de gestor', 'error');
        } else {
            await this.addNewMessage();
        }
        this.showLoading(false);
        this._utilsService.closeManagerMessageDialog(this.managerMessage!);
    }

    async addNewMessage(): Promise<void> {
        try {
            const company: Company = JSON.parse(localStorage.getItem('companyJson') || '{}');
            const manager: Manager = JSON.parse(localStorage.getItem('managerJson') || '{}');
            this.managerMessage!.company = company;
            this.managerMessage!.manager = manager;
            this.managerMessage!.itac_message = this.itac_message;
            this.managerMessage!.default = false;
            const managerMessageId = await this._apiService.addDocument('manager-message', this.managerMessage);
            if (managerMessageId) {
                this.managerMessage!.id = managerMessageId;
                this._utilsService.openSnackBar('Mensaje de gestor añadido correctamente');
            } else {
                this._utilsService.openSnackBar('Error añadiendo mensaje de gestor', 'error');
            }
        } catch (error) {
            this._utilsService.openSnackBar('Error añadiendo mensaje de gestor', 'error');
        }
    }
}

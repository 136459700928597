import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '../services/utils.service';

@Pipe({
    name: 'geolocation',
})
export class GeolocationPipe implements PipeTransform {
    constructor(private _utilsService: UtilsService) {}

    transform(value: any, ...args: unknown[]): unknown {
        let userName = this._utilsService.getGeolocationUrl(value);
        return userName;
    }
}

export interface MiRutaFilter {
    Or_field?: string[],
    And_field?: string[],
    fields?: FilterField[],
}

export interface FilterField {
    field_name: string,
    active?: boolean,
    field_type?: string,
    search_params?: SearchParam[],
}

export interface SearchParam {
    search_type?: string,
    nullity?: boolean,
    value?: any,
    custom_join?: CustomJoin,
    inside?: any[],
    between_join?: SearchParamBetweenJoin[],
    between?: SearchParamBetween[],
    active?: boolean,
}

export interface SearchParamBetween {
    upper_value: any,
    lower_value: any,
}

export interface SearchParamBetweenJoin {
    upper_value: any,
    lower_value: any,
    join_table: string,
    join_table_field: string,
    join_field: string
}
export interface CustomJoin {
    join_table: string,
    join_table_field: string,
    join_search_field: string,
    join_field: string
    complex: true
    complex_table: string
}

export interface FilterNode {
    name: string;
    children?: FilterNode[];
    active?: boolean;
}

/**
 * Creates a deep copy of a CustomJoin object.
 * 
 * @param original - The original CustomJoin object to be copied.
 * @returns A new CustomJoin object that is a deep copy of the original object.
 */
export function deepCopyCustomJoin(original: CustomJoin): CustomJoin {
    if (!original) return original;
    const copy: CustomJoin = {
        join_table: original.join_table,
        join_table_field: original.join_table_field,
        join_search_field: original.join_search_field,
        join_field: original.join_field,
        complex: original.complex,
        complex_table: original.complex_table,
    };
    return copy;
}

/**
 * Creates a deep copy of the given SearchParamBetweenJoin object.
 * 
 * @param original - The original SearchParamBetweenJoin object to be copied.
 * @returns A new SearchParamBetweenJoin object that is a deep copy of the original object.
 */
export function deepCopySearchParamBetweenJoin(original: SearchParamBetweenJoin): SearchParamBetweenJoin {
    if (!original) return original;
    const copy: SearchParamBetweenJoin = {
        upper_value: original.upper_value,
        lower_value: original.lower_value,
        join_table: original.join_table,
        join_table_field: original.join_table_field,
        join_field: original.join_field,
    };
    return copy;
}

/**
 * Creates a deep copy of a SearchParamBetween object.
 * 
 * @param original - The original SearchParamBetween object to be copied.
 * @returns A new SearchParamBetween object that is a deep copy of the original.
 */
export function deepCopySearchParamBetween(original: SearchParamBetween): SearchParamBetween {
    if (!original) return original;
    const copy: SearchParamBetween = {
        upper_value: original.upper_value,
        lower_value: original.lower_value,
    };
    return copy;
}

/**
 * Creates a deep copy of a SearchParam object.
 * 
 * @param original - The original SearchParam object to be copied.
 * @returns A new SearchParam object that is a deep copy of the original.
 */
export function deepCopySearchParam(original: SearchParam): SearchParam {
    if (!original) return original;
    const copy: SearchParam = {
        search_type: original.search_type,
        nullity: original.nullity,
        value: original.value,
        custom_join: original.custom_join ? deepCopyCustomJoin(original.custom_join) : undefined,
        inside: original.inside ? [...original.inside] : undefined,
        between: original.between ? original.between.map(b => deepCopySearchParamBetween(b)) : undefined,
        between_join: original.between_join ? original.between_join.map(b => deepCopySearchParamBetweenJoin(b)) : undefined,
        active: original.active,
    };
    return copy;
}

/**
 * Creates a deep copy of a FilterField object.
 * 
 * @param original - The original FilterField object to be copied.
 * @returns A new FilterField object that is a deep copy of the original.
 */
export function deepCopyFilterField(original: FilterField): FilterField {
    if (!original) return original;
    const copy: FilterField = {
        field_name: original.field_name,
        active: original.active,
        field_type: original.field_type,
        search_params: original.search_params ? original.search_params.map(s => deepCopySearchParam(s)) : undefined,
    };
    return copy;
}

/**
 * Creates a deep copy of a FilterNode object.
 * 
 * @param original - The original FilterNode object to be copied.
 * @returns A new FilterNode object that is a deep copy of the original object.
 */
export function deepCopyFilterNode(original: FilterNode): FilterNode {
    if (!original) return original;
    const copy: FilterNode = {
        name: original.name,
        children: original.children ? original.children.map(c => deepCopyFilterNode(c)) : undefined,
        active: original.active,
    };
    return copy;
}

/**
 * Creates a deep copy of the given MiRutaFilter object.
 * 
 * @param original - The original MiRutaFilter object to be copied.
 * @returns A new MiRutaFilter object that is a deep copy of the original.
 */
export function deepCopyMiRutaFilter(original: MiRutaFilter): MiRutaFilter {
    if (!original) return original;
    const copy: MiRutaFilter = {
        Or_field: original.Or_field ? [...original.Or_field] : undefined,
        And_field: original.And_field ? [...original.And_field] : undefined,
        fields: original.fields ? original.fields.map(f => deepCopyFilterField(f)) : undefined,
    };
    return copy;
}

/**
 * Deletes a filter field from the given MiRutaFilter object.
 * @param filter - The MiRutaFilter object to modify.
 * @param fieldName - The name of the field to delete.
 * @returns The modified MiRutaFilter object with the specified field removed.
 */
export function deleteFilterField(filter: MiRutaFilter, fieldName: string): MiRutaFilter {
    if (!filter || !filter.fields) return filter;
    const updatedFields = filter.fields.filter(field => field.field_name !== fieldName);
    const updatedAndFields = filter.And_field?.filter(field => field !== fieldName);
    const updatedOrFields = filter.Or_field?.filter(field => field !== fieldName);
    filter = { ...filter, fields: updatedFields, And_field: updatedAndFields, Or_field: updatedOrFields };
    return filter;
}

/**
 * Deletes a filter field from the given `MiRutaFilter` object without modifying the original object.
 * @param filter - The `MiRutaFilter` object to remove the field from.
 * @param fieldName - The name of the field to be deleted.
 * @returns A new `MiRutaFilter` object with the specified field removed, or the original `filter` object if it is null or does not have any fields.
 */
export function deleteFilterFieldNotModifying(filter: MiRutaFilter, fieldName: string): MiRutaFilter {
    if (!filter || !filter.fields) return filter;
    const updatedFields = filter.fields.filter(field => field.field_name !== fieldName);
    const updatedAndFields = filter.And_field?.filter(field => field !== fieldName);
    const updatedOrFields = filter.Or_field?.filter(field => field !== fieldName);
    return { ...filter, fields: updatedFields, And_field: updatedAndFields, Or_field: updatedOrFields };
}
import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '../services/utils.service';

@Pipe({
    name: 'counterActivatedStatus',
})
export class CounterActivatedStatusPipe implements PipeTransform {
    constructor(private _utilsService: UtilsService) {}

    transform(value: any, ...args: unknown[]): string {
        const counterActivatedStatusString =
            this._utilsService.getCounterActivatedStatusFromBoolean(value);

        return counterActivatedStatusString;
    }
}

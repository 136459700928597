/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface Message {
    text: string;
    data?: any;
}

@Injectable({
    providedIn: 'root',
})
export class MessagingService {
    constructor() {}

    private sendedMessageSource = new Subject<Message>();

    // Observable string streams
    sendedMessage$ = this.sendedMessageSource.asObservable();

    // Service message commands
    sendMessage(text: string, data?: any) {
        const message: Message = {
            text: text,
            data: data,
        };
        this.sendedMessageSource.next(message);
    }
}

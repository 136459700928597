/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { FormControl, FormGroup } from '@angular/forms';

export interface ClassCounter {
    clase: string;
    codigo_clase: string;
    date_time_modified: Date;
    id: number;
}

export function getClassCounterDisplayColumns(): string[] {
    const displayedColumns: string[] = ['Código clase', 'Clase', 'Fecha de Modificación'];
    return displayedColumns;
}

export function getClassCounterField(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'Código clase':
            value = 'codigo_clase';
            break;
        case 'Clase':
            value = 'clase';
            break;
        case 'Fecha de Modificación':
            value = 'date_time_modified';
            break;
    }
    return value;
}

export function getClassCounterFieldName(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'codigo_clase':
            value = 'Código clase';
            break;
        case 'clase':
            value = 'Clase';
            break;
        case 'date_time_modified':
            value = 'Fecha de Modificación';
            break;
    }
    return value;
}

export function getClassCounterFormControls(): FormGroup {
    const classCounterFormData: FormGroup = new FormGroup({
        clase: new FormControl(),
        codigo_clase: new FormControl(),
        date_time_modified: new FormControl(),
        id: new FormControl(),
    });
    return classCounterFormData;
}

export function getClassCounterFieldType(field_name: string): string {
    //in bigquery
    let value: string = '';
    switch (field_name) {
        case 'codigo_clase':
            value = 'string';
            break;
        case 'clase':
            value = 'string';
            break;
        case 'date_time_modified':
            value = 'Date';
            break;
        case 'id':
            value = 'number';
            break;
    }
    return value;
}

export function getClassCounterExcelFieldName(excel_field: string): string {
    let value: string = '';
    switch (excel_field.trim().toLowerCase()) {
        case 'clase':
            value = 'clase';
            break;
        case 'código clase':
            value = 'codigo_clase';
            break;
        case 'codigo clase':
            value = 'codigo_clase';
            break;
        case 'fecha de modificación':
            value = 'date_time_modified';
            break;
        case 'fecha de modificacion':
            value = 'date_time_modified';
            break;
        case 'codigo_clase':
            value = 'codigo_clase';
            break;
        case 'date_time_modified':
            value = 'date_time_modified';
            break;
    }
    return value;
}

export function getClassCounterExcelExportColumns(): string[] {
    const columns = ['Código clase', 'Clase', 'Fecha de Modificación'];
    return columns;
}

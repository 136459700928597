/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component } from '@angular/core';
import { UtilsService } from './services/utils.service';
import { PushNotificationService } from './services/push-notification.service';
import { MessagingService } from './services/messaging.service';
import { IpcService } from './services/ipc.service';
import { FcmMessage } from './interfaces/fcm-message';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    title = 'Mi Ruta';

    constructor(
        private notificationService: PushNotificationService, 
        private _messagingService: MessagingService, 
        private _utilsService: UtilsService,
        public _electronService: IpcService,
    ) {
        this.loadSavedFiltersAndOrders();
        if (this._electronService.isElectronApp()) return;
        this.notificationService.requestPermission();
    }

    ngOnInit(): void {
        if (this._electronService.isElectronApp()) return;

        this.notificationService.receiveMessage().subscribe((fcmMessage: FcmMessage) => {
            if(this._utilsService.isNotificationsEnableByUser()){
                this._utilsService.openSnackBar(
                    fcmMessage.notification.title, 'info', 6000,  '🔔​​', 
                    () => this._utilsService.openNotifications()
                );
            }
            this._messagingService.sendMessage('Update notification count');
        });
    }

    loadSavedFiltersAndOrders(): void {
        try {
            let orderTasks = JSON.parse(sessionStorage.getItem('orderTasks') || '');
            if (orderTasks) {
                for (const orderTask of orderTasks) {
                    this._utilsService.orderTasks.unshift(orderTask);
                }
            }
        } catch (err) {}
        try {
            let orderItacs = JSON.parse(sessionStorage.getItem('orderItacs') || '');
            if (orderItacs) {
                for (const orderItac of orderItacs) {
                    this._utilsService.orderItacs.unshift(orderItac);
                }
            }
        } catch (err) {}
        try {
            let orderCounters = JSON.parse(sessionStorage.getItem('orderCounters') || '');
            if (orderCounters) {
                for (const orderCounter of orderCounters) {
                    this._utilsService.orderCounters.unshift(orderCounter);
                }
            }
        } catch (err) {}
        try {
            let orderWaterRoutes = JSON.parse(sessionStorage.getItem('orderWaterRoutes') || '');
            if (orderWaterRoutes) {
                for (const orderWaterRoute of orderWaterRoutes) {
                    this._utilsService.orderWaterRoutes.unshift(orderWaterRoute);
                }
            }
        } catch (err) {}
        try {
            let orderSides = JSON.parse(sessionStorage.getItem('orderSides') || '');
            if (orderSides) {
                for (const orderSide of orderSides) {
                    this._utilsService.orderSides.unshift(orderSide);
                }
            }
        } catch (err) {}
        try {
            let filterTasks = JSON.parse(sessionStorage.getItem('filterTasks') || '');
            if (filterTasks) this._utilsService.filterTasks = filterTasks;
        } catch (err) {}
        try {
            let filterItacs = JSON.parse(sessionStorage.getItem('filterItacs') || '');
            if (filterItacs) this._utilsService.filterItacs = filterItacs;
        } catch (err) {}
        try {
            let filterCounters = JSON.parse(sessionStorage.getItem('filterCounters') || '');
            if (filterCounters) this._utilsService.filterCounters = filterCounters;
        } catch (err) {}
        try {
            let filterWaterRoutes = JSON.parse(sessionStorage.getItem('filterWaterRoutes') || '');
            if (filterWaterRoutes) this._utilsService.filterWaterRoutes = filterWaterRoutes;
        } catch (err) {}
        try {
            let filterSides = JSON.parse(sessionStorage.getItem('filterSides') || '');
            if (filterSides) this._utilsService.filterSides = filterSides;
        } catch (err) {}
    }
}

<ngx-spinner
    bdColor="bdColor"
    size="large"
    color="color"
    type="type"
    [fullScreen]="fullScreen"
    name="name"
>
    <p class="loading-text-background" style="margin-top: 50px">
        {{ loadingText }}
    </p>
</ngx-spinner>

/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faBuilding, faFilePdf, faKey, faInbox } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { ApiService } from '../../../services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessagingService } from 'src/app/services/messaging.service';

import * as moment from 'moment';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClassCounter, getClassCounterFormControls } from 'src/app/interfaces/class-counter';

@Component({
    selector: 'app-class-counter',
    templateUrl: './class-counter.component.html',
    styleUrls: ['./class-counter.component.scss'],
})
export class ClassCounterComponent implements OnInit {
    faFilePdf = faFilePdf;
    faInbox = faInbox;
    faBuilding = faBuilding;
    faKey = faKey;
    loading: boolean = false;
    classCounterId: string = '';
    classCounter?: ClassCounter;

    classCounterFormData: FormGroup = getClassCounterFormControls();

    classCounterSubcription$?: Subscription;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _apiService: ApiService,
        private _utilsService: UtilsService,
        private spinner: NgxSpinnerService,
        private _messagingService: MessagingService
    ) {
        this.classCounterId = data.classCounterId;
        // this.activatedRoute.params.subscribe((params)=>{
        //   this.classCounterId = params['id'];
        // });
    }

    async ngOnInit(): Promise<void> {
        if (this.classCounterId) {
            this.showLoading(true);

            this.classCounterSubcription$ = this._apiService
                .getDocument('class-counter', this.classCounterId)
                .subscribe(async (doc: any) => {
                    if (!doc) {
                        this._utilsService.openSnackBar(
                            'Error obteniendo datos de classCounter',
                            'error'
                        );
                        this.showLoading(false);
                        return;
                    }
                    const classCounter = doc as ClassCounter;
                    classCounter.id = parseInt(this.classCounterId);

                    this.classCounter = classCounter;

                    console.log(this.classCounter);

                    const classCounterFormData = getClassCounterFormControls();
                    const keys = Object.keys(classCounter);
                    for (let key of keys) {
                        let value: any = classCounter[key as keyof ClassCounter];
                        if (value) {
                            if (this._utilsService.isValidDate(value)) {
                                value = value;
                            }
                            classCounterFormData.controls[key].setValue(value);
                        }
                    }
                    this.classCounterFormData = classCounterFormData;

                    this.showLoading(false);
                });
        }
    }

    ngOnDestroy(): void {
        this.classCounterSubcription$?.unsubscribe();
    }

    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this.spinner.show('classCounterSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this.spinner.hide('classCounterSpinner');
        }
    }

    saveFormData() {
        const keys = Object.keys(this.classCounterFormData.controls);
        let classCounter: any = {};
        for (let key of keys) {
            let value = this.classCounterFormData.controls[key].value; //this.classCounter[key as keyof ClassCounter];
            try {
                if (moment.isMoment(value)) {
                    value = value.toDate();
                }
                classCounter![key as keyof ClassCounter] = value;
            } catch (error) {}
        }
        classCounter!['date_time_modified'] = new Date();
        this.classCounter = classCounter;
        // console.log(this.classCounter);
    }

    async saveChanges() {
        this.showLoading(true);
        this.saveFormData();
        if (this.classCounterId) {
            const result: boolean = await this._apiService.updateDocument(
                'class-counter',
                this.classCounterId,
                this.classCounter
            );
            if (result) {
                this._utilsService.openSnackBar('Causa actualizada correctamente');
            } else {
                this._utilsService.openSnackBar('Error actualizando causa', 'error');
            }
        } else {
            try {
                const classCounterId = await this._apiService.addDocument(
                    'class-counter',
                    this.classCounter
                );
                if (classCounterId) {
                    this.classCounter!.id = classCounterId;
                    this._utilsService.openSnackBar('Causa añadido correctamente');
                } else {
                    this._utilsService.openSnackBar('Error añadiendo causa', 'error');
                }
            } catch (error) {
                this._utilsService.openSnackBar('Error añadiendo causa', 'error');
            }
        }
        this.showLoading(false);
        this._utilsService.closeClassCounterDialog(this.classCounter!);
    }
}

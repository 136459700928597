import { FormControl, FormGroup } from '@angular/forms';

export interface Longitude {
    longitud: string;
    codigo_longitud: string;
    date_time_modified: Date;
    id: number;
}

export function getLongitudeDisplayColumns(): string[] {
    const displayedColumns: string[] = ['Código longitud', 'Longitud', 'Fecha de Modificación'];
    return displayedColumns;
}

export function getLongitudeField(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'Código longitud':
            value = 'codigo_longitud';
            break;
        case 'Longitud':
            value = 'longitud';
            break;
        case 'Fecha de Modificación':
            value = 'date_time_modified';
            break;
    }
    return value;
}

export function getLongitudeFieldName(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'codigo_longitud':
            value = 'Código longitud';
            break;
        case 'longitud':
            value = 'Longitud';
            break;
        case 'date_time_modified':
            value = 'Fecha de Modificación';
            break;
    }
    return value;
}

export function getLongitudeFormControls(): FormGroup {
    const longitudeFormData: FormGroup = new FormGroup({
        longitud: new FormControl(),
        codigo_longitud: new FormControl(),
        date_time_modified: new FormControl(),
        id: new FormControl(),
    });
    return longitudeFormData;
}

export function getLongitudeFieldType(field_name: string): string {
    //in bigquery
    let value: string = '';
    switch (field_name) {
        case 'codigo_longitud':
            value = 'string';
            break;
        case 'longitud':
            value = 'string';
            break;
        case 'date_time_modified':
            value = 'Date';
            break;
        case 'id':
            value = 'number';
            break;
    }
    return value;
}

export function getLongitudeExcelFieldName(excel_field: string): string {
    let value: string = '';
    switch (excel_field.trim().toLowerCase()) {
        case 'longitud':
            value = 'longitud';
            break;
        case 'código longitud':
            value = 'codigo_longitud';
            break;
        case 'codigo longitud':
            value = 'codigo_longitud';
            break;
        case 'fecha de modificación':
            value = 'date_time_modified';
            break;
        case 'fecha de modificacion':
            value = 'date_time_modified';
            break;
        case 'codigo_longitud':
            value = 'codigo_longitud';
            break;
        case 'date_time_modified':
            value = 'date_time_modified';
            break;
    }
    return value;
}

export function getLongitudeExcelExportColumns(): string[] {
    const columns = ['Código longitud', 'Longitud', 'Fecha de Modificación'];
    return columns;
}

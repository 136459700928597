/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, OnInit } from '@angular/core';
import {
    getAgrupationField,
    Agrupation,
    getAgrupationDisplayColumns,
} from '../../../interfaces/agrupation';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from '../../../services/api.service';
import { UtilsService } from '../../../services/utils.service';
import { WindowRefService } from 'src/app/services/window-ref.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessagingService } from 'src/app/services/messaging.service';
import { Company } from 'src/app/interfaces/company';
import {
    getAgrupationFieldType,
    getAgrupationExcelExportColumns,
    getAgrupationExcelFieldName,
} from '../../../interfaces/agrupation';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import { Moment } from 'moment';

@Component({
    selector: 'app-agrupations',
    templateUrl: './agrupations.component.html',
    styleUrls: ['./agrupations.component.scss'],
})
export class AgrupationsComponent implements OnInit {
    loading: boolean = true;
    agrupationsInPage: Agrupation[] = [];
    dataSource: MatTableDataSource<Agrupation> = new MatTableDataSource();

    length = 0; //task count in current table
    pageSize = 200; //limit of query
    lastPageIndex = 0;
    pageSizeOptions: number[] = [10, 50, 100, 200, 500];

    displayedColumns: string[] = getAgrupationDisplayColumns();
    fixedColumns = [];
    displayedColumnsField: string[] = this.displayedColumns.map((displayedColumn: string) =>
        getAgrupationField(displayedColumn)
    );
    clickedRows = new Set<Agrupation>();

    lastSelectedRow: number = -1;

    filteredColumn?: string;
    orderedColumn?: string;

    loadingText = 'Cargando...';

    constructor(
        private _apiService: ApiService,
        private _utilsService: UtilsService,
        private _windowRefService: WindowRefService,
        private _spinner: NgxSpinnerService,
        private _messagingService: MessagingService
    ) {}

    async ngOnInit(): Promise<void> {
        await this.getAgrupations();
    }

    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this._spinner.show('innerSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this._spinner.hide('innerSpinner');
        }
    }

    setAgrupationsInTable(agrupations: Agrupation[]) {
        this.agrupationsInPage = agrupations;
        // this.dataSource.data = agrupations.slice(0, this.sliceSize);
        this.dataSource.data = agrupations;
        // this.dataSource = new TableVirtualScrollDataSource(agrupations);// infiniteScroll
        // console.log(this.dataSource);
        this.showLoading(false);
    }

    async getAgrupations() {
        // console.log('****************** getAgrupations *************');
        this.setAgrupationsInTable(await this.selectAgrupations());
    }
    async selectAgrupations() {
        this.showLoading(true);
        console.log('****************** selectAgrupations *************');
        const company = localStorage.getItem('company');
        console.log(company);

        let docs = [];
        try {
            docs = await this._apiService.getDocuments<Agrupation>(`agrupation`, undefined, [
                'codigo_gestor',
                'asc',
            ]);
        } catch (err) {
            console.log('============= err =============');
            console.log(err);
        }
        this.length = docs.length;
        return docs;
    }

    searchValue(event: any) {
        const filterValue: string = event;
        this.dataSource!.filter = filterValue.trim().toLowerCase();
    }

    async deleteAgrupations() {
        let errorIds = [];
        if (this.clickedRows.size == 0) {
            this._utilsService.openSnackBar('Debe seleccionar al menos una gestor', 'warning');
            return;
        }
        const result = await this._utilsService.openQuestionDialog(
            'Confirmación',
            '¿Desea eliminar las agrupaciones seleccionadas?'
        );
        if (result) {
            this._spinner.show();
            this.loadingText = `Eliminando agrupaciones ...`;
            let rowIndexes = new Set<number>();
            const oldDataSource = this.dataSource!.data;
            this.clickedRows.forEach(async (row) => {
                console.log(row.id);

                const index = this.dataSource!.data.indexOf(row, 0);
                if (index > -1) {
                    rowIndexes.add(index);
                }
            });
            let agrupations = [];
            let deleteCount = 0;

            const companyId = localStorage.getItem('company');
            const company = await this._apiService.getDocumentSync<Company>('company', companyId!);

            for (let i = 0; i < oldDataSource.length; i++) {
                if (!rowIndexes.has(i)) {
                    agrupations.push(oldDataSource![i]);
                } else {
                    this.loadingText = `Eliminando agrupaciones ${++deleteCount} de ${
                        rowIndexes.size
                    }`;
                    if (company) {
                        const agrupations = [];
                        for (const agrupation of company.agrupations!) {
                            if (agrupation.id != oldDataSource[i].id!) agrupations.push(agrupation);
                        }
                        company.agrupations = agrupations;

                        console.log('============= company =============');
                        console.log(company);
                    }
                    await this._apiService.updateDocument('company', companyId!, company);
                    if (
                        !(await this._apiService.deleteDocument(
                            `agrupation`,
                            oldDataSource[i].id!.toString()
                        ))
                    ) {
                        console.log('****************** deleteAgrupation error *************');
                        errorIds.push(oldDataSource[i].id);
                    } else {
                        console.log('****************** deleteAgrupation success *************');
                    }
                }
            }
            if (errorIds.length > 0) {
                this._utilsService.openSnackBar(`Hubo errores eliminando agrupaciones`, 'error');
            } else {
                this._utilsService.openSnackBar(`Agrupaciones eliminadas correctamente`);
            }
            this.length -= rowIndexes.size;
            this.setAgrupationsInTable(agrupations);
            this.clickedRows.clear();
            this._spinner.hide();
        }
    }

    async filterBy(column: string) {
        try {
            this.filteredColumn = getAgrupationField(column);
            if (getAgrupationFieldType(this.filteredColumn) == 'Date') {
                const dates = await this._utilsService.openDateRangeSelectorDialog(
                    'Seleccione rango de fechas'
                );
                this.onDateSelected(dates);
            } else {
                const options: any[] = this.dataSource.data.map(
                    (agrupation: Agrupation) => agrupation[this.filteredColumn as keyof Agrupation]
                );
                const result = await this._utilsService.openSelectorDialog(
                    'Seleccione opción',
                    options
                );
                if (result) {
                    let docs = [];
                    try {
                        docs = await this._apiService.getDocuments<Agrupation>(`agrupation`, [
                            [this.filteredColumn, '==', result],
                        ]);
                    } catch (err) {
                        console.log('============= err =============');
                        console.log(err);
                    }
                    this.length = docs.length;
                    this.setAgrupationsInTable(docs);
                }
            }
        } catch (err) {}
    }
    async onDateSelected(dateRange: Date[]) {
        if (dateRange) {
            const startDate: Moment = moment(dateRange[0]);
            let endDate: Moment = moment(dateRange[1]);
            if (!endDate) {
                endDate = startDate;
            }
            let docs = [];
            try {
                docs = await this._apiService.getDocuments<Agrupation>(`agrupation`, [
                    [this.filteredColumn!, '>=', startDate.toDate()],
                    [this.filteredColumn!, '<', endDate.toDate()],
                ]);
            } catch (err) {
                console.log('============= err =============');
                console.log(err);
            }
            this.length = docs.length;
            this.setAgrupationsInTable(docs);
        } else {
            this._utilsService.openSnackBar('Rango fechas invalido', 'error');
        }
    }

    async orderBy(event: any) {
        const column = event.column;
        let orderType = event.orderType;
        if (orderType.toLowerCase().includes('asc')) {
            orderType = 'asc';
        } else {
            orderType = 'desc';
        }
        const orderedColumn = getAgrupationField(column);

        this.showLoading(true);
        const company = localStorage.getItem('company');
        const agrupations = await this._apiService.getDocuments<Agrupation>(
            `agrupation`,
            undefined,
            [orderedColumn, orderType]
        );
        this.setAgrupationsInTable(agrupations);
    }

    async addNewRow(event: any) {
        console.log('**************** addNewRow ***************');
        const agrupation = await this._utilsService.openAgrupationDialog('');
        console.log(agrupation);
        if (agrupation) {
            this.dataSource.data.push(agrupation);
            const agrupations = this.dataSource.data;
            this.dataSource.data = [];
            this.dataSource.data = agrupations;
        }
    }
    async doubleClickedRow(row: any) {
        console.log('**************** doubleClickedRow ***************');
        console.log(row.id);
        const agrupation = await this._utilsService.openAgrupationDialog(row.id);
        if (agrupation) {
            this.dataSource.data[row.rowIndex] = agrupation;
            const agrupations = this.dataSource.data;
            this.dataSource.data = [];
            this.dataSource.data = agrupations;
        }
    }

    clickedRow(receivedEvent: any) {
        const row = receivedEvent.row;
        const event = receivedEvent.event;
        const rowIndex = receivedEvent.rowIndex;

        const previousRow = this.lastSelectedRow;
        this.lastSelectedRow = rowIndex;

        if (event.button === 0) {
            if (!event.ctrlKey && !event.shiftKey) {
                this.clickedRows.clear();
                this.toggleRow(row);
                // console.log('**************** !event.ctrlKey && !event.shiftKey ***************');
            } else if (event.ctrlKey) {
                this.toggleRow(row);
                // console.log('**************** event.ctrlKey ***************');
            }
            if (event.shiftKey) {
                this.selectRowsBetweenIndexes(previousRow, rowIndex);
                console.log('**************** event.shiftKey ***************');
            }
        }
        if (this._windowRefService.nativeWindow.getSelection) {
            //remove selection in table with shift
            if (this._windowRefService.nativeWindow.getSelection().empty) {
                // Chrome
                this._windowRefService.nativeWindow.getSelection().empty();
            } else if (this._windowRefService.nativeWindow.getSelection().removeAllRanges) {
                // Firefox
                this._windowRefService.nativeWindow.getSelection().removeAllRanges();
            }
        }
    }
    selectRowsBetweenIndexes(lastSelectedRow: number, rowIndex: number) {
        let start, end;
        if (rowIndex > lastSelectedRow) {
            start = lastSelectedRow;
            end = rowIndex;
        } else {
            end = lastSelectedRow;
            start = rowIndex;
        }
        for (let i = start; i <= end; i++) {
            this.clickedRows.add(this.dataSource!.data[i]);
        }
    }
    toggleRow(row: any) {
        if (this.clickedRows.has(row)) {
            this.clickedRows.delete(row);
        } else {
            this.clickedRows.add(row);
        }
    }

    exportAgrupationsInTable() {
        this.exportExcel(this.dataSource!.data);
    }
    exportExcel(waterTasks: any) {
        let excelFormatTasks = [];
        for (let waterTask of waterTasks) {
            let task: any = {};
            const columns = getAgrupationExcelExportColumns();
            columns.forEach((column) => {
                task[`${column}`] = this._utilsService.tableDataPipe(
                    waterTask[getAgrupationExcelFieldName(column)]
                );
            });
            excelFormatTasks.push(task);
        }
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelFormatTasks);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        /* save to file */
        XLSX.writeFile(wb, 'Agrupaciones_Exportados.xlsx');
    }
}

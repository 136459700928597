/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MiRutaUser } from 'src/app/interfaces/mi-ruta-user';
import { GoogleMap, MapInfoWindow } from '@angular/google-maps';
import { UtilsService } from 'src/app/services/utils.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FilterComponent } from '../share/filter/filter.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

import { Subscription } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { Footprint } from 'src/app/interfaces/footprint';
import { WaterTask } from 'src/app/interfaces/water-task';
import { Prediction } from '../../interfaces/place-predictions';
import { PlaceDetails } from '../../interfaces/place-details';
import { Location } from '@angular/common';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { UserAction } from 'src/app/interfaces/user-action';
import { IpcService } from 'src/app/services/ipc.service';
import { Itac } from '../../interfaces/itac';

/**
 * Componente para la ubicación del usuario.
 * Este componente muestra un mapa de Google con la ubicación del usuario y permite realizar búsquedas de lugares.
 * También muestra marcadores de usuarios y permite mostrar la trayectoria de un usuario seleccionado.
 */
@Component({
    selector: 'app-user-location',
    templateUrl: './user-location.component.html',
    styleUrls: ['./user-location.component.scss'],
})
export class UserLocationComponent implements OnInit, OnDestroy {
    @ViewChild(GoogleMap, { static: false }) map!: GoogleMap;
    @ViewChild(MapInfoWindow, { static: false }) infoWindow!: MapInfoWindow;

    inputSearchControl = new FormControl();
    placePredictions: Prediction[] = [];
    timerInputChanged: any;

    filterDialogRef!: MatDialogRef<FilterComponent, any>;

    markers: google.maps.Marker[] = [];
    markerOptionsMap: Map<string, google.maps.MarkerOptions> = new Map<
        string,
        google.maps.MarkerOptions
    >();
    loading: boolean = false;
    loadingMarkerInfo: boolean = false;
    zoom = 15;
    center!: google.maps.LatLngLiteral; // = new google.maps.LatLng(43.2633182, -2.9349041608216098);
    options = {
        origin: new google.maps.LatLng(43.2633182, -2.9349041608216098),
        types: [],
        componentRestrictions: { country: 'ES' },
        fields: ['place_id', 'geometry', 'name'],
        bounds: new google.maps.LatLngBounds(
            new google.maps.LatLng(19.43488390019791, -164.271592),
            new google.maps.LatLng(51.943852774877634, -79.896592)
        ),
        strictBounds: false,
    };
    mapOptions: google.maps.MapOptions = {
        mapTypeId: 'hybrid',
        disableDoubleClickZoom: true,
    };

    markerTasks: MiRutaUser[] = [];

    user?: MiRutaUser;
    userId?: string = '';
    pageOption?: string = '';

    userSubscription$?: Subscription;

    autocomplete?: any;
    formData: FormGroup = new FormGroup({
        address: new FormControl(),
    });

    userMarkerIcon: google.maps.Icon = {
        url: 'assets/img/markers/user_marker.png',
        scaledSize: new google.maps.Size(30, 30),
        // size: new google.maps.Size(30, 30),
    };
    userMarkerOption: google.maps.MarkerOptions = {
        icon: this.userMarkerIcon,
        zIndex: 5,
        // animation: google.maps.Animation.DROP,
    };
    userMarkerOptionBouncing: google.maps.MarkerOptions = {
        icon: this.userMarkerIcon,
        animation: google.maps.Animation.BOUNCE,
    };

    footprintMarkerIcon: google.maps.Icon = {
        url: 'assets/img/markers/footprint.png',
        scaledSize: new google.maps.Size(15, 15),
        // size: new google.maps.Size(30, 30),
    };

    pointMarkerIcon: google.maps.Icon = {
        url: 'assets/img/markers/point.png',
        scaledSize: new google.maps.Size(12, 12),
    };
    footprintMarkerOption: google.maps.MarkerOptions = {
        icon: this.footprintMarkerIcon,
        zIndex: 3,
        // animation: google.maps.Animation.DROP,
    };
    userActionMarkerOption: google.maps.MarkerOptions = {
        icon: this.pointMarkerIcon,
        zIndex: 7,
    };

    footprintMarkerOptionBouncing: google.maps.MarkerOptions = {
        icon: this.footprintMarkerIcon,
        animation: google.maps.Animation.BOUNCE,
    };
    startMarkerIcon: google.maps.Icon = {
        url: 'assets/img/markers/start.png',
        scaledSize: new google.maps.Size(20, 20),
        // size: new google.maps.Size(30, 30),
    };
    startMarkerOption: google.maps.MarkerOptions = {
        icon: this.startMarkerIcon,
        zIndex: 6,
        // animation: google.maps.Animation.DROP,
    };

    startMarkerOptionBouncing: google.maps.MarkerOptions = {
        icon: this.startMarkerIcon,
        animation: google.maps.Animation.BOUNCE,
    };
    endMarkerIcon: google.maps.Icon = {
        url: 'assets/img/markers/end.png',
        scaledSize: new google.maps.Size(25, 25),
        // size: new google.maps.Size(30, 30),
    };
    endMarkerOption: google.maps.MarkerOptions = {
        icon: this.endMarkerIcon,
        zIndex: 6,
        // animation: google.maps.Animation.DROP,
    };
    endMarkerOptionBouncing: google.maps.MarkerOptions = {
        icon: this.endMarkerIcon,
        animation: google.maps.Animation.BOUNCE,
    };
    usersMarkers: Set<google.maps.Marker> = new Set<google.maps.Marker>();
    endPositionMarkers: Set<google.maps.Marker> = new Set<google.maps.Marker>();
    startPositionMarkers: Set<google.maps.Marker> = new Set<google.maps.Marker>();
    footprintMarkers: Set<google.maps.Marker> = new Set<google.maps.Marker>();

    userActionMarkers: Set<google.maps.Marker> = new Set<google.maps.Marker>();
    endUserActionPositionMarkers: Set<google.maps.Marker> = new Set<google.maps.Marker>();
    startUserActionPositionMarkers: Set<google.maps.Marker> = new Set<google.maps.Marker>();
    userActionPolylineOptions: any;
    
    lineSymbol = {
        path: 'M 0,-1 0,1',
        strokeOpacity: 1,
        scale: 4,
    };

    polylineOptions: any;
    footprintSubscription?: Subscription;


    actionText: string | null = null;
    selectedTask: WaterTask | null = null;
    selectedItac: Itac | null = null;
    selectedImage: string | null = null;
    selectedImageIndex: number = 0;
    selectedActionIndex: number = 0;
    selectedImagePosition: { x: number, y: number } | null = null;
    isAbove: boolean = true; // To determine the card position
    isLeft: boolean = true; // To determine the horizontal card position
    isListAbove: boolean = true; // To determine the card position
    isListLeft: boolean = true; // To determine the horizontal card position
    showFloatingList: boolean = false;
    clickPosition: { x: number, y: number } = { x: 0, y: 0 };

    selectedActions: UserAction[] = [];
    cardListTasks: WaterTask[] = [];
    cardListItacs: Itac[] = [];

    selectedUserActionDate: Date | null = null;
    /**
     * Represents the UserLocationComponent class.
     * This component is responsible for managing the user's location.
     */
    constructor(
        private _apiService: ApiService,
        private _utilsService: UtilsService,
        public filterDialog: MatDialog,
        private spinner: NgxSpinnerService,
        private activatedRoute: ActivatedRoute,
        public _electronService: IpcService,
        private router: Router,
        public location: Location
    ) {
        this.activatedRoute.params.subscribe((params) => {
            this.userId = params['id'];
            this.pageOption = params['option'];
        });
    }

    /**
     * @brief Toggles 3D mode on the map by adjusting the tilt option.
     * @details This function sets the tilt option of the map to 45 when active is true (enabling 3D mode),
     * and sets it to 0 when active is false (disabling 3D mode).
     * @param active - A boolean indicating whether 3D mode should be active.
     */
    onMap3D(event: MatSlideToggleChange) {
        this.mapOptions = { ...this.mapOptions, tilt: event.checked ? 45: 0 };
    }

    async searchPlace(prediction: Prediction) {
        const place: PlaceDetails = await this._apiService.searchPlace(prediction.place_id);
        if (place) {
            const lat = place.result.geometry.location.lat; 
            const lng = place.result.geometry.location.lng; 
            this.center = { lat: lat, lng: lng };
        }
    }

    /**
     * Initializes the component.
     * This method is called after the component has been created and initialized.
     * It is used to perform any necessary setup or initialization tasks.
     * @returns A Promise that resolves when the initialization is complete.
     */
    async ngOnInit(): Promise<void> {
        this.showLoading(true);
        await this._apiService.getUsers(['administrador', 'operario']);
        this.inputSearchControl.valueChanges.subscribe(async (value: any) => {
            clearTimeout(this.timerInputChanged);
            this.timerInputChanged = setTimeout(async () => {
                this.placePredictions = await this._apiService.searchPrediction(value);
            }, 1000);
        });
        this.userSubscription$ = this._apiService.getUserAsync(this.userId!).subscribe(async (doc: any) => {
            this.setUser(doc);
            if (this.pageOption == 'actions') {
                await this.loadMapInfo();
                if(this.selectedUserActionDate == null) this.showUserActions();
            }
            this.showLoading(false);
        });
    }

    /**
     * Lifecycle hook that is called when the component is destroyed.
     * It is used to perform any necessary cleanup logic, such as unsubscribing from observables.
     */
    ngOnDestroy(): void {
        this.userSubscription$!.unsubscribe();
        this.footprintSubscription?.unsubscribe();
    }

    /**
     * Sets the user data and updates the map markers.
     * 
     * @param doc - The user document.
     */
    setUser(doc: any) {
        if (!doc) return;
        const user = doc as MiRutaUser;
        this.user = user;
        const lat = this.user?.geolocalizacion?.lat;
        const lng = this.user?.geolocalizacion?.lng;
        if (!this.center) {
            this.center = { lat: lat || 43.2633182,  lng: lng || -2.9349041608216098 };
        }
        this.setMarkersUsers([user]);
    }

    /**
     * Adds a marker for the specified user to the map.
     * @param user - The user for which to add the marker.
     */
    addUserMarker(user: MiRutaUser) {
        let lat = user.geolocalizacion!.lat;
        let lng = user.geolocalizacion!.lng;

        let pos = new google.maps.LatLng(lat, lng);

        let userName = this._utilsService.userPipe(user.id);
        if (!userName) userName = user.username;
        
        const marker = new google.maps.Marker({
            position: pos,
            label: user.id.toString(),
            title: userName, //this is what get displayed on map
        });
        this.usersMarkers.add(marker);
    }

    /**
     * Sets the markers for the given users on the map.
     * @param users - An array of MiRutaUser objects representing the users.
     */
    setMarkersUsers(users: MiRutaUser[]) {
        this.usersMarkers.clear();
        users.forEach((user) => {
            if (user.geolocalizacion) this.addUserMarker(user);
        });
    }

    /**
     * Returns the action text for a given user action.
     * @param userAction - The user action object.
     * @param task - Optional. The water task object.
     * @param itac - Optional. The ITAC object.
     * @returns The action text.
     */
    getActionText(userAction: UserAction, task?: WaterTask, itac?: Itac): string {
        const date = moment(userAction.date_time_modified!).format('HH:mm DD/MM/YYYY');
        let text = `Tipo: ${this.convertActionType(userAction.action_type)}`
                + `\nDetalle: ${userAction.details}`
                + `\nPantalla: ${userAction.action_screen}`
                + `\nFecha: ${date}`;
        if (task) text += `\nAbonado: ${task.Numero_de_ABONADO}`;
        if (task) text += `\nDirección: ${this.getDirOfTask(task)}`;
        if (itac) text += `\nCódigo itac: ${itac.codigo_itac}`;
        if (itac) text += `\nDirección: ${itac.itac}`;
        return text;
    }

    /**
     * Converts an action type to its corresponding description.
     * @param actionType - The action type to convert.
     * @returns The description of the action type.
     */
    convertActionType(actionType: string): string { 
        if (actionType === 'press_button') return 'Botón pulsado';
        if (actionType === 'call_phone') return 'Llamada';
        if (actionType === 'send_whatsapp_message') return 'Envío mensaje';
        if (actionType === 'update_itac') return 'Itac actualizado';
        if (actionType === 'create_itac') return 'Itac creado';
        if (actionType === 'update_task') return 'Tarea actualizada';
        if (actionType === 'close_task') return 'Tarea cerrada';
        if (actionType === 'create_task') return 'Tarea creada';
        if (actionType === 'update_sector') return 'Sector actualizado';
        if (actionType === 'create_sector') return 'Sector creado';
        if (actionType === 'update_location') return 'Localización actualizada';
        return actionType;
    }

    /**
     * Handles the click event on a user action marker.
     * 
     * @param event - The click event object.
     * @param marker - The Google Maps marker object.
     * @returns A Promise that resolves when the click event is handled.
     */
    async onClickedUserActionMarker(event: google.maps.MapMouseEvent, marker: google.maps.Marker) { 
        this.clearCardData();
        if (marker) {
            const userActions: UserAction[] = JSON.parse(marker.getLabel() as any);
            if (userActions && userActions.length) {
                this.actionText = this.getActionText(userActions[0]);
                if(userActions[0].entity_type === 'task') return this.setTasksCardList(event, userActions)// this.setTaskCardData(userActions[0]);
                if(userActions[0].entity_type === 'itac') return this.setItacsCardList(event, userActions)// this.setItacCardData(userActions[0]);
                this.setCardPosition(event);
            }
        }
    }

    /**
     * Sets the task card data and card position based on the selected user action task.
     * 
     * @param event - The event object triggered by the user action.
     * @param index - The index of the selected user action task.
     */
    selectedUserActionTask(event: any, i: number) {
        const task = this.cardListTasks[i];
        const action = this.selectedActions.find((action: UserAction) => action.entity_id === task.id);
        if (action) {
            this.setTaskCardData(action);
            this.setCardPositionWithPointer(event);
        }
    }

    /**
     * Sets the Itac card data and position based on the selected user action.
     * 
     * @param event - The event object triggered by the user action.
     * @param index - The index of the selected user action.
     */
    selectedUserActionItac(event: any, index: number) { 
        this.setItacCardData(this.selectedActions[index]);
        this.setCardPositionWithPointer(event);
    }

    /**
     * Returns the tooltip for a given WaterTask.
     * @param task - The WaterTask for which to generate the tooltip.
     * @returns The tooltip string.
     */
    getCardTaskTooltip(task: WaterTask): string {
        const taskActions = this.selectedActions.filter(action => action.entity_type == 'task' && action.entity_id === task.id);
        let actionsStr = taskActions.map(action => this.getUserActionTooltip(action)).join('\n');
        return actionsStr;
    }

    /**
     * Returns the tooltip string for a given Itac.
     * The tooltip includes the user actions associated with the Itac.
     *
     * @param itac - The Itac object for which to generate the tooltip.
     * @returns The tooltip string containing the user actions for the Itac.
     */
    getCardItacTooltip(itac: Itac): string {
        const itacActions = this.selectedActions.filter(action => action.entity_type == 'itac' && action.entity_id === itac.id);
        let actionsStr = itacActions.map(action => this.getUserActionTooltip(action)).join('\n');
        return actionsStr;
    }
    
    /**
     * Sets the tasks card list based on the provided user actions.
     * @param event - The event object.
     * @param userActions - The array of user actions.
     */
    async setTasksCardList(event: any, userActions: UserAction[]) {
        this.selectedActions = userActions;
        const taskActions = userActions.filter(action => action.entity_type == 'task');
        const taskIds = taskActions.map(action => action.entity_id).filter(id => this._utilsService.isFieldValid(id));
        let ids: number[] = [];
        for (const taskId of taskIds) if(!ids.includes(taskId)) ids.push(taskId);

        this.cardListTasks = await this._apiService.getTasksSync(ids);

        this.showFloatingList = true; // Show the floating list of cards
        this.setListCardPosition(event); // Set the position of the list card
    }

    /**
     * Sets the list of ITACs cards based on the provided user actions.
     * 
     * @param userActions - The user actions to filter and retrieve ITACs.
     * @returns A promise that resolves to the list of ITACs cards.
     */
    async setItacsCardList(event: any, userActions: UserAction[]) {
        this.selectedActions = userActions;
        const itacActions = userActions.filter(action => action.entity_type == 'itac');
        const itacIds = itacActions.map(action => action.entity_id).filter(id => this._utilsService.isFieldValid(id));
        let ids: number[] = [];
        for (const itacId of itacIds) if (!ids.includes(itacId)) ids.push(itacId);
        
        this.cardListItacs = await this._apiService.getItacsSync(itacIds);

        this.showFloatingList = true; // Show the floating list of cards
        this.setListCardPosition(event); // Set the position of the list card
    }

    /**
     * Sets the task card data based on the user action.
     * @param userAction - The user action object.
     */
    async setTaskCardData(userAction: UserAction) {
        if (userAction.entity_id) {
            const task = await this._apiService.getTaskSync(userAction.entity_id);
            this.setCardData(userAction, task);
        }
        else if (userAction.entity_data) {
            const task = await this._apiService.getTaskSyncWithNUMIN(userAction.entity_data);
            this.setCardData(userAction, task);
        }
    }

    /**
     * Sets the Itac card data based on the user action.
     * @param userAction - The user action object.
     */
    async setItacCardData(userAction: UserAction) {
        if (userAction.entity_id) {
            const itac = await this._apiService.getItacSync(userAction.entity_id);
            this.setCardData(userAction, undefined, itac);
        }
        else if (userAction.entity_data) {
            const itac = await this._apiService.getItacWithCode(userAction.entity_data);
            this.setCardData(userAction, undefined, itac);
        }
    }

    /**
     * Sets the card data based on the user action, task, and ITAC.
     * @param userAction - The user action.
     * @param task - The water task.
     * @param itac - The ITAC.
     */
    setCardData(userAction: UserAction, task?: WaterTask, itac?: Itac) {
        if (task) { 
            this.selectedTask = task;
            this.actionText = this.getActionText(userAction, task);
            this.selectedImage = this._utilsService.getTaskImage(task);
        }
        if (itac) { 
            this.selectedItac = itac;
            this.actionText = this.getActionText(userAction, undefined, itac);
            this.selectedImage = this._utilsService.getItacImage(itac);
        }
    }

    /**
     * Performs the action based on the given direction.
     * @param direction - The direction of the action ('left' or 'right').
     */
    switchAction(direction: string) { 
        if (direction === 'left') this.selectedActionIndex--;
        else if (direction === 'right') this.selectedActionIndex++;

        let actions: UserAction[] = [];
        if (this.selectedTask) actions = this.selectedActions.filter(action => action.entity_id === this.selectedTask!.id); 
        if (this.selectedItac) actions = this.selectedActions.filter(action => action.entity_id === this.selectedItac!.id); 

        if (actions && actions.length) { 
            if (this.selectedActionIndex < 0) this.selectedActionIndex = actions.length - 1;
            else if (this.selectedActionIndex >= actions.length) this.selectedActionIndex = 0;
            
            if (this.selectedTask) this.actionText = this.getActionText(actions[this.selectedActionIndex], this.selectedTask);
            if (this.selectedItac) this.actionText = this.getActionText(actions[this.selectedActionIndex], undefined, this.selectedItac);
        }
    }

    /**
     * Switches the selected image based on the given direction.
     * @param direction - The direction to switch the image. Can be 'left' or 'right'.
     */
    switchImage(direction: string) {
        if (direction === 'left') this.selectedImageIndex--;
        else if (direction === 'right') this.selectedImageIndex++;

        let images: string[] = []; 
        if (this.selectedTask) images = this._utilsService.getTaskImages(this.selectedTask);
        if (this.selectedItac) images = this._utilsService.getItacImages(this.selectedItac);

        if (this.selectedImageIndex < 0) this.selectedImageIndex = images.length - 1;
        else if (this.selectedImageIndex >= images.length) this.selectedImageIndex = 0;
        this.selectedImage = images[this.selectedImageIndex];
    }

    /**
     * Sets the position of the card based on the mouse event.
     * @param event - The mouse event containing the target element.
     */
    setCardPosition(event: any) {
        if (event.domEvent) {
            this.selectedImagePosition = { x: event.domEvent.x, y: event.domEvent.y };
            this.isAbove = event.domEvent.y < (window.innerHeight / 2);
            this.isLeft = event.domEvent.x < (window.innerWidth / 2);
        }
    }

    /**
     * Sets the card position based on the pointer event.
     * 
     * @param event - The pointer event containing the coordinates of the pointer.
     */
    setCardPositionWithPointer(event: PointerEvent) {
        if (event) {
            this.selectedImagePosition = { x: event.x, y: event.y };
            this.isAbove = event.y < (window.innerHeight / 2);
            this.isLeft = event.x < (window.innerWidth / 2);
        }
    }

    /**
     * Sets the position of the list card based on the provided event.
     * 
     * @param event - The event object containing the position information.
     */
    setListCardPosition(event: any) { 
        if (event.domEvent) {
            this.clickPosition = { x: event.domEvent.x, y: event.domEvent.y };
            this.isListAbove = event.domEvent.y < (window.innerHeight / 2);
            this.isListLeft = event.domEvent.x < (window.innerWidth / 2);
        }
    }

    /**
     * Opens the selected task.
     * If running in an Electron app, navigates to the task page.
     * If running in a browser, opens the task page in a new tab.
     */
    openTask(): void {
        if (this.selectedTask) {
            const sameTab = this._utilsService.getLocalStorageBoolean('openTaskInSameTab');
            if (this._electronService.isElectronApp() || sameTab) {
                this.saveMapInfo();
                this.router.navigate(['/task', this.selectedTask.id]);
            } else {
                const url = this.router.serializeUrl(this.router.createUrlTree(['/task', this.selectedTask.id]));
                window.open(url, '_blank');
            }
        }
    }

    /**
     * Navigates back to the previous page and removes the 'user_actions_map_data' item from localStorage.
     */
    goBack() {
        localStorage.removeItem('user_actions_map_data');
        this.location.back();
    }

    /**
     * Loads the map information from local storage and sets the necessary properties.
     * If the map information is available in local storage, it sets the selected user action date,
     * calls the setActions method, sets the center and zoom properties, and then calls the setLoadData method.
     */
    async loadMapInfo() { 
        const user_actions_map_data = localStorage.getItem('user_actions_map_data');
        if (user_actions_map_data) {
            const data = JSON.parse(user_actions_map_data);
            this.selectedUserActionDate = new Date(data.selectedUserActionDate);
            await this.setActions(this.selectedUserActionDate);

            this.center = data.center;
            this.zoom = data.zoom;
            setTimeout(() => { this.setLoadData(data); }, 100);
        }
    }

    /**
     * Sets the data for the component.
     * 
     * @param data - The data object containing the properties to be set.
     */
    setLoadData(data: any) {
        this.actionText = data.actionText;
        this.selectedTask = data.selectedTask;
        this.selectedItac = data.selectedItac;
        this.selectedImage = data.selectedImage;
        this.selectedImageIndex = data.selectedImageIndex;
        this.selectedActionIndex = data.selectedActionIndex;
        this.selectedImagePosition = data.selectedImagePosition;
        this.isAbove = data.isAbove;
        this.isLeft = data.isLeft;
        this.isListAbove = data.isListAbove;
        this.isListLeft = data.isListLeft;
        this.clickPosition = data.clickPosition;
        this.selectedActions = data.selectedActions;
        this.cardListTasks = data.cardListTasks;
        this.cardListItacs = data.cardListItacs;
        this.showFloatingList = data.showFloatingList;
    }

    /**
     * Saves the map information to the local storage.
     */
    saveMapInfo() {
        const data = {
            center: this.map.googleMap!.getCenter(),
            zoom: this.map.googleMap!.getZoom(),
            selectedUserActionDate: this.selectedUserActionDate,
            actionText: this.actionText,
            selectedTask: this.selectedTask,
            selectedItac: this.selectedItac,
            selectedImage: this.selectedImage,
            selectedImageIndex: this.selectedImageIndex,
            selectedActionIndex: this.selectedActionIndex,
            selectedImagePosition: this.selectedImagePosition,
            isAbove: this.isAbove,
            isLeft: this.isLeft,
            isListAbove: this.isListAbove,
            isListLeft: this.isListLeft,
            clickPosition: this.clickPosition,
            selectedActions: this.selectedActions,
            cardListTasks: this.cardListTasks,
            cardListItacs: this.cardListItacs,
            showFloatingList: this.showFloatingList,
        };
        localStorage.setItem('user_actions_map_data', JSON.stringify(data));
    }
    
    /**
     * Opens the ITAC page based on the selected ITAC.
     * If the application is running in an Electron environment, it navigates to the ITAC page using the router.
     * If the application is running in a web browser, it opens a new window with the ITAC page.
     */
    openItac(): void {
        if (this.selectedItac) {
            if (this._electronService.isElectronApp()) {
                this.router.navigate(['/itac', this.selectedItac.id]);
            } else {
                const url = this.router.serializeUrl(this.router.createUrlTree(['/itac', this.selectedItac.id]));
                window.open(url, '_blank');
            }
        }
    }

    /**
     * Handles the click event on the map.
     * Clears the selected image.
     * 
     * @param event - The click event on the map.
     */
    mapClick(_: google.maps.MapMouseEvent) {
        this.clearCardData();
    }

    /**
     * Clears the selected image when the zoom is changed.
     */
    onZoomChanged() { 
        this.clearCardData();
    }

    /**
     * Clears the selected image when the center is changed.
     */
    onCenterChanged() {
        this.clearCardData();
    }

    /**
     * Clears the selected image.
     * 
     * @param event - The event object representing the click event.
     */
    mapDlclick(_: any) {
        this.clearCardData();
    }

    /**
     * Clears the selected image and resets the action text and image position.
     */
    clearCardData(): void {
        this.selectedItac = null;
        this.selectedTask = null;
        this.actionText = null;
        this.selectedImage = null;
        this.selectedImagePosition = null;
        this.showFloatingList = false;
        this.selectedActions = [];
        this.cardListTasks = [];
        this.cardListItacs = [];
        this.selectedImageIndex = 0;
        this.selectedActionIndex = 0;
    }
    
    /**
     * Handles the click event on a user marker.
     * Retrieves the position of the marker and performs additional actions.
     * @param marker - The clicked marker.
     */
    async onClickedUserMarker(marker: google.maps.Marker) {
        const lat = marker.getPosition()!.lat();
        const lng = marker.getPosition()!.lng();
        this.center = { lat: lat, lng: lng };
        const userId = marker.getLabel();
        try {
            const question = '¿Desea mostrar trayectoria de fontanero?';
            const res = await this._utilsService.openQuestionDialog('Confirmación', question);
            if (res) {
                const date = await this._utilsService.openDateSelectorDialog('Fecha a mostrar');
                const dayString = moment(date).format('YYYY-MM-DD'); // '2021-10-13'
                const footprints: Footprint[] = await this._apiService.getUserFootPrint(+userId!, dayString);
                if (footprints && footprints.length > 0) this.setMarkersFootprints(footprints);
                else {
                    const text = `No hay trayectoria del dia ${moment(date).format('DD/MM/YYYY')}`;
                    this._utilsService.openSnackBar(text, 'error');
                }
            }
        } catch (err) {}
    }

    /**
     * Displays the user action based on the selected date.
     * If a user is available, it opens a date selector dialog to choose a date.
     * Then, it retrieves the user actions for the selected date and sets the user action markers.
     */
    async showUserActions() {
        try {
            if(this.user) {
                this.selectedUserActionDate = await this._utilsService.openDateSelectorDialog('Fecha a mostrar');
                await this.setActions(this.selectedUserActionDate);
            }
        } catch (err) {}
    }

    /**
     * Sets the actions for the given date.
     * @param {Date} date - The date for which to set the actions.
     * @returns {Promise<void>} - A promise that resolves when the actions are set.
     */
    async setActions(date: Date): Promise<void> {
        if (this.user) { 
            const dayString = moment(date).format('YYYY-MM-DD'); // '2021-10-13'
            const userActions: UserAction[] = await this._apiService.getUserActionsDay(this.user.id, dayString);
            if (userActions && userActions.length > 0) await this.setUserActionMarkers(userActions);
            else this._utilsService.openSnackBar('No hay acciones para el día seleccionado', 'warning');
        }
    }

    /**
     * Retrieves the tasks associated with the given user actions.
     * @param userActions - An array of user actions.
     * @returns A promise that resolves to an array of tasks.
     */
    async getActionTasks(userActions: UserAction[]): Promise<WaterTask[]> {
        const actions = (userActions.filter((userAction: UserAction) => userAction.entity_type === 'task'));
        const tasksIds = actions.map((userAction: UserAction) => userAction.entity_id);
        const tasks = await this._apiService.getTasksSync(tasksIds);
        return tasks;
    }

    /**
     * Retrieves the ITACs (Individual Task and Control) associated with the given user actions.
     * @param userActions - An array of user actions.
     * @returns A promise that resolves to an array of ITACs.
     */
    async getActionItacs(userActions: UserAction[]): Promise<Itac[]> {
        const actions = (userActions.filter((userAction: UserAction) => userAction.entity_type === 'itac'));
        const itacsIds = actions.map((userAction: UserAction) => userAction.entity_id);
        const itacs = await this._apiService.getTasksSync(itacsIds);
        return itacs;
    }

    /**
     * Sets the user action markers and polyline options based on the given user actions.
     * @param userActions - An array of user actions.
     */
    async setUserActionMarkers(userActions: UserAction[]): Promise<void> {
        this.clearUserActionMarkers();
        let coordinates: any = [];
        coordinates.push(new google.maps.LatLng(userActions[0].geolocation!.lat, userActions[0].geolocation!.lng));

        const tasks = await this.getActionTasks(userActions);
        const itacs = await this.getActionItacs(userActions);

        for (let i = 1; i < userActions.length - 1; i++) {
            if (!userActions[i].geolocation) continue;
            this.addUserActionMarker(userActions[i], tasks, itacs);
        }
        this.addUserActionsPolyline(userActions, coordinates);
    }

    /**
     * Adds a polyline to the map representing the user actions.
     * @param userActions - An array of user actions.
     * @param coordinates - An array to store the coordinates of the polyline.
     */
    addUserActionsPolyline(userActions: UserAction[], coordinates: any) {
        for (const marker of this.userActionMarkers) {
            const position = marker.getPosition();
            if (position) coordinates.push(new google.maps.LatLng(position.lat(), position.lng()));
        }
        this.setUserActionsStartAndEndMarkers(userActions, coordinates);
    }

    /**
     * Sets the start and end markers for user actions on the map.
     * 
     * @param userActions - An array of user actions.
     * @param coordinates - An array of coordinates.
     */
    setUserActionsStartAndEndMarkers(userActions: UserAction[], coordinates: any) {
        this.center = { lat: userActions[0].geolocation!.lat, lng: userActions[0].geolocation!.lng };
        this.addStartUserActionPositionMarker(userActions[0]);
        this.addEndUserActionPositionMarker(userActions[userActions.length - 1]);
        const lat = userActions[userActions.length - 1].geolocation!.lat;
        const lng = userActions[userActions.length - 1].geolocation!.lng;
        coordinates.push(new google.maps.LatLng(lat, lng));
        this.userActionPolylineOptions = this.getUserActionPolylineOptions(coordinates);
    }
    
    /**
     * Clears the user action markers and position markers.
     */
    clearUserActionMarkers() {
        this.userActionMarkers.clear();
        this.startUserActionPositionMarkers.clear();
        this.endUserActionPositionMarkers.clear();
    }

    /**
     * Returns the polyline options for the user action.
     * @param coordinates - The coordinates for the polyline path.
     * @returns The polyline options object.
     */
    getUserActionPolylineOptions(coordinates: any): any {
        return  {
            path: coordinates,
            strokeOpacity: 0,
            strokeColor: '#FFFFFF',
            zIndex: 3,
            icons: [
                {
                    icon: this.lineSymbol,
                    offset: '0',
                    repeat: '20px',
                },
            ],
        };
    }

    /**
     * Sets the markers and polyline for the given footprints.
     * @param footprints - An array of Footprint objects.
     */
    setMarkersFootprints(footprints: Footprint[]) {
        this.footprintMarkers.clear();
        this.startPositionMarkers.clear();
        this.endPositionMarkers.clear();
        let coordinates: any = [];
        coordinates.push(
            new google.maps.LatLng(footprints[0].geolocation!.lat, footprints[0].geolocation!.lng)
        );
        for (let i = 1; i < footprints.length - 1; i++) {
            if (footprints[i].geolocation) {
                this.addFootprintMarker(footprints[i]);
                coordinates.push(
                    new google.maps.LatLng(
                        footprints[i].geolocation!.lat,
                        footprints[i].geolocation!.lng
                    )
                );
            }
        }
        this.addStartPositionMarker(footprints[0]);
        this.addEndPositionMarker(footprints[footprints.length - 1]);
        coordinates.push(
            new google.maps.LatLng(
                footprints[footprints.length - 1].geolocation!.lat,
                footprints[footprints.length - 1].geolocation!.lng
            )
        );
        this.polylineOptions = {
            path: coordinates,
            strokeOpacity: 0,
            strokeColor: '#FFFFFF',
            zIndex: 3,
            icons: [
                {
                    icon: this.lineSymbol,
                    offset: '0',
                    repeat: '20px',
                },
            ],
            // geodesic: true,
        };
    }

    /**
     * Adds a footprint marker to the map.
     * @param footprint - The footprint object containing the geolocation and date information.
     */
    addFootprintMarker(footprint: Footprint) {
        let lat = footprint.geolocation!.lat;
        let lng = footprint.geolocation!.lng;
        let pos = new google.maps.LatLng(lat, lng);

        let footprintName = moment(footprint.date!).format('HH:mm  DD/MM/YYYY');
        const marker = new google.maps.Marker({
            position: pos,
            label: footprint.id.toString(),
            title: footprintName, //this is what get displayed on map
        });
        this.footprintMarkers.add(marker);
    }

    /**
     * Adds a start position marker to the map based on the provided footprint.
     * 
     * @param footprint - The footprint object containing the geolocation information.
     */
    addStartPositionMarker(footprint: Footprint) {
        let lat = footprint.geolocation!.lat;
        let lng = footprint.geolocation!.lng;
        let pos = new google.maps.LatLng(lat, lng);

        let footprintName = `${moment(footprint.date!).format(
            'HH:mm  DD/MM/YYYY'
        )} -> Inicio de trayecto`;
        const marker = new google.maps.Marker({
            position: pos,
            label: footprint.id.toString(),
            title: footprintName, //this is what get displayed on map
        });
        this.startPositionMarkers.add(marker);
    }

    /**
     * Adds an end position marker to the map based on the given footprint.
     * @param footprint - The footprint containing the geolocation and date information.
     */
    addEndPositionMarker(footprint: Footprint) {
        let lat = footprint.geolocation!.lat;
        let lng = footprint.geolocation!.lng;

        let pos = new google.maps.LatLng(lat, lng);
        let footprintName = `${moment(footprint.date!).format(
            'HH:mm  DD/MM/YYYY'
        )} -> Fin de trayecto`;
        const marker = new google.maps.Marker({
            position: pos,
            label: footprint.id.toString(),
            title: footprintName, //this is what get displayed on map
        });
        this.endPositionMarkers.add(marker);
    }

    /**
     * Finds a nearby marker within a given radius based on the user's position and a new user action.
     * @param position - The user's position as a google.maps.LatLng object.
     * @param radius - The radius within which to search for nearby markers.
     * @param newUserAction - The new user action to compare with the marker data.
     * @returns The first marker found within the radius that matches the new user action, or null if no match is found.
     */
    findNearbyMarker(position: google.maps.LatLng, radius: number, newUserAction: UserAction): google.maps.Marker | null {
        for (const marker of this.userActionMarkers) {
            const markerPosition = marker.getPosition();
            if (markerPosition) {
                    const distance = this._utilsService.calculateDistance(
                    position.lat(), position.lng(),
                    markerPosition.lat(), markerPosition.lng()
                );
                if (distance < radius) {
                    const label = marker.getLabel() as any;
                    const markerData: UserAction[] = JSON.parse(label);
                    
                    if (markerData.some(action => this.isSameEntityOrLocation(action, newUserAction))) return marker;
                }
            }
        }
        return null;
    }

    /**
     * Checks if two UserAction objects represent the same entity or location.
     * @param action - The first UserAction object.
     * @param newAction - The second UserAction object.
     * @returns A boolean value indicating whether the two UserAction objects represent the same entity or location.
     */
    isSameEntityOrLocation(action: UserAction, newAction: UserAction): boolean {
        if (this.isEntityTypeAction(newAction)) {
            if (action.entity_type === newAction.entity_type && action.entity_id === newAction.entity_id) return true;
            if (newAction.entity_location_code) {
                if(action.entity_location_code === newAction.entity_location_code) return true; 
            }
        }
        return false;
    }

    /**
     * Adds a user action marker to the map.
     * @param userAction - The user action object containing geolocation and other details.
     */
    addUserActionMarker(userAction: UserAction, tasks: WaterTask[], itacs: Itac[]): boolean {
        let pos = this.getActionsPosition(userAction, tasks, itacs);
        userAction.geolocation = this._utilsService.myLatLngFromLatLang(pos);
        const existingMarker = this.findNearbyMarker(pos, 100, userAction);
        if (existingMarker) {
            this.addUserActionToExistingMarker(userAction, existingMarker);
            return false;
        }
        else this.addNewUserActionMarker(userAction, pos);
        return true;
    }

    /**
     * Retrieves the position for a user action based on its type and associated tasks or itacs.
     * @param userAction - The user action for which to retrieve the position.
     * @param tasks - The array of tasks.
     * @param itacs - The array of itacs.
     * @returns The position as a `google.maps.LatLng` object.
     */
    getActionsPosition(userAction: UserAction, tasks: WaterTask[], itacs: Itac[]) {
        let pos = new google.maps.LatLng(userAction.geolocation.lat, userAction.geolocation.lng);
        if (userAction.entity_type === 'task') {
            const task = tasks.find(t => t.id === userAction.entity_id);
            if (task) {
                let latLng = this._utilsService.getLatLngFromMyLatLng(task);
                if (latLng && this._utilsService.isWithinRadius(pos, latLng, 100)) pos = latLng;
                else {
                     //?Action task not in radius
                }
            }
        }
        else if (userAction.entity_type === 'itac') { 
            const itac = itacs.find(i => i.id === userAction.entity_id);
            if (itac) {
                let latLng = this._utilsService.getLatLngFromItacMyLatLng(itac);
                if (latLng && this._utilsService.isWithinRadius(pos, latLng, 100)) pos = latLng;
                else {
                     //?Action itac not in radius
                }
            }
        }
        return pos;
    }

    /**
     * Adds a user action to an existing marker.
     * 
     * @param userAction - The user action to be added.
     * @param marker - The marker to which the user action will be added.
     */
    addUserActionToExistingMarker(userAction: UserAction, marker: google.maps.Marker) {
        const title = marker.getTitle();
        const label = marker.getLabel() as any;
        const markerData: UserAction[] = JSON.parse(label);
        markerData.push(userAction);
        marker.setLabel(JSON.stringify(markerData));
        marker.setPosition(this.averageUserActionLocations(markerData));
        if (this.isEntityTypeAction(userAction)) {
            const existingTitle = marker.getTitle() as any;
            const actionCount = existingTitle.includes('Acciones:') ? parseInt(existingTitle.split('Acciones:')[1].trim()) : 0;
            const code = userAction.entity_location_code ? `- Código de emplazamiento: ${userAction.entity_location_code}` : '';
            const date = moment(markerData[0].date_time_modified!).format('HH:mm DD/MM/YYYY');
            marker.setTitle(`Acciones: ${actionCount + 1} - Fecha ${date} ${code}`);
        }
        else marker.setTitle(title + '\n' + this.getUserActionTooltip(userAction)); // Update the title if needed
    }

    /**
     * Calculates the average user action location based on an array of user actions.
     * @param userActions - An array of user actions.
     * @returns The average user action location as a google.maps.LatLng object.
     */
    averageUserActionLocations(userActions: UserAction[]): google.maps.LatLng {
        const averageLat = userActions.reduce((sum, action) => sum + action.geolocation!.lat, 0) / userActions.length;
        const averageLng = userActions.reduce((sum, action) => sum + action.geolocation!.lng, 0) / userActions.length;
        const averagePos = new google.maps.LatLng(averageLat, averageLng);
        return averagePos;
    }

    /**
     * Adds a new user action marker to the map.
     * 
     * @param userAction - The user action associated with the marker.
     * @param pos - The position of the marker on the map.
     */
    addNewUserActionMarker(userAction: UserAction, pos: google.maps.LatLng) { 
        let userActionText = this.getUserActionTooltip(userAction);
        const code = userAction.entity_location_code ? `- Código de emplazamiento: ${userAction.entity_location_code}` : '';
        const date = moment(userAction.date_time_modified!).format('HH:mm DD/MM/YYYY');
        if (this.isEntityTypeAction(userAction)) userActionText = `Acciones: ${1} - Fecha ${date} ${code}`;

        const marker = new google.maps.Marker({
            position: pos,
            label: JSON.stringify([userAction]),
            title: userActionText, //this is what get displayed on map
        });
        this.userActionMarkers.add(marker);
    }

    /**
     * Checks if the given user action is of a specific entity type.
     * @param userAction - The user action to check.
     * @returns `true` if the user action is of type 'task' or 'itac', `false` otherwise.
     */
    isEntityTypeAction(userAction: UserAction): boolean {
        if (userAction.entity_type === 'task') return true;
        if (userAction.entity_type === 'itac') return true;
        return false;
    }

    /**
     * Returns the tooltip text for a user action marker.
     * @param userAction - The user action object.
     * @returns The tooltip text.
     */
    getUserActionTooltip(userAction: UserAction): string {
        const dateTime = moment(userAction.date_time_modified!).format('HH:mm DD/MM/YYYY');
        let userActionText = `${this.convertActionType(userAction.action_type)} (${userAction.details}) - ${dateTime}`;
        return userActionText;
    }

    /**
     * Adds a marker to the map representing the start position of a user action.
     * 
     * @param userAction - The user action object containing the geolocation information.
     */
    addStartUserActionPositionMarker(userAction: UserAction) {
        let lat = userAction.geolocation!.lat;
        let lng = userAction.geolocation!.lng;

        let pos = new google.maps.LatLng(lat, lng);

        let userActionName = this.getUserActionTooltip(userAction);
        userActionName += ` -> Inicio de acciones`;
        const marker = new google.maps.Marker({
            position: pos,
            label: JSON.stringify([userAction]),
            title: userActionName, //this is what get displayed on map
        });
        this.startUserActionPositionMarkers.add(marker);
    }

    /**
     * Adds a position marker for the end user action.
     * 
     * @param userAction - The user action object containing the geolocation information.
     */
    addEndUserActionPositionMarker(userAction: UserAction) {
        let lat = userAction.geolocation!.lat;
        let lng = userAction.geolocation!.lng;

        let pos = new google.maps.LatLng(lat, lng);
        
        let userActionName = this.getUserActionTooltip(userAction);
        userActionName += ` -> Fin de acciones`;
        const marker = new google.maps.Marker({
            position: pos,
            label: JSON.stringify([userAction]),
            title: userActionName, //this is what get displayed on map
        });
        this.endUserActionPositionMarkers.add(marker);
    }

    /**
     * Returns the directory of the given water task.
     * @param task - The water task for which to get the directory.
     * @returns The directory of the water task.
     */
    getDirOfTask(task: WaterTask) {
        return this._utilsService.getDirOfTask(task, false);
    }
    
    /**
     * @brief Gets the text line for the subscriber of a task.
     * @details This function calls the `_utilsService.getTaskSubscriberTextLine` function
     * to get the text line for the subscriber of the specified water task.
     * @param task - The water task for which to get the subscriber text line.
     * @returns The text line for the subscriber of the task.
     */
    getTaskSubscriberTextLine(task: WaterTask) {
        return this._utilsService.getTaskSubscriberTextLine(task);
    }

    /**
     * @brief Gets the text line for the counter of a task.
     * @details This function calls the `_utilsService.getTaskCounterTextLine` function
     * to get the text line for the counter of the specified water task.
     * @param task - The water task for which to get the counter text line.
     * @returns The text line for the counter of the task.
     */
    getTaskCounterTextLine(task: WaterTask) {
        return this._utilsService.getTaskCounterTextLine(task);
    }

    /**
     * @brief Checks if a task has a radius.
     * @details This function calls the `_utilsService.checkIfFieldIsValid` function
     * to check if the `tipoRadio` field of the specified water task is valid.
     * @param task - The water task for which to check the radius.
     * @returns True if the task has a radius, false otherwise.
     */
    hasRadius(task: WaterTask) {
        return this._utilsService.isFieldValid(task.tipoRadio);
    }

    /**
     * Returns the color associated with the priority of a given task.
     * 
     * @param task - The WaterTask object for which to determine the priority color.
     * @returns The color associated with the priority of the task.
     */
    getPriorityColor(task: WaterTask) {
        return this._utilsService.getTaskPriorityColor(task);
    }

    /**
     * Handles the click event on a marker.
     * @param marker - The clicked marker.
     */
    async onClickedMarker(marker: google.maps.Marker) {
        this.infoWindow.open();
        console.log(marker.getPosition()?.toUrlValue());
        const lat = marker.getPosition()!.lat();
        const lng = marker.getPosition()!.lng();

        this.center = {
            lat: lat, //position.coords.latitude,
            lng: lng, //position.coords.longitude,
        };
    }

    /**
     * Shows or hides the loading spinner based on the provided state.
     * @param state - A boolean value indicating whether to show or hide the loading spinner.
     */
    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this.spinner.show('mapSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this.spinner.hide('mapSpinner');
        }
    }

    /**
     * Retrieves the image for a given water task.
     * 
     * @param task - The water task for which to retrieve the image.
     * @returns The image URL for the specified task.
     */
    getTaskImage(task: WaterTask) {
        return this._utilsService.getTaskImage(task);
    }

    /**
     * Retrieves the image for the specified ITAC.
     * 
     * @param itac - The ITAC object for which to retrieve the image.
     * @returns The image URL for the specified ITAC.
     */
    getItacImage(itac: Itac) {
        return this._utilsService.getItacImage(itac);
    }

    /**
     * Returns the marker options for a counter location.
     * @param user - The user object.
     * @returns The marker options for the counter location.
     */
    getCounterMarkerOption(user: MiRutaUser) {
        const markerIconName: string = `assets/img/markers/counter_location.svg`;
        const markerIcon: google.maps.Icon = {
            url: markerIconName,
            scaledSize: new google.maps.Size(40, 40),
        };
        const markerOption: google.maps.MarkerOptions = {
            icon: markerIcon,
            // animation: google.maps.Animation.DROP,
            draggable: true,
        };
        return markerOption;
    }

    /**
     * Handles the drag event for the marker.
     * @param event - The drag event object.
     * @param marker - The Google Maps marker object.
     */
    onDrag(event: any, marker: google.maps.Marker) {
        console.log('************ onDrag marker ***************');
        console.log(marker);
        console.log('************ onDrag marker ***************');
        console.log('************ event ***************');
        console.log(event);
        console.log('************ event ***************');
    }

    /**
     * Handles the click event on the map.
     * @param event - The click event object.
     */
    click(event: google.maps.MapMouseEvent) {
        console.log('*********** click ***********');
        console.log(event);
        console.log('*********** click ***********');
    }

    /**
     * Handles the double click event on the map.
     * @param event - The double click event on the map.
     */
    async mapDblclick(event: google.maps.MapMouseEvent) {
        this.showLoading(true);
        const lat = event?.latLng?.lat(); //lat drop
        const lng = event?.latLng?.lng(); //lng drop
        this.showLoading(false);
    }
}

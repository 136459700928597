<div *ngIf="electronService.isElectronApp()" class="floating-top-left" (click)="location.back()">
    <button mat-fab color="primary">
        <mat-icon style="margin-left: 10px" matTooltip="Ir atrás">arrow_back_ios</mat-icon>
    </button>
</div>

<div class="screen">
    <div class="row1">

        <div [formGroup]="totalMonthGroup"
            [ngClass]="{
                'total_month': !totalMonthFullScreen, 
                'full_screen': totalMonthFullScreen
            }">
            <div class="tag_container">
                <h3 class="h3_title" style="margin-left: 80px;">
                    Total al Mes (Todos)
                </h3>
                <mat-form-field appearance="standard" style="width: 60px">
                    <mat-label>Tipo</mat-label>
                    <mat-select formControlName="type">
                        <mat-option *ngFor="let type of chartTypes" [value]="type">{{ type }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" style="max-width: 100px;">
                    <mat-label>Año</mat-label>
                    <mat-select formControlName="year">
                        <mat-option *ngFor="let year of totalYearChartLabels" [value]="+year">{{ year }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <button mat-icon-button matTooltip="Pantalla completa" color="primary"
                    (click)="toggleTotalMonthFullScreen();">
                    <mat-icon *ngIf="!totalMonthFullScreen">open_in_new</mat-icon>
                    <mat-icon *ngIf="totalMonthFullScreen">minimize</mat-icon>
                </button>
            </div>
            <div class="chart_container">
                    <canvas *ngIf="!loadingTotalMonth" baseChart
                        [options]="barChartOptions"
                        [datasets]="totalMonthChartData"
                        [labels]="totalMonthChartLabels"
                        [legend]="totalMonthChartLegend"
                        [type]="totalMonthChartType">
                    </canvas>
                    <div *ngIf="loadingTotalMonth">
                        <mat-spinner style="margin: auto;" diameter="50"></mat-spinner>
                    </div>
            </div>
        </div>

        <div [formGroup]="totalYearUserGroup"
            [ngClass]="{'total_year_per_user': !totalYearUserFullScreen, 'full_screen': totalYearUserFullScreen}">
            <div class="tag_container">
                <h3 class="h3_title">
                    Tareas al año por operario
                </h3>
                <mat-form-field appearance="standard" style="width: 60px">
                    <mat-label>Tipo</mat-label>
                    <mat-select formControlName="type">
                        <mat-option *ngFor="let type of chartTypes" [value]="type">{{ type }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Operarios</mat-label>
                    <mat-select multiple  formControlName="users">
                        <mat-option *ngFor="let user of users" [value]="user">{{ user | user }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <button mat-icon-button matTooltip="Pantalla completa" color="primary"
                    (click)="toggleTotalYearUserFullScreen();">
                    <mat-icon *ngIf="!totalYearUserFullScreen">open_in_new</mat-icon>
                    <mat-icon *ngIf="totalYearUserFullScreen">minimize</mat-icon>
                </button>
            </div>
            <div class="chart_container">
                <canvas *ngIf="!loadingTotalYearUser" baseChart
                    [options]="barChartOptions"
                    [datasets]="totalYearUserChartData"
                    [labels]="totalYearUserChartLabels"
                    [legend]="totalYearUserChartLegend"
                    [type]="totalYearUserChartType">
                </canvas>
                <div *ngIf="loadingTotalYearUser">
                    <mat-spinner style="margin: auto;" diameter="50"></mat-spinner>
                </div>
            </div>
        </div>

        <div [formGroup]="totalYearGroup"
            [ngClass]="{
                'total_year': !totalYearFullScreen, 
                'full_screen': totalYearFullScreen
            }">
            <div class="tag_container">
                <h3 class="h3_title">
                    Total de tareas al año (Todos los operarios)
                </h3>
                <mat-form-field appearance="standard" style="width: 60px">
                    <mat-label>Tipo</mat-label>
                    <mat-select formControlName="type">
                        <mat-option *ngFor="let type of chartTypes" [value]="type">{{ type }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <button mat-icon-button matTooltip="Pantalla completa" color="primary"
                    (click)="toggleTotalYearFullScreen();">
                    <mat-icon *ngIf="!totalYearFullScreen">open_in_new</mat-icon>
                    <mat-icon *ngIf="totalYearFullScreen">minimize</mat-icon>
                </button>
            </div>
            <div class="chart_container">
                <canvas *ngIf="!loadingTotalYear" baseChart
                    [options]="barChartOptions"
                    [datasets]="totalYearChartData"
                    [labels]="totalYearChartLabels"
                    [legend]="totalYearChartLegend"
                    [type]="totalYearChartType">
                </canvas>
                <div *ngIf="loadingTotalYear">
                    <mat-spinner style="margin: auto;" diameter="50"></mat-spinner>
                </div>
            </div>
        </div>

    </div>


    <div class="row2">

        <div [formGroup]="totalMonthUserGroup"
            [ngClass]="{
                'total_month_per_user': !totalMonthUserFullScreen, 
                'full_screen': totalMonthUserFullScreen
            }">
            <div class="tag_container">
                <h3 class="h3_title">
                    Total de tareas al mes por operario
                </h3>
                <mat-form-field appearance="standard" style="width: 60px">
                    <mat-label>Tipo</mat-label>
                    <mat-select formControlName="type">
                        <mat-option *ngFor="let type of chartTypes" [value]="type">{{ type }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" style="max-width: 100px;">
                    <mat-label>Año</mat-label>
                    <mat-select formControlName="year">
                        <mat-option *ngFor="let year of totalYearUserChartLabels" [value]="+year">{{ year }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Operarios</mat-label>
                    <mat-select multiple formControlName="users">
                        <mat-option *ngFor="let user of users" [value]="user">{{ user | user }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <button mat-icon-button matTooltip="Pantalla completa" color="primary"
                    (click)="toggleTotalMonthUserFullScreen();">
                    <mat-icon *ngIf="!totalMonthUserFullScreen">open_in_new</mat-icon>
                    <mat-icon *ngIf="totalMonthUserFullScreen">minimize</mat-icon>
                </button>
            </div>
            <div class="chart_container">
                    <canvas *ngIf="!loadingTotalMonthUser" baseChart
                        [options]="barChartOptions"
                        [datasets]="totalMonthUserChartData"
                        [labels]="totalMonthUserChartLabels"
                        [legend]="totalMonthUserChartLegend"
                        [type]="totalMonthUserChartType">
                    </canvas>
                    <div *ngIf="loadingTotalMonthUser">
                        <mat-spinner style="margin: auto;" diameter="50"></mat-spinner>
                    </div>
            </div>
        </div>

        <div [formGroup]="totalDayUserGroup"
            [ngClass]="{
                'total_day_per_user': !totalDayUserFullScreen, 
                'full_screen': totalDayUserFullScreen
            }">
            <div class="tag_container">
                <h3 class="h3_title">
                    Total de tareas al día por operario
                </h3>
                <mat-form-field appearance="standard" style="width: 60px">
                    <mat-label>Tipo</mat-label>
                    <mat-select formControlName="type">
                        <mat-option *ngFor="let type of chartTypes" [value]="type">{{ type }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Mes</mat-label>
                    <mat-select formControlName="month">
                        <mat-option *ngFor="let month of totalMonthUserChartLabels" [value]="month">{{ month }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Año</mat-label>
                    <mat-select formControlName="year">
                        <mat-option *ngFor="let year of totalYearUserChartLabels" [value]="+year">{{ year }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Operarios</mat-label>
                    <mat-select multiple formControlName="users">
                        <mat-option *ngFor="let user of users" [value]="user">{{ user | user }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <button mat-icon-button matTooltip="Pantalla completa" color="primary"
                    (click)="toggleTotalDayUserFullScreen();">
                    <mat-icon *ngIf="!totalDayUserFullScreen">open_in_new</mat-icon>
                    <mat-icon *ngIf="totalDayUserFullScreen">minimize</mat-icon>
                </button>
            </div>
            <div class="chart_container">
                    <canvas *ngIf="!loadingTotalDayUser" baseChart
                        [options]="barChartOptions"
                        [datasets]="totalDayUserChartData"
                        [labels]="totalDayUserChartLabels"
                        [legend]="totalDayUserChartLegend"
                        [type]="totalDayUserChartType">
                    </canvas>
                    <div *ngIf="loadingTotalDayUser">
                        <mat-spinner style="margin: auto;" diameter="50"></mat-spinner>
                    </div>
            </div>
        </div>

    </div>

</div>
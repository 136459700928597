<mat-dialog-content>

    <div [formGroup]="itacFormData">

        <div class="container">
            
            <div class="row bd-highlight" class="row-content-center">
                
                <p style="font-size: large; margin: 6px; margin-bottom: 20px;">
                    Sección 2 Llave de Puertas
                </p>
                
                <div class="row">
                    <mat-form-field appearance="fill">
                        <mat-label>Siempre Abierto</mat-label>
                        <mat-select formControlName="siempre_abierto">
                            <mat-option *ngFor="let option of ['SI', 'NO']" [value]="option">{{ option }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Tipo de llave</mat-label>
                        <mat-select formControlName="tipo_llave" #selectKeyType>
                            <mat-option *ngFor="let option of ['Llaves de exterior','Llaves maestras','Llave específica','Sin comprobar']" [value]="option">{{ option }}</mat-option>
                        </mat-select>
                    </mat-form-field> 
                    <mat-form-field *ngIf="selectKeyType.value == 'Llaves de exterior'" appearance="fill">
                        <mat-label>Llave de Exterior</mat-label>
                        <mat-select formControlName="extras_llaves">
                            <mat-option *ngFor="let option of ['Allen','Triángulo','Cuadrado','CABB','Otros']" [value]="option">{{ option }}</mat-option>
                        </mat-select>
                    </mat-form-field> 
                    <mat-form-field *ngIf="selectKeyType.value == 'Llaves maestras'" appearance="fill">
                        <mat-label>Llave Maestra</mat-label>
                        <mat-select formControlName="extras_llaves">
                            <mat-option *ngFor="let option of ['IBERDROLA','Gas','Del Gestor','Otros']" [value]="option">{{ option }}</mat-option>
                        </mat-select>
                    </mat-form-field> 
                    <mat-form-field *ngIf="selectKeyType.value == 'Llave específica'" appearance="fill">
                        <mat-label>Llave Específica</mat-label>
                        <mat-select formControlName="extras_llaves">
                            <mat-option *ngFor="let option of ['Tienen todos los vecinos','Tiene algun vecino','Presidentes','Administracion','Conserje','Copia en gestor aguas']" [value]="option">{{ option }}</mat-option>
                        </mat-select>
                    </mat-form-field> 
                    <mat-form-field *ngIf="selectKeyType.value == 'Llaves maestras'" appearance="fill">
                        <mat-label>Utilidad de llave maestra</mat-label>
                        <mat-select formControlName="extras_llaves_utilidad_de_llave_maestra">
                            <mat-option *ngFor="let option of ['Abre directamente el cuarto de contadores',
                            'Abre la puerta donde guardan llave de los contadores']" [value]="option">{{ option }}</mat-option>
                        </mat-select>
                    </mat-form-field> 
                </div>
                
                <div class="row">
                    <mat-form-field style="flex: 3;" appearance="fill">
                        <mat-label>Nota</mat-label>
                        <textarea matInput formControlName="llaves_nota"
                            placeholder="Nota"
                            cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="3">
                        </textarea>
                    </mat-form-field>  
                </div>                    
                
                
            </div>
            
        </div>
    </div>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false">Cancelar</button>
    <button mat-button cdkFocusInitial
    (click)="saveData()"> 
        <mat-icon color="primary">check</mat-icon> Actualizar
    </button>
</mat-dialog-actions>

<ngx-spinner [bdColor] = "(itac)?'rgba(0, 0, 0, 0.2)': 'rgba(255, 255, 255, 1)'" size = "large" color = "#368DCE" 
type = "ball-scale-multiple" [fullScreen] = "true" name="itacSpinner">
</ngx-spinner>
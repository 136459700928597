<div class="form-container">
    <form [formGroup]="formGroup" ngNativeValidate>
        <mat-slide-toggle color="primary" formControlName="restartFilters">
            Reiniciar filtros en cambios de estado
        </mat-slide-toggle>
    </form>
    <div class="spacer"></div>
    <form [formGroup]="formGroup" ngNativeValidate>
        <mat-slide-toggle color="primary" formControlName="showNotifications">
            Mostrar notificaciones
        </mat-slide-toggle>
    </form>
    <div class="spacer"></div>
    <form [formGroup]="formGroup" ngNativeValidate>
        <mat-slide-toggle color="primary" formControlName="openTaskInSameTab">
            Abrir tareas e itacs en misma pestaña
        </mat-slide-toggle>
    </form>
</div>
<div class="header-container">
    <div class="title-container">
        <p class="title-text">Configuración de filtro</p>
    </div>
    <div *ngIf="filterNotEmpty" class="button-container">
        <button
            mat-mini-fab
            class="danger-color"
            (click)="clearFilter()"
            matTooltip="Limpiar filtro"
        >
            <mat-icon class="icon-size">delete</mat-icon>
        </button>
    </div>
</div>

<div [ngClass]="{'scrollable-container': isTreeExpanded, 'non-scrollable-container': !isTreeExpanded}">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
        <!-- This is the tree node template for leaf nodes -->
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
            <!-- use a disabled button to provide padding for tree leaf -->
            <button mat-icon-button disabled></button>
            {{ node.name }}
        </mat-tree-node>
        <!-- This is the tree node template for expandable nodes -->
        <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
            <mat-checkbox
                color="primary"
                class="checkbox-padding"
                (change)="checkBoxChange($event.checked, node.name)"
                [checked]="isChecked(node)"
            >
            </mat-checkbox>
            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                <mat-icon class="mat-icon-rtl-mirror">
                    {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                </mat-icon>
            </button>
            {{ node.name }}
        </mat-tree-node>
    </mat-tree>
</div>

<div *ngIf="!filterNotEmpty" class="no-filter-container">
    <div class="centered-content">
        <div class="icon-container">
            <fa-icon class="large-icon" [icon]="faInbox"></fa-icon>
        </div>
        <div class="message-container">
            <span class="no-filter-message">No hay filtro</span>
        </div>
    </div>
</div>
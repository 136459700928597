<div *ngIf="!loading" class="animate__animated animate__fadeIn animate__slow"
>
    <div style="display: flex;">
        <mat-card-title> 🔔​ Notificaciones </mat-card-title>
        <div style="flex: 1"></div>
        <mat-card-subtitle> {{ getCountText() }} </mat-card-subtitle>
    </div>

    <div style="height: 25px;"></div>

    <div
        *ngIf="!loading && total > 0"
        class="div_scroll"
        infiniteScroll
        [infiniteScrollDistance]="1"
        [infiniteScrollThrottle]="10"
        (scrolled)="onScroll()"
        [scrollWindow]="false"
    >
        <ul>
            <li *ngFor="let notification of notifications; let index=index">
                <button mat-flat-button class="line"
                    [class.new_notification]="!notification.is_seen"
                    (click)="openNotification(index)"
                >
                    <div class="title">
                        {{ notification.title }}
                    </div>
                    <div class="message">
                        {{ notification.message }}
                    </div>
                </button>
            </li>
        </ul>
    </div>

    <div *ngIf="!loading && total == 0" class="empty_box_div">
        <div class="icon_div">
            <fa-icon [icon]="faInbox"></fa-icon>
        </div>
        <div class="text_div">
            <span>No hay notificaciones para mostrar</span>
        </div>
    </div>

    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>{{ 'Cerrar' }}</button>
    </mat-dialog-actions>
</div>

<ngx-spinner
    [bdColor]="'rgba(255, 255, 255, 1)'"
    size="large"
    color="#368DCE"
    type="ball-scale-multiple"
    [fullScreen]="false"
    name="loadingSpinner">
</ngx-spinner>
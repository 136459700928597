/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MySqlService } from 'src/app/services/mysql.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
    selector: 'app-fill-counter',
    templateUrl: './fill-counter.component.html',
    styleUrls: ['./fill-counter.component.scss'],
})
export class FillCounterComponent implements OnInit {
    myControl: FormControl;
    loading = false;
    serialNumbers: string[];
    timerInputChanged: any;

    constructor(public _utilsService: UtilsService, public _mySqlService: MySqlService) {
        this.myControl = new FormControl();
        this.serialNumbers = [];
    }

    async ngOnInit(): Promise<void> {
        const serialNumbers = await this.getSerialNumbers();
        if (serialNumbers) this.serialNumbers = serialNumbers;

        this.myControl.valueChanges.subscribe(async (value: any) => {
            clearTimeout(this.timerInputChanged);
            this.timerInputChanged = setTimeout(async () => {
                this.serialNumbers = await this.getSerialNumbers(value);
            }, 1000);
        });
    }

    async getSerialNumbers(inputText?: string) {
        let whereJsonArray: any = [];
        let whereJson: any = {};
        whereJson['field'] = `status_contador`;
        whereJson['type'] = `AND`;
        whereJson['value'] = 0;

        if (inputText) {
            let whereJson: any = {};
            whereJson['field'] = `numero_serie_contador`;
            whereJson['type'] = `AND`;
            whereJson['value'] = `%${inputText}%`;
            whereJsonArray.push(whereJson);
        }
        whereJsonArray.push(whereJson);

        let selectJson: any = {};
        selectJson['fields'] = [`numero_serie_contador`];
        selectJson['distincts'] = [`numero_serie_contador`];
        const jsonString = JSON.stringify(selectJson);
        const query_result_codes = await this._mySqlService.getCountersFieldValues(
            jsonString,
            JSON.stringify(whereJsonArray),
            undefined,
            undefined,
            '20'
        );
        const serialNumbers = query_result_codes.map((e) => e['numero_serie_contador']!);

        return serialNumbers;
    }
}

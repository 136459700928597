/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faBuilding, faFilePdf, faKey, faInbox } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { ApiService } from '../../../services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessagingService } from 'src/app/services/messaging.service';

import * as moment from 'moment';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Emplacement, getEmplacementFormControls } from 'src/app/interfaces/emplacement';

@Component({
    selector: 'app-emplacement',
    templateUrl: './emplacement.component.html',
    styleUrls: ['./emplacement.component.scss'],
})
export class EmplacementComponent implements OnInit {
    faFilePdf = faFilePdf;
    faInbox = faInbox;
    faBuilding = faBuilding;
    faKey = faKey;
    loading: boolean = false;
    emplacementId: string = '';
    emplacement?: Emplacement;

    emplacementFormData: FormGroup = getEmplacementFormControls();

    emplacementSubcription$?: Subscription;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _apiService: ApiService,
        private _utilsService: UtilsService,
        private spinner: NgxSpinnerService,
        private _messagingService: MessagingService
    ) {
        this.emplacementId = data.emplacementId;
        // this.activatedRoute.params.subscribe((params)=>{
        //   this.emplacementId = params['id'];
        // });
    }

    async ngOnInit(): Promise<void> {
        if (this.emplacementId) {
            this.showLoading(true);

            this.emplacementSubcription$ = this._apiService
                .getDocument('emplacement', this.emplacementId)
                .subscribe(async (doc: any) => {
                    if (!doc) {
                        this._utilsService.openSnackBar(
                            'Error obteniendo datos de emplacement',
                            'error'
                        );
                        this.showLoading(false);
                        return;
                    }
                    const emplacement = doc as Emplacement;
                    emplacement.id = parseInt(this.emplacementId);

                    this.emplacement = emplacement;

                    console.log(this.emplacement);

                    const emplacementFormData = getEmplacementFormControls();
                    const keys = Object.keys(emplacement);
                    for (let key of keys) {
                        let value: any = emplacement[key as keyof Emplacement];
                        if (value) {
                            if (this._utilsService.isValidDate(value)) {
                                value = value;
                            }
                            emplacementFormData.controls[key].setValue(value);
                        }
                    }
                    this.emplacementFormData = emplacementFormData;

                    this.showLoading(false);
                });
        }
    }

    ngOnDestroy(): void {
        this.emplacementSubcription$?.unsubscribe();
    }

    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this.spinner.show('emplacementSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this.spinner.hide('emplacementSpinner');
        }
    }

    saveFormData() {
        const keys = Object.keys(this.emplacementFormData.controls);
        let emplacement: any = {};
        for (let key of keys) {
            let value = this.emplacementFormData.controls[key].value; //this.emplacement[key as keyof Emplacement];
            try {
                if (moment.isMoment(value)) {
                    value = value.toDate();
                }
                emplacement![key as keyof Emplacement] = value;
            } catch (error) {}
        }
        emplacement!['date_time_modified'] = new Date();
        this.emplacement = emplacement;
        // console.log(this.emplacement);
    }

    async saveChanges() {
        this.showLoading(true);
        this.saveFormData();
        if (this.emplacementId) {
            const result: boolean = await this._apiService.updateDocument(
                'emplacement',
                this.emplacementId,
                this.emplacement
            );
            if (result) {
                this._utilsService.openSnackBar('Emplazamiento actualizado correctamente');
            } else {
                this._utilsService.openSnackBar('Error actualizando emplazamiento', 'error');
            }
        } else {
            try {
                const emplacementId = await this._apiService.addDocument(
                    'emplacement',
                    this.emplacement
                );
                if (emplacementId) {
                    this.emplacement!.id = emplacementId;
                    this._utilsService.openSnackBar('Emplazamiento añadido correctamente');
                } else {
                    this._utilsService.openSnackBar('Error añadiendo emplazamiento', 'error');
                }
            } catch (error) {
                this._utilsService.openSnackBar('Error añadiendo emplazamiento', 'error');
            }
        }
        this.showLoading(false);
        this._utilsService.closeEmplacementDialog(this.emplacement!);
    }
}

<mat-dialog-content [formGroup]="time">
    <div style="padding: 15px">
        <div style="padding: 5px; margin-left: 10px">
            <span style="font-size: 1.1em">Hora inicial</span>
        </div>
        <ngx-timepicker-field
            [toggleIcon]="icon"
            formControlName="time"
            [format]="24"
            (timeChanged)="onTimeChanged($event)"
        ></ngx-timepicker-field>
        <ng-template #icon>
            <mat-icon>query_builder</mat-icon>
        </ng-template>
    </div>

    <mat-dialog-content>
        <mat-dialog-actions align="center" style="margin: 5px">
            <button mat-button mat-dialog-close>Cancelar</button>
            <button
                mat-button
                cdkFocusInitial
                [mat-dialog-close]="timeSelected"
                [disabled]="!time.controls['time'].value"
            >
                Aceptar
            </button>
        </mat-dialog-actions></mat-dialog-content
    ></mat-dialog-content
>

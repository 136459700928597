import { FormControl, FormGroup } from '@angular/forms';

export interface Info {
    codigo_info?: string;
    date_time_modified?: Date;
    info?: string;
    id?: string;
    lastIDExportacion?: number;
    lastIDOrden?: number;
    lastIDSAT?: number;
    company?: number;
    manager?: number;
}

export function getInfoDisplayColumns(): string[] {
    const displayedColumns: string[] = [
        'Código',
        'Información',
        'Último ID exportación',
        'Último ID orden',
        'Último ID SAT',
        'Fecha de Modificación',
    ];
    return displayedColumns;
}

export function getInfoField(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'Código':
            value = 'codigo_info';
            break;
        case 'Información':
            value = 'info';
            break;
        case 'Último ID exportación':
            value = 'lastIDExportacion';
            break;
        case 'Último ID orden':
            value = 'lastIDOrden';
            break;
        case 'Último ID SAT':
            value = 'lastIDSAT';
            break;
        case 'Fecha de Modificación':
            value = 'date_time_modified';
            break;
    }
    return value;
}

export function getInfoFieldName(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'codigo_info':
            value = 'Código';
            break;
        case 'info':
            value = 'Información';
            break;
        case 'lastIDExportacion':
            value = 'Último ID exportación';
            break;
        case 'lastIDOrden':
            value = 'Último ID orden';
            break;
        case 'lastIDSAT':
            value = 'Último ID SAT';
            break;
        case 'date_time_modified':
            value = 'Fecha de Modificación';
            break;
    }
    return value;
}

export function getInfoFormControls(): FormGroup {
    const infoFormData: FormGroup = new FormGroup({
        codigo_info: new FormControl(),
        date_time_modified: new FormControl(),
        id: new FormControl(),
        info: new FormControl(),
        lastIDExportacion: new FormControl(),
        lastIDOrden: new FormControl(),
        lastIDSAT: new FormControl(),
        company: new FormControl(),
        manager: new FormControl(),
    });
    return infoFormData;
}

export function getInfoFieldType(field_name: string): string {
    //in bigquery
    let value: string = '';
    switch (field_name) {
        case 'codigo_info':
            value = 'string';
            break;
        case 'info':
            value = 'string';
            break;
        case 'date_time_modified':
            value = 'Date';
            break;
        case 'id':
            value = 'number';
            break;
        case 'lastIDExportacion':
            value = 'number';
            break;
        case 'lastIDOrden':
            value = 'number';
            break;
        case 'lastIDSAT':
            value = 'number';
            break;
    }
    return value;
}

export function getInfoExcelFieldName(excel_field: string): string {
    let value: string = '';
    switch (excel_field.trim().toLowerCase()) {
        case 'Información':
            value = 'info';
            break;
        case 'código':
            value = 'codigo_info';
            break;
        case 'codigo':
            value = 'codigo_info';
            break;
        case 'último id exportación':
            value = 'lastIDExportacion';
            break;
        case 'ultimo id exportación':
            value = 'lastIDExportacion';
            break;
        case 'ultimo id orden':
            value = 'lastIDOrden';
            break;
        case 'último id orden':
            value = 'lastIDOrden';
            break;
        case 'último id sat':
            value = 'lastIDSAT';
            break;
        case 'ultimo id sat':
            value = 'lastIDSAT';
            break;
        case 'fecha de modificación':
            value = 'date_time_modified';
            break;
        case 'fecha de modificacion':
            value = 'date_time_modified';
            break;
    }
    return value;
}

export function getInfoExcelExportColumns(): string[] {
    const columns = [
        'Código',
        'Información',
        'Último ID exportación',
        'Último ID orden',
        'Último ID SAT',
        'Fecha de Modificación',
    ];
    return columns;
}

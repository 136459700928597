/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faBuilding, faFilePdf, faKey, faInbox } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { ApiService } from '../../../services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessagingService } from 'src/app/services/messaging.service';

import * as moment from 'moment';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Info, getInfoFormControls } from 'src/app/interfaces/info';

@Component({
    selector: 'app-info',
    templateUrl: './info.component.html',
    styleUrls: ['./info.component.scss'],
})
export class InfoComponent implements OnInit {
    faFilePdf = faFilePdf;
    faInbox = faInbox;
    faBuilding = faBuilding;
    faKey = faKey;
    loading: boolean = false;
    infoId: string = '';
    info?: Info;

    infoFormData: FormGroup = getInfoFormControls();

    infoSubcription$?: Subscription;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _apiService: ApiService,
        private _utilsService: UtilsService,
        private spinner: NgxSpinnerService,
        private _messagingService: MessagingService
    ) {
        this.infoId = data.infoId;
        // this.activatedRoute.params.subscribe((params)=>{
        //   this.infoId = params['id'];
        // });
    }

    async ngOnInit(): Promise<void> {
        if (this.infoId) {
            this.showLoading(true);
            this.infoSubcription$ = this._apiService
                .getDocument('info', this.infoId)
                .subscribe(async (doc: any) => {
                    if (!doc) {
                        this._utilsService.openSnackBar('Error obteniendo datos de info', 'error');
                        this.showLoading(false);
                        return;
                    }
                    const info = doc as Info;
                    info.id = this.infoId;

                    this.info = info;

                    console.log(this.info);

                    const infoFormData = getInfoFormControls();
                    const keys = Object.keys(info);
                    for (let key of keys) {
                        let value: any = info[key as keyof Info];
                        if (value) {
                            if (this._utilsService.isValidDate(value)) {
                                value = value;
                            }
                            infoFormData.controls[key].setValue(value);
                        }
                    }
                    this.infoFormData = infoFormData;

                    this.showLoading(false);
                });
        }
    }

    ngOnDestroy(): void {
        this.infoSubcription$?.unsubscribe();
    }

    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this.spinner.show('infoSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this.spinner.hide('infoSpinner');
        }
    }

    saveFormData() {
        const keys = Object.keys(this.infoFormData.controls);
        let info: any = {};
        for (let key of keys) {
            let value = this.infoFormData.controls[key].value; //this.info[key as keyof Info];
            try {
                if (moment.isMoment(value)) {
                    value = value.toDate();
                }
                info![key as keyof Info] = value;
            } catch (error) {}
        }
        info!['date_time_modified'] = new Date();
        this.info = info;
        // console.log(this.info);
    }

    async saveChanges() {
        this.showLoading(true);
        this.saveFormData();
        if (this.infoId) {
            const result: boolean = await this._apiService.updateDocument(
                'info',
                this.infoId,
                this.info
            );
            if (result) {
                this._utilsService.openSnackBar('Información actualizada correctamente');
            } else {
                this._utilsService.openSnackBar('Error actualizando info', 'error');
            }
        } else {
            try {
                const company = localStorage.getItem('company');
                const manager = localStorage.getItem('manager');
                this.info!.company = parseInt(company!);
                this.info!.manager = parseInt(manager!);
                const infoId = await this._apiService.addDocument('info', this.info);
                if (infoId) {
                    this.info!.id = infoId;
                    this._utilsService.openSnackBar('Información añadido correctamente');
                } else {
                    this._utilsService.openSnackBar('Error añadiendo info', 'error');
                    this.showLoading(false);
                    this._utilsService.closeInfoDialog(undefined);
                    return;
                }
            } catch (error) {
                this._utilsService.openSnackBar('Error añadiendo info', 'error');
                this.showLoading(false);
                this._utilsService.closeInfoDialog(undefined);
                return;
            }
        }
        this.showLoading(false);
        this._utilsService.closeInfoDialog(this.info!);
    }
}

<google-map
    height="100%"
    width="100%"
    [zoom]="zoom"
    [center]="center"
    [options]="options"
    (mapClick)="click($event)"
    (mapDblclick)="mapDblclick($event)"
>
    <map-marker
        #markerElem
        *ngFor="let marker of markers; let i = index"
        [position]="marker.getPosition()!"
        [title]="marker.getTitle()!"
        [options]="markerOptionsMap.get(marker.getTitle()!.toString())!"
        (mapClick)="onClickedMarker(marker)"
        (mapDragend)="onDragEnd($event, marker)"
    >
    </map-marker>

    <map-info-window>Hello Google Maps</map-info-window>
</google-map>

<div class="search-input">
    <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Ubicación</mat-label>
        <input
            type="text"
            placeholder="Seleccione lugar ..."
            aria-label="Number"
            matInput
            [formControl]="inputSearchControl"
            [matAutocomplete]="auto"
        />
        <mat-autocomplete #auto="matAutocomplete">
            <mat-option
                *ngFor="let prediction of placePredictions"
                [value]="prediction.description"
                [matTooltip]="prediction.description"
                (click)="searchPlace(prediction)"
            >
                {{ prediction.description }}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</div>

<ngx-spinner
    [bdColor]="zone ? 'rgba(0, 0, 0, 0.3)' : 'rgba(255, 255, 255, 1)'"
    size="large"
    color="#43B0FF"
    type="ball-scale-multiple"
    [fullScreen]="true"
    name="mapSpinner"
>
</ngx-spinner>

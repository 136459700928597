<!-- app-image-carousel.component.html -->
<div class="overlay" *ngIf="images && images.length > 0">
    <div class="background-blur"></div>
    <div class="carousel-container">
        <button class="download-btn" (click)="download()">
            <mat-icon color="primary">download</mat-icon>
        </button>
        <button class="close-btn" (click)="closeCarousel()">&#10005;</button>
        <div class="carousel">
            <button class="control prev" (click)="previousSlide()">
                <mat-icon>chevron_left</mat-icon>
            </button>
            <div class="slides" [style.transform]="'translateX(' + (-currentIndex * 100) + '%)'">
                <div class="slide" *ngFor="let image of images">
                <img [src]="image" alt="Carousel Image" class="d-block w-100">
                </div>
            </div>
            <button mat-icon-button class="control next" (click)="nextSlide()">
                <mat-icon>chevron_right</mat-icon>
            </button>
        </div>
        <div class="indicators">
            <span *ngFor="let image of images; let i = index"
                    [class.active]="i === currentIndex"
                    (click)="goToSlide(i)"></span>
        </div>
    </div>
</div>

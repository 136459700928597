<footer [ngClass]="{ 'footer-margined': !showPaginator }" style="background-color: transparent;">
    <div *ngIf="imageLogo" class="image_logo" (click)="showCompanyAndManager()">
        <img
            [src]="imageLogo"
            id="manager_logo"
            alt="manager"
            
            onerror="this.onerror=null; this.src='assets/img/noimage.png'"
            [matTooltip]="_utilsService.getCompanyAndManagerText()"
        />
    </div>

    <section [ngClass]="{ 'section-rounded': !showPaginator }">
        <div class="example-button-row">
            <div class="example-flex-container">
                <div *ngIf="showMapButton" class="example-button-container">
                    <button
                        mat-fab
                        color="primary"
                        aria-label="Mostrar mapa de proximidad"
                        matTooltip="Mostrar en mapa de proximidad"
                        (click)="openMaps()"
                    >
                        <mat-icon>place</mat-icon>
                    </button>
                </div>
                <div *ngIf="showExcelButton" class="example-button-container">
                    <button
                        mat-fab
                        class="excel-color"
                        aria-label="Exportar excel"
                        matTooltip="Exportar tabla a excel"
                        (click)="exportTable()"
                    >
                        <img src="assets/img/microsoft-excel.svg" alt="excel icon" />
                    </button>
                </div>
                <div *ngIf="showNewButton && !_utilsService.isClientUser()" class="example-button-container">
                    <button
                        mat-fab
                        class="white-color"
                        aria-label="Example icon button with a home icon"
                        matTooltip="Añadir nueva"
                        (click)="addRow()"
                    >
                        <mat-icon color="primary">note_add</mat-icon>
                    </button>
                </div>

                <div *ngIf="showDeleteButton && !_utilsService.isClientUser()" class="example-button-container">
                    <button
                        mat-fab
                        class="danger-color"
                        aria-label="Example icon button with a heart icon"
                        matTooltip="Eliminar filas seleccionadas"
                        (click)="deleteRows()"
                    >
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </section>

    <mat-paginator
        *ngIf="showPaginator"
        [length]="length"
        [pageSize]="pageSize"
        [pageIndex]="pageIndex"
        [pageSizeOptions]="pageSizeOptions"
        showFirstLastButtons
        (page)="pageEvent($event)"
    >
    </mat-paginator>
</footer>

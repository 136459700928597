import { FormControl, FormGroup } from '@angular/forms';

export interface Planning {
    planning: string;
    codigo_planning: string;
    date_time_modified: Date;
    id: number;
}

export function getPlanningDisplayColumns(): string[] {
    const displayedColumns: string[] = ['Código planificación', 'Planificación', 'Fecha de Modificación'];
    return displayedColumns;
}

export function getPlanningField(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'Código planificación':
            value = 'codigo_planning';
            break;
        case 'Planificación':
            value = 'planning';
            break;
        case 'Fecha de Modificación':
            value = 'date_time_modified';
            break;
    }
    return value;
}

export function getPlanningFieldName(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'codigo_planning':
            value = 'Código planificación';
            break;
        case 'planning':
            value = 'Planificación';
            break;
        case 'date_time_modified':
            value = 'Fecha de Modificación';
            break;
    }
    return value;
}

export function getPlanningFormControls(): FormGroup {
    const planningFormData: FormGroup = new FormGroup({
        planning: new FormControl(),
        codigo_planning: new FormControl(),
        date_time_modified: new FormControl(),
        id: new FormControl(),
    });
    return planningFormData;
}

export function getPlanningFieldType(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'codigo_planning':
            value = 'string';
            break;
        case 'planning':
            value = 'string';
            break;
        case 'date_time_modified':
            value = 'Date';
            break;
        case 'id':
            value = 'number';
            break;
    }
    return value;
}

export function getPlanningExcelFieldName(excel_field: string): string {
    let value: string = '';
    switch (excel_field.trim().toLowerCase()) {
        case 'planificación':
            value = 'planning';
            break;
        case 'planificacion':
            value = 'planning';
            break;
        case 'código planificación':
            value = 'codigo_planning';
            break;
        case 'codigo planificacion':
            value = 'codigo_planning';
            break;
        case 'fecha de modificación':
            value = 'date_time_modified';
            break;
        case 'fecha de modificacion':
            value = 'date_time_modified';
            break;
        case 'codigo_planning':
            value = 'codigo_planning';
            break;
        case 'date_time_modified':
            value = 'date_time_modified';
            break;
    }
    return value;
}

export function getPlanningExcelExportColumns(): string[] {
    const columns = ['Código planificación', 'Planificación', 'Fecha de Modificación'];
    return columns;
}

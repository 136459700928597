

<mat-dialog-content class=" animate__animated animate__fadeIn">
    <div [formGroup]="teamFormData"
    class=" animate__animated animate__fadeIn">

        <div>

            <div class="container">

                <div class="row bd-highlight" class="row-content-center">

                    <div>
                        <div style="margin-top: 5px; margin-bottom: 30px; margin-left: 5px;">                        
                            <p style="font-size: large;">Datos de equipo</p>
                        </div> 
                        <div>

                            <div class="row-content">

                                <mat-form-field style="width: 120px;" appearance="fill">
                                    <mat-label>Código</mat-label>
                                    <input formControlName="codigo_equipo_operario" matInput type="text" placeholder="Código...">
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Equipo</mat-label>
                                    <input formControlName="equipo_operario" matInput type="text" placeholder="Equipo...">
                                </mat-form-field>

                            </div>

                            <div class="row-content">

                                <mat-form-field style="flex: 3;" appearance="fill">
                                    <mat-label>Operarios</mat-label>
                                    <input [value]="teamFormData.get('operarios')?.value | user"
                                        matInput type="text" placeholder="Operarios" readonly>
                                    <button matTooltip="Añadir operarios" matSuffix mat-icon-button aria-label="Open users selection"
                                    (click)="addUsers();">
                                        <mat-icon color="primary">edit</mat-icon>
                                    </button>
                                </mat-form-field> 
                                
                            </div>

                            <div class="row-content">

                                <mat-form-field style="flex: 3;" appearance="fill">
                                    <mat-label>Descripción</mat-label>
                                    <textarea matInput formControlName="descripcion"
                                        placeholder="Descripción..."
                                        cdkTextareaAutosize
                                        #autosize="cdkTextareaAutosize"
                                        cdkAutosizeMinRows="2">
                                    </textarea>
                                </mat-form-field> 

                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>

    <ngx-spinner [bdColor] = "(team)?'rgba(0, 0, 0, 0.2)': 'rgba(255, 255, 255, 1)'" size = "large" color = "#368DCE" 
    type = "ball-scale-multiple" [fullScreen] = "false" name="teamSpinner">
    </ngx-spinner>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="team">Cancelar</button>
    <button mat-button cdkFocusInitial
    matTooltip="Guardar cambios" 
    (click)="saveChanges()"> 
        <mat-icon color="primary">check</mat-icon> {{ (teamId)?'Actualizar':'Añadir' }}
    </button>
</mat-dialog-actions>


<div class="search-input">
    <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Ubicación</mat-label>
        <input
            type="text"
            placeholder="Seleccione lugar ..."
            aria-label="Number"
            matInput
            [formControl]="inputSearchControl"
            [matAutocomplete]="auto"
        />
        <mat-autocomplete #auto="matAutocomplete">
            <mat-option
                *ngFor="let prediction of placePredictions"
                [value]="prediction.description"
                [matTooltip]="prediction.description"
                (click)="searchPlace(prediction)"
            >
                {{ prediction.description }}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</div>

<div style="z-index: 99999">
    <ngx-spinner
        [bdColor]="'rgba(0, 0, 0, 0.3)'"
        size="large"
        color="#43B0FF"
        type="ball-scale-multiple"
        [fullScreen]="true"
        name="mapSpinner"
    >
    </ngx-spinner>
</div>

<mat-dialog-content>
    <google-map
        height="620px"
        width="1000px"
        [zoom]="zoom"
        [center]="center"
        [options]="options"
        (dblclick)="dblclick($event)"
        (mapClick)="click($event)"
        (mapMousemove)="mouseMove($event)"
        #map
    >
        <map-info-window>Hello Google Maps</map-info-window>

        <div
            *ngIf="
                drawingActive &&
                polylineOptionsDrawing &&
                polylineOptionsDrawing.path &&
                polylineOptionsDrawing.path.length > 1
            "
            (click)="toggleDrawing()"
        >
            <map-polygon [options]="polylineOptionsDrawing"></map-polygon>
        </div>
    </google-map>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div *ngIf="!loading">
        <button
            mat-raised-button
            [matTooltip]="drawingActive ? 'Limpiar trazado' : 'Dibujar zona en mapa'"
            class="raise-filter-button"
            style="margin-right: 40px"
            (click)="toggleDrawing()"
        >
            <fa-icon *ngIf="!drawingActive" class="fa-icon-column" [icon]="faPen"></fa-icon>
            <fa-icon *ngIf="drawingActive" class="fa-icon-column" [icon]="faEraser"></fa-icon>
        </button>
    </div>
    <button mat-button mat-dialog-close>Cancelar</button>
    <button mat-button (click)="getDrewZone()">Aceptar</button>
</mat-dialog-actions>

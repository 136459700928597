/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import * as moment from 'moment';
import { Moment } from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import {
    Manager,
    getManagerDisplayColumns,
    getManagerExcelExportColumns,
    getManagerExcelFieldName,
    getManagerField,
    getManagerFieldType,
} from 'src/app/interfaces/manager';
import { ApiService } from 'src/app/services/api.service';
import { MessagingService } from 'src/app/services/messaging.service';
import { UtilsService } from 'src/app/services/utils.service';
import { WindowRefService } from 'src/app/services/window-ref.service';
import * as XLSX from 'xlsx';
import { Company } from '../../../interfaces/company';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-managers',
    templateUrl: './managers.component.html',
    styleUrls: ['./managers.component.scss'],
})
export class ManagersComponent implements OnInit {
    loading: boolean = true;
    managersInPage: Manager[] = [];
    dataSource: MatTableDataSource<Manager> = new MatTableDataSource();

    length = 0; //task count in current table
    pageSize = 200; //limit of query
    lastPageIndex = 0;
    pageSizeOptions: number[] = [10, 50, 100, 200, 500];

    displayedColumns: string[] = getManagerDisplayColumns();
    fixedColumns = [];
    displayedColumnsField: string[] = this.displayedColumns.map((displayedColumn: string) =>
        getManagerField(displayedColumn)
    );
    clickedRows = new Set<Manager>();

    lastSelectedRow: number = -1;

    filteredColumn?: string;
    orderedColumn?: string;

    loadingText = 'Cargando...';

    constructor(
        private _apiService: ApiService,
        private _utilsService: UtilsService,
        private _windowRefService: WindowRefService,
        private _spinner: NgxSpinnerService,
        private router: Router,
        private route: ActivatedRoute,
    ) {}

    async ngOnInit(): Promise<void> {
        await this.getManagers();
    }

    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this._spinner.show('innerSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this._spinner.hide('innerSpinner');
        }
    }

    setManagersInTable(managers: Manager[]) {
        this.managersInPage = managers;
        // this.dataSource.data = managers.slice(0, this.sliceSize);
        this.dataSource.data = managers;
        // this.dataSource = new TableVirtualScrollDataSource(managers);// infiniteScroll
        // console.log(this.dataSource);
        this.showLoading(false);
    }

    async getManagers() {
        this.setManagersInTable(await this.selectManagers());
    }

    /**
     * Reloads the current route.
     */
    reload(): void {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['./'], { relativeTo: this.route });
    }

    async selectManagers() {
        this.showLoading(true);
        const company = localStorage.getItem('company');

        let docs = [];
        try {
            docs = await this._apiService.getDocuments<Manager>(
                `manager`,
                [['company', '==', company]],
                ['codigo_gestor', 'asc']
            );
        } catch (err) {
            console.log('============= err =============');
            console.log(err);
        }
        this.length = docs.length;
        return docs;
    }

    searchValue(event: any) {
        const filterValue: string = event;
        this.dataSource!.filter = filterValue.trim().toLowerCase();
    }

    async deleteManagers() {
        let errorIds = [];
        if (this.clickedRows.size == 0) {
            this._utilsService.openSnackBar('Debe seleccionar al menos una gestor', 'warning');
            return;
        }
        const result = await this._utilsService.openQuestionDialog(
            'Confirmación',
            '¿Desea eliminar las gestores seleccionadas?'
        );
        if (result) {
            this._spinner.show();
            this.loadingText = `Eliminando gestores ...`;
            let rowIndexes = new Set<number>();
            const oldDataSource = this.dataSource!.data;
            this.clickedRows.forEach(async (row) => {
                console.log(row.id);

                const index = this.dataSource!.data.indexOf(row, 0);
                if (index > -1) {
                    rowIndexes.add(index);
                }
            });
            let managers = [];
            let deleteCount = 0;

            const companyId = localStorage.getItem('company');
            const company = await this._apiService.getDocumentSync<Company>('company', companyId!);

            for (let i = 0; i < oldDataSource.length; i++) {
                if (!rowIndexes.has(i)) {
                    managers.push(oldDataSource![i]);
                } else {
                    this.loadingText = `Eliminando gestores ${++deleteCount} de ${rowIndexes.size}`;
                    if (company) {
                        const managers = [];
                        for (const manager of company.managers!) {
                            if (manager.id != oldDataSource[i].id!) managers.push(manager);
                        }
                        company.managers = managers;
                    }
                    await this._apiService.updateDocument('company', companyId!, company);
                    if (
                        !(await this._apiService.deleteDocument(
                            `manager`,
                            oldDataSource[i].id!.toString()
                        ))
                    ) {
                        errorIds.push(oldDataSource[i].id);
                    } else {
                    }
                }
            }
            if (errorIds.length > 0) {
                this._utilsService.openSnackBar(`Hubo errores eliminando gestores`, 'error');
            } else {
                this._utilsService.openSnackBar(`Gestors eliminadas correctamente`);
            }
            this.length -= rowIndexes.size;
            this.setManagersInTable(managers);
            this.clickedRows.clear();
            this._spinner.hide();
        }
    }

    async filterBy(column: string) {
        try {
            this.filteredColumn = getManagerField(column);
            if (
                getManagerFieldType(this.filteredColumn) == 'Date' ||
                getManagerFieldType(this.filteredColumn) == 'Timestamp'
            ) {
                const dates = await this._utilsService.openDateRangeSelectorDialog(
                    'Seleccione rango de fechas'
                );
                this.onDateSelected(dates);
            } else {
                const options: any[] = this.dataSource.data.map(
                    (manager: Manager) => manager[this.filteredColumn as keyof Manager]
                );
                const result = await this._utilsService.openSelectorDialog(
                    'Seleccione opción',
                    options
                );
                if (result) {
                    let docs = [];
                    try {
                        docs = await this._apiService.getDocuments<Manager>(`manager`, [
                            [this.filteredColumn, '==', result],
                        ]);
                    } catch (err) {
                        console.log('============= err =============');
                        console.log(err);
                    }
                    this.length = docs.length;
                    this.setManagersInTable(docs);
                }
            }
        } catch (err) {}
    }
    async onDateSelected(dateRange: Date[]) {
        if (dateRange) {
            const startDate: Moment = moment(dateRange[0]);
            let endDate: Moment = moment(dateRange[1]);
            if (!endDate) {
                endDate = startDate;
            }
            const company = localStorage.getItem('company');
            let docs = [];
            try {
                docs = await this._apiService.getDocuments<Manager>(`manager`, [
                    [['company', '==', company]],
                    [this.filteredColumn!, '>=', startDate.toDate()],
                    [this.filteredColumn!, '<', endDate.toDate()],
                ]);
            } catch (err) {
                console.log('============= err =============');
                console.log(err);
            }
            this.length = docs.length;
            this.setManagersInTable(docs);
        } else {
            this._utilsService.openSnackBar('Rango fechas invalido', 'error');
        }
    }

    async orderBy(event: any) {
        const column = event.column;
        let orderType = event.orderType;
        if (orderType.toLowerCase().includes('asc')) {
            orderType = 'asc';
        } else {
            orderType = 'desc';
        }
        const orderedColumn = getManagerField(column);

        this.showLoading(true);
        const company = localStorage.getItem('company');
        const managers = await this._apiService.getDocuments<Manager>(`manager`, undefined, [
            orderedColumn,
            orderType,
        ]);
        this.setManagersInTable(managers);
    }

    async addNewRow(event: any) {
        const manager = await this._utilsService.openManagerDialog('');
        console.log(manager);
        if (manager) {
            this.dataSource.data.push(manager);
            const managers = this.dataSource.data;
            this.dataSource.data = [];
            this.dataSource.data = managers;
        }
    }
    async doubleClickedRow(row: any) {
        const manager = await this._utilsService.openManagerDialog(row.id);
        if (manager) {
            this.dataSource.data[row.rowIndex] = manager;
            const managers = this.dataSource.data;
            this.dataSource.data = [];
            this.dataSource.data = managers;
        }
    }

    clickedRow(receivedEvent: any) {
        const row = receivedEvent.row;
        const event = receivedEvent.event;
        const rowIndex = receivedEvent.rowIndex;

        const previousRow = this.lastSelectedRow;
        this.lastSelectedRow = rowIndex;

        if (event.button === 0) {
            if (!event.ctrlKey && !event.shiftKey) {
                this.clickedRows.clear();
                this.toggleRow(row);
                // console.log('**************** !event.ctrlKey && !event.shiftKey ***************');
            } else if (event.ctrlKey) {
                this.toggleRow(row);
                // console.log('**************** event.ctrlKey ***************');
            }
            if (event.shiftKey) {
                this.selectRowsBetweenIndexes(previousRow, rowIndex);
                console.log('**************** event.shiftKey ***************');
            }
        }
        if (this._windowRefService.nativeWindow.getSelection) {
            //remove selection in table with shift
            if (this._windowRefService.nativeWindow.getSelection().empty) {
                // Chrome
                this._windowRefService.nativeWindow.getSelection().empty();
            } else if (this._windowRefService.nativeWindow.getSelection().removeAllRanges) {
                // Firefox
                this._windowRefService.nativeWindow.getSelection().removeAllRanges();
            }
        }
    }
    selectRowsBetweenIndexes(lastSelectedRow: number, rowIndex: number) {
        let start, end;
        if (rowIndex > lastSelectedRow) {
            start = lastSelectedRow;
            end = rowIndex;
        } else {
            end = lastSelectedRow;
            start = rowIndex;
        }
        for (let i = start; i <= end; i++) {
            this.clickedRows.add(this.dataSource!.data[i]);
        }
    }
    toggleRow(row: any) {
        if (this.clickedRows.has(row)) {
            this.clickedRows.delete(row);
        } else {
            this.clickedRows.add(row);
        }
    }

    exportManagersInTable() {
        this.exportExcel(this.dataSource!.data);
    }
    exportExcel(waterTasks: any) {
        let excelFormatTasks = [];
        for (let waterTask of waterTasks) {
            let task: any = {};
            const columns = getManagerExcelExportColumns();
            columns.forEach((column) => {
                task[`${column}`] = this._utilsService.tableDataPipe(
                    waterTask[getManagerExcelFieldName(column)]
                );
            });
            excelFormatTasks.push(task);
        }
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelFormatTasks);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        /* save to file */
        XLSX.writeFile(wb, 'Gestors_Exportados.xlsx');
    }
}

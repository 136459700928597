<div *ngIf="electronService.isElectronApp()" class="floating-top-left" (click)="location.back()">
    <button mat-fab color="primary">
        <mat-icon style="margin-left: 10px" matTooltip="Ir atrás">arrow_back_ios</mat-icon>
    </button>
</div>

<mat-drawer-container [formGroup]="settingsGroup" [hasBackdrop]="true" class="animate__animated animate__fadeIn">
    <mat-drawer color="primary" #drawer [mode]="'over'" class="drawer-background">
        <app-drawer (sendOpenSettings)="openSettings()">
        </app-drawer>
    </mat-drawer>

    <mat-drawer-content style="overflow-y: hidden;">
        <app-toolbar
            [showCalendar]="false"
            [showCleanFilter]="true"
            [showFilter]="true"
            [marginImage]="false"
            (sendLoading)="showLoading($event)"
            (sendSearchValue)="searchValue($event)"
            (sendToggleDrawer)="drawer.toggle()"
            (sendCompanyAndGestorChanged)="initialSelect()"
            (sendSelectAll)="selectAll()"
        >
        </app-toolbar>

        <div class="row-justify-content-center toolbar-background">
            <div>
                <mat-slide-toggle 
                    formControlName="columnsShiftedEnable"
                    color="primary"
                    >
                    Desplazar horas de día
                </mat-slide-toggle>
                
                <mat-form-field appearance="fill">
                    <mat-label>Fecha</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="date">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>Vista</mat-label>
                    <mat-select formControlName="view">
                        <mat-option value="daily">Diaria</mat-option>
                        <mat-option value="weekly">Semanal</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>Operario</mat-label>
                    <mat-select formControlName="username">
                        <mat-option *ngFor="let username of usernames" [value]="username">{{username}}</mat-option>
                    </mat-select>
                </mat-form-field>  
                
                <div class="legend-container">
                    <div class="legend-item" *ngFor="let status of dateStatuses">
                        <span class="legend-color" [style.background-color]="status.status_color"></span>
                        <span class="legend-label">{{status.status_text}}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex-container custom-flex-container">
            <div class="table-container">
                <div class="inner-table-container">
                    <div
                        *ngIf="!loading && dataSource!.data.length > 0"
                        class="animate__animated animate__fadeIn"
                        class="app-table-container"
                        style="display: flex;"
                        [ngStyle]="{'justify-content': screenWidth > displayedColumns.length*81 ? 'center' : 'start'}"
                        infiniteScroll
                        [infiniteScrollDistance]="5"
                        [infiniteScrollThrottle]="rowsLimit"
                        (scrolled)="onScroll()"
                        [scrollWindow]="false"
                    >
                        <app-table
                            [dataSource]="dataSource"
                            [hoursList]="hoursList"
                            [tableName]="tableName"
                            [displayedColumns]="displayedColumns"
                            [displayedColumnsField]="displayedColumnsField"
                            [clickedRows]="clickedRows"
                            [tableStyle]="'width: ' + displayedColumns.length*81 + 'px;'"
                            [menuOptions]="menuOptions"
                            (sendFilterBy)="filterBy($event)"
                            (sendOrderBy)="orderBy($event)"
                            (sendDoubleClickedRow)="doubleClickedRow($event)"
                            (sendClickedRow)="clickedRow($event)"
                            (sendMenuOptionSelected)="onMenuOptionSelected($event)"
                            (sendSelectedRows)="selectedRows($event)"
                            (sendReload)="reload()"
                        >
                        </app-table>
                    </div>
                </div>
            </div>
        </div>

        <ngx-spinner
            bdColor="rgba(0, 0, 0, 0)"
            size="large"
            color="#368DCE"
            type="ball-scale-multiple"
            [fullScreen]="false"
            name="innerSpinner"
        >
        </ngx-spinner>

        <app-footer
            [length]="length"
            [pageSize]="pageSize"
            [pageIndex]="lastPageIndex"
            [pageSizeOptions]="pageSizeOptions"
            [showMapButton]="true"
            [showNewButton]="false"
            [showDeleteButton]="false"
            [showExcelButton]="false"
            (sendOpenMaps)="openMaps()"
            (sendPageEvent)="pageEvent($event)"
        >
        </app-footer>

        <div *ngIf="!loading && dataSource!.data.length == 0" class="center-child">
            <div style="text-align: center">
                <fa-icon style="font-size: 80px; color: #777777" [icon]="faInbox"></fa-icon>
            </div>
            <div style="margin: 15px">
                <span style="font-size: 20px; color: #777777">No hay tareas para mostrar</span>
            </div>
        </div>

        <ngx-spinner
            bdColor="rgba(0, 0, 0, 0)"
            size="large"
            color="#368DCE"
            type="ball-scale-multiple"
            [fullScreen]="false"
            name="innerSpinner"
        >
        <div
            *ngIf="_utilsService.resposeError"
            class="reload-loading-button animate__animated animate__fadeInUp"
            (click)="reload()"
        >
            <button mat-icon-button matTooltip="Reintentar" color="primary">
                <mat-icon style="font-size: 25px; color: white">refresh</mat-icon>
            </button>
        </div>
        <div
            *ngIf="_utilsService.resposeError"
            class="close-loading-button animate__animated animate__fadeInDown"
            (click)="showLoading(false)"
        >
            <button mat-icon-button matTooltip="Cancelar petición" color="primary">
                <mat-icon style="font-size: 25px; color: white">close</mat-icon>
            </button>
        </div>
        </ngx-spinner>

    </mat-drawer-content>
</mat-drawer-container>

<ngx-spinner
    bdColor="rgba(0, 0, 0, 0.1)"
    size="large"
    color="#368DCE"
    type="square-jelly-box"
    [fullScreen]="true"
>
    <p class="loading-text-background" style="margin-top: 50px">{{ loadingText }}</p>
</ngx-spinner>

/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UtilsService } from 'src/app/services/utils.service';
import { FilterComponent } from '../share/filter/filter.component';

@Component({
    selector: 'app-splash',
    templateUrl: './splash.component.html',
    styleUrls: ['./splash.component.scss'],
})
export class SplashComponent implements OnInit {
    filterDialogRef!: MatDialogRef<FilterComponent, any>;

    data: any;

    constructor(
        private router: Router,
        public utilsService: UtilsService,
        public filterDialog: MatDialog
    ) {}

    ngOnInit(): void {
        setTimeout(async () => {
            this.router.navigate(['login']);
        }, 1000);
    }
}

import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '../services/utils.service';

@Pipe({
    name: 'managerPipe',
})
export class ManagerPipe implements PipeTransform {
    constructor(private _utilsService: UtilsService) {}

    transform(value: string): string {
        return this._utilsService.managerPipe(value);
    }
}

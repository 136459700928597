
<mat-dialog-content class=" animate__animated animate__fadeIn">
    <div [formGroup]="causeFormData"
    class=" animate__animated animate__fadeIn">

        <div>

            <div class="container">

                <div class="row bd-highlight" class="row-content-center">

                    <div>
                        <div style="margin-top: 5px; margin-bottom: 30px; margin-left: 5px;">                        
                            <p style="font-size: large;">Datos de causa</p>
                        </div> 
                        <div>

                            <div class="row-content">

                                <mat-form-field style="max-width: 60px;" appearance="fill">
                                    <mat-label>Código</mat-label>
                                    <input formControlName="codigo_causa" matInput type="text" placeholder="Código...">
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Causa</mat-label>
                                    <input formControlName="causa" matInput type="text" placeholder="Causa...">
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>A realizar</mat-label>
                                    <input formControlName="arealizar" matInput type="text" placeholder="A realizar...">
                                </mat-form-field>
                                <mat-form-field style="width: 80px;" appearance="fill">
                                    <mat-label>Tipo tarea</mat-label>
                                    <input formControlName="tipo_tarea" matInput type="text" placeholder="Tipo tarea...">
                                </mat-form-field>

                            </div>

                            <div class="row-content">

                                <mat-form-field appearance="fill">
                                    <mat-label>Acción ordenada</mat-label>
                                    <input formControlName="accion_ordenada" matInput type="text" placeholder="Acción ordenada...">
                                </mat-form-field>
                                <mat-form-field style="max-width: 140px;" appearance="fill">
                                    <mat-label>Dependencia calibre</mat-label>
                                    <mat-select formControlName="dependencia_calibre">
                                      <mat-option *ngFor="let option of [true, false]" [value]="option">{{ (option)?'Si':'No' }}</mat-option>
                                    </mat-select>
                                </mat-form-field> 
                                <mat-form-field appearance="fill">
                                    <mat-label>Resultados posibles</mat-label>
                                    <mat-select formControlName="resultados_posibles" multiple>
                                    <mat-option *ngFor="let option of results" [value]="option">{{ option }}</mat-option>
                                    </mat-select>
                                </mat-form-field> 

                            </div>

                            <div class="row-content">

                                <mat-form-field appearance="fill">
                                    <mat-label>Pautas ejecución</mat-label>
                                    <textarea matInput formControlName="pautas_ejecucion"
                                        placeholder="Pautas ejecución..."
                                        cdkTextareaAutosize
                                        #autosize="cdkTextareaAutosize"
                                        cdkAutosizeMinRows="2">
                                    </textarea>
                                </mat-form-field> 

                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>

    <ngx-spinner [bdColor] = "(cause)?'rgba(0, 0, 0, 0.2)': 'rgba(255, 255, 255, 1)'" size = "large" color = "#368DCE" 
    type = "ball-scale-multiple" [fullScreen] = "false" name="causeSpinner">
    </ngx-spinner>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="cause">Cancelar</button>
    <button mat-button cdkFocusInitial
    matTooltip="Guardar cambios" 
    (click)="saveChanges()"> 
        <mat-icon color="primary">check</mat-icon> {{ (causeId)?'Actualizar':'Añadir' }}
    </button>
</mat-dialog-actions>

<mat-drawer-container [hasBackdrop]="true" class="animate__animated animate__fadeIn">
    <mat-drawer color="primary" #drawer [mode]="'over'" class="drawer-background">
        <app-drawer
            (sendOpenSettings)="openSettings()"
        >
        </app-drawer>
    </mat-drawer>

    <mat-drawer-content>
        <app-toolbar
            [taskTable]="false"
            [showFilter]="true"
            [showCleanFilter]="true"
            [marginImage]="false"
            (sendLoading)="showLoading($event)"
            (sendSearchValue)="searchValue($event)"
            (sendToggleDrawer)="drawer.toggle()"
            (sendSelectAll)="selectAll()"
            (sendCompanyAndGestorChanged)="getSides()"
            (sendFilter)="customFilterBy($event)"
        >
        </app-toolbar>

        <mat-tab-group class="background"
            [selectedIndex]="selected.value"
            mat-align-tabs="center"
            backgroundColor="primary"
            (selectedIndexChange)="onTabChanged($event)"
        >
            <mat-tab *ngFor="let tab of tabs; let index = index" [label]="tab">
                <ng-template mat-tab-label>
                    <span
                        [matBadge]="length"
                        matBadgeOverlap="false"
                        [matBadgeHidden]="!(selected.value == index) || loading || length >= 1000"
                        >{{ tab }}
                    </span>
                    <div *ngIf="loading && selected.value == index" class="spinner-status">
                        <mat-spinner diameter="20"></mat-spinner>
                    </div>
                    <mat-chip-list *ngIf="!loading && length >= 1000 && selected.value == index">
                        <mat-chip backgroundColor="white" selected>
                            {{ length }}
                        </mat-chip>
                    </mat-chip-list>
                </ng-template>
                <div
                    *ngIf="selected.value == index && !loading && dataSource!.data.length > 0"
                    class="animate__animated animate__fadeIn hide_scroll"
                    class="app-table-container"
                    style="display: flex;"
                    [ngStyle]="{'justify-content': screenWidth > 2200 ? 'center' : 'start'}"
                    infiniteScroll
                    [infiniteScrollDistance]="5"
                    [infiniteScrollThrottle]="rowsLimit"
                    (scrolled)="onScroll()"
                    [scrollWindow]="false"
                >
                    <app-table
                        [dataSource]="dataSource"
                        [tableName]="tableName"
                        [displayedColumns]="displayedColumns"
                        [displayedColumnsField]="displayedColumnsField"
                        [clickedRows]="clickedRows"
                        [tableStyle]="'width: 2200px;'"
                        [menuOptions]="menuOptions"
                        (sendMenuOptionSelected)="onMenuOptionSelected($event)"
                        (sendFilterBy)="filterBy($event)"
                        (sendOrderBy)="orderBy($event)"
                        (sendDoubleClickedRow)="doubleClickedRow($event)"
                        (sendClickedRow)="clickedRow($event)"
                        (sendSelectedRows)="selectedRows($event)"
                        (sendReload)="reload()"
                    >
                    </app-table>
                </div>
            </mat-tab>
        </mat-tab-group>

        <app-footer
            [showDeleteButton]="false"
            [showNewButton]="false"
            [showMapButton]="true"
            [imageLogo]="imageLogo"
            [length]="length"
            [pageSize]="pageSize"
            [pageIndex]="lastPageIndex"
            [pageSizeOptions]="pageSizeOptions"
            (sendOpenMaps)="openMaps()"
            (sendExportTable)="exportSidesInTable()"
            (sendPageEvent)="pageEvent($event)"
        >
        </app-footer>

        <div *ngIf="!loading && dataSource!.data.length == 0" class="center-child">
            <div style="text-align: center">
                <fa-icon style="font-size: 80px; color: #777777" [icon]="faInbox"></fa-icon>
            </div>
            <div style="margin: 15px" class="loading-text-background">
                <span style="font-size: 20px; color: #777777">No hay bandos para mostrar</span>
            </div>
        </div>

        <ngx-spinner
            bdColor="rgba(0, 0, 0, 0)"
            size="large"
            color="#368DCE"
            type="ball-scale-multiple"
            [fullScreen]="false"
            name="innerSpinner"
        >
            <div
                *ngIf="_utilsService.resposeError"
                class="reload-loading-button animate__animated animate__fadeInUp"
                (click)="reload()"
            >
                <button mat-icon-button matTooltip="Reintentar" color="primary">
                    <mat-icon style="font-size: 25px; color: white">refresh</mat-icon>
                </button>
            </div>
            <div
                *ngIf="_utilsService.resposeError"
                class="close-loading-button animate__animated animate__fadeInDown"
                (click)="showLoading(false)"
            >
                <button mat-icon-button matTooltip="Cancelar petición" color="primary">
                    <mat-icon style="font-size: 25px; color: white">close</mat-icon>
                </button>
            </div>
        </ngx-spinner>
    </mat-drawer-content>
</mat-drawer-container>

<ngx-spinner
    bdColor="rgba(0, 0, 0, 0.1)"
    size="large"
    color="#368DCE"
    type="square-jelly-box"
    [fullScreen]="true"
>
    <p class="loading-text-background" style="margin-top: 50px">{{ loadingText }}</p>
</ngx-spinner>

<mat-dialog-content class="mat-typography">
    <form class="example-form">
        <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Añadir texto ...</mat-label>
            <input type="text" [placeholder]="'Inserte '" matInput [formControl]="myControl" />
            <button matSuffix mat-icon-button aria-label="Add message" (click)="addMessage()">
                <mat-icon style="font-size: 18px">add</mat-icon>
            </button>
        </mat-form-field>

        <div style="height: 20rem; overflow-y: auto; overflow-x: hidden">
            <div *ngFor="let text of textList; let i = index">
                <div style="display: flex">
                    <button mat-icon-button aria-label="Delete message" (click)="deleteMessage(i)">
                        <mat-icon style="font-size: 18px; margin-bottom: 30px">delete</mat-icon>
                    </button>
                    <p>
                        {{ text }}
                    </p>
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancelar</button>
    <button mat-button (click)="closeDialog()">Aceptar</button>
</mat-dialog-actions>


<mat-dialog-content>

    <div [formGroup]="taskFormData">

        <div class="container">

            <div class="row bd-highlight" class="row-content-center">

                <mat-card  class="mat-card-block">
                    <mat-card-subtitle style="margin: 6px; margin-bottom: 15px;">                        
                        <p style="font-size: large;">Datos de informe de instalación y servicios</p>
                    </mat-card-subtitle> 
                    <mat-card-content>

                        <div class="row">
                                
                            <mat-form-field style="width: 100px;" appearance="fill">
                                <mat-label>ID SAT</mat-label>
                                <input formControlName="ID_SAT" matInput type="number" placeholder="Id SAT">
                            </mat-form-field>
                            <mat-form-field style="width: 120px;" appearance="fill">
                                <mat-label>Num Abonado</mat-label>
                                <input formControlName="Numero_de_ABONADO" matInput type="text" placeholder="Num Abonado">
                            </mat-form-field>
                            <mat-form-field style="width: 60px;" appearance="fill">
                                <mat-label>Calibre</mat-label>
                                <input formControlName="CALIBREDV" matInput type="text" placeholder="Calibre">
                            </mat-form-field>
                            <mat-form-field style="width: 150px;" appearance="fill">
                                <mat-label>Fecha informe</mat-label>
                                <input matInput [matDatepicker]="picker_fecha_informe" formControlName="fecha_informe_servicios" placeholder="Fecha informe">
                                <mat-datepicker-toggle matSuffix [for]="picker_fecha_informe"></mat-datepicker-toggle>
                                <mat-datepicker #picker_fecha_informe></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field style="width: 150px;" appearance="fill">
                                <mat-label>Fecha Realización</mat-label>
                                <input matInput [matDatepicker]="picker_fecha_realizacion" formControlName="fecha_realizacion" placeholder="Fecha Realización">
                                <mat-datepicker-toggle matSuffix [for]="picker_fecha_realizacion"></mat-datepicker-toggle>
                                <mat-datepicker #picker_fecha_realizacion></mat-datepicker>
                            </mat-form-field>

                        </div>

                    </mat-card-content>
                </mat-card>

            </div>

            <div class="row bd-highlight" class="row-content-center">

                <mat-card  style="flex: 3;">
                    <mat-card-subtitle style="margin: 6px; margin-bottom: 15px;">                        
                        <p style="font-size: large;">Suministros y servicios</p>
                    </mat-card-subtitle> 
                    <mat-card-content>

                        <div class="row">
                                
                            <mat-form-field appearance="fill">
                                <mat-label>Suministros</mat-label>
                                <mat-select formControlName="suministros" multiple>
                                <mat-option *ngFor="let option of suplies" [value]="option">{{ option }}</mat-option>
                                </mat-select>
                            </mat-form-field> 

                        </div>

                        <div class="row">
                                
                            <mat-form-field appearance="fill">
                                <mat-label>Servicios</mat-label>
                                <mat-select formControlName="servicios" multiple>
                                <mat-option *ngFor="let option of services" [value]="option">{{ option }}</mat-option>
                                </mat-select>
                            </mat-form-field> 

                        </div>

                    </mat-card-content>
                </mat-card>

                <mat-card  class="mat-card-block">
                    <mat-card-subtitle style="margin: 6px; margin-bottom: 15px;">                        
                        <p style="font-size: large;">Observaciones</p>
                    </mat-card-subtitle> 
                    <mat-card-content>

                        <div class="row">
                                
                            <mat-form-field appearance="fill">
                                <textarea matInput formControlName="OBSERVA"
                                    cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize"
                                    cdkAutosizeMinRows="1">
                                </textarea>
                            </mat-form-field> 

                        </div>

                    </mat-card-content>
                </mat-card>

            </div>

        </div>

    </div>

</mat-dialog-content>
    
<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false">Cancelar</button>
    <button mat-button cdkFocusInitial
    (click)="closeTask()"> 
        <mat-icon color="primary">check</mat-icon> Cerrar
    </button>
</mat-dialog-actions>

<ngx-spinner [bdColor] = "(task)?'rgba(0, 0, 0, 0.2)': 'rgba(255, 255, 255, 1)'" size = "large" color = "#368DCE" 
type = "ball-scale-multiple" [fullScreen] = "true" name="taskSpinner">
</ngx-spinner>
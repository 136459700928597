<mat-dialog-content [formGroup]="date">
    <div class="date_and_status_block">
        <div *ngIf="currentDateObject && currentDateObject.OPERARIO && currentDateObject.OPERARIO.length" >
            <button mat-raised-button color="primary" (click)="openWeek()">
                Semana de {{ currentDateObject.OPERARIO | user }}
            </button>
        </div>
        <div *ngIf="!is_date_range">
            <mat-form-field appearance="fill">
                <mat-label>{{ placeHolderText }}</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="dateValue" />
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>
        <div *ngIf="is_date_range">
            <mat-form-field appearance="fill">
                <mat-label> {{ placeHolderText }} </mat-label>
                <mat-date-range-input [rangePicker]="rangePicker">
                    <input matStartDate formControlName="start" placeholder="Fecha inicial" />
                    <input matEndDate formControlName="end" placeholder="Fecha final" />
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
                <mat-date-range-picker style="z-index: 999" #rangePicker>
                    <mat-date-range-picker-actions>
                        <button mat-button matDateRangePickerCancel>Cancelar</button>
                        <button mat-raised-button color="primary" matDateRangePickerApply>Aceptar</button>
                    </mat-date-range-picker-actions>
                </mat-date-range-picker>

                <mat-error *ngIf="date.controls['start'].hasError('matStartDateInvalid')"
                    >Invalid start date</mat-error
                >
                <mat-error *ngIf="date.controls['end'].hasError('matEndDateInvalid')"
                    >Invalid end date</mat-error
                >
            </mat-form-field>
        </div>
        <div>
            <mat-form-field *ngIf="show_date_status" appearance="fill">
                <mat-label>Tipo de cita</mat-label>
                <mat-select formControlName="dateStatus">
                    <mat-option *ngFor="let dateStatus of dateStatuses" [value]="dateStatus">
                        <div class="date_status_element">
                            <div [style.backgroundColor]="dateStatus.status_color"></div>
                            {{dateStatus.status_text}}
                        </div>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div class="time_row">
        <div>
            <span>Hora inicial</span>
        </div>
        <ngx-timepicker-field
            [toggleIcon]="icon"
            [format]="24"
            [defaultTime]="minTime"
            [max]="is_date_range ? getMaxRange(): maxTime"
            (timeChanged)="onStartTimeChanged($event)"
        ></ngx-timepicker-field>
        <ng-template #icon>
            <mat-icon>query_builder</mat-icon>
        </ng-template>
    </div>
    
    <div class="time_row">
        <div>
            <span>Hora final</span>
        </div>
        <ngx-timepicker-field
            [toggleIcon]="icon"
            [defaultTime]="maxTime"
            [min]="is_date_range ? getMinRange(): minTime"
            [format]="24"
            (timeChanged)="onEndTimeChanged($event)"
        ></ngx-timepicker-field>
        <ng-template #icon>
            <mat-icon>query_builder</mat-icon>
        </ng-template>
    </div>

    <mat-dialog-content>
        <mat-dialog-actions align="center">
            <button mat-button mat-dialog-close>Cancelar</button>
            <button mat-button cdkFocusInitial [mat-dialog-close]="getDateTimeRange()">Aceptar</button>
        </mat-dialog-actions>
    </mat-dialog-content>
</mat-dialog-content>

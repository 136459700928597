/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faBuilding, faFilePdf, faKey, faInbox } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { ApiService } from '../../../services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessagingService } from 'src/app/services/messaging.service';

import * as moment from 'moment';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Zone, getZoneFormControls } from 'src/app/interfaces/zone';
import { Router } from '@angular/router';

@Component({
    selector: 'app-zone',
    templateUrl: './zone.component.html',
    styleUrls: ['./zone.component.scss'],
})
export class ZoneComponent implements OnInit {
    faFilePdf = faFilePdf;
    faInbox = faInbox;
    faBuilding = faBuilding;
    faKey = faKey;
    loading: boolean = false;
    zoneId: string = '';
    zone?: Zone;

    zoneFormData: FormGroup = getZoneFormControls();

    zoneSubcription$?: Subscription;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _apiService: ApiService,
        private _utilsService: UtilsService,
        private spinner: NgxSpinnerService,
        private router: Router
    ) {
        this.zoneId = data.zoneId;
        // this.activatedRoute.params.subscribe((params)=>{
        //   this.zoneId = params['id'];
        // });
    }

    async ngOnInit(): Promise<void> {
        if (this.zoneId) {
            this.showLoading(true);

            const company = localStorage.getItem('company');
            this.zoneSubcription$ = this._apiService
                .getDocument('zone', this.zoneId)
                .subscribe(async (doc: any) => {
                    if (!doc) {
                        this._utilsService.openSnackBar('Error obteniendo datos de zone', 'error');
                        this.showLoading(false);
                        return;
                    }
                    const zone = doc as Zone;
                    zone.id = parseInt(this.zoneId);

                    this.zone = zone;

                    console.log(this.zone);

                    const zoneFormData = getZoneFormControls();
                    const keys = Object.keys(zone);
                    for (let key of keys) {
                        let value: any = zone[key as keyof Zone];
                        if (value) {
                            if (this._utilsService.isValidDate(value)) {
                                value = value;
                            }
                            zoneFormData.controls[key].setValue(value);
                        }
                    }
                    this.zoneFormData = zoneFormData;

                    this.showLoading(false);
                });
        }
    }

    ngOnDestroy(): void {
        this.zoneSubcription$?.unsubscribe();
    }

    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this.spinner.show('zoneSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this.spinner.hide('zoneSpinner');
        }
    }

    saveFormData() {
        const keys = Object.keys(this.zoneFormData.controls);
        let zone: any = {};
        for (let key of keys) {
            let value = this.zoneFormData.controls[key].value; //this.zone[key as keyof Zone];
            try {
                if (moment.isMoment(value)) {
                    value = value.toDate();
                }
                zone![key as keyof Zone] = value;
            } catch (error) {}
        }
        zone!['date_time_modified'] = new Date();
        this.zone = zone;
        // console.log(this.zone);
    }

    async saveChanges() {
        this.showLoading(true);
        this.saveFormData();
        if (this.zoneId) {
            const company = localStorage.getItem('company');
            const result: boolean = await this._apiService.updateDocument(
                'zone',
                this.zoneId,
                this.zone
            );
            if (result) {
                this._utilsService.openSnackBar('Zona actualizada correctamente');
            } else {
                this._utilsService.openSnackBar('Error actualizando zona', 'error');
            }
        } else {
            try {
                const company = localStorage.getItem('company');
                this.zone!.company = parseInt(company!);
                const zoneId = await this._apiService.addDocument('zone', this.zone);
                if (zoneId) {
                    this.zone!.id = zoneId;
                    this._utilsService.openSnackBar('Zona añadido correctamente');
                } else {
                    this._utilsService.openSnackBar('Error añadiendo zona', 'error');
                }
            } catch (error) {
                this._utilsService.openSnackBar('Error añadiendo zona', 'error');
            }
        }
        this.showLoading(false);
        this._utilsService.closeZoneDialog(this.zone!);
    }

    openZoneLocation() {
        console.log('************* openZoneLocation *************');
        console.log(this.zoneId);
        this.router.navigate(['/zone-location', this.zoneId]);
        this._utilsService.closeZoneDialog(this.zone);
    }
}

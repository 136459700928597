/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { faBuilding, faFilePdf, faKey, faInbox } from '@fortawesome/free-solid-svg-icons';
import { UtilsService } from 'src/app/services/utils.service';
import { NgxSpinnerService } from 'ngx-spinner';

import * as moment from 'moment';
import { Itac, getItacFormControls } from 'src/app/interfaces/itac';
import { ApiService } from 'src/app/services/api.service';
import { Mark } from '../../../interfaces/mark';
import { Caliber } from '../../../interfaces/caliber';
import { Longitude } from '../../../interfaces/longitude';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Zone } from '../../../interfaces/zone';

@Component({
    selector: 'app-itac-assign',
    templateUrl: './itac-assign.component.html',
    styleUrls: ['./itac-assign.component.scss'],
})
export class ItacAssignComponent implements OnInit {
    faFilePdf = faFilePdf;
    faInbox = faInbox;
    faBuilding = faBuilding;
    faKey = faKey;
    loading: boolean = false;
    itac?: any;

    classes?: string[];
    marks?: string[];
    typeItacs?: string[];
    calibers?: string[];
    longitudes?: string[];
    zones: string[] = [];

    assing_to_itacs: boolean = false;
    itacFormData: FormGroup = getItacFormControls();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _utilsService: UtilsService,
        private _apiService: ApiService,
        private spinner: NgxSpinnerService
    ) {}

    async ngOnInit(): Promise<void> {
        this.zones = (await this._apiService.getZones())
            .map((zone: Zone) => `${zone.codigo_zona} - ${zone.zona}`)
            .sort();
    }

    ngOnDestroy(): void {}

    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this.spinner.show('itacSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this.spinner.hide('itacSpinner');
        }
    }

    saveFormData() {
        const keys = Object.keys(this.itacFormData.controls);
        let itac: any = {};
        for (let key of keys) {
            let value = this.itacFormData.controls[key].value;
            try {
                itac![key] = typeof value === 'string' ? value : value.toString();
            } catch (error) {}
        }

        itac!['date_time_modified'] = new Date();
        this.itac = itac;
    }

    async saveChanges() {
        if (!this.itacFormData.valid) {
            this._utilsService.openSnackBar('Rellene todos los campos requeridos', 'error');
            const keys = Object.keys(this.itacFormData.controls);
            for (let key of keys) {
                this.itacFormData.get(key)?.markAsTouched();
                this.itacFormData.get(key)?.markAsDirty();
            }
            return;
        }
        this.showLoading(true);
        this.saveFormData();
        this.showLoading(false);
        console.log('============= itac =============');
        console.log(this.itac);
        this._utilsService.closeItacAssignDialog(this.itac!);
    }
}

/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';

@Component({
    selector: 'app-time-selector',
    templateUrl: './time-selector.component.html',
    styleUrls: ['./time-selector.component.scss'],
})
export class TimeSelectorComponent implements OnInit {
    time = new FormGroup({
        time: new FormControl(),
    });

    timeSelected?: Date;

    constructor() {}

    ngOnInit(): void {}

    onTimeChanged(event: string) {
        console.log('********************************');
        console.log(event);
        const timeString: string = event.replace('a. m.', 'AM').replace('p. m.', 'PM');
        const timeMoment = moment(timeString, 'hh:mm A');
        this.timeSelected = timeMoment.toDate();
        console.log(this.timeSelected);
        console.log('********************************');
    }
}

/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faInbox } from '@fortawesome/free-solid-svg-icons';
import { NgxSpinnerService } from 'ngx-spinner';
import { Part } from 'src/app/interfaces/part';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
    selector: 'app-parts-selection',
    templateUrl: './parts-selection.component.html',
    styleUrls: ['./parts-selection.component.scss'],
})
export class PartsSelectionComponent implements OnInit {
    faInbox = faInbox;
    parts: string[] = [];
    partsInTask: string[] = [];
    loading: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _apiService: ApiService,
        private _utilsService: UtilsService,
        private spinner: NgxSpinnerService
    ) {
        this.partsInTask = data.parts;
    }

    async ngOnInit(): Promise<void> {
        this.showLoading(true);
        (await this._apiService.getParts()).map((part: Part) => {
            this.parts.push(part.pieza!);
        });
        this.parts.sort();
        this.showLoading(false);
    }

    retrieveData() {
        this._utilsService.closePartsSelectionDialog(this.partsInTask);
    }

    showLoading(state: boolean) {
        this.loading = state;
        // console.log('***************** Loading ******************');
        // console.log(this.loading);
        // console.log('***************** Loading ******************');
        if (state) {
            this.spinner.show('spinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this.spinner.hide('spinner');
        }
    }

    async selectPart() {
        try {
            const part = await this._utilsService.openSelectorDialog(
                'Seleccione pieza',
                this.parts
            );
            if (part) {
                this.requestAmount(part);
            }
        } catch (err) {}
    }
    deletePart(partInTask: string) {
        const index = this.partsInTask.indexOf(partInTask);
        this.partsInTask.splice(index, 1);
    }

    async requestAmount(part: string) {
        try {
            let amount = parseInt(
                await this._utilsService.openInputSelectorDialog(
                    'Seleccione cantidad de esta pieza',
                    '1',
                    'number'
                )
            );
            if (amount) {
                const partExisting = this.partsInTask.find((partInTask) =>
                    partInTask.includes(part)
                );
                if (partExisting) {
                    const index = this.partsInTask.indexOf(partExisting);
                    const previousAmount = parseInt(partExisting.split('-')[0].trim());
                    if (previousAmount) {
                        amount += previousAmount;
                        this.partsInTask[index] = `${amount} - ${part}`;
                    }
                } else {
                    this.partsInTask.push(`${amount} - ${part}`);
                }
            }
        } catch (err) {}
    }
}

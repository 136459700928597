/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import * as moment from 'moment';

export interface TimeRangeSelectorData {
    placeHolderTextStart: '';
    placeHolderTextEnd: '';
}

@Component({
    selector: 'app-time-range-selector',
    templateUrl: './time-range-selector.component.html',
    styleUrls: ['./time-range-selector.component.scss'],
})
export class TimeRangeSelectorComponent implements OnInit {
    minTime = '12:00 am';

    range = new FormGroup({
        start: new FormControl(),
        end: new FormControl(),
    });

    placeHolderTextStart?: string = 'Hora inicial';
    placeHolderTextEnd?: string = 'Hora final';
    time_range: Date[] = [];

    darkTheme: NgxMaterialTimepickerTheme = {
        container: {
            bodyBackgroundColor: '#424242',
            buttonColor: '#fff',
        },
        dial: {
            dialBackgroundColor: '#555',
        },
        clockFace: {
            clockFaceBackgroundColor: '#555',
            clockHandColor: '#368DCE',
            clockFaceTimeInactiveColor: '#fff',
        },
    };

    constructor(@Inject(MAT_DIALOG_DATA) public data: TimeRangeSelectorData) {
        this.placeHolderTextStart = data.placeHolderTextStart;
        this.placeHolderTextEnd = data.placeHolderTextEnd;
    }

    ngOnInit(): void {}

    onStartTimeChanged(event: any) {
        const startTimeString: string = event.replace('a. m.', 'AM').replace('p. m.', 'PM');
        const startTime = moment(startTimeString, 'hh:mm A');
        this.time_range[0] = startTime.toDate();
        this.minTime = startTime.format('hh:mm A');
    }
    onEndTimeChanged(event: any) {
        const endTime: string = event.replace('a. m.', 'AM').replace('p. m.', 'PM');
        this.time_range[1] = moment(endTime, 'hh:mm A').toDate();
    }

    onSelectedTime() {}
}

<div *ngIf="!chageSuccess" class="background animate__animated animate__fadeIn animate__slow">
    <!-- <img src='assets/img/background.svg' style='position: absolute; top: -20px; width: 90%; height: 90%; object-fit: fill;'> -->

    <mat-card class="center-child">
        <mat-card-title>
            <div class="child-content">
                <p>
                    <img src="assets/img/logo.svg" width="130" alt="" />
                </p>
            </div>
        </mat-card-title>

        <mat-card-content [formGroup]="formData">
            <div class="child">
                <div class="div-margin">
                    <div class="" style="text-align: center">
                        <div>
                            <mat-form-field appearance="outline">
                                <mat-label>Nueva contraseña</mat-label>
                                <input
                                    #newPassword
                                    formControlName="newPassword"
                                    matInput
                                    type="password"
                                    placeholder="Nueva contraseña"
                                    minlength="6"
                                    required
                                />
                                <mat-icon matSuffix style="color: #555555">lock</mat-icon>
                                <!-- <mat-hint>Hint</mat-hint> -->
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field appearance="outline">
                                <mat-label>Repita la contraseña</mat-label>
                                <input
                                    #repeatedPassword
                                    formControlName="repeatedPassword"
                                    matInput
                                    type="password"
                                    placeholder="Repita al contraseña"
                                    minlength="6"
                                    required
                                />
                                <mat-icon matSuffix style="color: #555555">lock</mat-icon>
                                <!-- <mat-hint>Hint</mat-hint> -->
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>

        <mat-card-footer>
            <div>
                <p style="text-align: center">
                    <button
                        mat-raised-button
                        color="primary"
                        class="button"
                        (click)="setNewPassword(newPassword.value, repeatedPassword.value)"
                    >
                        Cambiar contraseña
                    </button>
                </p>
            </div>
        </mat-card-footer>
    </mat-card>
</div>

<div *ngIf="chageSuccess" class="background animate__animated animate__fadeIn animate__slow">
    <div class="center-child div-success">
        <div style="display: flex">
            <img src="assets/img/lock-check.svg" width="50" />
            <div style="font-size: 22px; padding: 15px; color: rgba(90, 90, 90)">
                Contraseña cambiada correctamente
            </div>
        </div>
    </div>
</div>

<ngx-spinner
    bdColor="rgba(0, 0, 0, 0.1)"
    size="large"
    color="#368DCE"
    type="square-jelly-box"
    [fullScreen]="true"
>
    <p class="loading-text-background" style="margin-top: 50px">Cambiando contraseña...</p>

    <div
        *ngIf="_utilsService.resposeError"
        class="close-loading-button animate__animated animate__fadeInDown"
        style="top: 95px; left: 45px"
        (click)="showLoading(false)"
    >
        <button mat-icon-button matTooltip="Cancelar petición" color="primary">
            <mat-icon style="font-size: 25px; color: white">close</mat-icon>
        </button>
    </div>
</ngx-spinner>
`

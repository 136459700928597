/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { MyLatLng } from './lat-lng';
import { FormGroup, FormControl } from '@angular/forms';

export interface ActivationLog {
    ID?: number;
    id?: number;
    counter_serial_number?: string;
    counter_serial_module?: string;
    error_code?: number;
    request?: string;
    response?: string;
    response_es?: string;
    geolocation?: MyLatLng;
    user_id?: number;
    company?: number;
    date_time_modified?: Date;
}

export function getActivationLogDisplayColumns(): string[] {
    const displayedColumns: string[] = [
        'ID',
        'Fecha',
        'Número de serie',
        'Número de módulo',
        'Activación',
        'Respuesta',
        'Usuario activador',
        'Ubicación',
    ];
    return displayedColumns;
}

export function getActivationLogField(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'ID':
            value = 'ID';
            break;
        case 'id':
            value = 'id';
            break;
        case 'Fecha':
            value = 'date_time_modified';
            break;
        case 'Número de serie':
            value = 'counter_serial_number';
            break;
        case 'Número de módulo':
            value = 'counter_serial_module';
            break;
        case 'Activación':
            value = 'error_code';
            break;
        case 'Respuesta':
            value = 'response_es';
            break;
        case 'Usuario activador':
            value = 'user_id';
            break;
        case 'Ubicación':
            value = 'geolocation';
            break;
    }
    return value;
}

export function getActivationLogFieldName(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'ID':
            value = 'ID';
            break;
        case 'date_time_modified':
            value = 'Fecha';
            break;
        case 'counter_serial_number':
            value = 'Número de serie';
            break;
        case 'counter_serial_module':
            value = 'Número de módulo';
            break;
        case 'error_code':
            value = 'Activación';
            break;
        case 'response_es':
            value = 'Respuesta';
            break;
        case 'user_id':
            value = 'Usuario activador';
            break;
        case 'geolocation':
            value = 'Ubicación';
            break;
    }
    return value;
}

export function getActivationLogFormControls(): FormGroup {
    const activationLogFormData: FormGroup = new FormGroup({
        codigo_empresa: new FormControl(),
        id: new FormControl(),
        counter_serial_number: new FormControl(),
        counter_serial_module: new FormControl(),
        error_code: new FormControl(),
        request: new FormControl(),
        response: new FormControl(),
        response_es: new FormControl(),
        geolocation: new FormControl(),
        user_id: new FormControl(),
        company: new FormControl(),
        date_time_modified: new FormControl(),
    });
    return activationLogFormData;
}

export function getActivationLogFieldType(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'id':
            value = 'number';
            break;
        case 'counter_serial_number':
            value = 'string';
            break;
        case 'counter_serial_module':
            value = 'string';
            break;
        case 'error_code':
            value = 'number';
            break;
        case 'request':
            value = 'string';
            break;
        case 'response':
            value = 'string';
            break;
        case 'response_es':
            value = 'string';
            break;
        case 'geolocation':
            value = 'string';
            break;
        case 'user_id':
            value = 'number';
            break;
        case 'date_time_modified':
            value = 'Date';
            break;
    }
    return value;
}

export function getActivationLogExcelFieldName(excel_field: string): string {
    let value: string = '';
    switch (excel_field.trim().toLowerCase()) {
        case 'Fecha':
            value = 'date_time_modified';
            break;
        case 'Número de serie':
            value = 'counter_serial_number';
            break;
        case 'Número de módulo':
            value = 'counter_serial_module';
            break;
        case 'Activación':
            value = 'error_code';
            break;
        case 'Respuesta':
            value = 'response_es';
            break;
        case 'Usuario activador':
            value = 'user_id';
            break;
        case 'Ubicación':
            value = 'geolocation';
            break;
    }
    return value;
}

export function getActivationLogExcelExportColumns(): string[] {
    const columns = [
        'Fecha',
        'Número de serie',
        'Número de módulo',
        'Activación',
        'Respuesta',
        'Usuario activador',
        'Ubicación',
    ];
    return columns;
}

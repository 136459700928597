
<mat-drawer-container [hasBackdrop]="true" class="animate__animated animate__fadeIn"
>
    <mat-drawer color="primary" #drawer [mode]="'over'" class="drawer-background">
        <app-drawer>
        </app-drawer>
    </mat-drawer>

    <mat-drawer-content class="background">

        <app-toolbar
        [marginImage] = "false"
        (sendLoading) = "showLoading($event)"
        (sendSearchValue) = "searchValue($event)"
        (sendToggleDrawer) = "drawer.toggle()"
        (sendInfoAndInformaciónChanged) = "getInfos()"
        >          
        </app-toolbar>
        
        <div class="flex-container" style="background-color: transparent !important;">
            <div class="table-container">
                <div class="inner-table-container">
                    <div class="app-table-container" style="display: flex; justify-content: center;">
                        <app-table *ngIf="!loading && (dataSource!.data.length > 0)" class="animate__animated animate__fadeIn" style="margin: 20px;"
                            [dataSource] = "dataSource"
                            [displayedColumns] = "displayedColumns"
                            [displayedColumnsField] = "displayedColumnsField"
                            [clickedRows] = "clickedRows"
                            [tableStyle] = "'width: 1100px;'"
                            (sendFilterBy) = "filterBy($event)"
                            (sendOrderBy) = "orderBy($event)"
                            (sendDoubleClickedRow) = "doubleClickedRow($event)"
                            (sendClickedRow) = "clickedRow($event)"
                            >
                        </app-table>
                    </div>
                </div>                
            </div>
        </div>

        <ngx-spinner bdColor = "rgba(0, 0, 0, 0)" size = "large" color = "#368DCE" 
        type = "ball-scale-multiple" [fullScreen] = "false" name="innerSpinner">
        </ngx-spinner>

        <app-footer
            [showPaginator]="false"
            [length]="length"
            [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions" 
            [showMapButton] = "false"
            (sendExportTable)="exportInfosInTable()"
            (sendAddRow)="addNewRow($event)"
            (sendDelete)="deleteInfos()"
            >        
        </app-footer>

    </mat-drawer-content>

</mat-drawer-container>


<ngx-spinner bdColor = "rgba(0, 0, 0, 0.1)" size = "large" color = "#368DCE" 
type = "square-jelly-box" [fullScreen] = "true">
    <p class="loading-text-background" style="margin-top: 50px;"> {{ loadingText }} </p>
</ngx-spinner>

/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import {
    getWaterRouteExcelFieldName,
    getWaterRouteDisplayColumns,
    getWaterRouteField,
    getWaterRouteFieldType,
    WaterRoute,
    getWaterRouteExcelExportColumns,
} from 'src/app/interfaces/water-route';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { WindowRefService } from 'src/app/services/window-ref.service';
import * as XLSX from 'xlsx';
import { MySqlService } from '../../services/mysql.service';
import { MatTableDataSource } from '@angular/material/table';
import { faInbox } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-water-routes',
    templateUrl: './water-routes.component.html',
    styleUrls: ['./water-routes.component.scss'],
})
export class WaterRoutesComponent implements OnInit {
    loading: boolean = true;
    waterRoutes: WaterRoute[] = [];
    dataSource: MatTableDataSource<WaterRoute> = new MatTableDataSource();

    length = 0; //task count in current table
    pageSize = 50; //limit of query
    scrollOffset: number = 50;
    rowsLimit: number = 50;
    lastPageIndex = 0;
    pageSizeOptions: number[] = [10, 50, 100, 200, 500];

    tableName: string = 'waterRoutes';
    displayedColumns: string[] = [];
    fixedColumns = [];
    displayedColumnsField: string[];
    clickedRows = new Set<WaterRoute>();

    lastSelectedRow: number = -1;

    filteredColumn?: string;
    orderedColumn?: string;

    loadingText = 'Cargando...';

    faInbox = faInbox;

    async onScroll() {
        this.scrollOffset += this.rowsLimit;
        if (this.scrollOffset > this.pageSize) return;
        this.dataSource.data = [];
        this.dataSource.data = this.waterRoutes.slice(0, this.scrollOffset);
    }

    constructor(
        private _apiService: ApiService,
        private _mySqlService: MySqlService,
        private _utilsService: UtilsService,
        private _windowRefService: WindowRefService,
        private router: Router,
        private route: ActivatedRoute,
        private _spinner: NgxSpinnerService
    ) {
        this.pageSize = parseInt(
            localStorage.getItem('waterRoute_pageSize') || `${this.rowsLimit}`
        );

        this.displayedColumns = this._utilsService.setDisplayColumns(
            this.displayedColumns,
            this.tableName,
            getWaterRouteDisplayColumns,
        );

        this.displayedColumnsField = this.displayedColumns.map((displayedColumn: string) =>
            getWaterRouteField(displayedColumn)
        );
        document.addEventListener('visibilitychange', async () => {
            if (document.hidden) {
            } else {
                const updateNeeded = localStorage.getItem('waterRouteUpdateNeeded');
                console.log(updateNeeded);
                if (updateNeeded == 'true') {
                    this.scrollOffset = 50;
                    localStorage.setItem('waterRouteUpdateNeeded', 'false');
                    this.showLoading(true);
                    this.setWaterRoutesInTable(await this._mySqlService.getLastWaterRoutesPage());
                    this.showLoading(false);
                }
            }
        });
    }

    async ngOnInit(): Promise<void> {
        await this.getWaterRoutes();
    }

    reload() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['./'], { relativeTo: this.route });
    }

    async fileEvent(event: any) {
        if (event['file_option'] == 'Importar rutas') {
            this.processExcelFile(event);
        }
    }
    async processExcelFile(event: any) {
        this._spinner.show();
        this.loadingText = `Cargando ...`;
        //TODO: add waterRoutes from excel
        let workBook: XLSX.WorkBook;
        let jsonData = null;
        const reader = new FileReader();
        const file = event['file'].target.files[0];
        reader.onload = async (_) => {
            this._spinner.show();
            this.loadingText = `Procesando rutas ...`;
            const data = reader.result;
            workBook = XLSX.read(data, { type: 'binary' });
            jsonData = workBook.SheetNames.reduce((initial: any, name) => {
                const sheet = workBook.Sheets[name];
                initial[name] = XLSX.utils.sheet_to_json(sheet);
                return initial;
            }, {});
            let sheets: string[] = [];
            let arrayWaterRoutes: WaterRoute[] = [];

            Object.keys(jsonData).forEach((key: string) => {
                sheets.push(key);
            });
            for (let sheet of sheets) {
                for (let jsonWaterRoute of jsonData[sheet]) {
                    let waterRouteData: any = {};
                    Object.keys(jsonWaterRoute).forEach((key) => {
                        let field: string = getWaterRouteExcelFieldName(key);
                        if (this._utilsService.isFieldValid(field)) {
                            let value = jsonWaterRoute[key];
                            try {
                                if (typeof value === 'number') {
                                    if (getWaterRouteFieldType(field) == 'number') {
                                        waterRouteData[`${field}`] = value;
                                    } else {
                                        waterRouteData[`${field}`] = value.toString().trim();
                                    }
                                } else if (
                                    typeof value === 'string' &&
                                    this._utilsService.isFieldValid(value)
                                ) {
                                    if (getWaterRouteFieldType(field) == 'string') {
                                        waterRouteData[`${field}`] = value.trim();
                                    } else if (getWaterRouteFieldType(field) == 'boolean') {
                                        waterRouteData[`${field}`] =
                                            value.trim().toUpperCase() == 'SI' ? true : false;
                                    } else {
                                        waterRouteData[`${field}`] = parseInt(value);
                                    }
                                }
                            } catch (error) {
                                console.log(value);
                            }
                        }
                    });
                    let waterRoute = waterRouteData as WaterRoute;
                    if (waterRoute) {
                        const companyId = localStorage.getItem('company');
                        const managerId = localStorage.getItem('manager');
                        waterRoute.company = parseInt(companyId!);
                        waterRoute.manager = parseInt(managerId!);

                        if (waterRoute.ruta)
                            waterRoute.ruta = this._utilsService.addCeros(waterRoute.ruta, 6);
                        if (
                            !waterRoute.codigo_ruta!.includes('.') &&
                            waterRoute.ruta &&
                            waterRoute.portal
                        ) {
                            const str = waterRoute.ruta + '.' + waterRoute.portal;
                            waterRoute.codigo_ruta = str;
                        }
                        if (waterRoute.portal)
                            waterRoute.portal = this._utilsService.addCeros(waterRoute.portal!, 3);
                        arrayWaterRoutes.push(waterRoute);
                    }
                }
            }
            console.log('============= arrayWaterRoutes =============');
            console.log(arrayWaterRoutes);
            const result = await this.importWaterRoutes(arrayWaterRoutes);
            this._spinner.hide();
        };
        reader.readAsBinaryString(file);

        this._spinner.hide();
    }

    async importWaterRoutes(waterRoutes: WaterRoute[]) {
        this._spinner.show();
        this.loadingText = `Añadiendo rutas ...`;
        let i = 0,
            errorIds = [];
        for (let waterRoute of waterRoutes) {
            this.loadingText = `Añadiendo rutas ${++i} de ${waterRoutes.length}`;
            if (!(await this._apiService.addDocument('water-route', waterRoute))) {
                errorIds.push(waterRoute.codigo_ruta);
            }
        }
        this._spinner.hide();
        if (errorIds.length > 0) {
            this._utilsService.openSnackBar(`Hubo errores añadiendo rutas`, 'error');
        } else {
            this._utilsService.openSnackBar(`Rutas subidos correctamente`);
        }
        await this.getWaterRoutes();
        return !(errorIds.length > 0);
    }

    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this._spinner.show('innerSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this._spinner.hide('innerSpinner');
        }
    }

    setWaterRoutesInTable(waterRoutes: WaterRoute[]) {
        this.waterRoutes = waterRoutes;
        this.dataSource.data = [];
        for (let [index, waterRoutes] of this.waterRoutes.entries()) {
            waterRoutes.ID = index + 1;
        }
        this.dataSource.data = this.waterRoutes.slice(0, this.rowsLimit);
        this.showLoading(false);
    }

    async getWaterRoutes() {
        this.setWaterRoutesInTable(await this.selectWaterRoutes());
    }
    async selectWaterRoutes(status?: string) {
        this.showLoading(true);
        let where = '';
        this.length = await this._mySqlService.getWaterRoutesCount(where);
        const order = this._utilsService.orderWaterRoutes;
        let order_clause = undefined;
        if (order.length > 0) {
            order_clause = this._utilsService.getOrderClauseFromOrder(order);
        }
        return await this._mySqlService.getWaterRoutes(
            undefined,
            /*where*/ undefined,
            /*order_clause*/ undefined,
            '0',
            this.pageSize.toString()
        );
    }

    searchValue(event: any) {
        const filterValue: string = event;
        this.dataSource!.filter = filterValue.trim().toLowerCase();
    }

    openMaps() {}

    async exportWaterRoutesInTable() {
        let result: boolean = false;
        if (this.clickedRows.size) {
            result = await this._utilsService.openQuestionDialog(
                'Seleccione',
                '¿Exportar solo las rutas seleccionadas?',
                'Seleccionadas',
                'Todas'
            );
        }
        if (result) this.exportExcel(this.clickedRows);
        else {
            this.loadingText = `Descargando para exportación ...`;
            this._spinner.show();
            const savedOffset = this._mySqlService.last_waterRoute_offset;
            let waterRoutes: WaterRoute[] = [];
            const filter = this._utilsService.filterWaterRoutes;
            let where_clause = undefined;
            if (filter && filter.fields) {
                where_clause = this._utilsService.getWhereClauseFromFilter(filter);
            }
            const count = await this._mySqlService.getWaterRoutesCount(where_clause);
            const order = this._utilsService.orderWaterRoutes;
            let order_clause = undefined;
            if (order.length > 0) order_clause = this._utilsService.getOrderClauseFromOrder(order);
            let limit: number = 1000;
            for (let offset = 0; offset < count; offset += limit) {
                this.loadingText = `Descargando para exportación (${offset} de ${count}) ...`;
                const serverWaterRoutes = await this._mySqlService.getWaterRoutes(
                    undefined,
                    where_clause,
                    order_clause,
                    offset.toString(),
                    limit.toString()
                );
                waterRoutes = waterRoutes.concat(serverWaterRoutes);
            }
            this._mySqlService.last_waterRoute_offset = savedOffset;
            this.exportExcel(waterRoutes);
            this._spinner.hide();
        }
    }

    exportExcel(WaterRoutes: any) {
        let excelFormatTasks = [];
        for (let waterRoute of WaterRoutes) {
            let data: any = {};
            const columns = getWaterRouteExcelExportColumns();
            columns.forEach((column) => {
                data[`${column}`] = this._utilsService.tableDataPipe(
                    waterRoute[getWaterRouteExcelFieldName(column)]
                );
            });
            excelFormatTasks.push(data);
        }
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelFormatTasks);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        /* save to file */
        XLSX.writeFile(wb, 'Rutas_Exportadas.xlsx');
    }

    async deleteWaterRoutes() {
        let errorIds = [];
        if (this.clickedRows.size == 0) {
            this._utilsService.openSnackBar('Debe seleccionar al menos una waterRoute', 'warning');
            return;
        }
        const result = await this._utilsService.openQuestionDialog(
            'Confirmación',
            '¿Desea eliminar las waterRoutes seleccionadas?'
        );
        if (result) {
            this._spinner.show();
            this.loadingText = `Eliminando waterRoutes ...`;
            let rowIndexes = new Set<number>();
            const oldDataSource = this.waterRoutes;
            this.clickedRows.forEach(async (row) => {
                console.log(row.id);

                const index = this.waterRoutes.indexOf(row, 0);
                if (index > -1) {
                    rowIndexes.add(index);
                }
            });
            let waterRoutes = [];
            let deleteCount = 0;
            for (let i = 0; i < oldDataSource.length; i++) {
                if (!rowIndexes.has(i)) {
                    waterRoutes.push(oldDataSource![i]);
                } else {
                    this.loadingText = `Eliminando waterRoutes ${++deleteCount} de ${
                        rowIndexes.size
                    }`;
                    if (
                        !(await this._apiService.deleteDocument(
                            'water-route',
                            oldDataSource[i].id!.toString()
                        ))
                    ) {
                        console.log('****************** deleteWaterRoute error *************');
                        errorIds.push(oldDataSource[i].id);
                    } else {
                        console.log('****************** deleteWaterRoute success *************');
                    }
                }
            }
            if (errorIds.length > 0) {
                this._utilsService.openSnackBar(`Hubo errores eliminando waterRoutes`, 'error');
            } else {
                this._utilsService.openSnackBar(`Tareas eliminadas correctamente`);
            }
            this.length -= rowIndexes.size;
            this.setWaterRoutesInTable(waterRoutes);
            this.clickedRows.clear();
            this._spinner.hide();
        }
    }

    async selectAll() {
        this.clickedRows.clear();
        const waterRoutes = this.waterRoutes;
        for (const waterRoute of waterRoutes) {
            if (!this.clickedRows.has(waterRoute)) {
                this.clickedRows.add(waterRoute);
            }
        }
        this._utilsService.openSnackBar(`Seleccionadas ${this.clickedRows.size} rutas`);
    }

    async pageEvent(event: any) {
        console.log(`length ${event.length}`);
        console.log(`pageSize ${event.pageSize}`);
        console.log(`pageIndex ${event.pageIndex}`);
        if (this.lastPageIndex != event.pageIndex) {
            this.showLoading(true);
            if (this.lastPageIndex < event.pageIndex) {
                this.setWaterRoutesInTable(
                    await this._mySqlService.getNextWaterRoutesPage(
                        event.pageIndex - this.lastPageIndex
                    )
                );
            } else {
                this.setWaterRoutesInTable(
                    await this._mySqlService.getPreviousWaterRoutesPage(
                        this.lastPageIndex - event.pageIndex
                    )
                );
            }
            this.lastPageIndex = event.pageIndex;
        }
        if (this.pageSize != event.pageSize) {
            this.pageSize = event.pageSize;
            localStorage.setItem('waterRoute_pageSize', this.pageSize.toString());
            await this.getWaterRoutes();
        }
        this.scrollOffset = 50;
    }

    async filterWaterRoute(where?: string) {
        // console.log('************** filterWaterRoute ***************');
        this.length = await this._mySqlService.getWaterRoutesCount(where);
        const order = this._utilsService.orderWaterRoutes;
        let order_clause = undefined;
        if (order.length > 0) {
            order_clause = this._utilsService.getOrderClauseFromOrder(order);
        }
        return await this._mySqlService.getWaterRoutes(
            undefined,
            where,
            order_clause,
            '0',
            this.pageSize.toString()
        );
    }

    async applyFilter(values: any, column: string, not_empty: boolean = false, empties_checked: boolean = false) {
        const where_clause = this._utilsService.getWhereClauseFromFilter(
            this._utilsService.processFilter(
                this._utilsService.filterWaterRoutes!,
                values,
                column,
                getWaterRouteFieldType(column),
                this._mySqlService.waterRoutesTableName,
                true,
                not_empty,
                empties_checked
            )
        );
        this.showLoading(true);
        this.lastPageIndex = 0;
        this.setWaterRoutesInTable(await this.filterWaterRoute(where_clause));
    }

    async filterBy(column: string) {
        this.filteredColumn = getWaterRouteField(column);
        if (getWaterRouteFieldType(this.filteredColumn) == 'Date') {
            const dates = await this._utilsService.openDateRangeSelectorDialog(
                'Seleccione rango de fechas'
            );
            let times: Date[];
            try {
                times = await this._utilsService.openTimeRangeSelectorDialog(
                    'Seleccione rango de horas'
                );
                this.onDateSelected(dates, times);
            } catch (err) {
                this.onDateSelected(dates);
            }
        } else {
            const result = await this._utilsService.openFilterDialog(
                column,
                this.filteredColumn,
                this._mySqlService.waterRoutesTableName,
                this._utilsService.filterWaterRoutes
            );
            if (result && result.data) {
                 this.applyFilter(result.data, result.column, result.not_empty, result.empties_checked);
            }
        }
    }
    async onDateSelected(dateRange: Date[], timeRange?: Date[]) {
        if (dateRange) {
            const values = this._utilsService.getDateRangeString(dateRange, timeRange);
            await this.applyFilter(values, this.filteredColumn!);
        } else {
            this._utilsService.openSnackBar('Rango fechas inválido', 'error');
        }
    }

    async orderBy(event: any) {
        const column = event.column;
        const orderType = event.orderType;

        const orderedColumn = getWaterRouteField(column);
        const order_clause = this._utilsService.getOrderClauseFromOrder(
            this._utilsService.processOrder(
                this._mySqlService.waterRoutesTableName,
                this._utilsService.orderWaterRoutes,
                orderedColumn,
                orderType
            )
        );
        const filter = this._utilsService.filterWaterRoutes;
        let where_clause = undefined;
        if (filter && filter.fields) {
            where_clause = this._utilsService.getWhereClauseFromFilter(filter);
        }
        this.showLoading(true);
        const waterRoutes = await this._mySqlService.getWaterRoutes(
            undefined,
            where_clause,
            order_clause,
            '0',
            this.pageSize.toString()
        );
        this.lastPageIndex = 0;
        this.setWaterRoutesInTable(waterRoutes);
    }

    async addNewRow(event: any) {
        console.log('**************** addNewRow ***************');
        const waterRoute = await this._utilsService.openWaterRouteDialog('');
        console.log(waterRoute);
        if (waterRoute) {
            this.waterRoutes.push(waterRoute);
            this.dataSource.data = [];
            this.dataSource.data = this.waterRoutes.slice(0, this.scrollOffset);
        }
    }
    async doubleClickedRow(row: any) {
        let waterRoute = await this._utilsService.openWaterRouteDialog(row.id);

        if (waterRoute) {
            waterRoute.ID = this.waterRoutes[row.rowIndex].ID;
            this.waterRoutes[row.rowIndex] = waterRoute;
            this.dataSource.data = [];
            this.dataSource.data = this.waterRoutes.slice(0, this.scrollOffset);
        }
    }

    clickedRow(receivedEvent: any) {
        const row = receivedEvent.row;
        const event = receivedEvent.event;
        const rowIndex = receivedEvent.rowIndex;

        const previousRow = this.lastSelectedRow;
        this.lastSelectedRow = rowIndex;

        if (event.button === 0) {
            if (!event.ctrlKey && !event.shiftKey) {
                this.clickedRows.clear();
                this.toggleRow(row);
                // console.log('**************** !event.ctrlKey && !event.shiftKey ***************');
            } else if (event.ctrlKey) {
                this.toggleRow(row);
                // console.log('**************** event.ctrlKey ***************');
            }
            if (event.shiftKey) {
                this.selectRowsBetweenIndexes(previousRow, rowIndex);
                console.log('**************** event.shiftKey ***************');
            }
        }
        if (this._windowRefService.nativeWindow.getSelection) {
            //remove selection in table with shift
            if (this._windowRefService.nativeWindow.getSelection().empty) {
                // Chrome
                this._windowRefService.nativeWindow.getSelection().empty();
            } else if (this._windowRefService.nativeWindow.getSelection().removeAllRanges) {
                // Firefox
                this._windowRefService.nativeWindow.getSelection().removeAllRanges();
            }
        }
    }
    selectRowsBetweenIndexes(lastSelectedRow: number, rowIndex: number) {
        let start, end;
        if (rowIndex > lastSelectedRow) {
            start = lastSelectedRow;
            end = rowIndex;
        } else {
            end = lastSelectedRow;
            start = rowIndex;
        }
        for (let i = start; i <= end; i++) {
            this.clickedRows.add(this.waterRoutes[i]);
        }
    }
    toggleRow(row: any) {
        if (this.clickedRows.has(row)) {
            this.clickedRows.delete(row);
        } else {
            this.clickedRows.add(row);
        }
    }

    async openSettings() {
        await this._utilsService.openFilterConfigurationDialog(
            this._mySqlService.waterRoutesTableName,
            this._utilsService.filterWaterRoutes
        );
    }
}

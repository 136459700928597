/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
    title: string;
    text: string;
    acceptText: string;
    cancelText: string;
}

@Component({
    selector: 'app-question-dialog',
    templateUrl: './question-dialog.component.html',
    styleUrls: ['./question-dialog.component.scss'],
})
export class QuestionDialogComponent implements OnInit {
    title?: string = 'Titulo';
    text?: string = 'Texto';
    acceptText?: string = 'Aceptar';
    cancelText?: string = 'Cancelar';

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {
        this.title = data.title;
        this.text = data.text;
        if (data.acceptText) this.acceptText = data.acceptText;
        if (data.cancelText) this.cancelText = data.cancelText;
    }

    ngOnInit(): void {}
}

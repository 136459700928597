<mat-dialog-content class="animate__animated animate__fadeIn">
    <div [formGroup]="companyFormData" class="animate__animated animate__fadeIn">
        <div>
            <div class="container">
                <div class="row bd-highlight" class="row-content-center">
                    <div>
                        <div style="margin-top: 5px; margin-bottom: 30px; margin-left: 5px">
                            <p style="font-size: large">Datos de empresa</p>
                        </div>
                        <div>
                            <div class="row-content">
                                <mat-form-field style="width: 120px" appearance="fill">
                                    <mat-label>Código empresa</mat-label>
                                    <input
                                        formControlName="codigo_empresa"
                                        matInput
                                        type="text"
                                        placeholder="Código empresa"
                                    />
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Nombre Empresa</mat-label>
                                    <input
                                        formControlName="nombre_empresa"
                                        matInput
                                        type="text"
                                        placeholder="Nombre empresa"
                                    />
                                </mat-form-field>
                            </div>

                            <div class="row-content">
                                <mat-form-field appearance="fill">
                                    <mat-label>Descripción</mat-label>
                                    <textarea
                                        matInput
                                        formControlName="descripcion"
                                        placeholder="Descripción..."
                                        cdkTextareaAutosize
                                        #autosize="cdkTextareaAutosize"
                                        cdkAutosizeMinRows="2"
                                    >
                                    </textarea>
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Usuario Entidad</mat-label>
                                    <input
                                        formControlName="entity_user"
                                        matInput
                                        type="text"
                                        placeholder="Usuario Entidad"
                                    />
                                </mat-form-field>
                                <mat-form-field *ngIf="this.companyId" appearance="fill">
                                    <mat-label>Contraseña Entitdad</mat-label>
                                    <input
                                        formControlName="entity_pasword"
                                        matInput
                                        type="password"
                                        placeholder="Contraseña Entitdad"
                                    />
                                </mat-form-field>

                                <mat-form-field *ngIf="!this.companyId" appearance="fill">
                                    <mat-label>Contraseña Entitdad</mat-label>
                                    <input
                                        formControlName="entity_pasword"
                                        matInput
                                        type="text"
                                        placeholder="Contraseña Entitdad"
                                    />
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ngx-spinner
        [bdColor]="company ? 'rgba(0, 0, 0, 0.2)' : 'rgba(255, 255, 255, 1)'"
        size="large"
        color="#368DCE"
        type="ball-scale-multiple"
        [fullScreen]="false"
        name="companySpinner"
    >
    </ngx-spinner>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="company">Cancelar</button>
    <button mat-button cdkFocusInitial matTooltip="Guardar cambios" (click)="saveChanges()">
        <mat-icon color="primary">check</mat-icon> {{ companyId ? 'Actualizar' : 'Añadir' }}
    </button>
</mat-dialog-actions>

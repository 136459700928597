<mat-dialog-content>
    <div style="display: flex">
        <div *ngIf="waterTask?.telefono1" style="flex: 1">
            <label id="example-radio-group-label">Teléfono 1 -> {{ waterTask?.telefono1 }}</label>
            <mat-radio-group
                aria-labelledby="example-radio-group-label"
                class="example-radio-group"
                [(ngModel)]="phone1ValidOption"
            >
                <mat-radio-button
                    color="primary"
                    class="example-radio-button"
                    *ngFor="let option of validOptions"
                    [value]="option"
                >
                    {{ option }}
                </mat-radio-button>
            </mat-radio-group>
            <mat-radio-group
                aria-labelledby="example-radio-group-label"
                class="example-radio-group"
                [(ngModel)]="phone1AnswerOption"
            >
                <mat-radio-button
                    color="primary"
                    class="example-radio-button"
                    *ngFor="let option of answerOptions"
                    [value]="option"
                    (change)="onAnswerPhone1OptionChange($event)"
                >
                    {{ option }}
                </mat-radio-button>
            </mat-radio-group>
        </div>

        <div
            *ngIf="waterTask?.telefono1 && waterTask?.telefono2"
            style="flex: 1; width: 25px"
        ></div>
        <div *ngIf="waterTask?.telefono2" style="flex: 1">
            <label id="example-radio-group-label">Teléfono 2 -> {{ waterTask?.telefono2 }}</label>
            <mat-radio-group
                aria-labelledby="example-radio-group-label"
                class="example-radio-group"
                [(ngModel)]="phone2ValidOption"
            >
                <mat-radio-button
                    color="primary"
                    class="example-radio-button"
                    *ngFor="let option of validOptions"
                    [value]="option"
                >
                    {{ option }}
                </mat-radio-button>
            </mat-radio-group>
            <mat-radio-group
                aria-labelledby="example-radio-group-label"
                class="example-radio-group"
                [(ngModel)]="phone2AnswerOption"
            >
                <mat-radio-button
                    color="primary"
                    class="example-radio-button"
                    *ngFor="let option of answerOptions"
                    [value]="option"
                    (change)="onAnswerPhone2OptionChange($event)"
                >
                    {{ option }}
                </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>

    <div>
        <span style="margin: 5px;">Si ha llamado ahora y no contesta...</span>
        <div style="display: flex; margin: 5px;">
            <button mat-button color="primary" (click)="onNoAnswerPhone1Click()">
                No contesta TEL1
            </button>
            <button mat-button color="primary" (click)="onNoAnswerPhone2Click()">
                No contesta TEL2
            </button>
        </div>
    </div>

</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-button mat-dialog-close>Cancelar</button>
    <button mat-button cdkFocusInitial (click)="retrieveData()">Aceptar</button>
</mat-dialog-actions>

import { FormControl, FormGroup } from '@angular/forms';

export interface Part {
    codigo_pieza?: string;
    date_time_modified?: Date;
    fecha_utilizacion?: string;
    id?: string;
    pieza?: string;
    state_pieza?: string;
}

export function getPartDisplayColumns(): string[] {
    const displayedColumns: string[] = [
        'Código pieza',
        'Pieza',
        'Fecha utilización',
        'Estado de pieza',
        'Fecha de Modificación',
    ];
    return displayedColumns;
}

export function getPartField(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'Código pieza':
            value = 'codigo_pieza';
            break;
        case 'Pieza':
            value = 'pieza';
            break;
        case 'Fecha utilización':
            value = 'fecha_utilizacion';
            break;
        case 'Estado de pieza':
            value = 'state_pieza';
            break;
        case 'Fecha de Modificación':
            value = 'date_time_modified';
            break;
    }
    return value;
}

export function getPartFieldName(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'codigo_pieza':
            value = 'Código pieza';
            break;
        case 'pieza':
            value = 'Pieza';
            break;
        case 'fecha_utilizacion':
            value = 'Fecha utilización';
            break;
        case 'state_pieza':
            value = 'Estado de pieza';
            break;
        case 'date_time_modified':
            value = 'Fecha de Modificación';
            break;
    }
    return value;
}

export function getPartFormControls(): FormGroup {
    const partFormData: FormGroup = new FormGroup({
        pieza: new FormControl(),
        codigo_pieza: new FormControl(),
        fecha_utilizacion: new FormControl(),
        state_pieza: new FormControl(),
        date_time_modified: new FormControl(),
        id: new FormControl(),
    });
    return partFormData;
}

export function getPartFieldType(field_name: string): string {
    //in bigquery
    let value: string = '';
    switch (field_name) {
        case 'codigo_pieza':
            value = 'string';
            break;
        case 'pieza':
            value = 'string';
            break;
        case 'state_pieza':
            value = 'string';
            break;
        case 'fecha_utilizacion':
            value = 'Date';
            break;
        case 'date_time_modified':
            value = 'Date';
            break;
        case 'id':
            value = 'number';
            break;
    }
    return value;
}

export function getPartExcelFieldName(excel_field: string): string {
    let value: string = '';
    switch (excel_field.trim().toLowerCase()) {
        case 'pieza':
            value = 'pieza';
            break;
        case 'state_pieza':
            value = 'state_pieza';
            break;
        case 'fecha utilizacion':
            value = 'fecha_utilizacion';
            break;
        case 'fecha utilización':
            value = 'fecha_utilizacion';
            break;
        case 'código pieza':
            value = 'codigo_pieza';
            break;
        case 'codigo pieza':
            value = 'codigo_pieza';
            break;
        case 'fecha de modificación':
            value = 'date_time_modified';
            break;
        case 'fecha de modificacion':
            value = 'date_time_modified';
            break;
        case 'codigo_pieza':
            value = 'codigo_pieza';
            break;
        case 'date_time_modified':
            value = 'date_time_modified';
            break;
        case 'fecha_utilizacion':
            value = 'fecha_utilizacion';
            break;
        case 'state_pieza':
            value = 'state_pieza';
            break;
    }
    return value;
}

export function getPartExcelExportColumns(): string[] {
    const columns = [
        'Código pieza',
        'Pieza',
        'Fecha utilización',
        'Estado de pieza',
        'Fecha de Modificación',
    ];
    return columns;
}

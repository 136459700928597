/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilsService } from 'src/app/services/utils.service';

export interface BarcodeDialogData {
    title: string;
    text: string;
}

@Component({
    selector: 'app-barcode-dialog',
    templateUrl: './barcode-dialog.component.html',
    styleUrls: ['./barcode-dialog.component.scss'],
})
export class BarcodeDialogComponent implements OnInit {
    title?: string = 'Titulo';
    image?: string = '';

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: BarcodeDialogData,
        private _utilsService: UtilsService
    ) {
        this.title = data.title;
        this.image = data.text;
    }

    ngOnInit(): void {
        let c = document.getElementById('canvas') as any;
        let ctx = c.getContext('2d');
        let canvasImage = this._utilsService.textHTMLCanvasBarcode(this.image!);
        ctx!.drawImage(canvasImage, 0, 0);
    }
}


<mat-dialog-content class=" animate__animated animate__fadeIn">
    <div [formGroup]="emplacementFormData"
    class=" animate__animated animate__fadeIn">

        <div>

            <div class="container">

                <div class="row bd-highlight" class="row-content-center">

                    <div>
                        <div style="margin-top: 5px; margin-bottom: 30px; margin-left: 5px;">                        
                            <p style="font-size: large;">Datos de emplazamiento</p>
                        </div> 
                        <div>

                            <div class="row-content">

                                <mat-form-field appearance="fill">
                                    <mat-label>Código emplazamiento</mat-label>
                                    <input formControlName="codigo_emplazamiento" matInput type="text" placeholder="Código emplazamiento">
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Resto</mat-label>
                                    <input formControlName="resto" matInput type="text" placeholder="Resto">
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Emplazamiento</mat-label>
                                    <input formControlName="emplazamiento" matInput type="text" placeholder="Emplazamiento">
                                </mat-form-field>

                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>

    <ngx-spinner [bdColor] = "(emplacement)?'rgba(0, 0, 0, 0.2)': 'rgba(255, 255, 255, 1)'" size = "large" color = "#368DCE" 
    type = "ball-scale-multiple" [fullScreen] = "false" name="emplacementSpinner">
    </ngx-spinner>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="emplacement">Cancelar</button>
    <button mat-button cdkFocusInitial
    matTooltip="Guardar cambios" 
    (click)="saveChanges()"> 
        <mat-icon color="primary">check</mat-icon> {{ (emplacementId)?'Actualizar':'Añadir' }}
    </button>
</mat-dialog-actions>


<div *ngIf="electronService.isElectronApp()" class="floating-top-left" (click)="location.back()">
    <button mat-fab color="primary">
        <mat-icon style="margin-left: 10px" matTooltip="Ir atrás">arrow_back_ios</mat-icon>
    </button>
</div>

<div >
    <div class="toolbar-background" [formGroup]="settingsGroup" >
        <div class="settings-group">
            <mat-form-field appearance="outline" class="date-form">
                <mat-label>Fecha</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="date">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="outline" class="users-form">
                <mat-label>Operario</mat-label>
                <mat-select formControlName="user">
                    <mat-option *ngFor="let user of users" [value]="user">{{ user | user }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="scrollable-hours">
        <mwl-calendar-week-view (contextmenu)="preventDefault($event)"
            [viewDate]="viewDate"
            [dayStartHour]="minHour"
            [dayEndHour]="maxHour"
            [events]="events"
            [minimumEventHeight]="20"
            [hourSegments]="12"  
            [hourSegmentHeight]="hourSegmentHeight"
            [excludeDays]="[0, 6]"
            [locale]="'es'"
            (eventTimesChanged)="onEventTimesChanged($event)"
            (eventClicked)="onEventClicked($event)"
            (hourSegmentClicked)="onHourSegmentClicked($event)"
        ></mwl-calendar-week-view>
    </div>
</div>
    
<ngx-spinner
    bdColor="rgba(0, 0, 0, 0.1)"
    size="large"
    color="#368DCE"
    type="square-jelly-box"
    [fullScreen]="true"
>
    <p class="loading-text-background" style="margin-top: 50px">{{ loadingText }}</p>
</ngx-spinner>

<div
    style="visibility: hidden; position: fixed"
    [style.left]="menuTopLeftPosition.x"
    [style.top]="menuTopLeftPosition.y"
    [matMenuTriggerFor]="eventMenu"
></div>

<mat-menu #eventMenu="matMenu">
    <ng-template matMenuContent let-item="item">
        <button *ngFor="let option of menuOptions" mat-menu-item (click)="onMenuOptionSelected(option)">
            {{ option }}
        </button>
    </ng-template>
</mat-menu>


<div
    style="visibility: hidden; position: fixed"
    [style.left]="menuTopLeftPosition.x"
    [style.top]="menuTopLeftPosition.y"
    [matMenuTriggerFor]="segmentMenu"
    #segmentTrigger="matMenuTrigger"
></div>

<mat-menu #segmentMenu="matMenu">
    <ng-template matMenuContent let-item="item">
        <mat-form-field appearance="outline" class="menu-input">
            <input matInput placeholder="Buscar tarea" [(ngModel)]="searchInput" 
                (ngModelChange)="onChangeEvent($event)"  
                (click)="$event.stopPropagation();">
        </mat-form-field>

        <div *ngIf="searching" class="progress-indicator">
            <mat-spinner diameter="40"></mat-spinner>
        </div>

        <div *ngIf="!searching">
            <mat-card *ngFor="let task of foundTasks; let i = index" class="menu-card" (click)="onMenuTaskSelected(task)">
                <mat-card-content class="menu-card-content">
                    <div>
                        <div *ngIf="getDirOfTask(task).length < 33">
                            <b>{{ getDirOfTask(task) }}</b>
                        </div>
                        <marquee *ngIf="getDirOfTask(task).length >= 33">
                            <b>{{ getDirOfTask(task) }}</b>
                        </marquee>
                        <div>{{ getTaskSubscriberTextLine(task) }}</div>
                        <div>{{ getTaskCounterTextLine(task) }}</div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        
    </ng-template>
</mat-menu>
/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Injectable } from '@angular/core';
import { ElectronService } from 'ngx-electron';
import { getItacPhotoFieldByName, Itac } from '../interfaces/itac';
import { getPhotoFieldByName, WaterTask } from '../interfaces/water-task';
import { ApiService } from './api.service';
import { UtilsService } from './utils.service';
import { environment } from '../../environments/environment';
import { MessagingService } from './messaging.service';
import { FcmMessage } from '../interfaces/fcm-message';
var nodeBase64 = require('nodejs-base64-converter');
const {
    START_NOTIFICATION_SERVICE,
    NOTIFICATION_SERVICE_STARTED,
    NOTIFICATION_SERVICE_ERROR,
    NOTIFICATION_RECEIVED,
    TOKEN_UPDATED,
} = require ('electron-push-receiver/src/constants')

@Injectable({
    providedIn: 'root',
})
export class IpcService {
    waterTask?: WaterTask;
    itac?: Itac;

    constructor(
        private _electronService: ElectronService, 
        private _apiService: ApiService, 
        private _utilsService: UtilsService,
        private _messagingService: MessagingService, 
    ) {
        if (this._electronService.isElectronApp) {
            this._electronService.ipcRenderer.on('asynchronous-reply', (event, arg) => {});
            this._electronService.ipcRenderer.on('sync-message', async (_: any, arg: any) => {
                await this.onSyncMessage(arg);
            });
            this._electronService.ipcRenderer.on(NOTIFICATION_SERVICE_STARTED, (_, token) => {
                this._utilsService.saveFirebaseToken(token);
            });
            this._electronService.ipcRenderer.on(NOTIFICATION_SERVICE_ERROR, (_, error) => {
                console.log('NOTIFICATION_SERVICE_ERROR', error);
            });
            this._electronService.ipcRenderer.on(TOKEN_UPDATED, (_, token) => {
                this._utilsService.saveFirebaseToken(token);
            });
            this._electronService.ipcRenderer.on(NOTIFICATION_RECEIVED, (_, fcmMessage: FcmMessage) => {
                this.onNotificationReceived(fcmMessage);
            });
            this.startFCMElectronService();
        }
    }

    startFCMElectronService() {
        const senderId = environment.fcmElectronSenderId;
        this._electronService.ipcRenderer.send(START_NOTIFICATION_SERVICE, senderId)
    }

    async onNotificationReceived(fcmMessage: FcmMessage){
        if (fcmMessage.notification){
            if (this._utilsService.isNotificationsEnableByUser()) {
                this._utilsService.openSnackBar(fcmMessage.notification.title, 'info', 6000);
            }
            this._messagingService.sendMessage('Update notification count'); 
        } 
    }

    async onSyncMessage(arg: any){
        if (arg.type == 'itac') {
            await this.uploadItacImage(arg);
        } else if (arg.type == 'task') {
            await this.uploadImage(arg);
        }
        this._electronService.ipcRenderer.send('sync-reply', 'received and answer');
    }

    public isElectronApp(): boolean {
        return this._electronService.isElectronApp;
    }

    setDisplayColumns(
        classDisplayColumns: string[],
        tableName: string,
        reloadFunction: Function,
        displayColumnFunction: Function,
        electronService: IpcService,
        electronServiceAnswer: ElectronService
    ): string[] {
        classDisplayColumns = displayColumnFunction();

        const displayedColumns = localStorage.getItem('displayedColumns_' + tableName);
        if (electronService.isElectronApp()) {
            if (!displayedColumns || displayedColumns.length == 0) {
                electronService.sendMessage('load-columns', {
                    table: tableName,
                });
            }

            electronServiceAnswer.ipcRenderer.on(
                `send-load-columns-${tableName}`,
                async (event: any, arg: any) => {
                    try {
                        const decode = this.decodeBase64(arg);
                        const localDisplayColumns = JSON.parse(decode);
                        if (classDisplayColumns.length == localDisplayColumns.length) {
                            classDisplayColumns = localDisplayColumns;
                        }
                        localStorage.setItem(
                            'displayedColumns_' + tableName,
                            JSON.stringify(classDisplayColumns)
                        );
                        await this._apiService.saveColumnsOnUser(tableName, decode);

                        reloadFunction();
                    } catch (err) {}
                    electronServiceAnswer.ipcRenderer.send('sync-reply', 'received and answer');
                }
            );
        }
        if (displayedColumns) {
            let columns;
            try {
                columns = JSON.parse(displayedColumns);
            } catch (err) {}
            if (columns && columns.length == classDisplayColumns.length) {
                try {
                    if (Array.isArray(columns)) classDisplayColumns = columns;
                    else classDisplayColumns = displayColumnFunction();
                    return classDisplayColumns;
                } catch (err) {
                    classDisplayColumns = displayColumnFunction();
                    return classDisplayColumns;
                }
            }
        }
        classDisplayColumns = displayColumnFunction();
        localStorage.setItem('displayedColumns_' + tableName, JSON.stringify(classDisplayColumns));

        return classDisplayColumns;
    }
    decodeBase64(value: string) {
        return nodeBase64.decode(value);
    }

    convertBase64ToBlob(b64: string): Blob {
        const byteCharacters = atob(b64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'audio/mp3' });
        return blob;
    }
    public async uploadImage(arg: any) {
        const file = arg.file;
        const subscriber = arg.subscriber;
        const anomaly = arg.anomaly;
        const photo = arg.photo; //filename
        const data = arg.data;
        const search_anomaly = arg.search_anomaly;
;

        const company = localStorage.getItem('company');
        const manager = localStorage.getItem('manager');

        const photoField = getPhotoFieldByName(photo);
        if (!photoField) {
            console.error('************* error *************');
            console.error(' Task photoField not found');
            return;
        }
        let tasks: WaterTask[] = [];

        try {
            if (this.waterTask 
                && this.waterTask.Numero_de_ABONADO == subscriber 
                && (search_anomaly ? this.waterTask.ANOMALIA == anomaly:true)) {
                tasks = [this.waterTask];
            } else {
                const expressions = [];
                expressions.push(['Numero_de_ABONADO', '==', subscriber])
                if(search_anomaly) expressions.push(['ANOMALIA', '==', anomaly])
                expressions.push(['company', '==', company])
                expressions.push(['GESTOR', '==', manager])
                tasks = await this._apiService.getTasks(expressions);
            }
            if (tasks.length > 0) {
                const waterTask = tasks[0];
                this.waterTask = waterTask;

                let photoFile: File = new File([this.convertBase64ToBlob(data)], photo);
                var formData: any = new FormData();
                formData.append('image', photoFile);
                formData.append('data', photoField);

                try {
                    const taskData = await this._apiService.uploadTaskImage(
                        formData,
                        this.waterTask!
                    );
                } catch (err) {
                    console.log('************* err *************');
                    console.log(err);
                }
            }
        } catch (err) {
            console.error('************* error *************');
            console.error(err);
        }
    }

    public async uploadItacImage(arg: any) {
        const file = arg.file;
        const itac_code = arg.itac_code;
        const photo = arg.photo;
        const data = arg.data;

        const photoField = getItacPhotoFieldByName(photo);
        if (!photoField) {
            console.error('************* error *************');
            console.error(' Itac photoField not found');
            return;
        }
        let itacs: Itac[] = [];

        if (this.itac && this.itac.codigo_itac == itac_code) {
            // console.log('*************  this.itac *************');
            itacs = [this.itac];
        } else {
            const company = localStorage.getItem('company');
            const manager = localStorage.getItem('manager');
            // console.log('*************  this._apiService.getItacs *************');
            itacs = await this._apiService.getItacs([
                ['codigo_itac', '==', itac_code, 'company'],
                ['company', '==', company],
                ['gestor', '==', manager],
            ]);
            // console.log(itacs);
        }
        if (itacs.length > 0) {
            const itac = itacs[0];
            this.itac = itac;

            let photoFile: File = new File([this.convertBase64ToBlob(data)], photo);
            var formData: any = new FormData();
            formData.append('image', photoFile);
            formData.append('data', photoField);

            try {
                const itacData = await this._apiService.uploadItacImage(formData, this.itac!);
            } catch (err) {
                console.log('************* err *************');
                console.log(err);
            }
        }
    }

    public sendMessageSync(message: any): any {
        if (this._electronService.isElectronApp) {
            let answer: string = this._electronService.ipcRenderer.sendSync(
                'synchronous-message',
                message
            );
            return answer;
        }
        return '';
    }
    public sendMessage(topic: string, message: any): void {
        if (this._electronService.isElectronApp) {
            this._electronService.ipcRenderer.send(topic, message);
        }
    }

    // REVIEW this function is not tested yet
    public createBrowserWindow() {
        const remote = this._electronService.remote;
        const BrowserWindow = remote.BrowserWindow;
        const win = new BrowserWindow({
            height: 600,
            width: 800,
        });

        win.loadURL('<url>');
    }
}

/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faInbox } from '@fortawesome/free-solid-svg-icons';
import { UtilsService } from 'src/app/services/utils.service';
import { WaterTask } from '../../../interfaces/water-task';
import { FechasNoContesta } from '../../../interfaces/fechas-no-contesta';
import { ClientPhoneStatus } from '../../../interfaces/client-phone-status';

export interface PhoneStatusAnswers { 
    phoneStatus: string[]; 
    noAnswerDates: FechasNoContesta[];
}
@Component({
    selector: 'app-phone-status',
    templateUrl: './phone-status.component.html',
    styleUrls: ['./phone-status.component.scss'],
})
export class PhoneStatusComponent implements OnInit {
    faInbox = faInbox;
    waterTask: WaterTask = {};
    statusInTask: string[] = [];
    loading: boolean = false;
    phone1ValidOption?: string;
    phone2ValidOption?: string;
    phone1AnswerOption?: string;
    phone2AnswerOption?: string;
    validOptions: string[] = ['Nº correcto', 'Nº incorrecto', 'Sin comprobar'];
    answerOptions: string[] = ['Contesta', 'No sabe', 'No contesta', 'No comprobado'];
    noAnswerDates: FechasNoContesta[] = [];

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private _utilsService: UtilsService) {
        this.waterTask = data.waterTask;
        if (this.waterTask.telefonos_cliente){
            this.statusInTask = this.waterTask.telefonos_cliente.map((status: any) => status.value);
        }
    }

    async ngOnInit(): Promise<void> {
        if (this.statusInTask && this.statusInTask.length > 0) {
            for (const validOption of this.validOptions) {
                if (this.statusInTask.includes(`TEL1 ${validOption}`)) {
                    this.phone1ValidOption = validOption;
                } else if (this.statusInTask.includes(`TEL2 ${validOption}`)) {
                    this.phone2ValidOption = validOption;
                }
            }
        }
        this.fillNoAnswerDate();
    }

    onAnswerPhone1OptionChange(event: any){
        if(event && event.value.includes(`No contesta`)){
            this.addNoAswerDate(this.waterTask.telefono1!);
        }
    }

    onAnswerPhone2OptionChange(event: any){
        if(event && event.value.includes(`No contesta`)){
            this.addNoAswerDate(this.waterTask.telefono2!);
        }
    }

    onNoAnswerPhone1Click() {
        if(!this.statusInTask.includes(`TEL1 No contesta`)) this.statusInTask.push(`TEL1 No contesta`);
        this.addNoAswerDate(this.waterTask.telefono1!);
        this.fillNoAnswerDate();
    }

    onNoAnswerPhone2Click() {
        if(!this.statusInTask.includes(`TEL2 No contesta`)) this.statusInTask.push(`TEL2 No contesta`);
        this.addNoAswerDate(this.waterTask.telefono2!);
        this.fillNoAnswerDate();
    }

    fillNoAnswerDate(){
        for (const answerOption of this.answerOptions) {
            if (this.statusInTask.includes(`TEL1 ${answerOption}`)) {
                this.phone1AnswerOption = answerOption;
            } 
            if (this.statusInTask.includes(`TEL2 ${answerOption}`)) {
                this.phone2AnswerOption = answerOption;
            }
        }
    }

    checkIfTel1NoAnswer(){
        if(this.checkIfContainsStatus(`TEL1 No contesta`)) return;
        if(`TEL1 ${this.phone1AnswerOption}` == `TEL1 No contesta`){
            this.addNoAswerDate(this.waterTask.telefono1!);
        }
    }

    checkIfTel2NoAnswer(){
        if(this.checkIfContainsStatus(`TEL2 No contesta`)) return;
        if(`TEL2 ${this.phone2AnswerOption}` == `TEL2 No contesta`){
            this.addNoAswerDate(this.waterTask.telefono2!);
        }
    }

    checkIfContainsStatus(status: string){
        const statuses: ClientPhoneStatus[] = this.waterTask.telefonos_cliente!;
        for(const st of statuses){
            if(st.value === status) return true;
        }
        return false;
    }

    addNoAswerDate(phone: string) {
        this._utilsService.openSnackBar('Se ha añadido fecha y hora actual de no contesta');
        this.noAnswerDates.push({  value: new Date(), phone: phone });
    }

    retrieveData() {
        this.statusInTask = [];
        if (this.phone1ValidOption) this.statusInTask.push(`TEL1 ${this.phone1ValidOption}`);
        if (this.phone1AnswerOption) this.statusInTask.push(`TEL1 ${this.phone1AnswerOption}`);
        if (this.phone2ValidOption) this.statusInTask.push(`TEL2 ${this.phone2ValidOption}`);
        if (this.phone2AnswerOption) this.statusInTask.push(`TEL2 ${this.phone2AnswerOption}`);
        this._utilsService.closePhoneStatusDialog({ phoneStatus: this.statusInTask, noAnswerDates: this.noAnswerDates});
    }
}


<mat-dialog-content class=" animate__animated animate__fadeIn">
    <div [formGroup]="partFormData"
    class=" animate__animated animate__fadeIn">

        <div>

            <div class="container">

                <div class="row bd-highlight" class="row-content-center">

                    <div>
                        <div style="margin-top: 5px; margin-bottom: 30px; margin-left: 5px;">                        
                            <p style="font-size: large;">Datos de pieza</p>
                        </div> 
                        <div>

                            <div class="row-content">

                                <mat-form-field style="width: 120px;" appearance="fill">
                                    <mat-label>Código pieza</mat-label>
                                    <input formControlName="codigo_pieza" matInput type="text" placeholder="Código pieza">
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Pieza</mat-label>
                                    <input formControlName="pieza" matInput type="text" placeholder="Pieza">
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Estado</mat-label>
                                    <input formControlName="state_pieza" matInput type="text" placeholder="Estado">
                                </mat-form-field>

                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>

    <ngx-spinner [bdColor] = "(part)?'rgba(0, 0, 0, 0.2)': 'rgba(255, 255, 255, 1)'" size = "large" color = "#368DCE" 
    type = "ball-scale-multiple" [fullScreen] = "false" name="partSpinner">
    </ngx-spinner>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="part">Cancelar</button>
    <button mat-button cdkFocusInitial
    matTooltip="Guardar cambios" 
    (click)="saveChanges()"> 
        <mat-icon color="primary">check</mat-icon> {{ (partId)?'Actualizar':'Añadir' }}
    </button>
</mat-dialog-actions>

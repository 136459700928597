import { Component, OnInit, ViewChild } from '@angular/core';
import { CalendarEvent, CalendarEventTimesChangedEvent, CalendarEventTimesChangedEventType, CalendarView } from 'angular-calendar';
import * as moment from 'moment';
import { ApiService } from '../../../services/api.service';
import { WaterTask } from 'src/app/interfaces/water-task';
import { UtilsService } from '../../../services/utils.service';
import { DateStatus } from '../../../interfaces/date-status';
import { NgxSpinnerService } from 'ngx-spinner';
import { MiRutaUser } from 'src/app/interfaces/mi-ruta-user';
import { FormGroup, FormControl } from '@angular/forms';
import { Moment } from 'moment';
import { MatMenuTrigger } from '@angular/material/menu';
import { IpcService } from '../../../services/ipc.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {


    // reference to the MatMenuTrigger in the DOM
    @ViewChild(MatMenuTrigger, { static: true }) eventMenuTrigger?: MatMenuTrigger;
    @ViewChild('segmentTrigger', { static: true }) segmentMenuTrigger?: MatMenuTrigger;

    settingsGroup: FormGroup = new FormGroup({
        date: new FormControl(),
        user: new FormControl(),
    });

    timerInputChanged: any;
    view: CalendarView = CalendarView.Day;
    viewDate: Date = new Date();
    events: CalendarEvent[] = [];
    dateStatuses: DateStatus[] = [];
    waterTasks: WaterTask[] = [];
    foundTasks: WaterTask[] = [];
    loadingText: string = 'Cargando...';
    users: MiRutaUser[] = [];

    menuOptions: string[] = [];
    menuTopLeftPosition = { x: '0', y: '0' };
    searchInput: any;

    searching: boolean = false;

    hourSegmentHeight = 30;
    minHour = 8;
    maxHour = 18;

    taskId: string = '';
    task?: WaterTask;

    /**
     * Represents the CalendarComponent class.
     * This component is responsible for managing the calendar functionality.
     */
    constructor(
        private _apiService: ApiService,
        private _utilsService: UtilsService,
        private _spinner: NgxSpinnerService,
        private router: Router,
        public electronService: IpcService,
        public location: Location,
        private route: ActivatedRoute,
    ) {
        this.route.params.subscribe((params) => { this.setCurrentTask(params); });
        this.checkIfUpdateViewNeeded();
    }

    /**
     * Sets the current task based on the provided parameters.
     * @param params - The parameters containing the task ID.
     * @returns A promise that resolves when the task is set.
     */
    async setCurrentTask(params: any): Promise<void> {
        this.taskId = params['id'];
        if(this.taskId) {
            this.task = await this._apiService.getTaskSync(this.taskId);
            if(this.task) this.foundTasks.push(this.task);
        }
    }

    /**
     * @brief Initializes the component.
     * This method is called after the component has been created and initialized.
     * It is used to perform any initialization logic that relies on data being available.
     * @returns A promise that resolves when the initialization is complete.
     */
    async ngOnInit(): Promise<void> {
        this.updateHourSegmentHeight();
        window.addEventListener('resize', this.updateHourSegmentHeight.bind(this));
        await this.initData();
    }

    /**
     * Updates the height of the hour segments based on the window height.
     */
    updateHourSegmentHeight(): void {
        this.hourSegmentHeight = window.innerHeight * 0.008;
    }

    /**
     * @brief Initializes the data for the calendar component.
     * Retrieves users and date statuses from the API service,
     * sets up group actions settings, and loads data.
     * @returns A promise that resolves when the data is initialized.
     */
    async initData(): Promise<void> {
        this.users = await this._apiService.getUsers(['operario']);
        this.dateStatuses = await this._apiService.getDateStatuses();
        this.setSettingsGroupActions();
        this.loadData();
    }

    /**
     * @brief Checks if an update to the view is needed when the visibility of the document changes.
     */
    checkIfUpdateViewNeeded(): void {
        document.addEventListener('visibilitychange', async () => {
            if (!document.hidden) { //isShown
                const updateNeeded = localStorage.getItem('taskUpdateNeeded');
                if (updateNeeded == 'true') {
                    localStorage.setItem('taskUpdateNeeded', 'false');
                    await this.initData();
                }
            }
        });
    }

    /**
     * @brief Loads the data for the calendar component.
     * Retrieves the selected user and date from the session storage,
     * and sets the view date and user in the settings group accordingly.
     */
    loadData(): void {
        const calendarSelectedDate = sessionStorage.getItem('calendarSelectedDate');
        if(calendarSelectedDate) {
            this.viewDate = new Date(calendarSelectedDate);
            this.settingsGroup.controls['date'].setValue(moment(this.viewDate));
        }
        else this.settingsGroup.controls['date'].setValue(moment(this.viewDate));
        
        const calendarSelectedUser = sessionStorage.getItem('calendarSelectedUser');
        if(calendarSelectedUser) {
            const miRutaUser: MiRutaUser = JSON.parse(calendarSelectedUser);
            const user = this.users.find(u => u.id === miRutaUser.id);
            this.settingsGroup.controls['user'].setValue(user);
        }
    }

    
    /**
     * @brief Gets the text line for the subscriber of a task.
     * @details This function calls the `_utilsService.getTaskSubscriberTextLine` function
     * to get the text line for the subscriber of the specified water task.
     * @param task - The water task for which to get the subscriber text line.
     * @returns The text line for the subscriber of the task.
     */
    getTaskSubscriberTextLine(task: WaterTask) {
        return this._utilsService.getTaskSubscriberTextLine(task);
    }

    /**
     * @brief Gets the text line for the counter of a task.
     * @details This function calls the `_utilsService.getTaskCounterTextLine` function
     * to get the text line for the counter of the specified water task.
     * @param task - The water task for which to get the counter text line.
     * @returns The text line for the counter of the task.
     */
    getTaskCounterTextLine(task: WaterTask) {
        return this._utilsService.getTaskCounterTextLine(task);
    }

    /**
     * Handles the change event of the input field.
     * @param text - The text entered in the input field.
     * @returns A Promise that resolves when the search tasks operation is complete.
     */
    async onChangeEvent(text: string): Promise<void> {
        clearTimeout(this.timerInputChanged);
        this.timerInputChanged = setTimeout(async () => {
            this.searching = true;
            if(text) this.foundTasks = await this._apiService.searchTasks(text);
            else if(this.task) {
                this.foundTasks = [this.task];
            }
            this.searching = false;
        }, 1000);
    }

    /**
     * Handles the selection of a task from the menu.
     * 
     * @param task - The selected WaterTask object.
     * @returns A Promise that resolves when the task date is updated.
     */
    async onMenuTaskSelected(task: WaterTask): Promise<void> {
        if(this.segmentMenuTrigger && task){
            const item = this.segmentMenuTrigger.menuData.item;
            const userId = this._apiService.getLoggedUserId();
            let data = { OPERARIO: this.getOperators(task) };

            if(item && item.date && (item.force || !task.fecha_hora_cita)) {
                data = { ...data, ...this.getDateData(item.date) };
            }
            this._spinner.show();
            this.loadingText = `Añadiendo cita ...`;
            const success = await this.updateTaskDate(data, task, userId);
            this._spinner.hide();
            if(success) this.updateView(task, data);
            else this._utilsService.openSnackBar('Error al añadir la cita', 'error');
        }
    }

    /**
     * Retrieves the operators for a given water task.
     * If the current user is not included in the operators list, it will be added.
     * @param task - The water task for which to retrieve the operators.
     * @returns An array of MiRutaUser objects representing the operators.
     */
    getOperators(task: WaterTask): MiRutaUser[] {
        const currentUser: MiRutaUser = this.settingsGroup.controls['user'].value;
        let operators = task.OPERARIO;
        if(operators && operators.length) {
            if (!operators.some(operator => operator.id === currentUser.id)) {
                operators.push(currentUser);
            }
        }
        else operators = [currentUser];
        return operators;
    }
    
    /**
     * Updates the view with the modified task data.
     * If the task already exists in the waterTasks array, it updates the task.
     * Otherwise, it adds the modified task to the waterTasks array.
     * It also updates the events based on the updated waterTasks array.
     * Finally, it updates the foundTasks array if the task exists in it.
     * 
     * @param task - The original task to be updated.
     * @param data - The modified data for the task.
     */
    updateView(task: WaterTask, data: any) {
        const modifiedTask = { ...task, ...data };
        let indexOfTask = this.waterTasks.findIndex(t => t.id === task.id);
        if(indexOfTask >= 0)  this.waterTasks[indexOfTask] = modifiedTask;
        else this.waterTasks.push(modifiedTask);
        this.setEvents(this.waterTasks);
        indexOfTask = this.foundTasks.findIndex(t => t.id === task.id);
        if(indexOfTask >= 0) this.foundTasks[indexOfTask] = modifiedTask;    
    }

    /**
     * Retrieves the date data for a given date.
     * @param date - The date for which to retrieve the data.
     * @returns An object containing the date data.
     */
    getDateData(date: Date) {
        const momentDatePlus20min = moment(date).add(20, 'minutes');
        let data: any = {};
        data['fecha_hora_cita'] = date;
        data['fecha_hora_cita_end'] = momentDatePlus20min.toDate();
        data['cita_pendiente'] = true;
        data['nuevo_citas'] = this._utilsService.getNewDateString(moment(date), momentDatePlus20min);
        data['date_status'] = this.dateStatuses.find(ds => ds.default);
        return data;
    }

    /**
     * @brief Handles the actions related to the settings group.
     * This method subscribes to the value changes of the 'date' and 'user' controls,
     * and updates the information based on the selected user and date.
     */
    async setSettingsGroupActions(): Promise<void> {
        this.settingsGroup.controls['date'].valueChanges.subscribe(async (date: Moment) => {
            const user: MiRutaUser = this.settingsGroup.controls['user'].value;
            await this.updateInfo(user, date);
        });
        this.settingsGroup.controls['user'].valueChanges.subscribe(async (user: MiRutaUser) => {
            const date: Moment = this.settingsGroup.controls['date'].value;
            await this.updateInfo(user, date);
        });
    }
    
    /**
     * @brief Handles the click event on the calendar component.
     * @param event - The mouse or keyboard event that triggered the click.
     * @param eventItem - The item associated with the clicked event.
     */
    onClick(event: MouseEvent | KeyboardEvent, eventItem: any): void {
        if (this.menuOptions.length == 0) return;
        this.menuTopLeftPosition.x = (event as PointerEvent).clientX + 'px';
        this.menuTopLeftPosition.y = (event as PointerEvent).clientY + 'px';
        if (this.eventMenuTrigger) {
            this.eventMenuTrigger.menuData = { item: eventItem };
            this.eventMenuTrigger.openMenu();
        }
    }

    /**
     * @brief Handles the selection of a menu option.
     * @param option - The selected menu option.
     * @returns A promise that resolves when the operation is complete.
     */
    async onMenuOptionSelected(option: string): Promise<void> {
        if(this.eventMenuTrigger) {
            const item = this.eventMenuTrigger.menuData.item;
            if(option === 'Modificar cita') {
                await this.assignDateToTask(item.id);
            } 
            else if (option === 'Abrir tarea') {
                this.openTask(item.id);
            }
            else if (option === 'Añadir tarea a este horario') {
                this.openSegmentMenu({ date: item, force: true });
            }
            else if (option === 'Añadir tarea con cita') {
                this.openSegmentMenu({ date: item, force: false });
            }
        }
    }

    /**
     * Opens the segment menu for the specified item.
     * @param item - The item for which the segment menu should be opened.
     */
    openSegmentMenu(item: any): void {
        if (this.segmentMenuTrigger) {
            this.segmentMenuTrigger.menuData = { item: item };
            this.segmentMenuTrigger.openMenu();
        }
    }

    
    /**
     * Handles the click event on an hour segment in the calendar.
     * 
     * @param {Object} options - The options for the click event.
     * @param {Date} options.date - The date of the clicked hour segment.
     * @param {MouseEvent} options.sourceEvent - The source event that triggered the click.
     */
    onHourSegmentClicked({ date, sourceEvent }: { date: Date; sourceEvent: MouseEvent }){
        this.menuOptions = ['Añadir tarea a este horario', 'Añadir tarea con cita'];
        this.onClick(sourceEvent, date);
    }

    /**
     * @brief Assigns a date to a task.
     * 
     * @param taskId - The ID of the task to assign the date to.
     * @returns A promise that resolves when the date is assigned to the task.
     */
    async assignDateToTask(taskId: number): Promise<void> {
        const indexOfTask = this.waterTasks.findIndex(task => task.id === taskId);
        if(indexOfTask >= 0) {
        let task = this.waterTasks[indexOfTask];
            if(task) {
                const userId = this._apiService.getLoggedUserId();
                let data = await this._utilsService.selectDateAppointment(task);
                if(data) {
                    data = { ...data, ultima_modificacion: userId };
                    this._spinner.show();
                    this.loadingText = `Actualizando cita ...`;
                    await this.updateTaskDate(data, task, userId);
                    this._spinner.hide();
                    this.waterTasks[indexOfTask] = { ...task, ...data };
                    this.setEvents(this.waterTasks);
                }
            }
        }
    }

    /**
     * @brief Updates the date of a task.
     * 
     * @param dateData - The updated date data.
     * @param task - The task to update.
     * @param userId - The ID of the user performing the update.
     * @returns A promise that resolves to the updated task if successful, otherwise false.
     */
    async updateTaskDate(dateData: any, task: WaterTask, userId: string){
        if(task){
            dateData = { ...dateData , ...this._utilsService.resetTaskPriority(task) };
            try {
                const data = { ...dateData , ultima_modificacion: userId };
                return await this._apiService.updateTask(task.id!.toString(), data, false);
            } catch (err) {}
        }
        return false;
    }

    /**
     * @brief Opens a task based on the provided taskId.
     * If the application is running as an Electron app, it navigates to the task route.
     * Otherwise, it opens a new browser window with the task route.
     * @param taskId - The ID of the task to open.
     */
    openTask(taskId: number) {
        if(taskId) {
            if (this.electronService.isElectronApp()) {
                this.router.navigate(['/task', taskId]);
            } else {
                const url = this.router.serializeUrl(this.router.createUrlTree(['/task', taskId]));
                window.open(url, '_blank');
            }
        }
    }

    /**
     * @brief Updates the information for the calendar component.
     * 
     * @param user - The MiRutaUser object representing the user.
     * @param date - The Moment object representing the selected date.
     * @returns A Promise that resolves to void.
     */
    async updateInfo(user: MiRutaUser, date: Moment): Promise<void> {
        if(user && date && date.isValid()) {
            this._spinner.show();
            this.loadingText = 'Obteniendo información...'
            this.waterTasks = await this._apiService.getOperatorWeek(user.id, date.format('YYYY-MM-DD HH:mm:ss'));
            this.setEvents(this.waterTasks, date.toDate());
            this.viewDate = date.toDate();
            sessionStorage.setItem('calendarSelectedDate', this.viewDate.toString());
            sessionStorage.setItem('calendarSelectedUser', JSON.stringify(user));
            this._spinner.hide();
        }
    }

    /**
     * @brief Sets the events for the calendar component.
     * 
     * @param tasks - An array of tasks to be displayed as events.
     */
    setEvents(tasks: any, date?: Date): void {
        if(date) this.viewDate = date;
        this.events = [];
        tasks.forEach((task: WaterTask) => {
            const color = (task.date_status && task.date_status!.status_color)
                ? task.date_status.status_color: this.dateStatuses.find(ds => ds.default)!.status_color!;
            this.events.push({
                id: task.id,
                start: task.fecha_hora_cita!,
                end: task.fecha_hora_cita_end!,
                title: this.getDirOfTask(task),
                cssClass: (task.date_status?.dark_text)? 'calendar-text-yellow-background': 'calendar-text',
                color: { primary: color, secondary: color },
                draggable: true,
                resizable: { beforeStart: true, afterEnd: true },
                meta: { task: task },
            });
        });
    }

    /**
     * @brief Handles the click event on a calendar event.
     * 
     * @param event - The calendar event that was clicked.
     * @param sourceEvent - The source event that triggered the click (e.g., MouseEvent or KeyboardEvent).
     * @returns A Promise that resolves when the click event is handled.
     */
    async onEventClicked({ event, sourceEvent }
        : { event: CalendarEvent; sourceEvent: MouseEvent | KeyboardEvent; }): Promise<void> {
        this.menuOptions = ['Abrir tarea', 'Modificar cita'];
        this.onClick(sourceEvent, event);
    }
    
    /**
     * @brief Prevents the default behavior of an event.
     * 
     * @param event - The event object.
     */
    preventDefault(event: any) {
        event.preventDefault();
    }

    /**
     * @brief Handles the event when the times of a calendar event are changed.
     * @param event - The event containing the changed times and event details.
     * @returns A promise that resolves when the event times have been handled.
     */
    async onEventTimesChanged(event: CalendarEventTimesChangedEvent<CalendarEvent>): Promise<void> {
        if(event.type === CalendarEventTimesChangedEventType.Drag){
            await this.onDragEnd(event);
        }
        if(event.type === CalendarEventTimesChangedEventType.Resize){
            await this.onResizeEnd(event);
        }
    }

    /**
     * @brief Handles the resize end event for a calendar event.
     * 
     * @param resizeEvent - The resize event containing the updated times for the event.
     * @returns A promise that resolves when the event handling is complete.
     */
    async onResizeEnd(resizeEvent: CalendarEventTimesChangedEvent<CalendarEvent>): Promise<void> {
        await this.onDateChangeEvent(resizeEvent);
    }

    /**
     * @brief Handles the drag end event for a calendar event.
     * 
     * @param dragEvent - The event containing the dragged calendar event and the new times.
     * @returns A promise that resolves when the event handling is complete.
     */
    async onDragEnd(dragEvent: CalendarEventTimesChangedEvent<CalendarEvent>): Promise<void> {
        await this.onDateChangeEvent(dragEvent);
    }

    /**
     * @brief Handles the event when the date changes in the calendar.
     * @param event - The event object containing the updated calendar event.
     * @returns A promise that resolves when the date change is processed.
     */
    async onDateChangeEvent(event: CalendarEventTimesChangedEvent<CalendarEvent>): Promise<void>{
        this._spinner.show();
        this.loadingText = 'Actualizando cita...'
        if(event.event.id){
            const task = this.waterTasks.find(task => task.id === event.event.id);
            if(task && this.isModified(task, event)) {
                task.fecha_hora_cita = event.newStart;
                task.fecha_hora_cita_end = event.newEnd;
                const userId = this._apiService.getLoggedUserId();
                const data = {
                    fecha_hora_cita: event.newStart,
                    fecha_hora_cita_end: event.newEnd,
                    nuevo_citas: this._utilsService.getNewDateString(moment(event.newStart), moment(event.newEnd)),
                    ultima_modificacion: userId
                }
                this.setEvents(this.waterTasks);
                await this.updateTaskDate(data, task, userId);
            }
        }
        this._spinner.hide();
    }
    
    /**
     * @brief Checks if a task has been modified based on the given event.
     * @param task - The task to check for modification.
     * @param event - The event containing the new start and end times.
     * @returns A boolean value indicating whether the task has been modified.
     */
    isModified(task: WaterTask, event: CalendarEventTimesChangedEvent<CalendarEvent>): boolean {
        if(task.fecha_hora_cita && task.fecha_hora_cita_end && event.newEnd) {
            return task.fecha_hora_cita.getTime() != event.newStart.getTime() 
                    || task.fecha_hora_cita_end.getTime() != event.newEnd.getTime() ;
        }
        return true;
    }

    /**
     * @brief Gets the direction of a task.
     * @details This function calls the `_utilsService.getDirOfTask` function
     * to get the direction of the specified water task.
     * @param task - The water task for which to get the direction.
     * @returns The direction of the task.
     */
    getDirOfTask(task: WaterTask) {
        return this._utilsService.getDirOfTask(task, false);
    }
}

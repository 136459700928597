import { Component, Inject, OnInit } from '@angular/core';
import { WaterTask } from 'src/app/interfaces/water-task';
import { ServicesInformData } from '../services-inform/services-inform.component';
import { UtilsService } from 'src/app/services/utils.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';

@Component({
    selector: 'app-common-services-inform',
    templateUrl: './common-services-inform.component.html',
    styleUrls: ['./common-services-inform.component.scss']
})
export class CommonServicesInformComponent implements OnInit {

    taskFormData: FormGroup;
    task: WaterTask = {};

    suplies: string[];
    services: string[];

    controlKeys = [
        'FECH_CIERRE',
        'fecha_realizacion',
        'fecha_informe_servicios',
        'OBSERVA',
        'servicios',
        'last_service',
        'suministros',
    ]

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ServicesInformData,
        private _utilsService: UtilsService
        ) {
        this.task = data.task;
        this.suplies = this._utilsService.suplies;
        this.services = this._utilsService.services;
        this.taskFormData = this.getFormControls();
        this.taskFormData = this.getFormFromTask(this.task!);
    }

    ngOnInit(): void {
    }

    getFormControls(): FormGroup {
        const now = new Date();
        const taskFormData: FormGroup = new FormGroup({
            FECH_CIERRE: new FormControl(now),
            fecha_realizacion: new FormControl(now),
            fecha_informe_servicios: new FormControl(now),
            OBSERVA: new FormControl(),
            servicios: new FormControl(),
            last_service: new FormControl(),
            suministros: new FormControl()
        });
        return taskFormData;
    }

    getFormFromTask(task: WaterTask): FormGroup {
        const taskFormData = this.getFormControls();
        const keys = Object.keys(task);
        for (let key of keys) {
            let value: any = task[key as keyof WaterTask];
            if (value && this.controlKeys.includes(key)) {
                if (
                    Array.isArray(value) &&
                    value.length > 0 &&
                    Object.keys(value[0]).includes('value')
                ) {
                    if (value.length > 0) {
                        let values = [];
                        for (const v of value) {
                            const keysInside = Object.keys(v);
                            if (keysInside.includes('value')) values.push(v['value']);
                        }
                        taskFormData.controls[key].setValue(values);
                    }
                } else {
                    try {
                        taskFormData.controls[key].setValue(value);
                    } catch (err) {
                        console.log('************* err *************');
                        console.log(key);
                        console.log(err);
                    }
                }
            }
        }
        return taskFormData;
    }

    saveFormData() {
        const keys = Object.keys(this.taskFormData.controls);
        for (let key of keys) {
            let value = this.taskFormData.controls[key].value; 
            try {
                if (Array.isArray(value) && value.length > 0) {
                    let values = [];
                    for (const v of value) if (typeof v == 'string') values.push({ value: v });
                    value = values;
                } else if (moment.isMoment(value)) value = value.toDate();
                if(value) this.task[key as keyof WaterTask] = value;
            } catch (err) {}
        }
        if (this.task.servicios?.length) this.task.last_service = this.task.servicios[0].value;
    }

    async retrieveData() {
        this.saveFormData(); 
        this._utilsService.closeCommonServicesInformDialog(this.task);
    }
}

import { FormControl, FormGroup } from '@angular/forms';
import { MiRutaUser } from './mi-ruta-user';
import { Sector } from './sector';

export interface Side {
    ID: number;
    MUNICIPIO: string;
    CALLE: string;
    NUME: string;
    BIS: string;
    codigo_de_geolocalizacion: string;
    codigo_itac: string;
    total: string;
    foto_1: string;
    acceso_devuelto: string;
    fecha_hora_cita: Date;
    fecha_hora_cita_end: Date;
    advice_portal: Date;
    advice_portal_end: Date;
    equipo: number;
    sectors: Sector[] | string;
    OPERARIO: MiRutaUser[] | string;
}

export function getSideDisplayColumns(): string[] {
    const displayedColumns: string[] = [
        'ID', 
        'MUNICIPIO', 
        'CALLE', 
        'NUME', 
        'BIS', 
        'C.EMPLAZAMIENTO', 
        'TOTAL', 
        'EQUIPO', 
        'PERIMETROS', 
        'OPERARIO', 
        'ACCESO', 
        'Cita', 
        'Cita fin', 
        'Aviso portal', 
        'Aviso portal fin', 
    ];
    return displayedColumns;
}

export function getSideField(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'ID':
            value = 'ID';
            break;
        case 'MUNICIPIO':
            value = 'MUNICIPIO';
            break;
        case 'CALLE':
            value = 'CALLE';
            break;
        case 'NUME':
            value = 'NUME';
            break;
        case 'BIS':
            value = 'BIS';
            break;
        case 'C.EMPLAZAMIENTO':
            value = 'codigo_de_geolocalizacion';
            break;
        case 'TOTAL':
            value = 'total';
            break;
        case 'ACCESO':
            value = 'acceso_devuelto';
            break;
        case 'Cita':
            value = 'fecha_hora_cita';
            break;
        case 'Cita fin':
            value = 'fecha_hora_cita_end';
            break;
        case 'Aviso portal':
            value = 'advice_portal';
            break;
        case 'Aviso portal fin':
            value = 'advice_portal_end';
            break;
        case 'EQUIPO':
            value = 'equipo';
            break;
        case 'OPERARIO':
            value = 'OPERARIO';
            break;
        case 'PERIMETROS':
            value = 'sectors';
            break;
        case 'sectors':
            value = 'sectors';
            break;
    }
    return value;
}

export function getSideOrderField(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'ID':
            value = 'ID';
            break;
        case 'MUNICIPIO':
            value = 'MAX(waterTask.MUNICIPIO)';
            break;
        case 'CALLE':
            value = 'MAX(waterTask.CALLE)';
            break;
        case 'NUME':
            value = 'MAX(waterTask.NUME)';
            break;
        case 'BIS':
            value = 'MAX(waterTask.BIS)';
            break;
        case 'Cita':
            value = 'MAX(waterTask.fecha_hora_cita)';
            break;
        case 'Cita fin':
            value = 'MAX(waterTask.fecha_hora_cita_end)';
            break;
        case 'Aviso portal':
            value = 'itac.fecha_hora_cita';
            break;
        case 'Aviso portal fin':
            value = 'itac.fecha_hora_cita_end';
            break;
        case 'ACCESO':
            value = 'MAX(waterTask.acceso_devuelto)';
            break;
        case 'C.EMPLAZAMIENTO':
            value = 'codigo_de_geolocalizacion';
            break;
        case 'EQUIPO':
            value = 'equipo';
            break;
        case 'OPERARIO':
            value = 'OPERARIO';
            break;
        case 'PERIMETROS':
            value = 'sectors';
            break;
    }
    return value;
}

export function getSideFieldName(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'term':
            value = 'Búsqueda';
            break;
        case 'ID':
            value = 'ID';
            break;
        case 'MUNICIPIO':
            value = 'MUNICIPIO';
            break;
        case 'CALLE':
            value = 'CALLE';
            break;
        case 'NUME':
            value = 'NUME';
            break;
        case 'BIS':
            value = 'BIS';
            break;
        case 'codigo_de_geolocalizacion':
            value = 'C.EMPLAZAMIENTO';
            break;
        case 'total':
            value = 'TOTAL';
            break;
        case 'acceso_devuelto':
            value = 'ACCESO';
            break;
        case 'fecha_hora_cita':
            value = 'Cita';
            break;
        case 'fecha_hora_cita_end':
            value = 'Cita fin';
            break;
        case 'advice_portal':
            value = 'Aviso portal';
            break;
        case 'advice_portal_end':
            value = 'Aviso portal fin';
            break;
        case 'equipo':
            value = 'EQUIPO';
            break;
        case 'OPERARIO':
            value = 'OPERARIO';
            break;
        case 'PERIMETROS':
            value = 'sectors';
            break;
    }
    return value;
}

export function getSideFormControls(): FormGroup {
    const typeRadiusFormData: FormGroup = new FormGroup({
        MUNICIPIO: new FormControl(),
        CALLE: new FormControl(),
        NUME: new FormControl(),
        BIS: new FormControl(),
        codigo_de_geolocalizacion: new FormControl(),
        total: new FormControl(),
        acceso_devuelto: new FormControl(),
        fecha_hora_cita: new FormControl(),
        fecha_hora_cita_end: new FormControl(),
        advice_portal: new FormControl(),
        advice_portal_end: new FormControl(),
        equipo: new FormControl(),
        OPERARIO: new FormControl(),
        sectors: new FormControl(),
    });
    return typeRadiusFormData;
}

export function getSideFieldType(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'ID':
            value = 'number';
            break;
        case 'MUNICIPIO':
            value = 'string';
            break;
        case 'CALLE':
            value = 'string';
            break;
        case 'NUME':
            value = 'string';
            break;
        case 'BIS':
            value = 'string';
            break;
        case 'codigo_de_geolocalizacion':
            value = 'string';
            break;
        case 'total':
            value = 'string';
            break;
        case 'acceso_devuelto':
            value = 'string';
            break;
        case 'fecha_hora_cita':
            value = 'Date';
            break;
        case 'fecha_hora_cita_end':
            value = 'Date';
            break;
        case 'advice_portal':
            value = 'Date';
            break;
        case 'advice_portal_end':
            value = 'Date';
            break;
        case 'equipo':
            value = 'number';
            break;
        case 'OPERARIO':
            value = 'string';
            break;
        case 'sectors':
            value = 'string';
            break;
        case 'sector':
            value = 'string';
            break;
        case 'user':
            value = 'string';
            break;
        case 'supplies_tasks':
            value = 'string';
            break;
        case 'client_phone_status':
            value = 'string';
            break;
    }
    return value;
}

export function getSideExcelFieldName(excel_field: string): string {
    let value: string = '';
    switch (excel_field.trim().toUpperCase()) {
        case 'MUNICIPIO':
            value = 'MUNICIPIO';
            break;
        case 'CALLE':
            value = 'CALLE';
            break;
        case 'NUME':
            value = 'NUME';
            break;
        case 'BIS':
            value = 'BIS';
            break;
        case 'C.EMPLAZAMIENTO':
            value = 'codigo_de_geolocalizacion';
            break;
        case 'TOTAL':
            value = 'total';
            break;
        case 'ACCESO':
            value = 'acceso_devuelto';
            break;
        case 'Cita':
            value = 'fecha_hora_cita';
            break;
        case 'Cita fin':
            value = 'fecha_hora_cita_end';
            break;
        case 'Aviso portal':
            value = 'fecha_hora_cita';
            break;
        case 'Aviso portal fin':
            value = 'fecha_hora_cita_end';
            break;
        case 'OPERARIO':
            value = 'OPERARIO';
            break;
        case 'PERIMETROS':
            value = 'sectors';
            break;
        case 'EQUIPO':
            value = 'equipo';
            break;
    }
    return value;
}

export function getSideExcelExportColumns(): string[] {
    const columns = [
        'MUNICIPIO', 
        'CALLE', 
        'NUME', 
        'BIS', 
        'C.EMPLAZAMIENTO', 
        'ACCESO', 
        'Cita', 
        'Cita fin', 
        'Aviso portal', 
        'Aviso portal fin', 
        'TOTAL', 
        'EQUIPO', 
        'OPERARIO', 
        'PERIMETROS', 
    ];
    return columns;
}
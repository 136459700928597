import { Component, OnInit, ViewChild } from '@angular/core';
import { GoogleMap, MapInfoWindow } from '@angular/google-maps';
import { MatDialogRef } from '@angular/material/dialog';
import { Prediction } from 'src/app/interfaces/place-predictions';
import { FilterComponent } from '../filter/filter.component';
import { PlaceDetails } from 'src/app/interfaces/place-details';
import { UtilsService } from 'src/app/services/utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormControl, FormGroup } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { faEraser, faPen, faSave } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-assign-zone-location',
    templateUrl: './assign-zone-location.component.html',
    styleUrls: ['./assign-zone-location.component.scss'],
})
export class AssignZoneLocationComponent implements OnInit {
    @ViewChild('map', { static: false }) googlemaps!: google.maps.Map;
    @ViewChild(MapInfoWindow, { static: false }) infoWindow!: MapInfoWindow;

    inputSearchControl = new FormControl();
    placePredictions: Prediction[] = [];
    timerInputChanged: any;

    markers: google.maps.Marker[] = [];
    markerOptionsMap: Map<string, google.maps.MarkerOptions> = new Map<
        string,
        google.maps.MarkerOptions
    >();
    loading: boolean = false;
    loadingMarkerInfo: boolean = false;
    zoom = 15;
    center!: google.maps.LatLngLiteral; // = new google.maps.LatLng(43.2633182, -2.9349041608216098);

    options: google.maps.MapOptions;

    autocomplete?: any;
    formData: FormGroup = new FormGroup({
        address: new FormControl(),
    });

    polylineOptionsDrawing: any;
    polygons: any[] = [];

    drawingActive: boolean = false;
    painting: boolean = false;

    faPen = faPen;
    faEraser = faEraser;
    faSave = faSave;

    constructor(
        private _apiService: ApiService,
        private _utilsService: UtilsService,
        private spinner: NgxSpinnerService
    ) {
        this.options = this.getDefaultMapOptions();
    }

    dblclick(event: any) {
        this.painting = false;
    }

    mouseMove(event: google.maps.MapMouseEvent) {
        if (!this.painting) return;
        if (!this.drawingActive) return;
        if (!this.polylineOptionsDrawing) return;

        let coordinates: any = this.polylineOptionsDrawing.path;
        coordinates.push(
            new google.maps.LatLng(event?.latLng?.lat() || 0, event?.latLng?.lng() || 0)
        );
        this.polylineOptionsDrawing = this.getPolylineOptionsDrawing(coordinates);
    }

    toggleDrawing() {
        this.drawingActive = !this.drawingActive;
        if (this.drawingActive) this.desactivePainting();
        else this.activePainting();
    }

    activePainting() {
        this.painting = false;
        this.polylineOptionsDrawing = null;
        this.zoom = this.googlemaps.getZoom() || 9;
        this.center = this.getMapCurrentCenter();
        this.options = this.getDefaultMapOptions();
    }

    getMapCurrentCenter() {
        return {
            lat: this.googlemaps.getCenter()?.lat() || 0,
            lng: this.googlemaps.getCenter()?.lng() || 0,
        };
    }

    desactivePainting() {
        this.zoom = this.googlemaps.getZoom() || 9;
        this.center = this.getMapCurrentCenter();
        this.options = this.getPaintingMapOptions();
        this.painting = true;
    }

    async searchPlace(prediction: Prediction) {
        const place: PlaceDetails = await this._apiService.searchPlace(prediction.place_id);
        if (place) {
            const lat = place.result.geometry.location.lat; //lat drop
            const lng = place.result.geometry.location.lng; //lng drop
            this.center = {
                lat: lat, //position.coords.latitude,
                lng: lng, //position.coords.longitude,
            };
        }
    }

    async ngOnInit(): Promise<void> {
        this.inputSearchControl.valueChanges.subscribe(async (value: any) => {
            clearTimeout(this.timerInputChanged);
            this.timerInputChanged = setTimeout(async () => {
                try {
                    this.placePredictions = await this._apiService.searchPrediction(value);
                } catch (err) {}
            }, 1000);
        });
    }

    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this.spinner.show('mapSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this.spinner.hide('mapSpinner');
        }
    }

    async mapDblclick(event: google.maps.MapMouseEvent) {}

    click(event: google.maps.MapMouseEvent) {
        if (this.drawingActive) {
            if (this.polylineOptionsDrawing) {
                this.toggleDrawing();
            } else {
                let coordinates: any = [];
                coordinates.push(
                    new google.maps.LatLng(event?.latLng?.lat() || 0, event?.latLng?.lng() || 0)
                );
                this.polylineOptionsDrawing = this.getPolylineOptionsDrawing(coordinates);
            }
        }
    }

    getPaintingMapOptions() {
        return {
            mapTypeId: 'hybrid',
            draggable: false,
            zoomControl: false,
            scrollwheel: false,
            disableDoubleClickZoom: true,
            draggableCursor: 'url(assets/img/markers/grease-pencil.png), auto',
        };
    }

    getDefaultMapOptions() {
        return {
            mapTypeId: 'hybrid',
            center: new google.maps.LatLng(43.2633182, -2.9349041608216098),
            draggable: true,
            zoomControl: true,
            scrollwheel: true,
            disableDoubleClickZoom: true,
            draggableCursor: 'default',
        };
    }

    getPolylineOptionsDrawing(coordinates: any = []) {
        return {
            path: coordinates,
            strokeColor: '#FFFFFF',
            strokeOpacity: 1.0,
            strokeWeight: 8,
            fillColor: '#368DCE',
            fillOpacity: 0.35,
            zIndex: 3,
        };
    }

    getDrewZone() {
        let path: any[] = [];
        for (let geopoint of this.polylineOptionsDrawing.path) {
            let latLang = this._utilsService.createLatLng(geopoint.lat(), geopoint.lng());
            path.push({ value: latLang });
        }
        if (path.length) this._utilsService.closeMapDialog(path);
        else this._utilsService.closeMapDialog(null);
    }
}

<mat-dialog-content>

    <div [formGroup]="itacFormData">
        
        <div class="container">
            
            <div class="row bd-highlight" class="row-content-center">
                
                
                <p style="font-size: large; margin: 6px; margin-bottom: 20px;">
                    Sección 1 Acceso y ubicación
                </p>
                
                <div class="row">
                    <mat-form-field appearance="fill">
                        <mat-label>Ubicación</mat-label>
                        <mat-select formControlName="acceso_ubicacion_ubicacion" #selectLocation>
                            <mat-option *ngFor="let option of ['Dentro de Edificio', 'Exterior']" [value]="option">{{ option }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field *ngIf="selectLocation.value == 'Dentro de Edificio'" appearance="fill">
                        <mat-label>Ubicación Dentro del Edificio</mat-label>
                        <mat-select formControlName="extra_acceso_ubicacion">
                            <mat-option *ngFor="let option of ['Portal','Garaje','Sótano','Planta','Descansillo','Entreplanta','Entresuelo','Otros']" [value]="option">{{ option }}</mat-option>
                        </mat-select>
                    </mat-form-field>  
                    <mat-form-field *ngIf="selectLocation.value == 'Exterior'" appearance="fill">
                        <mat-label>Ubicación en Exterior</mat-label>
                        <mat-select formControlName="extra_acceso_ubicacion">
                            <mat-option *ngFor="let option of ['Armario o Nicho','Caseta','Arqueta','Aire Libre','Cuarto','Otros']" [value]="option">{{ option }}</mat-option>
                        </mat-select>
                    </mat-form-field> 
                    <mat-form-field appearance="fill">
                        <mat-label>Acceso</mat-label>
                        <mat-select formControlName="acceso_ubicacion_acceso">
                            <mat-option *ngFor="let option of ['Libre Acceso', 'Dentro de Recinto Cerrado']" [value]="option">{{ option }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                
                <div class="row">
                    <mat-form-field style="flex: 3;" appearance="fill">
                        <mat-label>Nota</mat-label>
                        <textarea matInput formControlName="acceso_ubicacion_nota"
                            placeholder="Nota"
                            cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="3">
                        </textarea>
                    </mat-form-field>  
                </div>                    
                
            </div>
            
        </div>

    </div>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false">Cancelar</button>
    <button mat-button cdkFocusInitial
    (click)="saveData()"> 
        <mat-icon color="primary">check</mat-icon> Actualizar
    </button>
</mat-dialog-actions>

<ngx-spinner [bdColor] = "(itac)?'rgba(0, 0, 0, 0.2)': 'rgba(255, 255, 255, 1)'" size = "large" color = "#368DCE" 
type = "ball-scale-multiple" [fullScreen] = "true" name="itacSpinner">
</ngx-spinner>
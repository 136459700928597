<h3 mat-dialog-title>
    <b>{{ title }}</b>
</h3>
<mat-dialog-content style="align: center">
    <div style="margin: 5px; align: center">
        <canvas id="canvas" style="padding: 5px; align: center" width="350" height="150"></canvas>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Aceptar</button>
</mat-dialog-actions>

<mat-dialog-content [formGroup]="dateControl">
    <mat-form-field appearance="fill" style="padding: 10px">
        <mat-label>{{ placeHolderText }}</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="dateValue" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-dialog-content>
        <mat-dialog-actions align="center">
            <button mat-button mat-dialog-close>Cancelar</button>
            <button
                mat-button
                cdkFocusInitial
                [mat-dialog-close]="dateControl.controls['dateValue'].value.toDate()"
            >
                Aceptar
            </button>
        </mat-dialog-actions>
    </mat-dialog-content>
</mat-dialog-content>

import { FormControl, FormGroup } from '@angular/forms';

export interface Observation {
    codigo_observacion?: string;
    date_time_modified?: Date;
    id?: string;
    observacion?: string;
    origen?: string;
}

export function getObservationDisplayColumns(): string[] {
    const displayedColumns: string[] = [
        'Código observación',
        'Observación',
        'Origen',
        'Fecha de Modificación',
    ];
    return displayedColumns;
}

export function getObservationField(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'Código observación':
            value = 'codigo_observacion';
            break;
        case 'Observación':
            value = 'observacion';
            break;
        case 'Origen':
            value = 'origen';
            break;
        case 'Fecha de Modificación':
            value = 'date_time_modified';
            break;
    }
    return value;
}

export function getObservationFieldName(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'codigo_observacion':
            value = 'Código observación';
            break;
        case 'observacion':
            value = 'Observación';
            break;
        case 'origen':
            value = 'Origen';
            break;
        case 'date_time_modified':
            value = 'Fecha de Modificación';
            break;
    }
    return value;
}

export function getObservationFormControls(): FormGroup {
    const observationFormData: FormGroup = new FormGroup({
        observacion: new FormControl(),
        codigo_observacion: new FormControl(),
        origen: new FormControl(),
        date_time_modified: new FormControl(),
        id: new FormControl(),
    });
    return observationFormData;
}

export function getObservationFieldType(field_name: string): string {
    //in bigquery
    let value: string = '';
    switch (field_name) {
        case 'codigo_observacion':
            value = 'string';
            break;
        case 'observacion':
            value = 'string';
            break;
        case 'origen':
            value = 'string';
            break;
        case 'date_time_modified':
            value = 'Date';
            break;
        case 'id':
            value = 'number';
            break;
    }
    return value;
}

export function getObservationExcelFieldName(excel_field: string): string {
    let value: string = '';
    switch (excel_field.trim().toLowerCase()) {
        case 'observacion':
            value = 'observacion';
            break;
        case 'observación':
            value = 'observacion';
            break;
        case 'origen':
            value = 'origen';
            break;
        case 'código observacion':
            value = 'codigo_observacion';
            break;
        case 'codigo observacion':
            value = 'codigo_observacion';
            break;
        case 'codigo observación':
            value = 'codigo_observacion';
            break;
        case 'fecha de modificación':
            value = 'date_time_modified';
            break;
        case 'fecha de modificacion':
            value = 'date_time_modified';
            break;
        case 'codigo_observacion':
            value = 'codigo_observacion';
            break;
        case 'date_time_modified':
            value = 'date_time_modified';
            break;
    }
    return value;
}

export function getObservationExcelExportColumns(): string[] {
    const columns = ['Código observación', 'Observación', 'origen', 'Fecha de Modificación'];
    return columns;
}

import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '../services/utils.service';

@Pipe({
  name: 'taskStatus'
})
export class TaskStatusPipe implements PipeTransform {

  constructor(private _utilsService: UtilsService){
    
  }
  transform(value: any, ...args: unknown[]): string {
    const taskStatusString = this._utilsService.getTaskStatusFromInt(value);
    return taskStatusString;
  }

}

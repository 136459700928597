<div *ngIf="electronService.isElectronApp()" class="floating-top-left" (click)="location.back()">
    <button mat-fab color="primary">
        <mat-icon style="margin-left: 10px" matTooltip="Ir atrás">arrow_back_ios</mat-icon>
    </button>
</div>

<div class="row">
    <div [formGroup]="timeGroup"
        [ngClass]="{
            'white_background': !timeFullScreen, 
            'full_screen': timeFullScreen
        }">
        <div class="tag_container">
            <h3 class="h3_title">
                Actividad diaria
            </h3>
            <div></div>
            <mat-form-field appearance="standard" style="width: 60px">
                <mat-label>Tipo</mat-label>
                <mat-select formControlName="type">
                    <mat-option *ngFor="let type of chartTypes" [value]="type">{{ type }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Fecha</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="date">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Operario</mat-label>
                <mat-select formControlName="user">
                    <mat-option *ngFor="let user of users" [value]="user">{{ user | user }}</mat-option>
                </mat-select>
            </mat-form-field>
            <button mat-icon-button matTooltip="Pantalla completa" color="primary"
                (click)="toggleTimeFullScreen();">
                <mat-icon *ngIf="!timeFullScreen">open_in_new</mat-icon>
                <mat-icon *ngIf="timeFullScreen">minimize</mat-icon>
            </button>
        </div>
        <div class="chart_container">
                <canvas *ngIf="!loadingTime" baseChart
                    [options]="barChartOptions"
                    [datasets]="timeChartData"
                    [labels]="timeChartLabels"
                    [legend]="timeChartLegend"
                    [type]="timeChartType">
                </canvas>
                <div *ngIf="loadingTime">
                    <mat-spinner diameter="50"></mat-spinner>
                </div>
        </div>
    </div>
</div>

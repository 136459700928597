<mat-dialog-content>
    <div style="display: flex;">

        <div style="flex: 1;">
            <div style="margin: 10px; display: flex;">
                <h2>Piezas de tarea</h2>   
                <button mat-mini-fab color="primary" style="margin-left: 20px;"
                aria-label="Open parts selection"
                (click)="selectPart();" matTooltip="Añadir piezas">
                    <mat-icon style="font-size: 22px;">add</mat-icon>
                </button>
            </div>
            <mat-list>
                <mat-list-item *ngFor="let partInTask of partsInTask">
                    {{ partInTask }}                
                    <button mat-flat-button (click)="deletePart(partInTask);">
                        <mat-icon>delete</mat-icon>
                    </button>
                </mat-list-item>
            </mat-list>

            <div *ngIf="partsInTask.length == 0" style="display: flex; margin: 20px; margin-bottom: 30px;">
                <div style="margin: auto;">
                    <div style="text-align: center;">
                        <fa-icon style="font-size: 40px; color: #777777;" [icon]="faInbox"></fa-icon>
                    </div>
                    <div style="text-align: center; margin: 10px;">
                        <span style="font-size: 16px; color: #777777">No hay piezas</span>
                    </div>
                </div>                     
            </div>  

        </div>

    </div>
</mat-dialog-content>

<mat-dialog-actions align="center">
    <button mat-button mat-dialog-close>Cancelar</button>
    <button mat-button cdkFocusInitial (click)="retrieveData()">Aceptar</button>
</mat-dialog-actions>
    
<ngx-spinner [bdColor] = "'rgba(0, 0, 0, 0.2)'" size = "large" color = "#368DCE" 
    type = "ball-scale-multiple" [fullScreen] = "false" name="spinner">
</ngx-spinner>
/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import * as moment from 'moment';
import { Moment } from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import {
    Mark,
    getMarkDisplayColumns,
    getMarkExcelExportColumns,
    getMarkExcelFieldName,
    getMarkField,
    getMarkFieldType,
} from 'src/app/interfaces/mark';
import { ApiService } from 'src/app/services/api.service';
import { MessagingService } from 'src/app/services/messaging.service';
import { UtilsService } from 'src/app/services/utils.service';
import { WindowRefService } from 'src/app/services/window-ref.service';
import * as XLSX from 'xlsx';
import { faInbox } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-marks',
    templateUrl: './marks.component.html',
    styleUrls: ['./marks.component.scss'],
})
export class MarksComponent implements OnInit {
    loading: boolean = true;
    tableName: string = 'marks';
    marksInPage: Mark[] = [];
    dataSource: MatTableDataSource<Mark> = new MatTableDataSource();

    length = 0; //task count in current table
    pageSize = 200; //limit of query
    lastPageIndex = 0;
    pageSizeOptions: number[] = [10, 50, 100, 200, 500];

    displayedColumns: string[] = getMarkDisplayColumns();
    fixedColumns = [];
    displayedColumnsField: string[] = this.displayedColumns.map((displayedColumn: string) =>
        getMarkField(displayedColumn)
    );
    clickedRows = new Set<Mark>();

    lastSelectedRow: number = -1;

    filteredColumn?: string;
    orderedColumn?: string;

    loadingText = 'Cargando...';

    faInbox = faInbox;

    constructor(
        private _apiService: ApiService,
        private _utilsService: UtilsService,
        private _windowRefService: WindowRefService,
        private _spinner: NgxSpinnerService,
        private _messagingService: MessagingService
    ) {}

    async ngOnInit(): Promise<void> {
        await this.getMarks();
    }

    async fileEvent(event: any) {
        if (event['file_option'] == 'Importar marcas') {
            this.processExcelFile(event);
        }
    }
    async processExcelFile(event: any) {
        this._spinner.show();
        this.loadingText = `Cargando ...`;
        //TODO: add marks from excel
        let workBook: XLSX.WorkBook;
        let jsonData = null;
        const reader = new FileReader();
        const file = event['file'].target.files[0];
        reader.onload = async (_) => {
            this._spinner.show();
            this.loadingText = `Procesando marcas ...`;
            const data = reader.result;
            workBook = XLSX.read(data, { type: 'binary' });
            jsonData = workBook.SheetNames.reduce((initial: any, name) => {
                const sheet = workBook.Sheets[name];
                initial[name] = XLSX.utils.sheet_to_json(sheet);
                return initial;
            }, {});
            let sheets: string[] = [];
            let arrayMarks: Mark[] = [];

            Object.keys(jsonData).forEach((key: string) => {
                sheets.push(key);
            });
            for (let sheet of sheets) {
                for (let jsonMark of jsonData[sheet]) {
                    let markData: any = {};
                    Object.keys(jsonMark).forEach((key) => {
                        let field: string = getMarkExcelFieldName(key);
                        if (this._utilsService.isFieldValid(field)) {
                            let value = jsonMark[key];
                            try {
                                if (typeof value === 'number') {
                                    if (getMarkFieldType(field) == 'number') {
                                        markData[`${field}`] = value;
                                    } else {
                                        markData[`${field}`] = value.toString().trim();
                                    }
                                } else if (
                                    typeof value === 'string' &&
                                    this._utilsService.isFieldValid(value)
                                ) {
                                    if (getMarkFieldType(field) == 'string') {
                                        markData[`${field}`] = value.trim();
                                    } else if (getMarkFieldType(field) == 'boolean') {
                                        markData[`${field}`] =
                                            value.trim().toUpperCase() == 'SI' ? true : false;
                                    } else {
                                        markData[`${field}`] = parseInt(value);
                                    }
                                }
                            } catch (error) {
                                console.log(value);
                            }
                        }
                    });
                    let mark = markData as Mark;
                    if (mark && this._utilsService.isFieldValid(mark.codigo_marca)) {
                        mark.codigo_marca = this._utilsService.addCeros(mark.codigo_marca, 3);
                        arrayMarks.push(mark);
                    }
                }
            }
            console.log('============= arrayMarks =============');
            console.log(arrayMarks);
            const result = await this.importMarks(arrayMarks);
            this._spinner.hide();
        };
        reader.readAsBinaryString(file);

        this._spinner.hide();
    }

    async importMarks(marks: Mark[]) {
        this._spinner.show();
        this.loadingText = `Añadiendo marcas ...`;
        let i = 0,
            errorIds = [];
        for (let mark of marks) {
            this.loadingText = `Añadiendo marcas ${++i} de ${marks.length}`;
            if (!(await this._apiService.addDocument('mark', mark))) {
                errorIds.push(mark.codigo_marca);
            }
        }
        this._spinner.hide();
        if (errorIds.length > 0) {
            this._utilsService.openSnackBar(`Hubo errores añadiendo marcas`, 'error');
        } else {
            this._utilsService.openSnackBar(`Marcas subidas correctamente`);
        }
        await this.getMarks();
        return !(errorIds.length > 0);
    }

    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this._spinner.show('innerSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this._spinner.hide('innerSpinner');
        }
    }

    setMarksInTable(marks: Mark[]) {
        this.marksInPage = marks;
        // this.dataSource.data = marks.slice(0, this.sliceSize);
        this.dataSource.data = marks;
        // this.dataSource = new TableVirtualScrollDataSource(marks);// infiniteScroll
        // console.log(this.dataSource);
        this.showLoading(false);
    }

    async getMarks() {
        this.setMarksInTable(await this.selectMarks());
    }
    async selectMarks() {
        this.showLoading(true);
        let docs = [];
        try {
            docs = await this._apiService.getDocuments<Mark>('mark', undefined, [
                'codigo_marca',
                'asc',
            ]);
        } catch (err) {
            console.log('============= err =============');
            console.log(err);
        }
        this.length = docs.length;
        return docs;
        // undefined,/*where*/undefined, /*order_clause*/undefined, '0', this.pageSize.toString());
    }

    searchValue(event: any) {
        const filterValue: string = event;
        this.dataSource!.filter = filterValue.trim().toLowerCase();
    }

    async deleteMarks() {
        let errorIds = [];
        if (this.clickedRows.size == 0) {
            this._utilsService.openSnackBar('Debe seleccionar al menos una marca', 'warning');
            return;
        }
        const result = await this._utilsService.openQuestionDialog(
            'Confirmación',
            '¿Desea eliminar las marcas seleccionadas?'
        );
        if (result) {
            this._spinner.show();
            this.loadingText = `Eliminando marcas ...`;
            let rowIndexes = new Set<number>();
            const oldDataSource = this.dataSource!.data;
            this.clickedRows.forEach(async (row) => {
                console.log(row.id);

                const index = this.dataSource!.data.indexOf(row, 0);
                if (index > -1) {
                    rowIndexes.add(index);
                }
            });
            let marks = [];
            let deleteCount = 0;
            for (let i = 0; i < oldDataSource.length; i++) {
                if (!rowIndexes.has(i)) {
                    marks.push(oldDataSource![i]);
                } else {
                    this.loadingText = `Eliminando marcas ${++deleteCount} de ${rowIndexes.size}`;
                    if (
                        !(await this._apiService.deleteDocument(
                            'mark',
                            oldDataSource[i].id!.toString()
                        ))
                    ) {
                        console.log('****************** deleteMark error *************');
                        errorIds.push(oldDataSource[i].id);
                    } else {
                        console.log('****************** deleteMark success *************');
                    }
                }
            }
            if (errorIds.length > 0) {
                this._utilsService.openSnackBar(`Hubo errores eliminando marcas`, 'error');
            } else {
                this._utilsService.openSnackBar(`Marcas eliminadas correctamente`);
            }
            this.length -= rowIndexes.size;
            this.setMarksInTable(marks);
            this.clickedRows.clear();
            this._spinner.hide();
        }
    }

    async filterBy(column: string) {
        this.filteredColumn = getMarkField(column);
        if (
            getMarkFieldType(this.filteredColumn) == 'Date' ||
            getMarkFieldType(this.filteredColumn) == 'Timestamp'
        ) {
            const dates = await this._utilsService.openDateRangeSelectorDialog(
                'Seleccione rango de fechas'
            );
            this.onDateSelected(dates);
        } else {
            const options: any[] = this.dataSource.data.map(
                (mark: Mark) => mark[this.filteredColumn as keyof Mark]
            );
            const result = await this._utilsService.openSelectorDialog(
                'Seleccione opción',
                options
            );
            if (result) {
                 
                let docs = [];
                try {
                    docs = await this._apiService.getDocuments<Mark>('mark', [
                        [this.filteredColumn, '==', result],
                    ]);
                } catch (err) {
                    console.log('============= err =============');
                    console.log(err);
                }
                this.length = docs.length;
                this.setMarksInTable(docs);
            }
        }
    }
    async onDateSelected(dateRange: Date[]) {
        if (dateRange) {
            const startDate: Moment = moment(dateRange[0]);
            const startDateString = startDate.format('YYYY-MM-DD HH:mm:ss');
            let endDate: Moment = moment(dateRange[1]);
            if (!endDate) {
                endDate = startDate;
            }
            const endDateString = endDate.add(1, 'days').format('YYYY-MM-DD HH:mm:ss'); //plus one to filter range
            const values = [startDateString, endDateString];
            let docs = [];
            try {
                docs = await this._apiService.getDocuments<Mark>('mark', [
                    [this.filteredColumn!, '>=', startDate.toDate()],
                    [this.filteredColumn!, '<', endDate.toDate()],
                ]);
            } catch (err) {
                console.log('============= err =============');
                console.log(err);
            }
            this.length = docs.length;
            this.setMarksInTable(docs);
        } else {
            this._utilsService.openSnackBar('Rango fechas invalido', 'error');
        }
    }

    async orderBy(event: any) {
        const column = event.column;
        let orderType = event.orderType;
        if (orderType.toLowerCase().includes('asc')) {
            orderType = 'asc';
        } else {
            orderType = 'desc';
        }
        const orderedColumn = getMarkField(column);

        this.showLoading(true);
        const marks = await this._apiService.getDocuments<Mark>('mark', undefined, [
            orderedColumn,
            orderType,
        ]);
        this.setMarksInTable(marks);
    }

    async addNewRow(event: any) {
        console.log('**************** addNewRow ***************');
        const mark = await this._utilsService.openMarkDialog('');
        console.log(mark);
        if (mark) {
            this.dataSource.data.push(mark);
            const marks = this.dataSource.data;
            this.dataSource.data = [];
            this.dataSource.data = marks;
        }
    }
    async doubleClickedRow(row: any) {
        console.log('**************** doubleClickedRow ***************');
        console.log(row.id);
        const mark = await this._utilsService.openMarkDialog(row.id);
        if (mark) {
            this.dataSource.data[row.rowIndex] = mark;
            const marks = this.dataSource.data;
            this.dataSource.data = [];
            this.dataSource.data = marks;
        }
    }

    clickedRow(receivedEvent: any) {
        const row = receivedEvent.row;
        const event = receivedEvent.event;
        const rowIndex = receivedEvent.rowIndex;

        const previousRow = this.lastSelectedRow;
        this.lastSelectedRow = rowIndex;

        if (event.button === 0) {
            if (!event.ctrlKey && !event.shiftKey) {
                this.clickedRows.clear();
                this.toggleRow(row);
                // console.log('**************** !event.ctrlKey && !event.shiftKey ***************');
            } else if (event.ctrlKey) {
                this.toggleRow(row);
                // console.log('**************** event.ctrlKey ***************');
            }
            if (event.shiftKey) {
                this.selectRowsBetweenIndexes(previousRow, rowIndex);
                console.log('**************** event.shiftKey ***************');
            }
        }
        if (this._windowRefService.nativeWindow.getSelection) {
            //remove selection in table with shift
            if (this._windowRefService.nativeWindow.getSelection().empty) {
                // Chrome
                this._windowRefService.nativeWindow.getSelection().empty();
            } else if (this._windowRefService.nativeWindow.getSelection().removeAllRanges) {
                // Firefox
                this._windowRefService.nativeWindow.getSelection().removeAllRanges();
            }
        }
    }
    selectRowsBetweenIndexes(lastSelectedRow: number, rowIndex: number) {
        let start, end;
        if (rowIndex > lastSelectedRow) {
            start = lastSelectedRow;
            end = rowIndex;
        } else {
            end = lastSelectedRow;
            start = rowIndex;
        }
        for (let i = start; i <= end; i++) {
            this.clickedRows.add(this.dataSource!.data[i]);
        }
    }
    toggleRow(row: any) {
        if (this.clickedRows.has(row)) {
            this.clickedRows.delete(row);
        } else {
            this.clickedRows.add(row);
        }
    }

    exportMarksInTable() {
        this.exportExcel(this.dataSource!.data);
    }
    exportExcel(marks: any) {
        let excelFormatTasks = [];
        for (let mark of marks) {
            let data: any = {};
            const columns = getMarkExcelExportColumns();
            columns.forEach((column) => {
                data[`${column}`] = this._utilsService.tableDataPipe(
                    mark[getMarkExcelFieldName(column)]
                );
            });
            excelFormatTasks.push(data);
        }
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelFormatTasks);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        /* save to file */
        XLSX.writeFile(wb, 'Marcas_Exportados.xlsx');
    }
}

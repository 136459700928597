/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

interface ImageData {
    thumbImage: string;
    image: string;
    title: string;
}

@Component({
    selector: 'app-dialog-image',
    templateUrl: './dialog-image.component.html',
    styleUrls: ['./dialog-image.component.scss'],
})
export class DialogImageComponent implements OnInit {
    faAngleLeft = faAngleLeft;
    faAngleRight = faAngleRight;
    myThumbnail = '';
    myFullresImage = '';
    title: string = '';
    zoomMode = 'click';
    index = 0;
    next = false;
    prev = false;
    animation: string = 'row animate__animated animate__fadeIn';

    imageClicked = false;
    boxClicked = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<DialogImageComponent>
    ) {
        this.index = this.data.currentIndex;
        this.setButtonsVisibility();
        this.setImageData(this.data.photos[this.index]);
    }

    ngOnInit(): void {}

    /**
     * @brief Go to the previous photo
     */
    nextPhoto() {
        this.imageClicked = true;
        this.animation = 'row animate__animated animate__backInRight animate__fast';
        if (!this.isLastImage()) this.index++;
        this.setImageData(this.data.photos[this.index]);
        this.setButtonsVisibility();
    }

    /**
     * @brief Go to the next photo
     */
    prevPhoto() {
        this.imageClicked = true;
        this.animation = 'row animate__animated animate__backInLeft animate__fast';
        if (!this.isFirstImage()) this.index--;
        this.setImageData(this.data.photos[this.index]);
        this.setButtonsVisibility();
    }

    /**
     * @brief Sets the image data in the dialog.  Image data consist of
     * an image a thumbnail and a tittle.
     *
     * @param  {ImageData} imageData: Image data to set
     */
    setImageData(imageData: ImageData) {
        this.myThumbnail = imageData.thumbImage;
        this.myFullresImage = imageData.image;
        this.title = imageData.title;
    }

    /**
     * @brief This function check wheter the next or the
     * previous button need to be hidden or shown
     */
    setButtonsVisibility() {
        this.isFirstImage();
        this.isLastImage();
    }

    /**
     * @brief Check if it is first image and hides the previous button.
     * If it is not the first image shows the previous button
     */
    isFirstImage() {
        if (this.index <= 0) this.prev = true;
        else this.prev = false;
        return this.prev;
    }

    /**
     * @brief Check if it is last image and hides the next button.
     * If it is not the last image shows the next button
     */
    isLastImage() {
        if (this.index >= this.data.photos.length - 1) this.next = true;
        else this.next = false;
        return this.next;
    }

    /**
     * @brief This function closes the dialog
     */
    close() {
        this.dialogRef.close();
    }

    onBoxClick() {
        this.boxClicked = true;
        if (this.imageClicked) {
            this.imageClicked = false;
            return;
        }

        this.close();
    }

    onImageClick() {
        this.imageClicked = true;
    }
}

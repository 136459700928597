/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2023.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpResponse,
    HttpRequest,
    HttpHandler,
    HttpErrorResponse,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilsService } from '../services/utils.service';

/**
 * Interceptor for handling HTTP requests and responses, including error handling.
 */
@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private spinner: NgxSpinnerService,
        private _utilsService: UtilsService
    ) {}

    /**
     * Intercept HTTP requests and handle responses/errors.
     * @param httpRequest - The HTTP request to intercept.
     * @param next - The handler for the intercepted request.
     * @returns Observable with the intercepted event.
     */
    intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<any> {
        const token = localStorage.getItem('access_token') || '';
        this._utilsService.resposeError = false;
        return next
            .handle(httpRequest.clone({ setHeaders: { Authorization: `Bearer ${token}` } }))
            .pipe(
                tap((evt) => {
                    if (evt instanceof HttpResponse) {
                        if (evt.body && evt.body.success)
                            console.log(evt.body.success.message, evt.body.success.title, {
                                positionClass: 'error 1',
                            });
                    }
                }),
                catchError((err: any) => {
                    this._utilsService.resposeError = true;
                    if (err instanceof HttpErrorResponse) {
                        try {
                            if (err.status === 401) this.onNotAuthorizeResponse();
                            else this.handleServerErrorDownload(err, httpRequest.url, httpRequest.body);
                        } catch (e) {
                            console.error('An error occurred', err, e);
                        }
                    }
                    return of(err);
                })
            );
    }

    /**
     * Handles actions for unauthorized responses, such as displaying error messages and redirecting.
     */
    private onNotAuthorizeResponse(): void {
        if (this.router.url === '/login') {
            this._utilsService.openSnackBar(
                'Nombre de usuario o contraseña incorrecto',
                'error'
            );
        } else {
            this._utilsService.openSnackBar(
                'Sessión vencida, loguear de nuevo',
                'warning'
            );
            this.router.navigate(['/login']);
        }
        this.spinner.hide();
    }

    /**
     * Handles the download of the error response as a text file.
     * @param errorResponse - The error response to be downloaded.
     * @param url - The URL of the request.
     * @param body - The body of the request.
     */
    private handleServerErrorDownload(errorResponse: any, urlRequest: string, body: any): void {
        this.spinner.hide();
        if(this._utilsService.isDebugEnable()){
            this.downloadError(errorResponse, urlRequest, body);
            this._utilsService.showErrorMessage();
        }
    }
    
    /**
     * Obtiene el texto del error en formato JSON, que incluye la respuesta de error, la URL de la solicitud y el cuerpo de la solicitud.
     * @param errorResponse - La respuesta de error obtenida.
     * @param urlRequest - La URL de la solicitud que generó el error.
     * @param body - El cuerpo de la solicitud que generó el error.
     * @returns Una cadena JSON formateada que contiene la información del error.
     */
    getErrorText(errorResponse: any, urlRequest: string, body: any) {
        const errorData = {
            errorResponse,
            request: {
                urlRequest,
                body,
            },
        };
        return JSON.stringify(errorData, null, 2);
    }

    /**
     * Descarga el error en formato de archivo de texto, generado a partir de la respuesta de error, la URL de la solicitud y el cuerpo de la solicitud.
     * El archivo se descarga automáticamente.
     * @param errorResponse - La respuesta de error obtenida.
     * @param urlRequest - La URL de la solicitud que generó el error.
     * @param body - El cuerpo de la solicitud que generó el error.
     */
    downloadError(errorResponse: any, urlRequest: string, body: any){
        const errorText = this.getErrorText(errorResponse, urlRequest, body);
        this._utilsService.downloadErrorFile(errorText);
    }
}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ChartType, ChartDataset, ChartOptions } from 'chart.js';
import { IpcService } from '../../../../services/ipc.service';
import { UtilsService } from '../../../../services/utils.service';
import { AvgExecutedInADayPerMonth } from '../../../../interfaces/avg-executed-in-a-day-per-month';
import { ApiService } from '../../../../services/api.service';
import { MiRutaFilter } from '../../../../interfaces/mi-ruta-filter';
import { Location } from '@angular/common';
import { MiRutaUser } from 'src/app/interfaces/mi-ruta-user';
import { AvgExecutedInADayPerUserPerYear } from '../../../../interfaces/avg-executed-in-a-day-per-user-per-year';
import { AvgExecutedInADayPerYear } from '../../../../interfaces/avg-executed-in-a-day-per-year';
import { AvgExecutedInADayPerUserPerMonth } from '../../../../interfaces/avg-executed-in-a-day-per-user-per-month';

@Component({
    selector: 'app-statistics-avg',
    templateUrl: './statistics-avg.component.html',
    styleUrls: ['./statistics-avg.component.scss']
})
export class StatisticsAvgComponent implements OnInit {

    chartTypes: ChartType[] = ['line', 'bar', 'radar', 'pie', 'doughnut', 'polarArea', 'bubble', 'scatter'];
    barChartOptions: ChartOptions = { responsive: true };
    monthLabels: string[] = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    yearLabels: string[] = (() => {
        const currentYear = new Date().getFullYear();
        const years: string[] = [];
        for (let year = 2019; year <= currentYear; year++) {
            years.push(year.toString());
        }
        return years;
    })();
    
    avgInADayPerMonthChartType: ChartType = 'bar';
    avgInADayPerMonthChartLegend = true;
    avgInADayPerMonthChartData: ChartDataset[] = [{ 
        data: new Array(this.monthLabels.length).fill(0), 
        label: 'Sin año' 
    }];
    
    avgInADayPerYearPerUserChartLabels: string[] = [...this.yearLabels];
    avgInADayPerYearPerUserChartType: ChartType = 'bar';
    avgInADayPerYearPerUserChartLegend = true;
    avgInADayPerYearPerUserChartData: ChartDataset[] = [{ 
        data: new Array(this.yearLabels.length).fill(0), 
        label: 'Sin operario' 
    }];
    
    avgInADayPerYearChartLabels: string[] = [...this.yearLabels];
    avgInADayPerYearChartType: ChartType = 'line';
    avgInADayPerYearChartLegend = true;
    avgInADayPerYearChartData: ChartDataset[] = [{ 
        data: new Array(this.yearLabels.length).fill(0), 
        label: 'Sin operario' 
    }];
    
    avgInADayPerMonthPerUserChartType: ChartType = 'bar';
    avgInADayPerMonthPerUserChartLegend = true;
    avgInADayPerMonthPerUserChartData: ChartDataset[] = [{ 
        data: new Array(this.monthLabels.length).fill(0), 
        label: 'Sin año y operario' 
    }];
    
    avgInADayPerMonthFullScreen: boolean = false;
    avgInADayPerYearPerUserFullScreen: boolean = false;
    avgInADayPerYearFullScreen: boolean = false;
    avgInADayPerMonthPerUserFullScreen: boolean = false;
    loadingAvgInADayPerMonth: boolean = false;
    loadingAvgInADayPerYearPerUser: boolean = false;
    loadingAvgInADayPerYear: boolean = false;
    loadingAvgInADayPerMonthPerUser: boolean = false;

    avgInADayPerMonthGroup: FormGroup = new FormGroup({
        type: new FormControl(),
        year: new FormControl(),
    });

    avgInADayPerYearPerUserGroup: FormGroup = new FormGroup({
        type: new FormControl(),
        users: new FormControl(),
    });
    
    avgInADayPerYearGroup: FormGroup = new FormGroup({
        type: new FormControl(),
    });
    
    avgInADayPerMonthPerUserGroup: FormGroup = new FormGroup({
        type: new FormControl(),
        year: new FormControl(),
        users: new FormControl(),
    });

    dataAvgInADayPerMonth: AvgExecutedInADayPerMonth[] = [];
    dataAvgInADayPerYearPerUser: AvgExecutedInADayPerUserPerYear[] = [];
    dataAvgInADayPerYear: AvgExecutedInADayPerYear[] = [];
    dataAvgInADayPerMonthPerUser: AvgExecutedInADayPerUserPerMonth[] = [];

    avgInADayPerMonthFilter: MiRutaFilter = {};
    avgInADayPerYearPerUserFilter: MiRutaFilter = {};
    avgInADayPerYearFilter: MiRutaFilter = {};
    avgInADayPerMonthPerUserFilter: MiRutaFilter = {};

    users: MiRutaUser[] = [];

    constructor(
        public electronService: IpcService,
        private _utilsService: UtilsService,
        private _apiService: ApiService,
        public location: Location,
    ) { }

    async ngOnInit(): Promise<void> {
        this.users = await this._apiService.getUsers(['operario']);
        await this.setAvgInADayPerMonthActions();
        await this.setAvgInADayPerMonthPerUserActions();
        await this.setAvgInADayPerYearPerUserActions();
        await this.setAvgInADayPerYearActions();
    }

    async setAvgInADayPerMonthActions(): Promise<void> {
        this.avgInADayPerMonthGroup.controls['type'].valueChanges.subscribe((value) => {
            this.avgInADayPerMonthChartType = value;
        });
        this.avgInADayPerMonthGroup.controls['year'].valueChanges.subscribe(async (value) => {
            await this.getAvgInADayPerMonth(value);
        });
        this.avgInADayPerMonthGroup.controls['type'].setValue(this.avgInADayPerMonthChartType);
        this.avgInADayPerMonthGroup.controls['year'].setValue(new Date().getFullYear());
    }

    async setAvgInADayPerYearPerUserActions(): Promise<void> {
        this.avgInADayPerYearPerUserGroup.controls['type'].valueChanges.subscribe((value) => {
            this.avgInADayPerYearPerUserChartType = value;
        });
        this.avgInADayPerYearPerUserGroup.controls['users'].valueChanges.subscribe(async (value) => {
            await this.getAvgInADayPerYearPerUser(value);
        });
        this.avgInADayPerYearPerUserGroup.controls['type'].setValue(this.avgInADayPerYearPerUserChartType);
    }

    async setAvgInADayPerYearActions(): Promise<void> {
        this.avgInADayPerYearGroup.controls['type'].setValue(this.avgInADayPerYearChartType);
        this.avgInADayPerYearGroup.controls['type'].valueChanges.subscribe((value) => {
            this.avgInADayPerYearChartType = value;
        });
        await this.getAvgInADayPerYear();
    }

    async setAvgInADayPerMonthPerUserActions(): Promise<void> {
        this.avgInADayPerMonthPerUserGroup.controls['type'].valueChanges.subscribe((value) => {
            this.avgInADayPerMonthPerUserChartType = value;
        });
        this.avgInADayPerMonthPerUserGroup.controls['year'].valueChanges.subscribe(async (year) => {
            const users = this.avgInADayPerMonthPerUserGroup.controls['users'].value;
            this.getAvgInADayPerMonthPerUser(users, year);
        });
        this.avgInADayPerMonthPerUserGroup.controls['users'].valueChanges.subscribe(async (users) => {
            const year = this.avgInADayPerMonthPerUserGroup.controls['year'].value;
            this.getAvgInADayPerMonthPerUser(users, year);
        });
        this.avgInADayPerMonthPerUserGroup.controls['type'].setValue(this.avgInADayPerMonthPerUserChartType);
        this.avgInADayPerMonthPerUserGroup.controls['year'].setValue(new Date().getFullYear());
    }

    async toggleAvgInADayPerMonthFullScreen(): Promise<void> {
        this.avgInADayPerMonthFullScreen = !this.avgInADayPerMonthFullScreen;
        this.getAvgInADayPerMonth(this.avgInADayPerMonthGroup.controls['year'].value);
    }

    async toggleAvgInADayPerYearPerUserFullScreen(): Promise<void> {
        this.avgInADayPerYearPerUserFullScreen = !this.avgInADayPerYearPerUserFullScreen;
        this.getAvgInADayPerYearPerUser(this.avgInADayPerYearPerUserGroup.controls['users'].value);
    }

    async toggleAvgInADayPerYearFullScreen(): Promise<void> {
        this.avgInADayPerYearFullScreen = !this.avgInADayPerYearFullScreen;
        this.getAvgInADayPerYear();
    }

    async toggleAvgInADayPerMonthPerUserFullScreen(): Promise<void> {
        this.avgInADayPerMonthPerUserFullScreen = !this.avgInADayPerMonthPerUserFullScreen;
        this.getAvgInADayPerMonthPerUser(
            this.avgInADayPerMonthPerUserGroup.controls['users'].value,
            this.avgInADayPerMonthPerUserGroup.controls['year'].value
        );
    }

    // ================================== AvgExecutedInADayPerMonthPerUser ==================================
    async getAvgInADayPerMonthPerUser(users: MiRutaUser[], year: number): Promise<void> {
        if(users && users.length && year) {
            this.loadingAvgInADayPerMonthPerUser = true;
            const userIds = users.map((item) => item.id.toString());
            this.avgInADayPerMonthPerUserFilter = {};
            this.avgInADayPerMonthPerUserFilter = this._utilsService.processFilter(this.avgInADayPerMonthPerUserFilter, userIds, 'last_modification_operator_uid', 'string', '', false);
            this.avgInADayPerMonthPerUserFilter = this._utilsService.processFilter(this.avgInADayPerMonthPerUserFilter, [year], '_year', 'number', '', false);
            const where = this._utilsService.getWhereClauseFromFilter(this.avgInADayPerMonthPerUserFilter);
            this.dataAvgInADayPerMonthPerUser = await this._apiService.getAvgInADayPerUserPerMonth(where);
            this.avgInADayPerMonthPerUserChartData = [];
            this.setAvgInAMonthPerUserChartData(users);
            this.loadingAvgInADayPerMonthPerUser = false;
        }
    }

    setAvgInAMonthPerUserChartData(users: MiRutaUser[]): void {
        for (const user of users) {
            const userData = this.dataAvgInADayPerMonthPerUser.filter((item) => +item.last_modification_operator_uid === user.id);
            let data: number[] = this.getAvgInAMonthPerUserData(userData);
            this.avgInADayPerMonthPerUserChartData.push({ data: data, label: user.nombre });
        }
    }

    getAvgInAMonthPerUserData(userData: AvgExecutedInADayPerUserPerMonth[]): number[] {
        let data: number[] = [];
        for(let month = 1; month <= this.monthLabels.length; month++) {
            let executed_per_day = 0;
            for(const item of userData){
                if(item._month === month) executed_per_day = item.executed_per_day;                        
            }
            data.push(Math.round(executed_per_day));
        }
        return data;
    }
    // ================================== AvgExecutedInADayPerMonthPerUser ==================================

    // ================================== AvgExecutedInADayPerYear ==================================
    async getAvgInADayPerYear(): Promise<void> {
        this.loadingAvgInADayPerYear = true;
        this.avgInADayPerYearFilter = {};
        const where = this._utilsService.getWhereClauseFromFilter(this.avgInADayPerYearFilter);
        this.dataAvgInADayPerYear = await this._apiService.getAvgInADayPerYear(where);
        this.setAvgInADayPerYearChartData();
        this.loadingAvgInADayPerYear = false;
    }

    setAvgInADayPerYearChartData(): void {
        this.avgInADayPerYearChartData = [];
        let data: number[] = this.getAvgInADayPerYearData(this.dataAvgInADayPerYear);
        this.avgInADayPerYearChartData.push({ data: data, label: 'Total por año' });
    }

    getAvgInADayPerYearData(userData: AvgExecutedInADayPerYear[]): number[] {
        let data: number[] = [];
        for(const year of this.avgInADayPerYearChartLabels) {
            let executed_per_day = 0;
            for(const item of userData){
                if(item._year === +year) executed_per_day = item.executed_per_day;                        
            }
            data.push(Math.round(executed_per_day));
        }
        return data;
    }
    // ================================== end AvgExecutedInADayPerYear ==================================

    // ================================== AvgExecutedInADayPerUserPerYear ==================================
    async getAvgInADayPerYearPerUser(users: MiRutaUser[]): Promise<void> {
        if(users && users.length) {
            this.loadingAvgInADayPerYearPerUser = true;
            const userIds = users.map((item) => item.id.toString());
            this.avgInADayPerYearPerUserFilter = {};
            this.avgInADayPerYearPerUserFilter = this._utilsService.processFilter(this.avgInADayPerYearPerUserFilter,
                userIds, 'last_modification_operator_uid','string', '', false,
            );
            const where = this._utilsService.getWhereClauseFromFilter(this.avgInADayPerYearPerUserFilter);
            this.dataAvgInADayPerYearPerUser = await this._apiService.getAvgInADayPerUserPerYear(where);
            this.setAvgInADayPerYearPerUser(this.dataAvgInADayPerYearPerUser);
            this.setAvgInAYearPerUserChartData(users);
            this.loadingAvgInADayPerYearPerUser = false;
        }
    }

    setAvgInAYearPerUserChartData(users: MiRutaUser[]): void {
        this.avgInADayPerYearPerUserChartData = [];
        for (const user of users) {
            const userData = this.dataAvgInADayPerYearPerUser.filter((item) => +item.last_modification_operator_uid === user.id);
            let data: number[] = this.getAvgInADayPerYearPerUserData(userData);
            this.avgInADayPerYearPerUserChartData.push({ data: data, label: user.nombre });
        }
    }   
    
    getAvgInADayPerYearPerUserData(userData: AvgExecutedInADayPerUserPerYear[]): number[] {
        let data: number[] = [];
        for(const year of this.avgInADayPerYearPerUserChartLabels) {
            let executed_per_day = 0;
            for(const item of userData){
                if(item._year === +year) executed_per_day = item.executed_per_day;                        
            }
            data.push(Math.round(executed_per_day));
        }
        return data;
    }

    setAvgInADayPerYearPerUser(userData: AvgExecutedInADayPerUserPerYear[]) {
        this.avgInADayPerYearPerUserChartLabels = [];
        const years = userData.map((item) => item._year);
        for (const year of years) {
            if(!this.avgInADayPerYearPerUserChartLabels.includes(`${year}`)){
                this.avgInADayPerYearPerUserChartLabels.push(`${year}`);
            } 
        }
        this.avgInADayPerYearPerUserChartLabels.sort();
    }
    // ================================== end AvgExecutedInADayPerUserPerYear ==================================
    

    // ================================== AvgExecutedInADayPerMonth ==================================
    async getAvgInADayPerMonth(year: number): Promise<void> {
        if(year) {
            this.loadingAvgInADayPerMonth = true;
            this.avgInADayPerMonthFilter = {}
            this.avgInADayPerMonthFilter = this._utilsService.processFilter(this.avgInADayPerMonthFilter, [year], '_year', 'number', '', false);
            const where = this._utilsService.getWhereClauseFromFilter(this.avgInADayPerMonthFilter);
            this.dataAvgInADayPerMonth = await this._apiService.avgExecutedInADayPerMonth(where);
            this.setAvgInADayPerMonthChartData(year);
            this.loadingAvgInADayPerMonth = false;
        }
    }

    setAvgInADayPerMonthChartData(year: number): void {
        this.avgInADayPerMonthChartData = [];
        let data: number[] = this.getAvgInADayPerMonthData(this.dataAvgInADayPerMonth);
        this.avgInADayPerMonthChartData.push({
            data: data, 
            label: year.toString()
        });
    }
    
    getAvgInADayPerMonthData(monthData: AvgExecutedInADayPerMonth[]): number[] {
        let data: number[] = [];
        for(let month = 1; month <= this.monthLabels.length; month++) {
            let executed_per_day = 0;
            for(const item of monthData){
                if(item._month === month) executed_per_day = item.executed_per_day;                        
            }
            data.push(Math.round(executed_per_day));
        }
        return data;
    }
    // ================================== end AvgExecutedInADayPerMonth ==================================
}

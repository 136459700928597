import { FormControl, FormGroup } from '@angular/forms';

export interface PlanningDetailExtra {
    id: number;
    common_code: string;
    codigo_planning: string;
    codigo_planning_details: string;
    detail: string;
    date_time_modified: Date;
}


export function getPlanningDetailExtraDisplayColumns(): string[] {
    const displayedColumns: string[] = [
        'Código común',
        'Código planificación',
        'Código detalle planificación',
        'Detalle',
        'Fecha de Modificación'
    ];
    return displayedColumns;
}

export function getPlanningDetailExtraField(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'Código planificación':
            value = 'codigo_planning';
            break;
        case 'Código detalle planificación':
            value = 'codigo_planning_details';
            break;
        case 'Código común':
            value = 'common_code';
            break;
        case 'Detalle':
            value = 'detail';
            break;
        case 'Fecha de Modificación':
            value = 'date_time_modified';
            break;
    }
    return value;
}

export function getPlanningDetailExtraFieldName(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'codigo_planning':
            value = 'Código planificación';
            break;
        case 'codigo_planning_details':
            value = 'Código detalle planificación';
            break;
        case 'common_code':
            value = 'Código común';
            break;
        case 'detail':
            value = 'Detalle';
            break;
        case 'date_time_modified':
            value = 'Fecha de Modificación';
            break;
    }
    return value;
}

export function getPlanningDetailExtraFormControls(): FormGroup {
    const planningFormData: FormGroup = new FormGroup({
        id: new FormControl(),
        common_code: new FormControl(),
        codigo_planning: new FormControl(),
        codigo_planning_details: new FormControl(),
        detail: new FormControl(),
        date_time_modified: new FormControl(),
    });
    return planningFormData;
}

export function getPlanningDetailExtraFieldType(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'id':
            value = 'number';
            break;
        case 'common_code':
            value = 'string';
            break;
        case 'codigo_planning':
            value = 'string';
            break;
        case 'codigo_planning_details':
            value = 'string';
            break;
        case 'detail':
            value = 'string';
            break;
        case 'date_time_modified':
            value = 'Date';
            break;
    }
    return value;
}

export function getPlanningDetailExtraExcelFieldName(excel_field: string): string {
    let value: string = '';
    switch (excel_field.trim().toLowerCase()) {
        case 'detalle':
            value = 'detail';
            break;
        case 'detail':
            value = 'detail';
            break;
        case 'código planificación':
            value = 'codigo_planning';
            break;
        case 'codigo planificacion':
            value = 'codigo_planning';
            break;
        case 'codigo_planning_details':
            value = 'codigo_planning_details';
            break;
        case 'codigo detalle planificacion':
            value = 'codigo_planning_details';
            break;
        case 'código detalle planificación':
            value = 'codigo_planning_details';
            break;
        case 'código detalle planificacion':
            value = 'codigo_planning_details';
            break;
        case 'codigo detalle planificación':
            value = 'codigo_planning_details';
            break;
        case 'fecha de modificación':
            value = 'date_time_modified';
            break;
        case 'fecha de modificacion':
            value = 'date_time_modified';
            break;
        case 'codigo_planning':
            value = 'codigo_planning';
            break;
        case 'date_time_modified':
            value = 'date_time_modified';
            break;
        case 'código común':
            value = 'common_code';
            break;
        case 'codigo común':
            value = 'common_code';
            break;
        case 'codigo comun':
            value = 'common_code';
            break;
    }
    return value;
}

export function getPlanningDetailExtraExcelExportColumns(): string[] {
    const columns = [
        'Código común',
        'Código planificación',
        'Código detalle planificación',
        'Detalle',
        'Fecha de Modificación'
    ];
    return columns;
}

/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DateRangeSelectorData {
    placeHolderText: '';
}

@Component({
    selector: 'app-date-range-selector',
    templateUrl: './date-range-selector.component.html',
    styleUrls: ['./date-range-selector.component.scss'],
})
export class DateRangeSelectorComponent implements OnInit {
    range = new FormGroup({
        start: new FormControl(),
        end: new FormControl(),
    });

    date_range: Date[] = [];

    placeHolderText?: string;

    /**
     * Represents the DateRangeSelectorComponent.
     * @constructor
     * @param data - The data object containing the placeHolderText.
     */
    constructor(@Inject(MAT_DIALOG_DATA) public data: DateRangeSelectorData) {
        this.placeHolderText = data.placeHolderText;
    }

    /**
     * Initializes the component.
     * This method is called after the component has been created and initialized.
     * It is a lifecycle hook that is called once, after the first `ngOnChanges` hook is called.
     */
    ngOnInit(): void {}

    /**
     * Handles the selection of a date range.
     */
    onSelectedDate() {
        this.date_range.push(this.range.controls['start'].value.toDate());
        if (this.range.controls['end'].value) {
            this.date_range.push(this.range.controls['end'].value.toDate());
        } else {
            this.date_range.push(this.range.controls['start'].value.toDate());
        }
    }
}

// <div class="custom-theme-example">
//             <input placeholder="Custom theme" aria-label="Custom theme"
//                 [ngxTimepicker]="darkPicker" readonly>
//             <ngx-material-timepicker #darkPicker [theme]="darkTheme"></ngx-material-timepicker>
//          </div>

//         //...
//         darkTheme: NgxMaterialTimepickerTheme = {
//             container: {
//                 bodyBackgroundColor: '#424242',
//                 buttonColor: '#fff'
//             },
//             dial: {
//                 dialBackgroundColor: '#555',
//             },
//             clockFace: {
//                 clockFaceBackgroundColor: '#555',
//                 clockHandColor: '#9fbd90',
//                 clockFaceTimeInactiveColor: '#fff'
//             }
//         };

<div class="background animate__animated animate__fadeIn animate__slow">
    <!-- <img src='assets/img/background.svg' style='position: absolute; top: -20px; width: 100%; height: 100%; object-fit: fill;'> -->

    <mat-card class="center-child">
        <mat-card-title>
            <div class="child-content">
                <p>
                    <img src="assets/img/logo.svg" width="130" alt="" />
                </p>
            </div>
        </mat-card-title>

        <mat-card-content [formGroup]="formData">
            <div class="child">
                <div class="div-margin">
                    <div class="" style="text-align: center">
                        <div>
                            <mat-form-field appearance="outline">
                                <mat-label>Nombre de usuario</mat-label>
                                <input
                                    #emailSignIn
                                    formControlName="user_name"
                                    matInput
                                    type="email"
                                    placeholder="Nombre de usuario"
                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                                    required
                                />
                                <mat-icon matSuffix>account_circle</mat-icon>
                                <!-- <mat-hint>Hint</mat-hint> -->
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field appearance="outline">
                                <mat-label>Contraseña</mat-label>
                                <input
                                    #passwordSignIn
                                    formControlName="password"
                                    matInput
                                    [type]="passworType"
                                    placeholder="Contraseña"
                                    required
                                />
                                <mat-icon *ngIf="passworType == 'password'" (click)="togglePasswordVisibility()" matSuffix>visibility</mat-icon>
                                <mat-icon *ngIf="passworType == 'text'"     (click)="togglePasswordVisibility()" matSuffix>visibility_off</mat-icon>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>

        <mat-card-footer>
            <div>
                <p style="text-align: center">
                    <button 
                        mat-raised-button
                        color="primary"
                        class="button-login"
                        (click)="login(emailSignIn.value.trim(), passwordSignIn.value)"
                    >
                        Entrar
                    </button>
                </p>
            </div>
        </mat-card-footer>
    </mat-card>
</div>

<ngx-spinner
    bdColor="rgba(0, 0, 0, 0.1)"
    size="large"
    color="#368DCE"
    type="square-jelly-box"
    [fullScreen]="true"
>
    <p class="loading-text-background" style="margin-top: 50px">Autenticando...</p>

    <div
        *ngIf="_utilsService.resposeError"
        class="close-loading-button animate__animated animate__fadeInDown"
        style="top: 95px; left: 45px"
        (click)="closeLoading()"
    >
        <button mat-icon-button matTooltip="Cancelar petición" color="primary">
            <mat-icon style="font-size: 25px; color: white">close</mat-icon>
        </button>
    </div>
</ngx-spinner>
`

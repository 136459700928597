/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';

export interface DateSelectorData {
    placeHolderText: string;
    defaultDate: Date;
}

@Component({
    selector: 'app-date-selector',
    templateUrl: './date-selector.component.html',
    styleUrls: ['./date-selector.component.scss'],
})
export class DateSelectorComponent implements OnInit {
    dateControl = new FormGroup({ dateValue: new FormControl() });
    defaultDate: Date = new Date();
    dateSelected: Date = new Date();
    placeHolderText: string = 'Seleccione fecha';

    constructor(@Inject(MAT_DIALOG_DATA) public data: DateSelectorData) {
        this.placeHolderText = data.placeHolderText;
        if(data.defaultDate) this.defaultDate = data.defaultDate;
        this.dateControl.controls['dateValue'].setValue(moment(this.defaultDate));
    }

    ngOnInit(): void {}

    onSelectedDate() {
        const dateMoment = this.dateControl.controls['dateValue'].value;
        console.log(dateMoment);
        this.dateSelected = dateMoment.toDate();
        console.log(this.dateSelected);
    }
}

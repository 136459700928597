/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface InputSelectorDialogData {
    placeHolderText: string;
    currentValue: string;
    inputType: string;
}

@Component({
    selector: 'app-input-selector',
    templateUrl: './input-selector.component.html',
    styleUrls: ['./input-selector.component.scss'],
})
export class InputSelectorComponent implements OnInit {
    @Input() placeHolderText = '';
    @Input() inputType = 'text';

    formData = new FormGroup({
        currentValue: new FormControl(),
    });

    constructor(@Inject(MAT_DIALOG_DATA) public data: InputSelectorDialogData) {
        this.placeHolderText = data.placeHolderText;
        this.formData.controls['currentValue'].setValue(data.currentValue);
        this.inputType = data.inputType;
    }

    ngOnInit(): void {}
}
